$data-editor-row-height: 30px;
.data-editor {
  .data-editor__header {
    display: flex;
    border-bottom: 1px solid $app-border-color;
    margin-bottom: 0px;
    height: 30px;
    position: relative;
    box-sizing: border-box;
    padding-left: 15px;

    .resize-preview {
      position: absolute;
      width: 4px;
      height: 100%;
      background-color: $app-border-color;
      z-index: 20;
    }

    @include hover {
      background-color: transparent;
    }

    .data-editor__header__controls {
      color: var(--content-color-brand);
      display: flex;
      flex-direction: row;
      padding: 5px;
      align-items: center;

      .data-editor-column-toggle {
        position: relative;
        border-left: 1px solid var(--border-color-default);

        .data-editor-column-toggle__menu {
          position: absolute;
          flex: 1;
          max-width: 175px;
          max-height: 300px;
          overflow-y: auto;
          overflow-x: hidden;
          background: $dropdown-menu-background-color;
          z-index: $dropdown-menu-z-index;
          @include material-shadow-1;
          right: 0;
          top: 25px;
          padding: 5px;
          color: $dropdown-menu-item-color;
          border-radius: var(--border-radius-default);
        }

        .data-editor-column-toggle__column {
          &:first-child {
            font-size: 10px;
            color: var(--content-color-secondary);
            cursor: default;
          }
          display: flex;
          padding: 5px;
          cursor: pointer;
        }

        .input-checkbox {
          margin-right: 10px;
        }
      }
    }

    .data-editor__reorder {
      flex: 0 0 15px;
    }

    .data-editor__header__cell {
      display: flex;
      align-items: center;
      position: relative;
      border-width: 0px 1px 0px 1px;
      box-sizing: border-box;
      border-style: solid;
      border-color: var(--border-color-default);
      margin: 0;
      padding: 5px;
      font-size: 10px;
      font-family: $font-primary;
      font-weight: var(--text-weight-medium);
      color: var(--content-color-secondary);
      cursor: default;
      overflow: hidden;

      span {
        @include text-truncate-ellipsis;
      }

      .resize-handle {
        position: absolute;
        top: 0px;
        width: 16px;
        height: 100%;
        right: -10px;
        cursor: col-resize;
        z-index: 10;
      }
    }
  }

  .data-editor__rows {
    flex: 1;
    border-top: none;
  }

  .data-editor__row {
    display: flex;
    flex-direction: row;
    height: 30px;
    border-left: 1px solid $app-border-color;
    border-right: 1px solid $app-border-color;

    .data-editor__custom-row {
      display: flex;
      flex: 1;
      height: 30px;
      position: relative;

      .data-editor__reorder {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: $data-editor-row-height;
        flex: 0 0 15px;
        cursor: ns-resize;
        visibility: hidden;

        .data-editor__reorder__icon {
          @include unselectable;
        }

        @include hover {
          background-color: transparent;

          .data-editor__reorder__icon {
            cursor: ns-resize;
          }
        }
      }

      &.is-focused,
      &:hover {
        background-color: var(--background-color-secondary);

        .data-editor__reorder {
          visibility: visible;
        }

        .data-editor__row__actions {
          display: flex;
        }

        .data-editor__cells .data-editor__cell:last-child {
          padding-right: 30px;
        }
      }

      &.is-selected {
        background-color: var(--background-color-secondary);
      }

      &.is-dragged {
        opacity: 0.1;
      }

      &.hover-top {
        position: relative;

        &::before {
          background-color: var(--base-color-brand);
          top: 0;
          content: ' ';
          position: absolute;
          height: 2px;
          width: 100%;
          z-index: 1;
        }
      }

      &.hover-bottom {
        position: relative;

        &::before {
          background-color: var(--base-color-brand);
          bottom: 0;
          content: ' ';
          position: absolute;
          height: 2px;
          width: 100%;
          z-index: 1;
        }
      }

      .data-editor__cells {
        display: flex;
        flex: 1;
      }

      .data-editor__row__actions {
        display: none;
        z-index: 10;
        position: absolute;
        right: 6px;
        height: 30px;
        align-items: center;

        .data-editor__row__action--delete {
          cursor: pointer;

          @include unselectable;
          @include clickable;
        }
      }

      .data-editor__cell {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        position: relative;
        border: 1px solid var(--border-color-default);
        margin: 0px;
        flex-direction: row;

        &.is-disabled {
          color: var(--content-color-tertiary);
          text-decoration: line-through;
        }

        /* Specific styles for inputs. Can be removed / replaced */

        .input-suggestion-group,
        .input-field {
          width: 100%;
          margin: 1px 3px;

          .input-field {
            margin: 0;
          }

          input {
            height: 22px;

            &:focus {
              background: $app-background-color;
              border: 1px solid $app-border-color;
            }
          }
        }

        .auto-suggest {
          margin: 1px;

          .auto-suggest-cell {
            padding: 0;
          }

          &.is-focused {
            .DraftEditor-editorContainer {
              border: 1px solid $app-border-color;
            }

            .DraftEditor-root {
              background: $app-background-color;
            }
          }

          .public-DraftEditorPlaceholder-root {
            color: var(--content-color-tertiary);
          }

          .DraftEditor-editorContainer, .public-DraftEditorPlaceholder-root {
            padding: 3px;
            border: 1px solid transparent;
          }

          .public-DraftEditor-content, .public-DraftEditorPlaceholder-root {
            font-size: var(--text-size-m);
            font-family: $font-primary;
          }

          .DraftEditor-root{
            width: 100%;
          }
        }

        .dropdown-menu {
          max-height: 180px;
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid $app-border-color;

      .data-editor__custom-row .data-editor__row__actions {
        display: none;
      }

      .data-editor__custom-row {
        padding-left: 15px;

        .data-editor__reorder {
          display: none;
        }

        &.is-focused,
        &:hover {
          .data-editor__reorder {
            visibility: none;
          }

          .data-editor__row__actions {
            display: none;
          }

          .data-editor__cells .data-editor__cell:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

/* @postman/data-editor styles */
.data-editor__header {
  border: 1px solid $app-border-color;
}

.data-editor__header__cell {
  display: flex;
  align-items: center;
  position: relative;
  border-width: 0px 1px 0px 1px;
  box-sizing: border-box;
  border-style: solid;
  border-color: var(--border-color-default);
  margin: 0;
  padding: 5px;
  font-size: 10px;
  font-family: $font-primary;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: default;

  @include text-truncate-ellipsis;
}

.data-editor__cell {
  .data-editor__cell__content {
    display: flex;
    flex: 1;

    .auto-suggest {
      width: 100%;
    }
  }

  .data-editor__cell__string,
  .data-editor__cell__number {
    border: none;

    input {
      background-color: transparent;
      color: $app-generic-text-color;
      font-size: 12px;
      height: 24px;
      margin: 1px 3px;
      padding: 0 3px;
      border: 1px solid transparent;

      &:focus {
        border: 1px solid $app-border-color;
      }
    }
  }

  .data-editor__object-key,
  .data-editor__object-value {
    border: none;
  }
}

.data-editor__header {
  margin-left: 0px;

  .data-editor__custom-header {
    display: flex;
    flex: 1;
    position: relative;
  }
  .data-editor__header__controls {
    position: absolute;
    right: 0;
    top: 0;

    .data-editor-column-toggle {
      background-color: $app-background-color;

      .data-editor-column-toggle__menu {
        position: absolute;
        width: 250px;
        background: $dropdown-menu-background-color;
        z-index: $dropdown-menu-z-index;
        @include material-shadow-1;
        right: 0;
        top: 25px;
        padding: 12px;
        color: $dropdown-menu-item-color;
        border-radius: var(--border-radius-default);
      }

      .data-editor-column-toggle__group:not(:first-child) {
        margin-top: 10px;
      }

      .data-editor-column-toggle__header {
        font-size: 12px;
        margin-bottom: 8px;
      }

      .data-editor-column-toggle__column {
        display: flex;
        padding: 5px;
        cursor: pointer;

        &:first-child {
          font-size: 10px;
          color: var(--content-color-secondary);
          cursor: default;
        }
      }

      .input-checkbox {
        margin-right: 10px;
      }
    }
  }
}
