/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$empty-state-path: '../../../../../assets/images/empty-state/' !default;
$icon-not-signed-in-url: $empty-state-path + 'integrations_cloud.svg';
$icon-github-logo: $empty-state-path + 'github_logo.png';
$icon-bitbucket-logo: $empty-state-path + 'bitbucket_logo.png';
$icon-gitlab-logo: $empty-state-path + 'gitlab.png';
$icon-azure-logo: $empty-state-path + 'azure.png';
$icon-slack-logo: $empty-state-path + 'slack_new.svg';

.import-via-code-repository-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  overflow-y: auto;

  .import-via-code-repository {
    display: flex;
    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      .import-via-code-repository__line {
        //check
        height: 0px;
        margin-top: var(--spacing-xl);

        /* border-color/strong */
        border-top: var(--border-width-default) solid var(--border-color-strong);
      }
      .import-via-code-repository__content-title {
        font-size: var(--text-size-m);
        color: var(--content-color-primary);
        // check
        margin-top: 2px;
        width: auto;
        float: left;
      }
      .import-via-code-repository__content-dropdown {
        width: 480px;

        .integrations-add-card__segment {

          .Select--single.pm-select {
            font-size: 12px;
            height: 32px;
            padding: var(--spacing-zero);
            width: 480px;
            margin: 0px;
          }

          .Select--single.pm-select .Select-menu {
            max-height: 130px;
          }
        }
        .import-dropdown-wrapper {
          padding: var(--spacing-zero);
          width: 480px;
        }

        .import-dropdown-group {
          display: flex;
          flex-direction: column;
          // check
          padding-top: 20px;
          .import-repository-missing__message {
            display: flex;
            flex-direction: row;
            color: var(--content-color-secondary);
            .import-repository-message {
              margin-top:  var(--spacing-s);
              color: var(--content-color-secondary);
            }
            .btn-text {
              height: var(--size-xs);
              margin-top:  var(--spacing-s);
              padding-left: var(--spacing-xs);
              color: var(--content-color-secondary);
            }

          }
          .import-repo-error {
            display: flex;
            flex-direction: row;
            color: var(--content-color-error);
            height: 20px;
            align-items: center;
            padding: 1px;

            .import-repo-error-btn {
              cursor: pointer;
              text-decoration: underline;
              margin-left: var(--spacing-xs);
            }
          }

          .import-repo-specify-manually {
            display: flex;
            flex-direction: row;
            color: var(--content-color-secondary);
            height: 20px;
            align-items: center;
            padding-left: 1px;
            padding-top: var(--spacing-s);

            .import-repo-specify-manually-btn {
              cursor: pointer;
              text-decoration: underline;
              margin-left: var(--spacing-xs);
            }
          }

          .import-repository-error__message {
            display: flex;
            flex-direction: row;
            color: var(--content-color-error);
            padding-top: var(--spacing-s);
            .import-repository-error__icon {
              padding: var(--spacing-xs);
              @include icon-color(var(--base-color-error));
            }
          }
        }

        .import-dropdown-label {
          font-style: normal;
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          padding-bottom: var(--spacing-s);
        }
        .import-dropdown-label--manual-input {
          // check
          height: 40px;
        }
      }
    }
  }
}

.add-margin-left {
  margin-left: var(--spacing-xs);
}

.import-via-code-repository-container-new-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: visible;
  margin-left: calc(var(--spacing-s) * -1);
}

.import-via-code-repository-user-info {
  display: flex;
  flex-direction: row;
  width: auto;
  float: left;
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
    .import-via-code-repository-user-info__username {
      margin-left: var(--spacing-xs);
    }
    .import-via-code-repository-user-info__username_title {
      margin-right: var(--spacing-s);
      color: var(--content-color-secondary);
    }
}

.importers-repository-primary-screen {
  font-family: var(--text-family-default);
  text-align: left;

  .entity-empty {
    border: none;
    padding: var(--spacing-zero);
    font-size: var(--text-size-m);
    align-items: flex-start;
    &.entity-empty__centered {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .entity-empty__title {
        &.github-import-title {
          font-size: var(--text-size-l);
          color: var(--content-color-primary);
          line-height: var(--line-height-l);
          margin-bottom: var(--spacing-zero);
        }
        &.api-gateway-import-title {
          font-size: var(--text-size-l);
          color: var(--content-color-primary);
          line-height: var(--line-height-l);
          margin-bottom: var(--spacing-zero);
          &.create-api-modal {
            font-size: var(--text-size-m);
          }
        }
      font-size: var(--text-size-xl);
      font-weight: var(--text-weight-medium);
      margin-bottom: var(--spacing-xs);
      line-height: var(--line-height-xl);
    }

    .switch-to-workspace__title{
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
      margin-bottom: var(--spacing-xs);
      line-height: var(--line-height-m);
    }

    .switch-to-workspace__subtitle {
      // check
      margin-bottom: 16px;
      text-align: center;
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
      line-height: 20px;
    }
    .entity-empty__authenticating_title {
      &.github-import-authenticating-title {
        font-size: var(--text-size-xxl);
        color: var(--content-color-primary);
        line-height: var(--line-height-xxl);
      }
    font-size: var(--text-size-xl);
    font-weight: var(--text-weight-bold);
    margin-bottom: var(--spacing-xs);
    line-height: var(--line-height-xl);
  }
    .entity-empty__subtitle {
      // check
      margin-bottom: 20px;
      text-align: left;
      &.github-import-subtitle {
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-regular);
        line-height: var(--line-height-l);
        // check
        margin-bottom: 20px;
        width: 100%;
        text-align: center;

      }
      &.reposelection-import-subtitle {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-regular);
        line-height: var(--line-height-m);
        // check
        margin-bottom: var(--spacing-l);
        width: 100%;
        white-space: nowrap;
      }
      &.api-gateway-import-subtitle {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-regular);
        line-height: var(--line-height-m);
        margin-bottom: var(--spacing-l);
        width: 100%;
        white-space: nowrap;
      }
    }

    .entity-empty__title,
    .entity-empty__subtitle {
      @include unselectable;
    }
    .entity-empty__illustration-wrapper--repository {
      margin-bottom: var(--spacing-l);
      .code-repository-empty__illustration {
        @include icon(
          $icon-not-signed-in-url,
          100px,
          100px
        );
      }
    }
    .entity-empty__illustration-wrapper--github {
      display: flex;
      align-content: stretch;
      margin-bottom: var(--spacing-m);
      // not using color token because this is needed to look white in both light and dark theme
      background-color: var(--grey-00);
      border-radius: var(--border-radius-default);
      width: 64px;
      height: 64px;
      .oauth-empty__illustration__github {
        @include icon(
          $icon-github-logo,
          56px,
          56px
        );
        margin: auto;
      }
      .oauth-empty__illustration__bitbucket {
        @include icon(
          $icon-bitbucket-logo,
          56px,
          56px
        );
        margin: auto;
      }
      .oauth-empty__illustration__gitlab {
        @include icon(
          $icon-gitlab-logo,
          56px,
          56px
        );
        margin: auto;
      }
      .oauth-empty__illustration__azure {
        @include icon(
          $icon-azure-logo,
          56px,
          56px
        );
        margin: auto;
      }
      .oauth-empty__illustration__slack {
        @include icon(
          $icon-slack-logo,
          56px,
          56px
        );
        margin: auto;
      }
    }

    .entity-empty__connect {
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;

      .repository-icon-button-override-github {
        margin-left: 2px;

        svg {
          color: rgb(22, 21, 20);
        }
      }

      .repository-icon-button-override-github-dark {
        margin-left: 2px;

        svg {
          color: white;
        }
        svg rect {
          fill: unset;
        }
      }

      .repository-icon-button-override-bitbucket {
        svg {
          color: rgb(38, 132, 255)
        }
      }

      .repository-icon-button-override-gitlab {
        svg {
          color: rgb(252, 109, 38)
        }
      }

      .repository-icon-new {
        display: flex;
        flex-direction: row;
        width: 16px; // control logo width
        height: 8px; // control logo height
        padding: var(--spacing-m);
        cursor: pointer;
        left: 0px;
        top: 0px;
        align-items: flex-start;
        margin-left: -15px;

        .repository-card-logo-wrapper.icon-dark-mode {
          background-color: transparent;
          display: flex;
          align-content: stretch;
          border-radius: var(--border-radius-default);
          width: 30px;
          min-width: 30px;
          height: 30px;

          &:hover {
          box-sizing: border-box;
          background-color: #303030;
          color: #FFFFFF;
          }
        }

        .repository-card-logo-wrapper {
          background-color: transparent;
          display: flex;
          align-content: stretch;
          border-radius: var(--border-radius-default);
          width: 30px;
          min-width: 30px;
          height: 30px;

          &:hover {
            box-sizing: border-box;
            background-color: rgb(237, 237, 237);
            color: rgb(33, 33, 33);
          }



          .repository-card-logo__gateway {
            width: 24px;
            height: 24px;
          }
          .repository-card-logo__github {
            @include icon(
              $icon-github-logo,
              24px,
              24px
            );
            margin: auto;
          }
          .repository-card-logo__bitbucket {
            @include icon(
              $icon-bitbucket-logo,
              24px,
              24px
            );
            margin: auto;
          }
          .repository-card-logo__gitlab {
            @include icon(
              $icon-gitlab-logo,
              24px,
              24px
            );
            margin: auto;
          }
          .repository-card-logo__azure {
            @include icon(
              $icon-azure-logo,
              24px,
              24px
            );
            margin: auto;
          }
        }
      }

      .repository-card {
        display: flex;
        flex-direction: row;
        width: 168px;
        height: 48px;
        padding: var(--spacing-m);
        cursor: pointer;
        left: 0px;
        top: 0px;
        align-items: center;
        border: var(--border-width-default) solid var(--border-color-strong);
        box-sizing: border-box;
        border-radius: var(--border-radius-default);
        margin-right: var(--spacing-l);
        margin-bottom: var(--spacing-l);

        .repository-card-loading-wrapper {
          display: flex;
          visibility: hidden;
          flex-direction: row;
          margin-left: var(--spacing-xxxl)
        }

        .repository-card-title {
          width: 41px;
          height: 16px;
          left: 44px;
          margin-left: var(--spacing-s);
          top: calc(50% - 16px/2);
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          /* identical to box height, or 133% */

          display: flex;
          align-items: center;
        }

        .repository-card-logo-wrapper {
          display: flex;
          align-content: stretch;
          // not using color token because this is needed to look white in both light and dark theme
          background-color: var(--grey-00);
          border-radius: var(--border-radius-default);
          width: 28px;
          height: 28px;
          .repository-card-logo__gateway {
            width: 24px;
            height: 24px;
          }
          .repository-card-logo__github {
            @include icon(
              $icon-github-logo,
              24px,
              24px
            );
            margin: auto;
          }
          .repository-card-logo__bitbucket {
            @include icon(
              $icon-bitbucket-logo,
              24px,
              24px
            );
            margin: auto;
          }
          .repository-card-logo__gitlab {
            @include icon(
              $icon-gitlab-logo,
              24px,
              24px
            );
            margin: auto;
          }
          .repository-card-logo__azure {
            @include icon(
              $icon-azure-logo,
              24px,
              24px
            );
            margin: auto;
          }
        }
      }
      .repository-card:hover {
        background: var(--background-color-tertiary);
        /* border-color/default */

        border: var(--border-width-default) solid var(--border-color-strong);
        box-sizing: border-box;
        border-radius: var(--border-radius-default);
      }
      .repository-card:hover >  .repository-card-loading-wrapper{
        visibility: visible;
      }
      .gateway-card {
        display: flex;
        flex-direction: row;
        width: 190px;
        height: 48px;
        padding: var(--spacing-m);
        cursor: pointer;
        left: 0px;
        top: 0px;
        align-items: center;
        border: var(--border-width-default) solid var(--border-color-strong);
        box-sizing: border-box;
        border-radius: var(--border-radius-default);
        margin-right: var(--spacing-l);

        .gateway-card-loading-wrapper {
          display: flex;
          visibility: hidden;
          flex-direction: row;
          margin-left: var(--spacing-xs);
        }

        .gateway-card-title {
          width: 120px;
          height: var(--size-xs);
          left: 44px;
          margin-left: var(--spacing-s);
          top: calc(50% - 16px/2);
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          /* identical to box height, or 133% */

          display: flex;
          align-items: center;
        }

        .gateway-card-logo-wrapper {
          display: flex;
          align-content: stretch;
          // not using color token because this is needed to look white in both light and dark theme
          background-color: var(--grey-00);
          border-radius: var(--border-radius-default);
          width: 28px;
          height: 28px;
          .gateway-card-logo__icon {
            width: var(--size-s);
            height: var(--size-s);
          }
        }

        .gateway-card-logo-wrapper__new-api {
          display: flex;
          align-content: stretch;
          background-color: var(--background-color-primary);
          border-radius: var(--border-radius-default);
          width: var(--size-s);
          height: var(--size-s);
          .gateway-card-logo__icon {
            width: var(--size-s);
            height: var(--size-s);
          }
        }
      }

      .gateway-card__disabled {
        @extend .gateway-card;
        cursor: default;
        background: var(--background-color-tertiary);
      }

      .gateway-card__new-api {
        @extend .gateway-card;
        margin-top: var(--spacing-m);
        padding: 6px var(--spacing-m);
        margin-right: var(--spacing-zero);
        height: auto;
        width: auto;

        .gateway-card-logo-wrapper {
          display: flex;
          align-content: stretch;
          background-color: var(--background-color-primary);
          border-radius: var(--border-radius-default);
          width: var(--size-xs);
          height: var(--size-xs);

          .gateway-card-logo__icon {
            width: var(--size-s);
            height: var(--size-s);
          }
        }

        .gateway-card-title {
          width: auto;
          height: var(--size-xs);
          left: 44px;
          margin-left: var(--spacing-s);
          top: calc(50% - 16px/2);
          font-weight: var(--text-weight-regular);
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          color: var(--content-color-primary);
          display: flex;
          align-items: center;
        }
      }

      .gateway-card__new-api__disabled {
        @extend .gateway-card__new-api;
        cursor: default;
        background: var(--background-color-tertiary);
      }

      .gateway-card:hover {
        background: var(--background-color-tertiary);
        border: var(--border-width-default) solid var(--border-color-strong);
        box-sizing: border-box;
        border-radius: var(--border-radius-default);
      }
      .gateway-card:hover >  .gateway-card-loading-wrapper{
        visibility: visible;
      }
      .import-code-connect__github {
        padding-left: var(--spacing-xs);
        padding-right: var(--spacing-xs);
      }
      .import-code-connect__icon {
        @include icon-color(var(--content-color-constant));
      }

      &__new-api {
        gap: var(--spacing-m);
      }
    }

    .entity-empty-or__import-code {
      display: flex;
      flex-direction: row;
      margin-top: var(--spacing-xl);
      margin-bottom: var(--spacing-l);

      .entity-empty-line__import-code {
        width: 56px;
        height: 0px;
        // check
        margin-top: 5px;

        /* border-color/strong */
        border-top: var(--border-width-default) var(--border-color-strong);
      }
      .entity-empty-or-text__import-code {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        margin-right: var(--spacing-m);
        margin-left: var(--spacing-m);
      }
    }

    .entity-empty__learn {
      .entity-empty__learn__mouse-click-icon {
        padding-right: var(--spacing-s);
      }
    }
  }
}

.import-code-repository-tab {
  display: flex;
  flex-direction: row;
    .import-tabs-header--code-repository-new {
      width: 40px;
      left: 312px;
      padding: var(--spacing-zero);
      // check
      border-radius: 12px;
      background-color: var(--background-color-info);
      color: var(--content-color-info);
      font-weight: var(--text-weight-medium);
      margin-left: var(--spacing-xs);
    }
}

.import-api-gateway-tab {
  display: flex;
  flex-direction: row;
  .import-tabs-header--api-gateway-new {
    width: 40px;
    left: 312px;
    padding: var(--spacing-zero);
    // check
    border-radius: 12px;
    background-color: var(--background-color-info);
    color: var(--content-color-info);
    font-weight: var(--text-weight-medium);
    margin-left: var(--spacing-xs);
  }
}

.import-via-code-repository__banner {
  display: flex;
  align-items: center;
  background-color: var(--background-color-warning);
  // check
  padding: 10px var(--spacing-l) 10px var(--spacing-l);
  margin-top: var(--spacing-l);

  .import-via-code-repository__banner__icon {
    @include icon-color(var(--content-color-warning));
  }

  .import-via-code-repository__banner__message {
    margin-left: var(--spacing-l);
    color: var(--content-color-warning);
  }
}

.import-via-code-repository__aetherbanner {
  padding-bottom: 18px;
}
