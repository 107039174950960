@import '../../mixins/inline-variable';

.request-body-editors {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.request-body-editor-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;

  // If the editor container is shrinked, the text-editor was not getting resized
  // accordingly in Firefox. This fixes the resizing issue.
  min-height: 0;

  &.is-hidden {
    display: none;
  }
}

.request-body-none-editor-wrapper {
  align-items: center;
  padding: var(--spacing-m) var(--spacing-zero);
  @include unselectable;
}

.request-body-binary-editor-wrapper {
  .request-body-binary-editor {
    padding: var(--spacing-xs) var(--spacing-m);
    display: flex;
    align-items: center;
  }
}

.request-body-formdata-editor,
.request-body-urlencoded-editor {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  .key-value-form-editor {
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default) !important;
  }

  .key-value-bulk-editor {
    border-top: none;
  }
}

.request-body-formdata-editor {
  flex: 1;
  display: flex;
  flex-direction: column;

  .key-value-editor {
    flex: 1;
  }

  .key-value-form-editor {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.request-body-urlencoded-editor {
  flex: 1;
  display: flex;

  .key-value-editor {
    flex: 1;
    width: 100%;
  }
}

.request-body-raw-editor {
  flex: 1;
  display: flex;
  position: relative;
  padding: var(--spacing-s);

  // If the editor container is shrinked, the text-editor was not getting resized
  // accordingly in Firefox. This fixes the resizing issue.
  min-height: 0;

  &.comment-available__light {
    .view-lines {
      cursor: url('../assets/images/thumbnails/select-cursor-dark.svg') 4 8, text;

      .view-line {
        span {
          cursor: url('../assets/images/thumbnails/comment-select-cursor-dark.svg') 4 8, text;
        }
      }
    }
  }

  &.comment-available__dark {
    .view-lines {
      cursor: url('../assets/images/thumbnails/select-cursor-light.svg') 4 8, text;

      .view-line {
        span {
          cursor: url('../assets/images/thumbnails/comment-select-cursor-light.svg') 4 8, text;
        }
      }
    }
  }

  @include inlineVariableText;
}

.request-body-graphql-editor {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs);

  // If the editor container is shrinked, the text-editor was not getting resized
  // accordingly in Firefox. This fixes the resizing issue.
  min-height: 0;

  .layout-2-column & {
    flex-direction: column;
  }

  .request-body-graphql-query-editor {
    flex: 1;
    margin: var(--spacing-xs);
    display: flex;
    flex-direction: column;

    // Query and Variable Editors are being provided  min-width: 0, as they being flex items, don't shrink on resize
    // because monaco takes in 100% of the parent's width and the flex item cannot shrink beyond it's content's width.
    // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size

    min-width: 0;
    min-height: 0;
  }

  .request-body-graphql-variables-editor {
    flex: 1;
    margin: var(--spacing-xs);
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 0;

    .texteditor-header {
      .info-tooltip-icon {
        display: inline-flex;
        margin-left: var(--spacing-xs);
      }
    }
  }

  .request-body-graphql-editor-ratio-resize-handle {
    z-index: 7;

    $resize-handle-width: 10px;
    $resize-handle-margin: $resize-handle-width * 0.5;

    .layout-2-column & {
      cursor: ns-resize;
      height: $resize-handle-width;
      margin: -$resize-handle-margin $resize-handle-margin;
    }

    .layout-1-column & {
      cursor: ew-resize;
      width: $resize-handle-width;
      margin: var(--spacing-xl) (-$resize-handle-margin) $resize-handle-margin;
    }
  }
}
