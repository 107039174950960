$diff-bg-color: var(--background-color-secondary);
$diff-added-color: var(--background-color-success);
$diff-removed-color: var(--background-color-error);
$source-color: var(--background-color-brand);
$dest-color: var(--background-color-info);

.pm-diff {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  width: 100%;

  &--added {
    background: $diff-added-color;
    color: var(--content-color-success);
  }

  &--removed {
    background: $diff-removed-color;
    color: var(--content-color-error);
  }

  &--scrollable {
    overflow: auto;
  }

  &-words {
    overflow-wrap: break-word;
    border: var(--border-width-default) solid var(--border-color-default);
    color: var(--content-color-primary);

    > div {
      padding-top: var(--spacing-s);
      padding-bottom: var(--spacing-s);
      padding-left: var(--spacing-s);
    }
  }

  &-sentences {
    overflow-wrap: break-word;
    font-family: var(--text-family-code); // same as monaco editor
    border: var(--border-width-default) solid var(--border-color-default);
    color: var(--content-color-primary);
    white-space: pre;
  }

  &-code {
    overflow: auto;
    border: var(--border-width-default) solid var(--border-color-default);
    border-radius: var(--border-radius-default);
    font-family: var(--text-family-code); // same as monaco editor
    color: var(--content-color-primary);

    &__index {
      display: table-cell;
      padding-left: var(--spacing-s);
      padding-right: var(--spacing-xs);
      background: $diff-bg-color;
      text-align: right;
      color: var(--content-color-tertiary);
      width: 32px;
      max-width: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__line {
      display: inline-block;
      padding: var(--spacing-xs);
      position: relative;
      border-left: var(--border-width-default) solid var(--border-color-strong);
      white-space: pre-wrap;
      margin: 0;
      color: var(--content-color-primary);
    }

    &__row {
      margin-left: var(--spacing-s);
      display: table-row;
      height: 28px;
      color: var(--content-color-primary);
    }

    .pm-conflict-actions {
      padding-left: 64px;
      margin-right: var(--spacing-l);
    }
  }

  &-table {
    overflow-wrap: break-word;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: var(--border-width-default) solid var(--border-color-default);
    text-align: left;
    color: var(--content-color-primary);

    th,
    tr,
    td {
      height: 32px; // protection against empty values
      overflow: hidden;
      text-overflow: ellipsis;
      border: var(--border-width-default) solid var(--border-color-default);
      position: relative;
      padding: 0 0 0 var(--spacing-s);
    }

    &__enabled {
      width: 5%;

      .pm-checkbox-input {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -10px);
        padding-left: var(--spacing-l);
      }
    }

    &__key {
      width: 25%;
    }

    &__value {
      width: 30%;
    }

    &__description {
      width: 40%;
    }

    th {
      text-transform: uppercase;
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
    }
  }

  &-body {
    margin-left: var(--spacing-s);
  }

  &-item__toggle {
    margin-right: var(--spacing-s);
    vertical-align: text-top;
  }

  &__label-icon {
    position: relative;
    top: 2px;
  }
}

.pm-conflict {
  position: relative;
  margin: var(--spacing-s) 0;

  &-actions {
    margin-right: var(--spacing-l);
    font-size: var(--text-size-m);
    cursor: pointer;

    &__source {
      color: var(--content-color-brand); // check
      font-weight: var(--text-weight-medium);
      font-family: var(--text-family-default);
    }

    &__dest {
      color: var(--base-color-info);
      font-weight: var(--text-weight-medium);
      font-family: var(--text-family-default);
    }

    @media (min-width: 768px) {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: 0;
    }

    .btn.btn-secondary.btn-small {
      padding: 0;
      margin-left: var(--spacing-l);
      height: 24px;
      border: var(--border-width-default) solid var(--border-color-strong);
    }
  }

  &--source {
    background: $source-color;
    min-height: 20px;
  }

  &--dest {
    background: $dest-color;
    min-height: 20px;
  }

  @media (min-width: 768px) {
    padding-right: 168px;
  }
}

.is-multiline {
  top: 14px;
  position: absolute;
  right: 0;
}
