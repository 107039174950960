.call-logs {
  &__error__wrapper {
    display: flex;
    flex: 1;
    overflow-y: auto;
  }

  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    min-height: 300px;

    &__illustration {
      @include icon(
        '../assets/images/empty-state/alert.svg',
        120px,
        120px
      );
    }

    &__content {
      text-align: center;

      &__header {
        color: var(--content-color-primary);
        margin-top: var(--spacing-l);
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-medium);
        line-height: var(--line-height-l);
      }

      &__sub-header {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        line-height: var(--line-height-m);
        margin: var(--spacing-l) var(--spacing-zero);
      }
    }

    &__retry-button {
      height: var(--size-m);
      width: 180px;
    }
  }

  &__empty-search-results {
    display: flex;
    flex: 1;

    .no-result {
      margin: var(--spacing-zero) var(--spacing-xl);
      flex: 1;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: var(--spacing-xxl) var(--spacing-zero);
    color: var(--content-color-primary);

    &__header {
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
      line-height: var(--line-height-l);
      margin-top: var(--spacing-l);
    }

    &__body {
      font-size: var(--text-size-m);
      margin-top: var(--spacing-l);
      line-height: var(--line-height-m);
      background-color: var(--background-color-secondary);
      padding: var(--spacing-l);
      border-radius: var(--border-radius-default);

      &__steps:not(:last-child) {
        margin-bottom: var(--spacing-l);
      }

      &__header {
        margin-bottom: var(--spacing-l);
      }

      &__steps {
        &__url {
          color: var(--content-color-link);
          cursor: pointer;
          word-break: break-all;
          width: fit-content;
          display:flex;
          align-items: center;
        }

        .btn {
          font-weight: var(--text-weight-regular);
        }
      }

      &__copy {
        &__button {
          height: var(--size-s);

          &__icon {
            margin-right: var(--spacing-s);
            margin-left: var(--spacing-xs);
          }
        }
      }
    }

    .learn-more-link {
      color: var(--content-color-link);
    }
  }

  &__list__wrapper {
    display: flex;
    flex: 1;
    overflow-x: auto;
    flex-direction: column;
    padding-bottom: var(--spacing-xxl);
    overflow-y: overlay;
  }

  &__list-new {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 530px;
    padding: 0 var(--spacing-l);

    &__input {
      width: 296px;
      margin-right: var(--spacing-xs);
    }

    &__header {
      margin-bottom: var(--spacing-l);
      margin-top: 26px;
      display: flex;

      &__is-loading {
        cursor: default;

        svg {
          animation: spin 1s linear infinite;
        }
      }

      &__search-bar {
        max-width: 296px;
        display: flex;
        flex: 1;
        border-radius: var(--border-radius-default);
        height: var(--size-m);
        margin-right: var(--spacing-xs);
      }
    }

    &__body {
      font-size: var(--text-size-m);
      color: var(--content-color-primary);

      &__load-more {
        text-align: center;
        padding: var(--spacing-l) var(--spacing-zero);
      }

      &__is-loading {
        opacity: 0.4;
      }

      &__header {
        padding: var(--spacing-s);
        padding-left: var(--spacing-zero);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        color: var(--content-color-secondary);
        margin-top: var(--spacing-l);
        height: 40px;
        box-sizing: border-box;

        &__time, &__request, &__response {
          display: inline-block;
          vertical-align: sub;
          line-height: var(--line-height-s);
        }

        &__time {
          width: 20%;
          min-width: 20%;
          margin: var(--spacing-zero) var(--spacing-xxl);
        }

        &__request {
          width: 35%;
          min-width: 35%;
          margin-right: var(--spacing-xxl);
        }

        &__response {
          width: 25%;
          min-width: 25%;
        }
      }
    }

    &__footer {
      padding: var(--spacing-xxl) 0;
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      color: var(--content-color-primary);
      margin: var(--spacing-xl) var(--spacing-m) var(--spacing-zero);
      font-size: var(--text-size-m);

      .learn-more-link {
        color: var(--content-color-link);
      }
    }

    &__item {
      &__details {
        padding-left: var(--spacing-xxl);
      }

      &__title {
        cursor: pointer;
        position: relative;
        padding: var(--spacing-s);
        padding-left: var(--spacing-zero);
        line-height: var(--line-height-l);
        height: 40px;
        box-sizing: border-box;

        &__request-icon {
          float: left;
          margin-right: var(--spacing-xs);
          text-transform: uppercase;

          // To override the different line height recently added to the request icon
          .collection-sidebar-list-item__request__label {
            line-height: var(--line-height-l);
          }
        }

        &__date {
          display: inline-block;
          width: 20%;
          min-width: 20%;
          margin-right: var(--spacing-xxl);
          vertical-align: middle;
        }

        &__path {
          @include text-truncate-ellipsis;

          display: inline-block;
          width: 35%;
          min-width: 35%;
          margin-right: var(--spacing-xxl);
          vertical-align: middle;
        }

        &__example-name {
          @include text-truncate-ellipsis;

          display: inline-block;
          width: 25%;
          min-width: 25%;
          vertical-align: middle;
        }

        &__error-container {
          display: inline-block;
          width: 25%;
          min-width: 25%;
          vertical-align: middle;
        }

        &__error-example-name {
          @include text-truncate-ellipsis;

          color: var(--content-color-error);
          max-width: 70%;
          display: inline-block;
          vertical-align: middle;
        }

        &__error-icon {
          vertical-align: middle;
          margin-left: var(--spacing-s);
        }

        &__error-tooltip {
          margin-left: var(--spacing-xs);
          vertical-align: middle;

          &__content {
            max-width: 224px;

            &__header {
              font-weight: var(--text-weight-medium);
            }
          }
        }

        &__arrow-icon {
          display: inline-block;
          height: var(--size-s);
          width: var(--size-s);
          text-align: center;
          margin-right: var(--spacing-s);

          i {
            vertical-align: middle;
          }
        }

        &.is-visible {
          background: var(--background-color-secondary);
          border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        }

        &.is-disabled {
          cursor: default;
        }
      }

      &__body, &__header {
        position: relative;
        padding: var(--spacing-s);
        padding-left: var(--spacing-zero);
        display: inline-block;
        cursor: pointer;
        color: var(--content-color-tertiary);
        line-height: var(--line-height-s);

        &__arrow-icon {
          margin-right: var(--spacing-m);
          display: inline-block;

          i {
            vertical-align: middle;
          }
        }

        &__title {
          vertical-align: middle;
        }

        &__loader {
          text-align: center;
          transform: scale(0.7);
        }

        &__error {
          margin-left: var(--spacing-xxl);
          color: var(--content-color-tertiary);

          &__retry {
            display: inline-block;
            cursor: pointer;
            color: var(--content-color-link);
          }
        }

        &__wrapper {
          display: inline-block;
          margin-left: var(--spacing-xxl);
          color: var(--content-color-tertiary);
          overflow-x: auto;
          overflow-y: auto;
          max-height: 240px;
          width: 90%;
          white-space: pre;
          font-family: var(--text-family-code);

          &__item {
            line-height: var(--line-height-m);
            color: var(--content-color-primary);

            &__value {
              color: var(--blue-30);
            }
          }
        }
      }

      &__body__download {
        padding-left: var(--spacing-xxl);
        color: var(--content-color-tertiary);
      }

      &__body__response {
        padding-bottom: var(--spacing-s);
      }
    }

    &__empty-state {
      padding: 0;
      max-width: 322px;
      padding-top: 38px;
    }
  }
  &__list {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 768px;

    &__header {
      margin: var(--spacing-xl) var(--spacing-l) var(--spacing-l) var(--spacing-l);

      &__title {
        display: inline-block;
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
        line-height: var(--line-height-m);
      }

      &__refresh {
        margin-right: var(--spacing-xs);
      }

      &__refresh-button {
        margin-left: auto;
        vertical-align: middle;
      }

      &__is-loading {
        cursor: default;

        svg {
          animation: spin 1s linear infinite;
        }
      }

      &__search-bar {
        max-width: 296px;
        display: flex;
        flex: 1;
        border-radius: var(--border-radius-default);
        height: var(--size-m);

        .input-search {
          margin-top: var(--spacing-zero);
        }
      }
    }

    &__body {
      font-size: var(--text-size-m);
      padding: 0 var(--spacing-l);
      color: var(--content-color-primary);

      &__load-more {
        text-align: center;
        padding: var(--spacing-l) var(--spacing-zero);
      }

      &__is-loading {
        opacity: 0.4;
      }

      &__header {
        padding: var(--spacing-s);
        padding-left: var(--spacing-zero);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        color: var(--content-color-secondary);
        height: 40px;
        box-sizing: border-box;

        &__time, &__request, &__response {
          display: inline-block;
          vertical-align: sub;
          line-height: var(--line-height-s);
        }

        &__time {
          width: 20%;
          min-width: 20%;
          margin: var(--spacing-zero) var(--spacing-xxl);
        }

        &__request {
          width: 35%;
          min-width: 35%;
          margin-right: var(--spacing-xxl);
        }

        &__response {
          width: 25%;
          min-width: 25%;
        }
      }
    }

    &__footer {
      padding: var(--spacing-xxl) 0;
      color: var(--content-color-primary);
      margin: var(--spacing-zero) var(--spacing-m) ;
      font-size: var(--text-size-m);

      .learn-more-link {
        color: var(--content-color-link);
      }
    }

    &__item {
      &__details {
        padding-left: var(--spacing-xxl);
        background: var(--background-color-secondary);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      &__title {
        cursor: pointer;
        position: relative;
        padding: var(--spacing-s);
        padding-left: var(--spacing-zero);
        line-height: var(--line-height-l);
        height: 40px;
        box-sizing: border-box;
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

        &__request-icon {
          float: left;
          margin-right: var(--spacing-xs);
          text-transform: uppercase;

          // To override the different line height recently added to the request icon
          .collection-sidebar-list-item__request__label {
            line-height: var(--line-height-l);
          }
        }

        &__date {
          display: inline-block;
          width: 20%;
          min-width: 20%;
          margin-right: var(--spacing-xxl);
          vertical-align: middle;
        }

        &__path {
          @include text-truncate-ellipsis;

          display: inline-block;
          width: 35%;
          min-width: 35%;
          margin-right: var(--spacing-xxl);
          vertical-align: middle;
        }

        &__example-name {
          @include text-truncate-ellipsis;

          display: inline-block;
          width: 25%;
          min-width: 25%;
          vertical-align: middle;
        }

        &__error-container {
          display: inline-block;
          width: 25%;
          min-width: 25%;
          vertical-align: middle;
        }

        &__error-example-name {
          @include text-truncate-ellipsis;

          color: var(--content-color-error);
          max-width: 70%;
          display: inline-block;
          vertical-align: middle;
        }

        &__error-icon {
          vertical-align: middle;
          margin-left: var(--spacing-s);
        }

        &__error-tooltip {
          margin-left: var(--spacing-xs);
          vertical-align: middle;

          &__content {
            font-size: var(--text-size-m);
            max-width: 224px;

            &__header {
              font-weight: var(--text-weight-medium);
            }
          }
        }

        &__arrow-icon {
          display: inline-block;
          height: var(--size-s);
          width: var(--size-s);
          text-align: center;
          margin-right: var(--spacing-s);

          i {
            vertical-align: middle;
          }
        }

        &__delete-button {
          position: absolute;
          right: var(--spacing-s);

          button {
            &:hover {
              background-color: var(--background-color-error);
            }
          }
        }

        &.is-even {
          background: var(--background-color-secondary);
        }

        &.is-visible {
          background: var(--background-color-secondary);
          border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        }

        &.is-disabled {
          cursor: default;
        }
      }

      &__body, &__header {
        position: relative;
        padding: var(--spacing-s);
        padding-left: var(--spacing-zero);
        display: inline-block;
        cursor: pointer;
        color: var(--content-color-tertiary);
        line-height: var(--line-height-s);

        &__arrow-icon {
          margin-right: var(--spacing-m);
          display: inline-block;

          i {
            vertical-align: middle;
          }
        }

        &__title {
          vertical-align: middle;
          color: var(--content-color-primary);
        }

        &__loader {
          text-align: center;
          transform: scale(0.7);
        }

        &__error {
          margin-left: var(--spacing-xxl);
          color: var(--content-color-tertiary);

          &__retry {
            display: inline-block;
            cursor: pointer;
            color: var(--content-color-link);
          }
        }

        &__wrapper {
          display: inline-block;
          margin-left: var(--spacing-xxl);
          color: var(--content-color-tertiary);
          overflow-x: auto;
          overflow-y: auto;
          max-height: 240px;
          width: 90%;
          white-space: pre;
          font-family: var(--text-family-code);

          &__item {
            line-height: var(--line-height-m);
            color: var(--content-color-primary);

            &__value {
              color: var(--blue-30);
            }
          }
        }
      }

      &__body__download {
        padding-left: var(--spacing-xxl);
        color: var(--content-color-tertiary);
      }

      &__body__response {
        padding-bottom: var(--spacing-s);
      }
    }
  }
}
