$input-height: var(--controls-size-default) !default;
$input--box--focus-background-color: var(--background-color-primary);
$input--box-background-color: var(--background-color-secondary);
$input--box--hover-background-color: var(--highlight-background-color-secondary);

.input-select-wrapper {
  align-items: center;
  background-color: var(--background-color-tertiary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  display: flex;
  height: $input-height;
  position: relative;
  width: 210px;

  &.highlight {
    background-color: var(--highlight-background-color-secondary);
  }
  &:hover {
    background-color: var(--highlight-background-color-secondary);
  }
  &.is-open {
    background-color: var(--background-color-primary);
    border: 1px solid var(--border-color-strong);
  }

  .input-search-group {
    flex: 1;
    background: none;
    border: 0;
    border-radius: 0;
    padding-right: 0;

    .input {
      font-size: 12px;
      @include placeholder {
        font-size: 12px;
      }
    }
  }

  .dropdown-button {
    align-self: center;
    border-left: 0;
    background: none;
    border-radius: 0;
    flex: 0 0 30px;
    height: 30px;
    margin-left: auto;
    padding: 0;

    .dropdown-caret {
      margin-left: 0;
    }
  }
  .input-select-list {
    background: var(--background-color-secondary);
    border-radius: var(--border-radius-default);
    list-style: none;
    margin: 0;
    max-height: 420px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 35px;
    width: 110%;
    z-index: 50;

    .item {
      background: none;
      box-sizing: border-box;
      color: var(--content-color-primary);
      cursor: pointer;
      font-size: 12px;
      padding: 8px;
      white-space: pre;
      overflow: hidden;
      border-radius: var(--border-radius-default);

      &.is-focused {
        background: var(--highlight-background-color-secondary);
      }

      &.is-selected {
        background:  var(--highlight-background-color-secondary);
      }

      &:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    @include material-shadow-1;
  }
}
