.drop-files-dropzone {
  display: flex;
  min-width: 100px;
  min-height: 280px;
  align-items: center;

  @include clickable;

  &.is-entered {
    background-color: var(--background-color-primary);
  }

  &.is-accepted {
    background-color: var(--background-color-secondary);
  }

  &.is-rejected {
    background-color: var(--background-color-secondary);
  }
}

.drop-files-dropzone-text {
  flex: 1;
  padding-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.drop-files-inner-container {

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .drop-files-dropzone-new-formats {
    margin-top: var(--spacing-l);
  }
}

.drop-file-import-icon {
  background-color: transparent;
  transform: scale(2);
  padding-right: 18px;
}

.drop-file-import-icon2 {
  background-color: var(--highlight-background-color-tertiary);
  padding: 5px;
  border-radius: var(--border-radius-max);
  transform: scale(2);
}

.drop-file-import-icon::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: var(--spacing-xl);
  height: var(--spacing-xl);
  background-color: var(--background-color-tertiary);
  border-radius: 50%;
  top: 50%;
  left: 24%;
  transform: translate(-50%, -50%);
}

.drop-overlay-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}
