.interceptor-domains-list-wrapper {
  display: flex;
  flex-direction: row;
  max-height: 200px;
  min-height: 200px;

  .interceptor-domains-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 8px 0px;
    border-radius: 4px;
    overflow: auto;
    background-color: var(--background-color-tertiary);
  }

  .interceptor-domain-list-item {
    display: flex;
    flex-direction: row;
    padding: 2px 4px;
    border-radius: 2px;
    align-items: center;
    height: 24px;

  .interceptor-domain-list-item-label {
    flex: 2;
    @include unselectable;
  }
  .interceptor-domain-list-item-value {
    flex: 0 0 70px;
    display: flex;
    justify-content: flex-end;
    .intereceptor-remove-domain-btn{
      cursor: pointer;
    }
  }
}
}
