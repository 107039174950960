.artemis-empty-state {
  display: flex;
  flex-direction: column;

  .artemis-empty-state__title {
    color: var(--content-color-primary);
  }

  .artemis-empty-state__asset + .artemis-empty-state__title {
    margin-top: var(--spacing-l);
  }

  .artemis-empty-state__message {
    margin-top: var(--spacing-s);
    color: var(--content-color-secondary);
  }

  .artemis-empty-state__primary-action {
    margin-top: var(--spacing-l);
    height: 32px;
    width: fit-content;
    padding-left: var(--spacing-l);
    padding-right: var(--spacing-l);

    &.margin-xl {
      margin-top: var(--spacing-xl);
    }
  }

  .artemis-empty-state__secondary-action {
    width: 250px;
    margin-top: var(--spacing-xl);
    padding: var(--spacing-zero);
  }
}
