.draft-request-source-badge {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-s);
  flex: 1;
  min-width: 100px;
  overflow: hidden;
  max-width: 240px;

  &::before {
    content: '\2022';
    margin-right: var(--spacing-s);
    margin-top: -2px;
    font-size: 14px;
    color: var(--content-color-tertiary);
  }

  &__item {
    margin-left: var(--spacing-xs);
    display: flex;
    align-items: center;
    min-width: 0;

    &.is-clickable {
      @include clickable;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
  }

  &__name {
    min-width: 0;
    margin-left: var(--spacing-xs);
    @include text-truncate-ellipsis;
  }
}
