.sidebar-error-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: var(--spacing-xxl) var(--spacing-l);

  &__illustration {
    height: 120px;
    width: 140px;
    margin-bottom: var(--spacing-l);
  }

  &__head-and-body {
    margin-bottom: var(--spacing-l);

    & > div:not(:last-child) {
      margin-bottom: var(--spacing-s);
    }
  }

  &__title {
    text-align: center;
  }

  &__description {
    text-align: center;
    display: flex;

    span {
      display: inline-block;
    }
  }
}
