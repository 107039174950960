.api-definition-home {
  height: 100%;
  overflow-y: auto;
  width: 100%;

  &__title {
    padding: var(--spacing-s);
    border-bottom: 1px solid var(--border-color-default);

    &__copy-link-icon {
      visibility: hidden;
    }

    &:hover &__copy-link-icon {
      visibility: visible;
    }
  }
}

.create-definition-block-container {
  padding-top: var(--spacing-xxl);
  margin-left: auto;
  margin-right: auto;
}

.import-definition-heading {
  padding-top: var(--spacing-xxl);
}

.create-definition-subheading {
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-xl);
}

.create-definition-block-image-mask {
  background-color: transparent;
}
