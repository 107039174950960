.embeddable-report-graph-card{
  &__title{
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    margin: var(--spacing-s) var(--spacing-zero);
  }
  &__summary{
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    display: flex;
    justify-content: space-between;

    &__disclaimer{
      color: var(--content-color-tertiary);
      font-size: var(--text-size-xs);
    }
  }

  &__loader{
    display: flex;
    justify-content: center;
  }

  &__empty{
    color: var(--content-color-primary);

    &__body{
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
    }
  }
}
