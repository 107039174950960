.team-usage-container {
  border-top: 1px solid var(--border-color-default);
  border-bottom: 1px solid var(--border-color-default);
  padding: var(--spacing-l) 0 var(--spacing-xl);
  font-family: $font-primary;

  .team-usage-item {
    margin-bottom: 10px;

    .team-usage-item-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .team-usage-item-title {
        font-size: var(--text-size-m);
        line-height: 20px;
        color: var(--content-color-primary);
      }
    }

    .team-usage-item-body {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;

      .team-usage-item-meta {
        font-size: var(--text-size-m);
        position: absolute;
        right: 0;
        display: flex;
        width: 35%;
        flex-flow: wrap;
        justify-content: flex-end;
        color: var(--content-color-secondary);
        .success {
          color: var(--base-color-success);
        }
        .warning {
          color: var(--base-color-warning);
        }
        .error {
          color: var(--base-color-error);
        }
      }

      &__progress-indicator {
        background-color: var(--content-color-tertiary);
      }

      &__progress-container {
        width: 60%;
      }
    }
  }

  .team-usage-item:last-child{
    margin-bottom: 0px;
  }
}

.team-usage-loading {
  position: absolute;
  top:50%;
  left:50%;
}

.team-usage-loading-wrapper {
  position: relative;
  margin: 0 var(--spacing-l) var(--spacing-s);
}

.team-usage-loading-wrapper.is-loading {
  opacity: 0.5;
}

.team-usage-error {
  border-top: 1px solid var(--border-color-default);
  border-bottom: 1px solid var(--border-color-default);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-usage-error-btn {
  display: flex;
  justify-content: center;
}

.team-usage-error-title {
  font-size: var(--text-size-l);
  font-weight: 600;
  color: var(--content-color-primary);
  text-align: center;
}

.team-usage-error-meta {
  font-size: var(--text-size-m);
  line-height: 20px;
  color: var(--content-color-primary);
  margin: 24px 0px 16px 0px;
  text-align: center;
}

.team-usage-item-header {
  .info-button {
    margin-left: 8px;
  }

  .info-button-v3 {
    margin-left: 8px;
    cursor: pointer;
  }
}

.info__button-markdown_tooltip {
  @include markdown();
}
