.session-tab-header {
  display: flex;
  flex-direction: column;
  padding-right: var(--spacing-l);
  align-items: flex-start;
  box-sizing: border-box;

  &__name {
    display: flex;
    flex: 1;
    min-width: 0;
    align-items: center;
    line-height: var(--line-height-m);
    @include unselectable;

    &__container {
      display: flex;
      width: 100%;
    }

    &__input {
      color: var(--content-color-primary);
      font-family: $font-primary;
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
      @include text-truncate-ellipsis;
    }

    &__wrapper {
      @include text-truncate-ellipsis;
    }

    &.is-editing {
      .session-tab-header__name__wrapper {
        width: 100%;
      }
      .inline-input__wrapper {
        width: 100%;
      }
    }

    .inline-input__wrapper {
      .input-box {
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-medium);
        padding: 2px
      }
    }

    .breadcrumb__container {
      padding-left: 3px;
    }

    .session-tab-header__edit-btn {
      @include clickable;
      background-color: transparent;
      visibility: hidden;
      width: var(--size-s);
      height: var(--size-s);
      margin-left: var(--spacing-xs);
      @include hover {
        @include icon-color(var(--content-color-primary));
      }
    }

    @include hover {
      &.editable {
        .session-tab-header__edit-btn {
          visibility: visible;
        }
      }
      .session-tab-header__copy-btn {
        visibility: visible;
      }
    }
  }

  &__actions {
    display: flex;
    margin-left: var(--spacing-xxl);
    justify-content: flex-end;
    align-items: center;
  }
}
