/* OAuth 2 Manage Tokens Modal */
$auth-form-max-width: 496px;
$auth-form-field-layout-2-col-max-width: 280px;
$auth-form-field-layout-2-col-min-width: 196px;
$auth-form-field-layout-1-col-min-width: 90px;
$auth-form-added-field-layout-2-col-min-width: 168px;
$auth-form-custom-editors-max-width: 576px; //2*$auth-form-field-layout-2-col-max-width + 16px margin

.auth-editor {
  flex: 1;

  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
  overflow: auto;
  scrollbar-gutter: stable;

  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);

  // when in 2 column layout, we have little horizontal screen estate.
  // so we put the two sections in stacked layout. the overflow, if any should
  // scroll the whole container.
  .layout-2-column & {
    flex-direction: column;
    overflow-y: auto;

    .auth-type-editor {
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      max-width: $auth-form-max-width;
    }
  }

  // in single column layout, individual sections can scroll independently.
  .layout-1-column & {
    .auth-selector-wrapper,
    .auth-type-editor {
      overflow-y: auto;
    }

    .auth-type-editor {
      border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }
  }

  .auto-suggest {
    @include variablesInlineBlock;
  }
}

.auth-editor-wrapper {
  &--inactive {
    opacity: 0.5;
  }
}

.auth-type-wrapper {
  flex: 1;
  word-wrap: break-word;
}

.auth-selector-wrapper {
  padding: var(--spacing-m);
  max-width: $auth-form-max-width;

  .layout-1-column & {
    flex: 0.35 0;
  }

  .layout-2-column & {
    flex-shrink: 0;
    display: flex;
  }

  // truncate text in dropdown button name on overflow
  .dropdown-button .btn > span {
    @include text-truncate-ellipsis;
  }
}

.auth-type-cta-wrapper {
  display: flex;

  .auth-type-cta-group {
    display: none;
    padding-top: 15px;

    &.show {
      display: flex;
    }

    .btn {
      &.btn-primary {
        height: 30px;
      }
    }
  }

  .layout-2-column & {
    flex-shrink: 0;
    padding: 10px 0 10px 10px;
  }
}

// Auth Selector styles

.auth-type-dropdown-selector {
  .dropdown-menu {
    min-width: 224px;
  }
}
.auth-type-selector {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-s) var(--spacing-zero);
  flex-wrap: wrap;

  &__label {

    .layout-1-column & {
      flex: 1;
      min-width: $auth-form-field-layout-1-col-min-width;
    }

    .layout-2-column & {
      flex: 1;
      min-width: $auth-form-field-layout-2-col-min-width;
      max-width: $auth-form-field-layout-2-col-max-width;
    }

    margin-right: var(--spacing-l);
    padding: var(--spacing-xs) var(--spacing-zero);
    @include unselectable;
  }

  &__value{

    .layout-1-column & {
      flex: 1;
      min-width: $auth-form-field-layout-1-col-min-width;
    }

    .layout-2-column & {
      flex: 1;
      min-width: $auth-form-field-layout-2-col-min-width;
      max-width: $auth-form-field-layout-2-col-max-width;
    }
  }

  // doesn't seem to be used
  .input-checkbox {
    margin-right: var(--spacing-s);
  }
}

.auth-target-description {
  padding: var(--spacing-s) var(--spacing-zero);

  &__link {
    @include clickable;
  }
}

.auth-target-input { // oauth1, oauth2, aws

  &__selector {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .layout-1-column & {

      .auth-label {
        flex: 1;
        min-width: $auth-form-field-layout-1-col-min-width;
        margin-right: var(--spacing-l);
      }
      .auth-input {
        flex: 1;
        min-width: $auth-form-field-layout-1-col-min-width;
      }
    }
    .layout-2-column & {

      .auth-label {
        flex: 1;
        min-width: $auth-form-field-layout-2-col-min-width;
        max-width: $auth-form-field-layout-2-col-max-width;
        margin-right: var(--spacing-l);
      }
      .auth-input {
        flex: 1;
        min-width: $auth-form-field-layout-2-col-min-width;
        max-width: $auth-form-field-layout-2-col-max-width;
      }
    }

    .auth-label {
      @include unselectable;

      .layout-1-column & {
        padding-bottom: var(--spacing-s);
      }
    }

    .auth-input {
      &__description {
        padding-top: var(--spacing-s);
      }
    }
  }
}

.auth-retry-input { // ntml, digest
  padding: var(--spacing-s) var(--spacing-zero);
}


// Auth Type Editor styles

.auth-type-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-m);
}

.auth-recommend-variables {
  &.is-warning {
    background-color: var(--background-color-warning);
  }

  padding: var(--spacing-s) var(--spacing-xs);
  margin-bottom: var(--spacing-l);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  @include unselectable;

  &__icon {
    margin-right: 7px;
    margin-top: 2px;
    flex-shrink: 0;
  }

  &__text {
    padding-right: var(--spacing-s);
  }

  &__dismiss-icon {
    margin-top: var(--spacing-xs);
    margin-left: auto;
    flex-shrink: 0;
    @include clickable;
  }
}

.auth-no-auth-container,
.auth-basic-auth-container,
.auth-apikey-auth-container,
.auth-bearer-token-container,
.auth-digest-auth-container,
.auth-o-auth-1-container,
.auth-o-auth-2-container,
.auth-aws-signature-auth-container,
.auth-ntlm-auth-container,
.auth-akamai-edgegrid-auth-container {
  flex: 1;
  display: flex;

  .layout-2-column & {
    flex-direction: column;
  }
}

.auth-ntlm-unavailable-container {
  margin: var(--spacing-s);
}

.auth-no-auth-container {
  align-items: center;
  text-align: left;
  padding: var(--spacing-xxl) var(--spacing-l) var(--spacing-zero);
  color: var(--content-color-secondary);

  .layout-1-column & {
    justify-content: center;
  }
}

.auth-input--add-empty-params-to-signature--wrapper, // oauth1
.auth-input--payload-hash--wrapper { // hawk
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-top: var(--spacing-l);
  padding-top: var(--spacing-xs);

  .auth-input-group:not(:last-child) {
    padding-bottom: var(--spacing-s);
  }
}

.auth-input-wrapper {
  flex-grow: 1;
}

.auth-input--token-type-dropdown {
  display: flex;
  align-items: center;

  &.is-hidden {
    display: none;
  }
}

.auth-advanced-wrapper {
  padding: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  align-items: center;

  .auth-advanced-label {
    &,
    label {
      @include clickable;
    }
  }

  .auth-advanced-toggle {
    margin: 2px var(--spacing-zero) var(--spacing-zero) -2px;

    @include clickable;
  }
}

.auth-advanced-description {
  margin: var(--spacing-xs) var(--spacing-zero);
}

.auth-input-group {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding-top: var(--spacing-s);
  align-items: center;
  flex: 1.6 4;

  .auth-label {
    display: flex;
    flex: 1;
    min-width: $auth-form-field-layout-2-col-min-width;
    max-width: $auth-form-field-layout-2-col-max-width;
    align-self: flex-start;
    padding-top: var(--spacing-m);
    margin-right: var(--spacing-l);

    .info-button__icon {
      margin-left: var(--spacing-xs);
      margin-top: 1px;
    }
  }

  .auth-input-subgroup-wrapper{
    flex: 1;
    align-items: center;
    min-width: $auth-form-field-layout-2-col-min-width;
    max-width: $auth-form-field-layout-2-col-max-width;

    .auth-input-subgroup{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
  }

  .auth-input-token-subgroup-wrapper{
    flex: 1;
    align-items: center;
    min-width: $auth-form-field-layout-2-col-min-width;
    max-width: $auth-form-field-layout-2-col-max-width;

    .auth-input-subgroup{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
  }

  .auth-input {
    display: flex;
    flex: 1;
    align-items: center;
    max-height: var(--size-m);
    min-width: $auth-form-field-layout-2-col-min-width;
    max-width: $auth-form-field-layout-2-col-max-width;
    padding-top: var(--spacing-s);

    &.disabled {
      opacity: 0.4;
    }

    .input-checkbox {
      margin-right: var(--spacing-s);
    }

    .password-input {
      width: 100%;
    }

    .dropdown {
      width: 100%;

      .btn {
        display: flex;

        span:not(.dropdown-caret) {
          flex: 1;
          text-align: left;
        }
      }
    }

    .auto-suggest__warning-btn,
    .auto-suggest__error-btn {
      top: 0;
      margin: 9px 9px 0 0;
      padding: 0;
    }

    .warning-button {
      position: absolute;
      top: 0;
      right: 0;
      margin: 9px 9px 0 0;
      z-index: 100;
    }

    .file-input {
      padding: var(--spacing-zero);
    }

    &.has-warning {
      position: relative;

      .DraftEditor-root {
        margin-right: 15px;
      }

      .auto-suggest-box {
        border: var(--border-width-default) var(--border-style-solid) var(--base-color-warning) !important;
      }
    }

    .public-DraftEditorPlaceholder-inner {
      white-space: nowrap !important;
    }

    .info-button {
      margin-left: var(--spacing-xs);
    }
  }

  .auth-input-dynamic {
    flex: 1;

    .oauth1-private-key-value {
      border-radius: var(--border-radius-default);
      min-height: 100px;
      height: 100px;
      word-wrap: normal;
      white-space: pre;
    }
  }

  .oauth2-auth-request-editor,
  .oauth2-token-request-editor,
  .oauth2-refresh-request-editor {
    margin-top: var(--spacing-l);
    max-width: $auth-form-custom-editors-max-width;
  }
}

.auth-akamai-edgegrid--timestamp-tooltip {
  display: inline;
}

.oauth2-refresh-token-cta-wrapper {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);

  .oauth2-refresh-token-info-wrapper {
    display: flex;
    align-items: center;
  }

  .oauth2-refresh-token-expiry-icon,
  .oauth2-refresh-token-expiry-info {
    margin-right: var(--spacing-xs);
  }
}

.oauth2-token-config-msg {
  margin-top: var(--spacing-xs);
  padding: var(--spacing-m);
  background: var(--background-color-warning);
  border-radius: var(--border-radius-default);

  &__title {
    margin-bottom: var(--spacing-xs);
  }

  &__action {
    margin-top: var(--spacing-s);
  }
}

// OAuth 2 Token Modal styles
.oauth2-manage-tokens-modal__modal-content {
  padding: 0 !important;
  display: flex !important;
  flex-direction: row !important;
}

.oauth2-manage-tokens-modal__modal-header {
  max-height: 64px;
  min-height: 64px;
  h3 {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-secondary)
  }
}

.oauth2-manage-tokens-modal {
  height: 80vh !important;
  max-height: 450px !important;
}

.oauth2-manage-tokens-sidebar {
  border-right: 1px solid $app-border-color;
  width: 250px;
  overflow-y: auto;
}

.oauth2-manage-tokens-description {
  width: 100%;
  overflow-y: auto;
}

.oauth2-manage-tokens-sidebar__header {
  display: flex;
  flex-direction: row;
  padding: 20px 20px 5px 20px;
  line-height: 20px;
  justify-content: space-between;
  font-weight: 600;

  .btn {
    padding: 0;
    color: var(--content-color-error);
    font-weight: 600;
  }

  @include icon-color(var(--content-color-error));
}

.oauth2-manage-tokens-sidebar__bulk-delete-actions {
  .dropdown-menu {
    min-width: 130px;
  }
}

.oauth2-manage-tokens-sidebar__token-name-wrapper {
  display: flex;
  padding: 10px 20px;
}

.oauth2-manage-tokens-sidebar__item {
  cursor: pointer;
  &:hover {
    background-color: var(--background-color-tertiary);

    .oauth2-token-delete-icon {
      display: block;
    }
  }
}

.oauth2-manage-tokens-sidebar__item--active {
  background-color: var(--background-color-tertiary);
  .oauth2-token-delete-icon {
    display: block;
  }
}

.oauth2-manage-tokens-sidebar__token-name-wrapper {
  .oauth2-manage-tokens-sidebar__token-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .oauth2-manage-tokens-sidebar__token-name--expired {
    text-decoration-line: line-through;
    color: var(--content-color-secondary);
  }

  .oauth2-token-delete-icon {
    margin-left: auto;
    display: none;
    flex-shrink: 0;
  }
}

.oauth2-token-details-wrapper {
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.oauth2-token-details-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .oauth2-token-details-header__title {
    flex: 1;
    font-weight: 600;
  }

  .oauth2-token-details-header__action-group {
    margin-left: 16px;
    flex-shrink: 0;
  }
}

.oauth2-token-details-warning {
  display: flex;
  flex-direction: row;
  justify-content: start;
  background: var(--background-color-secondary);
  padding: 16px;
  margin: -16px -16px 16px -16px;

  .oauth2-token-details-warning__icon {
    margin-right: 16px;

    .pm-icon {
      vertical-align: middle;
      @include icon-color(#A9A9A9);
    }
  }

  .oauth2-token-details-warning__message {
    line-height: 23px;
  }
}

.oauth2-token-details-item-group {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  flex-shrink: 0;
  line-height: 20px;

  .oauth2-token-details-item-group__label {
    flex: 0.4;
    color: var(--content-color-primary);

    .info-button,
    .warning-button {
      display: inline-flex;
      margin-left: 5px;
    }
  }
  .oauth2-token-details-item-group__value {
    width: 250px;
    margin-left: 32px;
    word-break: break-all;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;


    // @note this is a hack to avoid jitter when changing mode for inline-input
    // because when in input mode, input-box is added which causes line-height
    // to increase.
    .inline-input__wrapper {
      margin-top: -4px;
      margin-left: -1px;
      height: 20px;
    }

    .oauth2-token-details-item-group__value-edit-button {
      flex-shrink: 0;
      display: flex;

      .btn {
        height: auto;
      }
    }
  }
}

.oauth2-token-details-item-group--warning {
  .oauth2-token-details-item-group__label,
  .oauth2-token-details-item-group__value {
    color: var(--content-color-error);
  }
}

.oauth2-token-details-tooltip__highlight {
  font-weight: 600;
}

// OAuth2 token waiting modal styles
.oauth2-callback-waiting-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .oauth2-waiting-status {
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .oauth2-waiting-title {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    margin-bottom: 16px;
    line-height: 24px;

    .oauth2-waiting-title__success {
      color: var(--content-color-success);
    }

    .oauth2-waiting-title__error {
      color: var(--content-color-error);
    }
  }

  .oauth2-waiting-description {
    font-family: var(--text-family-default);
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-regular);
    line-height: var(--line-height-m);
    text-align: center;
    margin-bottom: var(--spacing-xl);
    margin-right: var(--spacing-xxxl);
    margin-left: var(--spacing-xxxl);
  }

  .oauth2-callback-learn-more-link {
    @include clickable;
    color: var(--content-color-link);
  }

  .oauth2-waiting-action {
    margin-bottom: var(--spacing-l);

    .btn {
      width: 160px;
    }
  }

  .oauth2-callback-return-link {
    @include clickable;
    color: var(--content-color-brand);
    margin-top: -50px;
    margin-bottom: 20px;
  }
}

// OAuth2 editor styles

.auth-oauth2--manage-tokens {
  color: var(--content-color-brand);
}

.auth-oauth2--shared-token {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  @include icon-color(var(--content-color-success));
}

.auth-oauth2--no-tokens {
  box-sizing: border-box;
  height: var(--controls-size-default);
  padding: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);

  display: flex;
  align-items: center;
}

.auth-oauth2-actions-group {
  padding-top: var(--spacing-xl);
  display: block;
  flex-direction: column;

  &__clear-cookies{
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-s);

    &__icon {
      margin-right: var(--spacing-xs);
    }

    .btn-small {
      height: var(--size-s);
    }

    .info-button {
      margin-left: var(--spacing-xs);
    }
  }
}

.auth-oauth2-token-actions-group {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid)
    var(--border-color-default);

  .auth-oauth2-share-access-token-title {
    margin-top: var(--spacing-l);
    display: flex;
    justify-content: space-between;
  }

  .auth-oauth2-refresh-access-token-title {
    margin-top: var(--spacing-l);
    display: flex;
    justify-content: space-between;
  }
}

.auth-input--refresh-token-toggle {
  margin-top: var(--spacing-xxl);
}

.auth-oauth2--key-access-token {
  flex: 1
}

.auth-oauth2--key-id-token {
  flex: 1
}

.oauth2-main-section-header {
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);

  .oauth2-main-section-header--sync-message {
    margin-top: var(--spacing-m);
    font-weight: var(--text-weight-regular);
    color: var(--content-color-tertiary);
  }
}

.oauth2-token_icon-wrapper--synced {
  margin-left: var(--spacing-xs);
  background: var(--background-color-success);
  @include icon-color(var(--content-color-success));

  @include hover {
    background: var(--background-color-success);
  }

  @include disabled {
    @include hover {
      background: var(--background-color-success);
    }
  }
}

.oauth2-token_icon-wrapper--not-synced {
  margin-left: var(--spacing-xs);
  background: var(--background-color-warning);
  @include icon-color(var(--content-color-warning));

  @include hover {
    background: var(--background-color-warning);
  }

  @include disabled {
    @include hover {
      background: var(--background-color-warning);
    }
  }
}

.auth-form-title {
  display: flex;
  justify-content: flex-start;
  margin-top: var(--spacing-l);
}

// @todo: Improve EntityEditorTabs component to handle this case
.auth-o-auth-2-container .request-editor__tabs-wrapper {
  padding: unset;
}

.oauth2-get-access-token-tabs {
  .tab-text-wrapper {
    .oauth2-access-token-tabs-badge {
      display: inline-flex;
      width: 8px;
      height: 8px;
      margin-left: 5px;
      background-color: var(--base-color-success);
      border-radius: 8px;
    }
  }
}

.auth-oauth2-input--audience {
  .auth-label {
    align-items: center;
  }

  .auth-input {
    cursor: not-allowed;
    color: var(--content-color-secondary);
    background-color: var(--background-color-secondary);

    @include hover {
      background-color: var(--background-color-secondary);
    }

    @include focus {
      background-color: var(--background-color-secondary);
    }
  }
}

.auth-oauth2-input--resource {
  .auth-label {
    align-items: center;
  }

  .auth-input {
    cursor: not-allowed;
    color: var(--content-color-secondary);
    background-color: var(--background-color-secondary);

    @include hover {
      background-color: var(--background-color-secondary);
    }

    @include focus {
      background-color: var(--background-color-secondary);
    }
  }
}

.auth-oauth2-input--advanced {
  display: block;
}

.auth-target-description__lc-banner {
  max-width: $auth-form-custom-editors-max-width;
}

.oauth2-advanced-fields {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  &__add-field-button {
    margin-top: var(--spacing-m);
  }

  &__field-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: $auth-form-field-layout-2-col-max-width;

    &__close-button {
      padding: var(--spacing-xs);
      margin-top: var(--spacing-s);
      margin-left: var(--spacing-xs);
    }

    &.added {
      .auth-input {
        min-width: $auth-form-added-field-layout-2-col-min-width;
      }
    }
  }
}

.auth-jwt-container {
  .auth-jwt-input--payload-box,
  .auth-jwt-input--header-box {
    box-sizing: border-box;
    height: 140px;
    margin: var(--spacing-xl) 0 0 0;
  }

  .auth-jwt--base64-checkbox {
    padding: 0;
  }

  .auth-jwt--header-info {
    padding-top: var(--spacing-xs);
  }

  .auth-jwt-input--header-box {
    margin: var(--spacing-s) 0 0 0;
  }

  .auth-jwt-private-key-value {
    border-radius: var(--border-radius-default);
    min-height: 100px;
    height: 100px;
    word-wrap: normal;
    white-space: pre;
  }

  .auth-label{
    align-items: center;
  }

  .auth-jwt-text-editor {
    border-radius: var(--border-radius-default);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

// Inherited Auth styles

.is-inherit {
  .auth-input {
    .auto-suggest-box {
      &.read-only {
        background-color: var(--background-color-primary);
      }
      
      border: 1px dashed var(--content-color-tertiary);
    }

    .dropdown > .dropdown-button > .btn-secondary {
      box-shadow: none;
      border: 1px dashed var(--content-color-tertiary);
      opacity: 1;
      color: var(--content-color-tertiary);

      svg {
        color: var(--content-color-tertiary);
      }
    }

    [aria-checked = "true"] {
      &[data-aether-id = "aether-toggleSwitch"] > label, [data-aether-id = "aether-checkbox"] {
        background-color: var(--content-color-tertiary);
      } 
    }

    .auth-jwt-text-editor, [data-aether-id = "aether-textInput"] {
      background-color: var(--background-color-primary);
      border: 1px dashed var(--content-color-tertiary);
    }
  }

  .textarea, [data-testid |= "input-form-gen-jsoneditor"] {
    background-color: var(--background-color-primary);
    border: 1px dashed var(--content-color-tertiary);
  }
}
