
@media (min-width: 1280px) {
  .pm-container.integrations-container {
    max-width: 100%;
  }
}

.browse-integration-categories-sidebar {
  position: sticky;
  top: 0;
  width: 270px;
  height: 100vh;
  float: left;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-xl) 0 0;
  text-transform: capitalize;

  &__back-button {
    margin-bottom: var(--spacing-l);
    &:hover {
      background-color: transparent;
    }
  }

  &__list-wrapper {
    padding: 0 var(--spacing-s);
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin-top: var(--spacing-l);
    cursor: pointer;
    & > &--item:not(:last-child) {
      margin-bottom: var(--spacing-s);
    }

    &--item {
      height: var(--size-m);
      line-height: var(--line-height-xxl);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
      padding: 0px var(--spacing-s);
      color: var(--content-color-primary);

      &-is-selected {
        background: var(--highlight-background-color-tertiary);
        border-radius: var(--border-radius-default);
      }

      &:hover {
        background: var(--highlight-background-color-tertiary);
        border-radius: var(--border-radius-default);
      }
      .name {
        float: left;
      }
      .count {
        float: right;
      }
    }
  }
}

.browse-integrations-page {
  width:  1280px;
  margin: auto;

  .input-search-group {
    margin-bottom: var(--spacing-l);
    max-width: 275px;
    min-width: 230px;
    border-radius: var(--border-radius-default);
    height: var(--size-m);
  }
  &-heading {
    font-size: var(--text-size-xxxl);
    font-weight: var(--text-weight-medium);
    line-height: var(--line-height-xxxl);
    color: var(--content-color-primary);
    padding-left: var(--spacing-s);
  }
}

.browse-integrations__list {
  overflow: hidden;
  padding: var(--spacing-zero) var(--spacing-xxl) var(--spacing-l) var(--spacing-xxl);
  position: relative;

  &--description {
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-xxl);
    padding: 0 var(--spacing-s);
  }
}

/**
  * Breakpoint-aware visibility
  */
  .visible-desktop-block {
    @media (max-width: 600px) {
      display: none;
    }
  }


.integrations-cards__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;

  & > .integrations-cards__list-item {
    margin-right: var(--spacing-xl);
  }

  &-item {
    display: flex;
    margin-bottom: var(--spacing-xl);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid var(--border-color-default);
    border-radius: var(--border-radius-default);
    cursor: pointer;

    padding: var(--spacing-l);
    width: 240px;
    min-height: 160px;

    &--heading-wrapper,
    &--heading {
      display: flex;
    }

    &--heading-wrapper {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: var(--spacing-s);
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    &--heading {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &--icon-container {
      height: 48px;
      width: 48px;
      padding:var(--spacing-xs);
      box-sizing: border-box;
      border-radius: var(--border-radius-default);
      background: var(--background-color-secondary);
      margin-bottom: var(--spacing-xs);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--icon {
      height: auto;
      width: 100%;
    }

    &--fallback-icon {
      @include icon('../assets/images/thumbnails/integration-stroke.svg',40px,40px);
    }

    &--badge {
      border-radius: 12px;
      height: var(--spacing-l);
      padding: 0px var(--spacing-s);
      font-size: var(--text-size-s);
      line-height: var(--line-height-s);
      font-weight: var(--text-weight-medium);
      background-color: var(--background-color-info);
      color: var(--content-color-info);
      text-transform: capitalize;
      &-noteworthy {
        background-color: var(--background-color-warning);
        color: var(--content-color-warning);
      }
      &-new {
        background-color: var(--background-color-info);
        color: var(--content-color-info);
      }
    }

    &--desc {
      text-align: left;
      color: var(--content-color-secondary);
    }

    &:hover {
      border: 1px solid var(--border-color-strong);
    }
  }
}

