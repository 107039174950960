.folder-editor-content {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.collection-editor-tabs,
.folder-editor-tabs {
  margin-top: var(--spacing-s);
}
