/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.pull-request {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-body {
    max-width: 90%;
  }

  &__action--decline {
    color: var(--content-color-error);

    &:hover,
    &:hover .dropdown-menu-item-label {
      color: var(--content-color-constant);
      background-color: var(--base-color-error);
    }
  }

  &__inline-comment-header {
    cursor: pointer;
    display: inline-block;
  }

  &__actions--merge {
    margin-right: var(--spacing-s);
  }

  &__body {
    padding: var(--spacing-l);
    padding-top: 0;
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-view__header {
    margin-right: var(--spacing-xl);
    margin-left: var(--spacing-xl);
    border-bottom: 1px solid var(--border-color-default);
  }

  &__actions {
    float: right;
    margin-top: var(--spacing-s);

    &-approve {
      vertical-align: bottom;
      margin-right: var(--spacing-l);
      font-size: var(--text-size-m);
    }

    &-unapprove-icon {
      vertical-align: sub;
      margin-right: var(--spacing-s);
      font-size: var(--text-size-m);
    }

    &-merge-icon {
      margin-right: var(--spacing-s);
    }

    &-dropdown {
      .btn {
        height: var(--size-m);
        width: var(--size-m);
      }

      &:hover {
        background-color: var(--highlight-background-color-secondary);
        border-radius: var(--border-radius-default);
      }
    }
  }

  &-description {
    font-size: var(--text-size-l);
    color: var(--content-color-primary);

    @media (min-width: $breakpoint-md) {
      width: 75%;
      float: left;
    }

    &-empty {
      color: var(--content-color-secondary);
    }
  }

  &-meta {
    overflow-y: auto;
    padding: var(--spacing-zero) var(--spacing-s);

    &__section {
      font-size: var(--text-size-m);
      margin-top: var(--spacing-s);

      &:not(:last-child) {
        margin-bottom: var(--spacing-xl);
      }

      &-header {
        color: var(--content-color-secondary);
        font-weight: var(--text-weight-medium);
        display: flex;
        align-items: center;
        line-height: var(--line-height-s);
      }

      p {
        color: var(--content-color-primary);
        font-weight: var(--text-weight-regular);
        line-height: var(--line-height-m);
      }
    }
  }

  &__pull-changes {
    margin: var(--spacing-l) var(--spacing-zero);

    &-link {
      font-size: var(--text-size-m);
      margin-top: var(--spacing-s);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-link);
      cursor: pointer;
    }

    &-separator {
      display: inline-block;
      border-left: 1px solid var(--border-color-default);
      vertical-align: middle;
      height: var(--spacing-xxl);
      margin-left: var(--spacing-s);
      margin-right: var(--spacing-s);
    }

    &-alert {
      .pm-alert-cta {
        max-width: 160px;
        margin-right: var(--spacing-l);
      }
    }
  }

  &__fork-label {
    margin-left: var(--spacing-xs);
    color: var(--content-color-secondary);
  }

  &-tabs {
    width: 100%;
    margin-right: var(--spacing-xl);

    &:first-child {
      margin-right: var(--spacing-s);
    }
  }

  &-tab {
    padding-left: 0;
    padding-right: 0;
    margin-right: var(--spacing-l);

    .tab-text-wrapper {
      margin-left: 0;
      margin-right: var(--spacing-xs);
      padding: var(--spacing-m) 0;
    }

    &__changes-count {
      color: var(--content-color-primary);
      background-color: var(--background-color-tertiary);
      height: var(--spacing-l);
      min-width: var(--spacing-l);
      padding: 0px;
      font-weight: 600;
      pointer-events: none;
    }

    &__changes-text {
      padding-right: var(--spacing-s);
    }
  }

  &__fetch-error {
    margin-top: 200px;
  }

  &-reviewers-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    width: 100%;
    padding-right: var(--spacing-s);

    li + li {
      margin-top: var(--spacing-s);
    }
  }

  &-settings-list {
    list-style-type: none;
    padding: 0;
    margin-top: var(--spacing-l);
    margin-left: var(--spacing-s);
  }

  &-merge-check {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-m);

    &__loading {
      position: relative;
      top: 50%;
      left: 50%;
    }

    &__section {
      height: 100%;
    }

    &__description {
      margin-left: var(--spacing-m);
      color: var(--content-color-primary);
    }

    &__empty {
      color: var(--content-color-secondary);
    }
  }

  &-reviewer {
    margin-bottom: var(--spacing-s);
    min-height: 33px;

    &-empty {
      color: var(--content-color-secondary);
      margin-top: 0;
    }

    &-label {
      white-space: nowrap;
      color: var(--base-color-brand);

      &-inline {
        white-space: nowrap;
        color: var(--base-color-brand);
        display: inline-block;
        vertical-align: middle;
        max-width: calc(
          100% - 18px
        ); // account for space for green checkmark on the right.

        span {
          position: static; // convert to static so that parent div takes width of child.
        }
      }

      span {
        position: static;
      }
    }

    &-approved {
      display: inline-block;
      margin-left: var(--spacing-s);
      vertical-align: middle;

      svg {
        path {
          fill: var(--base-color-success);
        }
        color: var(--base-color-success);
      }
    }
  }

  &-label {
    margin-bottom: var(--spacing-l);
  }

  &-user {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;

    &__label {
      display: flex;
      margin-bottom: var(--spacing-l);
      margin-top: var(--spacing-m);
    }

    &__name {
      font-size: var(--text-size-m);
      margin-left: var(--spacing-s);
      display: inline-block;
    }
  }

  &-group {
    &__icon {
      // margin-left: var(--spacing-s);
      align-items: center;
      justify-content: center;
      align-self: center;
      padding-left: var(--spacing-xs);
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);
      padding-right: var(--spacing-xs);
      background: var(--background-color-tertiary);
      border-radius: var(--border-radius-max);
    }
  }

  &__status {
    border-radius: 12px;

    &-text {
      text-transform: capitalize;
      color: var(--content-color-primary);
    }

    &-alert {
      margin: 0 !important;
      padding: 2px var(--spacing-xxl);
      height: 40px; // As per design
    }

    &-description {
      font-size: var(--text-size-m);
      color: var(--content-color-primary);
    }
  }

  &__diff-body {
    padding: 0;
    height: 100%;
  }

  &__diff-items {
    display: flex;
    align-items: center;
    margin: var(--spacing-xs) 0 var(--spacing-s) 0;
  }

  &__retry {
    width: 300px;
  }

  &__diff-comments-container {
    float: right;

    &-disabled {
      float: right;
      pointer-events: none;
      opacity: 0.4;
    }
  }

  &__diff-comments {
    margin-top: var(--spacing-xs);
    float: none;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__diff-changes {
    font-size: var(--text-size-m);
    margin-left: var(--spacing-s);
    color: var(--content-color-primary);
  }

  &__alert-text {
    margin-left: var(--spacing-xs);
    color: var(--content-color-primary);
  }

  &__diff-conflicts {
    margin-left: var(--spacing-s);
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
  }

  &__approval-count {
    border-radius: var(--border-radius-default);
    margin-left: var(--spacing-s);
    padding: 2px 4px;
    background: var(--background-modal-backdrop);
    color: var(--content-color-constant);
  }

  &-offline {
    position: relative;
    top: 25%;

    &-image {
      @include icon(
        '../assets/images/thumbnails/browse-view-offline.svg',
        160px,
        160px
      );
      margin: auto;
    }
  }

  &-entity-comments {
    float: right;
    position: relative;
    // minor correction in positioning of the icon button.
    top: -4px;

    &__label {
      margin-right: var(--spacing-l);
      cursor: pointer;
    }

    &__icon {
      vertical-align: middle;
    }

    &__text {
      color: var(--content-color-primary);
    }

    &__loading {
      display: inline-block;
      margin-left: var(--spacing-l);
      vertical-align: middle;
    }

    &__count {
      color: var(--content-color-primary);
      background-color: var(--background-color-tertiary);
      height: var(--spacing-l);
      min-width: var(--spacing-l);
      padding: 0;
      font-weight: var(--text-weight-medium);
      pointer-events: none;
      margin-left: var(--spacing-s);
    }

    &__list {
      position: absolute !important;
      right: 0;
      margin-top: var(--spacing-xl);
      background: var(--background-color-primary);
      border-radius: var(--border-radius-default);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      z-index: 800;
      box-shadow: var(--shadow-default);
      min-width: 350px;
      margin-right: var(--spacing-m);

      &:before {
        content: ' ';
        position: absolute;
        box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
        border-color: var(--background-color-primary) transparent transparent
          var(--background-color-primary);
        border-style: solid;
        border-width: 6px;
        top: -6px;
        right: 10px;
        transform: rotate(45deg);
      }
    }
  }

  &__more-options-icon {
    margin-right: var(--spacing-s);
  }

  &__merge-checks-header {
    margin-left: var(--spacing-s);
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
  }

  &__error-state {
    margin: auto;
    width: 480px;
  }
}

.merge-check {
  display: flex;
  align-items: center;
}
