.disabled-operation-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  .disabled-operation-modal__icon {
    margin-bottom: 50px;

    .disabled-operation-modal__icon__icon {
      @include icon(
        '../assets/images/empty-state/share_collection.svg',
        161px,
        133px
      )
    }
  }

  .disabled-operation-modal__title {
    font-size: 16px;
    font-weight: 600;
    color: $create-new-primary-color;
    margin-bottom: 30px;
  }

  .disabled-operation-modal__subtitle {
    font-size: 12px;
    color: $create-new-secondary-color;
    margin-bottom: 50px;
    max-width: 430px;
    text-align: center;
  }

  .disabled-operation-modal__actions {
    display: flex;

    .btn-primary {
      width: 185px;
      font-size: 14px;
      margin: 0 15px;
    }
  }
}
