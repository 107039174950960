.sidebar-list-item {
  display: flex;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--content-color-primary);
  padding: var(--spacing-zero) var(--spacing-s);

  @include hover {
    background-color: var(--highlight-background-color-secondary);

    .sidebar-list-item__right-section-actions,
    .actions-dropdown {
      .btn {
        height: var(--size-s);
      }
    }
  }

  &.active {
    background-color: var(--highlight-background-color-secondary);

    .sidebar-list-item__right-section-actions,
    .actions-dropdown {
      display: flex;

      .btn {
        height: var(--size-s);
      }
    }
  }

  @include focus {
    background-color: var(--background-color-brand);
    border-radius: var(--border-radius-default);
  }

  &.selected {
    color: var(--content-color-primary);
    background-color: var(--highlight-background-color-tertiary)
  }

  // Right meta container
  &__actions-container {
    display: flex;
    align-items: center;
    .btn {
      background-color: transparent;
      height: var(--size-s);
      width: var(--size-s);
      border-radius: var(--border-radius-s);
      @include icon-color(var(--content-color-secondary));

      &:hover {
        @include icon-color(var(--content-color-primary));
        background-color: var(--highlight-background-color-tertiary);
      }

      &.is-disabled:hover {
        background: none ;
      }
    }

    .sidebar-list-item__right-section-actions,
    .actions-dropdown {
      display: flex;
      .btn {
        height: var(--size-s);
        width: var(--size-s);
      }
    }

    .btn,
    .actions-dropdown {
      @include icon-color(var(--content-color-secondary));

      &:hover {
        @include icon-color(var(--content-color-primary));
      }
    }
  }

  &__left-actions-container {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__icon-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--size-xs);
    min-width: var(--size-s);
  }

  // Text container
  &__content-wrapper {
    display: flex;
    flex: 1;
    min-width: 0;
    margin-left: 28px;
    padding: var(--spacing-xs) var(--spacing-zero);
    align-items: center;

    & > *:not(:last-child) {
      margin-right: var(--spacing-s);
    }
  }

  // Inside text container
  &__name-wrapper {
    line-height: var(--line-height-m);
    display: flex;
    min-width: 0px;
    margin-right: var(--spacing-xs);

    @include text-truncate-ellipsis;

    .inline-input {
      height: var(--size-s);
    }

    &:hover{
      color: var(--content-color-primary);
    }

    // When 'use browser tabs' setting is enabled,
    // Removes selection when using `modifier+shift+click` to open a new tab.
    &.use-browser-tabs {
      @include unselectable;
      @include clickable;
    }
  }

  &__content-wrapper.edit-mode {
    .sidebar-list-item__name-wrapper {
      margin-right: var(--spacing-xs);
      width: 100%;
    }

    .inline-input__wrapper {
      width: 100%;
    }
  }

  // Status indicators
  &__status-indicators {
    display: flex;

    i {
      margin-right: var(--spacing-xs);
    }
  }

  &__link {
    flex: 1;
    overflow: hidden;
  }

  // InlineInput Component
  &__name {
    padding-right: var(--spacing-xs);
    @include text-truncate-ellipsis;
  }

  // Any action button component inside the .sidebar-list-item
  .sidebar-action-btn {
    background-color: transparent;
    height: var(--size-s);
    width: var(--size-s);
    border-radius: var(--border-radius-s);
    @include icon-color(var(--content-color-secondary));

    &:hover {
      @include icon-color(var(--content-color-primary));
      background-color: var(--highlight-background-color-tertiary);
    }

    &.is-disabled:hover {
      background: none ;
    }
  }
}
