.onboarding, .team-onboarding {
  $tags-input-container-min-height: 168px;
  $tags-input-container-max-height: 220px;

  $active-input-min-width: var(--spacing-xxl);
  $active-input-height: var(--spacing-xl);

  .tag {
    display: inline-table;
    vertical-align: middle;
    border-radius: var(--border-radius-default);
    margin: var(--spacing-xs);
    padding: var(--spacing-s) var(--spacing-m);
    color: var(--content-color-primary);

    &-icon,
    &-text,
    &-remove-btn {
      position: relative;
      display: table-cell;
      vertical-align: middle;
    }

    &-text {
      padding: 0 var(--spacing-s);
    }

    &-remove-btn, &-icon {
      line-height: 10px;
    }

    .text:hover {
      cursor: text;
    }

    .tag-remove-btn:hover {
      cursor: pointer;
    }

    &-regular {
      background-color: var(--background-color-secondary);
      border: solid 1px var(--border-color-strong);
    }

    &-error {
      border: 1px dashed var(--base-color-error);
      background: var(--background-color-error);
    }
  }

  .tags-input-container {
    background-color: var(--background-color-primary);
    border: var(--border-width-default) solid var(--border-color-strong);
    border-radius: var(--border-radius-default);
    min-height: $tags-input-container-min-height;
    max-height: $tags-input-container-max-height;
    overflow: auto;
    word-break: break-all;
    font-size: var(--text-size-m);
    padding: var(--spacing-s);
    position: relative;
    box-sizing: border-box;

    .tags-input-placeholder {
      position: absolute;
      top: var(--spacing-s);
      left: var(--spacing-s);
      padding: var(--spacing-s);
      color: var(--content-color-secondary);
      line-height: var(--line-height-m);
    }

    .active-input {
      display: inline-block;
      vertical-align: middle;
      line-height: var(--line-height-m);
      min-width: $active-input-min-width;
      height: $active-input-height;
      margin: var(--spacing-xs);
      padding: var(--spacing-xs);
      border: none;
      outline: none;
    }
  }

  .tags-input-component-focused {
    border-color: var(--base-color-brand);
  }

  .tags-input-component-error {
    border-color: var(--base-color-error);
  }

}
