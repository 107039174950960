@import './containers/index';
@import './WebSocketConnection/index';
@import './WebSocketMessages/index';
@import './WebSocketEditorHeader/index';
@import './WebSocketSavedMessages/index';

.websocket-tabs-badge {
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-left: var(--spacing-xs);
  background-color: var(--base-color-success);
  border-radius: 8px;
}
