.header-container {
  max-width: 900px;
  margin: 0 auto;
  padding-top: var(--spacing-xxxl);

  .header--title-container {
    margin: 0 auto;
    color: var(--content-color-primary);

    .header--title {
      text-align: center;
      font-size: var(--text-size-xxxl);
      font-weight: var(--text-weight-bold);
    }

    .header--subtitle {
      margin: var(--spacing-s) var(--spacing-s) 0px var(--spacing-s);
      text-align: center;
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
    }
  }
}
