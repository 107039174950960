.entity-meta-info {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-xl);

    &__label {
      display: flex;
      margin-bottom: var(--spacing-xs);
      @include unselectable;
    }

    &__content {
      display: flex;
      align-items: center;
      overflow: hidden;

      &__name {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
      }

      &__id {
        width: 100%;
        background-color: var(--background-color-secondary);
        border-radius: var(--border-radius-default);
        padding: 6px var(--spacing-s) 6px var(--spacing-s);
        margin-right: var(--spacing-s);
        @include text-truncate-ellipsis;
      }

      &__action {
        height: var(--size-xs);
        width: 16px;
        margin-left: var(--spacing-s);
        background-color: transparent;

        @include hover {
          background-color: transparent;
        }

        &--copy {
          @include icon-color(var(--content-color-secondary));

          @include hover {
            @include icon-color(var(--content-color-primary));
          }
        }

        &--success {
          cursor: default;
          @include icon-color(var(--content-color-success));
        }
      }

      &.user-info {
        @include unselectable;

        .user-avatar {
          margin-right: var(--spacing-s);
        }
      }

      &.is-clickable {
        @include clickable;
      }
    }
  }

  &__item:first-child {
    margin-top: var(--spacing-zero);
  }
}
