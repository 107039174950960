.proxy-modal {
  .modal-content {
    overflow: auto;
    
  }
}

.proxy-settings-connection-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.proxy-settings-connection-input {
  flex: 1;
}

.proxy-settings-connection-label {
  flex: 0 0 32px;
}

.proxy-settings-connection-target-action-label {
  flex: 1;
  margin-right: 0;
}
