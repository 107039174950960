.websocket-stream {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &.connecting {
    opacity: 0.4;
    pointer-events: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__list div div div div:first-child {
    .websocket-hidden-messages {
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      margin-top: var(--spacing-xs);
    }
  }

  &__jump-to-latest {
    display: flex;
    justify-content: center;

    &__btn {
      position: absolute;
      border-radius: 20px;
      top: 48px;
      z-index: 10;
      padding: var(--spacing-xs) var(--spacing-m);
      background-color: var(--content-color-link);
      color: var(--content-color-constant);
      font-weight: var(--text-weight-medium);
      box-shadow: var(--shadow-default);

      i {
        svg {
          color: var(--content-color-constant);
        }
      }

      @include hover {
        background-color: var(--content-color-link);
        color: var(--content-color-constant);

        i {
          svg {
            color: var(--content-color-constant);
          }
        }
      }
    }
  }
}

.websocket-stream-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.stream-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;

  &__icon {
    @include icon(
      '../assets/images/thumbnails/astronaut.svg',
      120px,
      120px
    )
  }

  &__message {
    width: 320px;
  }
}
