@mixin default-link {
  text-decoration: underline;
  @include clickable;
  &:hover {
    color: var(--content-color-link);
  }
}

@mixin subtle-link {
  @include clickable;
  &:hover {
    color: var(--content-color-link);
    text-decoration: underline;
  }
}

.options-wrapper {
  .options-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-label-description {
        display: flex;
        flex-direction: column;
        padding-right: var(--spacing-xxl)
      }
      &-label {
        flex: 2;
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        color: var(--content-color-primary);
      }
      &-description-markdown {
        @include markdown();
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-regular);
        color: var(--content-color-secondary);
        white-space: pre-wrap;
      }
      &-value {
        flex: 0 0 100px;
        display: flex;
        justify-content: flex-start;
        align-self: center;
      }
    }
  }
  .dropdown {
    width: 100%;
    // check
    height: 30px;
    background-color: var(--background-color-tertiary);

    .dropdown-button {
      display: flex;

      .btn {
        flex: 1;
        //check
        height: 30px;
        color: var(--content-color-secondary);
        @include icon-color(var(--content-color-secondary));
        justify-content: space-between;
      }
    }
  }
}

.integration-context-bar-list {
  --integration-cb-list-width: 280px;
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  padding-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);

  &__label-wrapper {
    &-label {
      line-height: var(--line-height-m);
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      @include clickable;
    }

    &-toggle {
      margin-left: 0;
      margin-right: var(--spacing-s);
      margin-top: calc(var(--spacing-xs) / 2);
      vertical-align: text-top;
      @include clickable;
      float: left;

      &:hover {
        background-color: var(--highlight-background-color-secondary);
      }
    }
  };

  &__integration-list {
    padding-left: var(--spacing-xl);
  }

  &__browse {
    padding-top: var(--spacing-l);
    padding-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2 );
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    @include default-link();
  };

  &__info {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-m);
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-regular);
    line-height: var(--line-height-m);

    &-header {
      font-weight: var(--text-weight-medium);
    }

    &-body {
      color: var(--content-color-tertiary);
    }

    &-footer {
      color: var(--content-color-tertiary);
      @include default-link();
    }
  }

  &__item {
    line-height: var(--line-height-m);
    padding-top: var(--spacing-m);
    display: flex;

    &-area {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      padding-right: var(--spacing-m);
    }

    &-label {
      font-size: var(--text-size-m);
      color: var(--content-color-primary);
      @include subtle-link();
      @include text-truncate-ellipsis;
    }

    &-last-run {
      font-size: var(--text-size-m);
      color: var(--content-color-tertiary);
    }

    &-icon {
      padding-right: var(--spacing-s);
      align-items: center;
      place-content: center;
    }
  };

  &__loading {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-l);
  };

  &__status-info {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    padding-left: var(--spacing-xl);
    margin-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
    margin-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
    max-width: var(--integration-cb-list-width);
    display: inline-flex;
  };
}

.integration-status-icon {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  @include clickable;

  .agent-selection-label {
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    margin-left: var(--spacing-xs);
  }

  .sb__item__text {
    margin-bottom: 2px;
  }

  &-error {
    @include icon-color(var(--content-color-error) !important);
  }

  &-success {
    @include icon-color(var(--content-color-success) !important);
  }
}

.banner-container {
  &__neutral {
    background-color: var(--background-color-secondary);
  }
  &__info {
    background-color: var(--background-color-info);
  }
  &__warning {
    background-color: var(--background-color-warning);
  }
  &__error {
    background-color: var(--background-color-error);
  }
  &__success {
    background-color: var(--background-color-success);
  }
  padding: var(--spacing-l);
  display: flex;
  flex-direction: row;

  .banner-content {
    padding: 0 var(--spacing-s);
  }

  &-footer {
    padding-top: var(--spacing-s);
  }
}
