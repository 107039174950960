.workspace-details-modal {
  .modal-header {
    background-color: var(--background-color-primary);

    .modal-header-close-button-wrapper {
      margin-top: 30px;
      margin-right: 20px;
    }
  }

  .modal-content {
    padding: 30px;
    padding-top: 0;
    overflow: hidden;
  }

  .workspace-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .workspace-details__name {
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 600;
      color: var(--content-color-primary);
      margin-bottom: 15px;
    }

    .workspace-details__summary {
      font-family: $font-primary;
      font-size: 14px;
      color: var(--content-color-primary);;
      margin-bottom: 8px;
      line-height: 24px;
    }

    .workspace-details__owner,
    .workspace-details__last-updated,
    .workspace-details__member-count {
      display: flex;
      align-items: center;
      flex: 0 0 40px;
      font-family: $font-primary;
      font-size: 14px;

      .workspace-details__owner__icon {
        margin-right: 15px;
      }

      .workspace-details__last-updated__icon {
        margin-right: 15px;
      }

      .workspace-details__member-count__icon {
        margin-right: 15px;
      }

      .workspace-details__owner__owner,
      .workspace-details__last-updated__last-updated,
      .workspace-details__member-count__member-count {
        font-family: $font-primary;
        font-size: 14px;
      }
    }

    .workspace-details__member-count {
      margin-bottom: 30px;
    }

    .workspace-details__members {
      flex: 1;
      overflow: hidden;

      .sortable-table {
        height: 100%;
        overflow: hidden;

        .sortable-table__rows {
          height: calc(100% - 40px);
          overflow-y: auto;

          .user__row {
            display: flex;
            align-items: center;

            .user__row__details {
              display: flex;
              flex-direction: column;
              margin-left: 16px;

              .user__row__details__name {
                font-family: $font-primary;
                font-size: 12px;
                font-weight: 600;
                color: var(--content-color-primary);
              }

              .user__row__details__email {
                font-family: $font-primary;
                font-size: 12px;
                color: var(--content-color-primary);
              }
            }
          }
        }
      }
    }
  }
}
