.finish-template {
  width: 480px;
  margin: auto;

  .loading-indicator-wrapper {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .finish-template__title {
    color: $create-new-primary-color;
    font-size: 14px;
    margin: 0 10px 10px 0;
  }

  .finish-template__cards {
    margin-top: 30px;

    .separator {
      display: flex;
      color: $create-new-placeholder-color;
      font-weight: 600;
      font-size: 12px;
      margin: 10px 0px 20px 40px;

      .separator-line {
        flex: 1;
        height: 1px;
        margin-top: 9px;
        margin-left: 10px;
        background-color: $create-separator-line-border-color;
      }
    }

    .finish-template__card__right {
      flex: 1;
      margin-bottom: 20px;
      position: relative;
      margin-left: 40px;

      &::before {
        content: '\2219';
        font-size: 40px;
        position: absolute;
        margin-left: -35px;
      }

      .finish-template__card__right__next {
        font-size: 14px;
        font-weight: 600;
        color: $create-new-primary-color;

        b {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          color: $create-new-highlight-color-blue;
        }
      }

      .finish-template__card__right__description {
        font-size: 12px;
        color: $create-new-disabled-color;

        .btn-text {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .finish-template__card {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;

      &.is-loading {
        align-items: center;
        justify-content: center;
      }

      .finish-template__card__left {
        display: flex;
        flex: 1;
        padding-right: 0;
        align-items: flex-start;
        margin-bottom: 15px;

        .finish-template__card__left__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          margin-top: 8px;
        }

        .finish-template__card__left__text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .finish-template__card__left__text__text {
            font-size: 12px;
            color: $create-new-placeholder-color;
          }
          .finish-template__card__left__text__title {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $create-new-primary-color;

            b {
              display: inline-block;
              font-weight: 600;
              max-width: 250px;
              margin-right: 3px;
              @include text-truncate-ellipsis;
            }

            span {
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
