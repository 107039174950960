@mixin inlineVariables{

  & .inlineVariable:first-child  {
    margin-left: var(--spacing-zero);
  }

  .resolvedVariable, .unresolvedVariable {
    padding: var(--spacing-zero) 2px 1px;
    margin: var(--spacing-zero) 1px;
    border-radius: var(--border-radius-default);
    font-size: 12px;
    cursor: text;
  }

  .inlineVariable + .inlineVariable
  {
    margin-left: var(--spacing-zero);
  }
  
  .unresolvedVariable {
    background: var(--background-color-error);
    color: var(--content-color-error);
    border: 1px dashed var(--variable-unresolved-border-color);
    &:hover {
      color: var(--variable-unresolved-hover-color);
      border: 1px dashed var(--variable-unresolved-hover-border-color);
    }
  }
  
  .resolvedVariable {
    background: var(--background-color-secondary);
    color: var(--content-color-info);
    border: 1px solid var(--border-color-default);
    &:hover {
      border: 1px solid var(--content-color-tertiary);
      background: var(--background-color-tertiary);
    }
  }
}

// Added to align variable in key-value and auth editor
@mixin variablesInlineBlock {
  .resolvedVariable, .unresolvedVariable {
    display: inline-block;
  }
}

// Used in monaco-editor to style the variable if supported
@mixin inlineVariableText {
  .resolvedVariableText {
    color: var(--content-color-info);
  }

  .unresolvedVariableText {
    color: var(--content-color-error);
  }
}
