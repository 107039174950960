.response-viewer-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  height: 100%;
  overflow: auto;
}

.response-viewer-wrapper--hidden {
  visibility: hidden;
}

.response-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .requester-contents-group__section-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .response-viewer__tab-contents {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.layout-2-column {
  .response-viewer {
    flex: 1;
    display: flex;
    flex-direction: column;

    // As part of Electron Upgrade + Monaco migration, a performance degradation was observed when
    // the request tab was switched from 1-pane view to 2-pane view. While switching the view
    // extensive and expensive layouts were getting triggered, if none of the parent elements
    // in the hierarchy had a fixed height. This seems to be an issue with Chromium.
    // A similar issue was reported here. https://bugs.chromium.org/p/chromium/issues/detail?id=991562
    // This workaround/hack for issue was to apply any height the element. Since this element is a
    // flex element its height is calculated from the flex-basis and not from the explicit height
    // that was assigned to it. Hence we can set an arbitrary height to this element without
    // breaking the layout in Chromium. Setting this height to 0px, so that if any changes is
    // performed from the App/Chromium side, which can potentially break this change, it will visible
    // very prominently and can be addressed.
    height: 0px;

    .requester-contents-group {
      flex: 1;
      height: 100%;
    }
  }
}

.response-viewer__section-top {
  display: flex;
  justify-content: space-between;

  .response-viewer__tabs {
    height: var(--size-m);
  }
  .response-viewer__meta {
    flex: 1;
    height: var(--size-m);
  }
}

.response-viewer__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.response-viewer__meta {
  flex-direction: row-reverse;

  // This is needed because of a bug with how flex calculates size.
  // See: https://www.w3.org/TR/css-flexbox-1/#min-size-auto
  min-width: 0;

  align-self: center;

  .input-field {
    align-items: center;
    flex: none;
  }
}

.request-loading-wrapper {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.request-loading-wrapper--hidden {
  display: none;
}
.request-loading__message {
  font-family: var(--text-family-default);
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
}

.request-loading__cancel-button {
  margin-top: var(--spacing-l);
}
