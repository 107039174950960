.homepage-pending-tasks {
  margin-bottom: var(--spacing-xxl);

  &-heading {
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
    margin-bottom: var(--spacing-l);
  }

  &-pending-invites {
    border: 0.5px solid var(--border-color-default);
    box-sizing: border-box;
    border-radius: var(--border-radius-default);
    display: flex;
    padding: var(--spacing-l);
    min-width: 320px;
    margin-bottom: var(--spacing-s);

    .pm-link {
      text-decoration: none;
      color: var(--content-color-primary);
      font-weight: var(--text-weight-regular);
    }

    // Fixed width on bigger viewports
    @media only screen and (min-width: 2200px) {
      width: 552px;
    }

    .avatar {
      margin-right: var(--spacing-s);
    }

    &-info {
      display: flex;
      flex-direction: column;
      flex: 1;

      &-description {
        color: var(--content-color-secondary);
        word-break: break-word;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
        &:hover {
          a {
            color: var(--content-color-link);
          }
        }
        a {
          text-decoration: none;
          color: var(--content-color-primary);
          font-weight: var(--text-weight-regular);
          &:hover {
            text-decoration: underline;
          }
        }

        p {
          margin-bottom: var(spacing-xs);
        }
      }

      &-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-time {
          color: var(--content-color-tertiary);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
        }

        &-text {
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          padding: var(--spacing-s) 10px;
        }
    }

    &-close {
      margin: -8px 0 0 -4px;
    }
  }
}
}
