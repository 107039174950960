.trash-label {
  &-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-disabled {
      cursor: not-allowed;
    }
  }

  &-text {
    margin-left: var(--spacing-xs);
    font-size: var(--text-size-s);

    &:hover {
      color: var(--content-color-primary);
    }

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
}
