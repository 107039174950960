.request-items-table {
  .title {
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-s);

    &-content-container {
      & > * + * {
        margin-left: var(--spacing-l);
      }
    }

    &--secondary {
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-regular);
    }
  }
}

.request-item-row {
  display: flex;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  border-radius: var(--border-radius-default);
  word-break: break-word;
  color: var(--content-color-primary);
  min-height: var(--size-m);
  border-bottom: var(--border-width-default) var(--border-style-solid)
    var(--border-color-default);

  .item-key,
  .item-value {
    overflow-wrap: break-word;
    word-break: break-word;
    padding: var(--spacing-s) var(--spacing-zero);
  }

  .item-key {
    font-weight: var(--text-weight-medium);
    flex: 1;
    margin-right: var(--spacing-s);
  }

  .item-value {
    flex: 2;
    margin-left: var(--spacing-s);
    width: 50%;

    .item-description {
      color: var(--content-color-secondary);
    }
  }

  .item-value > div:not(:last-child):not(:empty) {
    margin-bottom: var(--spacing-s);
  }
}
