.documentation-list {
  &__title {
    text-transform: uppercase;
    font-size: var(--text-size-m);
    color: var(--content-color-tertiary);
    margin-bottom: var(--spacing-m);
  }

  &__items-container {
    /**
   * Displaying the icons in grid allows us to indent all the items description
   * and icons correctly as shown below without the need to hard code any
   * min-width or max-width
   *
   * icon-1                   description-1
   * long-icon-1 .            description-2
   * long-long-long-icon-2    description-3
   */
    display: grid;
    /**
   * max-content is meant to specify that the width of the icon columns would
   * the max-width of the icon and the rest area would be taken by its description
   */
    grid-template-columns: max-content 1fr;
    row-gap: var(--spacing-l);
    column-gap: var(--spacing-s);
    padding-bottom: var(--spacing-m);
    align-items: center;
  }

  &__item-name {
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    word-break: break-word;
    min-height: var(--line-height-m);
    max-height: var(--line-height-xxxl);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
  }

  &__item-icon {
    display: inherit;
    justify-self: end;

    &.with-padding {
      padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-xs) var(--spacing-zero);
    }

    .icon-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: var(--spacing-zero);
      padding: var(--spacing-zero);
      display: inherit;

      &:active {
        outline: none;
      }
    }

    .list-icon {
      vertical-align: middle;
    }
  }

  &__folder {
    display: flex;
    justify-content: flex-end;
    padding: 2px var(--spacing-zero) 2px var(--spacing-zero);
  }

  &__folder-icon {
    display: block;

    .list-icon {
      vertical-align: unset;
    }
  }
}

.documentation-list-item__marker {
  width: 0;
  height: 0;
  align-self: center;

  &--visible {
    margin-right: var(--spacing-s);
    margin-bottom: var(--spacing-s);
    width: var(--spacing-s);
    height: var(--spacing-s);
    border-radius: var(--border-radius-max);
    background-color: var(--content-color-brand);
  }
}
