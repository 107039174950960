$example-selector-min-width: calc(10 * var(--size-s));
$example-selector-max-width: 300px;
$example-selector-max-height: calc(10 * var(--size-m));

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentation-examples {
  --dropdown-label-max-width: 165px;

  &__label-container {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-s);

    .dropdown{
      width: 70%;

      .dropdown-button{
        text-align: right;
      }
    }
  }

  &__label {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-right: 20%;
  }

  &__item-label {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin-bottom: var(--spacing-s);
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-bold);
  }

  &__example-label {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);

    &--single {
      color: var(--content-color-primary);
      width: 100%;
      text-align: right;
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-m);

      @include ellipsis;
    }
  }

  &__selector {
    .btn {
      height: auto;
      padding: var(--spacing-zero);
      max-width: 100%;
    }
  }

  &__menu-container {
    overflow: auto;
    padding: var(--spacing-xs) var(--spacing-zero);
    min-width: $example-selector-min-width;
    max-width: $example-selector-max-width;
    max-height: $example-selector-max-height;
  }

  &__menu-item {
    padding: var(--spacing-xs) var(--spacing-l) !important;
    height: auto;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__dropdown-menu {
    min-width: $example-selector-min-width;
    max-width: $example-selector-max-width;
    max-height: calc(13 * var(--size-xs));
    overflow-x: auto;

    .dropdown-menu-item {
      height: auto;
    }
  }

  &__dropdown-item {
    line-height: var(--line-height-m);
    padding: var(--spacing-xs) var(--spacing-zero);
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__snippet {
    margin-bottom: var(--spacing-m);
  }
}

.documentation-example {
  &__response {
    margin-top: var(--spacing-xxl);
  }
}

.documentation-examples-label--response {
  margin-bottom: var(--spacing-s);
}

.documentation-examples-tabs {
  margin-bottom: var(--spacing-l);
}

.documentation-examples-tab {
  & > .tab-text-wrapper {
    margin: var(--spacing-zero) var(--spacing-l) var(--spacing-zero) var(--spacing-zero);
  }
}

.docs-example-response-headers-empty,
.docs-example-response-body-empty {
  font-size: var(--text-size-m);
  line-height: var(--text-size-m);
  text-align: center;
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  padding: var(--spacing-l);

  &__title {
    margin-bottom: var(--spacing-s);
    font-weight: var(--text-weight-medium);
  }

  &__body {
    font-size: var(--text-size-s);
  }
}

.documentation-number-of-headers {
  color: var(--content-color-success);
  margin-left: var(--spacing-xs);
}
