$expandable-tooltip-background-color: var(--background-color-secondary);
$expandable-tooltip-border-color: var(--border-color-default);
$expandable-tooltip-color--primary: var(--content-color-secondary);
$expandable-tooltip-color--highlight: var(--content-color-primary);

.expandable-tooltip {
  display: flex;
  flex-direction: column;

  .expandable-tooltip__item__header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    .expandable-tooltip__item__expand {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .expandable-tooltip__item__title {
      display: flex;
      flex: 1;
    }
  }

  .expandable-tooltip__item__body--string {
    display: flex;
    align-items: center;
  }

  .expandable-tooltip__item__body--json {
    display: flex;
    align-items: flex-start;

    .expandable-tooltip__item__body__key {
      flex: 0 0 auto;
    }
  }
}

.expandable-tooltip {
  font-family: $font-primary;
  font-size: 12px;
  position: absolute;
  left: 75px;
  top: 25px;

  &.bottom:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 15px;
    left: 5px;
    border: 7px solid transparent;
    border-bottom-color: $expandable-tooltip-background-color;
    z-index: 2;
  }

  &.top:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    left: 5px;
    border: 7px solid transparent;
    border-top-color: $expandable-tooltip-background-color;
    z-index: 2;
  }

  .expandable-tooltip__item {
    border-bottom: 1px solid $expandable-tooltip-border-color;
    border-radius: 2px;

    .expandable-tooltip__item__header {
      height: 30px;
      border-bottom: 1px solid $expandable-tooltip-border-color;
      padding: 0 10px;

      .expandable-tooltip__item__expand {
        height: 100%;
        width: 30px;

        @include clickable;
      }
    }

    .expandable-tooltip__item__title {
      margin-right: 10px;
    }

    .expandable-tooltip__item__length {
      margin-left: 10px;
      color: $expandable-tooltip-color--primary;
    }

    .expandable-tooltip__item__body--string {
      height: auto;
      pre {
        font-family: $font-monospaced;
        white-space: pre-wrap;
        word-wrap: break-word;

        @include selectable;
      }

      .expandable-tooltip__item__body__unavailable {
        padding: 5px 0;
      }
    }

    .expandable-tooltip__item__body--link {
      height: auto;
      pre {
        font-family: $font-monospaced;
        white-space: pre-wrap !important;
        word-wrap: break-word !important;
        cursor: pointer !important;


        @include selectable;
      }
      :hover {
        color: var(--content-color-link);
      }

      .expandable-tooltip__item__body__unavailable {
        padding: 5px 0;
      }
    }

    .expandable-tooltip__item__body--json {
      .expandable-tooltip__item__body__key {
        padding-top: var(--spacing-xs);
        color: $expandable-tooltip-color--highlight;
        font-weight: 700;
        @include selectable;
      }

      .expandable-tooltip__item__body__value {
        padding-top: 3px;
        word-break: break-all;
        word-wrap: break-word;
        font-family: $font-monospaced;
        color: $expandable-tooltip-color--primary;
        padding-left: 5px;
        @include selectable;
      }
    }
  }

  .expandable-tooltip__body {
    position: absolute;
    left: -10px;
    width: 480px;
    max-height: 360px;
    overflow-y: auto;
    background-color: $expandable-tooltip-background-color;
    border-radius: 2px;
    z-index: 1;
    @include material-shadow-1;

    &.bottom {
      top: 30px;
    }

    &.top {
      bottom: 30px;
    }

    .expandable-tooltip__item__body {
      padding: 2px 20px;
      max-width: 480px;
    }
  }

  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    top: -13px;
    border: 7px solid transparent;
    border-bottom-color: $expandable-tooltip-background-color
  }
}
