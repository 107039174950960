$collection-browser-list-item-request-height: 30px;

.collection-browser-list-item__request {
  display: flex;
  flex-direction: row;
  height: $collection-browser-list-item-request-height;
  position: relative;

  @include hover {
    background-color: $collection-browser-list-item-head--hover-background-color;
  }

  @include unselectable;
  @include clickable;
}
