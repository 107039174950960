.key-value-bulk-editor {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  .textarea-wrapper {
    flex: 1;

    .textarea {
      .layout-1-column & {
        border-bottom: 0;
      }
    }
  }

  &.is-bleed {
    .textarea,
    .bulk-editor__controls {
      border: 0;
    }

    .bulk-editor__controls {
      padding-right: 5px; // To fix shift in Bulk / Key-value Edit button when toggled
    }

    .bulk-editor__textarea {
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

      .layout-2-column & {
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }
  }

  .bulk-editor__controls {
    display: flex;
    height: $keyvalue-row-height;
    align-items: center;
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    padding-right: var(--spacing-xs);
    padding-left: var(--spacing-s);
    justify-content: space-between;

    &.hide-presets {
      flex: 0 0 80px;
      justify-content: flex-end;
    }

    &.hide-label {
      justify-content: flex-end;
    }
  }

  .bulk-editor__header-label-container {
    min-width: 0;
    color: var(--content-color-secondary);
  }

  .bulk-editor__header-actions {
    white-space: nowrap;
    width: 200px;
    display: flex;
    justify-content: flex-end;
  }
}
