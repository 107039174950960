.codegen__modal-dropdown {
  margin-left: var(--spacing-s);
  &.dropdown {
    width: 264px;
  }

  .btn {
    display: flex;
    width: 100%;

    .codegen__selected-label {
      flex: 1;
      text-align: left;
      @include text-truncate-ellipsis;
    }
  }

  .codegen__dropdown-menu {
    max-height: 190px;
    overflow-y: auto;
    width: 264px;
  }

  .codegen__dropdown-menu-item-label {
    display: block;
    @include text-truncate-ellipsis;
  }
}

.codegen__generate-text {
  margin-top: var(--spacing-s);
  padding: var(--spacing-zero) var(--spacing-s);
  margin-bottom: var(--spacing-s);
  font-size: var(--spacing-m);
  font-weight: var(--text-weight-bold);
  color: var(--content-color-secondary);
}
.codegen__button-container {
  padding: var(--spacing-m) var(--spacing-zero);
}
.codegen__generate-button {
  margin-left: var(--spacing-s);
  height: var(--size-m);
}

.codegen__banner {
  margin-left: var(--spacing-s);

  .codegen__error-link{
    cursor: pointer;
    text-decoration: underline;
  }
}

.codegen__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.codegen__icon {
  vertical-align: middle;
}

.codegen__container {
  margin-right: var(--spacing-m);
}

.codegen__beta-label {
  width: 50px;
  padding: 2px 6px;
  border-radius: 12px;
  background-color: var(--background-color-info);
  color: var(--content-color-info);
  margin-left: var(--spacing-xs);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
}

.codegen__unsupported-label {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  margin-left: var(--spacing-s);
}

.codegen__description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  padding: var(--spacing-s);
  line-height: var(--line-height-m);

  .codegen__learn-more-link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.codegen__disabled {
  opacity: 0.4;
}

.codegen__options-container {
  padding: var(--spacing-m) var(--spacing-zero) var(--spacing-zero);
  font-size: var(--text-size-m);
  display: flex;
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);

  .codegen__options-text {
    white-space: pre;
    margin-left: var(--spacing-s);
  }

  .codegen__learn-more-link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.git-linked-codegen-container {
  padding-top: 70px;
}

.git-linked-codegen-error-text {
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}