$base-spacing-unit: 16px;
$half-spacing-unit: $base-spacing-unit*0.5;
$quarter-spacing-unit: $base-spacing-unit*0.25;
.create-new-mock-modal {
  .modal-content {
    display: flex;
    padding: 0;
    overflow: overlay;

    .create-new-mock__error {
      flex: 1;
      display: flex;

      .creation-error__icon {
        margin-top: 0px;
      }
    }

    .create-new-mock-modal__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      .create-new-mock-modal__header {
        .tabs {
          width: 100%;
          height: 50px;
          border-bottom: 1px solid var(--border-color-default);
          background-color: var(--background-color-primary);
          position: relative;
        }
      }

      .create-new-mock-modal__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: unset;

        .tabs {
          flex: 0 0 30px;
          justify-content: center;
          width: fit-content;
        }

        .create-new-mock-modal__right {
          overflow: hidden;

          .create-new-mock-modal__right__table {
            height: 100%;
            flex: 1;

            .create-new-mock-modal__data-editor {
              height: 100%;

              .data-editor {
                height: 100%;

                .data-editor {
                  overflow-y: auto;
                  height: 100%;
                }
              }

              .data-editor__cell--path {
                display: flex;
                flex: 1;

                .auto-suggest {
                  margin: var(--spacing-zero);
                }

                .prefix {
                  font-size: var(--text-size-m);
                  padding-left: var(--spacing-s);
                  line-height: var(--line-height-l);
                  color: var(--content-color-secondary);
                }
              }
            }
          }
        }

        .create-new-mock-modal__footer__helptext {
          margin-top: 20px;
        }
      }

      .step1 {
        overflow: auto;

        .entity-empty__illustration-wrapper,
        .entity-empty__subtitle {
          margin-bottom: 10px;
        }

        &.create-new-mock-modal__body {
          // Add a min-width to prevent content from shrinking on lower viewport widths as the UI looks cluttered.
          min-width: 800px;

          &.is-loading {
            min-width: auto;
          }

          padding: 20px;

          .tabs {
            border: 1px solid var(--border-color-default);
            margin-bottom: 20px;
            border-radius: var(--border-radius-default);

            .tab--collections {
              border-left: 1px solid var(--border-color-default);
              border-right: 1px solid var(--border-color-default);
            }

            .tab-primary {
              border-bottom: 0px;
              background-color: var(--background-color-tertiary);

              &.is-active {
                background-color: var(--background-color-primary);
                color: var(--content-color-brand);

                .tab-text-wrapper {
                  box-shadow: none;
                }
              }
            }
          }

          .create-new-mock-modal__right__intro {
            font-size: 12px;
            line-height: 16px;
            color: var(--content-color-secondary);
            width: 100%;
            margin-bottom: var(--spacing-s);

            .btn-text {
              line-height: 16px;
              padding: 0 3px;
            }

            &__toggle {
              float: right;
              position: relative;
              top: -8px; // To be removed once DSUI fixes Dropdown placement issue
            }
          }

          .create-new-mock-modal__right {
            flex: 1;

            .create-new-mock-modal__footer__helptext {
              color: var(--content-color-primary);
              margin-top: 20px;

              .btn-text {
                height: 0px;
              }
            }

            .create-new-mock-modal__data-editor {
              margin-right: 1px;

              .data-editor__cell--path {
                .prefix {
                  display: flex;
                  align-items: center;
                }
              }

              .dropdown-button {
                .btn {
                  height: 28px;
                }
              }

              .DraftEditor-editorContainer {
                .public-DraftEditor-content {
                  height: 20px;
                }
              }
            }

            .data-editor {
              display: flex;
              flex-direction: column;

              .data-editor__header {
                margin-right: 12px;
                flex: 0 0 30px;
              }

              .data-editor__rows {
                overflow-y: scroll;
              }

              .data-editor__header {
                .data-editor__header__cell {
                  font-size: 11px;
                  color: var(--content-color-primary);
                  font-weight: 600;

                  .data-editor__header__cell__title {
                    @include text-truncate-ellipsis;
                  }
                }

                .create-new-mock-modal__test-header {
                  overflow: hidden;

                  .input-checkbox {
                    min-width: 16px;
                  }

                  span {
                    @include text-truncate-ellipsis;
                  }
                }
              }

              .data-editor__row {
                .data-editor__reorder {
                  width: 14px;
                }

                .dropdown {
                  margin: 0 5px;
                }

                .dropdown-button {
                  .btn {
                    background-color: transparent;
                    padding: 0;
                  }
                }

                .data-editor__cell--time {
                  display: flex;
                  flex: 1;
                  margin-right: 30px;

                  .postfix {
                    font-size: 12px;
                    padding-left: 5px;
                    line-height: 25px;
                    color: var(--content-color-secondary);;
                  }
                }

                .data-editor__custom-row {
                  &.is-focused,
                  &:hover {
                    .data-editor__cell--time {
                      margin-right: 0;
                    }
                  }
                }

                &:last-child {
                  .data-editor__custom-row {
                    &.is-focused,
                    &:hover {
                      .data-editor__cell--time {
                        margin-right: 30px;
                      }
                    }
                  }
                }
              }
            }

            .create-new-mock-modal__test-header {
              display: flex;

              .input-checkbox {
                margin-right: 5px;
              }
            }

            .create-new-mock-modal__right__table {
              flex: 1;
            }

            .create-new-mock-modal__card {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              flex: 0 0 224px;
              height: 40px;
              padding: 10px;
              cursor: pointer;
              border-radius: var(--border-radius-default);

              @include hover {
                background-color: var(--highlight-background-color-primary);
              }

              .create-new-mock-modal__card__icon {
                margin-right: 10px;
                flex: 0 0 22px;
              }

              .create-new-mock-modal__card__title {
                display: flex;
                align-items: center;
                max-width: 176px;

                span {
                  color: var(--content-color-primary);
                  @include text-truncate-ellipsis;
                  font-size: 14px;
                }

                .collection-meta-icons {
                  overflow: visible;
                }
              }
            }

            .create-new-mock-modal__right__collections {
              display: grid;
              box-sizing: border-box;
              grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
              border: var(--border-width-default) solid var(--border-color-default);
              overflow: auto;
              max-height: 100%;
              padding: 10px;
            }

            .assign-mock-collection__entity-empty {
              text-align: center;
              color: var(--content-color-secondary);
    
              &__title {
                font-size: var(--text-size-xl);
                font-weight: var(--text-weight-bold);
                margin-bottom: var(--line-height-m);
                margin-top: var(--spacing-l);
              }
    
              &__subtitle {
                font-size: var(--text-size-m);
                margin-top: var(--line-height-m);
              }
            }
          }
        }
      }

      .step2 {
        &.create-new-mock-modal__body {
          display: flex;
          flex-direction: column;
          padding: 20px;
          max-width: 900px;
          margin: 0 auto;
          // Add a min-width to prevent content from shrinking on lower viewport widths as the UI looks cluttered.
          min-width: 800px;

          &.is-loading {
            min-width: auto;
          }

          .create-new-mock__body__input-group {
            margin-bottom: 10px;

            .create-new-mock__body__label {
              font-size: 12px;
              line-height: 36px;

              &.is-error {
                color: var(--base-color-error);
              }
            }

            .create-new-mock__body__input {
              display: flex;

              .create-new-mock__body__input__helptext {
                margin-top: -4px;
                padding-left: 20px;

                // To be removed once Text component supports customizing text width using prop
                &__content {
                  max-width: 100%;
                }
              }

              .create-new-mock__body__field {
                display: flex;
                flex: 0 0 500px;
                margin-bottom: auto;
                height: 100%;

                .input-box__error {
                  border: var(--border-width-default) var(--border-style-solid) var(--content-color-error);
                  background-color: var(--background-color-primary);
                }
              }

              .input-checkbox {
                margin-right: 5px;
              }
            }

            .create-new-mock__body__input-error {
              color: var(--base-color-error);
              font-size: 11px;
            }

            .create-new-mock__body__show-more {
              .advanced-section-toggle {
                margin-left: -10px;
              }

              .advanced-section {
                .advanced-section {
                  margin: 10px 0;

                  &__field-error {
                    color: var(--base-color-error);
                    font-size: 11px;
                  }

                  .advanced-section__checkbox {
                    display: flex;
                  }

                  .advanced-section__field {
                    display: flex;
                    align-items: center;

                    .input-field {
                      margin: 5px 10px 5px 25px;

                      input {
                        flex: 0 0 300px;
                      }
                    }

                    .advanced-section__field__meta {
                      margin: 5px 25px;
                      font-size: 11px;
                    }

                    &.advanced-section__field--notifications {
                      display: block;
                    }

                    &--notification-limit {
                      .input-field {
                        display: inline-block;
                        margin-left: 10px;
                        width: 60px;

                        input {
                          text-align: center;
                        }
                      }

                      .input-error-section {
                        top: 3px;
                        margin-top: 0px;
                        display: inline-block;
                      }
                    }

                    &.advanced-section__field--timeout,
                    &.advanced-section__field--delay {
                      .input-field {
                        flex: 0 0 150px;

                        input {
                          flex: 0 0 150px;
                        }
                      }
                    }
                  }

                  &__select {
                    margin: 5px 10px 5px 25px;
                    display: flex;
                    align-items: center;

                    &--retry > * {
                      margin-right: 10px;
                    }
                  }

                  .advanced-section__checkbox__label {
                    margin-left: 10px;
                  }

                  &__checkbox-label-helper {
                    font-size: 10px;
                  }
                }
              }
            }

            .create-new-mock__body__select-group--regions {
              white-space: pre-line;
              margin-bottom: 8px;
              width: fit-content;
            }

            .create-new-mock__body__select-group--regions__radio {
              margin-right: 5px;
            }

            .create-new-mock__body__input--help {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              cursor: pointer;
            }

            .create-new-mock__body__subtext {
              color: var(--content-color-secondary);
              font-size: 12px;
              line-height: 24px;

              .btn-text {
                padding: 0 3px;
              }
            }

            &.create-new-mock__body__input-group--private {
              margin-top: 10px;
            }

            &.create-new-mock__body__input-group--create-environment {
              margin-bottom: var(--spacing-xl);
            }

            .mock-name-empty__msg-container {
              margin-top: var(--spacing-xs);

              &__text {
                color: var(--content-color-error)
              }
            }
          }

          .create-new-mock__body__input-group--frequency {
            .create-new-mock__body__input {
              .dropdown {
                margin-right: 10px;
              }
            }
          }

          .create-new-mock__body__input-group--regions {
            .create-new-mock__body__input {
              &.create-new-mock__body__input-regions--auto {
                display: none;
              }

              &.create-new-mock__body__input-regions--manual {
                display: flex;
                padding-top: 12px;
              }

              .create-new-mock__body__field {
                flex-wrap: wrap;
              }

              .create-new-mock__body__input__checkbox {
                display: flex;
                width: 500px;
                height: 40px;
                color: var(--content-color-primary);
                margin-top: 8px;

                .input-checkbox {
                  margin-left: 16px;
                  margin-right: 8px;
                }
              }

              .create-new-mock__body__input__checkbox-icon {
                display: inline-block;
                padding-left: 4px;
                vertical-align: -2px;
                @include icon-color(var(--content-color-tertiary));
              }

              .create-new-mock__body__input__checkbox-region {
                height: 18px;
              }
            }
          }
        }
      }

      .is-loading {
        justify-content: center;
      }

      .create-new-mock__body__field__version-dropdown {
        width: 500px;

        &-button {
          @include text-truncate-ellipsis;
        }
      }

      .step3 {
        .create-new-mock-modal__card__center{
          width: 480px;
          margin: 0 auto;
        }

        &.create-new-mock-modal__body {
          padding: 30px 0;

          &.is-error {
            justify-content: center;
          }

          .loading-indicator-wrapper {
            display: flex;
            flex: 1;
            justify-content: center;
          }

          .create-new-mock-modal__content__header {
            display: flex;
            margin-bottom: 20px;

            .create-new-mock-modal__content__header__icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 28px;
              height: 28px;
              margin-right: 12px;
            }

            .create-new-mock-modal__content__header__text {
              flex: 1;

              .create-new-mock-modal__content__header__text__title {
                font-size: 14px;
                line-height: 20px;
                color: var(--content-color-primary);

                b {
                  @include text-truncate-ellipsis;
                  font-weight: 600;
                }
              }

              .create-new-mock-modal__content__header__text__text {
                font-size: 12px;
                color: var(--content-color-tertiary);
              }
            }
          }

          .separator {
            display: flex;
            color: var(--content-color-tertiary);
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 20px;
            margin-left: 40px;
            padding-top: 10px;

            .separator-line {
              flex: 1;
              height: 1px;
              margin-top: 9px;
              margin-left: 10px;
              background-color: $create-separator-line-border-color;
            }
          }

          .create-new-mock-modal__content__footer {
            position: relative;

            .create-new-mock-modal__step {
              margin-bottom: 20px;
              position: relative;
              margin-left: 40px;

              &::before {
                content: '\2219';
                font-size: 40px;
                position: absolute;
                margin-left: -33px;
                top: 2px;
                color: var(--content-color-primary);
              }

              .create-new-mock-modal__step__title {
                color: var(--content-color-primary);
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
              }

              .create-new-mock-modal__step__text {
                color: var(--content-color-secondary);
                font-size: 12px;

                .btn-text {
                  padding-left: 0;
                }

                &__link{
                  font-weight: var(--text-weight-regular);
                }

                // To be removed once Text component supports customizing text width using prop
                &__content {
                  max-width: 100%;
                }

                .mock-url {
                  &.btn-text {
                    text-align: left;
                  }
                }
              }
            }
          }

          .create-new-mock-modal__content__header__helper-text {
            color: var(--content-color-secondary);
            font-size: 12px;
            word-break: break-word;

            .create-new-mock-modal__content__header__helper-text__entity-name {
              font-weight: 600;
            }
          }

          .create-new-mock-modal__content__header__text__mock-call-logs {
            color: var(--content-color-secondary);
          }

          .create-new-mock-modal__content__header__text__mock-call-logs__url {
            padding: 0px;
          }

          .create-new-mock-modal__content__header__text__mock-call-logs__url__icon {
            vertical-align: middle;
            padding-left: $quarter-spacing-unit;
            height: 10px;
            width: 10px;

            svg {
              height: 10px;
              width: 10px;
            }
          }

          .create-new-mock-modal__step__copy-button {
            height: $half-spacing-unit*3;
            padding: 0px $half-spacing-unit 0px $quarter-spacing-unit;
            font-size: var(--text-size-s);
          }

          .create-new-mock-modal__step__copy-button__icon {
            margin-right: $half-spacing-unit;
            height: 14px;
            width: 14px;
            vertical-align: middle;

            svg {
              height: 14px;
              width: 14px;

              use {
                fill: var(--content-color-tertiary);
              }
            }
          }

          .create-new-mock-modal__content__footer__title {
            color: var(--content-color-secondary);
            margin-left: 42px;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 24px;
          }

          .create-new-mock-modal__footer__helptext {
            color: var(--content-color-secondary);
            margin-left: 42px;
          }

          .create-new-mock-modal__step__text__bold {
            font-weight: 600;
          }
        }
      }

      &.enable_scrolling_tab {
        overflow: auto;
      }
    }
  }

  .modal-footer {
    padding-top: 20px;
    flex: 0 0 80px;
    border-top: 1px solid $create-new-card-border-color;

    .create-new-mock-modal__footer {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      font-size: 12px;
      position: relative;

      .create-new-mock-modal__footer__info {
        flex: 1;
        align-self: center;
        margin-left: 150px;
      }

      .create-new-mock-modal__footer__note {
        font-weight: 600;
        color: $create-new-highlight-color-blue;
        margin-right: 10px;
      }

      .create-new-mock-modal__footer__text {
        color: var(--content-color-primary);

        .btn-text {
          padding: 0 3px;
          margin-bottom: 1px;
        }
      }

      .btn-secondary {
        margin-right: 10px;
      }
    }
  }

  .modal-header {
    flex: 0 0 45px;
    background-color: var(--background-color-primary);
    border-bottom: 1px solid var(--border-color-default);

    .modal-header-title {
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 0;
      padding-left: 0;

      .tabs {
        flex: 1;
        height: 100%;

        .tab {
          display: flex;
          padding: var(--spacing-zero);
          font-size: 12px;
          line-height: 24px;
          height: 100%;
          cursor: default;

          &.is-active {
            @include hover {
              color: var(--content-color-primary);
            }
          }

          &.is-done {
            color: var(--content-color-secondary);
            @include icon-color(var(--content-color-secondary));
            @include hover {
              color: var(--content-color-primary);
              @include icon-color(var(--content-color-primary));
            }

            span {
              padding-left: 12px;
            }
          }
        }
      }

      .btn-text {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .permissions-loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}


.cnx-mock-delay-selector {
  margin-top: 40px;
  width: 100%;

  .cnx-mock-delay__checkbox {
    display: flex;
    margin-top: $base-spacing-unit;
  }

  .cnx-mock-delay__checkbox-label {
    cursor: pointer;
  }

  .cnx-mock-delay__options {
    margin-left: 5px;
    min-width: 476px;

    .cnx-delay-duration-type-dropdown {
      margin-top: 12px;
      height: 32px;
      width: 476px
    }

    .cnx-delay-duration__input-wrapper {
      margin-top: 12px;
      margin-bottom: 32px;

      .cnx-delay-duration__input {
        display: flex;
        width: fit-content;

        .cnx-delay-duration__input-unit {
          margin: 6px auto;
          align-self: center;
          height:100%;
          justify-content: center;
          position: relative;
          left: 6px;
          cursor: default;

          &.is-disabled {
            color: var(--content-color-tertiary);
          }
        }

        .cnx-delay-duration__input-field {
          &.is-disabled {
            cursor: default;
            opacity: 0.4;
          }
        }
      }
    }

    .cnx-delay-duration__error {
      display: flex;
      color: var(--base-color-error);
    }
  }

  .cnx-mock-delay__helptext {
    margin-top: -4px;
    padding-left: 20px;
    color: var(--content-color-tertiary);
  }
}
