.api-doc-offline-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__heading {
    margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
  }
}
