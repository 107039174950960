.capture-panel-header {
  padding: 10px;
  .capture-panel-header__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: var(--content-color-primary);
  }
}

.capture-settings-tabs{
  border-bottom: 1px solid var(--border-color-default);
}

.capture_requests_learn_more {
  padding-bottom: 16px;
  .external-link {
    padding-left: 2px;
    margin: 0px;
    color: var(--content-color-link);
    cursor: pointer;
  }
}

.capture-requests-source-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.capture-container {
  font-size: var(--text-size-m);
  position: relative;
  display: inline-block;
  z-index: 100; // has to be greater than z-index of topbar
}

.capture_request_source_switcher {
  padding-bottom: 16px;
}

.capture-icon {
  &__nudge {
    &:after {
      content: '';
      background-color: var(--base-color-brand);
      position: absolute;
      border-radius: var(--border-radius-max);
      top: 4px;
      left: 22px;
      height: 7px;
      width: 7px;
    }
  }
}

// Negative properties via var is
// possible only by wrapping it in calc
// https://github.com/css-modules/postcss-icss-values/issues/64
.capture-icon-tab-stretch {
  margin: calc(-1 * var(--spacing-l));
}
.capture-modal-fixed-control {
  // Negative properties to compensate the margins acquired by
  // Tabs inside Tabs and support the capture modal layout
  // margin: -16px -16px 16px -16px
  margin: calc(-1 * var(--spacing-l)) calc(-1 * var(--spacing-l)) var(--spacing-l) calc(-1 * var(--spacing-l));
}

.capture-request-body-container {
  overflow-y: overlay;
  overflow-x: hidden;
  padding-right: var(--spacing-l);
  padding-left: var(--spacing-xs);
  margin-right: calc(-1 * var(--spacing-l));
}

.capture-modal-body-size {
  // Sass does not support max and min
  // because it has no way to know the browser width or height
  // https://github.com/sass/libsass/issues/2701
  // https://sass-lang.com/documentation/syntax/special-functions#min-and-max
  max-height: unquote("min( 80vh - 240px, 45vh)");
}

.additional-filters-area {
  margin-right: var(--spacing-xs)
}
