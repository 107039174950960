.workspace-activity-item-wrapper  {
  display: flex;
  margin-bottom: var(--spacing-s);
}

.workspace-activity-item-content {
  width: 100%;
  &:hover {
    a {
      color: var(--content-color-link);
    }
  }
  a {
    text-decoration: none;
    color: var(--content-color-primary);
    font-weight: var(--text-weight-regular);
    &:hover {
      text-decoration: underline;
    }
  }
}

.workspace-activity-feed-connect-line {
  position: relative;
  top: 20px;
  left: 26px;
  z-index: -1;
  border-left: 1px solid $app-border-color;
}

.workspace-activity-item-header {
  display: flex;

  .workspace-activity-item-head {
    flex: 1;
    margin-right: 20px;

    blockquote {
      padding-left: 16px;
      margin-left: 0px;
      border-left: 3px solid var(--border-color-default);
      color: var(--content-color-secondary);
    }

    .workspace-activity-item-heading {
      word-break: break-all;
      
      p {
        margin: var(--spacing-zero);

        strong {
          font-weight: 600;
        }
      }
    }
  }
}

.workspace-activity-item-body {
  padding: 10px;
  margin-left: 50px;
  border: 1px solid var(--border-color-strong);
}

.workspace-activity-item-details-wrapper {
  padding: 0 10px;
}

.workspace-activity-item-details {
  border-left: 3px solid var(--border-color-strong);
  margin-left: 14px;
  padding-left: 13px;
}

.workspace-activity-item-profile-pic-wrapper {
  padding-right: var(--spacing-s);
  display: flex;
  align-items: baseline;
  margin-top: var(--spacing-xs);

  .pm-user-avatar-icon-NaN {
    background-image: none;
  }
}

.workspace-activity-item-meta {
  color: $workspace-activity-feed-meta-content-color;

  .workspace-activity-item-user {
    font-weight: 500;
    color: $workspace-activity-feed-meta-content-color;
    margin-right: 5px;
  }
}

.workspace-activity-item-heading {
  color: var(--content-color-secondary);
}

.workspace-activity-item-user,
.workspace-activity-item-folder,
.workspace-activity-item-response,
.workspace-activity-item-request-name,
.workspace-activity-item-collection-name {
  font-weight: 600;
  color: $workspace-activity-feed-font-color;
}

.workspace-activity-item-user {
  font-size: 11px;
}

.workspace-activity-item-restore {
  margin-left: 5px;
  color: var(--content-color-link);
  display: inline-block;

  &.workspace-activity-item-restore--is-restoring {
    display: inline-block;
  }

  @include unselectable;
  @include clickable;
}

.workspace-activity-item-private-user {
  p strong:first-child a {
    pointer-events: none;
    cursor: default;
  }
}
