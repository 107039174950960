/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.free-plan-banner {
  background-color: var(--background-color-secondary);
  margin-bottom: var(--spacing-xxl);
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-l) var(--spacing-l);
  border-radius: var(--border-radius-default);
  position: relative;
  box-sizing: border-box;

  .description {
    margin: var(--spacing-m) var(--spacing-xxl) var(--spacing-m) var(--spacing-zero);
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    display: block;
  }

  .btn-secondary {
    margin-top: var(--spacing-s);
    padding: var(--spacing-s) var(--spacing-m);
    border: 1px solid rgba(48,48,48,0.2);
    color: var(--content-color-primary);
  }

  .btn-tertiary {
    position: absolute;
    top: var(--spacing-s);
    right: var(--spacing-s);
  }

  .link {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin-left: var(--spacing-l);
    color: var(--content-color-secondary);
  }

  .illustration {
    position: absolute;
    right: 0px;
    bottom: 0px;

    svg {
      width: 216px;
      height: 72px;
    }
  }
}
