.event-badge {
  display: flex;
  align-items: center;
  max-width: 120px;

  &__name {
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    font-family: var(--text-family-default);
    font-weight: var(--text-weight-medium);

    @include text-truncate-ellipsis;
  }

  &__name-wrapper {
    display: flex;
    align-items: center;
    padding: var(--spacing-zero) var(--spacing-s);
    height: 20px;
    box-sizing: border-box;
    border: var(--border-width-default) var(--border-style-solid);
    border-radius: var(--border-radius-default);
    overflow-x: hidden;

    --perceived-lightness: calc(var(--r)*0.2126/255 + var(--g)*0.7152/255 + var(--b)*0.0722/255);

    &--light {
      --lightness-threshold: 0.6;
      --background-alpha: 0.18;
      --border-alpha: 0.3;
      --lightness-switch: max(0, min(calc(var(--perceived-lightness)*-1000 - var(--lightness-threshold)*-1000), 1));
      --lighten-by: calc((var(--lightness-threshold) * 100 - var(--perceived-lightness) * 100) * var(--lightness-switch));

      background-color: rgba(
        var(--r),
        var(--g),
        var(--b),
        var(--background-alpha)
      );
      border-color: hsla(
        var(--h),
        calc(var(--s)*1%),
        calc((var(--l)) * 0.4%),
        var(--border-alpha)
      );
      color: hsl(
        var(--h),
        calc(var(--s) * 1%),
        calc((var(--l)) * 0.4%)
      );
    }

    &--dark {
      --lightness-threshold: 0.6;
      --background-alpha: 0.18;
      --border-alpha: 0.3;
      --lightness-switch: max(0, min(calc(var(--perceived-lightness)*-1000 - var(--lightness-threshold)*-1000), 1));
      --lighten-by: calc((var(--lightness-threshold) * 100 - var(--perceived-lightness) * 100) * var(--lightness-switch));

      background-color: rgba(
        var(--r),
        var(--g),
        var(--b),
        var(--background-alpha)
      );
      border-color: hsla(
        var(--h),
        calc(var(--s)*1%),
        calc((var(--l) + var(--lighten-by)) * 1%),
        var(--border-alpha)
      );
      color: hsl(
        var(--h),
        calc(var(--s) * 1%),
        calc((var(--l) + var(--lighten-by)) * 1%)
      );
    }
  }
}
