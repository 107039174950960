.multi-select-entity__list {
  margin-top: 20px;
  border: 1px solid $dropdown-separator-color;
  height: 180px;
  overflow: auto;
  overflow-x: hidden;

  .multi-select-entity__item {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0px 5px;
    cursor: pointer;

    .input-checkbox {
      margin-right: 10px;
      flex-shrink: 0;
    }

    .multi-select-entity__item--name {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-child {
      background-color: $input--box-background-color;
    }
    @include hover {
      background-color: $input--box-background-color;
    }
  }
}
