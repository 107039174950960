$empty-state-path: '../assets/images/empty-state/' !default;
$monitor-empty-url: $empty-state-path + 'monitor_cloud.svg';

.entity-empty {
  .entity-empty__illustration-wrapper--monitors {
    .monitors-empty__illustration {
      @include icon(
        $monitor-empty-url,
        115px,
        95px
      )
    }
  }
}
