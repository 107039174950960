.list-carousal {
  display: flex;
  color: $app-generic-text-color;
  align-items: center;

  .btn-icon {
    background-color: transparent;
    @include hover {
      background-color: var(--highlight-background-color-primary);
    }
    @include focus {
      background-color: var(--highlight-background-color-primary);
    }

    @include active {
      background-color: var(--highlight-background-color-primary);
    }

    @include disabled {
      opacity: 0.5;
      cursor: default;

      @include focus {
        background-color: transparent;
      }

      @include hover {
        background-color: transparent;
      }

      @include active {
        background-color: transparent;
      }
    }
  }

  .list-carousal--label {
    white-space: pre;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0px 10px;
  }
  .list-carousal--next,
  .list-carousal--previous {
    padding: 1px;
  }
}
