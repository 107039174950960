.websocket-hidden-messages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-s);
  padding: var(--spacing-s) var(--spacing-xs) var(--spacing-s) var(--spacing-l);
  margin: var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  background-color: var(--background-color-secondary);

  &__left-section {
    display: flex;
    align-items: center;
    flex: 1;
    @include unselectable;

    &__icon {
      margin-right: var(--spacing-m)
    }
  }

  &__right-section {
    display: flex;
    align-items: center;
    margin-left: var(--spacing-l);
  }
}
