.upgrade-plan-modal {
  &-content {
    padding: var(--size-xs);
    text-align: center;

    &-close-icon {
      position: absolute;
      top: var(--spacing-l);
      right: var(--spacing-l);
    }

    &-heading {
      margin: var(--spacing-s);
    }

    &-loader {
      margin-bottom: var(--spacing-m);
    }

    &-text {
      margin-top: var(--spacing-zero);
      margin-bottom: var(--spacing-l);
    }

    &-illustration {
      margin: var(--size-m) auto calc(var(--spacing-s)) auto;
    }

    &-action {
      &-primary {
        width: 126px;
        display: flex;
        margin: auto auto var(--spacing-m) auto;
      }
      &-outline {
        width: 126px;
      }
    }
  }
}
