.input-select-v2-wrapper {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--input-border-color-default);
  border-radius: var(--border-radius-default);
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: var(--controls-size-default);
  position: relative;
  width: 210px;

  &.is-disabled {
    background-color: var(--background-color-secondary);
    border-color: var(--border-color-strong);
    cursor: not-allowed;

    .input {
      cursor: not-allowed;
    }
  }

  &.highlight {
    background-color: var(--highlight-background-color-secondary);
  }

  &.is-open {
    background-color: var(--background-color-primary);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  }

  .input-search-group {
    flex: 1;
    background: transparent;
    border: 0;
    border-radius: 0;
    padding-right: var(--spacing-zero);

    .input {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      @include placeholder {
        font-size: var(--text-size-m);
      }
    }
  }

  .dropdown-button {
    align-self: center;
    border-left: 0;
    background: transparent;
    border-radius: 0;
    flex: 0 0 30px;
    height: var(--controls-size-default);
    margin-left: auto;
    padding: var(--spacing-zero);

    .dropdown-caret {
      margin-left: var(--spacing-zero);
    }
  }
}

.input-select-v2-dropdown-menu {
  .dropdown-menu-item {
    cursor: pointer;

    .input-select-item {
      @include text-truncate-ellipsis;
      flex: 1;
    }
    .item-wrapper {
      overflow: hidden;
      display: flex;
      flex: 1;
      white-space: nowrap;
      align-items: center;
      .item-label {
        @include text-truncate-ellipsis;
        flex: 1;
      }
      .item-actions {
        visibility: hidden;
        display: flex;

        .item-action {
          display: flex;
          justify-content: center;
          align-items: center;
          height: var(--size-m);
          width: var(--size-m);
          @include hover {
            @include icon-color(var(--content-color-brand));
          }
        }
      }
    }
    @include hover {
      .item-wrapper {
        .item-actions {
          visibility: visible;
        }
      }
    }

    &.is-selected {
      .highlight {
        color: var(--content-color-brand);
      }
    }

    &.dropdown-menu-item--save_method {
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    }

    &.dropdown-menu-item--no_result {
      color: var(--content-color-tertiary);
      cursor: default;
      @include hover {
        background-color: inherit;
      }
      &.is-focused {
        background-color: inherit;
      }
    }

    &.dropdown-menu-item--no_methods {
      color: var(--content-color-tertiary);
      cursor: default;
      @include hover {
        background-color: inherit;
      }
      &.is-focused {
        background-color: inherit;
      }
    }
  }

  &.use-browser-tabs-header {
    .dropdown-menu {
      min-width: 130px;
    }
  }
}

.input-select-v2-dropdown-menu--banner {
  .dropdown-menu {
    padding-bottom: 0px;
  }
}
