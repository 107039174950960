$response-meta-viewer-font-size: var(--text-size-s) !default;
$response-meta-time-phase-name-width: 120px;
$response-meta-time-phase-graph-width: 190px;
$response-meta-time-phase-value-width: 70px;

.response-meta-viewer {
  display: flex;
  align-items: center;
  height: var(--size-m);
  font-size: $response-meta-viewer-font-size;
  color: var(--content-color-secondary);
  justify-content: flex-end;
  line-height: var(--line-height-m);
  overflow: hidden;
  padding-right: var(--spacing-l);

  .response-meta-item-name {
    @include unselectable;

    .editor-pane-response-viewer.small & {
      display: none;
    }
  }

  .response-meta-items {
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: flex-end;
  }

  .response-meta-item {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: var(--spacing-s);

      &::after {
        content: '';
        margin-left: var(--spacing-s);
        height: var(--border-width-xl);
        width: var(--border-width-xl);
        min-height: var(--border-width-xl);
        min-width: var(--border-width-xl);
        border-radius: var(--border-width-xl);
        background-color: var(--border-color-strong);
        display: inline-flex;
        vertical-align: middle;
      }

      &.status-indicator::after {
        content: none;
      }
    }

    &.is-collapsed:not(:last-child) {
      margin-right: var(--spacing-m);

      &::after {
        content: none;
      }
    }

    &.status-indicator:last-child {
      padding-right: 0;
    }

    &:last-child:not(:only-of-type)::after {
      content: '';
      margin-left: var(--spacing-s);
      height: 12px;
      width: 1px;
      background-color: var(--border-color-strong);
      display: inline-flex;
      vertical-align: middle;
    }

    &__status {
      flex: 0 1 auto;
      overflow: hidden;
      justify-content: flex-end;
    }

    &.small {
      margin-right: var(--spacing-s);
    }

    &.is-tooltip-container {

      .response-meta-item-name,
      .response-meta-item-value {
        .response-meta-status-code-desc {
          padding-left: var(--spacing-xs);
        }
      }

      .response-meta-size__total {
        &.max-reached {
          color: var(--content-color-error)
        }
      }
    }

    &.response-network-meta {
      display: flex;

      .network-icon {
        @include icon-color(var(--content-color-secondary));

        &.warning {
          @include icon-color(var(--base-color-error));
        }
      }
    }
  }

  .response-meta-item-value {
    color: var(--content-color-success);

    .is-large,
    .is-unsuccessful {
      color: var(--content-color-error);
    }
  }

  .response-meta-actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.response-details__unavailable {
  width: 290px;

  .response-details__unavailable__body {
    margin: var(--spacing-s);

    .response-details__unavailable__title {
      color: var(--content-color-primary)
    }

    .response-details__unavailable__message {
      margin-top: var(--spacing-s);
      color: var(--content-color-secondary)
    }
  }
}

.network-meta__tooltip {
  max-width: 400px;

  .network-meta__body {
    width: 330px;
    margin: var(--spacing-s);
    font-family: var(--text-family-default);

    .network-meta__header {
      font-size: var(--text-size-l);
      margin-bottom: var(--spacing-s);
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
    }

    .network-meta__item {
      display: flex;
      justify-content: space-between;
      font-size: var(--text-size-m);
      height: var(--size-s);
      align-items: center;

      .item__label {
        width: 30%;
        color: var(--content-color-secondary);
      }

      .item__value {
        width: 70%;
        box-sizing: border-box;
        padding-left: var(--spacing-xxl);
        color: var(--content-color-primary);
        text-align: right;
        @include text-truncate-ellipsis;
      }
    }

    .network-meta__warning {
      background-color: var(--background-color-error);
      padding: var(--spacing-s) var(--spacing-zero);
      display: flex;
      align-items: center;
      border-radius: var(--border-radius-default);
      margin-top: var(--spacing-l);

      .warning__icon {
        @include icon-color(var(--content-color-brand));
        margin-left: var(--spacing-l);
      }

      .warning__message {
        margin: var(--spacing-zero) var(--spacing-l);
        color: var(--content-color-error);
        font-size: var(--text-size-m);
      }
    }
  }

  .network-meta__divider {
    margin: var(--spacing-s) var(--spacing-zero);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.response-meta-time--tooltip {
  max-width: 400px;

  .response-meta-time--tooltip_body {
    width: 380px;
    display: flex;
    flex-direction: column;
    font-size: $response-meta-viewer-font-size;
  }

  .response-meta-time--tooltip_row {
    width: 100%;
    display: flex;
    flex-direction: row;
    line-height: var(--line-height-l);
  }

  .response-meta-time--tooltip_row_event {
    font-style: normal;
    font-weight: var(--text-weight-medium);
    color: var(--content-color-tertiary);
  }

  .response-meta-time--tooltip_row_prepare {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-tertiary);
    margin-bottom: var(--spacing-s);
  }

  .response-meta-time--tooltip_row_socket {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-primary);
  }

  .response-meta-time--tooltip_row_dns {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-primary);
  }

  .response-meta-time--tooltip_row_tcp {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-primary);
  }

  .response-meta-time--tooltip_row_secureHandshake {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-primary);
  }

  .response-meta-time--tooltip_row_firstByte {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-primary);
  }

  .response-meta-time--tooltip_row_download {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-s);
  }

  .response-meta-time--tooltip_row_process {
    font-style: normal;
    font-weight: normal;
    color: var(--content-color-tertiary);
    margin-bottom: var(--spacing-s);
  }

  .response-meta-time--tooltip_row_total {
    font-style: normal;
    font-weight: var(--text-weight-medium);
    color: var(--content-color-success);
  }

  .response-meta-time--phase_name {
    width: $response-meta-time-phase-name-width;
  }

  .response-meta-time--phase_graph {
    width: $response-meta-time-phase-graph-width;
  }

  .response-meta-time--phase_value {
    width: $response-meta-time-phase-value-width;
    text-align: right;
  }

  .response-meta-time--phase_value_cached {
    color: var(--content-color-tertiary);
  }

  .response-meta-time--phase_graph_bar {
    height: var(--size-xs);
    background: var(--content-color-success);
    margin: var(--spacing-xs) var(--spacing-zero);
  }

  .response-meta-time--phase_graph_bar_prepare {
    background: var(--response-meta-time-graph-system-bar-background-color);
  }

  .response-meta-time--phase_graph_bar_process {
    background: var(--response-meta-time-graph-system-bar-background-color);
  }

  .response-meta-time--phase_graph_separator {
    height: 220px;
    background-color: var(--border-color-default);
    width: 1px;
    position: absolute;
    margin-top: var(--spacing-xl);
    z-index: 1;
  }
}

.response-meta-size--tooltip_body {
  width: 240px;
  font-style: normal;
  font-weight: normal;
  padding: var(--spacing-s);
  padding-bottom: var(--spacing-zero);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.response-meta-size--tooltip_section {
  padding-bottom: var(--spacing-l);
  padding-top: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  gap: var(--spacing-m);

  &:first-child {
    padding-top: var(--spacing-zero);
  }
}

.response-meta-size--tooltip_body_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}

.response-meta-size_title_primary {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  line-height: var(--line-height-s);
}

.response-meta-size_data_secondary {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}

.response-meta-size_psa {
  margin-top: var(--spacing-s);
  color: var(--content-color-tertiary);
}

.response-meta-size_warning {
  display: flex;
  margin-top: var(--spacing-m);
  align-items: center;

  .response-meta-size_warning_icon {
    margin-right: 6px;
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);
  }

  .response-meta-size_warning_text {
    color: var(--content-color-error);
    padding-left: var(--spacing-xs);
  }
}

.status-selector-container {
  display: flex;
  align-items: center;
  color: var(--content-color-secondary);
  font-size: $response-meta-viewer-font-size;
  padding: var(--spacing-zero) var(--spacing-s);
  justify-content: flex-end;
  height: 100%;

  .response-status-selector-wrapper {
    margin-left: var(--spacing-m);

    &.response-custom {
      position: relative;
      display: flex;

      .input-reset {
        @include unselectable;
        @include clickable;
        transform: rotate(-180deg);
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    &__input {
      height: var(--controls-size-small);
    }

    .input-select-list {
      max-height: 210px;
    }
  }
}

.response-meta-action {
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: var(--spacing-xs);
  padding-left: var(--spacing-s);

  &__cancel,
  .dropdown-button .btn-text {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  &__cancel {
    margin: var(--spacing-zero) var(--spacing-l);
    height: var(--size-m);
  }

  .response-save-button {
    white-space: pre;
    padding-inline: var(--spacing-xs);

    .dropdown-caret {
      margin-left: var(--spacing-xs);
      @include icon-color(var(--content-color-link));
    }
  }

  .dropdown-button {
    display: flex;
    align-items: center;
  }
}

.response-meta__tooltip {
  .tooltip-wrapper {
    background: var(--background-color-primary);
  }
}
