.explorer {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  border-radius: var(--border-radius-default);
  font-family: $font-primary;
  color: var(--content-color-primary);
  border: 1px solid var(--border-color-strong);

  flex: 1;
  min-height: 100px;

  &__create-in-progress-loading-state {
    display: flex;
    height: 30px; // to match existing styles
    align-items: center;
    padding: var(--spacing-zero) var(--spacing-s);
    animation: fade 1s ease-in-out infinite;

    &__item {
      display: flex;
      width: 100%;
      background-color: var(--highlight-background-color-secondary);
      border-radius: var(--border-radius-default);
      height: var(--size-xs);
    }
  }

  .loading-indicator-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .explorer__header-wrapper {
    background-color: var(--background-color-secondary);
  }

  .explorer__header__create-wrapper {
    display: flex;
    align-items: center;
    padding: 15px 5px 5px 5px;

    .explorer__header__breadcrumb-wrapper {
      color: var(--content-color-secondary);
    }

    .explorer__header__create {
      display: flex;
      align-items: center;
      align-self: flex-end;
      margin-left: auto;
      font-size: 12px;
      color: $button--text-color;
      cursor: pointer;
    }
  }

  .explorer__create {
      display: flex;
      align-items: center;
      min-height: 30px;
      cursor: pointer;
    }

  .explorer-row__create {
    border: 1px solid $button--text-color;
    border-radius: 3px;
    width: 100%;
    padding-left: 5px;

    &.input-search-group{
      height: 24px;
    }

    &.input-search{
      height: 22px;
    }

    .input::-webkit-input-placeholder {
      font-size: 12px;
    }
  }

  .explorer__header-title {
    margin-right: 20px;

    @include unselectable;
  }

  .explorer-pop-btn {
    display: flex;
    flex: 3;
    cursor: pointer;
    align-items: center;
    padding-right: 20px;
    overflow: hidden;

    @include hover {
      .explorer-pop-btn__text {
        text-decoration: underline;
      }
    }

    .explorer-pop-btn__text {
      margin-left: 5px;
      max-width: 270px;
      @include text-truncate-ellipsis;
    }

    .explorer-pop-btn__meta {
      margin-left: 8px;
      min-width: 30px;

      @include text-truncate-ellipsis;
    }
  }

  .explorer__header-create-btn {
    flex: 1;
    color: var(--content-color-link);
    cursor: pointer;
    text-align: right;

    @include text-truncate-ellipsis;
  }

  .explorer-search {
    min-height: 30px;
    display: flex;
    align-items: center;

    .input-search-group {
      flex: 1;
      border: 0px;
      background-color: var(--background-color-secondary);

      &.is-focused {
        border: 1px solid var(--base-color-brand);
        background-color: var(--background-color-primary);
        border-radius: var(--border-radius-default);
      }

      &:not(.is-focused){
        border: 1px solid var(--background-color-secondary);
        border-bottom: 1px solid var(--border-color-strong);
        border-radius: 0;
      }

      .input::-webkit-input-placeholder {
        font-size: 12px;
      }
    }
  }

  .explorer__empty-state{
    align-self: center;
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .explorer-rows,
  .explorer-results {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .explorer-create-row {
    height: 30px;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: var(--content-color-primary);
    font-family: $font-primary;

    .input-search-group__search-cancel-wrapper{
      display: none;
    }

    .explorer-row__save {
      margin-left: var(--spacing-xs);
      margin-right: 1px;
    }

    .explorer-row__cancel {
      margin-right: var(--spacing-m);
    }
  }

  .explorer-row__icon {
    align-self: flex-start;
    margin: 7px;
    padding-top: var(--spacing-xs);

    .explorer-row__icon-leaf-icon {
      font-weight: 700;
      font-size: 9px;
    }
  }

  .explorer-row {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: var(--content-color-primary);

    &.is-disabled, &.is-leaf {
      cursor: default;
      opacity: 0.4;
    }

    &.explorer-result {
      padding: var(--spacing-s) var(--spacing-zero);
      .explorer-row__text {
        padding-left: 0;
      }

      .explorer-row__icon {
        padding-top: 2px;
        margin-top: var(--spacing-zero);
      }
    }

    .explorer-row__name {
      margin-right: 8px;

      @include text-truncate-ellipsis;
    }

    .explorer-row__icons {
      margin-right: 8px;
    }

    .explorer-row__icons:empty {
      margin-right: 0;
    }

    .explorer-row__meta {
      overflow: hidden;
      min-width: 30px;
      display: flex;
      align-items: center;
    }

    .explorer-row__content {
      overflow-x: hidden;
    }

    .explorer-result__path {
      padding-right: 20px;
      color: var(--content-color-tertiary);

      .highlight {
        opacity: 1;
        color: var(--content-color-secondary);
      }

      @include text-truncate-ellipsis;
    }

    .explorer-row__forward {
      flex: 1;
      margin: 7px;
      visibility: hidden;
      justify-content: flex-end;
    }

    @include hover {
      &:not(.is-leaf) {
        background-color: var(--highlight-background-color-secondary);
      }

      .explorer-row__forward {
        visibility: visible;
      }
    }

    &__selectedRequest {
      background-color: var(--highlight-background-color-secondary);
    }
  }

  .request-method--GET {
    color: var(--base-color-success);
  }

  .request-method--PUT {
    color: $request-method--PUT-color;
  }

  .request-method--POST {
    color: var(--base-color-warning);
  }

  .request-method--DELETE {
    color: var(--base-color-error);
  }
}
