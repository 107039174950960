/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.toast {
  z-index: 1000;
  padding: var(--spacing-l);
  display: flex;
  font-family: $font-primary;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 400px;
  background-color: var(--background-color-secondary);
  color: var(--content-color-primary);
  position: relative;

  a {
    color: var(--content-color-link, #097BED);
    text-decoration: none;
  }

  .toast-icon {
    margin: 2px 8px 0px auto;
    flex-shrink: 0;
  }

  .toast-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;

    .toast-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;

      * {
        margin: 0;
      }
    }

    .toast-body {
      font-size: 12px;
      line-height: 20px;

      * {
        margin: 0;
      }
    }

    .toast-actions {
      .toast-primary-action {
        margin-right: 16px;
      }

      .toast-secondary-action-only.btn-text {
        padding-left: 0px;
      }
    }
  }

  .toast-content-shrink {
    padding-right: var(--spacing-xxl);
  }

  .toast-actions {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .toast-dismiss {
    width: var(--spacing-xxxl);
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 4px 4px 0px;

    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    &:hover,
    .toast-close:hover {
      @include clickable;
      background: var(--highlight-background-color-secondary);
    }
  }
}

.toast-info {
  border-left: 4px solid var(--background-color-tertiary);
}

.toast-success {
  border-left: 4px solid var(--base-color-success);
}

.toast-error {
  border-left: 4px solid var(--base-color-error);
}

.toast-warn {
  border-left: 4px solid var(--base-color-warning);
}
