.collection-editor-content {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__variables-editor {
    margin-top: var(--spacing-m);

    .key-value-form-editor {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        height: 5px;
        width: calc(100% - .75rem);
        border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: calc(100% - .75rem);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      .key-value-form-header-row {
        overflow-y: scroll;
      }
    }
  }

  .documentation-usage-error {
    width: 100%;
  }
}


// For Autosizer to work correctly we need to make sure that all heights are set to 100%
.collection-editor__content {
  height: 100%;
}
