.pull-request-comments {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__empty-state-description i {
    position: relative;
    top: 3px;
    margin: 0 var(--spacing-xs);
  }

  .comments {
    height: 97%;
    display: flex;
    flex-direction: column;
  }

  .comments-list {
    max-height: initial;

    .comments-item {
      padding-top: var(--spacing-xl);
      padding-left: 0;
    }
  }

  .comments-input {
    padding-left: 0;
    font-size: var(--text-size-m);
  }
}
