.workspace-activity-feed-filter-container {
  display: flex;
  justify-content: space-between;

  .workspace-activity-feed-filter {
    display: flex;
    align-items: center;

    .workspace-activity-feed__search-button {
      height: 32px;
    }

    .workspace-activity-feed__clear-button {
      span span {
        &:hover {
          border-bottom-color: transparent;
        }
      }
    }

    .workspace-activity-feed-filter-heading {
      line-height: var(--size-s);
      vertical-align: middle;
    }

    .workspace-activity-feed-filter-types {
      display: flex;
      margin-left: var(--spacing-s);

      .select-dropdown-container {
        min-width: unset;
        height: var(--size-s);
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: var(--border-radius-default);

        .dropdown-button {
          width: 100%;
          border-radius: var(--border-radius-default);

          .select-dropdown-btn {
            color: var(--content-color-primary);
            height: var(--size-s);
          }
        }
      }

      .select-dropdown-container.workspace-members {
        margin-left: var(--spacing-xs);
      }

      .entity-select-container .dropdown {
        margin-left: 16px;
      }

      .select-dropdown-container .is-open, .select-dropdown-container .selected {
        .select-dropdown-btn {
          background-color: var(--highlight-background-color-secondary);

          i {
            svg {
              color: var(--content-color-secondary);
            }
          }
        }
      }
    }

    .workspace-activity-feed-filter-actions {
      margin-right: var(--spacing-l);
    }
  }
}
