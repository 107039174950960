.add-to-workspace-modal {
  .modal-content {
    overflow: visible;
  }

  .loading-indicator-wrapper {
    text-align: center;
  }

  .add-to-workspace-modal__contents {
    .add-to-workspace-modal__contents-box {
      height: inherit;
    }
  }
}

.add-to-workspace-modal__contents {
  display: flex;
  flex-direction: column;

  .add-to-workspace__tabs {
    width: 100%;
    border-bottom: 1px solid var(--border-color-default);
  }

  .add-to-workspace-modal__choose-entities {
    margin-bottom: 20px;
  }

  .add-to-workspace-modal__contents-box {
    height: 550px;
  }

  .add-to-workspace__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .add-to-workspace--helptext {
    margin-bottom: 20px;
  }

  .add-to-workspace-modal__entities-count {
    margin-top: 10px;
    color: var(--content-color-tertiary);
  }

  .add-to-workspace-modal__alert-wrapper {
    margin-top:20px;
  }

  .add-to-workspace__input-group {
    display: flex;
    flex-direction: column;
    flex: 1;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .add-to-workspace__input-group__label {
      margin-bottom: 7px;
    }

    .add-to-workspace__input-group__alert {
      margin-bottom: 8px;
    }

    .add-to-workspace__input-group__input {
      display: flex;
      margin-bottom: 8px;

      &.add-to-workspace__input-group--team-permissions {
        border: 1px solid $app-border-color;
        border-radius: var(--border-radius-default);
        padding: 10px;
      }

      .add-to-workspace__input-group__label__label {
        flex: 2;
        display: flex;
        align-items: center;
      }

      .add-to-workspace__input-group__input--team-permissions {
        align-self: center;
        flex: 1;
      }

      .add-to-workspace__input-group__input--selected-workspace {
        .workspace-name {
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 400px;
        }
        .dropdown-menu-item {
          justify-content: space-between;
        }

        .dropdown-menu {
          max-height: 355px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }

    .add-to-workspace__input-group__subtext {
      color: $workspace-create-new-disabled-color;
    }

    &.add-to-workspace__input-group--team-permissions,
    &.add-to-workspace__input-group--user-permissions {
      /*border: 1px solid $app-border-color;
      border-radius: var(--border-radius-default);
      padding: 10px;*/
    }
  }

  .add-to-workspace__create-team {
    margin-top: 32px;
  }
}

.add-to-workspace__input-group__input--selected-workspace {
  .workspace-name {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
  }
  .dropdown-menu-item {
    justify-content: space-between;
  }

  .dropdown-menu {
    max-height: 355px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
