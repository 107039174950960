@import 'collections-empty';
@import 'environments-empty';
@import 'integrations-empty';
@import 'mocks-empty';
@import 'monitors-empty';
@import 'history-empty';
@import 'add-to-workspace-empty';
@import 'crash-handler';
@import 'no-results';
@import 'create-team-empty';
@import 'contextual-sign-in-modal';
@import 'permission-denied';
@import 'sign-in-modal';
@import 'collection-sidebar-empty-shell';
@import 'share-modal-empty-shell';
@import 'tab-empty-shell';
@import 'empty-tab-state';

.entity-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  border: 1px solid var(--border-color-default);
  box-sizing: border-box;

  .entity-empty__illustration-wrapper {
    margin-bottom: 35px;
  }

  .entity-empty__title {
    font-size: 16px;
    font-family: $font-primary;
    font-weight: 600;
    color: var(--content-color-primary);
    margin-bottom: 20px;
  }

  .entity-empty__subtitle {
    font-size: 12px;
    font-family: $font-primary;
    color: var(--content-color-secondary);
    text-align: center;
    margin-bottom: 40px;
    max-width: 500px;
    line-height: 20px;

    .btn-text {
      font-size: 12px;
    }
  }

  .empty-empty__action {
    .btn-primary {
      height: 40px;
      font-size: 14px;
      font-family: $font-primary;
      font-weight: 600;
      padding: 10px 20px;
    }
  }
}
