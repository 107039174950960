/* stylelint-disable postman-stylelint/color-no-hex-rgb */
@import './StarLoader/index';

.ai-parent-container {
  position: absolute;


  &.snippets-collapsed {
    top: 8px;
    right: 38px;

    .snippets-ai-btn {
      display: none;
    }

  }

  .script-editor-ai-btn {
    right: 16px;
    position: relative;
    &.hide {
      display: none;
    }
  }

  &.snippets-expanded {
    width: 100%;
    top: -4px;

    .script-editor-ai-btn {
      display: none;
    }


    .ai-popover-container {
      width: 100%;

      .snippets-ai-btn {
        cursor: pointer;
        display: flex;
        gap: 8px;
        border: 1px solid var(--purple-20);
        border-radius: 4px;
        margin: 0 36px 0 8px;
        padding: 8px;

        svg {
          margin-top: 2px;
        }

        &.error-icon {
          svg {
            width: 16px;
            path {
              fill: var(--purple-60) !important;
            }
            color: var(--purple-60) !important;
          }
        }

        .snippets-ai-btn-text {
          h5 {
            line-height: 20px;
          }
        }
      }
    }
  }

  .alpha-badge {
    font-size: 10px;
  }

  .postbot-suggestion-badge {
    cursor: pointer;
  }

  .postbot-suggestion-badge, .alpha-badge {
    background-color: var(--purple-10);
    line-height: 16px;
    border-radius: 4px;
    padding: 0px 4px 0 4px;
    color: var(--purple-60);
    letter-spacing: 0.4px;
  }

  .ai-tests-loader {
    .rect-one, .rect-two, .rect-three {
      background-color: var(--purple-60);
    }
  }

  .popover-icon-container {
    position: absolute;
    top: 7px;
    right: 7px;
    button {
      .rect-one, .rect-two, .rect-three {
        background-color: var(--background-color-primary);
      }
    }
  }

  .popover-icon-container, .script-editor-ai-btn {

    &.bg-purple {
      button {
        background-color: var(--purple-60);

        &:hover {
          background-color: var(--purple-60);
        }
      }
    }

    .popover-btn, .ai-play-btn {
      width: 24px;
      height: 24px;
      z-index: 2;
      span {
        display: flex;
      }
      svg {
        path {
          fill: var(--purple-60) !important;
        }
        color: var(--purple-60) !important;
      }

      &.error-icon {
        svg {
          path {
            fill: var(--purple-60) !important;
          }
          color: var(--purple-60) !important;
        }
      }
      background-color: var(--background-color-primary);
      border: 1px solid var(--purple-60);

      &:hover {
        background-color: var(--background-color-primary);
      }

    }

    .cancel-ai-request-btn {
      background: none !important;
    }
  }

  .ai-popper {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    opacity: 0;

    background-color: var(--background-color-primary);
    padding: 12px 0 0 0;
    margin-right: -40px;
    margin-top: -8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;


    &.show-popper {
      width: 324px;
      opacity: 1;
      right: 32px;
    }

    &.hide-popper {
      width: 0;
      opacity: 0;
      padding: 0;

      .popover-icon-container {
        display: none;
      }
    }

    .line-divider {
      width: calc(100% - 16px);
      height: 1px;
      background-color: var(--border-color-default);
    }

    .suggestion-container {
      margin: 4px 8px 12px 8px;
      span {
        margin: 0 4px 8px 4px !important;
        cursor: pointer;
      }

      &.hide-tree {
        display: none;
      }
    }

    .script-summary-container, .script-error-container, .script-warning-container {
      margin-top: 8px;
      .warning-message {
        border-radius: var(--border-radius-default);
        background-color: var(--background-color-warning);
        padding: var(--spacing-s);

        .warning-btn-container {
          margin-left: 22px;
        }

        .message-warning-icon {
          i {
            margin-right: 6px;
          }
        }
      }
      .script-summary, .warning-message, .error-message-container {
        margin: 0 8px 8px 8px;
      }
      .script-summary {
        margin: 0 8px 8px 12px;
      }
    }

    .error-message-container {
      border-radius: var(--border-radius-default);
      background-color: var(--background-color-error);
      padding: var(--spacing-s);
      i {
        margin: 1px 6px 0 0;
      }
    }

    &.snippets-popper {
      border: 1px solid var(--purple-30);
      right: 42px;
      top: 4px;
      z-index: 10;

      &.show-popper {
        z-index: 11;
        width: 328px;
      }
    }

    .loading-tests-txt {
      width: calc(100% - 16px);
      margin: 8px 0 8px 16px;

      .cancel-ai-request-btn {
        height: max-content;
        margin-top: 4px;
        background: none;

        svg {
          color: var(--purple-60);
        }

      }
    }

  }

  .ai-play-btn {
    svg {
        path {
          stroke: var(--grey-00) !important;
        }
    }
  }

  .input-and-suggestion-container {
    width: 100%;
  }

  // Make this input custom so we don't have to override styles with important
  .ai-input-container {
    width: calc(100% - 48px);
    margin: 0 36px 8px 12px !important;

    .ai-textarea {
      width: 100%;
      min-height: 80px;
      max-height: 240px;
      overflow-x: hidden;
      overflow-y: scroll;
      color: var(--content-color-primary);
    }

    div[contentEditable]:empty::before {
      content: attr(data-placeholder);
      color: var(--content-color-tertiary);
    }
  }

  .postbot-feeback-btn {
    width: 100%;
    justify-content: start;
    margin-bottom: var(--spacing-s);
    button {
      width: 100%;
      justify-content: start;
      padding-left: 12px;
      color: var(--purple-60);
      border-radius: 0;

      svg {
        color: var(--purple-60);
      }

      &:hover {
        background-color: var(--purple-10);
        color: var(--purple-60);

        svg {
          color: var(--purple-60);
        }
      }
    }
  }

  &.dark-theme {

    .popover-icon-container, .script-editor-ai-btn {
      button {
        .rect-one, .rect-two, .rect-three {
          background-color: var(--grey-00);
        }
      }
    }

    .alpha-badge, .postbot-suggestion-badge {
      background-color: var(--purple-80) !important;
      color: var(--purple-30) !important;
    }

    .snippets-ai-btn {
      border: 1px solid var(--purple-60) !important;

      * {
        color: var(--purple-30);
      }

      svg {
        path {
          fill: var(--purple-30);
        }
        color: var(--purple-30);
      }
    }

    .snippets-popper, .ai-popper {
      border: 1px solid var(--purple-70);
    }

    .suggestion-container {
      span {
        background-color: var(--purple-70);
        color: var(--purple-30)
      }

    }

    .popover-btn {
      border: 1px solid var(--purple-40);
      svg {
        path {
          fill: var(--purple-40) !important;
        }
        color: var(--purple-40) !important;
      }
    }

    .summary-item {
      span {
        color: var(--purple-40)
      }
    }

    .script-error-container {
      svg {
        color: var(--purple-40) !important;
      }
    }

    .postbot-feeback-btn {
      button {
        color: var(--purple-50);

        svg {
          color: var(--purple-50);
        }

        &:hover {
          background: none;
        }
      }
    }
  }

  .cancel-ai-request-btn {
    svg {
      color: var(--purple-40) !important;
    }
  }
}


.ai-opt-in-container {
  position: absolute;
  top: -4px;
  right: 8px;
  padding: var(--spacing-xl) var(--spacing-l) !important;
  background-color: var(--background-color-primary);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  z-index: 11;
  text-align: center;

  h4 {
    margin: 16px 0 8px 0 !important;
  }

  .close-ai-opt-in {
    position: absolute;
    right: 4px;
    top: 4px;
  }

  .opt-in-terms-text {
    padding: 0 12px;
    margin-bottom: 16px !important;
  }

  .ai-consent-description {
    margin-bottom: 16px !important;
    line-height: 20px;

    svg {
      margin-right: 4px;
      g {
        path {
          stroke: var(--content-color-secondary);
        }
      }
    }
  }

}

.global-postbot-script-btn {
  display: flex;
  padding: 10px 0 var(--spacing-s) 0;
  gap: var(--spacing-s);
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color-default);
  margin: 0 var(--spacing-s) var(--spacing-m) var(--spacing-s);
}

