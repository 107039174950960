.monitor-sidebar-loading-shell{
  height: 100%;
  border-right: 1px solid var(--background-color-tertiary);
  border-left: 1px solid var(--background-color-tertiary);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;

  .monitor-sidebar-loading-shell--item{
    height: var(--size-m);
    border-bottom: 1px solid var(--background-color-tertiary);
    display: flex;
    align-items: center;

    .monitor-sidebar-loading-shell--monitor__dropdown{
      height: 12px;
      width: 12px;
      background-color: var(--background-color-tertiary);
      margin-left: 8px;
      border-radius: var(--border-radius-default);
    }

    .monitor-sidebar-loading-shell--monitor__icon{
      height: 12px;
      width: 12px;
      background-color: var(--background-color-tertiary);
      margin-left: 8px;
      border-radius: var(--border-radius-default);
    }

    .monitor-sidebar-loading-shell--monitor__info{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .monitor-sidebar-empty-shell--monitor__info--name,
    .monitor-sidebar-empty-shell--monitor__info--count{
      background-color: var(--background-color-tertiary);
      width: 100px;
      height: 8px;
      margin-left: 16px;
      margin-bottom:5px;
      border-radius: var(--border-radius-default);
    }
  }
}

.monitor-sidebar-offline-state {
  margin-top: 80px;
}

.monitor-sidebar-offline-icon {
  margin-right: 10px;
}
