.info-button-new {
  display: flex;
  cursor: default;

  &.error {
    @include icon-color(var(--base-color-error));
  }

  .info-button-new__icon {
    display: flex;
    @include icon-color(var(--content-color-secondary));
  }
}

.info-button-new__tooltip.enable-whitespace {
  white-space: pre-wrap;
}
