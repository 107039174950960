/**
 * Used most of the styles from the markdown mixin, but modified according
 * to the styles to the public documentation and mockups present
 * in Figma file
 */
 /* stylelint-disable postman-stylelint/color-no-hex-rgb */
.description-preview {
  color: var(--content-color-primary);
  font-size: inherit;
  line-height: inherit;
  overflow-wrap: break-word;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;

  &--editable {
    padding: var(--spacing-xs) var(--spacing-s);
    height: auto !important;
  }

  &--overlay {
    background-color: var(--background-color-secondary);
  }

  .click-to-expand-wrapper {
    position: relative;
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    table {
      border: none;
    }
  }

  .click-to-expand-container {
    --max-table-height-without-scroll: calc(20 * var(--size-s));
    max-height: var(--max-table-height-without-scroll);
    width: 100%;
    overflow: hidden;

    &--overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: var(--size-m);
      border-radius: var(--border-radius-default);
      background: linear-gradient(
        to top,
        var(--highlight-background-color-secondary),
        rgba(255, 255, 255, 0)
      );
    }

    &--expand-button {
      position: absolute;
      bottom: var(--spacing-s);
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--background-color-primary);
      border: var(--border-width-default) var(--border-style-solid)
        var(--border-color-default);
      border-radius: calc(8 * var(--border-radius-default));
      color: var(--content-color-primary);
      padding: var(--spacing-xs) var(--spacing-m);
      font-size: var(--text-size-m);

      &:focus {
        outline: none;
      }
    }
  }

  &__placeholder {
    color: var(--content-color-tertiary);
    font-size: var(--text-size-l);
    overflow-wrap: break-word;
    flex: 1;
  }

  &__placeholder_small {
    color: var(--content-color-tertiary);
    overflow-wrap: break-word;
    flex: 1;
  }

  &__no-description {
    color: var(--content-color-tertiary);
    flex: 1;
  }

  &__markdown {
    flex: 1;
    overflow: hidden;
    max-width: 100%;

    div[data-entity-type="code-block"] code {
      display: flex;
      flex-direction: column;
      width: 0;
    }
  }

  &__edit-icon {
    position: sticky;
    top: var(--spacing-xs);
    right: 0;
    opacity: 0;
    cursor: pointer;
    height: var(--size-xs);
  }

  &:hover &__edit-icon {
    opacity: 1;
  }
}
