.integration-status {
  &__normal-btn {
    font-weight: var(--text-weight-medium);
    height: var(--size-s);
    margin-left: var(--spacing-l);

    .loading-indicator-wrapper {
      margin-right: var(--spacing-l);
      margin-left: var(--spacing-s);
    }
  }

  &__loading {
    min-width: 180px;
  }

  &__error {
    background-color: var(--background-color-warning);
    color: var(--content-color-warning);
    min-width: 160px;

    i {
      margin-right: var(--spacing-s);

      @include icon-color(var(--content-color-warning));
    }

    &.is-disabled {
      opacity: 1;
    }

    &.is-disabled:focus, &.is-disabled:hover {
      background-color: var(--background-color-warning);
      color: var(--content-color-warning);
    }
  }

  &__api {
    background-color: var(--background-color-warning);
    color: var(--content-color-warning);
    min-width: 120px;

    i {
      margin-right: var(--spacing-s);

      @include icon-color(var(--content-color-warning));
    }

    &:focus, &:hover {
      background-color: var(--background-color-warning);
      color: var(--content-color-warning);
    }
  }

  &__default {
    min-width: 160px;

    i {
      margin-right: var(--spacing-s);
    }
  }

  &__success {
    background-color: var(--background-color-brand);
    color: var(--content-color-brand);

    i {
      margin-right: var(--spacing-s);

      @include icon-color(var(--content-color-brand));
    }

    &:focus, &:hover {
      background-color: var(--background-color-brand);
      color: var(--content-color-brand);
    }

    &__content {
      max-width: 120px;
    }
  }

  &__running {
    background-color: var(--background-color-brand);
    color: var(--content-color-brand);

    i {
      margin-right: var(--spacing-s);

      @include icon-color(var(--content-color-brand));
    }

    &:focus, &:hover {
      background-color: var(--background-color-brand);
      color: var(--content-color-brand);
    }

    &__content {
      max-width: 120px;
    }

    .pm-icon-sync {
      animation: rotate 1s infinite;
    }

    @keyframes rotate {
      100% {
        transform: rotate(-180deg);
      }
    }
  }

  &__failure {
    background-color: var(--background-color-error);
    color: var(--content-color-error);

    i {
      margin-right: var(--spacing-s);

      @include icon-color(var(--content-color-error));
    }

    &:focus, &:hover {
      background-color: var(--background-color-error);
      color: var(--content-color-error);
    }

    &__content {
      max-width: 120px;
    }
  }

  &__synced {
    background-color: var(--background-color-success);
    color: var(--content-color-success);

    i {
      margin-right: var(--spacing-s);

      @include icon-color(var(--content-color-success));
    }

    &:focus, &:hover {
      background-color: var(--background-color-success);
      color: var(--content-color-success);
    }

    &__content {
      max-width: 120px;
    }
  }
}

.tooltip {
  .integration-status__success__tooltip__entity {
    font-weight: var(--text-weight-medium);
  }
}

.integration-status-btn {
  font-size: var(--text-size-m);
}
