.copyright {
  &-container {
    max-width: none;
  }

  &-text {
    direction: ltr;
  }

  &-link {
    text-decoration: underline;
    color: var(--content-color-link);
    &:hover {
      cursor: pointer;
      color: var(--content-color-link);
    }
  }
}
