.proxy-settings-filters-container {
  padding-bottom: 16px;
}
.proxy-settings-filters-group {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.proxy-settings-filters-label {
  font-style: normal;
  font-weight: 600;
  flex: 0 0 32px;
  padding-right: 20px;
}

.proxy-settings-filters-input {
  flex: 1;
}

.proxy-settings-filters-input-help {
  color: var(--content-color-secondary);
  padding-top: 4px;
}

.proxy-settings-filters-wrapper {
  overflow: auto;
}

.proxy-settings-show-filters {
  margin-top: 16px;
  max-height: 100px;
  .show-filters-btn {
    margin: 0px;
    padding: 0px;
  }
}

.proxy-show-filters-btn {
  margin-top: 16px;
  padding: 0px;
}
