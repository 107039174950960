.rfa-banner {
  &__content {
    background: var(--background-color-info);
    margin-top: var(--spacing-m);

    &__text {
      padding: var(--spacing-m) var(--spacing-l) var(--spacing-zero) var(--spacing-l);
    }
  
    &__button {
      padding: var(--spacing-s) var(--spacing-zero) var(--spacing-m) var(--spacing-l);
    }
  }
}
