.mocks-dropdown-menu {
  min-width: 220px;

    .dropdown-menu-item {
      padding: 0;

      .dropdown-menu-item-icon {
        margin-right: var(--spacing-s);
      }
    }

    .mock-action-item {
      display: flex;
      flex: 1;
    }
}
