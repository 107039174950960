.request-editor-snippet-generator-modal-content {
  display: flex;
  overflow: auto;
  padding: 0;
}

.request-editor-snippet-generator-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.request-editor-snippet-generator-options-settings-content {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-l);
  overflow-y: auto;
  flex: 1;
  flex-shrink: 0;
  margin-top: var(--spacing-s);
}

.request-editor-snippet-generator-editor-options-pane {
  display: flex;
  flex-direction: column;
  flex:1;

  .request-editor-snippet-generator-header {
    display: flex;
    flex-direction: row;
    background-color: var(--background-color-primary);
    box-shadow: var(--shadow-default);
    border-radius: 0px;
    z-index: 2;

    .request-editor-snippet-generator-header-label {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      margin-left: var(--spacing-s);
    }

    .request-editor-snippet-generator-header__section-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .request-editor-snippet-generator-editor {
    flex: 1;
    display: flex;
    .request-editor-snippet-generator-text-editor {
      border: none;
    }
  }
}

.request-editor-snippet-generator-options {
  display: flex;
  flex-direction: column;
}
