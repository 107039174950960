.api-diff-view-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  // we should never have an overflow here otherwise for small widths,
  // contextbar would not open
  overflow-y: hidden;
}

.api-diff-view {
  display: flex;
  flex: 1 auto;
  flex-direction: column;

  // ensures that this element never expands more than its container
  min-height: 0;

  .api-diff-view-tab-content {
    display: flex;
    flex: 1;
    flex-direction: column;

    // ensures that api-tab-content doesn't overflow the pane by resizing
    // itself based on children's size, rather resizes children based on
    // container size
    min-height: 0;

    .api-diff-view-tab-content-pane {

      &__head {
        max-height: 100%;
        overflow-y: auto;
      }
    }

    .api-tab-content-pane__head {
      height: 100%;
    }
  }
}

.api-diff-view-header {
  position: sticky;

  .api-diff-view-header__left-section {
    display: flex;
    align-items: center;
    color: var(--content-color-primary);
    min-width: 0px;
    margin-left: var(--spacing-s);

    .api-diff-view-name-label {
      color: var(--content-color-primary);
      display: flex;
      align-items: center;
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);
      min-width: 0px;
      max-width: 300px;

      .breadcrumb__text {
        font-size: var(--text-size-l);
      }
    }
  }
}

.api-diff-view-tab-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .api-diff-view-tab-body-title {
    margin-bottom: var(--spacing-l);
  }

  .api-diff-view-tab-body__default {
    margin-top: var(--spacing-s);
    margin-left: var(--spacing-xxl);
  }
}


