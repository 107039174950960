.sidebar-signin-prompt-state {
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 0 auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);

  &__icon-container {
    height: 56px;
    width: 56px;
    background-color: var(--background-color-tertiary);
    border-radius: var(--border-radius-max);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .sidebar-signin-prompt-state__icon {
      height: var(--size-m);
      width: var(--size-m);

      svg {
        height: var(--size-m);
        width: var(--size-m);
      }
    }
  }

  &__title {
    color: var(--content-color-secondary);
    padding: var(--spacing-xs) 40px;
    text-align: center;

    h4 {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      margin-bottom: 0;
      margin-top: var(--spacing-l);
    }
  }

  &__body {
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-l);
    padding: var(--spacing-xs) var(--spacing-m);
    text-align: center;
  }

  &__link-action{
    margin-top: var(--spacing-xl);
    color:var(--content-color-brand);
    font-size: var(--text-size-s);
    font-weight: var(--text-weight-medium);
  }
}
