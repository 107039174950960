.monitor-context-bar-list {
  --monitor-cb-label-width: 220px;
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  padding-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);

  &__label-wrapper {
    display: flex;

    &-label {
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);
      cursor: default;
      flex: 1;
    }

    &-create-new-monitor {
      cursor: pointer;
      font-size: var(--text-size-s);
      color: var(--content-color-secondary);
      align-items: center;
      padding-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
      padding-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
      min-width: calc(var(--size-s) + var(--size-m));
      height: var(--size-s);

      &-icon {
        padding-right: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
      }
    }
  }

  &__monitor-list {
    display: grid;
  }

  &__item {
    height: 20px;
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-xs);
    display: flex;
    align-items: center;

    &-label {
      @include text-truncate-ellipsis;
      cursor: pointer;
      overflow: hidden;
      font-size: var(--text-size-m);
      width: var(--monitor-cb-label-width);
      flex: 1;

      >.aether-link-text{
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-icon {
      padding-left: var(--spacing-s);
      padding-right: var(--spacing-s);
      align-items: center;
    }

  }

  &__loading {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-l);
  }

  &__empty-state {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    margin-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
    margin-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
    display: inline-flex;
    line-height: var(--line-height-m);
  }

  &__error-state {
    display: block;

    &-message {
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
      margin-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
      margin-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
      line-height: var(--line-height-m);
    }

    &-retry-btn {
      display: flex;
      margin-top: var(--spacing-xs)
    }
  }

  &__show-all-monitors {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    line-height: var(--line-height-m);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
