/* stylelint-disable postman-stylelint/color-no-hex-rgb */
:root {
  --min-editor-height: calc(10 * var(--size-m));
}

.mimir {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: visible;
  font-family: var(--text-family-default);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);

  &.edit-mode {
    .media-wrapper {
      pointer-events: none;
    }
    .media-element {
      &:hover {
        .media-handle::after {
          opacity: 1;

          &:hover::after,
          &:focus::after,
          &:active::after {
            opacity: 1;
          }
        }
      }
    }
    .link-element {
      pointer-events: none;
    }

    .image-item {
      cursor: pointer;
    }
  }

  .slate-HeadingToolbar {
    width: 100%;
    min-height: unset;
    border-radius: var(--border-radius-default);
    box-shadow: var(--aether-popover-shadow);
    height: 28px;
    max-height: unset;
    padding: var(--spacing-zero);
    margin: var(--spacing-zero);
    border: var(--spacing-zero);
    background: var(--background-color-primary);
  }

  .toolbar__anchor {
    height: auto;
    background: var(--background-color-primary);
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 100;
    border-radius: var(--border-radius-default);
    width: 100%;
    position: sticky;
    top: var(--spacing-zero);
    opacity: 1;
    visibility: visible;
    transition: opacity 100ms ease-in-out;

    &.hide {
      display: none;
      opacity: 0;
    }
  }

  .toolbar__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-zero) var(--spacing-s);
  }

  .toolbar__info {
    display: flex;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    margin: var(--spacing-s) var(--spacing-zero);

    &:hover {
      background-color: var(--background-color-secondary);
    }

    &.active {
      background-color: var(--background-color-tertiary);
    }
  }

  .toolbar__list {
    height: fit-content;
    padding: var(--spacing-zero);
    margin: var(--spacing-zero);
    flex: 1;
    display: flex;
    overflow: hidden;

    .toolbar__list__divider--vertical {
      height: 50%;
      margin: auto var(--spacing-xs);
      border-left: 1px solid var(--border-color-default);
    }

    .toolbar__list__divider--horizontal {
      width: 100%;
      height: 0;
      margin: var(--spacing-s) var(--spacing-zero);
      border-bottom: 1px solid var(--border-color-default);
    }

    .toolbar__list__item {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: var(--spacing-xs);
      }

      .toolbar__button {
        height: var(--size-s);

        .slate-ToolbarButton {
          height: var(--size-s);
          width: var(--size-s);
          border-radius: 3px;

          svg {
            width: 12px;
            height: 12px;
          }

          svg * {
            fill: var(--content-color-secondary);
          }

          &:hover {
            background: var(--highlight-background-color-secondary);
            color: var(--content-color-primary) !important;

            svg * {
              fill: var(--content-color-primary);
            }
          }

          &-active {
            background-color: var(--highlight-background-color-tertiary);
            color: var(--content-color-primary) !important;

            svg * {
              fill: var(--content-color-primary);
            }
          }
        }
      }
    }

    .postbot__trigger {
      svg {
        color: var(--purple-50) !important;
      }

      &.dark {
        svg {
          color: var(--purple-30) !important;
        }
      }
    }

    .toolbar__dropdown__heading {
      display: flex;
      height: var(--size-s);
      max-width: 95px;
      border-radius: var(--border-radius-default);
      padding: 2px var(--spacing-xs);
      font-family: Inter;
      font-size: var(--text-size-m);
      font-style: normal;
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      letter-spacing: var(--spacing-zero);
      text-align: left;
      align-items: center;
      color: var(--content-color-secondary);
      cursor: pointer;

      & > * + * {
        margin-left: var(--spacing-xs);
      }

      &:hover {
        background: var(--background-color-secondary);
        color: var(--content-color-secondary);
      }

      &--active {
        background-color: var(--background-color-tertiary);
        color: var(--content-color-primary) !important;
      }

      &--disabled {
        color: var(--content-color-tertiary);
        opacity: 0.5;
        pointer-events: none;

        svg {
          width: 12px;
          height: 12px;
        }

        svg * {
          fill: var(--content-color-tertiary);
        }

        &:hover {
          background: var(--background-color-tertiary);
          color: var(--content-color-tertiary);
        }
      }

      & .title {
        &__truncated {
          min-width: 18px;
        }

        &__full-size {
          min-width: 67px;
        }
      }
    }

    .toolbar__dropdown__menu {
      display: flex;
      height: var(--size-s);
      border-radius: var(--border-radius-default);
      font-family: Inter;
      font-size: var(--text-size-m);
      font-style: normal;
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      letter-spacing: var(--spacing-zero);
      text-align: left;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--content-color-secondary);

      & > * + * {
        margin-left: var(--spacing-xs);
      }

      &:hover {
        background: var(--background-color-secondary);
        color: var(--content-color-secondary);
      }

      &--active {
        background-color: var(--background-color-tertiary);
        color: var(--content-color-primary) !important;
      }
    }

    .toolbar__dropdown {
      display: flex;
      width: 200px;
      flex-direction: column;
      position: absolute;
      z-index: 1;
      max-width: 200px;
      font-family: Inter;
      font-size: var(--text-size-m);
      font-style: normal;
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      letter-spacing: var(--spacing-zero);
      text-align: center;
      padding: 2px var(--spacing-zero);
      top: 30px;
      left: 2px;
      background-color: var(--background-color-primary);
      border-radius: var(--border-radius-default);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      box-shadow: var(--shadow-default);

      .slate-ToolbarButton {
        width: inherit !important;
        height: var(--size-m) !important;
        justify-content: flex-start;
        padding: var(--spacing-zero) var(--spacing-l);
        color: var(--content-color-secondary);

        svg {
          width: 12px;
          height: 12px;
        }

        svg * {
          fill: var(--content-color-secondary);
        }

        &:hover {
          background: var(--background-color-secondary);
          color: var(--content-color-secondary) !important;
        }

        &-active {
          background-color: var(--background-color-tertiary);
          color: var(--content-color-primary) !important;

          svg * {
            fill: var(--content-color-primary);
          }
        }
      }

      .toolbar-dropdown-icon {
        display: flex;
        position: relative;
        min-width: 100%;
      }

      .toolbar-dropdown-shortcut-label {
        color: var(--content-color-tertiary);
        font-weight: var(--text-weight-regular);
        position: absolute;
        right: var(--spacing-zero);
      }
    }

    .toolbar-button--disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      height: var(--size-s);
      width: var(--size-s);
      vertical-align: middle;
      color: var(--content-color-tertiary);
      opacity: 0.5;
      pointer-events: none;

      svg {
        width: 12px;
        height: 12px;
      }

      svg * {
        fill: var(--content-color-tertiary);
      }
    }

    .toolbar__dropdown.code {
      left: 162px;
    }

    .toolbar__dropdown.table {
      left: 72px;
    }
  }

  .postbot__description__placeholder {
    position: absolute;
    top: 13px;
    left: 13px;
    z-index: 2;
    color: var(--content-color-tertiary);
  }

  .editor__container {
    width: 100%;
    margin-top: var(--spacing-xs);
    height: max-content;

    & > div {
      overflow-y: auto;
      overflow-x: hidden;
      padding: var(--spacing-l);
      color: var(--content-color-primary);

      & > * {
        margin: var(--spacing-zero);
      }

      & > * + * {
        margin-top: var(--spacing-s);
      }

      & > *:first-child {
        margin-top: var(--spacing-zero) !important;
      }

      p {
        word-break: break-word;
        padding: var(--spacing-zero);

        span[data-slate-placeholder='true'] {
          width: calc(100% - var(--spacing-l)) !important;
        }

        & > * {
          margin: var(--spacing-zero);
        }

        & > * + * {
          margin-top: var(--spacing-l);
        }
      }

      h1 {
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
        font-weight: var(--text-weight-bold);
        margin-top: var(--spacing-xxl);
        color: var(--content-color-primary);
      }

      h2 {
        font-size: 20px;
        line-height: var(--line-height-xxl);
        font-weight: var(--text-weight-bold);
        margin-top: var(--spacing-xl);
        color: var(--content-color-primary);
      }

      h3 {
        font-size: var(--text-size-xxl);
        line-height: var(--line-height-xl);
        font-weight: var(--text-weight-bold);
        margin-top: 20px;
        color: var(--content-color-primary);
      }

      h4 {
        font-size: var(--text-size-xl);
        line-height: var(--line-height-l);
        font-weight: var(--text-weight-bold);
        margin-top: var(--spacing-l);
        color: var(--content-color-primary);
      }

      h5 {
        font-size: var(--text-size-l);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-bold);
        color: var(--content-color-primary);
      }

      h6 {
        font-size: var(--text-size-l);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-bold);
        color: var(--content-color-secondary);
      }

      hr {
        height: 0;
        border-style: none;
        border-width: var(--spacing-zero);
        padding: var(--spacing-zero);
        margin-top: var(--spacing-l);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      }

      .hr--selected {
        hr {
          border-color: var(--blue-70);
        }
      }

      blockquote {
        padding: var(--spacing-zero);
        padding-left: var(--spacing-l);
        border-left: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
        color: var(--content-color-secondary);
        margin-top: var(--spacing-l);
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: var(--text-weight-bold);
      }

      ul,
      ol {
        margin-left: var(--spacing-m);
        padding-left: var(--spacing-m);
        margin-top: var(--spacing-l);

        li {
          padding-left: var(--spacing-xs);
          padding-bottom: var(--spacing-xs);
        }

        p {
          margin: var(--spacing-zero);
        }

        ul,
        ol {
          margin-top: var(--spacing-xs);
        }
      }

      ol {

        li {
          list-style-type: decimal;

          ol {

            li {
              list-style-type: lower-alpha;

              ol {

                li {
                  list-style-type: lower-roman;

                  ol {

                    li {
                      list-style-type: lower-alpha;

                      ol {

                        li {
                          list-style-type: lower-roman;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      code {
        padding: 1px var(--spacing-xs);
        font-size: var(--text-size-m);
        font-family: var(--text-family-code);
        background-color: var(--background-color-tertiary);
        line-height: 1.8;

        p {
          margin: var(--spacing-zero);
        }
      }

      .highlighted-code {
        code {
          background-color: transparent;
          border: none;
          border-radius: 0;
        }
      }

      .slate-table-wrapper {
        width: 100%;
        max-width: 100%;
        overflow-x: overlay;
        overflow-y: hidden;
        cursor: default;
        margin-top: var(--spacing-l);
      }

      table {
        width: 100%;
        border-radius: var(--border-radius-default);
        border-spacing: var(--size-m);
        border-collapse: collapse;
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

        tr {
          height: var(--size-m);
          min-height: var(--size-m);
        }

        th,
        td {
          min-width: 77px;
          max-width: 77px;
          padding: var(--spacing-s);
          word-break: normal;
          vertical-align: top;
          cursor: text;
          border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
          background: transparent;

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }

          & > div {
            padding: 0 !important;
          }

          .slate-TableCellElement-resizableWrapper {
            display: none;
          }

          .slate-TableCellElement-content {
            z-index: 0 !important;
          }
        }

        th {
          border-top: none;
          text-align: left;
          font-weight: 600;
          background-color: var(--background-color-secondary);
        }

        tr {
          &:last-child {
            th,
            td {
              border-bottom: none;
            }
          }
        }
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }

      iframe {
        display: block;
        max-width: 100%;
      }

      .slate-codeblock-wrapper {
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
        border-radius: var(--border-radius-default);
        background-color: var(--background-color-primary);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: var(--spacing-l);

        .codeblock__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: var(--spacing-s);

          &__left {
            .codeblock__dropdown {
              .aether-dropdown__control {
                margin-top: var(--spacing-zero) !important;
                width: 130px !important;
                background-color: var(--background-color-secondary);
                border: none;
                cursor: pointer;

                .css-b8ldur-Input {
                  margin-top: var(--spacing-zero) !important;
                }
              }

              .aether-dropdown__indicators {
                margin-top: var(--spacing-zero) !important;

                .aether-dropdown__dropdown-indicator {
                  margin-top: var(--spacing-zero) !important;
                }
              }
            }
          }

          &__right {
            display: flex;
            margin: var(--spacing-zero);
            & > * {
              margin: var(--spacing-zero);
            }
          }
        }

        .codeblock__button {
          margin-left: var(--spacing-s);
          border: var(--border-width-default) var(--border-style-solid) transparent;
          &.word-wrapped {
            background: var(--highlight-background-color-primary);
            border-color: var(--border-color-strong);
          }
        }

        .codeblock__content {
          padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
          margin: var(--spacing-zero);
          color: var(--content-color-primary);
          max-height: var(--container-max-height);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          position: relative;
          flex: 1;

          code {
            display: flex;
            flex-direction: column;
            color: var(--content-color-secondary) !important;
            background-color: transparent;
            border: none;
            border-radius: var(--spacing-zero);
            font-family: var(--text-family-code);
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            margin: var(--spacing-zero);
            padding: var(--spacing-zero);

            &.word-wrapped {
              overflow-wrap: break-word;
              white-space: pre-wrap;

              .syntax-highlighted-code {
                white-space: pre-wrap;
              }
            }
          }
        }
      }

      .slate-mention, .slate-mention_input {
        margin-top: var(--spacing-zero);
        font-size: var(--text-size-m);
        padding: var(--spacing-zero) 2px;
      }

      .slate-mention_input {
        background-color: var(--highlight-background-color-tertiary);
      }
    }
  }
}

.mimir-small {
  position: relative;
  // min-height: calc(10 * var(--size-m));
  height: 100%;
  width: 100%;
  overflow: visible;
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  // margin: calc(var(--spacing-l) * -1) var(--spacing-zero);

  &.edit-mode {
    .media-wrapper {
      pointer-events: none;
    }
    .media-element {
      &:hover {
        .media-handle::after {
          opacity: 1;
          &:hover::after,
          &:focus::after,
          &:active::after {
            opacity: 1;
          }
        }
      }
    }
    .link-element {
      pointer-events: none;
    }

    .image-item {
      cursor: pointer;
    }
  }

  .slate-HeadingToolbar {
    height: 40px;
    max-height: unset;
    padding: var(--spacing-zero);
    margin: var(--spacing-zero);
    border: var(--spacing-zero);
    box-shadow: var(--shadow-default);
    border-radius: var(--border-radius-default) var(--border-radius-default) var(--spacing-zero) var(--spacing-zero);
    background: var(--background-color-primary);
  }

  .toolbar__anchor {
    width: 100%;
    position: absolute;
    top: var(--spacing-zero);
    z-index: 3;
    opacity: 1;
    visibility: visible;
    transition: opacity 100ms ease-in-out;
    &.hide {
      display: none;
      opacity: 0;
    }
  }

  .toolbar__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-zero) var(--spacing-s);
  }

  .toolbar__info {
    display: flex;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    margin: var(--spacing-s) var(--spacing-zero);

    &:hover {
      background-color: var(--background-color-secondary);
    }

    &.active {
      background-color: var(--background-color-tertiary);
    }
  }

  .toolbar__list {
    padding: var(--spacing-zero);
    margin: var(--spacing-zero);
    flex: 1;
    display: flex;
    overflow: hidden;

    .toolbar__list__divider--vertical {
      height: 50%;
      margin: auto var(--spacing-xs);
      border-left: 1px solid var(--border-color-default);
    }

    .toolbar__list__divider--horizontal {
      width: 100%;
      height: 0;
      margin: var(--spacing-s) var(--spacing-zero);
      border-bottom: 1px solid var(--border-color-default);
    }

    .toolbar__list__item {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: var(--spacing-xs);
      }

      .toolbar__button {
        height: var(--size-s);

        .slate-ToolbarButton {
          height: var(--size-s);
          width: var(--size-s);
          border-radius: 3px;

          svg {
            width: 12px;
            height: 12px;
          }

          svg * {
            fill: var(--content-color-secondary);
          }

          &:hover {
            background: var(--highlight-background-color-secondary);
            color: var(--content-color-primary) !important;

            svg * {
              fill: var(--content-color-primary);
            }
          }

          &-active {
            background-color: var(--highlight-background-color-tertiary);
            color: var(--content-color-primary) !important;

            svg * {
              fill: var(--content-color-primary);
            }
          }
        }
      }
    }

    .toolbar__dropdown__heading {
      display: flex;
      height: var(--size-s);
      max-width: 95px;
      border-radius: var(--border-radius-default);
      padding: 2px var(--spacing-xs);
      font-family: Inter;
      font-size: var(--text-size-m);
      font-style: normal;
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      letter-spacing: var(--spacing-zero);
      text-align: left;
      align-items: center;
      color: var(--content-color-secondary);

      & > * + * {
        margin-left: var(--spacing-xs);
      }

      &:hover {
        background: var(--background-color-secondary);
        color: var(--content-color-secondary);
      }

      &--active {
        background-color: var(--background-color-tertiary);
        color: var(--content-color-primary) !important;
      }

      &--disabled {
        color: var(--content-color-tertiary);
        opacity: 0.5;
        pointer-events: none;

        svg {
          width: 12px;
          height: 12px;
        }

        svg * {
          fill: var(--content-color-tertiary);
        }

        &:hover {
          background: var(--background-color-tertiary);
          color: var(--content-color-tertiary);
        }
      }

      & .title {
        &__truncated {
          min-width: 18px;
        }

        &__full-size {
          min-width: 67px;
        }
      }
    }

    .toolbar__dropdown__menu {
      display: flex;
      height: var(--size-s);
      border-radius: var(--border-radius-default);
      font-family: Inter;
      font-size: var(--text-size-m);
      font-style: normal;
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      letter-spacing: var(--spacing-zero);
      text-align: left;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--content-color-secondary);

      & > * + * {
        margin-left: var(--spacing-xs);
      }

      &:hover {
        background: var(--background-color-secondary);
        color: var(--content-color-secondary);
      }

      &--active {
        background-color: var(--background-color-tertiary);
        color: var(--content-color-primary) !important;
      }
    }

    .toolbar__dropdown {
      display: flex;
      width: 200px;
      flex-direction: column;
      position: absolute;
      z-index: 1;
      max-width: 200px;
      font-family: Inter;
      font-size: var(--text-size-m);
      font-style: normal;
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      letter-spacing: var(--spacing-zero);
      text-align: center;
      padding: 2px var(--spacing-zero);
      top: 40px;
      left: 2px;
      background-color: var(--background-color-primary);
      border-radius: var(--border-radius-default);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      box-shadow: var(--shadow-default);

      .slate-ToolbarButton {
        width: inherit !important;
        height: var(--size-m) !important;
        justify-content: flex-start;
        padding: var(--spacing-zero) var(--spacing-l);
        color: var(--content-color-secondary);

        svg {
          width: 12px;
          height: 12px;
        }

        svg * {
          fill: var(--content-color-secondary);
        }

        &:hover {
          background: var(--background-color-secondary);
          color: var(--content-color-secondary) !important;
        }

        &-active {
          background-color: var(--background-color-tertiary);
          color: var(--content-color-primary) !important;

          svg * {
            fill: var(--content-color-primary);
          }
        }
      }

      .toolbar-dropdown-icon {
        display: flex;
        position: relative;
        min-width: 100%;
      }

      .toolbar-dropdown-shortcut-label {
        color: var(--content-color-tertiary);
        font-weight: var(--text-weight-regular);
        position: absolute;
        right: var(--spacing-zero);
      }
    }

    .toolbar-button--disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      height: var(--size-s);
      width: var(--size-s);
      vertical-align: middle;
      color: var(--content-color-tertiary);
      opacity: 0.5;
      pointer-events: none;

      svg {
        width: 12px;
        height: 12px;
      }

      svg * {
        fill: var(--content-color-tertiary);
      }
    }

    .toolbar__dropdown.code {
      left: 162px;
    }

    .toolbar__dropdown.table {
      left: 72px;
    }
  }

  .editor__container {
    position: absolute;
    width: 100%;
    margin-top: 40px;
    height: calc(100% - 40px);

    & > div {
      max-height: calc(100% - 64px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: var(--spacing-l);
      color: var(--content-color-primary);
    }

    & div {
      & > * {
        margin: var(--spacing-zero);
      }

      & > * + * {
        margin-top: var(--spacing-l);
      }

      p {
        word-break: break-word;
        padding: var(--spacing-zero);
        margin: 1em var(--spacing-zero);

        span[data-slate-placeholder='true'] {
          width: calc(100% - var(--spacing-l)) !important;
        }

        & > * {
          margin: var(--spacing-zero);
        }

        & > * + * {
          margin-top: var(--spacing-l);
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: var(--content-color-primary);
        font-weight: var(--text-weight-medium);
      }

      // http://sndsgn.github.io/headings/
      h1 {
        // font-size: 2em;
        // margin: 0.67em 0;
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
      }
      h2 {
        // font-size: 1.5em;
        // margin: 0.83em 0;
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
      }
      h3 {
        // font-size: 1.17em;
        // margin: 1em 0;
        font-size: var(--text-size-xxl);
        line-height: var(--line-height-xxl);
      }
      h4 {
        // font-size: 1em;
        // margin: 1.33em 0;
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
      }
      h5 {
        // margin: 1.67em 0;
        font-size: 0.83em;
      }
      h6 {
        // margin: 2.33em 0;
        font-size: 0.67em;
      }

      ul li {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
      }

      /**
      * As there we no mockups in artemis-v2 Figma file, copied the
      * same styles as present in the public & private documentation
      */
      h1 * {
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
      }

      h2 * {
        font-size: var(--text-size-xxl);
        line-height: var(--line-height-xxl);
      }

      h3 * {
        font-size: var(--text-size-xl);
        line-height: var(--line-height-xl);
      }

      h4 * {
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
      }

      h5 * {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
      }

      h6 * {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        color: var(--content-color-secondary);
      }

      hr {
        height: 0;
        border-style: none;
        border-width: var(--spacing-zero);
        padding: var(--spacing-zero);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      }
      .hr--selected {
        hr {
          border-color: var(--blue-70);
        }
      }
      blockquote {
        padding: var(--spacing-zero);
        padding-left: var(--spacing-l);
        border-left: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
        color: var(--content-color-secondary);
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: var(--text-weight-medium);
      }

      ul,
      ol {
        margin-left: var(--spacing-s);
        padding-left: var(--spacing-m);
        p {
          margin: var(--spacing-zero);
        }
      }

      ol {
        li {
          list-style-type: decimal;
          ol {
            li {
              list-style-type: lower-alpha;
              ol {
                li {
                  list-style-type: lower-roman;
                  ol {
                    li {
                      list-style-type: lower-alpha;
                      ol {
                        li {
                          list-style-type: lower-roman;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      code {
        padding: 1px var(--spacing-xs);
        font-size: var(--text-size-m);
        font-family: var(--text-family-code);
        background-color: var(--background-color-tertiary);
        p {
          margin: var(--spacing-zero);
        }
      }
      .highlighted-code {
        code {
          background-color: transparent;
          border: none;
          border-radius: 0;
        }
      }
      .slate-table-wrapper {
        width: 100%;
        max-width: 100%;
        overflow-x: overlay;
        overflow-y: hidden;
        cursor: default;
      }

      table {
        width: 100%;
        border-radius: var(--border-radius-default);
        border-spacing: var(--size-m);
        border-collapse: collapse;
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
        tr {
          height: var(--size-m);
          min-height: var(--size-m);
        }
        th,
        td {
          min-width: 77px;
          max-width: 77px;
          padding: var(--spacing-s);
          word-break: normal;
          vertical-align: top;
          cursor: text;
          border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
          background: transparent;

          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }

          & > div {
            padding: 0 !important;
          }

          .slate-TableCellElement-resizableWrapper {
            display: none;
          }

          .slate-TableCellElement-content {
            z-index: 0 !important;
          }
        }
        th {
          border-top: none;
          text-align: left;
          font-weight: 600;
          background-color: var(--background-color-secondary);
        }
        tr {
          &:last-child {
            th,
            td {
              border-bottom: none;
            }
          }
        }
      }
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
      iframe {
        display: block;
        max-width: 100%;
      }
      .slate-codeblock-wrapper {
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
        border-radius: var(--border-radius-default);
        background-color: var(--background-color-primary);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .codeblock__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: var(--spacing-s);
          &__left {
            .codeblock__dropdown {
              .aether-dropdown__control {
                margin-top: var(--spacing-zero) !important;
                width: 130px !important;
                background-color: var(--background-color-secondary);
                border: none;
                cursor: pointer;
                .css-b8ldur-Input {
                  margin-top: var(--spacing-zero) !important;
                }
              }
              .aether-dropdown__indicators {
                margin-top: var(--spacing-zero) !important;
                .aether-dropdown__dropdown-indicator {
                  margin-top: var(--spacing-zero) !important;
                }
              }
            }
          }
          &__right {
            display: flex;
            margin: var(--spacing-zero);
            & > * {
              margin: var(--spacing-zero);
            }
          }
        }
        .codeblock__button {
          margin-left: var(--spacing-s);
          border: var(--border-width-default) var(--border-style-solid) transparent;
          &.word-wrapped {
            background: var(--highlight-background-color-primary);
            border-color: var(--border-color-strong);
          }
        }
        .codeblock__content {
          padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
          margin: var(--spacing-zero);
          color: var(--content-color-primary);
          max-height: var(--container-max-height);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          position: relative;
          flex: 1;
          code {
            display: flex;
            flex-direction: column;
            color: var(--content-color-secondary) !important;
            background-color: transparent;
            border: none;
            border-radius: var(--spacing-zero);
            font-family: var(--text-family-code);
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            margin: var(--spacing-zero);
            padding: var(--spacing-zero);
            &.word-wrapped {
              overflow-wrap: break-word;
              white-space: pre-wrap;
              .syntax-highlighted-code {
                white-space: pre-wrap;
              }
            }
          }
        }
      }

      .slate-mention, .slate-mention_input {
        margin-top: var(--spacing-zero);
        font-size: var(--text-size-m);
        padding: var(--spacing-zero) 2px;
      }
      .slate-mention_input {
        background-color: var(--highlight-background-color-tertiary);
      }
    }
  }
}

.toolbar__tooltip-body {
  margin: var(--spacing-s) var(--spacing-m);
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  color: var(--content-color-primary);
  &-shortcutKeys {
    color: var(--content-color-tertiary);
  }
}

.prism-token {
  /** prism-coy theme */
  &.token.comment,
  &.token.block-comment,
  &.token.prolog,
  &.token.doctype,
  &.token.cdata {
    color: #7d8b99;
  }

  &.token.punctuation {
    color: #5f6364;
  }

  &.token.tag {
    padding: 0 !important;
  }

  &.token.property,
  &.token.tag,
  &.token.boolean,
  &.token.number,
  &.token.function-name,
  &.token.constant,
  &.token.symbol,
  &.token.deleted {
    color: #c92c2c;
  }

  &.token.selector,
  &.token.attr-name,
  &.token.string,
  &.token.char,
  &.token.function,
  &.token.builtin,
  &.token.inserted {
    color: #2f9c0a;
  }

  &.token.operator,
  &.token.entity,
  &.token.url,
  &.token.variable {
    color: #a67f59;
  }

  &.token.atrule,
  &.token.attr-value,
  &.token.keyword,
  &.token.class-name {
    color: #1990b8;
  }

  &.token.regex,
  &.token.important {
    color: #e90;
  }

  &.language-css .token.string,
  &.style .token.string {
    color: #a67f59;
  }

  &.token.important {
    font-weight: normal;
  }

  &.token.bold {
    font-weight: bold;
  }
  &.token.italic {
    font-style: italic;
  }

  &.token.entity {
    cursor: help;
  }

  &.token.namespace {
    opacity: 0.7;
  }
}

.token.tag {
  padding: 0 !important;
}

comment {
  display: none;
}

.mimir-settings-modal {
  .modal-content {
    overflow-y: visible;
  }
}

.mimir-loading-indicator {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slate-toolbar-tooltip {
  .arrow {
    filter: drop-shadow(-2px 3px 2px rgba(0, 0, 0, 0.1));
  }
}

.image-element {
  animation: fadeIn 300ms ease-in-out;
  margin-top: var(--spacing-l) !important;

  &.selected {
    .image-item {
      box-shadow: rgb(59, 130, 249) 0px 0px 0px 1px;
      opacity: 0.85;
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 2px;
  }

  .image-item {
    animation: fadeIn 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    margin: auto;
    cursor: zoom-in;
  }

  .image-resize {
    &:hover {
      .image-handle::after {
        opacity: 1;
      }
    }

    .image-caption {
      width: 100%;
      border-style: none;
      margin-top: var(--spacing-s);
      padding: 0px;
      resize: none;
      text-align: center;
      overflow: hidden;
      background-color: transparent;
      color: var(--content-color-primary);

      &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;

        &::placeholder {
          opacity: 0;
        }
      }

      & + div {
        margin-top: 0;
      }
    }

    .image-handle {
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      position: absolute;
      user-select: none;
      width: 1.5rem;
      height: 100%;
      top: 0px;
      z-index: 10;

      &::after {
        display: flex;
        background-color: var(--content-color-info);
        content: ' ';
        width: 3px;
        height: 64px;
        max-height: 30%;
        border-radius: 6px;
        opacity: 0.3;
      }

      &:hover::after,
      &:focus::after,
      &:active::after {
        background-color: var(--content-color-info);
        opacity: 1;
      }

      &.handle-left {
        left: -0.1rem;
      }

      &.handle-right {
        align-items: flex-end;
        right: -0.4rem;
      }
    }
  }

  .image-loader {
    background-color: var(--background-color-secondary);
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 20em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-default);
    animation: fadeIn 300ms ease-in-out;

    &.image-loader-data {
      position: relative;
      width: auto;
      min-height: auto;

      .image-loader-preview {
        filter: blur(3px);
      }

      .image-loader-indicator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .image-loader-progress {
      width: 80%;
      height: 10px;
      position: relative;
      overflow: hidden;
      border-radius: calc(2 * var(--border-color-default));
      background-color: var(--background-color-primary);

      &-bar {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        justify-self: end;
        background-color: var(--highlight-background-color-tertiary);
        border-radius: calc(2 * var(--border-color-default));
      }
    }
  }
}

.media-element {
  animation: fadeIn 300ms ease-in-out;
  margin-top: var(--spacing-l) !important;

  &.selected {
    .media-item {
      box-shadow: rgb(59, 130, 249) 0px 0px 0px 1px;
      opacity: 0.85;
    }
  }

  .media-plus-caption-wrapper {
    display: flex;
    justify-content: center;
  }

  .media-item {
    border-style: none;
    animation: fadeIn 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    cursor: pointer;
  }

  .media-resize {
    &:hover {
      .media-handle::after {
        opacity: 0;
      }
    }

    &.iFrameLoading {
      display: none;
    }

    .media-caption {
      width: 100%;
      border-style: none;
      margin-top: var(--spacing-s);
      padding-bottom: var(--spacing-s);
      resize: none;
      text-align: center;
      overflow: hidden;
      background-color: transparent;
      color: var(--content-color-primary);

      &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      & + div {
        margin-top: 0;
      }
    }

    .media-handle {
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      position: absolute;
      user-select: none;
      width: 1.5rem;
      height: 100%;
      top: 0px;
      z-index: 10;

      &::after {
        opacity: 0;
        display: flex;
        background-color: var(--content-color-info);
        content: ' ';
        width: 3px;
        height: 64px;
        max-height: 30%;
        border-radius: 6px;
      }

      &:hover::after,
      &:focus::after,
      &:active::after {
        background-color: var(--content-color-info);
        opacity: 0;
      }

      &.handle-left {
        left: -0.1rem;
      }

      &.handle-right {
        align-items: flex-end;
        right: -0.4rem;
      }
    }
  }

  .media-loader {
    background-color: var(--background-color-secondary);
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 20em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-default);
    cursor: pointer;
  }
}

.link-element {
  .image-item {
    cursor: pointer;
  }
}

.toolbar-shortcut-label {
  color: var(--content-color-tertiary);
}

.link-element {
  color: var(--content-color-link);
  text-decoration: initial;

  :hover,
  :visited:hover {
    text-decoration: underline;
  }
}
