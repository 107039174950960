.schema-editor-view {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  min-height: 0;

  .schema-editor-view__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--background-color-primary);
    overflow-y: hidden;
  }

}
