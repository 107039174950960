.flatpickr-confirm {
  display: inline-flex;
  width: 50%;
  box-sizing: border-box;
  border: var(--border-width-default) solid var(--border-color-strong);
  background: var(--background-color-secondary);
  color: var(--content-color-primary);
}

.flatpickr-confirm:focus {
  outline: none;
}

.flatpickr-confirm:hover {
  background-color: var(--highlight-background-color-secondary);
}
