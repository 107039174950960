.monitor-tab-error {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      color: var(--content-color-primary);
      margin-bottom: var(--spacing-s);
    }

    &__body {
      color: var(--content-color-secondary);
    }
  }
}
