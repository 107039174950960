.export-collection-modal__message__collection-name {
  font-weight: 600;
}

.export-collection-modal__onboarding-recommendation-viewer {
  padding: 8px 8px 0 8px;
  background-color: var(--background-color-primary);
}

.export-collection-modal__subtext {
  margin-top: 20px;
  font-size: 13px;
  color: var(--content-color-secondary);

  .export-collection-modal__subtext__learn-more {
    color: var(--content-color-link);
    text-decoration: underline;

    @include clickable;
  }
}
