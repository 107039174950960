.response-viewer-tab-contents {
  flex: 1 auto;
  display: flex;
  padding: var(--spacing-s) var(--spacing-l);
  padding-left: var(--spacing-l);
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;

  &:has(.refresh-tests-button-wrapper),
  &:has(.response-tests-viewer) {
    padding-top: var(--spacing-xs);
    padding-inline: var(--spacing-s) var(--spacing-l);
  }

  &:has(.response-cookies-table),
  &:has(.response-header-viewer__table),
  &:has(.response-header-editor) {
    padding-inline: var(--spacing-l) var(--spacing-xs); // scrollbar gutter takes up 12px so padding right needs to account for this
    overflow: auto;
    scrollbar-gutter: stable; // ensures that the pane content doesn't shift when the scrollbar is shown
  }

  .response-viewer-tab-content {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.response-viewer-tab-content {
  &.is-hidden {
    display: none;
  }
}
