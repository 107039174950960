.monitor-list-item-health-status {
  --bullet-size: 8px;

  width: var(--bullet-size);
  height: var(--bullet-size);
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  cursor: default;

  &.healthy, &.up {
    @include icon("../assets/images/thumbnails/monitor_success.svg", 12px, 12px);
  }

  &.unhealthy, &.down {
    @include icon("../assets/images/thumbnails/monitor_error.svg", 12px, 12px);
  }

  &.paused {
    @include icon("../assets/images/thumbnails/monitor_pause.svg", 12px, 12px);
  }
  &.unknown {
    @include icon("../assets/images/thumbnails/monitor_unknown.svg", 12px, 12px);
  }
}
