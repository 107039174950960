
.loading-integrations-cards__list {
  animation: fade 1s ease-in-out infinite;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  & > .loading-integrations-cards__list-item:not(:last-child) {
    margin-right: var(--spacing-xl);
  }

  &-item {
    display: flex;
    margin-bottom: var(--spacing-xl);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid var(--border-color-default);
    border-radius: var(--border-radius-default);

    padding: var(--spacing-l);
    width: 240px;
    min-height: 160px;

    &--heading-wrapper,
    &--heading {
      display: flex;

    }

    &--heading-wrapper {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: var(--spacing-s);
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    &--heading {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &--icon-container {
      height: 48px;
      width: 48px;
      border-radius: var(--border-radius-default);
      background: var(--background-color-secondary);
      position: relative;
      margin-bottom: var(--spacing-xs);
    }
    &--icon {
      height: 40px;
      width: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
      background: center / contain no-repeat;
    }

    &--desc {
      text-align: left;
      color: var(--content-color-secondary);
    }
    &--desc-line1,
    &--desc-line2,
    &--desc-line3{
      background-color: var(--background-color-tertiary);
      margin-bottom: var(--spacing-m);
      height: 12px;
    }
  }

  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
}

.loading-integration-categories-sidebar {
  animation: fade 1s ease-in-out infinite;

  position: sticky;
  top: 0;
  width: 270px;
  height: 100vh;
  float: left;
  overflow-y: auto;
  padding: var(--spacing-m) var(--spacing-xl) 0 0;
  text-transform: capitalize;

  &__list-wrapper {
    padding: 0 var(--spacing-s);
  }

  &__list-wrapper-heading {
    background-color: var(--background-color-tertiary);
    margin-bottom: var(--spacing-m);
    height: 24px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin-top: var(--spacing-xxl);
    & > &--item:not(:last-child) {
      margin-bottom: var(--spacing-xl);
    }

    &--item {
      padding: 0px var(--spacing-s);
      background-color: var(--background-color-tertiary);
      height: 28px;
    }
  }

  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }

}

.loading-browse-integrations-page {
  width:  1280px;
  margin: auto;


  .input-search-group {
    margin-bottom: var(--spacing-xl);
    width: 215px;
    border-radius: var(--border-radius-default);
    height: var(--size-m);
  }
  &-heading {
    animation: fade 1s ease-in-out infinite;

    background-color: var(--background-color-tertiary);
    margin-bottom: var(--spacing-xxxl);
    height: 28px;
    margin-left: var(--spacing-s);
  }
}

.loading-browse-integrations__list {
  animation: fade 1s ease-in-out infinite;

  overflow: hidden;
  padding: var(--spacing-xl) var(--spacing-xxl) var(--spacing-l) var(--spacing-xxl);
  position: relative;

  &--text {
    background-color: var(--background-color-tertiary);
    margin-bottom: var(--spacing-l);
    height: 28px;
  }
  &--search {
    background-color: var(--background-color-tertiary);
    margin-bottom: var(--spacing-l);
    height: 28px;
  }

  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
}
