.workspaces-page {
  /* Remove default margins and padding */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd,
  ul,
  ol {
    margin: 0;
    padding: 0;
  }

  /* Remove list styles on ul, ol elements  */
  ul,
  ol {
    list-style: none;
  }

  display: flex;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: unset;

  &-container {
    width: 960px;
    padding: var(--spacing-xl);
    box-sizing: border-box;

    &-breadcrumbs {
      margin-bottom: var(--spacing-m);
    }

    &-public-workspaces {
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      box-sizing: border-box;
      border-radius: var(--border-radius-default);
      flex-direction: column;
      margin-bottom: var(--spacing-xxl);
      padding-bottom: var(--spacing-l);

      .pm-link {
        text-decoration: none;
        font-weight: var(--text-weight-regular);
        &:hover {
            text-decoration: underline;
            color: var(--content-color-link);
        }
      }

      strong {
        font-weight: var(--text-weight-medium);
      }

      .btn {
        &.btn-secondary {
          margin-right: var(--spacing-l);
          margin-top: var(--spacing-xs);
        }
      }

      &-show-all {
        margin-top: var(--spacing-l);
        margin-left: var(--spacing-l);
        font-weight: var(--text-weight-small);
      }

      &-request {
        display: flex;
        margin-top: var(--spacing-l);
        margin-left: var(--spacing-l);
      }

      .avatar {
        margin-right: var(--spacing-l);
      }

      &-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: var(--spacing-s);

        &-description {
          color: var(--content-color-secondary);
          word-break: break-word;
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
        }

        &-actions {
          display: flex;
          justify-content: space-between;

          &-time {
            color: var(--content-color-tertiary);
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
          }
          &-button {
            margin-right: var(--spacing-l);
            margin-top: var(--spacing-xs);
          }
        }
      }
    }

    &-filters {
      display: flex;
      justify-content: space-between;

      &-label {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        color: var(--content-color-secondary);
        margin-right: var(--spacing-xs);
      }

      &-label-workspace {
        color: var(--content-color-primary);
        display: flex;
        align-items: center;
      }
    }
    .ws-list-empty-state{
      margin-top: var(--spacing-xxxl);
      max-height: 480px;
    }
  }
}

.workspaces-page-container-loader {
  display: flex;
  align-items: center;
  min-height: 50%;
  width: 100%;
  justify-content: center;
}

.workspaces-page-pagination-loader {
  display: flex;
  align-items: center;
  height: 76px;
  width: 100%;
  justify-content: center;
  border-top: var(--border-width-default) solid var(--highlight-background-color-primary);
}


.workspace-visibility-dropdown {
  max-width: 116px;

  &__header {
    padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs);
  }

  .btn.workspace-visibility-dropdown__unselected {
    color: var(--content-color-tertiary);
  }

  &__unselected {
    color: var(--content-color-tertiary);
  }

  &__item {
    &__icon {
      margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xl);

      &--active {
        margin-left: var(--spacing-s);
      }
    }
  }

  .btn{
    border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-primary);
    font-weight:var(--text-weight-regular);
    color: var(--content-color-primary)
  }

  .dropdown-menu{
    background-color: var(--background-color-primary);
  }

  .is-active {
    color: var(--content-color-secondary);
    transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
    border-radius: var(--border-radius-default);
    box-shadow: 0px 0px 0px 2px var(--blue-30);
  }

  &__dark-mode {
    .is-active {
      color: var(--content-color-secondary);
      transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
      border: var(--border-width-default) var(--border-style-solid) var(--blue-50);
      border-radius: var(--border-radius-default);
      box-shadow: 0px 0px 0px 2px var(--blue-50);
    }
  }
}

.workspace-sort-dropdown {
  max-width: 208px;

  &__header {
    padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs);
  }

  &__item {
    &__icon {
      margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xl);

      &--active {
        margin-left: var(--spacing-s);
      }
    }
  }

  .btn{
    border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-primary);
    font-weight:var(--text-weight-regular);
    color: var(--content-color-primary);

    .label {
      color: var(--content-color-secondary);
    }
  }

  .dropdown-menu{
    background-color: var(--background-color-primary);
  }

  .is-active {
    color: var(--content-color-secondary);
    transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
    border-radius: var(--border-radius-default);
    box-shadow: 0px 0px 0px 2px var(--blue-30);
  }

  &__dark-mode {
    .is-active {
      color: var(--content-color-secondary);
      transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
      border: var(--border-width-default) var(--border-style-solid) var(--blue-50);
      border-radius: var(--border-radius-default);
      box-shadow: 0px 0px 0px 2px var(--blue-50);
    }
  }
}

@import "./workspace-list";
@import "./workspace-page-discoverable-teams";
@import "./workspace-list-loading-state";
