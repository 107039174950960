.cookies-management-modal-content-wrapper {
  margin-bottom: var(--spacing-s);

  .cookies-management-modal-content {
    height: 620px;
    .cookies-unavailable {
      display: flex;
      align-items: center;
      justify-content: center;

      .cookies-unavailable__icon {
        @include icon(
          '../assets/images/empty-state/cookie.svg',
          120px,
          120px
        )
      }

      .cookies-unavailable__message {
        white-space: pre-line;
        width: 416px;
      }
    }
  }
}

.cookie-jar-modal-tabs {
  padding: var(--spacing-zero) !important;
  margin-top: calc(-1 * var(--spacing-xs));
}

.cookie-jar-add-domain-button-container {
  width: calc(var(--size-s) * 4)
}

.cookies-management-modal {
  margin-bottom: var(--spacing-m);
}

