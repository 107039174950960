$integration-icon-dimension: 120px;
$breakpoint-md: 768px !default;


.loading-integration-service {
  &__overview {
    display: flex;
    flex-direction: column;
    animation: fade 1s ease-in-out infinite;

    @media (min-width: $breakpoint-md) {
      width: 288px;
      margin-right: var(--spacing-xl);
    }
  }

  &__detailed-view {
    margin-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
    min-height: 640px;
    animation: fade 1s ease-in-out infinite;

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
      padding-bottom: initial;
    }
    &-heading {
      background-color: var(--background-color-tertiary);
      margin-bottom: var(--spacing-m);
      height: 32px;
    }
  }

  &__details-wrapper {
    padding: var(--spacing-xl) 0 var(--spacing-l) var(--spacing-zero);
  }


  &__icon-wrapper {
    height: 32px;
    width: 32px;
    position: absolute;
    top: var(--spacing-l);
    left: var(--spacing-l);
    border: 1px solid var(--border-color-default);
    box-sizing: border-box;
    border-radius: var(--border-radius-s);
    margin-right: var(--spacing-l);

    @media (min-width: $breakpoint-md) {
      height: 120px;
      width: 120px;
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__icon {
    width: 100%;
    height: auto;
    background-color: var(--background-color-tertiary);
    @media (min-width: $breakpoint-md) {
      width: 100%;
      height: auto;
    }
  }

  &__name {
    margin-bottom: var(--spacing-l);
    background-color: var(--background-color-tertiary);
    height: 32px;

    @media (min-width: $breakpoint-md) {
      margin: 0px;
    }
  }

  &__description {
    margin: var(--spacing-xs) 0 0 40px;
    line-height: var(--line-height-s);

    @media (min-width: $breakpoint-md) {
      margin: var(--spacing-s) 0 var(--spacing-l);
    }
  }

  &__extended-description-line-1,
  &__extended-description-line-2,
  &__extended-description-line-3{
    background-color: var(--background-color-tertiary);
    margin-bottom: var(--spacing-m);
    height: 12px;
  }

  &__actions {
    margin: 0;
    padding: 0;
  }

  &__metadata {
    $cell-height: 36px;
    $icon-width: 30px;

    position: relative;
    min-height: $cell-height;
    line-height: $cell-height;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-xl);

    &--categories-text {
      background-color: var(--background-color-tertiary);
      height: 16px;
    }
  }

  &__types-list {
    @include unstyled-list;
    animation: fade 1s ease-in-out infinite;

    > li:first-child {
      padding-top: 20px;
    }
    > li:last-child {
      border-bottom: none;
    }
    li {
      width: 100%;
    }
  }

  &__types-item {

    &__status-summary {
      clear: both;
      padding-top: var(--spacing-l);

      &--link {
        text-decoration: none;
      }
    }

    &__configured {
      clear: both;
      line-height: var(--line-height-m);
      background-color: var(--background-color-tertiary);
      height: 20px;
      margin-top: var(--spacing-m);
    }

    border-radius: var(--border-radius-s);
    border-bottom: 1px solid var(--grey-10);
    padding: var(--spacing-l) 0;

    @media (min-width: $breakpoint-md) {
      padding: var(--spacing-l) 0;
    }
  }

  &__types-item-info {
    padding: 0;
  }

  &__types-item-actions {
    padding: 0;
    margin-top: var(--spacing-s);
    background-color: var(--background-color-tertiary);
    height: 32px;
    .btn-secondary {
      height: 32px;
      padding: var(--spacing-s) var(--spacing-m);
    }

    @media (min-width: $breakpoint-md) {
      text-align: right;
      margin-top: 0;
    }
  }
  &__types-item-actionables {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-description {
      display: flex;
      flex-direction: column;
      flex-basis: 75%;
      &-heading {
        background-color: var(--background-color-tertiary);
        margin-bottom: var(--spacing-m);
        height: 16px;
      }
      &-body {
        background-color: var(--background-color-tertiary);
        margin-bottom: var(--spacing-m);
        height: 16px;
      }
    }


  }
}

.integration-service__metadata.search-result-badge{
  margin-left: 0;
}
