.mock-create-container {
  flex-direction: column;
  display: flex;
  height: 100%;
  overflow: auto;
  padding:  var(--spacing-xxl);
  font-size: var(--text-size-m);

  .mock-create-container__content {
    display: flex;
    flex-direction: column;
    color: var(--content-color-primary);
    overflow: auto;
    padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-xs);

    .step1 {
      &.create-mock-form__body {
        display: flex;
        flex-direction: column;
      }

      &.is-loading {
        flex: 1;
        align-items: center;
        justify-content: center
      }

      .create-mock-form__body__input-group {
        margin-bottom: var(--spacing-l);

        .create-mock-form__body__label {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          margin-bottom: var(--spacing-s);
          font-weight: var(--text-weight-medium);

          &.is-error {
            color: var(--base-color-error);
          }
        }

        .create-mock-form__body__input {
          display: flex;

          .create-mock-form__body__input__helptext {
            padding-left: var(--spacing-xxl);
            color: var(--content-color-tertiary);
            max-width: 522px;

            // To be removed once Text component supports customizing text width using prop
            &__content {
              max-width: 100%;
            }

            .learn-more-link {
              color: var(--content-color-link);
              font-weight: var(--text-weight-regular);
            }
          }

          .create-mock-form__body__field {
            display: flex;
            width: 350px;
            height: 100%;

            &__label {
              cursor: pointer;
              font-weight: var(--text-weight-medium);
            }

            .environment-dropdown__button-label {
              @include text-truncate-ellipsis;
            }

            .input-box {
              border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
            }

            .input-box__error {
              border: var(--border-width-default) var(--border-style-solid) var(--content-color-error);
              background-color: var(--background-color-primary);
            }

            .btn-secondary {
              border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
              border-radius: var(--border-radius-default);
              background:var(--background-color-secondary);
            }

            .create-mock-form__version-dropdown {
              width: 350px;

              .create-mock-form__version-dropdown__button-label {
                @include text-truncate-ellipsis;
              }
            }
          }

          .input-checkbox {
            margin-right: var(--spacing-s);
          }
        }

        .mock-name-empty__msg-container {
          margin-top: var(--spacing-xs);

          &__text {
            color: var(--content-color-error)
          }
        }
      }
    }

    .mock-delay__options {
      min-width: 323px;
    }
  }

  .assign-mock-collection___search-bar {
    max-width: 296px;
    margin-top: var(--spacing-l);
    display: inline-flex;
    flex: 1;
    border-radius: var(--border-radius-default);
    height: var(--size-m);
    width: 100%;

    .input-search {
      margin-top: var(--spacing-zero);
    }
  }

  .assign-mock-collection___view-toggle {
    float: right;
    margin-top: var(--spacing-l);
  }

  .assign-mock-collection__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
    height: 100%;
    width: 100%;

    .assign-mock-collection__body {
      display: flex;
      flex-direction: column;
      flex: 1;

      .tabs {
        flex: 0 0 30px;
        justify-content: center;
        width: fit-content;
        width: -moz-max-content; /* Firefox */
        align-items: center;
      }

      .assign-mock-collection__right {
        overflow: hidden;
        min-height: 70px;
        overflow: auto;

        .assign-mock-collection__entity-empty {
          text-align: center;
          color: var(--content-color-secondary);

          &__title {
            font-size: var(--text-size-xl);
            font-weight: var(--text-weight-bold);
            margin-bottom: var(--line-height-m);
            margin-top: var(--spacing-l);
          }

          &__subtitle {
            font-size: var(--text-size-m);
            margin-top: var(--line-height-m);
          }
        }


      }
    }

    .step1 {
      overflow: auto;

      &.assign-mock-collection__body {

        .assign-mock-collection__body__tab-content {
          flex: 1;
        }

        .tabs {
          border: var(--border-width-default) solid var(--border-color-default);
          margin-bottom: 17px;
          border-radius: var(--border-radius-default);

          .tab-primary {
            border-bottom: var(--spacing-zero);
            background-color: var(--highlight-background-color-primary);

            .tab-text-wrapper {
              margin-right: var(--spacing-m);
            }

            &.is-active {
              background-color: var(--background-color-primary);
              .tab-text-wrapper {
                box-shadow: none;
              }
            }
          }
        }

        .assign-mock-collection__right__intro {
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          width: 100%;
          margin-top: var(--spacing-s);
          margin-bottom: var(--spacing-l);
          color: var(--content-color-primary);

          .btn-text {
            line-height: var(--line-height-s);
            padding: 0 3px;
          }
        }

        .assign-mock-collection__right__intro__heading {
          font-weight: 600;
          display: block;
          margin-bottom: var(--spacing-xs);
        }

        .assign-mock-collection__right {
          flex: 1;

          .assign-mock-collection__helptext {
            color: var(--content-color-primary);
            margin-top: 20px;

            .btn-text {
              height: 0px;
            }
          }



          .assign-mock-collection__test-header {
            display: flex;

            .input-checkbox {
              margin-right: 5px;
            }
          }

          .assign-mock-collection__right__table {
            flex: 1;
          }

          .assign-mock-collection__card {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex: 0 0 224px;
            height: 40px;
            padding: var(--spacing-s);
            cursor: pointer;
            border-radius: var(--border-radius-default);

            @include hover {
              background-color: var(--highlight-background-color-primary);
            }

            .assign-mock-collection__card__icon {
              margin-right: 10px;
              margin-top: 2px;
              flex: 0 0 22px;
            }

            .assign-mock-collection__card__title {
              display: flex;
              align-items: center;
              max-width: 176px;

              span {
                color: var(--content-color-primary);
                @include text-truncate-ellipsis;
                font-size: var(--text-size-l);
              }

              .collection-meta-icons {
                overflow: visible;
              }
            }
          }

          .assign-mock-collection__right__collections {
            display: grid;
            box-sizing: border-box;
            grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
            border: var(--border-width-default) solid var(--border-color-default);
            overflow: auto;
            max-height: 100%;
            padding: 10px;
          }
        }

      }
    }
  }

  .loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .mock-form-footer__container {
    display: flex;
    flex: 0 0 104px;
    align-items: center;
    border-top: var(--border-width-default) solid var(--border-color-default);
    padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
  }

  .mock-form__footer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: var(--text-size-m);
    position: relative;
    margin-top: var(--spacing-m);
    grid-gap: var(--spacing-s);


    .btn-primary {
      margin-right: var(--spacing-s);
    }
  }

  .mock-create__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    min-height: 300px;
  }
}



.assign-mock-collection__right__table {
  height: 100%;
  flex: 1;

  .assign-mock-collection__data-editor {
    height: 100%;

    .data-editor {
      height: 100%;

      .data-editor {
        overflow-y: auto;
        height: 100%;
      }
    }
  }

  .assign-mock-collection__data-editor {
    margin-right: 1px;

    .data-editor__cell--path {
      .prefix {
        display: flex;
        align-items: center;
      }
    }

    .dropdown-button {
      .btn {
        height: 28px;
      }
    }

    .DraftEditor-editorContainer {
      .public-DraftEditor-content {
        height: 20px;
      }
    }

    .data-editor {
      border: none;

      .data-editor__cell--path {
        display: flex;
        flex: 1;

        .auto-suggest {
          margin: var(--spacing-zero);
        }

        .prefix {
          font-size: var(--text-size-m);
          padding-left: 5px;
          line-height: 25px;
          color: var(--content-color-secondary);
        }

        .data-editor__cell__content {
          margin-left: 3px;
        }
      }
    }
  }

  .data-editor {
    display: flex;
    flex-direction: column;

    .data-editor__header {
      margin-right: var(--spacing-m);
      flex: 0 0 30px;
    }

    .data-editor__rows {
      overflow-y: scroll;
    }

    .data-editor__header {
      .data-editor__header__cell {
        font-size: var(--text-size-s);
        color: var(--content-color-primary);
        font-weight: 600;

        .data-editor__header__cell__title {
          @include text-truncate-ellipsis;
        }
      }

      .assign-mock-collection__test-header {
        overflow: hidden;

        .input-checkbox {
          min-width: var(--size-xs);
        }

        span {
          @include text-truncate-ellipsis;
        }
      }
    }

    .data-editor__row {
      .data-editor__reorder {
        width: 14px;
      }
      .dropdown {
        margin: var(--spacing-zero) 5px;
      }

      .dropdown-button {
        .btn {
          background-color: transparent;
          box-shadow: none; // To remove border coming from cascading style from Base button (.btn)
          padding: var(--spacing-zero);
        }
      }

      .data-editor__cell--time {
        display: flex;
        flex: 1;
        margin-right: 30px;

        .postfix {
          font-size: var(--text-size-m);
          padding-left: 5px;
          line-height: 25px;
          color: var(--content-color-secondary);
        }
      }

      .data-editor__custom-row {
        &.is-focused,
        &:hover {
          .data-editor__cell--time {
            margin-right: var(--spacing-zero);
          }
        }
      }

      &:last-child {
        .data-editor__custom-row {
          &.is-focused,
          &:hover {
            .data-editor__cell--time {
              margin-right: 30px;
            }
          }
        }
      }
    }
  }
}
