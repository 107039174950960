.mock-no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--content-color-primary);
  flex: 1;
  margin: var(--spacing-zero) var(--spacing-xl);
  margin-top: var(--spacing-xxxl);
  &__label {
    font-size: var(--text-size-xl);
    font-weight: var(--text-weight-bold);
    margin-bottom: 10px;
    margin-top: var(--spacing-l);
  }

  &__subtext {
    font-size: var(--text-size-m);
    opacity: 0.6;
  }
}
