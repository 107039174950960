.permission-list-container {
  @include unselectable;

  .permission-list--workspace {
    position: relative;
    display: flex;
    align-items: center;
  }

  .permission-list--workspace__member-details,
  .permission-list--workspace__member-list-icon {
    cursor: pointer;
  }

  .permission-list--workspace__member-details {
    margin-left: 8px;
  }

  .permission-list--workspace__member-list-icon {
    // Helps in increasing the hit area instead of using margin
    padding-left: 16px;
  }

  .permission-list--workspace__roles {
    position: absolute;
    right: 20px;
  }

  .permission-list {
    margin: 4px 4px 8px 4px;
    padding-bottom: 8px;

    .permission-list-item {
      position: relative;
      display: flex;
      align-items: center;
      margin: var(--spacing-l) 0;

      .permission-list-item--name {
        flex: 1;
        margin-left: 8px;
        color: var(--content-color-primary);

        @include text-truncate-ellipsis;
      }

      .permission-list-item--name__user-group {
        cursor: pointer;
      }

      .permission-list-item__user-group {
        width: var(--size-m);
        height: var(--size-m);
        align-items: center;
        justify-content: center;
        align-self: center;
        background: var(--background-color-tertiary);
        border-radius: var(--border-radius-max);
        cursor: pointer;
      }

      .permission-list-item--info {
        display: flex;
        align-items: center;
        padding: 4px;
        margin-right: 4px;

        .permission-list-item--info-icon {
          @include icon-color(var(--content-color-secondary));
        }
      }

      .permissions-list-item--permissions {
        .permissions-list-item--role {
          @include text-truncate-ellipsis;
        }

        .dropdown {
          width: 170px;
        }

        .dropdown-button {
          .btn {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: var(--size-s);
            font-weight: normal;
          }
        }
      }

      .permissions-list-item--delete {
        margin-left: 8px;
        @include clickable;
      }
    }

    .permisson-list-item:nth-of-type(1) {
      margin-top: 0;
    }
  }
}

.role-dropdown {
  .dropdown-menu-item.is-disabled {
    cursor: not-allowed;
  }
}

.permission-list-item__members-list {
  width: 240px;
  max-height: 300px;

  .tooltip-wrapper {
    padding: var(--spacing-xl);
  }

  &__title {
    display: flex;
    font-weight: var(--text-weight-bold);
    margin-bottom: var(--spacing-xs);
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);

    &__group-name {
      max-width: 110px;
      @include text-truncate-ellipsis;
    }

    &__no-members {
      max-width: unset;
    }
  }

  &__content {
    max-height: 188px;
    overflow-y: auto;
    margin-bottom: var(--spacing-l);

    &__no-members {
      margin-top: var(--spacing-xs);

      label {
        line-height: var(--line-height-m);
      }
    }

    &__item {
      display: flex;
      margin-top: var(--spacing-s);
      align-items: center;

      &__name {
        margin-left: var(--spacing-s);
        max-width: 176px;
        @include text-truncate-ellipsis;
      }
    }
  }

  &__no-members {
    margin-bottom: var(--spacing-s);
  }
}
