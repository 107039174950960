.lesson-list-item {
  cursor: initial;

  .lesson-list-item-description {
    font-weight: var(--text-weight-regular);
    margin-top: 10px;
    font-size: var(--text-size-s);
    color: var(--content-color-secondary);
  }
  .lesson-list-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .lesson-list-item-status {
      &.completed {
        color: var(--base-color-success);
      }

      &.inprogress {
        color: var(--base-color-warning);
      }
    }

    .lesson-actions {
      display: flex;
      font-size: var(--text-size-xs);
      margin-top: var(--spacing-l);

      .lesson-action {
        margin-right: 30px;
        cursor: pointer;
        align-items: center;
        }

        .pm-icon {
          margin-right: 5px;
          @include icon-color(var(--content-color-secondary));
          &.primary {
            @include icon-color(var(--base-color-brand));
          }
        }

        .action-label {
          display: flex;
          color: var(--content-color-secondary);
          height: 12px;

          &.loading {
            color: var(--content-color-tertiary);
          }

          &.primary {
            color: var(--base-color-brand);
            @include icon-color(var(--base-color-brand));
            align-items: flex-end;
          }
        }

        .spinner {
          margin-right: var(--spacing-s);
        }
      }
    }
  }
