.tab-content-shell-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .tab-content-shell {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-family: $font-primary;
    background-color: var(--background-color-primary);
    height: 100%;
    min-height: 350px;
  }

  .tab-content-shell-thumbnail {
    margin-bottom: 10px;
    fill: var(--background-color-tertiary);
  }

  .tab-content-shell-container{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tab-content-shell-header {
    font-size: 18px;
    font-weight: 600;
    color: var(--content-color-primary);
  }

  .tab-content-shell-content {
    font-size: 14px;
    text-align: center;
    margin: 30px 0;
    color: var(--content-color-secondary);

    .launchpad-open-icon {
      margin-right: 8px;
    }
  }
}
