.schema-documentation-overview-container {
  position: relative;
  overflow: hidden;
  max-height: 400px;
  min-height: 48px;
  background: var(--background-color-secondary);
  box-shadow: 0px 1px 0px 0px var(--highlight-background-color-primary) inset;
  width: 100%;
  height: auto;

  &.error-container {
    height: auto;
    padding: var(--spacing-l) var(--spacing-xl);

    .schema-documentation-overview-container-content {
      display: flex;
      align-items: center;
    }
  }

  &.empty-container {
    height: auto;
    padding: var(--spacing-l) var(--spacing-xl);
  }

  &-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: flex-end;
    padding-bottom: var(--spacing-l);
    padding-right: var(--spacing-xxl);
    right: 0;
    height: 40%;
    display: flex;
    justify-content: right;
    background: linear-gradient(180deg, transparent 0%, var(--background-color-tertiary) 82.81%);
  }

  &-content {
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
}

.schema-documentation-overview-error-message {
  margin-right: var(--spacing-m);
}

.schema-documentation-overview-empty-message {
  margin-right: var(--spacing-m);
}
