.file-editor-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $font-primary;
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-l);
  background-color: var(--background-color-primary);

  // This is because while editing the min size if icons is 32x32 which makes the UI change height
  min-height: 32px;

  &__comments {
    cursor: pointer;
  }

  .file-editor-header__left-section {
    box-sizing: border-box;
    display: flex;
    width: calc(100% - 70px);
    align-items: center;
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);

    .file-name-title {
      color: var(--content-color-primary);
      display: flex;
      align-items: center;

      .breadcrumb__text {
        font-size: var(--text-size-l);
      }

      .edit-icon-rename {
        display: inline-flex;
        visibility: hidden;
        margin-right: var(--spacing-xs);
        margin-left: var(--spacing-xs);
        @include hover {
          @include icon-color(var(--content-color-primary));
        }
      }

      @include hover {
        .edit-icon-rename {
          visibility: visible;
          cursor: pointer;
        }

        .copy-link-icon {
          visibility: visible;
          cursor: pointer;
        }
      }

      .copy-link-icon {
        display: inline-flex;
        visibility: hidden;
        margin-right: var(--spacing-xs);
      }

      &__root {
        margin-left: var(--spacing-xs);
        padding-right: 2px;
        display: flex;
        align-items: center;
      }

      &__root-title {
        color: var(--content-color-info);
        font-size: var(--text-size-s);
        font-weight: var(--text-weight-medium);
      }
    }

    .file-name-input {
      display: none;
    }
  }

  .file-editor-header__right-section {
    display: flex;
    width: 70px;
    align-items: center;

    .file-editor-header__save-message {
      display: flex;
      margin-right: var(--spacing-l);
      align-items: center;
      width: max-content;
    }

    .button-wrapper {
      box-sizing: border-box;
      height: var(--size-m);
      display: flex;
      align-items: stretch;
      padding: var(--spacing-s);
      margin-right: 2px;
      cursor: pointer;
      user-select: none;

      .button-icon {
        margin-right: var(--spacing-xs);
      }

      &.file-report-button, &.file-share-button, &.file-share-button {
        background: initial;

        &.button-disabled {
          @include hover {
            background: initial;
          }
        }

        @include hover {
          background-color: var(--highlight-background-color-primary);
        }
      }

      .watch-label {
        margin-left: var(--spacing-s);
      }

      .unwatch-icon {
        @include icon-color(var(--content-color-secondary));
      }

      &.file-context-menu-button {
        padding: var(--spacing-zero);
        margin-right: var(--spacing-s);

        .button-icon {
          height: var(--size-m);
          width: var(--size-m);

          &.is-active {
            background-color: var(--highlight-background-color-tertiary);
          }
        }
      }
    }

    .file-watch-button {
      margin-right: var(--spacing-s);
    }

    .file-mode-switcher {

      &.plugin__workspace-view-switcher__wrapper {
        .plugin__workspace-view-switcher__wrapper {
          display: flex;
          justify-content: center;
          height: var(--size-m);
          width: 70px;

          .plugin__workspace-view-switcher__switch {
            height: 28px;
            width: var(--size-m);

            &.right {
              left: 36px;
            }
          }

          .plugin__workspace-view-active-item {
            align-self: center;
            display: flex;
          }

          .plugin__workspace-view-switcher__switch__item {
            height: 100%;
            flex: 0 0 32px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &.disabled {
            opacity: 0.3;
          }
        }
      }

      .file-mode-switcher__icon {
        @include icon-color(var(--content-color-tertiary));

        &.active {
          @include icon-color(var(--base-color-brand));
        }
      }
    }

    .comments-ctx-toggler {
      padding: var(--spacing-zero) var(--spacing-s);
      font-size: var(--text-size-m);
      align-items: center;
      color: var(--content-color-brand);
      margin-right: var(--spacing-m);

      & > .comment-wrapper {
        cursor: pointer;

        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }
}

.file-context-menu-dropdown {   // to-do:  Add css according to the new dropdown design
  .dropdown-menu {
    margin-top: var(--spacing-zero);
    margin-right: 3px;
    width: 200px;
  }
}
