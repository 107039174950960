.table-balloon-container {
  position: absolute;
  cursor: pointer;
  z-index: 4;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;

  // Trigger styles
  .table__cell__action {
    width: var(--spacing-m);
    height: var(--size-xs);
    padding: 0 2px;
    border-radius: 3px;
    background-color: transparent;

    &--active {
      background-color: var(--highlight-background-color-primary);
    }

    &:hover {
      background-color: var(--highlight-background-color-primary);
    }
  }
}

// dropdown styles
.table__cell__dropdown {
  position: absolute;
  display: none;
  opacity: 0;
  width: 135px;
  z-index: 4;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: opacity 200ms ease-out, top 75ms ease-out, left 75ms ease-out;
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-default);
  height: fit-content;

  &--visible {
    display: flex;
  }

  .toolbar__cell__action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--spacing-s) 10px;

    &:hover {
      background-color: var(--highlight-background-color-primary);
    }

    .slate-ToolbarButton {
      width: 100%;
      height: auto;
      justify-content: flex-start;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    svg path {
      stroke: var(--content-color-secondary);
    }

    &--deltab {
      svg path {
        fill: var(--content-color-error);
        stroke: unset;
      }
      .icon__label {
        color: var(--content-color-error);
      }
    }
  }
}

.table-balloon-container .slate-ToolbarButton {
  border-radius: var(--border-width-xl);
  background-color: transparent;
}

.table-balloon-container .slate-ToolbarButton-active {
  background-color: var(--highlight-background-color-primary);
}

.table-balloon-container .slate-ToolbarButton:hover {
  background-color: var(--highlight-background-color-primary);
}

.icon__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
}
