.toggle-switch-container {
  display: flex;
  align-items: center;

  &.is-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  @include unselectable;
  @include clickable;
}

.toggle-switch {
  position: relative;
  width: var(--size-m);
  height: var(--size-xs);
  background: var(--switch-track-unchecked-background-color);
  // TO-DO component specific token required
  border-radius: 8px;
  border: var(--border-width-default) var(--border-style-solid) var(--switch-track-unchecked-border-color);
  box-sizing: border-box;

  &.is-active {
    background: var(--switch-track-checked-background-color);
    border-color: var(--switch-track-checked-border-color);
  }

  &:before {
    content: ' ';
    position: absolute;
    height: 12px;
    width: 12px;
    top: 1px;
    left: 1px;
    border-radius: 6px;
    background: var(--switch-thumb-background-color);
  }

  &.is-active:before {
    right: 1px;
    left: initial;
    background: var(--switch-thumb-background-color);
  }
}

.toggle-switch-text {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);

  .toggle-switch-text-on {
    color: var(--content-color-primary);
  }

  .toggle-switch-text-off {
    color: var(--content-color-secondary);
  }
}
