.learning-center-list-item {
  padding: 20px;
  background: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  font-weight: 600;
  font-size: var(--text-size-m);
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;

  &.skill-list-item{
    filter: drop-shadow($list-item-shadow);
  }

  &.lesson-list-item {
    border: 1px solid var(--border-color-default);
  }

  &.lesson-plan-list-item {
    align-items: center;
    height: 20px;
    filter: none;
    border: 1px solid var(--border-color-default);
  }
}

.learning-center-list-item-header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.learning-center-list-item-icon {
  margin-right: 15px;
  display: flex;

  img {
    object-fit: contain;
  }
}

.lesson-plan-list-item-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  .lesson-plan-actions {
    display: flex;
    cursor: pointer;
    align-items: center;

    .lesson-plan-start-icon {
      display: flex;
      margin-right: 10px;
    }

    .pm-icon {
      @include icon-color(var(--base-color-brand));
    }

    .lesson-plan-start {
      color: var(--content-color-brand);
      font-size: var(--text-size-m);
      font-weight: 600;
    }
  }
}
