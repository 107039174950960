.pull-request-decline {
  position: relative;

  &__close {
    position: absolute;
    top: var(--spacing-xxl);
    right: var(--spacing-xxl);
  }

  &__confirm {
    margin-left: var(--spacing-xs);
  }

  &__text {
    font-size: var(--text-size-l);
  }
}
