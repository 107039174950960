.resource-usage {
  border: 1px solid var(--border-color-default);
  border-radius: 4px;
  padding: var(--spacing-xl);
  width: 272px;
  margin-top: var(--spacing-xxl);
  
  &__heading {
    font-size: var(--text-size-xxl);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-top: 0px;
  }

  &__limit-reset-date {
    font-size: var(--text-size-s);
    color: var(--content-color-tertiary);
    margin-bottom: var(--spacing-l);
    line-height: var(--line-height-m);
  }

  &__body {
    .team-usage-container {
      border: none;
      padding: 0;
    }
  }

  &__view-all-link {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    margin-top: var(--spacing-l);
    padding: 0;
    color: var(--content-color-link);
    border: none;
    background: none;
  }
}