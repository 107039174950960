.create-new-workspace-modal {
  overflow-y: auto;
  margin: 0 auto;
  z-index: 0;

  .modal-content {
    overflow-y: auto;

    // Fixing the padding to shift scroll bar on extreme right hand side.
    padding: var(--spacing-xl) calc(50% - 444px);

    .loading-indicator-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  .add-user__list {
    height: 200px;
  }

  .empty-state--no-users {
    height: 160px;
    color: var(--content-color-secondary);
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-roles__container {
    height: 160px;
  }

  &__title {
    font-size: var(--text-size-xxxl);
    line-height: var(--line-height-xxxl);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-l);
  }

  &__error-state {
    height: 70%;
  }

  .create-new-workspace-modal__team_plan {
    &__warning {
        .alert {
            background-color: var(--background-color-warning);
            margin-top: var(--spacing-zero);
            margin-bottom: var(--spacing-xl);
        }
    }
    &__error {
      .alert {
          background-color: var(--background-color-error);
          margin-top: var(--spacing-zero);
          margin-bottom: var(--spacing-xl);
      }
    }
  }

  .create-new-workspace-modal__contents {
    display: flex;
    flex-direction: column;

    .create-new-workspace__input-group {
      display: flex;
      flex-direction: column;
      flex: 1;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .create-new-workspace__input-group__label {
        margin-bottom: 7px;
        display: flex;
        color: var(--content-color-secondary);
        font-weight: var(--text-weight-medium);
      }

      .create-new-workspace__input-group__left-label {
        flex: 1;
        color: var(--content-color-secondary);
        font-weight: var(--text-weight-medium);
      }

      .create-new-workspace__input-group--subtitle {
        flex: 1;
        color: var(--content-color-tertiary);
        font-size: 12px;
        margin-bottom: 8px;
      }

      .create-new-workspace__input-group__add-slots {
        padding-right: 0px;
      }

      .create-new-workspace__input-group__team-size {
        color: $workspace-switcher-header-color;
      }

      .create-new-workspace__input-group__input {
        display: flex;
        margin-bottom: 10px;

        .is-disabled {
          cursor: not-allowed;
        }

        .plugin__workspace-view-switcher__wrapper {
          display: flex;
          justify-content: center;
          height: 32px;

          .plugin__workspace-view-switcher__switch {
            height: 28px;
          }

          .plugin__workspace-view-active-item {
            align-self: center;
          }
        }

        .plugin__workspace-view-switcher__disable {
          opacity: 0.5;
          cursor: default;
        }

        .create-new-workspace__input-group__input--type {
          flex: 1;
        }

        .textarea-wrapper {
          flex: 1;
        }

        .create-new-workspace__input-group__input__edit-text-area {
          margin-bottom: 10px;
        }
      }

      .create-new-workspace__input-group__subtext {
        color: $workspace-create-new-disabled-color;
      }

      &--error {
        display: flex;
        align-items: center;
        margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-xs);
        color: var(--content-color-error);

        .pm-icon {
          margin-right: var(--spacing-s);
          @include icon-color(var(--content-color-error));
        }
      }
    }
  }

  .create-new-workspace__email-privileges-denied {
    margin-bottom: 16px;
    margin-top: 8px;
    color:var(--base-color-error);
  }

  .create-new-workspace__team_info {
    color: var(--content-color-tertiary);
  }

  .create-new-workspace__team_creation_info {
    color: var(--content-color-tertiary);
    margin-bottom: var(--spacing-m);
  }

  .modal-footer {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    margin: 0 calc(50% - 444px);
  }

  .button-small {
    width: 100px;
  }

  .button-medium {
    width: 120px;
  }

  .button-large {
    width: 280px;
  }

  @media (max-width: 1259px) {
    width: 100%;
  }

}
