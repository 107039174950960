/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.comment-container {
  $caret-size: 6px;
  $bg-color: var(--background-color-primary);

  position: absolute;
  top: var(--spacing-l);
  right: var(--spacing-zero);
  margin-top: var(--spacing-l);
  overflow: visible;
  background: $bg-color;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
  width: 400px;
  z-index: 12;

  // caret
  &::before {
    content: ' ';
    position: absolute;
    top: -$caret-size;
    right: var(--spacing-l);
    box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-width: $caret-size;
    border-style: var(--border-style-solid);
    border-color: $bg-color transparent transparent $bg-color;
  }

  .app-crash-wrapper {
    padding: var(--spacing-xxl);

    .app-crash-content-container {
      width: auto;
    }
  }
}

.comment-wrapper {
  display: flex;
  color: var(--content-color-link);
  align-items: center;
  line-height: var(--line-height-m);

  .comment-badge{
    background: var(--background-color-tertiary);
    line-height: var(--line-height-s);
    min-width: 8px;
    font-size: var(--text-size-xs);
    margin-left: var(--spacing-xs);
    padding: var(--spacing-zero) var(--spacing-xs);
    border-radius: var(--border-radius-s);
    text-align: center;
    height: var(--size-xs);
  }

  &__icon {
    margin-right: var(--spacing-s);
    height: var(--size-xs);
    @include icon-color(var(--content-color-secondary));
  }

  &__icon-active{
    margin-right: var(--spacing-s);
    height: var(--size-xs);
    @include icon-color(var(--content-color-primary));
  }

  &.is-active{
    color: var(--content-color-primary);

    .comment-badge{
      color: var(--content-color-primary);
    }
  }
}

.comments {
  $confirm-btn-width: 120px;
  $creator-width: 200px;

  min-height: 100px;
  position: relative;

  & > .loading-indicator-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .entity-empty {
    padding: var(--spacing-xxl);
  }

  &-tag {
    cursor: pointer;
    background: var(--grey-50);
    // TODO: Replace 12px with var(--border-radius-max)
    // Currently there is aether bug [AETHER-2001], where border-radius-max is set to 100%
    border-radius: 12px;
    color: var(--content-color-primary) !important;
    padding: var(--spacing-zero) var(--spacing-xs);

    &.is-light-theme {
      background: var(--grey-30);
    }

    &-private-user {
      cursor: default;

      &:hover {
        text-decoration: none !important;
      }
    }

    &-current-user {
      background: var(--yellow-70);

      &.is-light-theme {
        background: var(--yellow-30);
      }
    }
  }

  &-item{
    transition: background-color 0.3s ease-in-out;

    &__body{
      .edited{
        color: var(--content-color-tertiary);
        font-size: var(--text-size-s);
      }
    }

    &--focused {
      background: var(--background-color-brand);
      border-radius: var(--border-radius-default);
      transition: background-color 0.3s ease-in-out;
    }
  }

  &-header{
    display: flex;
    height: var(--size-s);
    align-items: center;
    justify-content: space-between;

    &-tooltip-arrow {
      left: var(--spacing-s) !important;
    }
  }

  &-user {
    height: var(--size-s);
  }

  &-input,
  &-item {
    position: relative;
    padding: var(--spacing-l);

    .avatar {
      left: var(--spacing-l);
      display: inline-flex;
    }
  }

  &-input {
    position: relative;
    display: flex;
    flex-direction: column;

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__actions-left-options {
      display: flex;
      align-items: center;
    }

    &__confirm {
      width: $confirm-btn-width;
      font-weight: var(--text-weight-medium);
    }

    &__cancel {
      margin-left: var(--spacing-s);
      color: var(--content-color-tertiary);
      font-weight: var(--text-weight-medium);
      &:hover,
      &.is-focused{
        color: var(--content-color-secondary);
      }
    }

    .textarea {
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      border-radius: var(--border-radius-default);
      margin-top: var(--spacing-xs);
      min-height: 76px;
      resize: none;
      padding: var(--spacing-s);

      &::placeholder{
        color: var(--content-color-tertiary);
      }
    }

    .comments-refresh{
      color: var(--content-color-tertiary);
      width: var(--size-s);
      min-width: var(--size-s);
      height: var(--size-s);
      &:hover,
      &.is-focused{
        background-color: var(--background-color-tertiary);
      }
    }

    &__user{
      display: flex;
    }
  }

  &-footer{
    line-height: var(--line-height-s);
    font-size: var(--text-size-xs);
    color: var(--content-color-secondary);
    text-align: left;
    margin-bottom: var(--spacing-s);
    margin-top: var(--spacing-xs);

      &__markdown-link{
        cursor: pointer;
        font-weight: var(--text-weight-medium);

        @include hover {
          text-decoration: underline;
          color: var(--content-color-primary);
        }
      }

      &__user-public-profile-text {
        display: flex;
        align-items: center;
        margin-top: var(--spacing-s);
        font-size: var(--text-size-m);
        margin-bottom: var(--spacing-xs);

        a {
          color: var(--content-color-link);
        }

        .pm-icon {
          margin-right: var(--spacing-s);
        }
      }
  }

  &--add-comment {
    margin: var(--spacing-s);
  }

  &-tag-list {
    font-size: var(--text-size-m);
    max-height: 180px;
    overflow-x: hidden;
    overflow-y: overlay;
    list-style: none;
    padding-left: var(--spacing-zero);
    position: absolute;
    top: calc(100% - 60px);
    left: var(--spacing-l);
    width: 280px;
    background: var(--background-color-primary);
    box-shadow: var(--shadow-default);
    color: var(--content-color-primary);
    z-index: 8;
    margin-top: var(--spacing-s);

    &__wrapper{
      display: flex;
    }

    &__avatar{
      pointer-events: none;
      cursor: default;
      padding: 6px var(--spacing-s);
      display: inherit;
    }

    &__user{
      line-height: var(--line-height-l);
      padding: 6px var(--spacing-s) 6px var(--spacing-zero);
      width: calc(100% - 56px); // 48px for .avatar and 8px for margin-right
      display: flex;
    }

    &__name{
      @include text-truncate-ellipsis;
      max-width: calc(66% - 8px); //8px for padding-right
      padding-right: var(--spacing-s);
      flex: none;
    }

    &__username{
      @include text-truncate-ellipsis;
      color: var(--content-color-tertiary);
    }

    &__item {
      cursor: pointer;

      &:hover,
      &.focused {
        background: var(--background-color-secondary);
      }
    }
  }

  &-list {
    list-style: none;
    padding: var(--spacing-zero);
    margin: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
    max-height: 300px;
    overflow: auto;
  }

  &-item {
    position: relative;
    padding-top: var(--spacing-s);
    padding-bottom: var(--spacing-s);
    padding-right: var(--spacing-l);

    &__time {
      color: var(--content-color-secondary);
      text-align: right;
      margin-left: var(--spacing-s);
      flex: 1;
      min-width: max-content;
    }

    &__creator {
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      display: inline-block;
      color: var(--content-color-primary);
      margin-left: var(--spacing-s);
      line-height: var(--line-height-l);

      &:hover {
        color: var(--content-color-primary);
        text-decoration: none;
      }
    }

    &__body {
      @include markdown();

      color: var(--content-color-primary);
      overflow-wrap: break-word;
      word-break: break-word;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      margin: var(--spacing-zero);
      margin-left: var(--spacing-xxl);
    }

    &--deleting {
      .comments-item__actions {
        display: block;
      }

      .comments-item__time {
        display: none;
      }
    }

    &--editable,
    &--deletable,
    &--copy-link {
      &:hover {
        background: var(--background-color-secondary);

        .comments-item__actions {
          display: block;
        }

        .comments-item__time {
          display: none;
        }
      }
    }

    &__actions {
      display: none;
      text-align: right;
      flex: 1;
      min-width: max-content;
      .btn {
        min-width: initial;
        height: var(--size-s);
        padding: 6px;
        @include icon-color(var(--content-color-tertiary));
        &:hover{
          background-color: var(--background-color-tertiary);
          @include icon-color(var(--content-color-secondary));
        }

        &.copy-link {
          margin-right: var(--spacing-s);

          &.success {
            @include icon-color(var(--base-color-success));

            &.is-disabled {
              opacity: 1;
            }
          }
        }

        &.edit{
          margin-right: var(--spacing-s);
        }

        &.is-disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
}

.copy-comment-link-button {
  display: inline-block;
}

.inline-comment-button {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-xs);
  background-color: var(--background-color-primary);

  i {
    margin-right: var(--spacing-s);
  }

  .comment-badge {
    height: var(--size-xs);
    width: var(--size-xs);
    margin-left: var(--spacing-xs);
    background-color: var(--background-color-tertiary);
  }
}

.inline-comment {
  .tooltip-wrapper {
    padding: var(--spacing-xs);
  }

  &__tooltip-arrow {
    &::before{
      border-right-color: var(--background-color-primary) !important;
      border-left-color: var(--background-color-primary) !important;
    }
  }

  .comments {
    min-height: 50px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color-secondary);
    padding: var(--spacing-xs) var(--spacing-l);
    height: var(--size-m);

    &-comment {
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-medium);

      .comment-badge {
        height: var(--size-xs);
        width: var(--size-xs);
        padding: var(--spacing-zero) var(--spacing-xs);
        background-color: var(--background-color-tertiary);
      }
    }
  }

  &__footer {
    padding: var(--spacing-zero) 6px var(--spacing-m);
  }
}

.inline-comment.comment {
  z-index: 110;
  width: 350px;

  .tippy-content {
    padding: 0;
    min-width: 350px;
    max-height: 350px;
    border-radius: 4px;
    overflow: auto;
    background-color: var(--background-color-primary);
  }

  .tooltip-wrapper {
    min-width: 350px;
    max-height: 520px;
    overflow: hidden;
    background-color: var(--background-color-primary);
  }

  .comments-list {
    max-height: 250px;
    margin-top: var(--spacing-zero);
  }
}

.inline-comment-available {
  background-color: var(--background-color-brand);

  &:hover {
    background-color: rgba(255, 108, 55, 0.2);
  }

  &__focused {
    background-color: rgba(255, 108, 55, 0.2);
  }
}

// Overrides for new comment styles
// TODO: Unify styles them when old experience is removed from API schema
.new-comments {
  %comment-text {
    text-decoration: solid underline var(--yellow-50) 1px;
    background-color:  var(--learning-center-highlight-background-color);

    &:hover {
      cursor: pointer;
    }
  }

  .inline-comment-available {
    background-color: inherit;

    .key-value-cell__text, span {
      @extend %comment-text;
    }

    &:hover {
      background-color: inherit;
    }

    &__focused {
      background-color: inherit;
    }

    &__selected {
      .key-value-cell__text, span {
        @extend %comment-text;

        filter: saturate(2);
      }

       // removes highlighting text/file dropdown text
      .key-value-form-row__type-select-container span {
        text-decoration: none;
        background-color:  transparent;
      }
    }

    &.inline-comment-available__focused {
      .key-value-cell__text, span {
        filter: saturate(2);
      }

      // in form-data editor of req body, the target ref also includes the text/file dropdown selector
      // we need to remove highlighting from it
      .key-value-form-row__type-select-container span {
        text-decoration: none;
        background-color:  transparent;
      }

      // removes the highlight if the input type is file in key-value column
      .file-input span {
        text-decoration: none;
        background-color:  transparent;
      }
    }

    // in form-data editor of req body, the target ref also includes the text/file dropdown selector
    // we need to remove highlighting from it
    .key-value-form-row__type-select-container, span {
      text-decoration: none;
      background-color:  transparent;
    }

    // removes the highlight if the input type is file in key-value column
    .file-input span {
      text-decoration: none;
      background-color:  transparent;
    }
  }

  .monaco-editor-inline-comment-text {
    border-bottom: solid var(--yellow-50) 1px;
    background-color: var(--learning-center-highlight-background-color);
  }

  .margin-comment-icon {
    content: url("../assets/images/icons/commentIconLight.svg");
    cursor: pointer;
    height: 100% !important;
    width: 12px !important;
    margin-left: 8px;

    &.margin-comment-icon-disabled {
      content: url("../assets/images/icons/commentIconLightDisabled.svg");
      cursor: not-allowed;
    }
  }

  &.new-comments-dark {
    .margin-comment-icon {
      content: url("../assets/images/icons/commentIconDark.svg");

      &.margin-comment-icon-disabled {
        content: url("../assets/images/icons/commentIconDarkDisabled.svg");
        cursor: not-allowed;
      }
    }
  }

  .margin-comment-line::before {
    content: '';
    border: 0.5px solid var(--content-color-secondary);
    height: 100%;
    position: absolute;
    left: 13px;
    cursor: pointer;
  }
}



.comment-drawer {
  height: 100%;
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);
  overflow-y: auto;

  .comments {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: unset;
    width: 100%;

    .comments-list {
      max-height: unset;
    }
  }
}

.inline-comment-drawer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .inline-comment-drawer-blankstate {
    max-width: 100%;
  }

  &__loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    color: var(--content-color-primary);
    text-align: center;
    line-height: var(--line-height-m);

    &__illustration {
      margin-bottom: var(--spacing-l);
    }

    &-title {
      color: var(--content-color-secondary);
    }

    &-description {
      margin-top: var(--spacing-l);
    }

    &-button {
      display: inline;
      padding: var(--spacing-zero);
    }

    &-doc {
      margin-top: var(--spacing-s);
    }
  }

  .inline-doc-link {
    cursor: pointer;
    color: var(--content-color-link);
  }
}

.inline-comment-drawer {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin: var(--spacing-s) var(--spacing-zero);
  border-radius: var(--border-radius-s);

  .comments-list {
    overflow: unset;
    max-height: unset;
    margin-top: var(--spacing-zero);
  }

  .overflow-seperator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--spacing-s) var(--spacing-zero);
    cursor: pointer;

    hr {
      margin: var(--spacing-zero);
      width: 60px;
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }

    span {
      padding: var(--spacing-zero) var(--spacing-s);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-secondary);
    }
  }

  &__header.is-resolved {
    background-color: var(--background-color-tertiary);
  }

  &__header {
    height: 20px;
    padding: 10px var(--spacing-l);
    background-color: var(--background-color-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
      width: calc(100% - 68px);
      color: var(--content-color-secondary);
      display: flex;
      align-items: center;
    }

    &-left.is-orphaned {
      text-decoration: line-through;
    }

    &--value {
      font-weight: var(--text-weight-medium);
      height: var(--size-xs);
      color: var(--content-color-primary);
      @include text-truncate-ellipsis;
      margin-left: var(--spacing-xs);
    }

    &--anchor {
      @include text-truncate-ellipsis;

      &-entity {
        @include clickable;
      }
    }

    &-resolved {
      color: var(--content-color-primary);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-medium);
    }
  }

  &__content.is-resolved {
    background-color: var(--background-color-secondary);
  }

  &__content {
    &--update-badge {
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
      background-color: var(--background-color-brand);
      color: var(--content-color-link);
      font-weight: var(--text-weight-medium);
      padding-left: 20px;
    }
  }

  &__actions {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
    flex: 0 1 auto;

    &-helptext {
      color: var(--content-color-secondary);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
    }

    .dropdown-button.is-open {
      .btn-icon.btn-icon-rect.inline-comment-drawer__actions-filter-button {
        background-color: var(--highlight-background-color-tertiary);
      }
    }

    .btn-icon.btn-icon-rect.inline-comment-drawer__actions-filter-button {
      height: var(--size-s);
      width: var(--size-s);
      background-color: var(--background-color-tertiary);
    }

    .btn-icon.btn-icon-rect.inline-comment-drawer__actions-filter-button:hover  {
      background-color: var(--highlight-background-color-tertiary);
    }

    &-filter {
      &-menu {
        height: 108px;
        width: 153px;
        background-color: var(--background-color-primary);
      }

      &--action-header {
        margin-left: var(--spacing-s);
        margin-top: var(--spacing-s);
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
      }

      &--action-item {
        margin-top: var(--spacing-m);
        margin-left: var(--spacing-l);

        &-name {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-primary);
          margin-left: var(--spacing-s);
        }
      }
    }
  }

  &__footer.is-resolved {
    background-color: var(--background-color-secondary);
  }


  &__footer {
    padding: var(--spacing-s) var(--spacing-l) var(--spacing-m) var(--spacing-l);
    display: flex;
    justify-content: space-between;

    &--add-comment {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &--show-all {
      display: flex;
      align-items: center;
      font-weight: var(--text-weight-medium);
      color: var(--content-color-secondary);
      cursor: pointer;
      width: 100%;
      justify-content: flex-end;

      .comment-badge {
        background: var(--content-color-tertiary);
        line-height: var(--size-xs);
        min-width: 8px;
        font-size: var(--text-size-xs);
        margin-left: var(--spacing-xs);
        padding: var(--spacing-zero) var(--spacing-xs);
        border-radius: var(--border-radius-s);
        text-align: center;
        height: var(--size-xs);
        color: var(--content-color-constant);
      }
    }
  }
}

.comment-filter-dropdown-item-checkbox {
  display: inline-flex;
}

.comment-filter-dropdown-item-checkbox.is-selected {
  background-color: var(--base-color-brand);
}

.comment-drawer--tabs {
  .tab {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    padding: var(--spacing-zero);
    margin-right: var(--spacing-xl);
    height: 40px;
    display: flex;
    align-items: flex-end;
  }

  .tab--inline {
    position: relative;
  }

  :not(.is-active) {
    color: var(--content-color-tertiary);
  }
}

.resolve-thread-confirmation-modal {
  .delete-confirmation-modal-delete-button {
    background-color: var(--base-color-brand);
  }
}

.comment-drawer-container {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-s);

    &-title {
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-xl);
      line-height: var(--line-height-xl);
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .dropdown-button.is-open {
      .btn-icon.btn-icon-rect.comment-drawer-container__actions-filter-button {
        background-color: var(--highlight-background-color-tertiary);
      }
    }

    .btn-icon.btn-icon-rect.comment-drawer-container__actions-filter-button {
      height: var(--size-s);
      width: var(--size-s);
      background-color: var(--background-color-tertiary);
    }

    .btn-icon.btn-icon-rect.comment-drawer-container__actions-filter-button:hover  {
      background-color: var(--highlight-background-color-tertiary);
    }

    &-filter {
      &-menu {
        height: 108px;
        width: 153px;
        background-color: var(--background-color-primary);
      }

      &--action-header {
        margin-left: var(--spacing-s);
        margin-top: var(--spacing-s);
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
      }

      &--action-item {
        margin-top: 10px;
        margin-left: var(--spacing-l);

        &-name {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-primary);
          margin-left: var(--spacing-s);
        }
      }
    }
  }
}

.comment-drawer-container.no-scroll {
  .comment-drawer {
    overflow: hidden;
  }
}
