@import 'environment-selector';
@import 'environment-preview';
@import 'delete-environment';
@import 'environment-meta-icons';
@import 'add-environment-template';
@import 'environment-sidebar';

.environment-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
