
.confirmation-modal__content {
  padding: 16px 24px 24px 24px;

  .confirmation-modal__body {
    font-family: $font-primary;

    .confirmation-modal__body__header {
      font-size: 16px;
      font-weight: var(--text-weight-medium);
      margin-bottom: 10px;
    }
  }

  .confirmation-modal__footer {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 30px;

    .btn {
      &.is-focused {
        box-shadow: var(--shadow-focus);
      }
    }

    .confirmation-modal__discard-btn {
      display: flex;
      flex-basis: 65%;
      background-color: var(--base-color-error);
      font-weight: 600;
    }

    .confirmation-modal__cancel-btn {
      display: flex;
      flex-basis: 30%;
      font-weight: 600;
    }
  }
}
