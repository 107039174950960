/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$tooltip-background-arrow-border-width: 0.6rem;
$tooltip-arrow-border-width: 0.5rem;
$popover-border-color: var(--base-color-brand);

.tooltip-popover {

  .tippy-content {
    border: solid 1px $popover-border-color;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .arrow {
    width: 0.9rem;
    height: 0.46rem;
    filter: none;

    &::after {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }

  // POPOVER ON THE TOP (TOP, TOP-*)
  &.bs-tooltip-top, &.bs-tooltip-auto[x-placement^='top'] {

    .arrow {
      bottom: 0;
      margin-left: 1.1rem;

      &::before {
        top: 0;
        border-width: $tooltip-background-arrow-border-width $tooltip-background-arrow-border-width 0;
        margin-left: -$tooltip-background-arrow-border-width;
        border-top-color: $popover-border-color;
      }

      &::after {
        top: 0;
        border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
        margin-left: -$tooltip-arrow-border-width;
        border-top-color: $tooltip-background-color;
      }
    }
  }

  // POPOVER ON THE TOP (RIGHT, RIGHT-*)
  &.bs-tooltip-right, &.bs-tooltip-auto[x-placement^='right'] {

    .arrow {
      left: 0;
      width: 0.4rem;
      height: 0.8rem;
      margin-top: 1.1rem;

      &::before {
        right: -1px;
        border-width: $tooltip-background-arrow-border-width $tooltip-background-arrow-border-width $tooltip-background-arrow-border-width 0;
        margin-top: -$tooltip-background-arrow-border-width;
        border-right-color: $popover-border-color;
      }

      &::after {
        right: -1px;
        border-width: $tooltip-arrow-border-width $tooltip-arrow-border-width $tooltip-arrow-border-width 0;
        margin-top: -$tooltip-arrow-border-width;
        border-right-color: $tooltip-background-color;
      }
    }
  }

  // POPOVER ON THE BOTTOM (BOTTOM, BOTTOM-*)
  &.bs-tooltip-bottom, &.bs-tooltip-auto[x-placement^='bottom'] {

    .arrow {
      top: 0;
      margin-left: 1.1rem;

      &::before {
        bottom: 0;
        border-width: 0 $tooltip-background-arrow-border-width $tooltip-background-arrow-border-width;
        margin-left: -$tooltip-background-arrow-border-width;
        border-bottom-color: $popover-border-color;
      }

      &::after {
        bottom: 0;
        border-width: 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
        margin-left: -$tooltip-arrow-border-width;
        border-bottom-color: $tooltip-background-color;
      }
    }
  }

  // POPOVER ON THE LEFT (LEFT, LEFT-*)
  &.bs-tooltip-left, &.bs-tooltip-auto[x-placement^='left'] {

    .arrow {
      right: 0;
      width: 0.4rem;
      height: 0.8rem;
      margin-top: 1.1rem;

      &::before {
        left: -1px;
        border-width: $tooltip-background-arrow-border-width 0 $tooltip-background-arrow-border-width $tooltip-background-arrow-border-width;
        margin-top: -$tooltip-background-arrow-border-width;
        border-left-color: $popover-border-color;
      }

      &::after {
        left: -1px;
        border-width: $tooltip-arrow-border-width 0 $tooltip-arrow-border-width $tooltip-arrow-border-width;
        margin-top: -$tooltip-arrow-border-width;
        border-left-color: $tooltip-background-color;
      }
    }
  }
}

.popover {
  min-height: 50px;
  min-width: 100px;
  max-width: 300px;
  margin: 8px;
  cursor: default;
  color: var(--content-color-primary);

  .popover-step {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 11px;

    .popover-step-title {
      color: var(--base-color-success);
      font-weight: 600;
      display: flex;
      align-items: center;
      line-height: 20px;

      .pie-progress-wrapper {
        margin-right: 10px;
      }
    }

    .popover-dismiss {
      cursor: pointer;
      align-self: center;
      color: var(--content-color-secondary);
    }
  }

  .popover-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .popover-header-title {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      justify-content: space-between;
      flex: 1;
      overflow: hidden;

      & > span {
        overflow: hidden;
      }

      .popover-dismiss {
        padding: 3px 0 0 16px;
      }
    }
  }

  .popover-body {
    margin-top: 8px;
    line-height: 20px;

    .popover-string {
      margin-top: 8px;
    }

    .popover-markdown-label {
      * {
        margin: 0;
      }

      code {
        flex: 1;
        display: flex;
        background: var(--background-color-tertiary);
        border-radius: 2px;
        padding: 5px;
        word-break: break-all;
        margin-top: 10px;
        user-select: all;
      }

      h3 {
        color: var(--content-color-secondary);
        font-weight: 600;
        font-size: 12px;
      }

      a {
        color: var(--base-color-brand);
      }
    }

    .popover-select-list {
      margin-top: 8px;
      flex-shrink: 0;

      .dropdown {
        width: 100%;
        height: 30px;
      }

      .dropdown-button .btn {
        width: 100%;
        display: flex;

        span {
          flex: 1;
          text-align: left;
          width: 0;
          @include text-truncate-ellipsis;
        }
      }

      .popover-select-list-title {
        font-weight: 600;
      }

      .popover-select-list-dropdown {
        margin-top: 8px;
      }
    }

    .popover-input {
      margin-top: 8px;

      .popover-input-title {
        font-weight: 600;
      }

      .popover-input-body {
        margin-top: 8px;
      }
    }

    .popover-checkbox {
      margin-top: 8px;

      .popover-checkbox-title {
          font-weight: 600;
      }

      .popover-checkbox-item {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }

      .popover-checkbox-label {
        margin-left: 8px;
      }
    }

    .popover-radio {
      margin-top: 8px;

      .popover-radio-title {
        font-weight: 600;
      }

      .popover-radio-selector-button-label {
        margin-top: 8px;
        display: flex;
        align-items: center;

        span {
          margin-left: 8px;
          flex: 1;
          text-align: left;
          width: 0;
          @include text-truncate-ellipsis;
        }
      }
    }

    .popover-toggle {
      margin-top: 8px;

      .popover-toggle-title {
        font-weight: 600;
      }

      .popover-toggle-body {
        margin-top: 8px;
      }
    }
  }

  .popover-actions {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;

    .popover-primary-action {
      margin-left: auto;
      background: transparent;
      color: var(--content-color-success);
    }

    .popover-secondary-action {
      margin-right: auto;
      background: transparent;
    }
  }
}

.target-visible {
  visibility: visible;
}
