.headers-editor {
  display: flex;
  background: $app-background-color;

  &__group {
    flex: 1;
    min-width: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    user-select: none;
  }

  &__preview-toggle {
    margin-left: var(--spacing-s);
    height: 20px;
    border-radius: 35px;
    background-color: var(--background-color-secondary);
    box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;

    .preview-toggle__icon {
      margin-right: 6px;
      @include icon-color(var(--content-color-tertiary));
    }

    .preview-toggle__text {
      @include text-truncate-ellipsis;
      font-size: var(--text-size-s);
    }

    .headers-editor__header .headers-editor__title {
      display: flex;
      align-items: center;
      background: var(--background-color-primary);
      height: var(--size-m);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-tertiary);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    height: var(--size-m);
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-medium);

    &--bleed {
      padding-left: var(--spacing-l);
    }
  }
}
