.request-permission-infobar {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  .infobar {
    min-height: 64px;
    background-color: var(--background-color-primary);

    .permission-infobar-icon {
      margin-right: var(--spacing-s);
      @include icon-color(var(--content-color-secondary));
    }

    .infobar__text-wrapper {
      padding: var(--spacing-zero) var(--spacing-s);
    }
  }
}
