
.loading-team-integrations-in-detail{

  &__types-list {
    @include unstyled-list;
    animation: fade 1s ease-in-out infinite;

    li {
      width: 100%;
      box-sizing: border-box;
      margin-left: var(--spacing-xl);
    }
    > li:first-child {
      margin-top: var(--spacing-l);
    }
  }

  &__types-item {

    border-radius: var(--border-radius-default);
    border: 1px solid var(--border-color-default);
    padding: var(--spacing-xl);
    margin:  var(--spacing-l) 0;

    @media (min-width: 768px) {
      padding: var(--spacing-xl)
    }
    &-status {
      margin-top: var(--spacing-xs);
      margin-bottom: var(--spacing-xl);
      background-color: var(--background-color-tertiary);
      height: 20px;
    }
    &-name-status {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    &-name-link {
      background-color: var(--background-color-tertiary);
      height: 28px;
      margin-top: var(--spacing-xs);
      margin-bottom: var(--spacing-xl);
    }
    &-info {
      padding: 0;

      & > h4 {
        margin: 0;
        display: inline-block;
      }
      & > p {
        margin: var(--spacing-s) 0 0 0;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);

    &-text {
      margin-left: var(--spacing-l);
      animation: fade 1s ease-in-out infinite;

      &--title {
        height: 32px;
        margin-bottom: var(--spacing-s);
        background-color: var(--background-color-tertiary);
      }
      &--description {
        height: 16px;
        background-color: var(--background-color-tertiary);
      }
    }
  }
  &__service-icon-wrapper {
    animation: fade 1s ease-in-out infinite;
    height: 64px;
    width: 64px;
    background-color: var(--background-color-tertiary);
    border-radius: var(--border-radius-default);
    position: relative;
  }
  &__service-icon {
    animation: fade 1s ease-in-out infinite;
    background-color: var(--background-color-tertiary);
    transform: translateX(-50%) translateY(-50%);
    height: 64px;
    width: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: center / contain no-repeat;
  }
  &__entity {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    &-service{
      display: flex;
    }
    &-service-link {
      background-color: var(--background-color-tertiary);
      height: 28px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 170px;
      margin-right: 20px;
    }

    &-service__icon {
      margin-right: var(--spacing-s);
      position: relative;
      vertical-align: text-top;
    }
  }
  &__user {
    display: flex;
    align-items: center;
    background-color: var(--background-color-tertiary);
    height: 28px;
  }
  &__lastrun {
    display: flex;
    align-items: center;
    background-color: var(--background-color-tertiary);
    height: 20px;
  }
  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
}

.loading-team-integrations-actionables {
  animation: fade 1s ease-in-out infinite;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--spacing-l) var(--spacing-xl) var(--spacing-zero) var(--spacing-xl);


  &__search-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__sort-add {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__sort {
    height: 28px;
    background-color: var(--background-color-tertiary);
    margin-right: var(--spacing-l);
  }
  &__search {
    height: 28px;
    background-color: var(--background-color-tertiary);
    margin-right: var(--spacing-l);
  }
  &__filter {
    height: 28px;
    background-color: var(--background-color-tertiary);
  }
  &__add-integration {
    height: 28px;
    background-color: var(--background-color-tertiary);
  }
}
