.integration-service-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.integration-service-details-page {
  display: flex;
  flex-direction: column;
  width: 960px;
  padding: 0 24px;
  box-sizing: border-box;
}


.integrations-service-details-breadcrumbs {
  margin-bottom: var(--spacing-l);
  &__team-name {
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    word-break: break-all;
    padding-right: var(--spacing-s);
    cursor: pointer;
  }

  &__divider {
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  &__integration-page {
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    padding: 0 var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    cursor: pointer;
  }
  &__browse{
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    padding: 0 var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    cursor: pointer;
  }
  &__service-text{
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    padding-left: var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }
}


