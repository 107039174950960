.history-sidebar-list-item-group {
  flex: 0 0 auto;

  &.is-hidden {
    display: none;
  }
}

.history-sidebar-list-item-group__error {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  padding: var(--spacing-xs) 0;
  color: var(--content-color-tertiary);

  span {
    cursor: pointer;
    display: inline-block;
    margin-left: var(--spacing-xs);
  }
}

.history-sidebar-list-item-group__meta {
  @include unselectable;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--spacing-s);
  height: 28px;
  cursor: pointer;

  @include hover {
    background: var(--highlight-background-color-secondary);

    .history-sidebar-list-item-group__actions {
      display: flex;
      .btn {
        background-color: transparent;
        height: var(--size-s);
        width: var(--size-s);
        border-radius: var(--border-radius-s);
        @include icon-color(var(--content-color-secondary));

        @include hover {
          background-color: var(--highlight-background-color-tertiary);
          @include icon-color(var(--content-color-primary));
        }
      }
    }
  }

  .caret {
    margin-right: var(--spacing-s);
    margin-left: var(--spacing-xs);
    @include icon-color(var(--content-color-secondary));

    &.is-closed {
      transform: rotate(-90deg);
    }
  }

  .history-sidebar-list-item-group__name {
    display: flex;
    line-height: var(--line-height-m);
    align-items: center;
  }

  .history-sidebar-list-item-group__actions {
    display: none;
    justify-content: space-around;
    align-items: center;
    flex: 0 0 50px;
    margin-right: var(--spacing-s);
  }
}

.history-sidebar-list-item-group__items {
  margin-bottom: 10px;
}

.history-list__meta {
  &:first-of-type {
    border-top: unset;
  }
}

.history-list__error {
  border-top: thin solid $app-border-color;
  border-bottom: thin solid $app-border-color;
}

.history-list__loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-sidebar-list__content {
  flex: 1;
  min-height: 0;
}

.sidebar-forbidden-error {
  height: 240px;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
}

.sidebar-forbidden-banner-container {
  position: absolute;
  width: 100%;
  bottom: 0;
}
