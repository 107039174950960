.markdown-html-root {
  ol, ul {
    margin: var(--spacing-zero) var(--spacing-xl);
    padding: var(--spacing-zero);

    li {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      margin-bottom: var(--spacing-s);
      display: list-item;

      & ol, & ul {
        margin-top: var(--spacing-s);
        margin-bottom: var(--spacing-s);
      }
    }
  }
}
