.beta-update {
  margin-top: 20px;
  
  .beta-update__heading {
    font-size: 14px;
    font-weight: 600;
  }

  .beta-update__button {
    margin-top: 10px;
  }
}
