.monitor-job {
  $padding-vertical-xs:  var(--spacing-xs) !default;
  $padding-vertical-sm:  var(--spacing-s) !default;
  $padding-vertical-md:  var(--spacing-l) !default;

  $padding-horizontal-sm: var(--spacing-l) !default;
  $padding-horizontal-xs: var(--spacing-s) !default;
  $padding-horizontal-md: var(--spacing-xxl) !default;
  $padding-horizontal-lg: var(--spacing-xxxl) !default;

  $border-radius-md: var(--border-radius-default) !default;

  $font-size-md: var(--text-size-m) !default;
  $font-size-xs: var(--text-size-xs) !default;



  .job-result-status {
    position: relative;
    background-color: var(--content-color-secondary);
    padding: $padding-vertical-sm $padding-horizontal-sm;
    border: var(--border-width-default) solid var(--border-color-strong);
    border-top: none;

    .run-status {
      font-size: $font-size-xs;
      display: block;

      @media (min-width: $breakpoint-sm) {
        display: inline;
      }

      &:not(:first-child) {
        margin-top: $padding-vertical-sm;

        @media (min-width: $breakpoint-sm) {
          margin-top: 0;
          margin-left: $padding-horizontal-xs;
        }
      }

      .pm-icon {
        margin-right: 5px;
        margin-top: -2px;
      }
    }
  }

  .job-results-container {
    border:  var(--border-width-default) solid var(--border-color-default);
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
    overflow-y: auto;
    display: flex;
    margin-top: var(--spacing-s);
    --filter-control-cell-width:35px;
    --filter-control-cell-height:35px;
  }

  .icon {
    &.success {
      @include icon("../assets/images/thumbnails/monitor_success.svg", 12px, 12px);
    }

    &.error {
      @include icon("../assets/images/thumbnails/monitor_error.svg", 12px, 12px);
    }
  }

  .job-results {
    @include unstyled-list;

    min-height: 12em;
    background-color: var(--background-color-primary);

    &.padded {
      padding: $padding-horizontal-md $padding-vertical-md;
    }

    .empty-state {
      padding: var(--spacing-l) 0;
      text-align: center;

      &__body {
        max-width: 360px;
        margin: 16px auto 32px;
      }
    }

    .request {
      border-bottom: var(--border-width-default) solid var(--border-color-default);
      font-size: $font-size-md;

      .ellipsify {
        @include text-truncate-ellipsis;
      }

      &:last-child {
        border-bottom: none;
      }

      .request-header {
        padding: $padding-vertical-sm $padding-horizontal-xs;
        border-bottom: var(--border-width-default) solid var(--border-color-default);
        cursor: pointer;

        .pm-row {
          display: flex;
          flex-direction: row;
          width: 100%;

          .request-meta {
            flex-grow: 4;
          }

          .request-summary{
            margin-right: var(--spacing-l);
            min-width: 200px;
          }

        }

      }

      .icon {
        float: left;
        margin-top: 2px;
      }

      .request-name {
        line-height: 1.5;
        font-weight: normal;
        display: inline;
        padding-left: $padding-horizontal-sm;
      }

      .request-method {
        font-size: 11px;
        font-weight: 600;
        padding-left: 12px;

        &.get {
          color: var(--base-color-success);
        }
        &.post {
          color: var(--base-color-warning);
        }
        &.put {
          color: var(--base-color-info);
        }
        &.patch {
          color: var(--content-color-secondary);
        }
        &.delete {
          color: var(--base-color-brand);
        }
      }

      .request-url {
        @include moderate-word-break;

        font-weight: 200;
        padding-left: 28px;
        margin-top: $padding-vertical-xs;
        display: block;

        @media (min-width: $breakpoint-md) {
          display: inline;
          padding-left: $padding-horizontal-xs;
          margin-top: 0;
        }
      }

      .response-code {
        font-size: $font-size-md;
        display: block;

        // On tiny screens, the response code is on its own row
        margin-top: $padding-vertical-md;

        // On small screens, the response code is on the same row as the request name
        @media (min-width: $breakpoint-sm) {
          margin-top: 0;
          text-align: right;
        }
      }

      .response-time {
        font-size: $font-size-xs;
        color: var(--content-color-link);
        display: block;

        @media (min-width: $breakpoint-sm) {
          text-align: right;
        }
      }

      .request-summary {
        margin-top: $padding-vertical-sm;
        padding-left: $padding-horizontal-lg;

        @media (min-width: $breakpoint-md) {
          margin-top: 0;
          padding-left: 0;
        }

        .run-status {
          &:not(:first-child) {
            padding-left: $padding-horizontal-xs;
          }

          .pm-icon {
            margin-right: 5px;
            margin-top: -2px;
          }
        }

        .no-response {
          color: var(--content-color-secondary);
        }
      }

      .request-test-results {
        .test-results {
          @include unstyled-list;
          line-height: 2;

          li {
            padding: $padding-vertical-sm $padding-horizontal-lg;

            .icon {
              margin-top: 5px;
            }
          }

          .test-result {
            display: inline-block;

            // Status changed to icons. No need to paint text for now.
            // &.passed {
            //   color: $success-color-bright;
            // }
            //
            // &.failed {
            //   color: $error-color-bright;
            // }
          }
        }
        .push-half--sides {
          margin-right: 8px !important;
          margin-left: 8px !important;
        }
      }
    }
  }

  .pm-link {
    color: var(--content-color-link);
    text-decoration: none;

    &:hover {
      color: var(--content-color-brand);
      text-decoration: underline;
    }
  }

  .hidden {
    display: none !important;
  }
}
