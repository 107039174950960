  /* stylelint-disable postman-stylelint/color-no-hex-rgb */
@keyframes indeterminateProgress {
	from { background-position: 0 0; }
	to { background-position: 7000px 0; }
}


.progress-bar {
  background-color: var(--background-color-tertiary);
  margin-top: 8px;
  height: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);

  .indeterminate {
    height: 100%;
    background-image: -webkit-repeating-linear-gradient(
      -45deg,
      #F8A97B 0px,
      #F8A97B 40px,
      #F47023 41px,
      #F47023 80px);
    background-repeat: repeat-x;
    animation: indeterminateProgress 60s linear infinite;
  }

  .determinate {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;

    &.success {
      background-color: var(--base-color-success);
    }

    &.warning {
      background-color: var(--base-color-warning);
    }

    &.error {
      background-color: var(--base-color-error);
    }
  }
}




