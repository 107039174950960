.floating-navigation-bar {
  --max-container-height: calc(15 * var(--size-s));
  --container-width: calc(11 * var(--size-s));

  &__container {
    position: absolute;
    bottom: calc(
      var(--spacing-xxl) + var(--spacing-xl)
    ); // --spacing-xl is height of the bottom pane
    right: var(--spacing-xxl);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 300;
  }

  &__container-api {
    bottom: var(--spacing-xl);
    right: var(--spacing-xxl);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 300;
    position: absolute;
  }

  &__content-container {
    max-height: var(--max-container-height);
    overflow: auto;
    background-color: var(--background-color-primary);
    box-shadow: var(--shadow-default);
    padding: var(--spacing-l);
    border-radius: var(--border-radius-default);
    margin-bottom: var(--spacing-m);
    width: var(--container-width);

    .side-navigation {
      position: relative;
    }
  }

  &__toggle-button {
    padding: var(--spacing-m);
    background-color: var(--background-color-primary);
    box-shadow: var(--shadow-default);
    border-radius: var(--border-radius-max);
    border: none;
    cursor: pointer;

    & > i {
      display: block;
    }
  }
}
