.test-suite-header {
  padding: var(--spacing-s) var(--spacing-l) !important;

  .suite-collection-name {
    margin-left: 4px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .test-suite-ctas {

    .generate-test-suite-btn {
      &.dark {
        background-color: var(--purple-50);

        color: var(--purple-10);

        svg {
          color: var(--purple-10);
        }
      }
      &.light {
        background-color: var(--purple-60);
      }


      &:disabled {
        opacity: 50%;
      }
    }
    .save-test-suite-btn, .generate-test-suite-btn {
      .rect-one,.rect-two,.rect-three {
        background-color: var(--purple-10);
      }
      .save-test-suite-btn, .generate-test-suite-btn {


        .rect-one,.rect-two,.rect-three {
          background-color: var(--purple-10);
        }
      }
    }
  }

}

.ai-loader {
  &.dark {
    p {
      color: var(--purple-30);
    }
    svg {
      path {
        fill: var(--purple-30);
      }
    }
  }

  &.light {
    p {
      color: var(--purple-60);
    }
    svg {
      path {
        fill: var(--purple-60);
      }
    }
  }
}

.test-suite-container {
  overflow: hidden;
  height: calc(100% - 64px);
  padding: 0 var(--spacing-l) 0 var(--spacing-l)  !important;

  .test-suite-table {
    border-collapse: collapse;
    width: 100%;

    th {
      text-align: left;
      height: 40px;
      position: sticky;
      top: 0;
      border: 1px solid var(--border-color-default);
      background-color: var(--background-color-primary);
      z-index: 1;

      .suite-header-content {
        width: 100%;
        height: 100%;
        padding: 0 8px 0 16px;

        button {
          font-weight: 500;
        }
      }
    }

    td {
      border: 1px solid var(--border-color-default);
    }

    td {
      padding: 16px;
      vertical-align: top;

      &.test-suite-tests-col {
        padding: 8px 16px 16px 16px;

        &.no-tests {
          padding-top: 16px;
        }

        &.test-results {
          padding: 8px 0;
        }
      }
    }

    .test-suite-request-col {
      width: 30%;

      .request-name-row {
        margin-bottom: var(--spacing-m);

        .suite-request-link {
          line-height: 16px;
        }
      }

      .request-method-icon {
        margin-right: var(--spacing-xs);
      }

      .response-dropdown-item {
        .response-dropdown-label {
          cursor: pointer;

          .response-data-collapsed {
            transform: rotate(-90deg);
          }
        }

      }
    }

    .response-data-block, .test-data-block {
      code {
        display: block;
        max-height: 400px;
        overflow: scroll;
        background-color: var(--background-color-primary);
        padding: 0 0 0 16px;
      }
    }

    .test-suite-response-col {
      width: 20%;
    }

    .test-suite-tests-col {

      &.response-error {
        padding: 0;
        position: relative;

        .response-error-banner {
          top: 0;
          height: 100%;

          &.non-json-error {
            position: absolute;
          }
        }
      }

      .test-info-container {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        code {
          cursor: default;
          padding: var(--spacing-s);
          margin: 0 16px var(--spacing-s) 16px;
        }

        pre {
          margin: 0;
        }

        .script-title-row {
          padding: var(--spacing-s) var(--spacing-l);

          .delete-script-icon {
            height: fit-content;
            opacity: 0;
          }

          .ai-added-script-icon {
            align-self: center;
          }

          &:hover {
            background-color: var(--background-color-secondary);
            border-radius: var(--border-radius-default);
            .delete-script-icon {
              opacity: 1;
              background-color: inherit;
            }
          }

        }

        .suite-monaco-editor {
          border: 1px solid var(--border-color-default);
          border-radius: var(--border-radius-default);
          margin: 0 16px;
        }

      }
    }

    .test-suite-script-item {
      margin-left: var(--spacing-s);
      width: max-content;
    }

    .script-title-row.expanded {
      margin-bottom: var(--spacing-s)
    }

  }
}
