/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.report-api {
  margin-left: 135px;
  margin-right: 135px;

  .pm-h1 {
    display: inline-block;
    margin-top: var(--spacing-xxl);
    margin-bottom: 0;
  }

  &__versions {
    display: inline-block;
    vertical-align: middle;
    margin-top: var(--spacing-xxl);

    .pm-badge {
      margin: 0 var(--spacing-l);
      vertical-align: middle;
    }

    .pm-link {
      vertical-align: middle;
    }
  }

  &-filters {
    margin-top: var(--spacing-l);

    &__label {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
    }

    .pm-select {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      margin-left: var(--spacing-l);
      cursor: pointer;
      width: 130px; // approximate max-width that can be applied without breaking inline filters on mobile screens

      .Select-clear-zone {
        display: none;
      }
    }

    &__collections {
      .Select-menu-outer {
        .Select-menu .Select-option:first-child {
          padding-bottom: var(--spacing-l);
          border-bottom: 1px solid var(--border-color-default);
        }
      }
    }
  }

  &-versions {
    @include unstyled-list;

    li {
      height: 40px;
      margin: var(--spacing-l) 0;
      margin-left: -var(--spacing-s);
      margin-right: -var(--spacing-s);
      padding: 10px var(--spacing-s);
      border-radius: var(--border-radius-s);

      &:hover {
        background: rgba(241,90,36,0.1);
        cursor: pointer;
      }
    }
  }

  &-metrics {
    margin-top: var(--spacing-l);

    &__label {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
    }

    &__list {
      @include inline-list;
    }

    &__item {
      font-size: var(--text-size-s);
      margin-left: var(--spacing-xs);
      font-weight: var(--text-weight-medium);
      background: var(--border-color-strong);
      border-radius: var(--border-radius-s);
      padding: 0 var(--spacing-s);

      a {
        text-decoration: none;
        color: var(--content-color-secondary);
      }
    }
  }

  &__no-reports {
    padding-top: var(--spacing-xxl);
    overflow: hidden;

    .empty-state__title {
      margin-top: var(--spacing-l);
    }

    .empty-state__body {
      max-width: 600px; // by design
      margin-top: var(--spacing-s);
    }
  }

  .pm-link {
    text-decoration: underline;
  
    &:hover {
      color: var(--content-color-link);
      text-decoration: underline;
      cursor: pointer;
    }
  
    &:visited {
      text-decoration: none;
    }
  }

  .report-loading-indicator {
    display: block;
    margin: 64px auto 16px;
    max-width: 48px;
    position: unset;

    @media (min-width: 768px) {
      margin-top: 192px;
    }
  }
}
