.documentation-context-bar-footer {
  bottom: 0;
  display: flex;
  cursor: pointer;
  position: sticky;
  align-items: end;
  font-size: var(--text-size-m);
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
  background: var(--background-color-primary);
  padding: var(--spacing-l) var(--spacing-s);
  border-top: var(--border-style-solid) var(--border-width-default)
    var(--border-color-default);

  /**
   * Fix for Safari: Safari seems to be collapsing the context bar header/footer when the container
   * is a flex container and has the height set to 100% -- which is the case for us because scrolling
   * doesn't work otherwise. Forcibly setting the flex-shrink for the header and footer seems to fix
   * this in Safari without breaking it in other browsers.
   *
   * Tracking the removal of this (some day) in https://postmanlabs.atlassian.net/browse/DOCUMENTER-2159
   */
  flex-shrink: 0;

  // The Icon component doesn't allow us to add custom classes, so this is as good of a selector as
  // we're going to get.
  > i {
    margin-left: var(--spacing-xs);
  }
}
