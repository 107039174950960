.gateway-import-schema-form-container {
  width: 100%;
  height: 100%;
  &__segment{
    margin-top: var(--spacing-xl);
  }
}

.observability-import-schema-modal {
  &__content{
    overflow-y: unset !important;
    padding-bottom: var(--spacing-m) !important;
  }
}
