$missing-current-workspace-thumbnail-url: $thumbnail-path + 'browse-view-offline.svg';
.missing-current-workspace-modal {
  font-family: $font-primary;
  .missing-current-workspace-modal__wrapper {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .missing-current-workspace-thumbnail__container {
    height: 200px;
    width: 200px;
    .missing-current-workspace-thumbnail {
      height: inherit;
      width: inherit;
      background: url($missing-current-workspace-thumbnail-url) no-repeat center;
      background-size: contain;
    }
  }
  .missing-current-workspace-modal__content {
    margin-top: 10px;
  }
  .missing-current-workspace-modal__header {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.8;
  }
  .missing-current-workspace-modal__subtext {
    font-size: 12px;
    margin: 10px 0 30px;
    opacity: 0.6;
    text-align: center;
  }

  .missing-current-workspace-modal__action {
    display: flex;
    justify-content: center;
  }

  .modal-header-close-button {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.6;
  }
}
