.stars-loader-container {
  max-height: 20px;
  .svg-container {
    display: inline-block;
    cursor: pointer;
  }

  .animate {
    animation: growShrink 0.3s infinite alternate;

  }

  .delay {
    animation-delay: 0.9s; /* Add a 1-second delay */
  }

  .small {
      width: 6px;
      height: 6px;
  }

  .two-stars-left {
    gap: 2px;
  }

  @keyframes growShrink {
    0% {
      transform: scale(0.75);
    }
    100% {
      transform: scale(1.5);
    }
  }
}

