.globals-sidebar-list-item {
  display: flex;
  margin: var(--spacing-zero) var(--spacing-s);
  height: 32px;
  align-items: center;
  border-radius: var(--border-radius-default);
  @include unselectable;
  @include clickable;

  @include hover {
    background-color: var(--highlight-background-color-primary);

    .globals-sidebar-list-item__actions {
      display: flex;
      margin-left: var(--spacing-s);

      .btn {
        background-color: transparent;
      }
    }
  }

  &.selected {
    background-color: var(--background-color-primary)
  }

  &__icon{
    display: flex;
    align-items: center;
    margin: var(--spacing-zero) var(--spacing-s);

    @include icon-color(var(--content-color-info))
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    color: var(--content-color-primary);

    @include unselectable;
    @include clickable;
  }

  &__actions {
    display: none;
  }
}

.globals-separator {
  height: 1px;
  background-color: var(--border-color-default);
  margin: var(--spacing-s);
}
