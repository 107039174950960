.collection-explorer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__path {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    margin-bottom: var(--spacing-s);

    &__empty{
      margin-left: var(--spacing-s);
    }

    .breadcrumb__container{
      flex: 1;
      margin-left: var(--spacing-s);
    }
  }

  &__blank-state {
    display: flex;
    flex: 1;
    padding: var(--spacing-xl) var(--spacing-l)
  }
}
