.in-app-modal {
  padding: var(--spacing-zero);

  &-header{
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-zero);
  }

  &-content {
    padding: var(--spacing-zero) !important;
    &-markdown {
      @include markdown();
    }
    &-center {
      text-align: center;
    }
  }

  &-container {
    z-index: 50;
    position: relative;
  }
}


.fullscreen-modal {
  padding: var(--spacing-zero);

  &-header{
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-zero);
  }

  &-content {
    box-shadow: var(--shadow-default);

    padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-l) var(--spacing-zero) !important;

    &-markdown {
      @include markdown();
    }
    &-center {
      text-align: center;
    }
  }

  &-container {
    z-index: 50;
    position: relative;
  }
}
