.observability-azure-dashboard-container{
  display: flex;
  flex-direction: column;
  margin: var(--spacing-zero) var(--spacing-xxxl);
  height: 100%;
  overflow-y: auto;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--spacing-xxl);

    &__left-section {
      display: flex;
      &__source {
        display: flex;
        align-items: center;
        margin-left: var(--spacing-m);
        height: 100%;
        align-self: flex-end;

        &__icon {
        margin: var(--spacing-zero) var(--spacing-xs);
        }
      }

    }

    &__right-section {
      display: flex;
      align-items: center;
      &__import-button {
        margin-right: var(--spacing-xs);
      }
    }
  }
  &__content {
    margin-top:var(--spacing-xxl);

    &__source {
      margin-bottom: var(--spacing-l);
    }
  }
}

.observability-azure-services-table {
  tbody {
    tr {
      td {
        padding: var(--spacing-zero) var(--spacing-s);
        .azure-custom-cell {
          height: 44px;
          display: flex;
          align-items: center;
          &__no-source {
            color: var(--content-color-tertiary);
            margin-right: var(--spacing-s);
          }
          &__ip-address {
            color: var(--content-color-secondary);
            margin-left: var(--spacing-s);
          }
        }
      }
    }
  }

  &__link {
    &__modal {
      max-width: 480px;
      margin: auto;
      &__title > h2{
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-medium);
        color: var(--content-color-secondary);
      }

      &__content {
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        color: var(--content-color-primary);
        overflow: visible;
      }
      &__footer {
        margin-top: var(--spacing-xxxl);
      }
    }
  }
}

.observability-azure-table-container {
  margin: var(--spacing-xxl) var(--spacing-zero);
  &__heading{
    margin-bottom: var(--spacing-s);
  }
}

.observability-detailed-dashboard-container{
  height: 100%;
}

.observability-azure-detailed-dashboard-container {
  padding: var(--spacing-xxl) var(--spacing-xxxl) var(--spacing-zero) var(--spacing-xxxl);
  height: 90%;
  overflow-y: auto;
  &__header{
    border-bottom: var(--border-style-solid) var(--border-width-default);
    border-color: var(--border-color-strong);
    padding: var(--spacing-s) var(--spacing-s);
    &__back {
      display: flex;
      align-items: center;
    }
    &__services {
      width: 178px;
      margin-left: var(--spacing-xs);
    }
    &__refresh {
      margin: var(--spacing-zero) var(--spacing-xs) !important;
    }
    &__import {
      margin-left: var(--spacing-xs);
    }
  }
  &__integration-name {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
  }
  &__status {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
    margin-left: var(--spacing-l);
  }
  &__label {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-medium);
    margin-left: var(--spacing-l);
    &__value {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      margin-left: var(--spacing-xs);
    }
  }
  &__row2 {
    margin-top: var(--spacing-l);
    &__label {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-medium);
      margin-left: var(--spacing-l);
      &__value {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        color: var(--content-color-secondary);
        margin-left: var(--spacing-xs);
      }
    }
    &__api {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-medium);
    }
    &__portal {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      margin-left: var(--spacing-l);
    }
  }
}

.postman-source-error {

  &__message {
    color: var(--content-color-error)
  }
  &__link {
    color: var(--content-color-error);
  }
  &__link:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.observability-azure-add-integration {
  &__title {
    &__description {
      margin-top: var(--spacing-xs);
      margin-bottom: var(--spacing-l);
    }
  }
  &__nickname {
    margin-top: var(--spacing-xl);
    font-size: var(--text-size-l);
  }
  &__service {
    font-weight: var(--text-weight-medium);
    margin-top: var(--spacing-l);
    &__workspace-selectors {
      margin-top: var(--spacing-l);
    }
    &__component {
      margin-bottom: var(--spacing-l);
    }
    &__section {
      margin-left: var(--spacing-l);
      margin-top: var(--spacing-l);
      &__container {
        margin-top: var(--spacing-l);
        border-bottom: var(--border-style-solid) var(--border-width-default);
        border-color: var(--border-color-strong);
      }
      &__header {
        margin-left: var(--spacing-xs);
        &__link {
          cursor: pointer;
        }
      }
      &__setup-service {
        margin-bottom: var(--spacing-l);
      }
      &__remove {
        display: flex;
      }
    }
  }
  &__buttons {
    margin-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xxl);
    &__cancel {
      margin-left: var(--spacing-m);
    }
    &__add-service {
      margin-top: var(--spacing-l);
    }
  }
}
.azure-current-revision-modal-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.highlight-dropdown-options{
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
