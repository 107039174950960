.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  color: $app-generic-text-color;

  .no-result-image {
    @include icon(
      '../assets/images/thumbnails/emptysearch.svg',
      120px,
      105px
    );
    margin-bottom: 20px;
  }

  .label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .subtext {
    font-size: 12px;
    opacity: 0.6;
  }
}
