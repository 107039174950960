.interceptor-snap-warning {
  padding-top: 30px;
  .interceptor-snap-label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--content-color-primary);
    padding-bottom: 8px;
  }
  .interceptor-snap-info {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 16px;
    color: var(--content-color-secondary);
  }
}