$empty-state-path: '../assets/images/empty-state/' !default;
$icon-team-url: $empty-state-path + 'team.svg';

.permission-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .permission-error--illustration {
    margin-bottom: var(--spacing-s);
  }

  .permission-error--title,
  .permission-error--description {
    color: var(--content-color-primary);
    font-size: 12px;
  }

  .permission-error--title {
    font-weight: 600;
    margin-bottom: var(--spacing-s);
    font-size: 14px;
  }

  .permission-error--action {
    padding: 0 18px;
  }

  .permission-error--description {
    margin-bottom: var(--spacing-l);
    text-align: center;

    p {
      margin: 0px;
      line-height: 20px;
    }
  }
}
