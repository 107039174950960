.flatpickr-calendar {
  --flatpickr-width: 308px;
  --flatpickr-height-s: 14px;
  --flatpickr-height-m: 34px;
  --flatpickr-height-l: 40px;
  --flatpickr-height-xl: 640px;
  --flatpickr-margin-xxs: 1px;
  --flatpickr-margin-xs: 2px;
  --flatpickr-margin-s: 5px;
  --flatpickr-margin-m: 10px;
  --flatpickr-margin-l: 22px;
  --flatpickr-day-radius: 150px;


  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: var(--text-size-m);
  line-height: var(--line-height-l);
  border-radius: var(--border-radius-default);
  position: absolute;
  width: var(--flatpickr-width);
  box-sizing: border-box;
  touch-action: manipulation;
  background: var(--background-color-secondary);
  box-shadow: var(--border-width-default) 0 0 var(--border-color-strong), calc((-1) * var(--border-width-default)) 0 0 var(--border-color-strong), 0 var(--border-width-default) 0 var(--border-color-strong), 0 calc((-1) * var(--border-width-default)) 0 var(--border-color-strong);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: var(--flatpickr-height-xl);
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top:  var(--flatpickr-margin-xs);
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: var(--flatpickr-height-l);
  border-top: var(--border-width-default) solid var(--border-color-strong);
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: var(--flatpickr-margin-l);
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: var(--flatpickr-margin-l);
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: var(--flatpickr-margin-s);
  margin: 0 calc((-1) * var(--flatpickr-margin-s));
}

.flatpickr-calendar:after {
  border-width: var(--border-width-xl);
  margin: 0 calc((-1) * var(--border-width-xl));
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--background-color-secondary);
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--background-color-secondary);
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--background-color-secondary);
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--background-color-secondary);
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: var(--content-color-primary);
  fill: var(--content-color-primary);
  height: var(--flatpickr-height-m);
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: var(--flatpickr-height-m);
  padding: var(--flatpickr-margin-m);
  z-index: 3;
  color: var(--content-color-primary);
  fill: var(--content-color-primary);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: var(--highlight-background-color-secondary);
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--content-color-brand);
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: var(--flatpickr-height-s);
  height: var(--flatpickr-height-s);
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: var(--flatpickr-height-s);
  padding: 0 var(--spacing-xs) 0 var(--flatpickr-margin-xs);
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: var(--border-width-default) solid var(--border-color-strong);
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: var(--highlight-background-color-primary);
}

.numInputWrapper span:active {
  background: var(--highlight-background-color-secondary);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  --custom-spacer: 2px;
  top: 0;
  right: calc((-1) * var(--custom-spacer));
  border-bottom: 0;
  background-color: inherit;
}

.numInputWrapper span.arrowUp:after {
  border-left: var(--border-width-xl) solid transparent;
  border-right: var(--border-width-xl) solid transparent;
  border-bottom: var(--border-width-xl) solid var(--content-color-primary);
  top: 36%;
}

.numInputWrapper span.arrowDown {
  --custom-spacer: 2px;
  top: 50%;
  right: calc((-1) * var(--custom-spacer));
  background-color: inherit;
}

.numInputWrapper span.arrowDown:after {
  border-left: var(--border-width-xl) solid transparent;
  border-right: var(--border-width-xl) solid transparent;
  border-top: var(--border-width-xl) solid var(--content-color-primary);
  top: 10%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: var(--background-color-secondary);
}

.numInputWrapper:hover {
  background: var(--highlight-background-color-secondary);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: var(--text-size-m);
  line-height: inherit;
  font-weight: var(--text-weight-regular);
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: var(--spacing-s) 0 0 0;
  line-height: 1;
  height: var(--flatpickr-height-m);
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-medium);
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: var(--highlight-background-color-secondary);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--content-color-primary);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--content-color-primary);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  width: 115%;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: var(--content-color-primary);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  height: auto;
  line-height: inherit;
  margin: calc((-1) * var(--flatpickr-margin-xxs)) 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: var(--highlight-background-color-secondary);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--background-color-secondary);
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(2 * var(--flatpickr-height-s));
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: var(--content-color-secondary);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: var(--flatpickr-margin-xxs) 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: var(--flatpickr-width);
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: var(--flatpickr-width);
  min-width: var(--flatpickr-width);
  max-width: var(--flatpickr-width);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.flatpickr-day {
  background: none;
  border: var(--border-width-default) solid transparent;
  border-radius: var(--flatpickr-day-radius);
  box-sizing: border-box;
  color: var(--content-color-primary);
  cursor: pointer;
  font-weight: var(--text-weight-regular);
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: var(--flatpickr-height-l);
  height: var(--flatpickr-height-l);
  line-height: var(--flatpickr-height-l);
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: var(--highlight-background-color-secondary);
  border-color: var(--background-color-secondary);
}

.flatpickr-day.today {
  border-color: var(--border-color-strong);
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--border-color-strong);
  background: var(--content-color-secondary);
  color:  var(--background-color-primary);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--base-color-brand);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: var(--background-color-primary);
  border-color: var(--base-color-brand);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: var(--content-color-tertiary);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color:  var(--content-color-tertiary);
  opacity: 0.3;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: var(--flatpickr-margin-xxs);
}

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: var(--flatpickr-height-l);
  max-height: var(--flatpickr-height-l);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: var(--flatpickr-height-l);
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--content-color-primary);
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--content-color-primary);
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color:  var(--content-color-primary);
  font-size: var(--text-size-m);
  position: relative;
  box-sizing: border-box;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: var(--text-weight-regular);
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: var(--text-weight-regular);
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color:  var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  width: 2%;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: var(--text-weight-regular);
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--highlight-background-color-secondary);
}
