$history-empty-url: $thumbnail-path + 'activity-feed.svg'; // Need to replace with history empty illustration once design teams provides it

.entity-empty {
  .entity-empty__illustration-wrapper--history {
    .history-empty__illustration {
      @include icon(
        $history-empty-url,
        200px,
        200px
      )
    }
  }
}
