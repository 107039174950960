.requester-builder {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.requester-builder-header {
  flex: none;
  height: 41px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  border-bottom: 1px solid $app-border-color;

  .requester-tabs-wrapper {
    display: flex;
    flex: 1;
    position: relative;
    min-width: 0;
    height: 40px;

    .requester-tab-actions {
      display: inherit;
      position: sticky;
      right: 0;
      align-items: center;
    }

    .requester-tab-nav-btn-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;

      &.nav-btn-left {
        border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        padding: var(--spacing-xs);
      }

      &.nav-btn-right {
        border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        padding-left: var(--spacing-xs);
      }

      .requester-tab-nav-previous-icon{
        opacity: 0.8;
        padding: 0 5px;

        &:hover {
          cursor: pointer;
        }
      }

      .requester-tab-nav-next-icon {
        opacity: 0.8;
        padding: 0 5px;
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.requester-builder-contents {
  background: inherit;
  flex: 1;

  overflow-y: hidden;

  display: flex;
  flex-direction: column;
}

.environment-resize-handle-wrapper {
  cursor: ew-resize;
  margin-right: var(--spacing-xs);

  .environment-resize-handle {
    background: var(--border-color-default);
    height: 100%;
    width: 1px;
  }

  &:hover {
    .environment-resize-handle {
      background: var(--border-color-strong);
    }
  }
}
