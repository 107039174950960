.image-balloon-container {
  position: absolute;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-width-xl);
  background: var(--background-color-primary);
  z-index: 2;
  padding: var(--spacing-m) !important;
  align-items: center;
  color: var(--content-color-link);
  display: flex;
  width: 350px;

  button {
    white-space: nowrap;
  }

  &.small {
    width: 255px;
  }

  &.auto {
    width: auto;
    padding: var(--spacing-zero) !important;
  }

  &--legacy {
    width: 300px;
    padding: var(--spacing-s);
  }

  .image-close-btn {
    position: absolute;
    top: var(--spacing-s);
    right: var(--spacing-s);
  }

  .image-balloon-tabs {
    width: 100%;
    padding: var(--spacing-zero);
  }

  .image-balloon-banner {
    margin-top: var(--spacing-m);
  }

  .image-balloon-loader {
    width: 68px;
    height: 38px;
    line-height: 36px;
  }

  input:disabled + label[for*="mimir-file-uploader"] {
    opacity: 0.5;
  }

  label[for*="mimir-file-uploader"] {
    margin-top: var(--spacing-zero);
    color: var(--content-color-constant);
    background-color: var(--base-color-brand);
    height: 38px;
    line-height: 36px;
    font-weight: bold;
  }

  label[for*="mimir-file-uploader"] + div {
    display: none;
  }

  .image-url-input {
    flex-grow: 1;
    background: var(--background-color-secondary);
    border: 1px solid var(--content-color-tertiary);
    border-radius: var(--border-width-xl);
    padding: 3px var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: 18px;

    &::placeholder {
      color: var(--content-color-tertiary);
    }
  }

  .text-button {
    cursor: pointer;
    font-size: var(--text-size-m);
    height: var(--size-s);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px var(--spacing-xs) !important;
    margin: 0px 0px 0px var(--spacing-s) !important;
    color: var(--content-color-secondary) !important;

    &.text-button--disabled {
      color: var(--content-color-tertiary) !important;
      cursor: not-allowed;
    }

    svg,
    path {
      fill: var(--content-color-secondary) !important;
    }

    svg {
      color: var(--content-color-secondary) !important;
    }
  }

  .icon-button {
    height: var(--size-s);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-size-m) !important;
    line-height: 18px !important;
    cursor: pointer;
    margin: 0px var(--spacing-s) !important;
    svg,
    path {
      fill: var(--content-color-secondary) !important;
    }

    svg {
      color: var(--content-color-secondary) !important;
    }
  }

  .usage-container {
    background-color: var(--background-color-secondary);
    padding: var(--spacing-s);
    margin-top: var(--spacing-s);
    border-radius: var(--border-radius-default);
    width: 100%;
    color: var(--content-color-secondary);
    animation: fadeIn 300ms ease-in-out;

    .file-usage-bars {
      position: relative;
      height: 5px;

      .file-limit,
      .file-usage {
        position: absolute;
        left: 0;
        height: 100%;
        border-radius: var(--border-radius-default);
      }
      .file-limit {
        background-color: var(--background-color-tertiary);
        width: 100%;
      }
      .file-usage {
        background-color: var(--base-color-success);
        width: 0;
        transition: width 500ms ease-in-out;
        cursor: pointer;

        &--warning {
          background-color: var(--base-color-warning);
        }
        &--error {
          background-color: var(--base-color-error);
        }
      }
    }

    .file-usage-text {
      margin-top: var(--spacing-s);
      margin-bottom: var(--spacing-zero);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      display: none;
    }
    100% {
      opacity: 1;
      display: block;
    }
  }
}
