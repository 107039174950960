.workspace-error-state {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: var(--content-color-primary);
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-m);
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
  }

  &__description {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    margin-bottom: var(--spacing-xs);
  }

  &__switch-btn {
    margin: var(--spacing-l) 0;
    height: var(--controls-size-default);
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
  }
}
