.monitor-actions-dropdown {
  .dropdown-menu {
    margin-top: 0;
    margin-right: var(--spacing-xs);
    min-width: 220px;

    .dropdown-menu-item {
      padding: 0 var(--spacing-l);

      .dropdown-menu-item-icon {
        margin-right: var(--spacing-s);
      }
    }
  }

  .dropdown-menu-item--delete {
    border-top: var(--border-width-default) solid var(--border-color-default);
  }
}

.monitor-action-item {
  display: flex;
  flex: 1;
}
