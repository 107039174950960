$requester-header-z-index: 90;

// this is a component specific token defined only for the exceptional case of the requester header top navigation
// this token is not intended to be used anywhere apart from the top navigation tabs
$tab-primary-padding: 14px;

$requester-header-height: 48px;
$requester-header-breakpoint: 1022px;

// specific to reduce padding between icons to increase drag area in header at smaller viewport widths
$requester-header-breakpoint-small: 675px;

$requester-header-signedout-breakpoint-small: 640px;

/*
  - These classes provide the color to the icons with the above mentioned values
  - These classes are only specific to the Requester header and not intended to use anywhere else
*/
.header-success-icon-color {
  @include icon-color(var(--content-color-success));
}

.header-warning-icon-color {
  @include icon-color(var(--content-color-warning));
}

.header-error-icon-color {
  @include icon-color(var(--content-color-error));
}

.requester-header {
  -webkit-app-region: drag;
  background-color: var(--global-navigation-background-color);
  z-index: $requester-header-z-index;

  display: flex;
  flex-direction: row;
  box-shadow: inset 0px -1px 0px var(--border-color-strong);
  align-items: stretch;

  flex: 0 0 $requester-header-height;
  padding: 0 var(--spacing-m);

  // This prevents incorrect selection of the searchfield text happening from double clicking the header outside of the Search Component.
  user-select: none;

  &-overlay-area {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
}

.requester-header-windows-app {
  padding: 0;
}

.requester-header-no-click-style,
.requester-header-no-click-style * {
  // For Mac OS desktop app non full screen mode we need to use cursor style default for all header options
  // Many of the header options define cursor pointer separately in child component style
  // To override those styles important is being used
  cursor: default !important;
}

.requester-header-homepage-signed-out {
  max-width: 100vw;
  box-shadow: unset;
  flex-basis: 48px;
  position: relative;

  .requester-header__section-left {
    @media screen and (max-width: $requester-header-signedout-breakpoint-small) {
      .top-navigation-buttons {
        display: none;
      }
    }
  }

  .pm-search-box-container {
    flex: 1;
    display: flex;
    justify-content: center;

    // Make sure the expanded search popover renders fine
    &.is_focused {
      border: 1px solid var(--border-color-strong);
      display: unset;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .requester-header__section-right {
    min-width: unset;
    @media screen and (max-width: $requester-header-signedout-breakpoint-small) {
      display: none;
    }

    &-responsive {
      display: none;

      @media screen and (max-width: $requester-header-signedout-breakpoint-small) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .top-navigation-buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;

        .top-navigation-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          cursor: pointer;
          padding: var(--spacing-m);
          font-size: var(--text-size-l);
          line-height: var(--line-height-m);
          font-weight: var(--text-weight-medium);
          color: var(--content-color-secondary);

          @include hover {
            color: var(--content-color-primary);
          }

          // Remove space between product dropdown and caret for signed out homepage
          .dropdown-caret {
            margin-left: unset;
          }
        }
      }

      .requester-header__user-info {
        margin-right: unset;
        margin-top: 36px;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .mobile-search-container {
        @media screen and (max-width: $requester-header-signedout-breakpoint-small) {
          display: flex;
        }
      }
    }
  }

  .pm-search-box-container {
    @media screen and (max-width: $requester-header-signedout-breakpoint-small) {
      display: none;
    }
  }

  .mobile-search-container {
    &.is-hidden-on-mobile{
      @media screen and (max-width: $requester-header-signedout-breakpoint-small) {
        display: none;
      }
    }
  }
}

.requester-header-signed-out-workspace {
  box-shadow: inset 0 -1px 0 var(--border-color-strong);
}

.requester-header-mac__section-left {
  margin-left: 76px;
}

.requester-header__section-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  &.fit-content {
    max-width: fit-content;
  }

  .top-navigation-buttons {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: var(--spacing-xs);

    .top-navigation-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      user-select: none;
      cursor: pointer;
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-regular);
      color: var(--content-color-primary);
      margin-right: var(--spacing-xs);
      @media screen and (max-width: $requester-header-breakpoint-small) {
        margin-right: var(--spacing-zero);
      }
      &.is-active {
        box-shadow: none;
      }

      // Remove space between product dropdown and caret for signed out homepage
      .dropdown-caret {
        margin-left: unset;
      }
    }
  }

  &-divider {
    display: flex;
    flex-direction: column;
    height: var(--controls-size-small);
    margin-right: var(--spacing-xl);
    border: none;
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.top-navigation-buttons-dropdown {
  .dropdown-menu {
    box-sizing: border-box;
    padding: var(--spacing-l) 0;
    background-color: var(--background-color-primary);
    .dropdown-menu-item {
      padding: 0;
      a {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 0 var(--spacing-xl);
        &:hover {
          color: var(--content-color-link);
        }
      }
      &:hover {
        background-color: unset;
      }
    }
  }

  .top-navigation-dropdown {
    &__menu {
      background-color: var(--background-color-primary);
      // to override styles set by dropdown component
      top: 55px !important;
      left: 55px !important;
    }
  }
}

.create-new-button-dropdown {
  .dropdown-menu-item {
    .dropdown-menu-item__text {
      margin-left: 10px;
    }
  }
}

.requester-header__section-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-m);
  z-index: 100;

  @media screen and (max-width: 1190px) {
    margin-right: var(--spacing-xs);
  }

  @media screen and (max-width: $requester-header-breakpoint-small) {
    margin-right: var(--spacing-zero);
  }
}

.requester-header__section-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  &__action-button {
    margin-right: var(--spacing-xs);
    @media screen and (max-width: $requester-header-breakpoint-small) {
      margin-right: var(--spacing-zero);
    }
  }

  .requester-header__team-info__button {
    .team-icon {
      margin-right: var(--spacing-s);
    }

    .dropdown-caret {
      margin-left: var(--spacing-xs);
    }
  }

  .requester-header__settings-group-dropdown {
    margin-right: 10px;
  }

  .requester-header__section-right__group {
    display: flex;
    align-items: center;

    & > .notification-container {
      margin-right: 10px;
    }
  }

  .btn-icon {
    background: transparent;
    @include icon-color(var(--content-color-secondary));

    &:not(.sync-button) {
      @include hover {
        background-color: var(--highlight-background-color-primary);
        @include icon-color(var(--content-color-primary));
      }

      &.is-active {
        background-color: var(--highlight-background-color-primary);
        @include icon-color(var(--content-color-primary));
      }
    }
  }

  .workspace-invite-icon-btn {
    display: none;
  }

  @media (max-width: 1190px) {
    .workspace-invite-btn {
      display: none;
    }

    .workspace-invite-icon-btn {
      display: flex;
    }
  }
}

.user-info-dropdown {
  .dropdown-menu {
    width: 300px;
    background: var(--background-color-primary);
    max-height: 500px;
    overflow-y: auto;
    padding: var(--spacing-s) var(--spacing-zero);

    .dropdown-menu-item {
      height: auto;
      background: transparent;
      padding: var(--spacing-zero);

      &.dropdown-menu-item--username {
        opacity: 1;
      }

      &:not(.is-disabled) {
        @include hover {
          background-color: var(--background-color-secondary);
        }
      }
    }

    .requester-header__user-info__menu-item {
      display: flex;
      align-items: center;
      height: var(--spacing-l);
      padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l);

      font-family: var(--text-family-default);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-primary);
      width: 100%;
    }
  }
}

.requester-header__user-info {
  margin-right: var(--spacing-m);
  @media screen and (max-width: $requester-header-breakpoint-small) {
    margin-right: var(--spacing-s);
  }


  .requester-header__user-info__button {
    width: var(--controls-size-default);
    height: var(--controls-size-default);
    vertical-align: middle;

    .requester-header__user-info__button-wrapper {
      display: flex;
      align-items: center;

      .pm-icon-sm {
        width: 24px;
        height: 24px;
      }
    }
  }

  .dropdown-filled-caret {
    margin-left: var(--spacing-zero);
  }
}

.settings-button {
  .settings-button-icon {
    &.indicator {
      &:after {
        content: '';
        background-color: var(--base-color-brand);
        position: absolute;
        border-radius: var(--border-radius-max);
        top: 4px;
        left: 22px;
        height: 7px;
        width: 7px;
      }

      &.error {
        &:after {
          background-color: var(--base-color-error);
        }
      }
    }
  }
}

#aether-popover-portal {
  .settings-header-update-available,
  .settings-header-update-downloaded {
    background-color: var(--base-color-brand);
    color: var(--content-color-constant);
    @include hover {
      // @todo @prashant
      background-color: var(--highlight-background-color-brand);
    }
    @include active {
      background-color: var(--highlight-background-color-brand);
    }
  }
  .settings-header-update-failed {
    background-color: var(--base-color-error);
    color: var(--content-color-constant);
    // @todo @prashant
    @include hover {
      background-color: var(--highlight-background-color-error);
    }
    @include active {
      background-color: var(--highlight-background-color-error);
    }
  }
}

.settings-dropdown {
  z-index: 100; // has to be greater than z-index of topbar

  .dropdown-menu {
    background-color: var(--background-color-primary);
  }

  .dropdown-menu {
    .dropdown-menu-item--update-available,
    .dropdown-menu-item--update-downloaded {
      background-color: var(--base-color-brand);
      color: var(--content-color-constant);
      @include hover {
        // @todo @prashant
        background-color: var(--highlight-background-color-brand);
      }
      @include active {
        background-color: var(--highlight-background-color-brand);
      }
    }
    .dropdown-menu-item--update-failed {
      background-color: var(--base-color-error);
      color: var(--content-color-constant);
      // @todo @prashant
      @include hover {
        background-color: var(--highlight-background-color-error);
      }
      @include active {
        background-color: var(--highlight-background-color-error);
      }
    }
  }
}

.interceptor-button {
  &.is-active {
    background-color: var(--base-color-brand);
  }
}

.interceptor-wrapper {
  display: flex;

  .interceptor-toggle-switch {
    margin-left: 10px;
  }
}

.requester-header__user-info__divider {
  background: var(--background-color-tertiary);
  margin: var(--spacing-s) var(--spacing-zero);
  height: 1px;
  border: unset;
}

.requester-header-entity {
  -webkit-app-region: no-drag;
}
