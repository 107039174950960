.collection-editor-header {
  &__action {
    margin-right: var(--spacing-s);

    &.with-label i {
        margin-right: var(--spacing-xs);
    }
  }

  &__action-experiment {
    margin-right: var(--spacing-s);
    min-width: auto;

    &.btn {
      height: var(--size-m);
    }

    &.with-label i {
      margin-right: var(--spacing-xs);
    }
  }

  &__subtitle {
    &__fork-label {
      span.fork-label {
        max-width: unset;
        line-height: var(--line-height-s);
      }
    }
  }
}

.collection-watch-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: var(--spacing-s);

  .watch-button {
    min-width: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: var(--text-size-m);
  }

  .watch-label {
    margin-left: var(--spacing-s);
  }

  .watcher-count-btn {
    margin: var(--spacing-zero);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    user-select: none;
    background-color: var(--background-color-tertiary);
    box-sizing: border-box;
    width: 34px;
    height: var(--size-m);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

    @include hover {
      background-color: var(--highlight-background-color-tertiary);
    }

    .count-label {
      color: var(--content-color-secondary);
      height: var(--size-s);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);

      &.button-disabled {
        @include hover {
          background-color: initial;
        }
      }
    }

    &.is-disabled {
      cursor: default;

      @include hover {
        background-color: var(--background-color-tertiary);
      }
    }
  }
}
