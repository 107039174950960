.create-new-documentation-modal {
  .modal-content {
    .create-new-monitor-modal__content {
      .step1 {
        .tabs{
          width: 400px;
        }

        .tabs-container{
          .tab-primary, &__existing-collection {
            flex: 1;
          }
        }

        &.create-new-monitor-modal__body {
          .data-editor {
            .dropdown {
              width: 100%;
              margin: 0 5px;
            }

            .dropdown-button {
              .btn {
                background-color: transparent;
                padding: 0;
              }
            }
          }
        }
      }

      .step2 {
        &.create-new-monitor-modal__body {
          flex: 1;

          .create-new-monitor__body__input {
            flex-direction: row;
          }

          .create-new-monitor__body__input-group--description {
            display: flex;
            flex-direction: column;
            flex: 1;
            max-width: initial;
            height:100%;

            .create-new-monitor__body__input {
              flex: 1;
              height: 100%;
            }
          }
        }
      }

      .step3 {
        &.create-new-monitor-modal__body {
          .create-new-monitor-modal__card__center{
            width: 640px;

            .view_documentation{
              margin-top: 8px;

              .btn-text{
                padding-left: 0px;
                height: auto;
                text-align: left;
                font-size: var(--text-size-m);
                line-height: var(--line-height-m);
                font-weight: var(--text-weight-regular);
              }
            }
          }

          .create-new-monitor-modal__content__footer {
            .create-new-monitor-modal__step {
              .create-new-monitor-modal__step__text {
                .btn-text {
                  height: initial;

                  div {
                    text-align: left;
                    white-space: pre;
                  }
                }
              }
            }
          }

        }
      }
    }
  }
}

.create-new-documentation-editor {
  border: 1px solid;
  border-radius: var(--border-radius-default);
  border-color: var(--border-color-strong)
}
