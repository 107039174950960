.new-schema-editor-container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .new-schema-editor-container__header {
    display: flex;
    flex: 0 0 37px;
    flex-direction:row;
    border-style: var(--border-style-solid);
    border-width: 0px 0px var(--border-width-default) 0px;
    border-color: var(--border-color-default);
    padding-left: 10px;

    .new-schema-editor-container__header-left {
      display: flex;
      flex: 1;
      align-items: center;

      .update-schema-type-link {
        font-size: var(--text-size-m);
        margin-right: var(--spacing-zero);
      }

      .update-schema-type-icon {
        color: var(--content-color-link);
      }
    }

    .new-schema-editor-container__button {
      height: var(--size-s);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      padding: var(--spacing-zero);
      min-width: 110px;
      color: var(--content-color-primary);
      @include icon-color(var(--content-color-primary));
    }

    .new-schema-editor-container__header-right {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }
  }

  .new-schema-editor-container__content {
    display: flex;
    flex: 1 1 0;
    min-height: 0;

    // done to prevent native scrollbar flicker while using draggable core
    overflow: hidden;

  }

  &__active-format {
    margin-left: var(--spacing-s);
    color: var(--content-color-primary);
    display: flex;
    align-items: center;
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
  }
}

.file_editor__glyph-warning {
  @include icon(
    '../assets/images/thumbnails/warning-thumbnail.svg',
    12px,
    12px
  );

  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-error {
  @include icon(
    '../assets/images/thumbnails/error.svg',
    12px,
    12px
  );

  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_error {
  @include icon(
    '../assets/images/thumbnails/security-error.svg',
    12px,
    12px
  );

  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_warn {
  @include icon(
    '../assets/images/thumbnails/security-warn.svg',
    12px,
    12px
  );

  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_info {
  @include icon(
    '../assets/images/thumbnails/security-info.svg',
    12px,
    12px
  );

  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_hint {
  @include icon(
    '../assets/images/thumbnails/security-hint.svg',
    12px,
    12px
  );

  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}
