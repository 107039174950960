@import './GreetingsShell';

.homepage-greetings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72px;
  width: 100%;
  margin-bottom: var(--spacing-xxl);

  .homepage-greeting-header {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xxxl);
    line-height: var(--line-height-xxxl);
    display: flex;
    color: var(--content-color-primary);
    word-break: break-word;
  }

  .homepage-greeting-body {
    display: flex;
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    margin-top: var(--spacing-s);
    color: var(--content-color-secondary);
  }
}
