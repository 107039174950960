.changelog-learn-more-header {
  font-family: $font-primary;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-bottom: var(--spacing-l);

  &__left-container {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .changelog-learn-more-header__icon {
    margin: 10px;
  }

  .changelog-learn-more-header__text {
    color: var(--content-color-link);
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.changelog-learn-more-header__refresh-container {
  margin-left: var(--spacing-xs);

  @-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
  }

  &.is-loading-new {
    cursor: default;
    .changelog-learn-more-header__refresh-icon {
      -webkit-animation: rotating 1s linear infinite;
    }
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.3;
  }
}

.activity-feed-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.activity-feed {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
  color: $activity-feed-font-color;
}

.activity-feed-loader {
  height: auto;
  padding: 20px;
  text-align: center;
}

.load-more-error-banner {
  font-size: 12px;
  color: $activity-feed-font-color;
  background-color: $activity-feed-error-banner-highlight-background-color;
}

.activity-feed-initial-fetch-error-container {
  padding: 20px;
  text-align: center;

  .initial-fetch-error-title {
    font-weight: var(--text-weight-medium);
    margin-bottom: 12px;
  }

  .initial-fetch-error-subtitle {
    line-height: 20px;
    margin-bottom: 24px;
  }

  .activity-feed-retry__btn {
    height: 32px;
    width: 43%;
    font-weight: 600;
    background-color: var(--background-color-tertiary);
    color: var(--content-color-secondary);

    &:hover {
      background-color: var(--highlight-background-color-tertiary);
      color: var(--content-color-primary);
    }

    &.is-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}

.activity-feed-load-more-error-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .load-more-error-info {
    display: flex;
    align-items: center;
  }

  .load-more-error-icon {
    @include icon-color(var(--base-color-error));
  }

  .load-more-error-subtitle {
    text-align: left;
    margin: 0 20px;
  }

  .activity-feed-retry__btn {
    background-color: initial;
    font-weight: 600;
    color: var(--base-color-error);

    &.is-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}

.activity-feed-date-group {
  padding-bottom: 10px;
  margin: 10px;
  font-size: 13px;
  font-weight: 600;
  color: $activity-feed-date-content-color;
  margin-left: 0;
}

.activity-feed-empty-container {
  padding: 30px 0;
  font-family: $font-primary;
  font-size: 12px;
  line-height: 18px;
  color: $activity-feed-meta-label-color;
  text-align: center;

  .activity-feed-empty-signin-link {
    color: $button--text-color;
    cursor: pointer;
  }
}

.activity-feed-context-bar {
  &__title {
    position: relative;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
    color: var(--content-color-primary);
  }
}
