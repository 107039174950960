.documentation-workbench-loader {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .documentation-workbench-body {
    position: relative;
    height: 100%;
    width: 100%;

    &--right {
      position: absolute;
    }
  }
}
