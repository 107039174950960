.mock-create-container-v2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow: auto;
  padding-bottom: 36px;
  overflow-y: overlay;

  &__loading-indicator {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .mock-form-v2 {
    margin-bottom: 20px;

    &__radio-button {
      max-width: 345px;
      padding-bottom: var(--spacing-l);

      & > span > label {
        font-weight: var(--text-weight-medium);
      }
    }

    &__footer {
      margin-left: -290px;
      gap: var(--spacing-s);
      display: flex;
    }
  }
}