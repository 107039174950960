.documentation-description {
  & > * + * {
    margin-top: var(--spacing-l);
  }

  // consistent padding of 8px to description of all entities
  > div {
    padding: 2px var(--spacing-s);
  }

  &--context-bar,
  &--workbench {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  &--workbench {
    .description-editor-wrapper {
      height: var(--max-editor-height);
    }
  }

  &--context-bar {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: auto;
    height: 100%;
    margin-right: var(--spacing-s);
    .description-editor-container {
      height: 100%;
    }
    .description-editor-wrapper {
      height: 100%;
    }
  }

  .unsaved-changes {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: var(--spacing-s);
    }

    &__message {
      font-size: var(--text-size-s);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
    }
  }
}
