.create-new-modal__templates {
  .create-new-modal__templates__template-list__load-more {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  &.api-networks {
    .api-network__label__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .api-network__icon__wrapper {
      height: 64px;
      width: 64px;
      display: flex;
      flex-direction: row;
      border-radius: 1px;

      .team-logo .team-logo--image {
        height: 64px;
        width: 64px;
        border-radius: 1px;
        object-fit: contain;
      }
    }
    .api-network__info__wrapper {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      line-height: 20px;
      margin-top: 6px;

      .create-new-modal__run__button__downloads {
        display: flex;
        margin-right: 16px;
        margin-left: 16px;
        color: var(--content-color-tertiary);

        .create-new-modal__run__button__downloads-count {
          display: flex;
          font-weight: 600;
          color: var(--content-color-secondary);
        }
      }
    }
    .create-new-modal__template-card__title {
      padding-bottom: 0px;
      margin-top: 10px;
      justify-content: center;
      color: var(--content-color-primary);
      font-size: 16px;
      line-height: 28px;
      max-width: 100%;
      text-align: center;
    }
    .create-new-modal__template-card {
      width: 260px;
      height: 182px;
      box-sizing: border-box;
      position: relative;

      .api-network__icon__wrapper {
        border-radius: 1px;
        img {
          border-radius: 1px;
        }

        .tag {
          padding: 0px 6px;
          width: 55px;
          height: 16px;
          align-items: center;
          text-align: center;
          font-weight: 600;
          font-size: 11px;
          line-height: 16px;
          overflow: unset;
          position: absolute;
          right: 0;
          margin-right: 10px;
          top: 10px;
        }
      }
    }
  }
}

.create-new-modal__template {
  .create-new-modal__flex__content {
    display: flex;
    justify-content: space-between;
  }

  &.api-network {
    height: 100%;
    align-items: center;

    .create-new-modal__template__content {
      align-self: flex-start;
    }

    .api-network__icon--collection {
      margin-right: 10px;
    }

    .create-new-modal__run__button__wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .create-new-modal__template__info {
        margin-left: unset;
        margin-top: 16px;
      }
    }

    .create-new-modal__run__button__content-title {
      display: flex;
      flex-direction: row;
    }

    .create-new-modal__apinetwork__title {
      display: flex;
      align-items: center;
      color: var(--content-color-primary);
      margin-right: 16px;
      margin-bottom: 40px;

      .api-network__icon__wrapper {
        display: flex;
        flex-direction: column;
        height: 80px;
        width: 80px;
        border-radius: 1px;

        .team-logo .team-logo--image {
          height: 80px;
          width: 80px;
          border-radius: 1px;
          object-fit: contain;
        }
      }

      .api-network__team__info {
        display: flex;
        flex-direction: column;
        margin-left: 32px;
        overflow: hidden;

        .api-network__team__name {
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 30px;
          font-size: 18px;
          font-weight: 600;

          span:nth-child(1) {
            @include text-truncate-ellipsis;
          }

          .tag {
            padding: 0px 6px;
            height: 16px;
            font-size: 11px;
            font-weight: 600;
            line-height: 16px;
            position: relative;
            margin-left: 16px;
            flex-shrink: 0;
          }
        }

        .api-network__team__description {
          display: flex;
          flex-direction: row;
          overflow: ellipsis;
          line-height: 20px;
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }

    .create-new-modal__run__button__actionables {
      display: flex;
      flex-direction: row;
    }

    .loading-indicator-wrapper {
      text-align: center;
    }

    .create-new-modal__run__button__version {
      .dropdown-button .btn {
        margin-right: 30px;
        margin-top: 24px;
      }

      .create-new-modal__run__button__version-name {
        @include text-truncate-ellipsis;
      }
    }

    .create-new-modal__run__button__content-title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .dropdown {
        width: 170px;
      }

      .dropdown-button {
        .btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .create-new-modal__run__button__downloads {
        font-size: 12px;
        color: var(--content-color-tertiary);
        overflow: hidden;
        margin-top: 8px;

        .create-new-modal__run__button__downloads-count {
          color: var(--content-color-secondary);
          font-weight: 600;
        }
      }
    }

    .create-new-modal__run__button__name {
      display: flex;
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
    }

    .create-new-modal__run__button__summary {
      margin-top: 8px;
      display: flex;
    }

    .run-in-postman {
      margin: 8px 0 4px;
      height: 32px;
      align-self: flex-start;
      flex-shrink: 0;
      justify-content: space-around;
      .arrow {
        margin-top: 2px;
      }
    }

    .api-network__loading-indicator {
      margin-top: 32px;
    }

    .environment-empty {
      color: $create-new-disabled-color;
    }
    .create-new-modal__run__buttons__label {
      font-weight: 600;
      color: $create-new-secondary-color;
    }

    .environment-name {
      color: $create-new-secondary-color;
    }
  }
}
