.custom-mock-error {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-size: var(--text-size-xxl);
    color: var(--content-color-primary);
    font-weight: var(--text-weight-bold);
    margin-bottom: 20px;
    margin-top: var(--spacing-l);
  }

  &__subtitle {
    font-size: var(--text-size-l);
    color: var(--content-color-primary);;
    margin-bottom: 20px;
    max-width: 410px;
    text-align: center;
  }

  &__action {
    .btn {
      font-size: var(--text-size-xl);
      padding: 10px 20px;
    }
  }
}