@import '../../mixins/inline-variable';

.key-value-form-editor {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: thin solid $app-border-color;
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  .key-value-form-row {
    .auto-suggest {
      @include variablesInlineBlock;
    }
  }

  &.is-bleed {
    border-top: 0;
    border-right: 0;

    .key-value-form-row {
      .auto-suggest-group > :last-child {
        border-right: 0;
      }

      & > :first-child {
        border-left: 0;
      }

      .key-value-form-row__draggable > :first-child {
        border-left: 0;
      }
    }

    .key-value-form-editor-sortable__selectable > :last-child {
      border-bottom: 0;
    }
  }
}

.key-value-cell {
  flex: 1;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;

  // @todo non-uniform spacing because of inconsistencies with design tokens
  padding: 0 3px 1px 9px;
  align-items: center;
  cursor: text;
  font-size: var(--text-size-m);
  user-select: none;
  color: var(--content-color-primary);
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;

  @include inlineVariables;
  @include variablesInlineBlock;

  &__icon {
    margin-left: 8px;
  }

  .key-value-cell__multiline__item {
    &:not(:last-child) {
      &::after {
        content: '↵';
        color: var(--content-color-tertiary);
        font-size: 9px;
        padding: 3px 3px 1px;
      }
    }
  }

  &.has-warning {
    margin-right: 24px;
  }

  &.has-error {
    margin-right: 24px;

    .key-value-cell__multiline__item {
      &:not(:last-child) {
        &::after {
          background: var(--background-color-error);
        }
      }
    }


    &.has-multiline {
      .key-value-cell__text {
        &::after {
          color: var(--color-whitespace-char);
          opacity: 1;
          background: var(--background-color-error);
        }
      }
    }
  }

  &.has-multiline {
    .key-value-cell__text {
      &::after {
        content: '↵';
        color: var(--content-color-tertiary);
        font-size: 9px;
        padding: 3px 3px 2px;
      }
    }

    &::after {
      content: '...';
      color: var(--content-color-tertiary);
    }
  }

  .key-value-cell__text {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.is-overridden {
    text-decoration: line-through;
    color: var(--content-color-tertiary);
  }
}

.key-value-cell__placeholder {
  flex: 1;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;

  // @todo non-uniform spacing because of inconsistencies with design tokens
  margin: 7px 3px 0 9px;
  align-items: center;
  cursor: text;
  font-size: 12px;
  user-select: none;
  color: var(--content-color-tertiary);
}
