.request-url-editor {
  flex: 1;
  min-width:0;
  color: var(--content-color-primary);
  background-color: var(--background-color-primary);
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:first-child {
    border: none;
    border-radius: 0px var(--border-radius-default) var(--border-radius-default) 0px;
    padding: 0px;
  }

  @include focus {
    outline: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
  }

  .auto-suggest {
    transition: all 0.2s ease-in-out;
    height: 100%;
    width: 100%;
    border: none;
    box-sizing: border-box;


    @include hover {
      background-color: var(--background-color-primary);
    }

    &.is-focused {
      background-color: var(--background-color-primary);

      > div:first-child {
        &.auto-suggest__overflown {
          border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
          border-bottom-right-radius: 4px;
          border-top: 0;
          border-right: 0;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: calc(100% - 36px);
            border-right: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
            margin-top: 36px;
            margin-right: -1px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
  .DraftEditor-root{
    min-height: 40px !important
  }

  .auto-suggest__collapsible {
    padding-right: var(--spacing-s);
    min-height: 40px
  }


  .public-DraftEditor-content, .public-DraftEditorPlaceholder-root {
    font-size: var(--text-size-m);
    line-height: 1.5;
  }
}

.request-url-editor-hard-focus {
  .auto-suggest {
    &.is-focused {

      > div:first-child {
        &.auto-suggest__overflown {
          border: 0;
          &::after {
            border-right: 0;
          }
        }
      }
    }
  }
}
.input-suggestions.request-url-editor-suggestions {
  padding: var(--spacing-s) var(--spacing-zero);
  margin-top: 10px;
}
