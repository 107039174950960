
.create-new-monitor-modal__body {
  --input-components-width: 320px;

  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding: var(--spacing-xs);

  .create-new-monitor__body__input-group {
    margin-bottom: 10px;

    .create-new-monitor__body__label {
      font-size: 12px;
      line-height: 36px;

      &.is-error {
        color: var(--base-color-error);
      }
    }

    .create-new-monitor__body__label--schedule-selector{
      margin-bottom: var(--spacing-xs);
    }

    .create-new-monitor__body__label--regions{
      @extend .create-new-monitor__body__label;

      line-height: var(--line-height-m);
      padding-top: var(--spacing-s);
    }

    .create-new-monitor__body__input {
      display: flex;

      .create-new-monitor__body__input__helptext {
        margin-top: 16px;
        padding-left: 20px;
        color: var(--content-color-tertiary);

        &--schedule-selector{
          margin-top: -1px;
        }
      }

      .create-new-monitor__body__field {
        display: flex;
        flex: 0 0 320px;
        margin-bottom: auto;
        height: 100%;

        &__dropdown{
          width: var(--input-components-width);

          &__collection-item-container-selected{
            // bound the max width as there is a overflow bug in Aether's dropdown item component for large texts
            max-width: 270px;
          }

          &__collection-item-container{
            // bound the max width as there is a overflow bug in Aether's dropdown item component for large texts
            max-width: 250px;
          }
        }
      }

      .input-checkbox {
        margin-right: 5px;
      }

      &.create-new-monitor__body__input--regions {
        margin-left: var(--spacing-xl);
      }
    }

    .create-new-monitor__body__input-error {
      color: var(--base-color-error);
      font-size: 11px;
    }

    .create-new-monitor__body__show-more {
      .advanced-section-toggle {
        margin-left: -10px;
      }

      .advanced-section {
        .advanced-section {
          margin: 10px 0;

          &__field-error {
            color: var(--base-color-error);
            font-size: 11px;
            margin: 5px 25px;
          }

          .advanced-section__checkbox {
            display: flex;
          }

          .advanced-section__field {
            display: flex;
            align-items: center;

            &__input {
              width: 300px;
              margin: 5px 10px 5px 25px;
            }

            .advanced-section__field__meta {
              margin: 5px 25px;
              font-size: 11px;
            }

            &.advanced-section__field--notifications {
              display: block;
            }

            &--notification-limit {
              .monitor-notification-limit__input{
                width: 75px;
                margin: 0 8px;
              }

              .input-error-section {
                top: 3px;
                margin-top: 0px;
                display: inline-block;
              }
            }

            &.advanced-section__field--timeout,
            &.advanced-section__field--delay {
              .advanced-section__field__input {
                width: 150px;
              }

              font-size: 11px;
            }
          }

          &__select {
            margin: 5px 10px 5px 25px;
            display: flex;
            align-items: center;

            &--retry > * {
              font-size: 11px;
              margin-right: 10px;
            }

            &__dropdown--retry{
              width: 75px;
            }
          }

          .advanced-section__checkbox__label {
            margin-left: 10px;
          }

          &__checkbox-label-helper {
            font-size: 10px;
          }
        }
      }
    }

    .create-new-monitor__body__select-group--regions {
      white-space: pre-line;
      width: fit-content;
    }

    .create-new-monitor__body__select-group--regions-manual{
      @extend .create-new-monitor__body__select-group--regions;

      margin-bottom: unset;
    }

    .create-new-monitor__body__select-group--regions__radio {
      margin-right: 5px;
    }

    .create-new-monitor__body__input--help {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      cursor: pointer;
    }

    .create-new-monitor__body__subtext {
      color: var(--content-color-secondary);
      font-size: 12px;
      line-height: 24px;

      .btn-text {
        padding: 0 3px;
      }
    }

    &.create-new-monitor__body__input-group--private {
      margin-top: 10px;
    }
  }

  .create-new-monitor__body__input-group--regions {
    .create-new-monitor__body__input {
      &.create-new-monitor__body__input-regions--auto {
        display: none;
      }

      &.create-new-monitor__body__input-regions--manual {
        display: flex;
      }

      .create-new-monitor__body__field {
        flex-wrap: wrap;
      }

      .create-new-monitor__body__input__checkbox {
        display: flex;
        width: 500px;
        color: var(--content-color-primary);
        margin-top: 8px;
      }

      .create-new-monitor__body__input__checkbox-icon {
        display: inline-block;
        padding-left: 4px;
        vertical-align: -2px;
        @include icon-color(var(--content-color-tertiary));
      }

      .create-new-monitor__body__input__checkbox-region {
        height: 18px;
      }
    }
  }
}

.preserve-context-loader {
  margin-top: 220px;
  width: 15px;
}
