$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.pull-request-edit {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .pm-h1 {
    padding-top: var(--spacing-s);
  }

  &__header-text {
    margin-top: var(--spacing-l);
    display: inline-block;
    margin-left: var(--spacing-l);
    font-size: 18px;
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
  }

  &__title {
    color: var(--content-color-primary);
  }

  &__header {
    margin-top: var(--spacing-m);
    border-bottom: 1px solid var(--border-color-default);
    padding: 0 var(--spacing-l);
    padding-bottom: var(--spacing-m);
  }

  &__body {
    margin-left: var(--spacing-l);
    margin-right: var(--spacing-l);
  }

  &__description {
    margin-top: var(--spacing-xxl);
    color: var(--content-color-primary);
  }

  &__reviewers {
    margin-top: var(--spacing-xxl);
    color: var(--content-color-primary);
  }

  &__changes_container {
    display: flex;
    align-items: flex-start;
  }

  &__changes_container > div {
    margin-right: var(--spacing-xl);
  }

  &__changes {
    width: 65%;
    margin-bottom: var(--spacing-l);
  }

  &__changes--full-width {
    width: 100%;
  }

  &__nav {
    margin-top: var(--spacing-l);
    flex: auto;
  }

  &__diff-nav-wrapper {
    border-left: var(--border-width-default) var(--border-style-solid)
      var(--border-color-default);
    padding-left: var(--spacing-l);
    color: var(--content-color-secondary);
  }

  &__form {
    max-width: 400px;
    font-size: var(--text-size-m);
    margin-top: var(--spacing-xl);

    label {
      display: block;
      font-weight: var(--text-weight-medium);
      margin-bottom: var(--spacing-s);
    }

    label + p {
      margin-top: var(--spacing-xs);
      margin-bottom: var(--spacing-s);
      color: var(--content-color-secondary);
    }

    .pm-invite-form,
    .pm-input-field {
      max-width: 400px;
    }

    textarea {
      min-width: 200px;
      max-width: 1000px;
      font-family: var(--text-family-default);
    }
  }

  &__source-destination-label {
    width: 100%;
  }

  &__max-reviewers {
    color: var(--base-color-error);
    margin-top: var(--spacing-s);
    display: block;
  }

  &__actions {
    margin-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);

    &-save {
      margin-right: var(--spacing-s);
    }
  }
}

.workspace-selector-menu {
  .dropdown-menu-item.dropdown-menu-item--default {
    border-top: var(--border-width-default) var(--border-style-solid)
      var(--border-color-strong);
  }
}
