.flowstudio {
  .edge__hovering-area {
    stroke-width: 16px;
    fill: none;
    stroke: none;
    cursor: pointer;

    &:hover + .edge__data {
      stroke-width: 3;
    }
  }

  .fs__edge {
    fill: none;
    cursor: pointer;
    // stroke-width: 1;

    &.edge__data {
      pointer-events: none;

      &.error {
        stroke: var(--content-color-error);
      }

      &.disabled {
        stroke: var(--grey-40);
      }

      &.chooser-attached {
        stroke: var(--blue-40);
        stroke-width: 3;
        stroke-dasharray: 5;
      }
    }

    &.edge__signal {
      stroke-dasharray: 5;
      stroke: var(--grey-40);

      &:hover {
        stroke-width: 3;
      }

      &.animated {
        stroke: var(--base-color-success);
      }

      &.error {
        stroke: var(--content-color-error);
      }

      &.active,
      &.disabled {
        stroke: var(--grey-40);
      }

      &.animated {
        stroke-dasharray: 5;
        animation: dashdraw 0.5s linear infinite;
      }
    }
  }

  .react-flow__edge {
    &.selected {
      .fs__edge.edge__data {
        stroke-width: 3;
        stroke: var(--blue-40);

        &.error {
          stroke: var(--base-color-error);
        }
      }

      .fs__edge.edge__signal {
        stroke: var(--content-color-success);
        stroke-width: 3;

        &.error {
          stroke: var(--base-color-error);
        }
      }
    }

    &.updating {
      .fs__edge {
        stroke: var(--grey-50);
      }
    }
  }

  .edge__connection {
    stroke: var(--grey-50);
  }
}
