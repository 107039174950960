.edit-billing-information {
  width: 70%;

  &__heading {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  &__back-button {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-l);
    font-size: var(--text-size-m);
    cursor: pointer;

    &-link {
      margin: 0px 0px 0px var(--spacing-xs);
    }
  }

  &__loader {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__action {
    display: flex;
    padding-bottom: var(--spacing-xl);

    &--save {
      margin-right: var(--spacing-m);
    }
  }
}
