.recommendations {
  font-family: $font-primary;
  color: var(--content-color-secondary);
  font-size: 12px;
  display: flex;
  flex-direction: column;

  .recommendation-item {
    position: relative;
    padding: 0 10px;
    background-color: var(--background-color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    .recommendation-banner{
      flex: 0 0 32px;
      width: 32px;
      height: 40px;
      background: url('../assets/images/thumbnails/postmanaut-helmet.png');
    }

    .recommendation-description {
      line-height: 20px;
      padding: 10px 8px;
      align-items: center;
      word-break: break-word;

      .recommendation-description-title {
        font-weight: 600;
      }
      .recommendation-action {
        display: inline-block;
        margin-left: 4px;
        font-weight: 600;
        color: var(--highlight-background-color-brand);
        cursor: pointer;
      }
    }

    .recommendation-dismiss {
      cursor: pointer;
      line-height: 20px;
      padding: 14px 8px 14px 4px;
    }

    @include hover {
      background-color: var(--background-color-tertiary);
    }
  }
}
