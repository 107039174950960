$empty-state-height: 200px; // Invite users section height

.edit-workspace-modal {
  .modal-content {
    overflow-y: auto;
    max-height: 75vh;

    .loading-indicator-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  .empty-state--no-users {
    height: $empty-state-height;
    color: var(--content-color-secondary);
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit-workspace-modal__contents {
    display: flex;
    flex-direction: column;

    .edit-workspace__input-group {
      display: flex;
      flex-direction: column;
      flex: 1;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .edit-workspace__input-group__label {
        margin-bottom: 7px;
        display: flex;
      }

      .edit-workspace__input-group__left-label {
        flex: 1
      }

      .edit-workspace__input-group__add-slots {
        padding-right: 0px;
      }

      .edit-workspace__input-group__team-size {
        color: $workspace-switcher-header-color;
      }

      .edit-workspace__input-group__input {
        display: flex;
        margin-bottom: 10px;

        .is-disabled {
          cursor: not-allowed;
        }

        .plugin__workspace-view-switcher__wrapper {
          display: flex;
          justify-content: center;
          height: 32px;

          .plugin__workspace-view-switcher__switch {
            height: 28px;
          }

          .plugin__workspace-view-active-item {
            align-self: center;
          }
        }

        .plugin__workspace-view-switcher__disable {
          opacity: 0.5;
          cursor: default;
        }

        .edit-workspace__input-group__input--type {
          flex: 1;
        }

        .textarea-wrapper {
          flex: 1;
        }

        .edit-workspace__input-group__input__edit-text-area {
          margin-bottom: 10px;
        }
      }

      .edit-workspace__input-group__subtext {
        color: $workspace-create-new-disabled-color;
      }
    }
  }

  .edit-workspace__email-privileges-denied {
    margin-bottom: 16px;
    margin-top: 8px;
    color: var(--base-color-error);
  }

  .edit-workspace__team_info {
    color: var(--content-color-tertiary);
  }

  .button-small {
    width: 100px;
  }

  .button-medium {
    width: 120px;
  }

  .button-large {
    width: 200px;
  }
}
