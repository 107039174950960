.info-button {
  display: flex;
  &.error {
    @include icon-color(var(--base-color-error));
  }

  .info-button__icon {
    display: flex;
  }
}
