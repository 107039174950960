.integration-runs-page-modal{
  height: 174px;
  .modal-footer {
    flex: 0 0 72px;
    padding: var(--spacing-l) var(--spacing-xl);
  }
  .modal-content {
    padding: var(--spacing-l) var(--spacing-xl);
  }
  .modal-header {
    .modal-header-close-button-wrapper {
      display: none;
    }
  }
}