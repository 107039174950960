.ws-outer-container {
  height: 100vh;
  overflow-y: scroll;
}

.ws-container {
  padding: var(--spacing-zero) var(--spacing-xl);
  margin: var(--spacing-zero) auto;
  max-width: 880px;

  &__spam-report {
    padding-top: var(--spacing-xl);
    border-top: 1px solid var(--border-color-default);
  }
}

.ws-settings-container {
  margin: auto;
  max-width: 440px;
}

.ws-content-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ws-content-left {
  margin-right: var(--spacing-l);
}

.ws-action-request {
  padding: var(--spacing-l) var(--spacing-zero);
  margin-bottom: var(--spacing-xl) var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  &__content {
    max-width: 600px;

    &__heading {
      margin-bottom: var(--spacing-s);

      a {
        text-decoration: none;
        color: var(--content-color-primary);

        &:hover {
          color: var(--content-color-brand);
        }
      }
    }

    &__notes {
      padding: var(--spacing-s);
      border-radius: var(--border-radius-default);
      background-color: var(--background-color-secondary);
      margin-top: var(--spacing-s);
    }

    &__actions {
      margin-top: var(--spacing-m);
    }
  }
}

// Header Styles
.ws-header {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  color: var(--content-color-primary);
  padding: var(--spacing-m) var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  min-width: 0;
  @include text-truncate-ellipsis;

  &__info {
    display: flex;
    align-items: baseline;
    flex-grow: 1;
    min-width: 0;
    @include text-truncate-ellipsis;

    &__name {
      @include text-truncate-ellipsis;
      margin-right: var(--spacing-s);
      padding: var(--spacing-zero) var(--spacing-xs);

      &__container {
        min-width: 0;
        width: 100%;
      }

      &--edit {
        &:hover {
          background-color: var(--background-color-tertiary);
          border-radius: var(--border-radius-default);
        }
      }

      &__block {
        margin: var(--spacing-zero);
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
        flex-grow: 1;
        min-width: 0;

        &__fixed {
          display: flex;

          &__text {
            margin-right: var(--spacing-s);
            @include text-truncate-ellipsis;
          }

          &__copy-icon {
            visibility: hidden;
          }

          &:hover &__copy-icon {
            visibility: visible;
          }
        }
      }

      &-edit-mode {
        display: flex;
        align-items: center;
        cursor: text;

        .inline-input__wrapper {
          width: 100%;

          .input {
            line-height: calc(var(--line-height-xxxl) - 2px); // Remove border width from height
            padding: var(--spacing-zero) calc(var(--spacing-xs) - 1px); // Remove border width from padding
          }
        }

        & .input::placeholder {
          font-size: var(--text-size-xxl) !important;
        }
      }

      &-copy-icon {
        visibility: hidden;
      }

      &-edit-mode:hover &-copy-icon {
        visibility: visible;
      }

      &:hover &-copy-icon {
        visibility: visible;
      }

      &-public-info {
        display: flex;
        align-items: flex-start;
        margin-top: var(--spacing-s);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
        color: var(--content-color-warning);
        white-space: break-spaces;

        &-icon {
          margin-right: var(--spacing-xs);
          margin-top: var(--spacing-xs);
        }
      }
    }

    &__visibility {
      &__icon {
        margin-right: var(--spacing-xs);
        background: var(--background-color-secondary);
        border-radius: var(--border-radius-default);
        width: var(--size-s);
        height: var(--size-s);
      }

      &-block {
        margin-right: var(--spacing-s);
      }
    }
  }


  &-info-action-icons {
    display: flex;
    margin-left: var(--spacing-m);
    align-items: center;


    &-container {
      &__watch-btn {
        button {
          color: var(--content-color-secondary);
          background-color: unset;
          min-width: unset;

          &:hover {
            color: var(--content-color-primary);
          }

          &:first-of-type {
            border-right-color: var(--border-color-default);
          }
        }
      }

      &__settings-btn {
        margin: var(--spacing-zero) var(--spacing-xs);
      }
    }
  }

  &-actions-delete {
    color: var(--content-color-error);
  }
  &-actions-delete:hover {
    color: unset;
  }

  &-block {
    &-icon {
      @include icon-color(var(--content-color-secondary));
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 280px;

      &__creator {
        margin-bottom: var(--spacing-l);
      }

      &__updated {
        margin-bottom: var(--spacing-l);
      }

      &__ws-id {
        border: none !important;
        box-shadow: none;
        background-color: var(--background-color-secondary);

        input {
          color: transparent;
          text-shadow: 0 0 0 var(--content-color-primary);
        }

        &:hover {
          border: none;
          background-color: var(--background-color-secondary);
        }
      }

      &__copy {
        color: var(--content-color-secondary);

        &--copied {
          &:hover {
            i svg {
              color: var(--base-color-success);
            }
          }

          i svg {
            color: var(--base-color-success);
          }
        }
      }
    }
  }

  &-dropdown {
    & .dropdown-menu {
      border-radius: var(--border-radius-default);
      background: var(--background-color-primary);
      box-shadow: var(--shadow-default);
      padding: var(--spacing-l);
    }
    &-icon {
      @include icon-color(var(--content-color-primary));
    }
  }

  &-actions-disable {
    opacity: 0.4;
    pointer-events: none;
  }
}

// Meta Styles
.ws-meta {
  overflow-y: visible;
  overflow-x: hidden;

  &-summary {
    word-break: break-all;

    &-text {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-primary);
      word-break: break-word;
      padding: var(--spacing-xs);
      width: 100%;

      &:hover {
        background-color: var(--background-color-tertiary);
        border-radius: var(--border-radius-default);
      }

      &-empty {
        color: var(--content-color-tertiary);
      }
    }

    &-uneditable-empty:hover {
      cursor: text;
    }

    &-edit-mode {
      display: flex;
      font-size: var(--text-size-m);
      cursor: text;

      .inline-input__wrapper {
        width: 100%;
      }

      &-description {
        display: flex;
        align-items: center;
        margin-top: var(--spacing-xs);

        &-left {
          display: flex;
          align-items: center;
          font-size: var(--text-size-m);
          color: var(--content-color-warning);
        }
      }

      &-word-count {
        margin-left: auto;
        font-size: var(--text-size-s);
        line-height: var(--line-height-s);
        color: var(--content-color-secondary);
      }
    }

    &-edit-icon {
      margin-left: var(--spacing-l);
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      visibility: hidden;
      @include icon-color(var(--content-color-secondary));
    }

    &-edit-mode:hover &-edit-icon {
      visibility: visible;
    }
  }

  &-description {
    font-size: var(--text-size-m);
    cursor: default;
    word-break: break-word;

    &-label {
      margin-bottom: var(--spacing-s);
    }

    &-md {
      padding: var(--spacing-s);
      box-sizing: border-box;
      border: var(--border-width-default) var(--border-style-solid) transparent;
      &:hover {
        background-color: var(--background-color-tertiary);
        border-radius: var(--border-radius-default);
      }

      &__uneditable {
        padding: var(--spacing-xs) var(--spacing-zero);

        &__empty {
          color: var(--content-color-tertiary);
        }
      }

      &__uneditable:hover {
        cursor: text;
        background-color: unset;
        border-radius: unset;
      }
    }

    &-edit-mode {
      display: flex;
      cursor: text;
      width: 100%;

      &-text {
        width: 100%;

        &-placeholder {
          box-sizing: border-box;
          padding: var(--spacing-xs);
          width: 100%;

          &:hover {
            background-color: var(--background-color-tertiary);
            border-radius: var(--border-radius-default);
          }
        }
      }

      &-wrapper {
        width: 100%;
      }
    }

    &-edit-icon {
      margin-left: var(--spacing-l);
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      visibility: hidden;
      @include icon-color(var(--content-color-secondary));
    }

    &-edit-mode:hover &-edit-icon {
      visibility: visible;
    }
  }
}

.ws-statistics-scratchpad {
  width: 50%;
}

// Statistic Styles
.ws-statistics {
  padding-right: var(--spacing-l);
  &-header {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);

    color: var(--content-color-primary);
    margin-bottom: var(--spacing-l);
  }

  &-block {
    display: flex;
    justify-content: space-between;
  }

  &-value {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-primary);
    margin: var(--spacing-zero);
  }

  &-label {
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    color: var(--content-color-tertiary);
    margin: var(--spacing-zero);
  }

  &-item {
    cursor: pointer;
    &:hover .ws-statistics-label {
      color: var(--content-color-primary);
    }
  }
}

// Activity Styles
.ws-activity {
  width: 100%;
  & .requester-team-overview {
    margin: unset;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--spacing-s);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    &__refresh-btn {
      height: unset;
      padding: var(--spacing-s);

      i,
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  & .select-dropdown-container .select-dropdown-btn {
    display: flex;
    justify-content: space-between;
  }

  & .workspace-activity-feed-filter-container {
    padding: var(--spacing-zero);
  }

  & .workspace-activity-feed {
    padding: var(--spacing-zero);
  }

  & .loading-indicator-wrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-xl);
  }

  & .btn-primary {
    min-width: 72px;
  }

  & .workspace-activity-feed-refresh {
    display: flex;
    align-items: center;
  }

  & .workspace-activity-item-heading {
    color: var(--content-color-secondary);
  }

  & .workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading a {
    color: var(--content-color-primary);
    font-weight: 400;
    word-break: break-word;
  }
  & .workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading a:hover {
    color: var(--content-color-link);
  }
}

// Get Started Styles
.ws-getstarted {
  font-size: var(--text-size-m);

  & .create-new-entity-section {
    max-width: max-content;
  }

  &-header {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
    color: var(--content-color-primary);
  }
}

// Members Styles
.ws-members {
  font-size: var(--text-size-m);

  &-header {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
    margin: var(--spacing-l) 0 var(--spacing-l) 0;
    color: var(--content-color-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-empty-text {
    color: var(--content-color-tertiary);
  }

  &-user-row {
    display: flex;
    align-items: center;
    font-size: var(--text-size-m);
    margin-bottom: var(--spacing-l);
    line-height: var(--line-height-m);

    &-block {
      display: flex;
      align-items: center;
    }

    & a {
      text-decoration: none;
      color: var(--content-color-primary);
      @include clickable;
      max-width: 70%;
    }

    & a:hover {
      color: var(--content-color-link);
    }

    &-name {
      margin-left: var(--spacing-s);
      @include text-truncate-ellipsis;

      &-anon {
        color: var(--content-color-secondary);
      }
    }

    &-role {
      margin-left: auto;
      text-transform: capitalize;
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
      margin-right: var(--spacing-s);
    }
  }

  &-anon-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: var(--size-m);
    height: var(--size-m);
    background-color: var(--background-color-tertiary);
  }

  &-toggle-cta label {
    color: var(--content-color-secondary);
    user-select: none;
    @include clickable;
  }
}

// Guest Introduction Styles
.ws-guest-intro {
  padding-bottom: var(--spacing-xl);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  &__heading {
    margin-bottom: var(--spacing-s);
  }
}

// Error Styles
.ws-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-thumbnail {
    margin-bottom: var(--spacing-l);
  }

  & .error-card-container__icon {
    display: none;
  }

  & .error-card-container {
    justify-content: unset;
    height: unset;
  }
}

// Back to top styles
.ws-backtop {
  display: flex;
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 100%;
  &-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 var(--spacing-l) 0 var(--spacing-s);

    &-icon {
      margin-right: var(--spacing-s);
    }
  }
}

// Pubisher info styles
.ws-publisher-info {
  &-header {
    margin-bottom: var(--spacing-s);
  }

  &-content {
    display: flex;
    align-items: center;
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    @include clickable;

    span {
      display: inline;
    }
  }

  &-content-text {
    margin-left: var(--spacing-s);
    display: inline-block;

    .verified-icon {
      margin-left: var(--spacing-xs);
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: var(--content-color-primary);
    }
  }

  &-img {
    width: var(--size-s);
    height: var(--size-s);
    object-fit: contain;
  }
}

.ws-join-btn {
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-l);
}
