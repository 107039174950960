.certificate-list-wrapper {
  border-top: 1px solid var(--border-color-default);
  padding-top: var(--spacing-l);

  .certificate-list-wrapper__heading {
    display: flex;
    justify-content: space-between;
  }

  &-desc {
    margin-top: var(--spacing-xs);

    & > .learn-more-button {
      margin-top: var(--spacing-s);
    }
  }


  .certificate-list {
    flex: 1;
    margin-top: var(--spacing-l);
  }
}

.certificate-list-wrapper__heading {
  display: flex;
  align-items: center;

  .certificate-list-wrapper__heading__title {
    flex: 1;
  }
}

.certificate-list-item {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-xs) var(--spacing-zero);
}

.certificate-list-item__row {
  display: flex;

  .certificate-list-item__key {
    display: flex;
    align-items: center;
    flex: 0 0 80px;

    .warning-button {
      margin: 0 0 2px 5px;
    }
  }

  .certificate-list-item__value {
    flex: 1;
    @include text-truncate-ellipsis;
  }

  .certificate-list-item__controls {
    flex: 0 0 auto;
    @include clickable;
    color: var(--content-color-error);
  }
}

.certificate-list-item__value-password {
  display: inline-block;

  input {
    padding-right: var(--spacing-xxl);
    height: var(--size-s);
    width: auto;
  }

  .password-input__toggle i {
    padding: var(--spacing-xs);
  }
}
