.interceptor-capture-cookies-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-flow: row;
  align-items: center;
  .info-button {
    display: inline-flex;
    margin-left: 5px;
  }
}

.interceptor-settings-domain-label {
  padding-top: 16px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.interceptor-domains-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.interceptor-cookie-sync-domain-list {
  background: var(--background-color-primary);
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid var(--border-color-default);
  height: auto;
  margin-top: 16px;
  display: flex;
  align-items: stretch;
  background-color: var(--highlight-background-color-primary);
  width: 100%;
}

.interceptor-cookie-sync-domain-list > table {
  width: 100px;
  margin: 10px;
  text-align: left;
}

.interceptor-remove-domain-btn {
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-end;
  justify-content: flex-end;
}

.interceptor-capture-cookies {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  .interceptor-capture-cookies-menu {
    flex: 0 0 40px;
    box-sizing: border-box;
    color: $app-generic-text-color;
    font-family: $font-primary;
    font-size: 12px;
    padding: 0 10px;
  }

  .interceptor-capture-cookies-list {
    flex: 1;
  }
}

.interceptor-capture-cookies-menu {

  display: flex;
  flex-direction: row;

  .interceptor-capture-cookies-menu__left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .interceptor-capture-cookies-menu__right {
    flex: 0 0 auto;
    align-items: center;
    display: flex;
    .interceptor-capture-requests-menu--interceptor-connected-label {
      color: var(--content-color-secondary);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

  }

  .interceptor-capture-cookies-menu-toggle {
    color: var(--content-color-secondary);
    margin-right: 5px;
  }

  .interceptor-capture-cookies-menu--interceptor-connected-label {
    color: var(--content-color-secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.interceptor-capture-cookies-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.interceptor-capture-cookies {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  .interceptor-capture-cookies-menu {
    flex: 0 0 40px;
    box-sizing: border-box;
    color: $app-generic-text-color;
    font-family: $font-primary;
    font-size: 12px;
    padding: 0 10px;
  }

  .interceptor-capture-cookies-list {
    flex: 1;
  }
}

.interceptor-capture-cookies-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.interceptor-capture-cookies {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  .interceptor-capture-cookies-menu {
    flex: 0 0 40px;
    box-sizing: border-box;
    color: $app-generic-text-color;
    font-family: $font-primary;
    font-size: 12px;
    padding: 0 10px;
  }

  .interceptor-capture-cookies-list {
    flex: 1;
  }
}

.interceptor-capture-cookies-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.interceptor-settings-add-domain {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
}

.interceptor-settings-add-domain-label {
  flex: 0 0 150px;
  padding-right: 16px;
}

.interceptor-settings-domain-input {
  flex: 1;
  margin-right: 10px;
}

.interceptor-domain-container {
  td {
    &:first-child{
      flex-grow: 16;
    }
    &:last-child {
      flex-grow: 1;
      margin-left: 10px;
    }
  }
}

.interceptor-domain-list-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 250px;

  .interceptor-domain-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 8px 0px;
    border: 1px solid var(--border-color-default);
    overflow: auto;
    background-color: var(--highlight-background-color-primary);
  }

  .interceptor-domain-list-item {
    display: flex;

    flex-direction: row;
    padding: 2px 8px;
    border-radius: 2px;
    align-items: center;
    height: 24px;

  .interceptor-domain-list-item-label {
    flex: 2;
    @include unselectable;
  }
  .interceptor-domain-list-item-value {
    flex: 0 0 70px;
  display: flex;
  justify-content: flex-end;
  .intereceptor-remove-domain-btn{
    cursor: pointer;
  }
  }
}
}

.interceptor-bridge-connected-dot {
  width: 8px;
  height: 8px;
  background-color: var(--base-color-success);
  border-radius: 50%;
  margin: 0 4px;
}

.interceptor-bridge-disconnected-dot {
  width: 8px;
  height: 8px;
  background-color: var(--content-color-secondary);
  border-radius: 50%;
  margin: 0 4px;
}

.interceptor-connection-indicator {
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-color: $request-editor-tabs-meta-color;
  border-radius: 8px;
  .connected {
    background-color: $request-editor-tabs-meta-color;
  }
  .disconnected {
    background-color: var(--content-color-secondary);
  }
}

.capture-settings-tabs{
  border-bottom: 1px solid var(--border-color-default);
} 

.interceptor-settings-connection-wrapper {
  padding: 0px;
}

.interceptor-settings-connection-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.interceptor-target-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.interceptor-capture-requests-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.interceptor-capture-requests-menu {

  display: flex;
  flex-direction: row;

  .interceptor-capture-requests-menu__left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .interceptor-capture-requests-menu__right {
    flex: 0 0 auto;
    align-items: center;
    display: flex
  }

  .interceptor-capture-requests-toggle-label {
    color: var(--content-color-secondary);
    margin-right: 5px;
  }

  .interceptor-capture-requests-menu--interceptor-connected-label {
    color: var(--content-color-secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.interceptor-capture-requests-banner {
  padding-bottom: var(--spacing-m);
}

.interceptor_capture_cookies_learn_more {
  padding: 0px;
  .external-link {
    padding-left: 2px;
    margin: 0px;
    color: var(--content-color-link);
    cursor: pointer;
  }
}
