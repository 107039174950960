.collection-fork-label {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
  color: var(--content-color-secondary);

  span {
    @include text-truncate-ellipsis;
    font-size: 12px;
  }

  .pm-icon {
    margin-right: 4px;
  }
}

.collection-fork-label-tooltip {

  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);

  .pm-icon {
    margin-right: 4px;
    margin-top: 4px;
  }

  .collection-fork-label-tooltip__header {
    display: flex;
  }

  .collection-fork-label-tooltip__description {
    margin-top: 8px;
  }

  .collection-fork-label-tooltip__bold-text {
    font-weight: var(--text-weight-medium);
  }
}
