@import './styles/team-integrations-common';
@import './styles/team-integrations';
@import './styles/no-search-results';
@import './styles/no-team-integrations';
@import './styles/team-integrations-in-detail';
@import './styles/error-state';
@import './styles/loading-team-integrations';
@import './styles/loading-team-integrations-in-detail';
@import './styles/offline-state-integrations';
@import './styles/browse-integrations';
@import './styles/request-integration';
@import './styles/loading-available-integrations';
@import './styles/breadcrumbs';
@import './styles/service-details-overview';
@import './styles/service-details';
@import './styles/integration-types-per-service';
@import './styles/loading-service-details';
@import './styles/add/service-setup';
@import './styles/add/integrations-add';
@import './styles/ConnectedAccount/connected-account-team-settings-page';
@import './styles/edit/integrations-edit';
@import './styles/ConnectedAccount/create-installed-app';
@import './styles/edit/api-version';
@import './styles/runs/integrations-run-listing';
@import './styles/runs/integration-actions';
@import './styles/runs/integration-run-breadcrumbs';
@import './styles/_pm-select';
@import './styles/no-edit-integration';
@import './styles/integration-modal';

.integrations-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
  height: 100%;
  color: var(--content-color-primary);
  flex: 1;
  overflow-y: scroll;
  padding: var(--spacing-xxl) var(--spacing-xxl);
}
