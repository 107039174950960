/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.highlighted-code {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__code-container {
    padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
    margin: var(--spacing-zero);
    color: var(--content-color-secondary);
    max-height: var(--container-max-height);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    position: relative;
    flex: 1;
    overflow: auto;

    code {
      font-family: var(--text-family-code);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      margin: var(--spacing-zero);
      padding: var(--spacing-zero);
    }

    &--word-wrapped {
      code {
        overflow-wrap: break-word;
        white-space: pre-wrap;

        .syntax-highlighted-code {
          white-space: pre-wrap;
        }
      }
    }
  }

  &__config-container {
    display: flex;
    align-items: center;
    padding: var(--spacing-s);

    & > * + * {
      margin-left: var(--spacing-s);
    }
  }

  &__config-button {
    border: var(--border-width-default) var(--border-style-solid) transparent;

    &--word-wrapped {
      background: var(--highlight-background-color-primary);
      border-color: var(--border-color-strong);
    }
  }

  &__language-label {
    background-color: var(--background-color-tertiary);
    color: var(--content-color-secondary);
    padding: var(--spacing-s) var(--spacing-m);
    border-radius: var(--border-radius-default);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
  }

  &__spacer {
    flex: 1;
  }

  &__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--size-m);
    border-radius: var(--spacing-zero) var(--spacing-zero) var(--border-radius-s) var(--border-radius-s);
    background: linear-gradient(180deg, rgba(189, 195, 199, 0) 0%, var(--grey-90) 100%, var(--grey-40) 100%);
    opacity: 0.1;
    mix-blend-mode: multiply;
    pointer-events: all;
  }

  &__expand-button {
    position: absolute;
    bottom: var(--spacing-s);
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--background-color-primary);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    color: var(--content-color-primary);
    padding: var(--spacing-xs) var(--spacing-m);
    font-size: var(--text-size-m);
    pointer-events: all;
    font-family: var(--text-family-default);

    // Right now we don't have a design token for border radius to make button edges rounded
    // border-radius-max makes the border radius 100% which not what we want, so using a large number
    // works in the interim.
    border-radius: 999px;

    &:focus {
      outline: none;
    }
  }

  &--documentation {
    --container-max-height: 200px;
  }

  &--documentation &__code-container {
    overflow: hidden;
  }
}


.code-renderer {
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__code-container {
    padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
    margin: var(--spacing-zero);
    color: var(--content-color-secondary);
    max-height: var(--container-max-height);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    position: relative;
    flex: 1;
    overflow: hidden;

    &.is-clipped{
      overflow: hidden;
    }


    code {
      font-family: var(--text-family-code);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      margin: var(--spacing-zero);
      padding: var(--spacing-zero);
      background-color: transparent;
      border-width: 0;
      border-radius: 0;
    }

    &--word-wrapped {
      code {
        overflow-wrap: break-word;
        white-space: pre-wrap;

        .syntax-highlighted-code {
          white-space: pre-wrap;
        }
      }
    }
  }

}
