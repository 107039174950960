$notification-feed--primary-background-color: var(--base-color-brand);
$notification-feed-background-color: var(--background-color-secondary);
$notification-feed--unread-background-color: var(--background-color-primary);
$notification-feed-border-color: var(--border-color-default);
$notification-feed-body-color: var(--content-color-primary);

.notification-container {
  position: relative;
  display: inline-block;
}

.notification-tabs {
  padding: 0px !important;

  > div:first-child{
    padding-left: 12px;
    margin-bottom: 5px;
    border-bottom: 1px solid $notification-feed-border-color;
  }
}

.notification-tab-list {
  li {
    padding-right: 4px;
  }
}


.notification-feed {
  position: absolute;
  top: 100%;
  right: 0;

  &.responsive-for-windows-app {
    @media screen and (max-width: 666px) {
      position: fixed;
      top: inherit;
      left: 14px;
    }
  }

  &.is-hidden {
    display: none;
  }

  .notification-panel-list-error-item__text{
    color: var(--content-color-secondary);
    text-align: center;
    padding: var(--spacing-xl) 0;
  }

  .notification-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .notification-panel-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: var(--text-size-m);

    .notification-panel-list-item {
      display: flex;
      height: auto;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;

      .notification-panel-list-item__body {
        display: flex;
        align-items: center;

        .notification-panel-list-item__content {
          flex: 1;
          overflow: hidden;
        }
      }
    }

    .loading-indicator-wrapper {
      display: flex;
      justify-content: center;
    }

    .notification-panel-list-error-item__text {
      color: var(--content-color-secondary);
      text-align: center;
      padding: var(--spacing-xl) 0;
    }
  }
}

.notification-container {
  border-radius: var(--border-radius-default);
}

.notifications-button-icon--unread {
  &:after {
    content: '';
    background-color: $notification-feed--primary-background-color;
    position: absolute;
    border-radius: var(--border-radius-max);
    top: 4px;
    left: 22px;
    height: 7px;
    width: 7px;
  }
}

.notifications-button-icon-badge {
  background: $notification-feed--primary-background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: -2px;
  width: 18px;
  height: 18px;
  font-weight: 400;
  font-size: 9px;
  border-radius: 50%;
}

.notification-feed {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-default);
  width: 406px;
  margin-top: 3px;
  border-radius: var(--border-radius-default);
  margin-bottom: 10px;

  .loading-indicator-wrapper {
    padding: 20px;
  }

  .notification-panel {
    max-height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
  }


  .notification-panel-header__actions {
    display: flex;
  }

  .notification-panel-header {
    padding: 10px;
    border-bottom: 1px solid $notification-feed-border-color;

    .notification-panel-header__title {
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      color: var(--content-color-primary);
    }

    .notification-panel-header__mark-all-read {
      color: var(--content-color-link);
      font-size: 12px;
      margin-right: 8px;
      line-height: 20px;
      @include clickable;
    }

    .btn {
      font-size: 11px;
    }
  }

  .notification-panel-list-empty-item {
    font-size: 12px;
    color: $notification-feed-body-color;
    text-align: center;
  }

  .notification-panel-list-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 340px;
  }

  .notification-panel-list-empty-text-primary {
    font-size: var(--text-size-l);
    margin: var(--spacing-l) 0 var(--spacing-xs);
    font-weight: var(--text-weight-bold);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
  }

  .notification-panel-list-empty-text-secondary {
    font-size: var(--text-size-m);
    margin: var(--spacing-xs) 0;
    color: var(--content-color-secondary);
  }

  .btn {
    padding-left: 0;
  }

  .signin-btn {
    padding: 0;
    margin-right: 3px;
    min-width: initial;
  }

  .notification-panel-list {
    width: 100%;
    padding-bottom: var(--spacing-xl) 0;
    font-size: var(--text-size-m);
  }

  .notification-category-icon {
    width: 20px;
    height: 20px;
    background-color: var(--background-color-secondary);
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 24px;
    top: 20px;
    align-items: center;
    justify-content: center;
    @include icon-color(var(--content-color-brand));
  }

  .notification-action-button {
    font-weight: 600;
    margin-top: 8px;
    height: 32px;
    padding: 8px 12px;
  }

  .notification-status {
    display: flex;
    justify-content: center;
    align-self: baseline;
    margin-left: 5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--background-color-brand);
    @include clickable;
  }

  .notification-status-unread {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    align-self: center;
    background-color: var(--base-color-brand);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .notification-panel-list-item {
    border-bottom: 1px solid $notification-feed-border-color;
    font-size: 12px;
    padding: 10px;

    &.are-tabs-enabled {padding: 10px 0}

    &.is-unread {
      background-color: $notification-feed--unread-background-color;
    }

    .notification-panel-list-item__body {
      color: $notification-feed-body-color;
      line-height: 16px;

      .notification-panel-list-item__icon {
        align-self: flex-start;
        padding: 0 15px 0 5px;
        position: relative;
        min-width: 32px;
      }

      .notification-item-custom-base {
        font-size: 11px;
        color: var(--content-color-secondary);

        p {
          display: block;
          margin: 0;
        }

        a {
          color: $notification-feed-body-color;
          text-decoration: none;
          word-break: break-word;
        }
      }

      .markdown-notification-read {
        strong {
          font-weight: 600;
          color: var(--content-color-secondary);
        }
        p {
          color: var(--content-color-secondary);
        }
      }

      .markdown-notification-unread {
        strong {
          font-weight: var(--text-weight-regular);
          color: var(--content-color-primary);
          &:hover {
            color: var(--content-color-link);
            text-decoration: underline;
          }
        }
        p {
          color: var(--content-color-secondary);
        }
      }

      .notification-panel-list-item__content {
        .notification-panel-list-item__content__text-markdown {
          p {
            display: block;
            margin: 0;
          }

          a {
            color: $notification-feed-body-color;
            text-decoration: none;
            word-break: break-word;
          }
        }
      }

      .notification-panel-list-item__content__text {
        height: 32px;
        overflow: hidden;
      }
    }

    .notification-panel-list-item__content__meta {
      color: var(--content-color-tertiary);
      font-size: 11px;
      margin: 4px 0;
    }
  }

  .notification-panel-list-end {
    font-size: 12px;
    color: $notification-feed-body-color;
    text-align: center;
    padding: 20px;
  }
}
