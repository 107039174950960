.custom-domains {

  &--delete-container {
    display: flex;
    justify-content: flex-end;
    padding-right: var(--spacing-s) !important;
    &--delete-button {
      cursor: pointer;
      visibility: hidden;
      position: relative;
      top: 3px;
    }
  }

  .custom-domains-table {
    &--status-column {
      display: flex;
      align-items: center;
    }

    &--badge {
      margin-left: var(--spacing-zero);
    }
  }

  .info-button {
    display: inline-block;
  }

  .entity-learn-more-link {
    color: var(--content-color-secondary);
    cursor: pointer;

    &:hover {
      color: var(--content-color-link);
    }
  }
}

.custom-domains-detail {
  .record-addition-heading {
    margin-bottom: var(--spacing-s);
  }

  .verification-records-heading {
    margin-bottom: var(--spacing-l);
    margin-top: -8px;
  }

  .txt-record-heading {
    margin-bottom: -16px;
  }

  .custom-domains-copy-button-cname {
    position: relative;
    top: 40px;
  }

  .custom-domains-copy-button-txt {
    position: relative;
    top: 65px;
  }

}

.delete-custom-domain-modal-content {
  font-size: var(--text-size-m);
}
