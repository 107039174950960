.deploy-tab-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.deploy-form-container {
  &__segment{
    margin-top: var(--spacing-l);
  }
  &__actions-segment {
    margin-top: var(--spacing-xl);
  }
  &__description {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-xl);
  }
}

.observability-dropdown-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: var(--background-color-primary);
  padding: var(--spacing-s) var(--spacing-m);

  &__name{
    margin-left: var(--spacing-s);
  }
}
.observability-dropdown-option:hover {
  background-color: var(--background-color-tertiary);
}
.gateway-options-release-view-container {
  min-height: var(--size-m);
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__connect-gateway-heading{
    padding: var(--spacing-m);
  }

  &__connected-gateway-button{
    max-width: 226px;
  }

  &__connected-menu {
    width: 223px;
    &__info{
      margin-bottom: var(--spacing-s);
    }
    &__deploy-button {
      width: 100%;
      height: var(--size-m);
      display: flex;
      align-items: center;
      padding-left: var(--spacing-xs);
      cursor: pointer;
      border-radius: var(--border-radius-default);
      background-color: var(--background-color-primary);
    }
    &__deploy-button:hover{
      background-color: var(--background-color-tertiary);
    }
  }
}

.deployments-overview-container {
  margin-top: var(--spacing-xxl) ;
  &__header{
    margin-bottom: var(--spacing-m);
  }
  &__content {
    &__empty-text {
      margin-left: var(--spacing-s);
    }
    &__table {
      tr td,th {
        padding: var(--spacing-m) var(--spacing-s);
      }
    }
  }
}

.postman-source-item-container {
  display: flex;
  &__icon {
    margin-right: var(--spacing-xs);
  }
}

.aws-cloudwatch {
  &__container {
    &__main {
      height: 100%;
      overflow-y: scroll;
    }

    height: auto;
    min-height: 286px;
    width: 50%;
    min-width: 412px;
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
    padding: var(--spacing-l);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-radius: var(--border-radius-default);

    .metrics {
      &__graph {
        margin: var(--spacing-l);
      }
      &__title {
        height: 20px;
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-l);
        line-height: var(--line-height-m);
        color: var(--content-color-secondary);
        text-align: center;
      }
    }

    &__empty {
      width: 50%;
      border: none;
      padding: var(--spacing-l);
    }
  }

  &__detailed-view {
    margin: var(--spacing-xl);
    margin-top: 64px;
  }

  &__tooltip {
    cursor: pointer;
  }

  &__checkbox {
    margin-top: var(--spacing-l);
  }
}

.observability-aws-cloudwatch-container {
  &__header{
    border-bottom: var(--border-style-solid) var(--border-width-default);
    border-color: var(--border-color-strong);
    padding-right: var(--spacing-l);
    padding-top: var(--spacing-s);
    padding-bottom: var(--spacing-s);
    position: fixed;
    width: 100%;
    background: var(--background-color-primary);
    &__back {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: var(--spacing-s);
    }
    &__stages {
      width: 178px;
      margin-left: var(--spacing-xs);
    }
    &__refresh {
      padding-right: var(--spacing-xxl);
    }
  }
}
