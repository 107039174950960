.homepage-team-activity-feed {
  position: relative;
  margin-bottom: var(--spacing-xxl);
  flex: 2;

  .loading-indicator-wrapper {
    text-align: center;
  }

  .workspace-activity-feed-date-group-wrapper {
    .workspace-activity-item-wrapper {
      font-size: 12px;

      a {
        word-break: break-word;
      }
    }
  }

  &-end {
    color: var(--content-color-secondary);
    text-align: center;
    margin: var(--spacing-l) 0;
  }

  &-title {
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
  }

  &-items {
    list-style: none;
    padding: 0;
    margin: var(--spacing-l) 0 0 0;
    position: relative;

    & > * + * {
      margin-top: var(--spacing-xxl);
    }

    &::before {
      position: absolute;
      content: "";
      height: 100%;
      border-left: 1px solid var(--highlight-background-color-primary);
      left: var(--spacing-l);
      top: var(--spacing-xxl);
      z-index: -1;
    }
  }

  &-item {
    display: flex;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);

    &-details-container {
      margin-left: var(--spacing-l);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      a {
        text-decoration: none;
        color: var(--content-color-link);
        font-weight: var(--text-weight-regular);
        word-break: break-word;
      }

      p {
        margin-top: 0;
        margin-bottom: 4px;

        strong {
          font-weight: 600;
        }
      }
    }
    &__description {
      margin-top: 0;
      margin-bottom: 0;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      max-width: 272px;
      overflow-wrap: break-word;
    }
    &__time {
      margin-top: var(--spacing-xs);
      color: var(--content-color-secondary);
      margin-bottom: 0;
    }
    .homepage-team-activity-feed-item-entity-link,
    .pm-link {
      @include clickable;
      color: var(--content-color-primary);
      &:hover {
        color: var(--content-color-link);
        text-decoration: none;
      }
    }
  }

  .workspace-activity-item-heading {
    word-break: break-word;
  }
}
