.collection-info-cb {
  &__pull-requests {
    &-label {
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      cursor: pointer;
    }

    &-icon {
      margin-right: var(--spacing-s);
    }

    &__toggle-wrapper {
      float: left;
      cursor: pointer;
    }

    &-loading {
      display: flex;
      justify-content: center;
      position: relative;
      top: 40%;
    }

    &-title {
      color: var(--content-color-primary);
      font-size: var(--text-size-m);

      &:hover {
        text-decoration: underline;
      }
    }

    &-time {
      color: var(--content-color-tertiary);
      font-size: var(--text-size-s);
      margin-top: 0;
    }

    &-elements {
      max-width: 50%;
      display: inline-block;
      cursor: pointer;
    }

    &-status {
      margin-left: var(--spacing-xxl);

      &__wrapper {
        margin-top: var(--spacing-xs);
        display: inline-block;
        width: 75px;
      }
    }

    &-meta {
      display: flex;
      float: right;
      right: 60px;
      position: relative;
      margin-top: var(--spacing-s);
    }

    &-collapsible {
      margin-top: var(--spacing-l);
      cursor: pointer;
      border-radius: var(--border-radius-s);
      max-width: 350px;
    }

    &-toggle {
      display: inline-block;
      margin-left: 0;
      margin-top: var(--spacing-xs);
    }

    &-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-x: hidden;
      padding: var(--spacing-zero) var(--spacing-s);
    }

    &-description {
      color: var(--content-color-secondary);
      font-size: var(--text-size-m);
      width: 80%;
    }
  }
}

.pull-request-container {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  height: 100%;
  overflow-x: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-s);

    &-title {
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-xl);
      line-height: var(--line-height-xl);
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .dropdown-button.is-open {
      .btn-icon.btn-icon-rect.pull-request-container__actions-filter-button {
        background-color: var(--highlight-background-color-tertiary);
      }
    }

    .btn-icon.btn-icon-rect.pull-request-container__actions-filter-button {
      height: var(--size-s);
      width: var(--size-s);
      background-color: var(--background-color-tertiary);
    }

    .btn-icon.btn-icon-rect.pull-request-container__actions-filter-button:hover {
      background-color: var(--highlight-background-color-tertiary);
    }

    &-filter {
      &-menu {
        height: 182px;
        width: 188px;
        background-color: var(--background-color-primary);
      }

      &--action-header {
        margin-left: var(--spacing-l);
        margin-top: var(--spacing-s);
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
      }

      &--action-item {
        margin-top: var(--spacing-m);
        margin-left: var(--spacing-l);

        &-name {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-primary);
          margin-left: var(--spacing-s);
        }
      }
    }
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 25%;
    margin-right: var(--spacing-xxl);
  }
}

.pull-request-filter-dropdown-item-checkbox {
  display: inline-flex;
}

.pull-request-filter-dropdown-item-checkbox.is-selected {
  background-color: var(--base-color-brand);
}

@container (inline-size < 300px) {
  .pullRequest-list-item__info.pullRequest-list-item__info {
    align-items: flex-start;
    flex-direction: column;

    .seperator.seperator {
      display: none;
    }
  }
}
