.homepage-recent-workspaces {
  margin-bottom: var(--spacing-xxl);
  
  .recent-workspaces-list {
    width: 100%;
    }

  .recent-workspaces-list-items {
    padding: 0;
    margin: 0;
    list-style-type: none;

    .recent-workspaces-list-item {
      min-width: 0;
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      &:hover {
        background-color: var(--highlight-background-color-primary);
      }
    }
  }
}

