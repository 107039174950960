.announcement {
  position: relative;
  margin-bottom: var(--spacing-xxl);

  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: var(--text-size-xxl);
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
    line-height: var(--line-height-xxl);
  }

  &-toggle-action {
    padding: 0 var(--spacing-s);
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    color: var(--content-color-link);
    cursor: pointer;
  }

  &-show-more {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-m);

    text-align: center;
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-link);
    cursor: pointer;
  }

  &-list {
    margin: var(--spacing-m) 0 var(--spacing-xl) 0;

    &__left {
      margin-top: var(--spacing-m);
      position: relative;
      width: 32px;
      height: 32px;

      &-category {
        width: 20px;
        height: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 18px;
        top: 18px;

        background-color: var(--background-color-secondary);
        border-radius: 50%;
      }
    }

    &__right {
      margin: var(--spacing-s) 0 0 var(--spacing-l);
    }

    &__item {
      display: flex;
      margin-bottom: var(--spacing-l);

      font-size: var(--text-size-m);
      line-height: var(--line-height-m);

      &--title,
      &--summary,
      &--meta {
        display: flex;
        align-items: center;
        color: var(--content-color-secondary);
      }

      &--title {
        display: inline;
      }

      &--new {
        margin-left: var(--spacing-s);
      }

      &--meta {
        margin-top: var(--spacing-xs);
      }

      &--meta_separator {
        color: var(--content-color-tertiary);
        display: inline-block;
        margin: 0 var(--spacing-xs);
      }

      &--summary {
        overflow: hidden;
        display: -webkit-box;
        margin: var(--spacing-xs) 0;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &--action {
        margin-top: var(--spacing-s);
      }

    }

    &--new {
      color: var(--base-color-brand);
      font-size: var(--text-size-s);
      margin-left: var(--spacing-xs);
    }
  }
}
