$container-max-width: 520px;

.purchase-key {
  max-width: $container-max-width;
  width: 100%;
  margin: var(--spacing-zero) auto;

  h1 {
    margin-top: var(--spacing-xxl);
  }

  &__review-banner {
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
  }

  &__review-items {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }

  &__action-buttons {
    display: flex;
  }

  &__action-confirm {
    min-width: 75px;
  }

  &__action-cancel {
    margin-left: var(--spacing-s);
  }
}
