.branch-switcher {
  max-width: 250px;
  align-self: center;

  .branch-switcher-trigger {
    margin-left: var(--spacing-s);
    cursor: pointer;
    color: var(--content-color-secondary);
    @include icon-color(var(--content-color-secondary));

    :hover {
      @include icon-color(var(--content-color-primary));
      color: var(--content-color-primary);
    }

    & > div > div, & > div > span {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .branch-switcher-icon {
    @include icon-color(var(--content-color-primary));
  }
}
