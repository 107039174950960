/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.diff-strap-numbers__wrapper {
  display: flex;
  flex-direction: column;
  color: var(--content-color-tertiary);
  min-width: 30px;
  align-items: center;

  .diff-strap-numbers {
    min-height: 20px;
    padding: var(--spacing-zero) var(--spacing-xs);
  }
}

.diff-strap-numbers-container {
  box-sizing: border-box;
  display: flex;
  background: var(--background-color-secondary);
  height: 100%;
  padding: var(--spacing-s) var(--spacing-zero);
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.changelog__wrapper {
  overflow-y: auto;
}

.changelog {
  margin-bottom: var(--spacing-xl);
  margin-right: var(--spacing-l);

  &__actor-icon {
    display: flex;
    width: var(--size-m);
    height: var(--size-m);
    margin: var(--spacing-zero) 10px;
    background-color: var(--background-color-secondary);
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &__content {
      @include icon-color(var(--content-color-tertiary));
    }
  }

  &__more-loader {
    display: flex;
    justify-content: center;
    padding: var(--spacing-xl);
    padding-top: var(--spacing-zero);
  }

  .activity-item-header {
    padding-bottom: var(--spacing-l);
  }

  .activity-item-profile-pic-wrapper {
    margin: var(--spacing-zero) 10px;
  }

  &__header {
    display: flex;
    flex: 1;
    flex-direction: column;
    line-height: var(--line-height-s);

    &__date {
      color: var(--content-color-tertiary);
      margin-bottom: var(--spacing-xs);
    }

    &__action-description {
      color: var(--content-color-primary);

      &__actor-name {
        display: inline-block;
        font-weight: var(--text-weight-medium);
      }
    }
  }

  .changelog-content-container {
    border-left: var(--border-width-l) var(--border-style-solid) var(--border-color-default);
    margin-left: var(--spacing-xl);
    padding-left: var(--spacing-xl);

    .changelog-content-container__content {
      display: flex;
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      max-height: 208px;
      overflow: hidden;
      position: relative;

      .changelog-content-container__content__shadow {
        position: absolute;
        width: 100%;
        height: 100%;

        @include hover {
          box-shadow: inset 0 -16px 16px -16px var(--content-color-tertiary);
        }
      }

      @include hover {
        cursor: pointer;

        &:before {
          bottom: var(--spacing-s);
          width: 120px;
          height: 18px;
          content: 'Click to expand';
          color: var(--content-color-constant);
          position: absolute;
          background: rgba(0, 0, 0, 0.4);
          transition: bottom cubic-bezier(0.22, 0.61, 0.36, 1) 2s;
          border-radius: 24px;
          margin: var(--spacing-zero) auto;
          right: var(--spacing-zero);
          left: var(--spacing-zero);
          text-align: center;
        }
      }
    }
  }

  .changelog-restore {
    border-left: var(--border-width-l) var(--border-style-solid) var(--border-color-default);
    margin-left: var(--spacing-xl);
    padding-left: var(--spacing-xl);
    padding-top: var(--spacing-l);
    color: var(--base-color-brand);

    .changelog-restore__loading {
      margin-left: var(--spacing-s);
    }

    &__button {
      padding: var(--spacing-s) var(--spacing-zero);
    }
  }
}

.diff-text {
  white-space: pre;
  padding: var(--spacing-s) var(--spacing-zero);
  flex: 1;
  color: var(--content-color-primary);

  .diff-text__line {
    height: 20px;
    display: flex;
    align-items: center;
    font-family: monospace;
  }

  .diff-text__line-added {
    background-color: var(--background-color-success);
  }

  .diff-text__line-removed {
    background-color: var(--background-color-error);
  }
}

.diff-view-modal-content__fullscreen {
  display: flex;
  height: 100%;
}

.schema-changelog__error {
  display: flex;
  height: 100%;
}
