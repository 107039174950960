.specification-editor-inline-decoration {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: var(--background-color-info);
}


.specification-editor-line-decoration {
  background: var(--highlight-background-color-info);
	width: 3px !important;
	margin-left: 3px;
}
