$request-method--GET-color: var(--content-color-success);
$request-method--PUT-color: var(--content-color-info);
$request-method--POST-color: var(--content-color-warning);
$request-method--DELETE-color: var(--content-color-error);
$request-method--PATCH-color: var(--content-color-patch-method);
$request-method--OPTIONS-color: var(--content-color-options-method);
$request-method--HEAD-color: var(--content-color-success);


.request-method--GET {
  color: var(--content-color-success);
  font-weight: var(--text-weight-medium);
}

.request-method--PATCH {
  color: var(--content-color-patch-method);
  font-weight: var(--text-weight-medium);
}

.request-method--OPTIONS {
  color: var(--content-color-options-method);
  font-weight: var(--text-weight-medium);
}

.request-method--PUT {
  color: $request-method--PUT-color;
  font-weight: var(--text-weight-medium);
}

.request-method--POST {
  color: var(--content-color-warning);
  font-weight: var(--text-weight-medium);
}

.request-method--DELETE {
  color: var(--content-color-error);
  font-weight: var(--text-weight-medium);
}

.request-method--HEAD {
  color: var(--content-color-success);
  font-weight: var(--text-weight-medium);
}

.request-method--default {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  text-overflow: ellipsis;
}