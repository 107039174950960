.websocket-message-lang-dropdown-btn {
  &:hover, &.is-active {
    background-color: var(--highlight-background-color-secondary);
  }

  // @todo: Remove this once the `DropdownButton` base component
  // is updated to use Button from Aether
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-default);
  color: var(--content-color-secondary);

  .dropdown-caret {
    margin-left: var(--spacing-xs);
  }
}
