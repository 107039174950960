$changelog-empty-url: $thumbnail-path + 'activity-feed.svg';

.schema-changelog-container {
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin-top: var(--spacing-l);
    font-size: var(--text-size-m);

    .entity-empty__wrapper {
      display: flex;
      flex: 1;
      overflow-y: auto;
    }

    .changelog_empty, .changelog_error, .no_schema_found {
      border: none;
      min-height: 400px;

      .entity-empty__title {
        text-align: center;
        margin-top: var(--spacing-l);
      }
    }
    
    .entity-empty {
      .entity-empty__illustration-wrapper--changelog {
        .changelog-empty__illustration {
          @include icon(
            $changelog-empty-url,
            200px,
            200px
          )
        }
      }
    }

    .changelog_loader {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  }

  &__refresh-button {
    &__loading {
      cursor: default;
    
      svg {
        animation: spin 1s linear infinite;
      }
    }
  }
}
