$empty-state-path: '../assets/images/empty-state/' !default;
$icon-team-url: $empty-state-path + 'team.svg';

.create-team-empty {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: var(--text-family-default);
  padding: 16px;
  background-color: var(--background-color-brand);
  border-radius: var(--border-radius-default);

  .create-team-empty__title {
    flex: 1;
    color: var(--content-color-primary);
    font-size: 12px;
  }
}
