.entity-actions-dropdown {
  .dropdown-menu {
    min-width: 200px;

    .dropdown-menu-item-icon {
      margin-right: var(--spacing-s);
    }

    .entity__action-item {
      display: flex;
      flex: 1;
    }
  }

  &.use-browser-tabs {
    .dropdown-menu {
      background-color: var(--background-color-primary);
      /**
      * fixes open in New Tab option text & shortcut in WindowOS.
      * https://postmanlabs.atlassian.net/browse/APPUX-153
      */
      min-width: 250px; 
      .dropdown-menu-item-shortcut {
        margin-left: var(--spacing-l);
      }
    }
  }
}
