.mocks-stub-dropdown-menu {
  min-width: 220px;
}

.mocks-stub-dropdown-menu .dropdown-menu-item {
  padding: 0 var(--spacing-l);
}

.mocks-stub-dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}

.mocks-stub-dropdown-menu .mock-stub-action-item {
  display: flex;
  flex: 1;
}

.mock-actions__more-actions-placeholder {
  height: var(--size-s);
  width: var(--size-s);
  background-color: transparent;
}

.mock-actions__toggle-active-placeholder {
  height: var(--size-s);
  width: var(--size-s);
  padding: 0 2px;
  background-color: transparent;
}
