/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.global__postbot {
  // Lines 4-37 are a hack to prevent the highly specific status bar icon styles from overriding what
  // we want for the various icons
  .response-warning-icon {
    margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
    svg {
      color: var(--content-color-warning);
    }
  }

  .response-error-icon {
    margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
    svg {
      color: var(--content-color-error);
    }
  }

  &:hover {
    // TODO: Check why this behavior is needed on global level
    // svg > path[fill] {
    //   fill: var(--content-color-secondary) !important;
    // }

    .ai-suggestions-container {
    svg {
      color: var(--content-color-secondary) !important;
    }
    }
    .global-postbot-cta {
      svg {
        color: var(--postbot-content) !important;
      }
    }
    .response-warning-icon {
      margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
      svg {
        color: var(--content-color-warning) !important;
      }
    }

    .response-error-icon {
      margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
      svg {
        color: var(--content-color-error) !important;
      }
    }
  }

  // TODO: Change class name to something closer to the actual context
  .global-postbot-settings-container {

    .postbot-shortcut-container {
      position: absolute;
      top: -56px;
      padding: 6px var(--spacing-s);
      background-color: var(--tooltip-background-color);
      border-radius: var(--border-radius-s);
      box-shadow: var(--tooltip-box-shadow);
      border: 1px solid var(--tooltip-border-color);
    }

    .global-postbot-cta {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);

      span {
        color: var(--postbot-content);
      }

      svg {
        color: var(--postbot-content);
      }
    }


    .global-postbot-popper {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: fixed;
      bottom: 32px;
      right: var(--spacing-xxl);
      width: 282px;
      background-color: var(--background-color-primary);
      border: 1px solid var(--border-color-strong);
      border-radius: 8px;
      box-shadow: var(--shadow-strong);
      padding-top: 36px;
      z-index: 2;

      &.focused {
        .postbot-header {
          p {
            color: var(--content-color-secondary);
          }

          .global-postbot-header-icon {
            svg {
              color: var(--content-color-secondary) !important;
            }
          }
        }
      }

      .postbot-header {
        background: var(--background-color-primary);
        z-index: 3;
        position: absolute;
        top: 0;
        display: flex;
        width: 100%;

        .postbot-header-items {
          justify-content: space-between;
          width: 100%;
          padding: 8px 8px 4px 12px;
        }
        .collapse-handle {
          position: absolute;
          width: 100%;
          height: 8px;
          top: 0;
          left: 0;

          &:hover {
            cursor: ns-resize;
          }
        }

        p {
          line-height: 11px;
        }
      }

      .star-loader-wrapper {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: var(--postbot-content);
      }


      .postbot-message-container {
        transition: 0.3s;
        transition-delay: 0.3s;
        height: 358px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;

        p {
          white-space: normal;
        }

        &.fade-out {
          animation: fadeOut 0s forwards;
        }

        .postbot-intro-icon-container {
          background-color: var(--postbot-background);
          border-radius: 50%;
          width: 72px;
          height: 72px;
          align-items: center;
          justify-content: center;
          display: flex;
          margin-bottom: var(--spacing-xl)
        }
      }

      .postbot-input-container {
        z-index: 3;
        background-color: var(--background-color-primary);
        padding: var(--spacing-s);
        align-items: flex-end;
        border-top: 1px solid var(--border-color-default);

        &.missing-prompt {
          border: 2px solid var(--red-30);
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .postbot-textarea {
          width: 100%;
          cursor: text;
          font-size: 12px;
          max-height: 240px;
          white-space: normal;
          overflow-x: hidden;
          overflow-y: scroll;
          margin-top: 0;
          padding: 2px 0 2px 8px;
          border: 1px solid transparent;
          user-select: text;
          color: var(--content-color-primary);

          &.executing-minified-prompt {
            color: var(--content-color-secondary);
          }

          &:empty::before {
            content: attr(data-placeholder);
            color: var(--content-color-tertiary);
            line-height: var(--line-height-s);
          }
        }

        .ai-play-btn, .cancel-ai-request-btn {
          width: 24px;
          height: 24px;

          span {
            display: flex;
          }

        }

        .ai-play-btn {
          background-color: var(--postbot-button-primary);
          svg {
            path {
              stroke: var(--postbot-button-primary-label) !important;
              fill: var(--postbot-button-primary) !important;
            }
          }
        }

        .cancel-ai-request-btn {
          background: none;
          svg {
            color: var(--postbot-content) !important;
          }
        }

      }

      .ai-suggestions-container {
        margin: var(--spacing-s) var(--spacing-m);
        border: 1px solid var(--border-color-default);
        border-radius: var(--border-radius-default);
        background-color: var(--background-color-secondary);
        padding: var(--spacing-xs) var(--spacing-s);
        z-index: 2;

        .suggestion-item {
          display: flex;
          gap: var(--spacing-s);
          align-items: center;
          padding: 6px 8px;
          border-bottom: 1px solid var(--border-color-default);
          cursor: pointer;

          &-tag {
            margin-left: auto;
          }

          &.selected {
            background-color: var(--highlight-background-color-tertiary) !important;
          }

          &:hover {
            background-color: var(--highlight-background-color-primary);
          }

          &:last-of-type {
            border: none;
          }

        }
      }

      .ai-history-container {
        flex-grow: 1;
        flex-basis: 0;
        margin: var(--spacing-l) var(--spacing-m) var(--spacing-s) var(--spacing-m) !important;

        .ai-history-item {
          padding: var(--spacing-s);
          border: 1px solid var(--border-color-default);
          border-radius: var(--border-radius-default);
          background-color: var(--background-color-secondary);
        }

        &.feedback {
          margin: 0 var(--spacing-m) var(--spacing-s) !important;
          .ai-history-item {
            margin-bottom: var(--spacing-s);
          }
        }

        .markdown-viewer-container {
          color: var(--postbot-content);
          font-size: var(--text-size-m);
          margin: -12px 0;
        }
      }

      .minified-history-container {
        .ai-history-item {
          padding: 8px 12px;

          .history-response-message {
            margin-bottom: var(--spacing-s);
            border-bottom: none !important;
          }
        }

        .markdown-viewer-container {
          color: var(--postbot-content);
          font-size: var(--text-size-m);
          margin: -12px 0 -5px 0;
        }
      }

      .ai-history-container, .minified-history-container {
        z-index: 2;
        overflow: auto;

        // For Webkit browsers like Chrome, Safari
        &::-webkit-scrollbar {
          width: 6px;
          background: transparent; // Make scrollbar track transparent
        }

        // For Firefox
        scrollbar-width: thin;
        scrollbar-color: transparent transparent;

        &:hover {
          &::-webkit-scrollbar-thumb {
              background: #888;  // Color of draggable scrolling handle
              &:hover {
                  background: #555;  // Darken color on hover
              }
          }

          // For Firefox
          scrollbar-color: #888 transparent;
        }

        .ai-history-item {
          user-select: text;

          &:first-of-type {
            margin-top: auto;
          }

          .history-prompt-container, .history-response-message {
            border-bottom: 1px solid var(--border-color-default);

            &:last-of-type {
              border: none;
            }
          }

          .history-prompt-container {
            padding-bottom: 4px;
            white-space: normal;

            p {
              white-space: pre-line;
            }
          }

          .history-message-with-prompt {
            padding-top: 8px;

            .history-loading {
              button {
                padding: 0;
                background: none;
              }
            }
          }

          .history-response-message {
            white-space: normal;

            .postbot-message-content * {
              color: var(--postbot-content);
            }

            .response-warning-container {
              .warning-message-container {
                margin: 0 !important;
              }

              button {
                width: fit-content;
                align-self: flex-start;
                margin: var(--spacing-s) 0 0 var(--spacing-l);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.inline-tooltip {
  background-color: var(--background-color-secondary);
  padding: var(--spacing-xs);
  border-radius: var(--border-radius-s);
  border: 1px solid var(--tooltip-border-color);
  box-shadow: var(--tooltip-box-shadow);
  margin: 0;
}
