$collection-browser-background-color: var(--background-color-secondary);
$collection-browser-font-color: var(--content-color-primary);
$collection-browser-meta-label-color: var(--content-color-secondary);
$collection-browser-border-color: var(--border-color-default);
$collection-browser-header-wrapper-background-color: var(--background-color-secondary);
$collection-browser-details-description-color: var(--content-color-secondary);
$collection-browser-list-item-head--hover-background-color: var(--highlight-background-color-primary);

$empty-states-path: '../assets/images/empty-state/' !default;

.collection-browser {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  background-color: $collection-browser-background-color;
  border-right: $collection-browser-border-color;
  @include material-shadow-right-1;
}

.collection-browser-details-header {
  font-family: $font-primary;
  font-size: 16px;
  color: $collection-browser-font-color;
  padding: 20px 20px 5px 20px;
  display: flex;
  align-items: center;
  @include selectable;

  .collection-browser-details-header__name, .inline-input__placeholder {
    display: inline;
    pointer-events: none;
  }

  .collection-browser-details-header__name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-wrap;
  }

  .inline-input {
    font-size: 16px;
  }
}

.dropdown-menu {
  min-width: 170px;

  .dropdown-menu-item {
    padding: 0 var(--spacing-l);

    .dropdown-menu-item-icon {
      margin-right: 10px;
    }
  }
}

.collection-browser-activity-feed-lists {
    display: flex;
    min-height: 100%;
}
