.monitor-performance-goto {
  --goto-input-width: 190px;
  display: flex;
  left: var(--spacing-l);
  position: sticky;
  z-index: 6;

  .flatpickr-input {
    padding-left: 6px;
    position: absolute;
    width: fit-content;
    width: -moz-fit-content;
    cursor: pointer;
    border: solid var(--border-width-default) var(--border-color-strong);
    border-radius: var(--border-radius-default);
    font-family: var(--text-family-default);

    &--loading {
      pointer-events: none;
      cursor: default;
      width: calc(var(--goto-input-width) + var(--size-s));
    }
  }

  .flatpickr-input:disabled {
    color: var(--content-color-primary);
    background: var(--background-color-primary);
  }

  .goto {
    top: var(--line-height-l);
    height: fit-content;
    height: -moz-fit-content; // Mozilla firefox doesn't support fit-content
    width: fit-content;
    width: -moz-fit-content;
    position: sticky;

    .goto__loader {
      position: absolute;
      margin-left: var(--goto-input-width);
      margin-top: var(--spacing-xs);
    }
  }
  .goto:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.job-history-chart:hover .flatpickr-input {
  opacity: 0.6;
}

.job-history-chart .goto:hover .flatpickr-input{
  opacity: 1;
}
