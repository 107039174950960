.fork-offline {
  position: relative;
  top: 25%;

  &-image {
    @include icon(
      '../assets/images/thumbnails/browse-view-offline.svg',
      160px,
      160px
    );
    margin: auto;
  }
}
