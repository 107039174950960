@mixin unstyled-list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}

.team-integrations__status-summary {
  display: flex;
  align-items: center;
  white-space: nowrap;
  &__icon {
    vertical-align: sub;
    margin-right: var(--spacing-xs);
  }

  &__text {
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    max-width: calc(4*var(--spacing-xl));
    text-overflow: ellipsis;
    overflow: hidden;
    &-success {
      color: var(--content-color-success);
    }
    &-configured {
      color: var(--content-color-tertiary);
    }
    &-error {
      color: var(--content-color-error);
    }
  }
}


.integration-runs {
  &__error{
    margin-top: 120px;

    &__action {
      max-width: 320px;
      width: 320px;
    }
  }
}


.team-integrations {
  margin: 0 var(--spacing-s);
  &-title {
    margin: 0 var(--spacing-xl);
    line-height: var(--line-height-xxxl);
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xxxl);
  }
  &-description {
    margin: 0 var(--spacing-xl);
    font-style: normal;
    font-weight: normal;
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-secondary);
  }
}

.team-integrations.page-1 {
  margin: 0 auto;
  @media (max-width: 1259px) {
    width: 100%;
  }
  @media (min-width: 1260px) {
    width: 960px;
  }
}

.team-integrations.page-2 {
  max-width: 960px;
  width: 960px;
  margin: 0 auto;
}

.team-integrations-actionables {
  margin: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  .integrations-sort-search-title {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    padding-bottom: var(--spacing-xs);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
  }
  .integrations-sort-search-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1024px){
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .integrations-sort-search {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px){
      flex-direction: column;
      & > .team-integrations-filter__actions {
        padding-bottom: var(--spacing-l);
      }
      padding-bottom: var(--spacing-l);
    }
  }
  .integrations-sort-dropdown {
    max-width: 300px;
    width: 200px;
    .Select-control {
      background: var(--background-color-secondary);
      border: 1px solid var(--border-color-strong);
      box-sizing: border-box;
      border-radius: var(--border-radius-default);

      .Select-value {
        color: var(--content-color-secondary);
      }
      .Select-arrow {
        border-color: var(--border-color-strong) transparent transparent;
      }
      &.is-open .Select-control .Select-arrow-zone .Select-arrow {
        border-bottom-color: var(--border-color-strong)
      }
    }
  }
  .integrations-search {
    padding-left: var(--spacing-l);
    width: 288px;
    @media (max-width: 1024px){
      padding-left: var(--spacing-zero);
    }
    .input-search-group {
      border-radius: var(--border-radius-default);
      height: var(--size-m);
    }
    .pm-form-control-sm {
      background: var(--background-color-secondary);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-sizing: border-box;
      border-radius: var(--border-radius-default);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      border: 1px solid var(--border-color-strong);
      padding: var(--spacing-zero) var(--spacing-xxl) var(--spacing-zero) var(--spacing-xxl);
    }
    .pm-form-control-sm {
      height: 40px;
    }

    .pm-icon-input .pm-icon.pm-icon-sm {
      margin: var(--spacing-m) var(--spacing-s);
    }
  }
}



.team-integrations-filter__actions {
  display: flex;
  align-items: center;

  .dropdown {
    height: var(--size-m);
  }

  .team-integrations-filter__actions-sort-button {
    display: flex;
    width: 150px;
    justify-content: space-between;
    height: var(--size-m);
    color: var(--content-color-primary);
    background-color: var(--background-color-secondary);
    border: 1px solid var(--border-color-default);
    @include hover {
      border-color:var(--border-color-strong);
      background-color: var(--highlight-background-color-secondary);
    }
    @include active {
      border-color: var(--border-color-strong);
      background-color: var(--highlight-background-color-secondary);
    }
  }

  .team-integrations-filter__actions__meta {
    padding-right: var(--spacing-xs);
    font-style: normal;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
  }
}

.team-integrations-empty-state-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-l);
  min-height: 100%;
  margin-top: var(--spacing-xl);
}

.team-integrations-empty-state {
  padding-top: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__illustration-wrapper {
    margin-bottom: var(--spacing-l);
  }

}

.team-integrations-empty-state-actions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-s)
}


.btn-secondary.browse-integrations  {
  height: var(--size-m);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding: var(--spacing-zero) var(--spacing-m);
}


.btn-primary.add-integrations  {
  height: var(--size-m);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding: var(--spacing-zero) var(--spacing-m);
}
