.link-balloon-container {
  position: absolute;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-width-xl);
  background: var(--background-color-primary);
  z-index: 2;
  margin-top: var(--spacing-l);
  align-items: center;
  transition: top 75ms ease-out, left 75ms ease-out;
  width: 350px;
  padding: var(--spacing-s) !important;
  overflow: hidden;

  &.small,
  &.edit {
    width: 255px;
    padding: var(--spacing-xs) var(--spacing-s) !important;
  }

  .link-close-btn {
    position: absolute;
    top: var(--spacing-s);
    right: var(--spacing-s);
    padding: 0 var(--spacing-xs);
    height: 24px;
  }

  .has-error {
    padding-bottom: 20px;
  }

  .link-input {
    &::placeholder {
      color: var(--content-color-tertiary);
    }

    &.link-url-input {
      color: var(--content-color-link);
    }
  }

  .link-unlink-btn {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .link-label {
    flex: 1;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }
}
