.overview-page-content-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex: 3;
  padding: var(--spacing-xxl) var(--spacing-l);

  &--responsive {
    padding-bottom: 0;
  }
}

.overview-page-plan-alert {
  margin-bottom: var(--spacing-xxl);

  .alert-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .alert-actions {
      width: calc(4 * var(--size-s));
      margin-top: var(--spacing-zero);

      .alert-primary-action {
        margin-right: var(--spacing-zero);
        width: calc(4 * var(--size-s));
        margin-left: var(--spacing-s);
      }
    }
  }

  &-icon {
    color: var(--base-color-info);
    background: var(--background-color-primary);
    font-size: var(--text-size-m);
    height: var(--size-xs);
    padding: var(--spacing-zero) var(--spacing-s);
    margin-top: var(--spacing-s);
    font-weight: var(--text-weight-bold);
    margin-right: var(--spacing-s);
    border-radius: var(--spacing-s);
  }
}
