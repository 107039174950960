.sb__item__pane {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 200px;
  background-color: var(--background-color-secondary);
  width: 100%;
  z-index: 30;
  box-shadow: var(--shadow-default);

  .plugin__pane-resize-wrapper {
    width: 100%;
    padding: 5px 0;
    cursor: row-resize;
    position: absolute;
  }

  &.is-hidden {
    display: none;
  }

  .plugin__pane-close {
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 10px;
  }
}
