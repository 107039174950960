// This is being imported first so we can extend/override the styles as required in this file
@import './react-table-stock';

.ReactTable {
  $header-height: 30px;

  border: none;

  .rt-thead {
    background: var(--background-color-secondary);

    &.-header {
      box-shadow: none;
    }

    .rt-tr {
      text-align: left;
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);
    }

    .rt-th,
    .rt-td {
      line-height: $header-height;
      padding: 0 var(--spacing-s);
      color: var(--content-color-secondary);
      border-right: none;

      &.-cursor-pointer {
        &:hover {
          color: var(--content-color-primary);
          background: var(--highlight-background-color-secondary);
        }
      }

      &.-sort-asc,
      &.-sort-desc {
        box-shadow: none;
        color: var(--content-color-link);
        &:hover {
          color: var(--content-color-link);
        }
      }

      &.-sort-asc {
        & > div {
          &:after {
            top: -2px;
            left: 8px;
            height: 0;
            content: ' ';
            position: relative;
            display: inline-block;
            border-color: transparent;
            border-top-color: inherit;
            border-width: 4px 4px 0;
            border-style: solid;
          }
        }
      }

      &.-sort-desc {
        & > div {
          &:after {
            top: -2px;
            left: 8px;
            height: 0;
            content: ' ';
            position: relative;
            display: inline-block;
            border-color: transparent;
            border-bottom-color: inherit;
            border-width: 0 4px 4px;
            border-style: solid;
          }
        }
      }
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border-bottom: solid thin var(--border-color-default);
    }

    .rt-td {
      font-size: var(--text-size-l);
      color: var(--content-color-primary);
      border: none;
    }

    .rt-tr {
      &__anchor {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .rt-td {
    padding: 0;
  }

  &.-highlight {
    .rt-tbody {
      .rt-tr {
        &:not(.-padRow) {
          &:hover {
            background: var(--background-color-secondary);
          }
        }
      }
    }
  }
}
