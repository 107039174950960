/* stylelint-disable postman-stylelint/color-no-hex-rgb */

.sync-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;

  .sync-status-badge__icon svg {
      color : var(--content-color-secondary);
    }

  &:hover {
    .sync-status-badge__icon svg {
      color: var(--content-color-primary);
    }

    .sync-status-badge > span {
      color: var(--content-color-primary);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .sync-status-badge > span {
    display: none;
  }
}

.sync-button {
  @include icon-color(var(--content-color-constant));
  &.sync-off {
    background-color: #b94a48;
    @include hover {
      background-color: #ed4b48;
    }
  }

  &.offline {
    background-color: #5a5a5a;
    @include hover {
      background-color: #6e6e6e;
    }
  }

  &.in-sync {
    background-color: #f47023;
  }

  &.error {
    background-color: #ED4B48;
  }

  &.syncing, &.connecting {
    background-color: #097bed;
    .pm-icon-sync {
      animation: rotate 1s infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(-180deg);
    }
  }
}

.sync-button-icon {
  @include icon(
    '../assets/images/thumbnails/sync_normal.svg',
    22px,
    21px
  )
}

.sync-status-tooltip {
  z-index: 90;

  .tooltip-wrapper {
    background-color: var(--background-color-primary);
    padding: var(--spacing-l);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.sync-status-tooltip-wrapper {
  max-width: 320px;
  font-family: $font-primary;

  .sync-status-offline-icon {
    @include icon-color(var(--content-color-primary));
  }

  .sync-status-success-icon {
    @include icon-color(var(--content-color-success));
  }

  .sync-status-error-icon {
    @include icon-color(var(--content-color-error));
  }

  .sync-error-tooltip-heading {
    color: var(--content-color-error);
  }

  .sync-status-tooltip-header {
    font-size: 12px;
    display: inline;
    margin: 0 0 0 8px;
    vertical-align: top;

    &__online {
      color: var(--content-color-success);
    }
  }

  @media only screen and (max-width: 768px) {
    .sync-status-tooltip-header {
      display: none;
    }
  }

  .sync-status-tooltip-title {
    font-weight: 800;
    margin-bottom: 8px;
  }

  .sync-status-tooltip-description {
    display: block;
    margin-top: 8px;
    font-size: 12px;
  }

  .sync-status-tooltip-button {
    margin-top: 16px;
  }
}

.sync-error-modal {
  max-height: 330px;
  scroll-behavior: auto;

  .sync-error-modal--error-icon {
    @include icon-color(var(--content-color-error));
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    display: inline;
    margin-left: 8px;
    position: relative;
    bottom: 2px;
    color: var(--content-color-error);
  }

  &__text {
    margin-top: 8px;
    color: var(--content-color-primary);
  }

  &__element {
    margin-left: 8px;
  }

  &__element-title {
    margin-top: 16px;
    margin-bottom: 16px;
    color: var(--content-color-primary);
    font-weight: 600;
  }

  &__element-name {
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    max-width: 200px;
    color: var(--content-color-primary);
  }

  &__support-text {
    color: var(--content-color-error);
    margin-left: 8px;
    margin-bottom: 6px;
  }

  &-retry {
    margin-left: 8px;
    margin-top: 16px;
  }
}

.sync-status-badge {
  cursor: default;

  &__error, &__off {
    background-color: var(--background-color-error);
  }

  &__connecting {
    background-color: var(--background-color-warning);
  }

  &__offline {
    background-color: var(--background-color-tertiary);
  }

  &__insync, &__syncing {
    background-color: var(--background-color-success);
  }

  &__text {
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
    margin-right: var(--spacing-s);
    margin-top: var(--spacing-xs);

    &-error, &-off {
      color: var(--content-color-error);
    }

    &-offline {
      color: var(--content-color-secondary);
    }

    &-connecting {
      color: var(--content-color-warning);
    }

    &-insync, &-syncing {
      color: var(--content-color-success);
    }
  }

  &__icon {
    vertical-align: middle;
    margin-right: var(--spacing-xs);
  }
}

.sync-status-wrapper {
  z-index: 1;
}

.sync-online-icon {
  &__syncing {
    animation: rotate 1s infinite;

    svg {
      path {
        fill: var(--content-color-success);
      }
      color: var(--content-color-success);
    }
  }

  &__insync {
    svg {
      path {
        fill: var(--content-color-success);
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(-180deg);
    }
  }
}

.sync-offline-icon {
  &__dark {
    svg {
      path {
        fill: var(--content-color-primary);
      }
    }
  }
}
