.collection-explorer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .collection-explorer__path {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    margin-bottom: var(--spacing-s);

    &__empty{
      margin-left: var(--spacing-s);
    }

    .breadcrumb__container{
      flex: 1;
      margin-left: var(--spacing-s);
    }
  }

  .explorer-empty-state{
    flex:1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 16px;
    border: var(--border-width-default) solid var(--border-color-default);

    &__illustration {
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: var(--spacing-l);
      background-color: var(--background-color-tertiary);

      &__icon {
        height: var(--size-m);
        width: var(--size-m);

        svg {
          height: var(--size-m);
          width: var(--size-m);
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;

      &__body {
        margin-top: var(--spacing-s);
        text-align: center
      }
    }

    &__action {
      margin-top: var(--spacing-l);
    }

  }
}
