.create-installed-app {
  &--label {
    margin-bottom: var(--spacing-xs) !important;
  }

  &--label-secondary {
    margin-top: var(--spacing-s) !important;
  }

  &--label-disabled {
    background-color: var(--background-color-secondary) !important;
  }

  &--description {
    margin-top: calc(-1*var(--spacing-s)) !important;
    margin-bottom: var(--spacing-xs);
  }
}

.update-installed-app {
  &--label {
    margin-bottom: var(--spacing-xs) !important;
  }

  &--label-secondary {
    margin-top: var(--spacing-s) !important;
  }

  &--label-disabled {
    background-color: var(--background-color-secondary) !important;
  }

  &--description {
    margin-top: calc(-1*var(--spacing-s)) !important;
    margin-bottom: var(--spacing-xs);
  }
}
