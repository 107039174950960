:root {
  --min-editor-height: calc(10 * var(--size-m));
  --max-editor-height: calc(100vh - 350px);
}

.description-editor-wrapper {
  padding-bottom: var(--spacing-s);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: var(--spacing-l);
  }
}

.documentation-editor-footer {
  display: flex;
  justify-content: space-between;
}

.documentation-editor-dropdown {
  height: 24px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  color: var(--content-color-primary);

  .dropdown-caret {
    position: relative;
    top: 4px;
    margin-left: 4px;
  }

  .dropdown-button {
    font-size: var(--text-size-m);
  }
  .dropdown-menu {
    min-width: 150px;
  }
}

.description-editor-actions {
  display: flex;

  & > * + * {
    margin-left: var(--spacing-m);
  }
}

.modal-content {
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-xl);
  color: var(--content-color-primary);
  overflow-y: auto;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.description-editor {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  height: max-content;

  &__radioactions__tag {
    line-height: var(--line-height-s);
    margin-left: var(--spacing-xs);
    padding: var(--spacing-zero) var(--spacing-xs);
    background: var(--background-color-info);
    border-radius: 9999px;
    color: var(--content-color-info);
    font-weight: var(--text-weight-bold);
  }

  &__actions {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius-default);
    height: 28px;
    margin-bottom: var(--spacing-s);
    box-shadow: var(--aether-popover-shadow);

    &--left {
      display: flex;
      align-items: center;
      height: 100%;
      box-sizing: border-box;

      button {
        padding: var(--spacing-zero) var(--spacing-l);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        border: none;
        height: 100%;
        font-family: var(--text-family-default);
        color: var(--content-color-secondary);
        background-color: transparent;

        &.active {
          color: var(--content-color-primary);
          box-shadow: inset 0px -1px 0px var(--base-color-brand);
        }
      }
    }

    &--right {
      display: flex;
      height: 24px;
      width: 24px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 3px;
      margin: var(--spacing-s);

      &:hover {
        background-color: var(--background-color-secondary);
      }

      &.active {
        background-color: var(--background-color-tertiary);
      }
    }
  }

  &__markdown-editor,
  &__preview {
    flex: 1;
    padding: var(--spacing-s);
    border-radius: var(--border-radius-default);
    border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
  }

  &__preview {
    overflow: auto;
  }
}

.description-preview {
  ul li {
    margin-left: var(--spacing-m);
  }
}
.popup-info-wrapper {
  position: absolute;
  top: 36px;
  right: 8px;
  z-index: 1;
  width: 223px;
  height: auto;
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l);
  background-color: var(--background-color-secondary);
  box-shadow: var(--shadow-default);

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -4px;
    right: var(--spacing-s);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid var(--background-color-secondary);
  }

  h3 {
    margin: 0 0 var(--spacing-m);
    color: var(--content-color-primary);
  }

  p {
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-l);
  }

  &__tag {
    line-height: var(--line-height-s);
    margin-left: var(--spacing-xs);
    padding: var(--spacing-zero) var(--spacing-s);
    background: var(--background-color-info);
    border-radius: var(--border-radius-default);
    color: var(--content-color-info);
    font-weight: var(--text-weight-bold);
    font-size: var(--text-size-m);
  }
}
