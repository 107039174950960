.password-input {
  position: relative;

  .password-input-message {
    position: absolute;
    display: inline;
    top: 0;
    right: 30px;

    .password-input__warning-btn {
      background: unset;
      @include icon-color(var(--base-color-warning));
    }

    .password-input__error-btn {
      background: unset;
      @include icon-color(var(--base-color-error));
    }
  }

  &__toggle {
    display: inline;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 0;
    right: 0;

    i {
      padding: var(--spacing-s);
    }
  }

  input {
    padding-right: var(--spacing-xxl);

    .input-box .input-huge {
      padding-right: var(--spacing-xxxl);
    }
  }
}
