.workspace-switcher__list-loading-state {
  height: calc(12 * 32px);
  border-right: 1px solid var(--border-color-default);
  border-left: 1px solid var(--border-color-default);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
  padding-left: var(--spacing-l);

  .workspace-switcher__list-loading-state--item {
    display: flex;
    height: 32px;
    align-items: center;

    .workspace-switcher__list-loading-state__icon {
      height: 8px;
      width: 8px;
      background-color: var(--highlight-background-color-secondary);
      margin: var(--spacing-zero) var(--spacing-m) var(--spacing-zero) var(--spacing-xxl);
      border-radius: var(--border-radius-s);
    }

    .workspace-switcher__list-loading-state__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 120px;
      height: 8px;
      background-color: var(--highlight-background-color-secondary);
      border-radius: var(--border-radius-s);
    }
  }
}

.workspace-input-loading-state {
  margin-right: var(--spacing-m);
}
