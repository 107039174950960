/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.integrations-add-card__segment, .integrations-form__segment__input {
  .menu {
    position: absolute;
    background: var(--background-color-primar);
    border: 1px solid var(--border-color-default);
    border-radius: var(--border-radius-default);
    margin-top: 8px;
    width:100% ;
    box-shadow: 0 0 10px -5px var(--grey-90);
  }

  .menu-section {
    border-bottom: 1px solid var(--border-color-default);
    border-top: 1px solid var(--border-color-default);
  }

  .menu-section:first-of-type {
    border-top: 0
  }

  .menu-section:last-of-type {
    border-bottom: 0
  }

  .menu-section+.menu-section {
    border-top: 0
  }

  .menu-section__title {
    font-size: 1rem;
    padding: .5rem;
    font-weight: 700
  }

  .menu-section-title:empty {
    padding: 0
  }

  .menu-section-title:empty+.menu-section {
    border-top: 0
  }

  .option {
    min-height: 24px;
    width: 100%;
    padding: 8px 16px
  }

  .option.focused,.option:hover {
    background: var(--background-color-secondary);
    cursor: pointer
  }

  .option:focus {
    border: 10px solid var(--border-color-default);
  }

  .option.disabled {
    opacity: .5;
    background: var(--background-color-secondary);
  }

  .option.disabled:hover {
    cursor: no-drop
  }

  .Select {
    width:100%;
  }

  .Select,.Select div,.Select input,.Select span {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: var(--text-size-m);
  }

  .Select.is-disabled>.Select-control {
    background-color: var(--background-color-tertiary);
    cursor:not-allowed;
  }

  .Select.is-disabled>.Select-control:hover {
    box-shadow: none;
    cursor:not-allowed;
  }

  .Select.is-disabled .Select-arrow-zone {
    cursor: not-allowed;
    pointer-events: none
  }
  .is-error .Select-control{
    border:1px solid var(--base-color-error);
  }

  .Select-control {
    background: var(--background-color-secondary);
    border-radius: var(--border-radius-default);
    border: 1px solid var(--border-color-default);
    color: var(--content-color-primary);;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 32px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .Select-control:hover {
    box-shadow: none ;
    background: var(--background-color-tertiary) ;
  }

  .is-disabled .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
    color: var(--content-color-secondary)
  }

  .Select-control:disabled{
    cursor: not-allowed;

  }

  .Select-control:focus {
    box-shadow: none ;
    background: var(--background-color-primary) ;
  }

  .Select-control .Select-input:focus {
    outline: none;
    box-shadow: none ;
    background: var(--background-color-primary) ;
  }

  .is-searchable.is-open>.Select-control {
    cursor: text
  }

  .is-open>.Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: var(--background-color-primary);
    border-color: var(--border-color-default);
  }

  .is-open>.Select-control .Select-arrow {
    top: -2px;
    border-color: transparent transparent var(--grey-50);
    border-width: 0 4px 4px
  }

  .is-searchable.is-focused:not(.is-open)>.Select-control {
    cursor: text
  }

  .is-focused:not(.is-open)>.Select-control {
    border-color: transparent ;
    box-shadow: none ;
  }

  .Select--single>.Select-control .Select-value,.Select-placeholder {
    bottom: 0;
    color:var(--content-color-tertiary);
    left: 0;
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .Select-input {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    max-width: calc(100% - 24px);
    overflow: hidden
  }

  .Select-input > input {
    background: none transparent;
    border: 0;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 14px;
    padding: 6px 0 10px;
    -webkit-appearance: none
  }

  .is-focused .Select-input>input {
    cursor: text
  }

  .Select-control:not(.is-searchable)>.Select-input {
    outline: none
  }

  .Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    text-align: center
  }

  .Select-loading,.Select-loading-zone {
    position: relative;
    vertical-align: middle;
    width: 16px
  }

  .Select-loading {
    -webkit-animation: Select-animation-spin .4s linear infinite;
    -o-animation: Select-animation-spin .4s infinite linear;
    animation: Select-animation-spin .4s linear infinite;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid var(--border-color-default);
    border-right-color: var(--content-color-primary);;
    display: inline-block
  }

  .Select-clear-zone {
    -webkit-animation: Select-animation-fadeIn .2s;
    -o-animation: Select-animation-fadeIn .2s;
    animation: Select-animation-fadeIn .2s;
    color: var(--grey-50);
    cursor: pointer;
    display: inline-block;
    position: absolute;
    bottom: 50%;
    right: 15px;
    transform: translateY(50%);
    width: 15px;
    height: 15px;
    margin-bottom: 1px
  }

  .Select-clear-zone:hover {
    color:var(--grey-50);
  }

  .Select-clear {
    display: inline-block;
    font-size: 16px;
    line-height: 1
  }

  .Select--multi .Select-clear-zone {
    width: 15px
  }

  .Select--multi .Select-multi-value-wrapper {
    display: inline-block
  }

  .Select .Select-aria-only {
    display: inline-block;
    height: 1px;
    width: 1px;
    margin: -1px;
    clip: rect(0,0,0,0);
    overflow: hidden
  }

  .Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    padding-right: 4px
  }

  .Select-arrow {
    border-color: var(--grey-50) transparent transparent;
    border-style: solid;
    border-width: 4px 4px 2px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative
  }

  .is-open .Select-arrow,.Select-arrow-zone:hover>.Select-arrow {
    border-top-color: var(--grey-50);
  }

  @-webkit-keyframes Select-animation-fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
  }

  @keyframes Select-animation-fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
  }

  .Select--single.pm-select {
    font-size: 12px;
    height: 32px;
    width: 100% ;
    max-width: 100%;
    margin: 0px;
  }

  .Select--single.pm-select .Select-value {
    pointer-events: none;
  }
  .Select--multi.pm-select .Select-value {
    cursor: pointer;
    color: var(--content-color-secondary);
    border: var(--border-width-default) solid var(--border-color-default);
    border-radius: var(--border-radius-default);
    margin: var(--spacing-xs);
  }

  .Select--single.pm-select .Select-control {
    display: inline-block;
    overflow: visible;
    max-width: 100%;
    width: auto;
    cursor: pointer
  }

  .Select--single.pm-select .Select-control:hover {
    box-shadow: none
  }

  .Select--single.pm-select .Select-placeholder,.Select--single.pm-select .Select-value {
    position: static;
    padding-right: 24px
  }

  .Select--single.pm-select .Select-input {
    top: 0;
    left: 0;
    position: absolute
  }

  .Select--single.pm-select .Select-arrow-zone {
    top: 50%;
    right: 0;
    padding: 0;
    position: absolute;
    display: inline-block;
    transform: translateY(-50%)
  }

  .Select--single.pm-select .Select-menu-outer {
    width: 100% ;
    margin-top: 4px;
    min-width: 120px;
    max-width: 100% ;
    max-height: 400px;
    border-radius: var(--border-radius-default);
    font-size: 14px
  }

  .Select--single.pm-select .Select-menu {
    max-height: 398px
  }

  .Select--single.pm-select .Select-option {
    overflow: hidden;
    padding-left: 16px;
    padding-right: 32px;
    color: var(--content-color-primary);
    font-size: var(--text-size-s);
    white-space: nowrap;
    text-overflow: ellipsis
  }

  .Select--multi.pm-select .Select-option {
    overflow: hidden;
    padding-left: 16px;
    padding-right: 32px;
    color: var(--content-color-primary);
    font-size: var(--text-size-s);
    white-space: nowrap;
    text-overflow: ellipsis
  }
  .Select--multi.pm-select .Select-option:first-child {
    margin-top: 8px
  }

  .Select--multi.pm-select .Select-option:last-child {
    margin-bottom: 8px
  }

  .Select--multi.pm-select .Select-option:hover {
    background: var(--background-color-tertiary);
    color: var(--content-color-primary);
  }

  .Select--multi.pm-select .Select-option:active {
    background: var(--background-color-secondary);
  }

  .Select--multi.pm-select--align-right .Select-menu-outer {
    right: 0
  }

  .Select-option .disabled-option {
    color: var(--content-color-tertiary);
  }

  .Select--single.pm-select .Select-option:first-child {
    margin-top: 8px
  }

  .Select--single.pm-select .Select-option:last-child {
    margin-bottom: 8px
  }

  .Select--single.pm-select .Select-option:hover {
    background: var(--background-color-tertiary);
    color: var(--content-color-primary);
  }

  .Select--single.pm-select .Select-option:active {
    background: var(--background-color-secondary);
  }

  .Select--single.pm-select--align-right .Select-menu-outer {
    right: 0
  }

  .Select--single.pm-select--loading .Select-arrow {
    display: none
  }

  .Select--single.pm-select .Select-placeholder {
    color: var(--content-color-tertiary);
  }

  .Select--single.pm-select .Select-value {
    color: var(--content-color-primary);
  }

  .Select--single.pm-select-text {
    display: inline-block
  }

  .Select--single.pm-select-text .Select-control {
    background: none;
    border: thin solid transparent
  }
  .Select--single.pm-select-text:not(.is-focused) .Select-control {
    border: none;
  }

  .Select--single.pm-select-text:not(.is-focused) .Select-control:hover {
    box-shadow: none
  }

  .Select--single.pm-select-text--dark .Select-control {
    border: thin solid transparent
  }

  .Select--single.pm-select-text--dark .Select-arrow,.Select--single.pm-select-text--dark .Select-placeholder,.Select--single.pm-select-text--dark .Select-value,.Select--single.pm-select-text--dark:active .Select-arrow,.Select--single.pm-select-text--dark:active .Select-placeholder,.Select--single.pm-select-text--dark:active .Select-value,.Select--single.pm-select-text--dark:hover .Select-arrow,.Select--single.pm-select-text--dark:hover .Select-placeholder,.Select--single.pm-select-text--dark:hover .Select-value {
    color: var(--content-color-constant);
    border-top-color: var(--grey-00);
  }

  .Select--single.pm-select-text--dark.is-open .Select-arrow,.Select--single.pm-select-text--dark.is-open:active .Select-arrow,.Select--single.pm-select-text--dark.is-open:hover .Select-arrow {
    border-bottom-color: var(--grey-00);
  }

  .Select--single .pm-select-loader {
    position: absolute;
    top: 50%;
    right: var(--spacing-l);
    transform: translateY(-50%)
  }

  .Select--single.pm-select-md {
    font-size: 14px
  }

  .Select--single.pm-select-md,.Select--single.pm-select-md .Select-control,.Select--single.pm-select-md .Select-input {
    height: 40px
  }

  .Select--single.pm-select-md .Select-input,.Select--single.pm-select-md .Select-placeholder,.Select--single.pm-select-md .Select-value {
    line-height: 40px
  }

  .Select--single.pm-select-lg {
    font-size: 16px
  }

  .Select--single.pm-select-lg,.Select--single.pm-select-lg .Select-control,.Select--single.pm-select-lg .Select-input {
    height: 48px
  }

  .Select--single.pm-select-lg .Select-input,.Select--single.pm-select-lg .Select-placeholder,.Select--single.pm-select-lg .Select-value {
    line-height: 48px
  }

  .Select--single.pm-select--block,.Select--single.pm-select--block .Select-control {
    width: 100%;
    max-width: 100%
  }

  .Select--single.pm-select--block .Select-menu-outer {
    width: 100%;
    max-width: none
  }

  .Select--single.is-searchable .Select-control,.Select--single.is-searchable .Select-multi-value-wrapper {
    display: block
  }

  .react-16-dropdown {
    display: inline-block
  }

  .react-16-dropdown .trigger-renderer {
    position: relative;
    padding: 8px
  }

  .react-16-dropdown .trigger-renderer:focus {
    outline: none
  }

  .react-16-dropdown.pm-dropdown--block,.react-16-dropdown.pm-dropdown--block .trigger {
    display: block
  }

  .react-16-dropdown.pm-dropdown--block .trigger-renderer {
    width: 100%;
    display: block
  }

  .react-16-dropdown.pm-more-options-dropdown .pm-btn__default-icon svg {
    height: 14px;
    width: 14px
  }

  .react-16-dropdown.pm-dropdown-md .trigger-renderer {
    font-size: 14px;
    line-height: 24px
  }

  .react-16-dropdown.pm-dropdown-lg .trigger-renderer {
    font-size: 16px;
    line-height: 28px
  }

  .react-16-dropdown-portal .option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }

  .react-16-dropdown-portal .menu {
    padding: 8px 0;
    max-width: 300px;
    min-width: 120px;
    z-index: 100;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.2)
  }

  .react-16-dropdown-portal .menu:focus,.react-16-dropdown-portal .menu>div:focus {
    outline: none
  }

  .Select-menu-outer {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--background-color-primary);
    border: 1px solid var(--border-color-default);
    border-top-color: var(--border-color-default);
    box-shadow: 0 1px 0 rgba(0,0,0,.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch
  }

  .Select-menu {
    max-height: 198px;
    overflow-y: auto
  }

  .Select-option {
    box-sizing: border-box;
    background-color: var(--background-color-primary);
    color: var(--content-color-primary);;
    cursor: pointer;
    display: block;
    padding: 8px 10px
  }

  .Select-option:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
  }

  .Select-option.is-selected {
    background-color: var(--background-color-primary);
    color: var(--content-color-primary);
  }

  .Select-option.is-focused {
    background-color: var(--background-color-primary);
    color: var(--content-color-primary);
  }

  .Select-option.is-disabled {
    color: var(--content-color-tertiary);
    cursor: not-allowed
  }

  .Select-noresults {
    box-sizing: border-box;
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    cursor: default;
    display: block;
    padding: 8px 10px
  }

  .Select--multi .Select-input {
    vertical-align: middle;
    top: 0;
    left: 0;
    padding-left: 2px;
  }

  .Select--multi.has-value .Select-input {
    margin-left: var(--spacing-xs);
    margin-top: var(--spacing-xs);
    height: var(--size-m);
    font-size: var(--text-size-m);
    line-height: var(--line-height-xxl);
    text-align: center;
  }

  .Select--multi .Select-value {
    background-color: var(--background-color-primary);
    border-radius: 2px;
    border: 1px solid var(--blue-20);
    color: var(--blue-40);
    display: inline-block;
    font-size: var(--text-size-s);
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top
  }

  .Select--multi .Select-value-icon,.Select--multi .Select-value-label {
    display: inline-block;
    vertical-align: middle;
    color: var(--content-color-secondary);
  }

  .Select--multi .Select-value-label {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    cursor: default;
    padding: 2px 5px
  }

  .Select--multi a.Select-value-label {
    color: var(--content-color-primary);
    text-decoration: none
  }

  .Select--multi a.Select-value-label:hover {
    text-decoration: underline
  }

  .Select--multi .Select-value-icon {
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: var(--border-width-default) solid var(--border-color-default);
    padding: 1px 5px 3px
  }

  .Select--multi .Select-value-icon:focus,.Select--multi .Select-value-icon:hover {
    background-color: var(--background-color-primary);
    color: var(--content-color-primary);
  }

  .Select--multi .Select-value-icon:active {
    background-color: var(--blue-20);
  }

  .Select--multi.is-disabled .Select-value {
    background-color: var(--background-color-primary);
    border: 1px solid var(--border-color-default);
    color: var(--content-color-secondary);
  }

  .Select--multi.is-disabled .Select-value-icon {
    cursor: not-allowed;
    border-right: 1px solid var(--border-color-default);
  }

  .Select--multi.is-disabled .Select-value-icon:active,.Select--multi.is-disabled .Select-value-icon:focus,.Select--multi.is-disabled .Select-value-icon:hover {
    background-color: var(--background-color-primary);
    cursor: not-allowed;
  }

  @keyframes Select-animation-spin {
    to {
        transform: rotate(1turn)
    }
  }

  @-webkit-keyframes Select-animation-spin {
    to {
        -webkit-transform: rotate(1turn)
    }
  }

}

.integrations-form__segment__label {
  &-title{
    color: var(--content-color-secondary);
    font-style: normal;
    font-size: var(--text-size-m);
    font-weight: 600;
    line-height: var(--line-height-s);
    span {
      color: var(--content-color-secondary);
    }
  }

  &-description {
    margin-top: 0;
    color: var(--content-color-secondary);
    font-style: normal;
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);
  }
}
