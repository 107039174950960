/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$list-margin-left: 0 60px;
$list-item-shadow: 0px 0 2px rgba(0, 0, 0, 0.2);

.lesson-center-container {
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
  font-family: $font-primary;
  padding: var(--spacing-xxl);
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--background-color-primary);
  font-size: var(--text-size-m);

  .loading-indicator-wrapper {
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
  }

  .lesson-center-header {
    margin: var(--spacing-xl) 0;
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
  }

  .lesson-center-list {
    display: flex;

    .lesson-center-list-column {
      flex: 1;
    }

    .empty-state {
      display: flex;
      padding-top: 180px;
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      height: 420px;
      margin-left: 20px;
      font-family: $font-primary;
      color: var(--content-color-secondary);
      flex-direction: column;
      align-items: center;
      margin-top: var(--spacing-xxl);
      border-radius: var(--border-radius-default);

      .bootcamp-illustration-title {
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-medium);
        margin: 20px 0 10px;
      }

      .bootcamp-illustration-content {
        text-align: center;
        padding: 0 var(--spacing-l);
      }
    }

    .learning-center-section-title {
      margin-bottom: var(--spacing-l);
      font-size: var(--text-size-m);
      color: var(--content-color-primary);
      font-weight: var(--text-weight-medium);
      display: flex;

      .skills-list-progress {
        font-size: var(--text-size-xs);
        color: var(--content-color-brand);
        margin-left: 10px;
      }
    }

    .resuming-learning-list-title {
      align-items: center;
      cursor: pointer;
    }

    .resume-learning-list-container {
      margin-bottom: var(--spacing-xl);
    }

    .loading-indicator-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
