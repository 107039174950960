.invite-user-modal {
  .empty-state--no-users {
    height: 200px;
    color: var(--content-color-secondary);
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .create-new-workspace__input-group {
    margin-top: 16px;
  }


  .modal-content {
    padding: 20px;
    overflow-y: auto;
    max-height: 75vh;

    .invite-user-modal__email-privileges-denied {
      margin: 16px 0;
      color: var(--base-color-error);
    }

    .invite-user-workspace-meta-title {
      color: var(--content-color-primary);
      margin-bottom: 16px;
    }
    .invite-user-workspace-meta-text {
      color: var(--content-color-tertiary);
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .invite-user-modal-alert {
      margin-top: 8px;
    }
  }

  &__copy-invite-link {
    margin-right: auto;
    margin-left: var(--spacing-zero);
  }

  .button-small {
    width: 100px;
  }

  .button-medium {
    width: 120px;
  }

  .button-large {
    width: 200px;
  }
}

.invitation-sent-text-meta {
  margin-top: 32px;
  margin-left: 16px;
  color: var(--content-color-secondary);
}

.invitation-sent-info-meta {
  margin-top: 80px;
  margin-left: 16px;
  color: var(--content-color-secondary);
}

.setup-team-name-meta {
  margin-top: 32px;
  margin-left: 16px;
  color: var(--content-color-tertiary);
}

.setup-team-url-meta {
  margin-top: 50px;
  margin-left: 16px;
  color: var(--content-color-tertiary);
}
.share-entity-model {
  .setup-team-model-content {
    padding: 16px;
  }
}
.invite-user-alert-container {
  margin-bottom: 8px;
}

.invite-user-modal__team_plan {
  &__warning {
      .alert {
          background-color: var(--background-color-warning);
          margin-top: var(--spacing-zero);
          margin-bottom: var(--spacing-xl);
      }
  }
  &__error {
    .alert {
        background-color: var(--background-color-error);
        margin-top: var(--spacing-zero);
        margin-bottom: var(--spacing-xl);
    }
  }
}



