@import './sidebar/EnvironmentSidebarContainer/index';
@import './sidebar/EnvironmentSidebarMenu/index';
@import './sidebar/EnvironmentSidebarListContainer/index';
@import './sidebar/EnvironmentSidebarListItem/index';
@import './sidebar/EnvironmentSidebarListItemDummy/index';
@import './contextbar/EnvironmentInfoCBView/index';

.environment-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  .entity-tab-header {
    border-bottom: none;
  }

  &__action {
    margin-right: var(--spacing-s);

    &-save i, i {
      margin-right: var(--spacing-xs);
    }

    &-share {
      margin-right: var(--spacing-s);
    }
  }

  .globals-editor__action:last-child {
    margin-right: unset;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &__variables-editor {
      .key-value-form-editor {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 5px;
          width: calc(100% - .75rem);
          border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 5px;
          width: calc(100% - .75rem);
          border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        }

        .key-value-form-header-row {
          overflow-y: scroll;
        }
      }
    }

    .variables-editor__content {
      overflow-x: hidden;
    }
  }
}
.environment-meta-icon {
  margin-right: var(--spacing-xs);
}
