.skills-list-container {
  .skill-list-item {
    cursor: pointer;

    .skill-list-item-content {
      display: flex;
      flex-direction: column;

      .skill-list-item-description {
        font-weight: var(--text-weight-regular);
        margin-top: 10px;
        font-size: var(--text-size-s);
        color: var(--content-color-secondary);
      }
    }
    .skill-meta {
      display: flex;
      font-size: var(--text-size-xs);
      margin-top: 10px;
      color: var(--content-color-secondary);

      .skill-lessons-number {
        margin-right: 20px;
      }

      .skill-progress {

      }
    }
  }
}
