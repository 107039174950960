

  .team-integrations-error-state {
    border: none;
    padding: var(--spacing-xl);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;


    &__illustration-wrapper {
      margin-bottom: var(--spacing-l);
    }
    &__title {
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
      line-height: var(--line-height-l);
      margin-bottom: var(--spacing-s);
    }

    &__subtitle {
      margin-bottom: var(--spacing-xl);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      font-weight: var(--text-weight-regular);
    }

    &__title,
    &__subtitle {
      @include unselectable;
    }

    .offline__illustration{
      height: 120px;
      width: 120px;
    }
  }

  .integration-entity-error-icon {
    margin-left: var(--spacing-s);
    i {
      vertical-align: middle;
      padding-bottom: 1%;
    }
  }

  .integration-entity-error-tooltip {
    margin-bottom: var(--spacing-xs);
    max-width: 204px;
  }
