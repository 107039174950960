

  .team-integrations-offline-state {
    border: none;
    padding: var(--spacing-xl);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;


    &__illustration-wrapper {
      margin-bottom: 0px;
    }
    &__title {
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-medium);
      line-height: var(--line-height-l);
      margin-bottom: var(--spacing-l);
    }

    &__subtitle {
      margin-bottom: var(--spacing-xl);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      font-weight: var(--text-weight-regular);
    }

    &__title,
    &__subtitle {
      @include unselectable;
    }

    .offline__illustration{
      height: 120px;
      width: 120px;
    }
  }
