$wrap-error-button-width:240px;
$error-button-width:320px;

.integration-edit-wrap {
  &__error{
    margin-top: 120px;

    &__action {
      max-width: $wrap-error-button-width;
      width: $wrap-error-button-width;
    }
  }
}

.integrations-form {
  &__segment {
    margin-top: var(--spacing-l);

    &__label {
      &-title {
        color: var(--content-color-primary);
        font-style: normal;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);

        span {
          color: var(--content-color-secondary);
        }
      }

      &-description {
        margin-top: 0;
        color: var(--content-color-secondary);
        font-style: normal;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);

        p {
          display: inline;
        }
      }
    }

    &__input {
      flex: 1;
      padding-top: var(--spacing-s);

      &__error {
        font-size: var(--text-size-m);
        color: var(--content-color-error);
        margin-top: var(--spacing-xs);
        &__btn {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &__inline-token {
        width: 440px;
      }

      &__helper {
        font-size: var(--text-size-m);
        margin-top: var(--spacing-xs);
        &__btn {
          text-decoration: none;
          color: var(--content-color-info);
          cursor: pointer;
        }
      }
    }
  }

  &-actions {
    &__button {
      &--container {
        margin-top: var(--spacing-xl);
        float: left;
        padding-bottom: var(--spacing-xxxl);
      }

      &--cancel {
        border-radius: var(--border-radius-default);
        margin-left: var(--spacing-s);
      }

      &--save-changes {
        border-radius: var(--border-radius-default);
      }
    }
  }
}

.integration-edit, .integrations-edit {
  margin: 0 auto;
  width: 720px;
  &__error{
    margin-top: 120px;

    &__action {
      max-width: $error-button-width;
      width: $error-button-width;
    }
  }

  .integration-edit-actions, .integrations-edit-actions {
    &__separator {
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      border-radius: var(--border-radius-s);
    }

    &__button {
      margin-bottom: var(--spacing-l);
    }
  }

  .integration-edit-card, .integrations-edit-card {
    // max-width: 374px;
    margin: 0 140px;
    position: relative;

    &__head {
      margin-bottom:var(--spacing-s);
    }

    .integration-edit-actions-separator, .integrations-edit-actions-separator {
      color: var(--content-color-secondary);
      margin-top: var(--spacing-l);
      margin-bottom: 0;
    }

    .integrations-edit-actions {
      &__button {
        &--container {
          margin-top: var(--spacing-xl);
          float: left;
          padding-bottom: var(--spacing-xxxl);
        }

        &--container-inline {
          align-self: flex-end;
          padding-right: var(--spacing-xl);
        }

        &--cancel {
          border-radius: var(--border-radius-default);
          margin-left: var(--spacing-s);
        }

        &--save-changes {
          border-radius: var(--border-radius-default);
        }
      }
    }
  }

  // Common components fields currently use add-card styles, override them here
  .integrations-add-card {

    &__segment {
      margin-top:var(--spacing-l);
    }

    &__label-description {
      margin-top: 0;
      color: var(--content-color-secondary);
      font-style: normal;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
    }
    &__label-description-external-link {
      margin-top: 0;
      display: flex;
      color: var(--content-color-secondary);
      font-style: normal;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
    }
    &__external-link{
      display: flex;
      align-items: center;
      color: var(--content-color-primary);
    }
    &__description {
      margin-top: 0px;
      margin-bottom: var(--spacing-l);
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-regular);
    }

    &__advanced {
      color: var(--orange-40);
      margin: var(--spacing-s) 0;
      cursor: pointer;

      svg {
        color: var(--orange-40);
      }
    }

    &__select {
      width: 100%;
    }

    &__name {
      text-align: center;
    }

    &-actions {
      margin-top: var(--spacing-l);

      &__cancel {
        width: 96px;
        float: left;
      }
    }


    &__authed-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: var(--spacing-xl);
      font-size: var(--text-size-m);
      margin-right: 8px;

      &__text {
        margin-right: 8px;
      }

      &___name {
        color: var(--content-color-secondary);
      }

      p {
        font-weight: 600;
      }

      img {
        border-radius: 50%;
        width: var(--spacing-xxl);
        margin-right: var(--spacing-xs);
      }

      span {
        font-size: var(--font-size-m);
        color: var(--content-color-secondary);
      }
    }

    &__fail-alert {
      margin: var(--spacing-l) 0;
    }
  }
}

.integrations-connected-account {
  &__form {
    &__header {
      &__primary-text {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
      }
      margin-top: var(--spacing-m);
      color: var(--content-color-primary);

      &__helper {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        margin-top: var(--spacing-xs);
        &__btn {
          text-decoration: none;
          color: var(--content-color-info);
          cursor: pointer;
        }
      }

      &__btn {
        margin-left: var(--spacing-l);
      }
    }
  }
}
