.sync-issue-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  .sync-issue-content-left {
    flex: 0 0 271px;
    margin-right: 20px;
  }

  .sync-issue-content-right {
    flex: 1;
  }

  .sync-issue-button {
    width: 100%;
  }
}

.sync-issue-detailed-log-container {
  padding-bottom: 20px;

  .sync-issue-detailed-log-head-text {
    padding-bottom: 10px;
  }
}
