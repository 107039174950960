.right-overlay {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 110;
  color: var(--content-color-primary);
  background: var(--background-modal-backdrop);

  .right-overlay__modal {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    background: var(--background-color-primary);
    height: 100%;
    right: 0;
    box-shadow: var(--shadow-default);
    font-size: var(--text-size-m);

    .right-overlay--resize-handle {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5px;
      cursor: ew-resize;
    }

    .right-overlay__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
      font-family: var(--text-family-default);

      .right-overlay__header {
        display: flex;
        align-items: center;
        color: var(--content-color-tertiary);
        padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);

        .right-overlay__breadcrumb {
          @include text-truncate-ellipsis;
          flex: 1;
          font-weight: normal;

          .right-overlay__breadcrumb-item {
            @include clickable;

            &:after {
              content: ' / ';
              padding: var(--spacing-zero) var(--spacing-xs);
            }

            .right-overlay--breadcrumb-item-name {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .right-overlay__close-icon-wrapper {
          margin-left: var(--spacing-s);
        }
        .right-overlay__close-icon {
          @include unselectable;
          @include clickable;
          &:hover {
            @include icon-color(var(--content-color-primary));
          }
        }
      }

      .right-overlay__title {
        @include text-truncate-ellipsis;
        padding: var(--spacing-zero) var(--spacing-xl);
        font-size: var(--text-size-xxl);
        font-weight: var(--text-weight-medium);
      }

      .right-overlay__details {
        padding: var(--spacing-zero) var(--spacing-xl);
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
      }
    }
  }
}


