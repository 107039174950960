.request-body {
  &--title {
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    padding: 2px var(--spacing-zero);

    &--tertiary {
      color: var(--content-color-tertiary);
      font-weight: var(--text-weight-regular);
      line-height: 22px;
      padding: 1px var(--spacing-zero);
    }
  }

  &--graphql {
    &--title-label {
      color: var(--content-color-tertiary);
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-medium);

      span:first-child {
        color: var(--content-color-primary);
        margin-right: var(--spacing-s);
      }
    }

    &--subtitle-label {
      margin-top: var(--spacing-m);
      font-size: var(--text-size-m) !important;
      line-height: var(--line-height-s) !important;
      color: var(--content-color-secondary) !important;
    }
  }

  &--urlencoded {
    &--title-label {
      font-weight: var(--text-weight-medium);
      color: var(--content-color-tertiary);
      line-height: var(--line-height-m);

      span:first-child {
        color: var(--content-color-primary);
        margin-right: var(--spacing-s);
      }
    }
  }

  &--form-data {
    &--title-label {
      color: var(--content-color-tertiary);
      font-weight: var(--text-weight-medium);
      line-height: var(--line-height-m);

      span:first-child {
        color: var(--content-color-primary);
        margin-right: var(--spacing-s);
      }
    }
  }

  &--binary {
    &--title-label {
      color: var(--content-color-tertiary);
      font-weight: var(--text-weight-medium);
      line-height: var(--line-height-m);

      span:first-child {
        color: var(--content-color-primary);
        margin-right: var(--spacing-s);
      }
    }
  }
}
