.documentation-tab {
  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  &-wrapper-legacy {
    height: 100%;
    overflow: hidden;
  }

  &-sidebar {
    border-right: var(--border-width-l) var(--border-style-solid)
      var(--border-color-default);
    max-height: 100%;
    width: 285px;
    max-width: 285px;
    padding: var(--spacing-s);
    overflow-x: auto;
    overflow-y: auto;
    box-sizing: border-box;

    .sidebar-list-item__name-wrapper{
      display: flex;
      align-items: center;
    }

    .schema-doc-invalid-yaml-json {
      .sidebar-list-item__name-wrapper {
        color: var(--content-color-secondary);
        font-style: italic;
      }
    }

    &-heading {
      margin-left: var(--spacing-s);
    }

    &-empty-state {
      margin: 0;
      margin-left: var(--spacing-s);
    }

    &-button {
      height: 24px;
      width: 24px;
    }

    .schema-documentation-sidebar {
      .documentation-list {
        &__folder {
          display: flex;
          justify-content: flex-start;
          padding: 2px var(--spacing-zero) 2px var(--spacing-zero);
        }

        &__item-name {
          min-height: 32px;
          max-height: 64px;
          padding-top: 0px;
          cursor: pointer;
        }

        &__item-error {
          margin-left: auto;
        }
      }
    }

    .sidebar-list-item {
      padding-left: var(--spacing-zero);
      height: 32px;
    }

    .sidebar-list-item__content-wrapper {
      margin-left: var(--spacing-zero);
    }

    .sidebar-list-item.selected {
      background-color: var(--background-color-tertiary);
    }

    .sidebar-list-item.selected + .collection-documentation-folder-container {
      margin-top: var(--spacing-s);
    }

    .sidebar-list-item.selected + .schema-folder-container {
      margin-top: var(--spacing-s);
    }

    .documentation-list {
      &__title {
        text-transform: uppercase;
        font-size: var(--text-size-m);
        color: var(--content-color-tertiary);
        margin-bottom: var(--spacing-s);
      }

      &__item {
        display: contents;
      }

      &__items-container {
        /**
       * Displaying the icons in grid allows us to indent all the items description
       * and icons correctly as shown below without the need to hard code any
       * min-width or max-width
       *
       * icon-1                   description-1
       * long-icon-1 .            description-2
       * long-long-long-icon-2    description-3
       */
        display: grid;
        /**
       * max-content is meant to specify that the width of the icon columns would
       * the max-width of the icon and the rest area would be taken by its description
       */
        grid-template-columns: max-content 1fr;
        row-gap: var(--spacing-l);
        column-gap: var(--spacing-s);
        padding-bottom: var(--spacing-m);
        align-items: flex-start;
      }

      &__item-name {
        font-size: var(--text-size-m);
        color: var(--content-color-primary);
        word-break: break-word;
        min-height: 30px;
        max-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 2px;
      }

      &__schema-path-icon {
        position: relative;
        top: 2px;
      }

      &__request-item-icon {
        padding: 2px !important;
        word-break: initial;
      }

      &__item-icon {
        display: inherit;
        justify-self: end;

        &.with-padding {
          padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-xs)
            var(--spacing-zero);
        }

        .icon-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          margin-right: var(--spacing-zero) !important;
          padding: var(--spacing-zero);
          display: inherit;

          &:active {
            outline: none;
          }
        }

        .list-icon {
          vertical-align: middle;
        }
      }

      &__folder {
        display: flex;
        justify-content: flex-start;
        padding: 4px var(--spacing-zero) 2px var(--spacing-zero);
      }

      &__folder-icon {
        display: block;

        .list-icon {
          vertical-align: unset;
        }
      }
    }

    .documentation-list-item__marker {
      width: 0;
      height: 0;
      align-self: center;

      &--visible {
        margin-right: var(--spacing-s);
        margin-bottom: var(--spacing-s);
        width: var(--spacing-s);
        height: var(--spacing-s);
        border-radius: var(--border-radius-max);
        background-color: var(--content-color-brand);
      }
    }

    .collection-documentation-folder-container {
      display: grid;
      grid-template-columns: min-content min-content 1fr min-content;
      gap: 0px 6px;
      align-items: flex-start;
      margin-left: 10px;

      .children-container {
        grid-column: span 4;
      }

      .collection-documentation-folder-container {
        grid-template-columns: var(--spacing-l) min-content 1fr min-content;
      }
    }

    .schema-folder-container {
      display: grid;
      grid-template-columns: min-content min-content 1fr min-content;
      gap: 0px 6px;
      align-items: flex-start;
      margin-left: 10px;

      .children-container {
        grid-column: span 4;
      }

      .schema-folder-container {
        grid-template-columns: 0px var(--spacing-xxl);
      }
    }

    .doc-warning-icon {
      display: flex;
      margin-left: var(--spacing-s);
      align-items: center;
    }
  }

  &-sidebar-title {
    margin: var(--spacing-zero) 1px;
    margin-bottom: var(--spacing-s);
    background-color: var(--background-color-tertiary);
  }

  &-content {
    height: 100%;
    flex: 1;
    overflow: auto;

    // setting only in network mode - Owned by API Network
    &__network {
      --maxContentWidth: 1224px;
      --sides-padding: max(calc((90vw - var(--maxContentWidth)) / 2), var(--spacing-xxl));
      padding-right: var(--sides-padding);
    }
  }

  &__generate-documentation {
    width: 185px;
    height: var(--size-m);
  }

  &__add-existing-documentation {
    width: 185px;
    height: var(--size-m);
    margin-top: var(--spacing-l);
  }
}
