// Size factors
$avatar-group-xs: 0.75 !default;
$avatar-group-sm: 1 !default;
$avatar-group-md: 1.5 !default;
$avatar-group-lg: 2 !default;

@mixin members($size-factor) {
  &.avatar-group {
    height: 40px * $size-factor;
    width: 40px * $size-factor;
  }

  .member {
    height: 16px * $size-factor;
    width: 16px * $size-factor;
    border-radius: 50%;
  }

  &.two-members {
    .member-item:nth-of-type(1) {
      transform: rotate(0deg) translate(6px * $size-factor) rotate(0deg) translate(-50%, -50%);
    }
    .member-item:nth-of-type(2) {
      transform: rotate(180deg) translate(6px * $size-factor) rotate(-180deg) translate(-50%, -50%);
    }
  }

  &.three-members {
    .member-item:nth-of-type(1) {
      transform: rotate(30deg) translate(8px * $size-factor) rotate(-30deg) translate(-50%, -50%);
    }
    .member-item:nth-of-type(2) {
      transform: rotate(150deg) translate(8px * $size-factor) rotate(-150deg) translate(-50%, -50%);
    }
    .member-item:nth-of-type(3) {
      transform: rotate(270deg) translate(8px * $size-factor) rotate(-270deg) translate(-50%, -50%);
    }
  }

  &.four-members {
    .member-item:nth-of-type(1) {
      transform: rotate(180deg) translate(8px * $size-factor) rotate(-180deg) translate(-50%, -50%);
    }
    .member-item:nth-of-type(2) {
      transform: rotate(270deg) translate(8px * $size-factor) rotate(-270deg) translate(-50%, -50%);
    }
    .member-item:nth-of-type(3) {
      transform: rotate(0deg) translate(8px * $size-factor) rotate(0deg) translate(-50%, -50%);
    }
    .member-item:nth-of-type(4) {
      transform: rotate(90deg) translate(8px * $size-factor) rotate(-90deg) translate(-50%, -50%);
    }
  }
}

.avatar-group {
  position: relative;

  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--background-color-brand);

  padding: var(--spacing-zero);
  list-style: none;
  box-sizing: content-box;

  .member-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;
  }

  .member {
    height: var(--size-xs);
    width: var(--size-xs);
    border-radius: 50%;
  }

  &.avatar-group-xs {
    @include members($avatar-group-xs);
  }

  &.avatar-group-sm {
    @include members($avatar-group-sm);
  }

  &.avatar-group-md {
    @include members($avatar-group-md);
  }

  &.avatar-group-lg {
    @include members($avatar-group-lg);
  }
}
