
.console-footer {
  background-color: var(--background-color-secondary);
  padding: 2px 5px;
  font-size: 13px;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

  @include unselectable;

  .console-footer__settings {
    margin: 3px 5px 3px 4px;
    display: flex;
    color: var(--content-color-secondary);

    .settings__item {
      display: flex;
      align-items: center;
      cursor: pointer;

      .settings__checkbox {
        &.is-selected {
          background-color: var(--base-color-brand);
        }
      }

      .settings__label {
        margin-left: var(--spacing-xs);
      }

      &.is-disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &:not(:last-of-type) {
        margin-right: var(--spacing-l);
      }
    }
  }

  .console-footer__meta-settings {
    display: flex;

    .meta-settings__left {
      align-items: center;
      display: flex;
      flex: 50%;

      .meta {
        margin: var(--spacing-zero) var(--spacing-xs);
        display: flex;
        align-items: center;

        .meta__error-icon {
          @include icon-color(var(--base-color-error));
        }

        .meta__warning-icon {
          @include icon-color(var(--base-color-warning));
        }

        .meta__label {
          margin-left: var(--spacing-xs);
          color: var(--content-color-primary);
        }
      }
    }

    .meta-settings__right {
      justify-content: flex-end;
      align-items: center;

      .settings-icon {
        background: none;
        max-height: 20px;
        margin: 2px var(--spacing-zero);

        &:hover {
          @include icon-color(var(--base-color-brand));
        }

        .settings-icon--active {
          @include icon-color(var(--base-color-brand));
        }
      }
    }
  }
}
