@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.invite-member {
  width: 450px;
  margin: var(--spacing-xxl) auto;
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);

  &--invite {
    &-left-container {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
    }

    &-slots {
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      color: var(--grey-60);
    }

    &-team-full {
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      color: var(--content-color-success);

      &-error {
        color: var(--content-color-error);
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
      }
    }
  }

  &--tags-container {
    margin-top: var(--spacing-s);
  }

  &--slot-error {
    margin-top: var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-error);

    a {
      text-decoration: underline;
    }
  }

  &--invite-link {
    margin-top: var(--spacing-xl);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    padding-bottom: var(--spacing-xl);

    &-title {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
    }

    &-subtitle {
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      margin-top: var(--spacing-s);
      color: var(--content-color-secondary);
    }

    &-generate-btn {
      width: 136px;
      margin-top: var(--spacing-l);
    }

    &-container {
      margin-top: var(--spacing-s);
      display: flex;
    }

    &--input {
      flex-grow: 1;
      margin-right: var(--spacing-s);
    }

    .invite-link-copied {
      input {
        border-color: var(--base-color-success);
      }
    }

    .link-copied {
      background: var(--base-color-success);
      color: var(--content-color-constant);
    }

    .tick-icon-alternate {
      -webkit-animation: zoom-in 0.1s linear none;
      animation: zoom-in 0.1s linear none;
      margin-right: 8px;
      svg {
        color: var(--content-color-constant) !important;
      }
    }
  }

  &--actions {
    margin-top: var(--spacing-xl);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    &-primary {
      width: 152px;
      font-size: var(--text-size-l);
    }

    &-secondary {
      font-size: var(--text-size-l);
    }
  }
}

.invite-member-onboarding-plan-alert {
  margin-bottom: var(--spacing-l);
  .alert {
    padding: var(--spacing-s);

    .alert-icon {
      margin-top: var(--spacing-s);

      svg {
        color: var(--content-color-info);
      }
    }

    .alert-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .alert-actions {
        width: calc(4 * var(--size-s));
        margin-top: var(--spacing-zero);

        .alert-primary-action {
          margin-right: var(--spacing-zero);
          width: calc(4 * var(--size-s));
          margin-left: var(--spacing-s);
        }
      }
    }
  }
}
