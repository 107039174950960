.create-new-modal__template-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: overlay;

  .create-new-template__error {
    display: flex;
    flex: 1;
    .creation-error__icon {
      margin-top: -40px;
    }
  }

  .create-new-modal__templates__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

.create-new-modal__templates {
  display: flex;
  flex: 1;
  height: inherit;
  max-width: 100%; // The entire component will remain in view irrespective of the modal is causing the overflow

  .create-new-modal__templates__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 0 20px 20px;

    .create-new-modal__templates__actions {
      display: flex;
      flex-direction: row;
      padding-left: 16px;
      margin-bottom: 16px;
    }

    .create-new-modal__templates__search {
      width: 320px;

      .input-search-group {
        border-radius: unset;
      }
    }

    .create-new-modal__template__version {
      margin-left: 6px;
      width: 158px;
      border-radius: var(--border-radius-default);

      .create-new-modal__template__version-name {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
      }

      .btn-small {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .create-new-modal__templates__template-list {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      overflow-y: auto;
    }

    .create-new-modal__templates__heading-wrapper {
      margin-left: 16px;
      margin-bottom: 16px;

      .create-new-modal__templates__heading {
        line-height: 30px;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      .create-new-modal__templates__heading-info {
        line-height: 20px;
        font-size: 12px;
        .btn {
          padding: 0 3px;
        }
      }
    }
  }

  .create-new-modal__templates__sidebar {
    width: 210px;
    height: inherit;

    .create-new-modal__templates__sidebar__title {
      font-size: 12px;
      font-weight: 600;
      color: $create-new-placeholder-color;
      text-transform: uppercase;
      margin: 20px 0 10px 20px;
    }

    .tabs-vertical .tab.is-active {
      color: var(--content-color-primary);
      background-color: var(--background-color-brand);
    }

    .tabs {
      width: 210px;
      border-right: 1px solid var(--border-color-default);
      overflow: auto;
      height: 90%;

      .tab {
        margin: 4px 8px;
        padding-left: 8px;
        border-radius: var(--border-radius-default);
        display: block;
        font-size: 12px;
        line-height: 22px;
        text-align: left;
        min-width: 150px;
        max-width: 210px;
        @include text-truncate-ellipsis;
        @include hover {
          color: var(--content-color-primary);
          background-color: var(--background-color-brand);
        }
      }
    }
  }

  .create-new-modal__template-card {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 144px;
    padding: 20px;
    margin: 16px;
    cursor: pointer;
    border: 1px solid var(--border-color-default);
    border-radius: 2px;
    box-sizing: border-box;

    @include hover {
      background-color: var(--background-color-secondary);
    }

    .create-new-modal__template-card__title-wrapper {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: flex-end;
      .tag {
        padding-left: 6px;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 55px;
        height: 16px;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        overflow: unset;
      }
    }

    .create-new-modal__template-card__title {
      font-size: 14px;
      font-weight: 600;
      color: var(--base-color-brand);
      min-width: 150px;
      padding-right: 4px;
      flex: 2;

      @include text-truncate-ellipsis;
    }

    .create-new-modal__template-card__description {
      font-size: 12px;
      color: $create-new-secondary-color;
      overflow: hidden;
      height: 38px;
      text-overflow: ellipsis;
    }

    .create-new-modal__template-card__downloads {
      font-size: 12px;
      color: var(--content-color-tertiary);
      overflow: hidden;
      margin-top: 8px;

      .create-new-modal__template-card__downloads-count {
        color: var(--content-color-secondary);
        font-weight: 600;
      }
    }
  }
}

.create-new-modal__template {
  display: flex;
  flex-direction: row;
  max-width: 100%; // To keep the entire component in view and handle overflow
  padding: 0 24px 0 24px;
  box-sizing: border-box;

  .create-new-modal__template__content {
    flex: 1;
    padding: 30px 0px;
    width: 640px;

    &.is-loading {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .api-network__empty-state {
      margin-top: 8px;
      color: var(--content-color-tertiary);

      &--description {
        @include markdown();
        border: 1px solid var(--border-color-default);
        flex:1;
        padding: 16px;
        margin: 16px 0;
        background-color: var(--background-color-secondary);
      }
    }
  }

  .create-new-modal__template__info {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-top: 54px;

    .run-in-postman {
      margin: 16px 0 4px;
      height: 30px;
      align-self: flex-start;
      flex-shrink: 0;
      justify-content: space-around;
      .arrow {
        margin-top: 2px;
      }
    }

    .view-documentation {
      margin: 8px 0 8px;
      height: 30px;
      color: var(--content-color-secondary);
      background-color: var(--background-color-tertiary);
      align-self: flex-start;
      flex-shrink: 0;
      justify-content: space-around;
    }

    .create-new-modal__template__info__categories,
    .create-new-modal__template__info__published-by,
    .create-new-modal__template__info__download {
      margin-top: 16px;
      margin-bottom: 8px;
      font-size: 12px;
      color: var(--content-color-tertiary);
      font-weight: 600;
    }

    .create-new-modal__template__info__categories--names,
    .create-new-modal__template__info__download-count {
      color: var(--content-color-primary);
      font-weight: 300;
      margin-top: 8px;
    }

    .create-new-modal__template__info__publisher {
      margin-top: 8px;
      display: flex;
      cursor: pointer;

      .create-new-modal__template__info__publisher-name {
        color: var(--base-color-brand);
        font-weight: 600;
        display: flex;
        max-width: 198px;
        margin-left: 8px;
        flex-direction: column;
        justify-content: center;
        @include text-truncate-ellipsis;

        &:hover {
          text-decoration: underline;
        }
      }

      .create-new-modal__template__info__publisher-icon {
        display: flex;
        align-self: center;
        flex-direction: column;
      }
    }

    .create-new-modal__template__info__categories--names {
      max-width: 140px;
      word-break: break-word;
    }
  }

  .create-new-modal__template__back-btn {
    padding: 0px;
    margin-bottom: 20px;

    .create-new-modal__template__back-icon {
      margin-right: 5px;
    }
  }

  .create-new-modal__template__title {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: $create-new-primary-color;
    flex-grow: 0;
    margin-right: 16px;
    overflow: hidden;

    span {
      @include text-truncate-ellipsis;
      flex: 2;
    }

    .tag {
      padding: 0px 6px;
      height: 16px;
      font-size: 11px;
      line-height: 16px;
      position: relative;
      margin-left: 16px;
    }
  }

  .create-new-modal__template__loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .create-new-modal__template__summary {
    margin-top: 20px;
    word-break: break-word;
  }

  .create-new-modal__template__description {
    @include markdown();

    flex: 1;
    border: 1px solid var(--border-color-default);
    padding: 16px;
    margin: 16px 0;
    background-color: var(--background-color-secondary);
  }

  .create-new-modal__template__version {
      width: 170px;

    .dropdown-button {
      .btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .create-new-modal__template__version-name,
    .create-new-modal__run__button__version-name {
      @include text-truncate-ellipsis;
    }
  }

  .btn-primary {
    font-size: 12px;
    width: 145px;
    height: 40px;
    align-self: flex-end;
  }
}
