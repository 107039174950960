$workspace-type-switcher-background-color: var(--border-color-strong);
$workspace-type-switcher-background-color--active: var(--background-color-primary);
$workspace-type-switcher-color: var(--content-color-secondary);
$workspace-type-switcher-color--active: var(--content-color-brand);

.plugin__workspace-view-switcher {
  .plugin__workspace-view-switcher__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 174px;
    height: 24px;
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-tertiary);
    direction: ltr;
    position: relative;

    &.disabled {
      .plugin__workspace-view-switcher__switch__item {
        cursor: default;
      }
    }

    .plugin__workspace-view-switcher__switch__item {
      flex: 0 0 85px;
      text-align: center;
      font-family: $font-primary;
      font-size: 12px;
      color: $workspace-type-switcher-color;
      cursor: pointer;
      line-height: 24px;
    }

    .plugin__workspace-view-switcher__switch {
      content: '';
      position: absolute;
      width: 85px;
      height: 20px;
      background-color: var(--background-color-primary);
      border-radius: var(--border-radius-default);
      border: 1px solid var(--border-color-strong);
      color: var(--content-color-primary);
      text-align: center;
      font-family: $font-primary;
      font-size: 12px;
      line-height: 20px;
      transition: left 0.3s;
      transition-timing-function: ease-out;
      display: flex;
      justify-content: center;
      z-index: 1;

      &.left {
        left: 0;
      }

      &.right {
        left: 87px;
      }
    }
  }
}

.workspace-type-switcher__intro__wrap {
  max-width: 360px;
}

.workspace-type-switcher__intro {
  padding: 15px;
  text-align: center;
  font-family: $font-primary;

  .workspace-type-switcher__intro__title {
    color: var(--content-color-primary);
    font-weight: 600;
    font-size: 14px;
  }

  .workspace-type-switcher__intro__description {
    color: var(--content-color-primary);
    font-size: 12px;
    margin: 10px 0;
  }

  .workspace-type-switcher__intro__learn-more {
    margin-bottom: 20px;
  }

  .workspace-type-switcher__intro__primary-btn .btn {
    width: 190px;
  }
}
