.pm-proxy-session-landing-wrapper {
  height: 100%;
  width: 100%;
}

.pm-proxy-session-landing-header {
  position: absolute;
  width: 100%;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.pm-proxy-session-landing-body-content{
  margin: calc(2 * var(--spacing-xxl)) var(--spacing-zero);
}

.pm-proxy-session-landing-body{
  height: 100%;
  width: 100%;
  margin-top: 15vh;
  overflow-y: scroll;
}

.pm-proxy-session-capture-button{
  margin-left: calc(-1 * (var(--spacing-xxl)));
  bottom: var(--spacing-l);
}

.pm-proxy-session-landing-body-content-item-title{
  padding-top: var(--spacing-xxl);
}

.pm-proxy-session-landing-body-content-item-description{
  max-width: 420px;
}

.pm-proxy-session-landing-body-content-logo{
  padding-right: var(--spacing-s);
}
