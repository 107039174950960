$keyvalue-row-height: 32px !default;
$keyvalue-row-height-session: 50px !default;
$keyvalue-row-height-session-padding: 82px !default;
$keyvalue-row-input-font-size: var(--text-size-m) !default;
$keyvalue-dropdown-caret-height: 12px !default;
$keyvalue-dropdown-caret-width: 12px !default;

@keyframes fadeOut {
  0% {
    filter: grayscale(0%)
  }

  25% {
    filter: grayscale(25%)
  }

  50% {
    filter: grayscale(50%)
  }

  75% {
    filter: grayscale(75%)
  }

  100% {
    filter: grayscale(100%);
    background-color: transparent;
  }
}

.key-value-form-row {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1 1 auto;
  height: $keyvalue-row-height;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);

  &__is-highlighted {
    background-color: var(--background-color-info);
    animation: fadeOut 1.2s linear 1;

    .key-value-form-row__modification-options {
      background-color: var(--background-color-info);
      animation: fadeOut 1.2s linear 1;
    }

    .variable-type-dropdown {
      .aether-dropdown__control {
        background-color: var(--background-color-info);
        animation: fadeOut 1.2s linear 1;
      }
    }

    .options-dropdown {
      .aether-dropdown__control {
        background-color: var(--background-color-info);
        animation: fadeOut 1.2s linear 1;
      }
    }

    :hover {
      .key-value-form-row__modification-options {
        background-color: transparent;
      }

      .variable-type-dropdown {
        .aether-dropdown__control {
          background-color: transparent;
        }
      }

      .options-dropdown {
        .aether-dropdown__control {
          background-color: transparent;
        }
      }
    }
  }

  .key-value-form-column {
    .auto-suggest-cell {
      padding: var(--spacing-zero) var(--spacing-xs);
    }
  }

  @include hover {
    &:not(.row-focused) {
      .key-value-form-column:last-child {
        .auto-suggest {
          &.has-error,
          &.has-warning {
            margin-right: var(--spacing-xl);
          }
        }
      }
    }
  }

  .auto-suggest-group {
    position: relative;

    .key-value-form-row__blocked {
      margin: var(--spacing-zero);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      z-index: 3;
    }

    .input-field {
      padding-inline-start: var(--spacing-xs);
    }
  }

  &__draggable {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .input-tooltip {
    &.warning {
      .input-warning-tooltip {
        color: var(--content-color-warning);
      }
    }

    &.error {
      .input-warning-tooltip {
        color: var(--content-color-error);
      }
    }

    &.message {
      .input-warning-tooltip {
        color: var(--content-color-primary);
      }
    }
  }

  .input-warning-tooltip {
    max-width: 400px;
    display: none;
    position: absolute;
    top: 32px;
    left: 45px;
    font-size: var(--text-size-xs);
    background-color: $keyvalue-border-color;
    font-family: var(--text-family-primary);
    padding: var(--spacing-xs) var(--spacing-s);
    border-radius: var(--border-radius-default);
    font-weight: var(--text-weight-medium);
    z-index: 1000;

    @include material-shadow-1;

    &:before {
      content: ' ';
      position: absolute;
      color: transparent;
      width: 0;
      height: 0;
      top: -7px;
      left: 0px;

      // @todo non-uniform spacing because of inconsistencies with design tokens
      border: 6px var(--border-style-solid);
      border-bottom-color: $keyvalue-border-color;
      border-left-color: $keyvalue-border-color;
    }
  }

  &:hover:not(.row-focused) {
    .input-warning-tooltip {
      display: flex;
      align-items: center;
    }

    .key-value-form-column {
      &:last-child {
        .auto-suggest-cell {
          padding-right: 30px !important;
        }
      }
    }

    &.is-file-enabled {
      .key-value-form-column {
        &:first-child {
          .auto-suggest-cell {
            padding-right: 60px !important;
          }
        }
      }
    }

    .key-value-form-row__delete,
    .key-value-form-row__types,
    .key-value-form-row__sensitive-var-options,
    .key-value-form-row__deep-link {
      opacity: 1;
    }
    .key-value-form-row__deep-link {
      visibility: visible;
    }

    .always-present {
      & i{
        margin-right: 6px;
      }

      & span{
        border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }

    .key-value-form-row__sort {
      display: flex;
    }
    .key-value-form-row__add-new-row {
      display: flex;
    }

    background-color: transparent;
  }

  &.row-focused {
    background-color: $keyvalue-row-active-color !important;

    & .variable-type-dropdown {
      & .aether-dropdown__control{
        background-color: $keyvalue-row-active-color !important;
      }
    }

    & .options-dropdown {
      & .aether-dropdown__control{
        background-color: $keyvalue-row-active-color !important;
      }
    }
  }

  .is-focused {
    & input {
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        background: $app-background-color;
        height: var(--size-s);
        padding-left: var(--spacing-xs);
        margin-top: var(--spacing-xs);
        margin-right: var(--spacing-s);
        padding-inline-start: var(--spacing-zero);
      }
  }

  &.row-selected {
    background-color: $keyvalue-selected-rows-color;
  }

  .auto-suggest-group {
    flex: 1;
    min-width: 0;

    .inline-comment-available {
      .auto-suggest, .key-value-cell {
        &.is-comment-enabled:hover {
          border: none;
          @include clickable;
        }
      }

      &__focused {
        .auto-suggest, .key-value-cell {
          &.is-comment-enabled:hover {
            border: none;
            @include clickable;
          }
        }
      }
    }

    .auto-suggest {
      margin: var(--spacing-xs) var(--spacing-zero);

      &.is-comment-enabled:hover {
        border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
        margin: var(--spacing-zero);
        padding: 3px var(--spacing-zero);
      }

      &.is-comment-enabled {
        &__light:hover {
          cursor: url('../assets/images/thumbnails/comment-cursor-dark.svg'), pointer;
        }

        &__dark:hover {
          cursor: url('../assets/images/thumbnails/comment-cursor-light.svg'), pointer;
        }
      }

      &.is-comment-disabled:hover {
        cursor: not-allowed;
      }
    }

    .key-value-cell {
      &.is-comment-enabled:hover {
        border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
        margin: var(--spacing-zero) -1px;
      }

      &.is-comment-enabled {
        &__light:hover {
          cursor: url('../assets/images/thumbnails/comment-cursor-dark.svg'), pointer;
        }

        &__dark:hover {
          cursor: url('../assets/images/thumbnails/comment-cursor-light.svg'), pointer;
        }
      }

      &.is-comment-disabled:hover {
        cursor: not-allowed;
      }
    }

    .inline-comment-available {
      .auto-suggest-group {
        .auto-suggest, .key-value-cell {
          .is-comment-enabled:hover {
            border: none;
            @include clickable;
          }
        }
      }
    }

    .last-column{
      border-right: unset !important;
    }

    .is-first-column:nth-child(1) {
      border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }

    .key-value-form-column {
      position: relative;
      box-sizing: border-box;
      min-width: 0;
      border-right: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);

      &.variable-type-dropdown {
        & .aether-dropdown__control{
          border: none;
          width: 100%;
          box-shadow: none;
        }
      }

      &.options-dropdown {
        & .aether-dropdown__control{
          border: none;

          // We don't want dropdown icon to overlap with the delete button
          width: 80%;
          box-shadow: none;
        }
      }

      &.is-non-editable {
        background-color: $keyvalue-non-editable-column-bg;

        .auto-suggest, .key-value-cell, .aether-dropdown__single-value--is-disabled {
          color: $keyvalue-non-editable-text;
        }

        .aether-dropdown__control--is-disabled {
          background-color: $keyvalue-non-editable-column-bg;
        }
      }

      &__sessionValue{
        display: flex;
        justify-content: space-between;

        & .key-value-cell {
          width: 100%;
        }
      }

      & .input-field input {
        overflow:hidden;
        text-overflow: ellipsis;
        display: block;
        padding-left: var(--spacing-xs);
      }

      .is-focused {
        .DraftEditor-editorContainer {
          border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        }

        .DraftEditor-root {
          background: $app-background-color;
        }

      }

      .public-DraftEditorPlaceholder-root {
        width: 100%;
        color: $keyvalue-placeholder-color;
      }

      .public-DraftEditorPlaceholder-inner {
        white-space: nowrap !important;
        line-height: var(--line-height-m);

        // @todo non-uniform spacing because of inconsistencies with design tokens
        padding: 2px var(--spacing-zero);

        @include text-truncate-ellipsis;
      }

      .DraftEditor-editorContainer, .public-DraftEditorPlaceholder-root {
        // @todo non-uniform spacing because of inconsistencies with design tokens
        padding: 3px var(--spacing-xs);
        border: var(--border-width-default) var(--border-style-solid) transparent;
        box-sizing: border-box;

      }

      .public-DraftEditor-content, .public-DraftEditorPlaceholder-root {
        font-size: $keyvalue-row-input-font-size;
        font-family: $font-primary;
      }

      .DraftEditor-root {
        width: 100%;
      }
    }
  }

  .key-value-form-actions {
    position: relative;
    display: flex;

    // @todo non-uniform spacing because of inconsistencies with design tokens
    width: 44px;
    justify-content: flex-end;
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-right: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);

    &.is-disabled {
      background-color: $keyvalue-non-editable-column-bg;
    }

    &.error {
      .feedback-state {
        width: 3px;
        background-color: var(--background-color-error);
        color: var(--content-color-error);
      }
    }

    &.message {
      .feedback-state {
        width: 3px;
        background-color: var(--background-color-primary);
        color: var(--content-color-primary);
      }
    }

    .feedback-state {
      height: 30px;
      width: 0;
      position: absolute;
      background-color: $keyvalue-border-color;
    }

    &.hide-column{
      display: none;
    }
  }

  .key-value-form-actions.show-add-new-row-option {
    width: 72px;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-right: var(--spacing-xs);

    &.disabled {
      opacity: 0.4;
    }
  }

  &__add-new-row {
    display: none;

    .create-new__btn {
      width: var(--size-s);
      margin-right: 2px;
      margin-left: var(--spacing-xs)
    }
  }

  &__sort {
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: grab;

    margin-right: var(--spacing-xs);

    &__icon {
      @include unselectable;
    }

    @include hover {
      background-color: transparent;

      .key-value-form-row__sort__icon {
        cursor: grab;

        &__disabled {
          cursor: not-allowed;
        }
      }
    }

    @include active {
      .key-value-form-row__sort__icon {
        cursor: grabbing;
        // set the fill
      }
    }
  }

  &.is-dragged {
    opacity: 0.1;
  }

  &.is-drop-hovered-top {
    position: relative;

    &::before {
      background-color: var(--base-color-brand);
      top: 0;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      z-index: 1;
    }
  }

  &.is-drop-hovered-bottom {
    position: relative;

    &::before {
      background-color: var(--base-color-brand);
      bottom: 0;
      content: ' ';
      position: absolute;
      height: 2px;
      width: 100%;
      z-index: 1;
    }
  }

  &.is-disabled,
  &.is-blocked {
    .auto-suggest, .key-value-cell, input, .aether-dropdown__single-value--is-disabled {
      color: $keyvalue-disabled-text;
    }


    .key-value-form-column {
      &.is-non-editable {
        .auto-suggest, .key-value-cell, input, .aether-dropdown__single-value--is-disabled{
          color: $keyvalue-disabled-text;
        }
      }
    }
  }

  &.is-system {
    &.is-blocked {
      background-color: var(--background-color-secondary);

      .auto-suggest, .key-value-cell {
        color: var(--content-color-primary);
      }
    }

    &.is-disabled {
      .auto-suggest, .key-value-cell {
        color: $keyvalue-disabled-text;
      }
    }
  }

  &__controls {
    flex: 0 0 $keyvalue-row-height + 25;
    display: flex;
    align-items: center;
    justify-content:center;
  }

  &__modification-options{
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--background-color-primary);
  }

  &__sensitive-var-options{
    display: flex;
    opacity: 0;

    & span{
      border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }

    & i{
      margin-right: 6px;
      margin-left: 6px;
      margin-top: 2px;
    }
  }

  .sensitive-options-hide-icon {
    margin-top: var(--spacing-s);
    width: var(--size-s);
    height: var(--size-s);
  }

  .always-present{
    opacity: 1;

    & span{
      border-right-color: transparent;
    }
  }

  &__vault {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-xs);

    &__icon {
      @include unselectable;
      @include clickable;
    }
  }

  &__delete, &__types {
    display: flex;
    opacity: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__icon {
      @include unselectable;
      @include clickable;
    }
  }

  &__deep-link {
    position: absolute;
    right: 0;
    line-height: var(--line-height-xs);
    height: $keyvalue-row-height;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    background: var(--background-color-secondary);
    color: var(--content-color-secondary);

    &__icon {
      padding-right: var(--spacing-xs);
      @include icon-color(var(--content-color-secondary));
    }
  }


  @include hover {
    .key-value-form-row__action {
      display: block;
    }
  }

  &__type-select-container {
    display: flex;
    flex: 0 0 60px;
    z-index: 1;
    border-left: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);

    .dropdown-button {
      display: flex;
      align-items: center;
    }

    .dropdown-menu {
      min-width: 50px;
      margin-top: var(--spacing-zero);
    }

    .dropdown-button {
      flex: 1;

      .key-value-form-row__type-select {
        font-weight: var(--text-weight-regular);
        display: flex;
        gap: var(--spacing-xs);
        padding: var(--spacing-s);
        flex: 1;
        color: var(--content-color-primary);
        height: $keyvalue-row-height;
        justify-content: space-between;
        align-items: center;
        font-family: $font-primary;
        cursor: pointer;
        font-size: var(--text-size-s);

        @include icon-color(var(--content-color-tertiary));
      }

      .is-last-row {
        color: var(--content-color-tertiary);
      }
    }
  }

  &__modal{
    &-header{
      line-height: var(--line-height-s);
      text-transform: uppercase;
    }

    &-content{
      overflow: visible;
      line-height: var(--line-height-m);
      padding: var(--spacing-xs) var(--spacing-xl) var(--spacing-zero);
    }

    &-action{
      padding-top: var(--spacing-m)
    }
  }
}
