.media-balloon-container {
  position: absolute;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-width-xl);
  background: var(--background-color-primary);
  z-index: 2;
  padding: var(--spacing-m) !important;
  align-items: center;
  color: var(--content-color-link);
  display: flex;
  width: 350px;

  button {
    white-space: nowrap;
  }

  &.small {
    width: 255px;
  }

  &.auto {
    width: auto;
    padding: var(--spacing-zero) !important;
  }

  input:disabled + label[for*="mimir-file-uploader"] {
    opacity: 0.5;
  }

  label[for*="mimir-file-uploader"] {
    margin-top: var(--spacing-zero);
    color: var(--content-color-constant);
    background-color: var(--base-color-brand);
    height: 38px;
    line-height: 36px;
    font-weight: bold;
  }

  label[for*="mimir-file-uploader"] + div {
    display: none;
  }

  .media-url-input {
    flex-grow: 1;
    background: var(--background-color-secondary);
    border: 1px solid var(--content-color-tertiary);
    border-radius: var(--border-width-xl);
    padding: 3px var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: 18px;

    &::placeholder {
      color: var(--content-color-tertiary);
    }
  }

  .text-button {
    cursor: pointer;
    font-size: var(--text-size-m);
    height: var(--size-s);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px var(--spacing-xs) !important;
    margin: 0px 0px 0px var(--spacing-s) !important;
    color: var(--content-color-secondary) !important;

    &.text-button--disabled {
      color: var(--content-color-tertiary) !important;
      cursor: not-allowed;
    }

    svg,
    path {
      fill: var(--content-color-secondary) !important;
    }

    svg {
      color: var(--content-color-secondary) !important;
    }
  }

  .icon-button {
    height: var(--size-s);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-size-m) !important;
    line-height: 18px !important;
    cursor: pointer;
    margin: 0px var(--spacing-s) !important;
    svg,
    path {
      fill: var(--content-color-secondary) !important;
    }

    svg {
      color: var(--content-color-secondary) !important;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      display: none;
    }
    100% {
      opacity: 1;
      display: block;
    }
  }
}
