$bullet-size: 8px;

.inactive-mock-indicator {
  width: $bullet-size;
  height: $bullet-size;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  background-color: var(--content-color-tertiary);

  &__sidebar_fixed {
    margin-right: var(--spacing-xxxl);

  &.use-browser-tabs {
    margin-right: 56px;
  }

  }

  &__banner {
    display: flex;
    flex: 1;
    background-color: var(--background-color-secondary);
    justify-content: left;
    align-items: center;
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    line-height: var(--line-height-m);
    padding: var(--spacing-l);
    max-height: 42px;
    box-sizing: border-box;

    &__icon {
      margin-right: var(--spacing-s);
    }
  }
}
