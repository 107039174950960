.sidebar-discoverable-teams-widget {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l);

  &-bottom-divider {
    border-top: var(--border-width-default) var(--border-style) var(--border-color-default);
    margin: var(--spacing-xxl) 0 0 0;
    height: 1px;
    width: 100%;
  }
}

.sidebar-discoverable-teams {
  margin: var(--spacing-s) 0 var(--spacing-l);
  padding: 0;
  & > * + * {
    margin-top: var(--spacing-xs);
  }
  &--team {
    display: flex;
    list-style-type: none;
    padding: var(--spacing-s) var(--spacing-m);
    height: auto;
    background: var(--background-color-tertiary);
    box-sizing: border-box;
    border-radius: var(--border-radius-default);
    align-items: center;
    transition: background 0.2s ease-in-out;
    width: 100%;

    &:hover button {
      opacity: 1;
      pointer-events: auto;
    }

    &:hover div {
      max-width: 100px;
    }
  }
  &--team-icon {
    width: var(--size-s);
    object-fit: contain;
  }
  &--team-name {
    @include text-truncate-ellipsis;
    font-size: var(--text-size-m);
    margin-left: var(--spacing-m);
    color: var(--content-color-primary);
    max-width: 164px;
  }
  &--team-count {
    @include text-truncate-ellipsis;
    font-size: var(--text-size-s);
    margin-left: var(--spacing-m);
    color: var(--content-color-secondary);
    max-width: 164px;
  }
  &--team-join-button {
    margin-left: auto;
    opacity: 0;
    pointer-events: none;
  }
}
