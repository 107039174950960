
.loading-team-integrations {
  padding: calc(var(--spacing-xl) * 0.5) calc(var(--spacing-xl) * 0.5) var(--spacing-xxl) calc(var(--spacing-xl) * 0.5);
  overflow: hidden;


  background-color: var(--background-color-primary);

  @media (min-width: 768px) {
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xxl) var(--spacing-xl);
  }

  &__list {
    @include unstyled-list;
    display: flex;
    flex-wrap: wrap;
    animation: fade 1s ease-in-out infinite;


    padding: 0 0 var(--spacing-l) 0;
    padding-left: var(--spacing-m);

    li {
      margin-bottom: var(--spacing-l);
      display: inline-flex;
      flex-direction: column;
      border: solid thin var(--border-color-default);
      border-radius: var(--border-radius-default);
      box-sizing: border-box;

      width: 288px;
      margin: var(--spacing-m);
      height: 130px;
      padding: var(--spacing-xl);
      &:hover {
        border: 1px solid var(--border-color-strong);
      }
    }
  }

  &__service-icon-wrapper {
    height: 16px;
    width: 16px;
    border-radius: var(--border-radius-default);
    background: var(--background-color-secondary);
    position: relative;
    background-color: var(--background-color-tertiary);
  }

  &__service-icon {
    transform: translateX(-50%) translateY(-50%);
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--background-color-tertiary);

    background: center / contain no-repeat;
  }

  &__service-name {
    background-color: var(--background-color-tertiary);
    height: 16px;
    margin-left: var(--spacing-s);
    margin-right: var(--spacing-l);
  }

  &__rule-details {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  &__rule-details &__service-details {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__service-description {
    overflow: hidden;
    margin-bottom: var(--spacing-xxl);
    height: 24px;
    background-color: var(--background-color-tertiary);
  }

  &__status-item {
    height: 16px;
    background-color: var(--background-color-tertiary);
  }
  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }

}
