/*These two variables are legacy font-variables and must not be used*/
$font-primary: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif !default;
$font-monospaced: 'IBMPlexMono', 'Cousine', monospace !default;

:root {
/* DEPRECATED: The design tokens mentioned below are deprecated and will be removed in the upcoming releases.
   It is recommended to use the tokens defined above. */

  --text-font-regular: 'Inter', 'OpenSans', Helvetica, Arial, sans-serif;
  --text-font-code: 'IBMPlexMono', 'Cousine', monospace;

  --text-font-size-xs: 10px;
  --text-font-size-s: 11px;
  --text-font-size-m: 12px;
  --text-font-size-l: 14px;
  --text-font-size-xl: 16px;
  --text-font-size-xxl: 18px;
  --text-font-size-xxxl: 24px;

  --text-line-height-xs: 12px;
  --text-line-height-s: 16px;
  --text-line-height-m: 20px;
  --text-line-height-l: 24px;
  --text-line-height-xl: 28px;
  --text-line-height-xxl: 32px;
  --text-line-height-xxxl: 40px;

  --text-font-weight-regular: 400;
  --text-font-weight-medium: 600;
  --text-font-weight-bold: 600;
}
