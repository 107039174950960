.add-environment-template {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
}

.add-environment-template__header {
  display: flex;

  .add-environment-template__header__title {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.add-environment__header__title {
  font-size: 13px;
  font-weight: 600;
}

.add-environment-template__section-name {
  display: flex;
  margin: 10px 0;
  align-items: flex-end;
}

.add-environment-template__section-name-extension {
  padding-left: 5px;
}

.add-environment-template__section-values {
  flex: 1;
  height: 0;
  overflow-y: auto;
}

.add-environment-template__section-controls {
  display: flex;
  flex-direction: row-reverse;
}

.add-environment-template__section-controls__cancel {
  margin-right: 10px;
}

.environment-template-newtemplate-wrapper {
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  border-top: 1px solid var(--border-color-default);
}

.environment-template-modal-empty-view-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}

.environment-template-modal-empty-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.environment-template-modal-loading-container {
  left: 47%;
  position: absolute;
  top: 5%;
}

.environment-template-modal-empty-view-content-header {
  padding: 20px 0 10px;
  font-size: 18px;
}

.environment-template-modal-empty-view-content-text {
  max-width: 310px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: var(--content-color-secondary);
  text-align: center;
}

.environment-template-modal-list-empty-view-content-text {
  margin-bottom: 10px;
  color: var(--content-color-secondary);
  text-align: center;
}

.environment-template-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  max-width: 80%;

  .environment-template-modal-empty-view-content-header {
    font-size: 12px;
  }

  .learnmore-link {
    margin-bottom: 32px;
  }

  .signout-out-signin-btn {
    font-size: 12px;
    max-width: 200px;
    align-self: center;
  }
}

.environment-template-modal-action-button {
  margin-top: 10px;
  align-self: center;
  min-width: 200px;
}
