$empty-state-path: '../assets/images/empty-state/' !default;
$mock-empty-url: $empty-state-path + 'mock_cloud.svg';

.entity-empty {
  .entity-empty__illustration-wrapper--mocks {
    .mocks-empty__illustration {
      @include icon(
        $mock-empty-url,
        115px,
        95px
      )
    }
  }
}
