.custom-tooltip {
  display: inline;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
  color: var(--content-color-secondary);

  &__body div {
    margin-bottom: var(--spacing-zero);
  }

  &__action {
    float: right;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
    margin-right: var(--spacing-xl);
  }

  span {
    @include text-truncate-ellipsis;
    font-size: var(--text-size-m);
  }

  .pm-icon {
    margin-right: var(--spacing-xs);
  }
}
