/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$diff-added-background-color: var(--background-color-success);
$diff-removed-background-color: var(--background-color-error);
$diff-added-font-color: var(--content-color-success);
$diff-removed-font-color: var(--content-color-error);
$diff-normal-color: var(--content-color-primary);
$diff-overlay-color: var(--background-color-primary);
$diff-line-number-container: var(--background-color-tertiary);
$diff-line-number-color: var(--content-color-secondary);

.diff-overlay-wrapper {
  display: flex;
  min-height: 100%;

  .diff-char {
    padding: 20px;
  }
}

.diff-view-modal-content {
  padding: 0;
}


.diff-line {
  display: flex;
  align-items: center;
}

.diff-wrapper {
  padding-top: 10px;
  margin: 0;
  overflow: visible;
  font-size: 12px;
  border-spacing: 0 1px;
  flex: 1;

  &.is-overlayed {
    padding: 2px;
    overflow: hidden;
  }

  .diff-normal {
    color: $diff-normal-color;
    background: transparent;
  }

  .diff-added {
    margin: 1px 0;
    color: $diff-added-font-color;
    background-color: $diff-added-background-color;
  }

  .diff-removed {
    color: $diff-removed-font-color;
    background-color: $diff-removed-background-color;
  }

  .diff-text-wrapper {
    height: 15px;
    margin: 1px 0;
    line-height: 15px;
  }

  .diff-text-line {
    margin-right: 20px;
  }
}

.is-expandable {
  position: relative;
  min-height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: all linear 0.1s;

  @include hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:before {
      bottom: 0;
    }
  }

  &:before {
    position: absolute;
    right: 0;
    bottom: -40px;
    left: 0;
    z-index: 1;
    display: block;
    width: 100px;
    height: 25px;
    margin: 10px auto;
    font-size: 10px;
    line-height: 25px;
    color: var(--content-color-constant);
    text-align: center;
    cursor: pointer;
    content: 'Click to expand';
    background: rgba(0,0,0,0.4);
    border-radius: 25px;
    transition: bottom cubic-bezier(0.22, 0.61, 0.36, 1) 0.1s;
  }
}

.diff-lines-numbers-container {
  display: flex;
  padding: 10px 0px 20px 0;
  background: $diff-line-number-container;
}

.diff-line-numbers-wrapper {
  display: flex;
  flex-direction: column;
  width: 30px;
  color: $diff-line-number-color;
  justify-content: flex-start;
  align-items: center;
}

.diff-line-numbers {
  height: 14px;
  padding: 1px 5px;
  margin: 0;
}
