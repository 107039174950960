/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.regex-list {
  width: 100%;

  .rt-thead {
    height: var(--size-m);
    background: var(--background-color-primary) !important;

    &.-header {
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }
  }

  .rt-tbody {
    .rt-td {
      padding: var(--spacing-xs) var(--spacing-s);
      min-height: 44px;

      .token-scanner-table-name {
        display: contents;
        font-size: var(--text-size-m);
      }
    }
  }

  .rt-tr {
    &:hover {
      .regex-list {
        &__edit-btn, &__delete-btn {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__edit-btn, &__delete-btn {
    visibility: hidden;
    opacity: 0;
  }
}

.default-regex-table {
  .table-row {
    height: 44px;
    padding: var(--spacing-m) var(--spacing-s);
    margin-bottom: var(--spacing-zero);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .infinite-loader {
      height: 20px;
      background-color: var(--background-color-tertiary);
      animation: fade 1s linear infinite;
      border-radius: 4px;
    }
  }
  .table-header {
    height: 32px;
    padding: var(--spacing-s);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.token-scanner-add-form {
  .scan-option {
    gap: var(--spacing-s);
  }
  .scan-option-dry-run {
    margin-top: var(--spacing-s);
  }
  .scan-option-add-pattern, .scan-option-dry-run {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.dry-run-results {
  height: calc(100vh - 137px);
  overflow-y: scroll;

  &.full-height {
    height: calc(100vh - 48px);
  }

  .bottom-bar {
    position: fixed;
    bottom: 0;
    background-color: var(--background-color-primary);
    padding: var(--spacing-xl) var(--spacing-zero) var(--spacing-xxl) var(--spacing-zero);
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    &.light-theme {
      box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 8px -4px;
    }
    &.dark-theme {
      box-shadow: rgba(255, 255, 255, 0.2) 0px -2px 8px -4px;
    }
  }
  .leaks-listing-container {
    .empty-secrets-container {
      i > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .leak-details-sticky-header {
    padding-bottom: var(--spacing-l);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.token-scanner-edit-form {
 overflow: auto;
}

.secret-info-popover {
  width: 300px;
  background: var(--background-color-primary);
  border-radius: 5px;

  .block-info {
    &__hash-value, &__hash-value:hover {
      background-color: var(--background-color-secondary);
      border: none;
      box-shadow: none;
    }
    &__copy {
      color: var(--content-color-secondary);

      &--copied {
        &:hover {
          i svg {
            color: var(--base-color-success);
          }
        }

        i svg {
          color: var(--base-color-success);
        }
      }
    }
  }
}

.leak-details-page {
  .element-filter {
    div[class^="FilterSelect__StyledFilterSelectPlaceHolder-"] {
      color: var(--content-color-primary);
    }
  }
}

.aether-filter-select-dropdown__menu-list {
  &.invisible {
    max-height: 150px;
    .aether-filter-select-dropdown__option, .aether-filter-select-dropdown__menu-notice {
      display: none;
    }
  }
  &.visible {
    visibility: visible;
    max-height: 250px;
  }
}

.infinite-load-spinner .spinner-container{
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  height: 16px;
  width: 16px;

  & > div {
    height: 100%;
    width: 4px;
    opacity: 0.2;
    -webkit-animation: gwQIXW 0.6s infinite ease-in-out;
    animation: gwQIXW 0.6s infinite ease-in-out;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    background-color: var(--content-color-tertiary);
  }

  .rect-two {
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }

  .rect-three {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
}
