.monitors-dropdown-menu {
  --dropdown-width: 220px;
  min-width: var(--dropdown-width);

  .dropdown-menu-item {
    padding: 0 var(--spacing-l);

    .dropdown-menu-item-icon {
      margin-right: var(--spacing-s);
    }
  }

  .mock-action-item {
    display: flex;
    flex: 1;
  }
}
