.sync-token-confirmation-modal {
  .sync-token-confirmation-modal__controls {
    display: flex;
    padding-top: 20px;
    flex-direction: row-reverse;
    align-items: center;

    .btn {
      &.is-focused {
        border: 1px solid $modal-button-focus-border-color;
      }
    }

    .sync-token-confirmation-modal__confirm-button {
      flex: 1;
      margin-left: var(--spacing-s);
      background: var(--base-color-brand);
    }
  }
}
