.collection-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);
  align-items: center;

    &.has-hoverable-collection-label {
      // Added max width to contain extra long fork/import labels and avoid
      // hiding collection names
      margin-left: var(--spacing-zero);
      flex-shrink: 0.2;
      .collection-sidebar-list-item__head__name-icon__wrapper & {
        max-width: 50%;
      }

      .collection-sidebar-fork-label {
        // Added min width to fork icon and label to keep them from being hidden
        // when collection name is long
        min-width: 25%;
        @include text-truncate-ellipsis;
      }
    }

  .collection-meta-icon {
    margin-right: var(--spacing-xs);

    .collection-sidebar-list-item__head__meta & {
      @include icon-color(var(--content-color-tertiary));
    }

    &.collection-sidebar-fork-label {
      display: flex;
      height: var(--size-xs);
      align-items: center;
    }

    &.fork-label {
      .entity-tab-header & {
        display: flex;
        min-width: 0;
      }
    }
  }
}
