$collection-sidebar-list-item-request-height: 28px;
$collection-sidebar-list-item-request-label-wrapper-width: 50px;


.collection-sidebar-list-item__folder {
  display: flex;
  flex-direction: column;

  .collection-sidebar-list-item__request__head {
    flex: 0 0 auto;
  }
}

.collection-sidebar-list-item__request__head {
  display: flex;
  flex-direction: row;
  height: $collection-sidebar-list-item-request-height;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--global-navigation-background-color);


  @include hover {
    background-color: var(--highlight-background-color-secondary);
  }

  .collection-sidebar-list-item__request__label-wrapper {
    flex: 0 0 28px;
    margin: var(--spacing-zero) var(--spacing-xs);
  }

  .collection-sidebar-list-item__request__meta {
    flex: 1;
  }

  .collection-sidebar-list-item__request__actions {
    flex: 0 0 var(--size-s);
  }

  .collection-sidebar-list-item__request__name {
    line-height: var(--line-height-m);
    padding-right: var(--spacing-xs);
    @include text-truncate-ellipsis;
  }
}

.collection-sidebar-list-item__request__head {
  &.is-pending {
    .collection-sidebar-list-item__request__actions {
      visibility: visible;
    }
  }

  @include hover {
    &:not(.is-multi-selection-active) {
      .collection-sidebar-list-item__request__actions {
        visibility: visible;
      }
    }
  }

  &.is-selected {
    &.is-sidebar-in-focus {
      .collection-sidebar__indent {
        &.active-indent {
          border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
        }
      }
    }
  }

  &.is-active {
    background-color: var(--highlight-background-color-secondary);
  }


  &.is-focused {
    &.is-sidebar-in-focus {
      box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
    }
  }

  &.is-selected {
    background-color: var(--highlight-background-color-primary);
    &.is-sidebar-in-focus {
      background-color: var(--highlight-background-color-tertiary);
      box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset;
      &.is-focused {
        box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset, 0px 0px 0px 2px var(--blue-30) inset;
      }
    }
  }

  // We are temporarily disabling this until we figure out all the edge cases associated with cut state.
  // &.is-cut {
  //   opacity: 0.5;
  // }

  &.is-drop-hovered-top {
    &:before {
      left: 0;
      background-color: var(--base-color-brand);
    }
  }

  &.is-drop-hovered-bottom {
    &:before {
      left: 0;
      background-color: var(--base-color-brand);
    }
  }

  // &.is-dragged {
  //   opacity: 0.1;
  // }

  &.is-drop-hovered-top {
    box-shadow: 0 -2px 0 var(--base-color-brand);
  }

  &.is-drop-hovered-bottom {
    box-shadow: inset 0px -2px var(--base-color-brand);
  }

  &.is-right-above {
    box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
  }

  @include unselectable;
  @include clickable;

  &.is-hidden {
    display: none;
  }
}

.collection-sidebar-list-item__request__actions {
  visibility: hidden;
}

.collection-sidebar-list-item__request__label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  &.next-entity-padding {
    padding-top: 3px !important;
  }

  &.folder-accordian-padding {
    padding-top: 1px !important;
  }
}

.collection-sidebar-list-item__request__label {
  @include request-method-label;
  color: var(--content-color-primary);
  text-align: left;
  font-size: 9px;
  font-weight: var(--text-weight-bold);
}

.collection-sidebar-list-item__request__meta {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-family: $font-primary;
  font-size: var(--text-size-m);
  font-weight: 400;
  color: var(--content-color-primary);
  align-items: center;
}

.collection-sidebar-request-dropdown-actions-wrapper {
  .btn {
    width: var(--size-s);
    height: var(--size-s);
    padding: var(--spacing-zero);

    .collection-sidebar-list-item__request__actions & {
      .collection-sidebar-request-dropdown-actions-button {
        @include icon-color(var(--content-color-secondary));
      }

      @include hover {
        background-color: var(--highlight-background-color-tertiary);

        .collection-sidebar-request-dropdown-actions-button {
          @include icon-color(var(--content-color-primary));
        }
      }
    }
  }
}
