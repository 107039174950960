.monitor-job {
  color: var(--content-color-primary);

  .loading-indicator-wrapper {
    margin: auto;
  }

  .not-allowed-state {
    padding: var(spacing-xxl) 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__text{
      margin-top:var(--spacing-xl);
      margin-bottom: var(--spacing-xl);
    }
  }

  line-height: initial;
  font-size: var(--text-size-m);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l);

  &__header {
    margin-top: var(--spacing-l);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    // @todo move this to monitor common
    .monitor-performance__region-selector {
      // overriding the width and margins specified in _performance.scss
      width: auto;
      margin-right: 0;

      label {
        display: none;
        margin: var(--spacing-xs) 0;
      }
    }

    small {
      margin-left: var(--spacing-s);
      color: var(--content-color-primary);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
    }

    @media (min-width: $breakpoint-md) {
      .monitor-performance__region-selector {
        label {
          display: block;
        }
      }
    }
  }

  &__summary--error {
    display: flex;
    align-items: center;
  }

  &__summary {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    line-height: var(--line-height-xxl);
    padding-right: 80px;
    @include text-truncate-ellipsis;

    &-message {
      margin: var(--spacing-zero);
      small {
        margin-left: var(--spacing-zero);
      }
    }

    &-error {
      color: var(--content-color-error);
    }

    &-success {
      color: var(--content-color-success)
    }
  }

  &__actions {
    margin: var(--spacing-s) 0;
    position: relative;
    border-bottom: var(--border-width-default) solid var(--border-color-strong);
    align-self: flex-start;

    .tabs {
      .tab-primary {
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);

        &.is-active {
          color: var(--content-color-primary);
        }
      }
    }
  }

  &__empty-card {
    color: var(--content-color-primary);
    margin: var(--spacing-xxxl) 0;
    text-align: center;

    .empty-state__body {
      margin-top: var(--spacing-xxl);
      margin-bottom: var(--spacing-s);
      font-size: var(--text-size-m);
    }
  }

  &__loader {
    display: block;
    margin: 256px auto;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .pm-row {
    overflow-x: visible;
  }
}

.empty-state__image {
  &.run {
    @include icon("../assets/images/empty-state/run.svg", 133px, 99px);
  }

  &.monitor {
    @include icon("../assets/images/empty-state/monitor.svg", 160px, 113px);
  }

  &.warning {
    @include icon("../assets/images/empty-state/warning.svg", 133px, 133px);
  }

  &.no-passes {
    @include icon("../assets/images/empty-state/no-passes.svg", 132px, 84px);
  }

  &.no-failures {
    @include icon("../assets/images/empty-state/no-failures.svg", 132px, 84px);
  }

  &.abort {
    @include icon("../assets/images/empty-state/abort.svg", 133px, 133px);
  }

  margin: auto;
  max-width: 160px;
  margin-bottom: var(--spacing-xl);
}

.split-selector-label {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
