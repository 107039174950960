$not-found-thumbnail-url: "../assets/images/thumbnails/browse-view-offline.svg";

.documentation-error-message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &--container {
    display: flex;
    width: calc(10 * var(--size-m));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xxl);
  }

  &--title {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-s);
    text-align: center;
  }

  &--content {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
    text-align: center;
  }

  &--thumbnail {
    height: 200px;
    width: 200px;
    background-size: contain;
    margin-bottom: var(--spacing-l);
    background-image: url($not-found-thumbnail-url);
  }

  &--link {
    margin-left: var(--spacing-xs);
  }

  &--refresh-button {
    margin-top: var(--spacing-s);
  }
}

.documentation-usage-error {
  border-top: var(--border-width-default) var(--border-style) var(--border-color-default);
  border-bottom: var(--border-width-default) var(--border-style) var(--border-color-default);
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--title {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    text-align: center;
  }

  &--meta {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
    margin: var(--spacing-s) var(--spacing-zero);
    text-align: center;
  }
}
