.integrations-run-actions {
  &__container {
    display: flex;
  }
  &__button {
    &--edit {
      margin-right: var(--spacing-s);
      border-radius: var(--border-radius-s);
    }
    &--delete {
      border-radius: var(--border-radius-s);
    }
    &--modal-delete {
      border-radius: var(--border-radius-s);
      background-color: var(--base-color-error);
    }
    &--modal-delete:hover {
      background-color: var(--base-color-error);
    }

    &--refresh {
      margin-right: var(--spacing-s);
      border-radius: var(--border-radius-s);
    }
    &--cancel {
      border-radius: var(--border-radius-s);
    }
  }
}