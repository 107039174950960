.workspace-activity-feed-error-state {
  text-align: center;
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  color: var(--content-color-primary);
  margin-top: 10vh;
  overflow-y: auto;
  line-height: var(--line-height-m);

  &__image {
    margin: auto;
  }

  &__label {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
  }

  &__description {
    margin-top: var(--spacing-s);
  }

  &__refresh-btn {
    margin-top: var(--spacing-l);
  }
}
