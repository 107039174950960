.cookies-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  .cookies-label-text {
    margin-left: var(--spacing-xs);

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
}

