$es-image-width: 160px;

.empty-state {
  text-align: center;

  &__image {
    max-width: $es-image-width;
    margin-bottom: var(--spacing-xl);
  }

  &__title {
    font-size: var(--text-size-l);
    color: var(--content-color-primary);
  }

  &__body {
    line-height: 20px;
    color: var(--content-color-primary);
    margin: var(--spacing-l) auto var(--spacing-xxl);
    font-size: var(--text-size-m);
  }

  &__action {
    margin-bottom: var(--spacing-xl);
  }

  &__crash-handler {
    margin-top: 200px;

    .empty-state__body {
      max-width: 500px;
    }
  }

  &__subtext {
    color: var(--content-color-secondary);
    font-size: var(--text-size-l);
  }
}
