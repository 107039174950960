$integration-empty-url: $thumbnail-path + 'integrations.svg';

.entity-empty {
  .entity-empty__illustration-wrapper--integrations {
    .integrations-empty__illustration {
      @include icon(
        $integration-empty-url,
        115px,
        95px
      )
    }
  }
}
