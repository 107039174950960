$resizer-thickness: 1px;
// changing it would require pane border obverlaping, require updation in few other props as well
$resizer-padding: 5px;

.pane-label {
  background-color: var(--background-color-primary);
  display: flex;
  align-items: center;
  @include unselectable;

  .pane-label__text {
    flex: 1;
    color: var(--content-color-tertiary);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    @include text-truncate-clip;

    span {
      @include clickable;
    }
  }

  .pane-label__actions {
    display: flex;
    align-items: center;
  }

  .pane-label__arrow {
    margin: var(--spacing-s);
    display: flex;
    align-items: center;
    @include clickable;

    i svg {
      color: var(--pane-chevron-color);
    }
  }

  &.vertical {
    width: var(--size-m);
    height: 100%;
    flex-direction: column-reverse;

    .pane-label__text {
      padding-top: var(--spacing-s);
      writing-mode: tb;
      transform: rotate(180deg);
      display: flex;
      align-items: center;
    }
  }

  &.horizontal {
    height: var(--size-m);

    .pane-label__text {
      margin-left: var(--spacing-s);
    }
  }
}

.pane-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}

.pane {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-height: 0;
  min-width: 0;

  position: relative;

  &.collapsedX {
    flex-direction: row;
  }
}

.pane-group {
  flex: 1;
  display: flex;
  min-height: 0;
  min-width: 0;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }

  .pane-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .pane-resize-handle {
      position: absolute;
      box-sizing: border-box;
      z-index: 8;

      .pane-resize-handle__body {
        background-color: var(--border-color-default);
      };

      &:hover,
      &:active {
        .pane-resize-handle__body {
          background-color: var(--blue-30);
        }
      }

      &.ew {
        padding: $resizer-padding 0;
        left: 0;
        right: 0;
        bottom: -$resizer-padding;
        cursor: ns-resize;

        .pane-resize-handle__body {
          height: $resizer-thickness;
          width: 100%;
        }
      }

      &.ns {
        padding: var(--spacing-zero) $resizer-padding;
        top: 0;
        bottom: 0;
        right: -$resizer-padding;
        cursor: ew-resize;

        .pane-resize-handle__body {
          width: $resizer-thickness;
          height: 100%;
        }
      }
    }
  }
}
