.api-definition-overview {
  gap: var(--spacing-m);
  width: fit-content;

  &__separator {
    height: 3px;
    width: 3px;
    border-radius: 50%;
    border: 1px transparent;
    background-color: var(--content-color-secondary);
  }

  &__item {
    min-width: max-content;
  }

  &__update{
    min-width: 100%;
  }
}

.api-definition-private {
  height: 100%;
  width: 100%;

  &__home {
    overflow-y: auto;
    height: 100%;
    width: 100%;
  }

  &__file{
    display: flex;
    height: 100%;
    width: 100%
  }

  &__file-container{
    width: 100%;
    overflow: hidden;
  }
}

.api-definition-private-schema-doc{
  width: 100%;
}

.api-definition-overview-icon{
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}
