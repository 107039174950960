
.team-integrations-header-icon-wrapper .btn {
  width: 150px;
}

.team-integrations-detail{
  &__header {
    display: flex;
    flex-direction: row;
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
  }
}
.team-integrations-detail__header-text {
  margin-left: var(--spacing-l);
  &--title {
    line-height: var(--line-height-xxxl);
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xxxl);
  }
  &--description {
    font-style: normal;
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-secondary);
  }
}

.team-integrations-detail__service-icon-wrapper {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding:var(--spacing-xs);
  align-items: center;
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
}

.team-integrations-detail__service-icon {
  height: auto;
  width: 100%;

}
.team-integrations-detail__fallback-icon {
  @include icon('../assets/images/thumbnails/integration-stroke.svg',56px,56px);
}

.team-integrations-breadcrumbs {
  padding: 0 var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.team-integrations-back-btn {
  color: var(--content-color-tertiary);
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.team-integrations-team-name {
  color: var(--content-color-tertiary);
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  word-break: break-all;
  padding-right: var(--spacing-s);
  cursor: pointer;

}

.team-integrations-integration-name {
  color: var(--content-color-tertiary);
  font-style: normal;
  font-weight: normal;
  padding-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}


.team-integrations-integration-page {
  color: var(--content-color-tertiary);
  font-style: normal;
  font-weight: normal;
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: pointer;
}

.team-integrations-detail {

  &__types-list {
    @include unstyled-list;
    width: 100%;

    display: inline-block;

    li {
      width: 100%;
      box-sizing: border-box;
      margin-left: var(--spacing-xl);
    }
  }

  &__types-item {

    border-radius: var(--border-radius-default);
    border: var(--border-width-default) solid var(--border-color-default);
    padding: var(--spacing-xl);
    margin:  var(--spacing-l) 0;

    @media (min-width: 768px) {
      padding: var(--spacing-xl)
    }
  }

  &__types-item-status {
    margin-bottom: var(--spacing-xl);
    cursor: pointer;

    // Unused style
    .pm-icon-error path {
      fill: var(--content-color-error);
    }

  }

  &__types-item-name-status {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

  }
  &__types-item-name-link {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);;
    color: var(--content-color-primary);
    text-decoration: none;
    margin-bottom: var(--spacing-xl);
  }

  &__types-item-info {
    padding: 0;

    & > h4 {
      margin: 0;
      font-weight: var(--text-weight-medium);
      display: inline-block;
      color: var(--content-color-brand);
    }

    & > p {
      color: var(--content-color-tertiary);
      font-size: var(--text-size-l);
      margin: var(--spacing-s) 0 0 0;
    }
  }

}

.team-integrations-detail-actionables {
  margin: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  .integrations-sort-search-title {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    padding-bottom: var(--spacing-xs);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
  }
  .integrations-sort-search-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1024px){
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .integrations-sort-search {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1024px){
      flex-direction: column;
      & > .team-integrations-search {
        padding-bottom: var(--spacing-l);
      }
      & > .team-integrations-filter {
        padding-bottom: var(--spacing-l);
      }
      padding-bottom: var(--spacing-l);
    }
  }

  .team-integrations-filter {
    width: 240px;
  }

  .team-integrations-search {
    width: 240px;
    .input-search-group {
      background-color: var(--background-color-primary);
      border-color: var(--content-color-tertiary);
      border-radius: var(--border-radius-default);
      height: var(--size-m);
    }
    .pm-form-control-sm {
      background: var(--background-color-secondary);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-sizing: border-box;
      border-radius: var(--border-radius-default);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      border: var(--border-width-default) solid var(--border-color-strong);
      padding: var(--spacing-zero) var(--spacing-xxl) var(--spacing-zero) var(--spacing-xxl);
    }
    .pm-form-control-sm {
      height: 40px;
    }

    .pm-icon-input .pm-icon.pm-icon-sm {
      margin: var(--spacing-m) var(--spacing-s);
    }
  }
}

.team-integrations-detail-footer {
  display: flex;
  justify-content: center;
  padding: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.team-integrations-sort-add {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-s);
  align-items: flex-end;
  .team-integrations-sort {
    width: 166px;
  }
}

.team-integrations-detail__entity {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  &-service{
    display: flex;
  }
  &-service-link {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);
    text-decoration: none;
    color: var(--content-color-secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    &--name {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &--error {
        color: var(--content-color-secondary);
        width: 180px;
        font-weight: var(--text-weight-regular);
      }
    }

    &--text {
      max-width: fit-content;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &-service__icon {
    margin-right: var(--spacing-s);
    position: relative;
    vertical-align: text-top;
  }
}

.team-integrations-detail__user {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  white-space: nowrap;
  &.is-clickable {
    cursor: pointer;
  }
  &-icon {
    margin-right: var(--spacing-s);
    position: relative;
    vertical-align: text-top;
  }
  &-label {
    font-weight: var(--text-weight-medium);
    padding-left: var(--spacing-s);
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }
  &-name {
    font-weight: var(--text-weight-medium);
    padding-left: 2px;
  }
}


.team-integrations-detail__lastrun {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  white-space: nowrap;
  &__icon {
    margin-right: var(--spacing-s);
    position: relative;
    vertical-align: text-top;
  }
  &-text {
    font-weight: var(--text-weight-medium);
    padding-left: 2px;
  }
}
