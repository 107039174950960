/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.websocket-message-stream-controls {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-l);

  // This required because without this the shadow gets cropped while scrolling
  // by the list item that has monaco editor in expanded state
  z-index: 1;

  &.add-shadow {
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
  }

  &__filter-dropdown {
    background-color: var(--background-color-primary);
    border-radius: var(--border-radius-default);
    box-shadow: var(--shadow-default);

    .message-type-filter-item > span {
      display: flex;
      align-items: center;
      gap: var(--spacing-s);
      height: var(--controls-size-default);
    }
  }

  &__filter-input {
    display: flex;
    align-items: center;
    width: 240px;
    min-width: 100px;
  }

  &__right-side {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;
  }
}
