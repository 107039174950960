/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.select-dropdown-container {
  .dropdown-menu {
    background-color: transparent;
    padding: var(--spacing-zero);
    box-shadow: unset;
  }

  .select-dropdown {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background-color: var(--background-color-primary);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius-default);
    padding: 8px;
    max-height: 260px;
    width: 230px;

    .input-search-group {
      border-radius: var(--border-radius-default);
      margin-bottom: 4px;
      margin-top: 8px;
      min-height: 30px;
    }

    .select-dropdown-list.isLoading {
      overflow: unset;
      margin: auto;
    }

    .select-dropdown-list {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      color: var(--content-color-secondary);

      .select-dropdown-item {
        @include clickable;
        user-select: none;
        padding: 8px 4px;
        position: relative;
        height: 18px;
        display: flex;
        align-items: center;

        .select-dropdown-item-text {
          color: var(--content-color-primary);
          margin-left: 8px;
          width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .select-dropdown-item-checkbox {
          position: absolute;
          right: 4px;
        }

        .input-checkbox.is-selected {
          background-color: var(--base-color-brand);
        }

        &:hover {
          background-color: var(--highlight-background-color-primary);
        }
      }

      .select-dropdown-item:nth-of-type(1) {
        margin-top: 8px;
      }
    }
  }
}
