.edit-request-modal {
  .field-label {
    margin-bottom: 5px;

    @include unselectable;
  }

  .edit-request-modal-name-input {
    margin-bottom: 15px;
  }

  .edit-request-modal-description-editor {
    height:300px;
  }
}
