@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
  }
}

.spinner {
  display: flex;
  justify-content: space-between;
  width: 14px;
  height: 12px;
  text-align: center;
  font-size: var(--text-size-xs);

   & > div {
    background-color: var(--content-color-tertiary);
    height: 100%;
    width: 2px;
    border-radius: var(--border-radius-s);

    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect-two {
    animation-delay: -1.1s;
  }

  .rect-three {
    animation-delay: -1.0s;
  }

  .rect-four {
    animation-delay: -0.9s;
  }

  .rect-five {
    animation-delay: -0.8s;
  }
}
