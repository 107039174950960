.image-modal {
  margin: auto;
  text-align: center;

  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
