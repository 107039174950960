.homepage-get-started-empty {
  margin: 24px 0;
  max-width: 584px;
  animation: fade 2s ease-in-out infinite;

  &-header {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
    height: 16px;
    width: 50%;
    background-color: var(--background-color-tertiary);
  }

  &-cards {
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-row: auto;
    grid-column-gap: var(--spacing-xxl);
    grid-row-gap: var(--spacing-l);
    margin-top: var(--spacing-l);

    &__card {
      box-sizing: border-box;
      border: 0.5px solid var(--border-color-default);
      height: 160px;
      border-radius: 4px;
      padding: 20px 24px;

      &-name {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
        width: 100%;
        margin: 0 0 8px 0;
        height: 12px;
        margin-bottom: var(--spacing-l);
        background-color: var(--background-color-tertiary);
      }

      &-description {
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        margin: 0;
        width: 100%;
        height: 32px;
        background-color: var(--background-color-tertiary);
      }
    }
  }
}
