.raw-websocket-compose {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin-right: var(--spacing-s);

  &__editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: var(--spacing-l);

    // If the editor container is shrinked, the text-editor was not getting resized
    // accordingly in Firefox. This fixes the resizing issue.
    min-height: 0;

    .text-editor .monaco-editor {
      .margin-view-overlays .line-numbers {
        color: var(--content-color-tertiary);
      }

      .monaco-placeholder {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        color: var(--content-color-secondary);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s) var(--spacing-zero);
    margin-left: var(--spacing-l);
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

    &__left {
      display: flex;
      align-items: center;

      &__lang-selector {
        margin-right: var(--spacing-xs);
      }
    }
  }
}
