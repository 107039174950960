.artemis-workspace-error-state {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__background {
    @include icon(
      '../assets/images/thumbnails/browse-view-offline.svg',
      160px,
      160px
    );
  }

  &__title {
    color: var(--content-color-primary);
    margin: 16px 0;
    font-size: 16px;
    font-weight: var(--text-weight-medium);
  }

  &__description {
    color: var(--content-color-primary);
    font-size: 14px;
  }

  &__switch-btn {
    margin: 16px 0;
    min-width: 136px;
    font-size: 14px;
    font-weight: 600;
  }
}
