.agent-unavailable-tooltip {
  &__header {
    color: var(--content-color-error);
  }

  &__body {
    color: var(--content-color-primary);
    margin-top: var(--spacing-xs);
    max-width: 250px;
  }
}
