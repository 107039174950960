.request-method-editor {
  padding-right: 2px;
  border: none;
  background-color: var(--background-color-primary);
  max-width: 108px;

  .dropdown {
    width: 100%;
    text-align: center;
  }

  .dropdown-button .btn {
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    font-size: var(--text-size-l);
    height: 38px;
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;


    @include hover {
      background-color: var(--highlight-background-color-secondary);
    }

    @include active {
      background-color: var(--background-color-primary);
    }
  }

  .request-methods-list-wrapper {
    &:focus-within {
      box-shadow: 0 0 0 3px var(--input-shadow-focus);
    }

    transition: all 0.2s ease-in-out;
    height: 100%;
    width: 108px;
    border-radius: inherit;
    box-sizing: border-box;
    border: var(--border-width-default) var(--border-style-solid) transparent;


    .dropdown-button {
      padding-right: var(--spacing-s);
    }

    .color-GET {
      color: var(--content-color-success);
    }
    .color-POST {
      color: var(--content-color-warning);
    }
    .color-DELETE {
      color: var(--content-color-error);
    }
    .color-PUT {
      color: var(--content-color-info);
    }
    .color-PATCH {
      color: var(--content-color-patch-method);
    }
    .color-OPTIONS {
      color: var(--content-color-options-method);
    }
    .color-HEAD {
      color: var(--content-color-success);
    }
    .color-default {
      color: var(--content-color-primary);
      text-overflow: ellipsis;
    }
  }

  .request-methods-list-wrapper.is-open {
    transition: all 0.2s ease-in-out;
    background-color: var(--background-color-primary);
    border: var(--border-width-default) var(--border-style-solid) transparent;
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
    z-index: 11;
  }
}

.request-methods-editor-dropdown-menu {
  .dropdown-menu {
    min-width: 156px;
    font-weight: var(--text-weight-bold);
    margin-top: var(--spacing-xs);

    .dropdown-menu-item {
      color: var(--content-color-primary);
      margin: 0 var(--spacing-s);
      padding: 0 var(--spacing-s);
      .highlight {
        color: inherit;
        font-weight: 800;
      }
    }
    .dropdown-menu-item--GET {
      color: var(--content-color-success);
    }
    .dropdown-menu-item--POST {
      color: var(--content-color-warning);
    }
    .dropdown-menu-item--PUT {
      color: var(--content-color-info);
    }
    .dropdown-menu-item--DELETE {
      color: var(--content-color-error);
    }
    .dropdown-menu-item--PATCH {
      color: var(--content-color-patch-method);
    }
    .dropdown-menu-item--OPTIONS {
      color: var(--content-color-options-method);
    }
    .dropdown-menu-item--HEAD {
      color: var(--content-color-success);
    }
  }
}
