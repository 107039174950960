.requester-tab-contents,
.requester-tab-content-container {
  flex: 1;

  display: flex;
  flex-direction: column;
  min-height: 0;
}

.requester-tab-content {
  flex: 1;

  display: flex;
  flex-direction: column;

  &.is-hidden {
    display: none;
  }

  min-height: 0;
}

.requester-tab-content-panes {
  flex: 1;
}

.layout-2-column {
  .requester-tab-content-panes {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .requester-tab-content-pane {
    flex: 1;

    display: flex;
    flex-direction: column;
  }
}

.requester-tab-contents-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.right-context-bar {
  display: flex;
  flex-direction: row;
}

.right-context-bar-options {
  width: 48px;
  height: 100%;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      box-sizing: border-box;
      position: relative;
      padding: var(--spacing-m);
      height: 48px;
      width: 48px;
      @include clickable;
      text-align: center;
      @include icon-color(var(--content-color-secondary));

      .icon-container {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
      }

      &:hover {
        @include icon-color(var(--content-color-primary));
      }

      &.active {
        @include icon-color(var(--content-color-primary));
      }
      &.active::before {
        content: "";
        display: block;
        position: absolute;
        left: 8px;
        top: 8px;
        bottom: 8px;
        right: 8px;
        background-color: var(--highlight-background-color-primary);
        border-radius: var(--border-radius-default);
      }
    }
  }

  &.contextbarOverlay:hover {
    cursor: pointer;
  }
}

.right-context-bar-content-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  .right-context-bar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-s);

    .right-context-bar__title {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      color: var(--content-color-primary);
    }

    .right-context__actions-container{
      display: flex;
    }
  }

  &.overlay {
    // content width = 100% of overlay - 48px ( For context bar tabs )
    width: calc(100% - 48px);
  }
}

.context-bar-container-stacking-order {
  z-index: 0;
}

// This ensures in overlay mode for context bar, the pane resize handle has no effect
.requester-tab-content-container.contextbarOverlay {
  .requester-tab-content .pane-container .pane-resize-handle {
    pointer-events: none;
  }
}

// This enables the pointer-events for any nested panes which were getting disabled by the selector above.
// https://postmanlabs.atlassian.net/browse/INC-473
.requester-tab-content-container.contextbarOverlay {
  .requester-tab-content .pane-container .pane-container .pane-resize-handle {
    pointer-events: all;
  }
}
