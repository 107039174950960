$preview-error-thumbnail-url: "../assets/images/thumbnails/browse-view-offline.svg";

.remote-iframe-viewer {
  width: 100%;
  height: 100%;
  // Can not use color tokens e.g. --background-color-primary as it will get mapped
  // to some other color when switching between the themes.
  background-color: var(--grey-00);
  flex: 1;

  .remote-iframe-viewer__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 200px;

    .remote-iframe-viewer__error-icon {
      @include icon($preview-error-thumbnail-url, 120px, 120px);
    }

    .remote-iframe-viewer__error-title {
      margin-top: var(--spacing-s);
      margin-bottom: var(--spacing-xs);
    }

    .remote-iframe-viewer__error-description {
      width: 350px;
      text-align: center;
    }

    .remote-iframe-viewer__error-reload {
      @include clickable;
      color: var(--content-color-brand);
    }
  }

  .remote-iframe-viewer__loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .remote-iframe-viewer__frame {
    width: 100%;
    height: 100%;
    border: 0;

    &.hidden {
      display: none;
    }
  }
}

.fork-recommendation-tooltip {
  .tippy-content {
    padding: 0;
  }

  &__title {
    font-weight: var(--text-weight-medium);
    margin-bottom: var(--spacing-s);
  }

  &__secondary-message {
    margin-top: var(--spacing-l);
  }

  &__actions {
    margin-top: var(--spacing-xl);

    button.primary-action {
      background: var(--button-primary-background-color);
      border-radius: var(--border-radius-default);
    }

    button.secondary-action {
      border-radius: var(--border-radius-default);
    }
  }
}
