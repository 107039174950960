#monitor-message-log {
  --monitor-msg-log-cell-padding: 5px;

  //added this to confirm that the first cell aligns in both the log state-line-field and the table's td element.
  --monitor-msg-log-first-cell-width: 25px;
  --monitor-msg-log-bullet-size: 8px;
  --monitor-msg-log-msg-field-padding-left: var(--spacing-s);
  --monitor-msg-log-timestamp-field-width: 60px;

  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  padding: var(--spacing-l);
  overflow: auto;

  .state-line {
    padding: var(--monitor-msg-log-cell-padding) 0;

    .loading-indicator-wrapper {
      display: inline-block;
      vertical-align: middle;
      transform: scale(.75);
    }
  }

  .state-line__field {
    padding-right: var(--monitor-msg-log-cell-padding);

    &.bullet {
      display: inline-block;
      padding: 0;
      width: var(--monitor-msg-log-first-cell-width);
      text-align: center;

      /**
       * The background property of the :after element has been given a --content-color instead of a --background design token
       * because the bullet is essentially content, moreover using the --background token here wouldn't work well with the colors
       * after render.
       */
      &:after {
        content: '';
        display: inline-block;
        width: var(--monitor-msg-log-bullet-size);
        height: var(--monitor-msg-log-bullet-size);
        border-radius: 50%;
        background: var(--content-color-tertiary);
      }
    }

    &.timestamp {
      width: var(--monitor-msg-log-timestamp-field-width);
    }

    &.message-body {
      padding-left: var(--monitor-msg-log-msg-field-padding-left);
    }
  }

  .state-line__field--toggler {
    color: var(--content-color-link);
    cursor: pointer;
    font-family: var(--text-family-default);
  }

  .nested-log {
    padding: var(--monitor-msg-log-cell-padding) 0;
  }

  table.messages {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    tr.message {
      display: table-row;
      border-top: var(--border-width-default) solid var(--border-color-strong);

      &:last-child {
        border-bottom: var(--border-width-default) solid var(--border-color-strong);
      }

      &.test.info .message-body{
        color: var(--content-color-success);
      }

      &.warn .message-body{
        color: var(--content-color-warning);
      }

      &.error .message-body{
        color: var(--content-color-error);
      }

      td {
        padding: var(--monitor-msg-log-cell-padding);
        white-space: nowrap;

        &.line-number {
          text-align: center;
          width: var(--monitor-msg-log-first-cell-width);
        }

        &.timestamp {
          background-color: var(--background-color-secondary);
          width: var(--monitor-msg-log-timestamp-field-width);
          padding-right: var(--spacing-l);
        }

        &.message-body {
          padding-left: var(--monitor-msg-log-msg-field-padding-left);
          padding-right: var(--spacing-xs);
          white-space: pre;

          .request {
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            .icon {
              margin-left: var(--spacing-xl);
              visibility: hidden;
            }
          }

          &:hover .request .icon {
            visibility: visible;
          }
        }
      }
    }
  }
}
