.error-loading-configured-integrations {
  &__text-error {
    padding-left: var(--spacing-xs);
    color: var(--content-color-error);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
  }
  &__text-error-retry {
    color: var(--content-color-error);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
    cursor: pointer;
    text-decoration: underline;
  }
  &__text-error-retry.is-disabled {
      cursor: not-allowed;
  }

}

.offline-state-configured-integrations {
  &__text-error {
    padding-left: var(--spacing-xs);
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
  }
}
