.edit-collection-modal {
  .field-label {
    margin-bottom: 5px;

    @include unselectable;
  }

  .edit-collection-modal-name-input {
    margin-bottom: 15px;
  }

  .edit-collection-modal-description-editor {
    &.ace_focus {
      border: 1px solid var(--border-color-strong);
    }

    .ace_active-line {
      background: none;
    }
  }
}
