/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.integration-runs__list-container{
  width: 100%;
  max-width: 960px;
  padding: var(--spacing-zero) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);

  &__breadcrumb {
    margin-left: var(--spacing-l);
  }

  &__content {
    margin-left: var(--spacing-l);
  }

  &__loading {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  &__header{
    display: flex;
    padding: var(--spacing-s) var(--spacing-s);
    &__sec1{
      width: 60%;
      &__title{
        color: var(--content-color-primary);
        font-style: normal;
        font-size: var(--text-size-xxl);
      }
      &__subtitle{
        font-weight: var(--text-weight-small);
        margin-top: 5px;
        color: var(--content-color-secondary);
        font-style: normal;
        font-size: var(--text-size-m);
      }
    }
    &__sec2{
      width: 40%;
    }
  }

  &__main{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    &__banner {
      padding-bottom: var(--spacing-xl);
    }

    &__sec1{
      width: 60%;
      color: var(--content-color-secondary);
      &__header{
        display: flex;
        border-bottom: 1px solid var(--grey-10);
        padding: var(--spacing-s) var(--spacing-l);
        font-size: var(--text-size-m);
        font-weight: 600;
        text-transform: uppercase;
        color: var(--content-color-tertiary);
        &__time{
          width: 40%;
        }
        &__status{
          width:10%;
        }
        &__reason{
          width:50%;
        }
      }
      &__empty{
        text-align: center;
        color: var(--content-color-primary);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: var(--spacing-xxl) var(--spacing-l);
        line-height: var(--line-height-s);
        position: relative;
        &__text {
          margin-top: var(--spacing-l);
          font-size: var(--text-size-l);
          color: var(--content-color-secondary);
        }
      }
      &__items{
        display: flex;
        flex-direction: column;
        &__item{
          display: flex;
          padding: var(--spacing-s) var(--spacing-l);
          font-size: var(--text-size-m);
          &__time{
            width: 40%;
            color: var(--content-color-primary);
          }
          &__status{
            font-size: var(--text-size-s);
            font-weight: 600;
            text-align: center;
            &__success{
              border-radius: 10px;
              padding: 2px 3px;
              background-color: var(--background-color-success);
              color: var(--content-color-success);
              text-transform: uppercase;
            }
            &__failure{
              border-radius: 10px;
              padding: 2px 4px;
              background-color: var(--background-color-error);
              color: var(--content-color-error);
              text-transform: uppercase;
            }
            &__running{
              border-radius: 10px;
              padding: 2px var(--spacing-xs);
              background-color: var(--background-color-tertiary);
              color: var(--content-color-secondary);
              text-transform: uppercase;
            }
          }
          &__reason{
            width:50%;
            margin-left: 5px;
            margin-bottom: 4px;
            &__msg{
              display: inline;
            }
            &__icon{
              display: inline;
              height: 16px;
              width: 16px;
              margin-top: 5px;
              margin-left: 5px;
              svg{
                transform: translateY(1px);
              }
            }
          }

        }
      }
    }
    &__sec2{
      width: 30%;
      height: 200px;
      &__container{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        &__item{
          width: 100%;
          height: 30%;
          color: var(--content-color-secondary);
          font-size: var(--text-size-m);
          &__title{
            font-weight: 400;
            font-size: var(--text-size-m);
            color: var(--content-color-primary);
            line-height: 12px;
          }
          &__description{
            margin-top: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            &__text{
              margin-left: 5px;
              width: 100%;
              &__div {
                max-width: fit-content;
                cursor: pointer;
              }
            }
            &__icon{
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              height: auto;
              width: 16px;
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
            }
            &__fallback-icon {
              @include icon('../assets/images/thumbnails/integration-stroke.svg',16px,16px);
            }
          }
        }
        .integration-page-banner {
          .alert-info  {
            margin-top: var(--spacing-xl);
            .alert-content .alert-actions .btn-primary {
              background-color: transparent;
              color: var(--content-color-primary);
              font-size: var(--text-size-m);
              font-weight: var(--text-weight-regular);
              border: 1px solid rgba(48,48,48,0.2);
              box-sizing: border-box;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}

.runlog-tooltip {
  max-width: 360px !important;
}

.trace-container {
  border-top: 1px solid var(--border-color-default);

  &__trace {
    padding-left: var(--spacing-xl);
  }

  &__toggle {
      margin-left: var(--spacing-s);
  }
}

.integration-run-log {

  &__steps-to-fix {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    margin: var(--spacing-m);

    p {
      a {
        color: var(--content-color-primary);
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &__tooltip-body {
    margin: 0;
    padding: 0;
  }
}

.integrations-meta__loading {
  margin-left: var(--spacing-m);
}

.integration-entity__link {
  cursor: pointer;
}

