/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.markdown-viewer {
  & > * {
    margin: var(--spacing-zero);
  }

  & > * + * {
    margin-top: var(--spacing-l);
  }

  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  height: auto;
  position: relative;
  color: var(--content-color-primary);

  /**
  * As there we no mockups in artemis-v2 Figma file, copied the
  * same styles as present in the public & private documentation
  */
  h1 * {
    font-size: var(--text-size-xxxl);
    line-height: var(--line-height-xxxl);
  }

  h2 * {
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
  }

  h3 * {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
  }

  h4 * {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
  }

  h5 * {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  h6 * {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
  }

  hr {
    border-style: none;
    border-width: 0;
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  }

  blockquote {
    padding-left: var(--spacing-l);
    border-left: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
    color: var(--content-color-secondary);
    margin: var(--spacing-zero);
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: var(--text-weight-medium);
  }

  ul,
  ol {
    margin-left: var(--spacing-s);
    padding-left: var(--spacing-m);
    p {
      margin: var(--spacing-zero);
    }
  }

  ol {
    li {
      list-style-type: decimal;
      ol {
        li {
          list-style-type: lower-alpha;
          ol {
            li {
              list-style-type: lower-roman;
              ol {
                li {
                  list-style-type: lower-alpha;
                  ol {
                    li {
                      list-style-type: lower-roman;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  code {
    padding: 1px var(--spacing-xs);
    font-family: var(--text-family-code);
    background-color: var(--background-color-tertiary);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-radius: var(--border-radius-default);
  }

  pre {
    font-family: var(--text-family-code);
  }

  .highlighted-code {
    code {
      background-color: transparent;
      border: none;
      border-radius: 0;
    }
  }

  table {
    width: 100%;
    border-radius: var(--border-radius-default);
    border-spacing: 0;
    border-collapse: collapse;
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    tr {
      height: var(--size-m);
      min-height: var(--size-m);
    }

    th,
    td {
      min-width: 77px;
      max-width: 77px;
      padding: var(--spacing-xs) var(--spacing-l);
      word-break: normal;
      vertical-align: top;
      white-space: pre-line;
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    th {
      border-top: none;
      text-align: left;
      font-weight: normal;
      background-color: var(--background-color-secondary);
    }

    tr {
      &:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }
  }

  .md-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.zoom {
      cursor: zoom-in;
    }

    img {
      flex: 1;
      padding-bottom: var(--spacing-zero);
    }

    .image-caption {
      margin-top: var(--spacing-xs);
      color: var(--content-color-secondary);
      text-align: center;
    }
  }

  img {
    display: block;
    max-width: 100%;
    padding: 10px 0px;
  }

  .click-to-expand-wrapper {
    position: relative;
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    table {
      border: none;
    }
  }

  .click-to-expand-container {
    --max-table-height-without-scroll: calc(20 * var(--size-s));
    max-height: var(--max-table-height-without-scroll);
    width: 100%;
    overflow: hidden;

    &--overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: var(--size-m);
      border-radius: var(--border-radius-default);
      background: linear-gradient(to top, var(--highlight-background-color-secondary), rgba(255, 255, 255, 0));
    }

    &--expand-button {
      position: absolute;
      bottom: var(--spacing-s);
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--background-color-primary);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      border-radius: calc(8 * var(--border-radius-default));
      color: var(--content-color-primary);
      padding: var(--spacing-xs) var(--spacing-m);
      font-size: var(--text-size-m);

      &:focus {
        outline: none;
      }
    }
  }

  &-truncation-text {
    font-weight: var(--text-weight-medium);
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--background-color-primary);
  }

  &.truncated {
    height: calc(2 * var(--line-height-m));
    overflow: hidden;
  }
}

comment {
  display: none;
}
