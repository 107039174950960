$section-margin-bottom: 16px;

.learning-center-skill {
  .learning-center-skill--main {
    display: flex;
  }

  .learning-center-skill--left {
    flex: 1;
    padding-right: 20px;
  }

  .learning-center-skill--right {
    padding-left: 20px;
    flex: 1;
  }

  .learning-center-back {
    display: inline-flex;
    margin: 20px 0;
    color: var(--content-color-secondary);
    align-items: center;
    cursor: pointer;

    .learning-center-back-icon {
      margin-right: 10px;
    }
  }

  .skill-details {
    display: flex;
    flex-direction: column;

    .skill-icon {
      margin-bottom: $section-margin-bottom;

      img {
        object-fit: contain;
      }
    }
    .skill-name {
      color: var(--content-color-primary);
      font-weight: 600;
      font-size: 12px;
      margin-bottom: $section-margin-bottom;
    }
    .skill-description {
      font-size: 12px;
      color: var(--content-color-secondary);
      margin-bottom: $section-margin-bottom;
    }
  }

  .skill-additional--lesson-detail {
    display: flex;
    justify-content: space-between;
    padding: 6px 28px 6px 16px;
  }

  .skill-additional--label {
    margin-bottom: 16px;
    font-weight: 600;
  }

  .skill-additional-resources-content {
    // we're styling html elements generated by the markdown parser here
    ol {
      padding: unset;

      li {
        margin: 16px 0px 16px 28px;

        p {
          margin: unset;
          
          &:first-child {
            margin-bottom: 4px;
          }
        }
      }
    }

    a {
      color: var(--content-color-brand);
      text-decoration: none;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      &:visited {
        text-decoration: none;
      }
    }
  }
}
