.sidebar-error-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  font-family: $font-primary;
  background-color: var(--background-color-secondary);
  height: 100%;
  padding: var(--spacing-s);
  margin-top: var(--spacing-xxl);

  .sidebar-error-thumbnail {
    height: 120px;
    width: 120px;
    background: url($missing-current-workspace-thumbnail-url) no-repeat center;
    background-size: contain;
    margin: var(--spacing-xl) var(--spacing-zero) var(--spacing-l) var(--spacing-zero);
  }

  .sidebar-error-content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacing-l);
  }

  .sidebar-error-header {
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-s);
  }

  .sidebar-error-content {
    font-size: var(--text-size-m);
    text-align: center;
    color: var(--content-color-secondary);
      margin-top: var(--spacing-xs);
  }

  .sidebar-error-button {
    font-size: var(--text-size-m);
    text-align: center;
    color: var(--content-color-secondary);
    margin-top: var(--spacing-l);
  }
}
