.generic-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    color: var(--content-color-primary);
  }

  &__illustration + &__title {
    margin-top: var(--spacing-l);
  }

  &__message {
    text-align: center;
  }

  &__illustration + &__message {
    margin-top: var(--spacing-l);
  }

  &__title + &__message {
    margin-top: var(--spacing-s);
  }

  &__primary-action {
    margin-top: var(--spacing-xl);
  }

  &__secondary-action {
    margin-top: var(--spacing-xl);
  }

  &__primary-action + &__secondary-action {
    margin-top: var(--spacing-l);
  }
}
