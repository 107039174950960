$environment-empty-url: $thumbnail-path + 'environment.svg';

.entity-empty {
  .entity-empty__illustration-wrapper--environment {
    .environment-empty__illustration {
      @include icon(
        $environment-empty-url,
        115px,
        95px
      )
    }
  }
}
