.empty-tab-state {
  text-align: center;
  padding-top: 96px;

  &__icon-container {
    height: 56px;
    width: 56px;
    background-color: var(--background-color-tertiary);
    border-radius: var(--border-radius-max);
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;

    .empty-tab-state__icon {
      height: var(--size-m);
      width: var(--size-m);
  
      svg {
        height: var(--size-m);
        width: var(--size-m);
      }
    }
  }

  &__title {
    margin: var(--spacing-l) auto var(--spacing-xs) auto;
  }

  &__description {
    margin: 0 auto var(--spacing-xl) auto;
    max-width: 448px;
  }
}