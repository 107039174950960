.report-team {
  margin-left: 135px;
  margin-right: 135px;

  .pm-h1 {
    display: inline-block;
    margin-top: var(--spacing-xxl);
    font-size: var(--text-size-xxxl);
    margin-bottom: 0;
  }

  &-filters {
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-s);

    &__label {
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
    }

    .pm-select {
      display: inline-block;
      vertical-align: middle;
      margin: 0 var(--spacing-l);
      cursor: pointer;

      .Select-clear-zone {
        display: none;
      }
    }
  }

  .pm-row {
    margin-left: -15px;
    margin-right: -15px;

    .pm-col-md-4 {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (min-width: 768px) {
      .pm-col-md-4 {
          width: 33.33333%;
      }
    }
  }

  .pm-link {
    text-decoration: underline;
  
    &:hover {
      color: var(--content-color-link);
      text-decoration: underline;
      cursor: pointer;
    }
  
    &:visited {
      text-decoration: none;
    }
  }
}
