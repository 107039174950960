.mock-offline__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.mock-offline__sidebar {
  padding: var(--spacing-xxl) var(--spacing-l);
  margin-top: var(--spacing-xxl);

  .mock-offline__content__header {
    color: var(--content-color-secondary);
  }
}

.mock-offline__tab {
  justify-content: center;

  .mock-offline__content__header {
    color: var(--content-color-primary);
  }
}

.mock-offline__context-bar {
  margin-top: 64px;

  .mock-offline__content__header {
    color: var(--content-color-primary);
  }
}

.mock-offline {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 200px;

  &__content {
    text-align: center;
    line-height: var(--line-height-m);
    margin-top: var(--spacing-l);

    &__header {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      margin-bottom: var(--spacing-s);
    }

    &__sub-header {
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
    }
  }
}
