$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.pull-request {
  &-topbar {
    position: relative;
    $topbar-actions-max-width: 240px;
    overflow: hidden;
    padding: var(--spacing-l) 0;

    &__actions {
      margin-top: var(--spacing-s);
      right: 0;
      bottom: 16px;

      @media (min-width: $breakpoint-lg) {
        position: absolute;
      }

      &-tooltip-fork-label {
        margin-left: var(--spacing-s);
        margin-bottom: 1px; // non-standard margin used for alignment
      }
    }

    &__action-btn {
      display: none;
      margin-right: var(--spacing-s);

      @media (min-width: $breakpoint-md) {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__approve {
      // for displaying the gray area around the number
      span {
        border-radius: var(--border-radius-default);
        margin-left: var(--spacing-xs);
        padding: 0px var(--spacing-xs);
        color: var(--content-color-constant);
      }
    }

    &__approver-list {
      margin: var(--spacing-s);

      h4 {
        color: var(--content-color-secondary);
        font-weight: var(--text-weight-medium);
        line-height: var(--line-height-m);
        font-size: var(--text-size-l);
        margin: 0px;
        margin-bottom: var(--spacing-m);
        white-space: nowrap;
      }

      .approved-user {
        margin: var(--spacing-s) 0px;
        padding-left: var(--spacing-xs);

        span {
          position: static; // override default absolute to static to make the parent element take width of child.
          padding: 0px var(--spacing-xxl); // provide space on left to account for icon.
          white-space: nowrap;
        }
      }
    }

    &__actions-comment-only {
      margin-top: var(--spacing-s);
      position: relative;
      right: 0;
      bottom: 16px;

      @media (min-width: $breakpoint-md) {
        position: absolute;
      }
    }

    &__action-btn-comment {
      display: inline-block;
      margin-top: var(--spacing-l);

      @media (max-width: $breakpoint-md) {
        margin-top: var(--spacing-l);
      }

      &:not(:last-child) {
        margin-right: var(--spacing-s);
      }
    }

    &__more-options {
      float: right;
    }
  }

  &__title {
    font-size: var(--text-size-xxxl);
    color: var(--content-color-primary);
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    max-width: calc(100% - 80px); // need to account space for pr status badge
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__status {
    border-radius: var(--border-radius-default);
    padding: 0 8px !important;
  }

  &__changes-count {
    margin-left: var(--spacing-s);
    height: 18px; // non-standard by design

    span {
      color: var(--content-color-secondary);
      line-height: inherit;
    }
  }

  &__source-destination-label {
    @media (min-width: $breakpoint-sm) {
      width: 90%;
    }

    @media (min-width: $breakpoint-lg) {
      width: 100%;
      padding-right: 440px;
    }
  }
}
