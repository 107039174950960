.env-sidebar-list-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-s);
  flex: 1;

  >.sidebar-list-item {
    flex: unset;
  }

  .env-sidebar-list {
    display: flex;
    flex: 1;
  }

  &__api-sidebar {
    margin-left: 32px;
  }
}

.empty-search-item {
  display: flex;
  justify-content: center;
  color: var(--content-color-tertiary);
  padding: var(--spacing-l);

  &__text {
    @include text-truncate-ellipsis;
  }
}
