$keyvalue-row-height: 32px !default;

.key-value-form-enable-all-checkbox {
  display: flex;
  align-items: center;
}

.key-value-form-header-row {
  font-size: var(--text-size-m);
  font-family: var(--font-family-default);

  .header-row {
    margin-bottom: var(--spacing-zero);

    .auto-suggest-group > :last-child {
      border-right: 0;
    }

    @include hover {
      background-color: transparent;
    }

    .key-value-form-actions{
      border-top-width: 0;

      .auto-suggest{
        display: none;
      }

      &.hide-column{
        display: none;
      }
    }

    .key-value-form-header-item {
      overflow: hidden;
      align-items: center;
      display: flex;
      position: relative;
      box-sizing: border-box;
      border-right: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);
      margin: var(--spacing-zero);

      &.is-non-editable {
        background-color: $keyvalue-non-editable-column-bg;
      }

      &.is-sort-enabled {
        cursor: pointer;
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

        .header-content {
          cursor: pointer;
        }

        &:hover {
          background-color: var(--background-color-tertiary);
          border-color: var(--border-color-strong);

          .header-content {
            color: var(--content-color-primary);
          }
        }

        &:active {
          background-color: var(--highlight-background-color-secondary);
        }
      }

      &.is-sort-active {
        background-color: var(--background-color-secondary);
        border-color: var(--border-color-strong);

        .header-content {
          color: var(--content-color-primary);
        }
      }

      .header-content {
        padding: var(--spacing-s);
        color: $keyvalue-header-label-color;
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
        transition: color 0.2s ease-in-out;

        @include unselectable;
        @include text-truncate-ellipsis;
      }

      .key-value-form-header-item-tooltip-wrapper {
        align-items: center;
        height: 12px; // To fix vertical alignment
        opacity: 0;
        visibility: hidden;
        transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
        // To prevent jumping of info icon when it appears on hover
        overflow: hidden;
        width: 0;

        .key-value-form-header-item-tooltip {
          display: flex;
          flex: 0;
          padding: var(--spacing-zero);

          @include icon-color(var(--content-color-secondary));
        }
      }

      &:hover {
        .key-value-form-header-item-tooltip-wrapper {
          margin-right: var(--spacing-xs); // To make more room for header label and apply spacing only when info icon is visible
          opacity: 1;
          visibility: visible;
          // Reinstate these properties on hover to prevent jumping of info icon on hover out
          overflow: visible;
          width: auto;
        }
      }

      .key-value-form-header-item__disabled-sync-icon {
        cursor: default;
      }

      &.is-first-column:nth-child(1) {
        border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }

    .bulk-editor-preset__controls {
      max-width: fit-content;
      color: $keyvalue-header-action-items-color;
      display: flex;
      align-items: center;
      height: $keyvalue-row-height;
      margin-right: 5px; // Offset to account for wrong margin in Aether component

      .preset-selector {
        flex: 1;
        height: $keyvalue-row-height;
        display: flex;
        align-items: center;
        @include clickable;

        .btn-text {
          color: var(--content-color-primary);
        }
        .caret {
          float: right;
          margin-left: var(--spacing-xs);

          @include icon-color(var(--content-color-primary));
        }
      }

      .key-value-column-toggle {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;

        .dropdown-button {
          display: flex;
        }
      }

      .goto-bulk-editor, .key-value-control-persist-all, .key-value-control-reset-all {
        flex: 1;
        white-space: pre;
        display: flex;
        align-items: center;
        @include clickable;
      }
    }

    .key-value-control-persist-all.is-disabled, .key-value-control-reset-all.is-disabled{
      opacity: 0.4;
      @include unselectable;
    }

    .resize-handle {
      height: $keyvalue-row-height;
      position: absolute;
      top: 0;
      width: 16px;
      left: -8px;
      cursor: col-resize;
    }
  }

}

.key-value-column-toggle--menu {
  @include icon-color(var(--content-color-primary));
  &:hover {
    @include icon-color(var(--content-color-primary));
  }
  .key-value-column-toggle__menu-item {
    padding: var(--spacing-zero) var(--spacing-s);

    .key-value-column-toggle--option {
      font-size: var(--text-size-m);
      display: flex;

      @include clickable;
    }
  }

  .key-value-column-toggle__label {
    font-size: var(--text-size-xs);
    color: var(--content-color-secondary);
    display: flex;
    padding: 6px var(--spacing-xs);

    // @todo non-uniform spacing because of inconsistencies with design tokens
    margin-left: 5px;

    @include unselectable;
  }

  .input-checkbox {
    margin-right: var(--spacing-s);
  }
}
