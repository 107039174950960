.breadcrumb__dropdown {
  position: absolute;

  .breadcrumb__dropdown-button-wrapper {
    border: none;
    height: auto;
    left: -2px;
    padding: 0;
    position: relative;
    width: auto;

    .breadcrumb__dropdown-button {
      position: relative;
    }
  }

  .dropdown-menu {
    left: -8.5px;
    max-width: 250px;

    .dropdown-menu-item {
      .dropdown-menu-item-label {
        @include text-truncate-ellipsis;
      }
    }
  }

  .dropdown-menu-item-icon {
    margin-right: 10px;
  }
}

.breadcrumb {
  overflow: hidden;
  padding-right: 15px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    content: '/';
    position: absolute;
    right: -1px;
    top: 0;
    width: 12px;
  }

  .breadcrumb__name {
    padding-right: 2px;
    @include clickable;

    @include hover {
      text-decoration: underline;
    }
  }

  &:last-child {
    padding-right: unset;

    .breadcrumb__name {
      color: var(--content-color-primary);
      text-decoration: unset;
      cursor: unset;
    }

    &:after {
      content: '';
    }
  }
}

.breadcrumb__container {
  display: flex;
  align-items: center;
  overflow: hidden;

  .dropdown-breadcrumb__separator {
    margin: 0 5px 0 30px;
  }

  .breadcrumb__text {
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
    line-height: var(--line-height-m);
    color: var(--content-color-tertiary);
  }
}
