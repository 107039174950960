.api-version-integration {
  width: 100%;
  counter-reset: section;

  &__error {
    &__action {
      min-width: 240px;
    }
  }

    &__delete-button {
      background-color: var(--base-color-error);
    }
    &__delete-button:hover {
      background-color: var(--base-color-error);
    }

  &__header {
    padding-bottom: var(--spacing-s);

    &__title {
      font-size: var(--spacing-xl);
      display: inline-block;
      font-weight: var(--text-weight-medium);
      margin-right: var(--spacing-l);
      vertical-align: middle;
    }

    &__icon {
      background-color: var(--base-color-success);
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      width: var(--spacing-xl);
      height: var(--spacing-xl);

      i {
        vertical-align: middle;

        svg {
          height: 12px;
          width: 12px;
          color: var(--content-color-constant);
        }
      }
    }

    &__description {
      color: var(--content-color-secondary);
      padding: var(--spacing-s) 0;
      border-bottom: 1px solid var(--border-color-default)
    }
  }

  &__content {
    &__header {
      margin: var(--spacing-xl) 0;
      position: relative;
      width:100%;
      color: var(--content-color-secondary);
      font-style: normal;
      font-size: var(--text-size-l);

      &:before {
        counter-increment: section;
        content: counter(section);
        position: absolute;
        left: -48px;
        width: var(--spacing-xl);
        height: var(--spacing-xl);
        text-align: center;
        line-height: var(--line-height-l);
        border-radius: var(--border-radius-default);
        background-color: var(--background-color-primary);
      }

      &__title {
        margin-bottom: var(--spacing-s);
        color: var(--content-color-primary);
        font-weight: var(--text-weight-medium);
      }

      &__description {
        &__content {
          max-width: 468px;
          display: inline-block;
        }

        &__button {
          margin-top: var(--spacing-l);
        }
      }

      strong {
        font-weight: var(--text-weight-medium);
      }

      a {
        display: block;
      }
    }
    &__header strong{
      color: var(--content-color-primary);
    }

    &__input-entity {
      margin-bottom: var(--spacing-xl);
      width: 450px;
      display: inline-block;

      .pm-form-control {
        background-color: var(--background-color-secondary);
      }

      .disabled {
        opacity: 1;
        pointer-events: initial;
        text-overflow: ellipsis;
      }

      .pm-form__label {
        font-size: var(--text-size-m);
      }
    }

    &__copy-button {
      margin-left: var(--spacing-s);
    }

    &__table {
      border: 1px solid var(--border-color-default);
      border-radius: var(--border-radius-default);
      &__empty {
        max-width: 360px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__title{
        color: var(--content-color-primary);
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-l);
        margin-top: 8px;
        padding: 6px;
        }

        &__add-button{
          margin-bottom: 8px;
        }

        &__description{
          color: var(--content-color-secondary);
          font-style: normal;
          font-size: var(--text-size-l);
          text-align: center;
          margin: 20px 0;
        }

        .empty-state__title {
          font-size: var(--text-size-l)
        }
      }

      &__header {
        border-bottom: 1px solid var(--border-color-default);
        padding: var(--spacing-s) var(--spacing-l);
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);

        &__name, &__branch {
          display: inline-block;
          min-width: 80px;
          margin-right: var(--spacing-l);
          color: var(--content-color-secondary);
        }

        &__path {
          display: inline-block;
          min-width: 150px;
          margin-right: var(--spacing-l);
          color: var(--content-color-secondary);
        }

        &__actions {
          display: inline-block;
        }
      }

      &__loader{
        display: flex;
        justify-content: center;
        width: 100%;
        margin:20px 0;
      }

      &__item {
        padding: var(--spacing-s) var(--spacing-l);
        line-height: var(--spacing-xxl);
        height: 48px;
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);

        &:hover {
          background-color: var(--background-color-primary);

          .api-version-integration__content__table__item__actions {
            @include ellipsis;

            vertical-align: middle;
            display: inline-block;
            width: 40px;
            min-width: 40px;
            text-align: right;

            i {
              cursor: pointer;
            }
          }
        }

        &__name, &__branch {
          @include ellipsis;

          vertical-align: middle;
          display: inline-block;
          width: 80px;
          min-width: 80px;
          margin-right: var(--spacing-l);
        }

        &__path {
          vertical-align: middle;
          display: inline-block;
          width: 150px;
          min-width: 150px;
          margin-right: var(--spacing-l);
        }

        &__actions {
          display: none;
        }
      }
    }
  }
}

.integrations-add-card__input2{
  display: flex;
}

.api-version-delete-modal {
  &__entity {
    font-weight: var(--text-weight-medium);
  }
}

.integrations-add-card__error {
  margin-top: var(--spacing-xs);
}

.integrations-add-card__retry-btn {
  cursor: pointer;
  text-decoration: underline;
}

.api-version-modal {


  .pm-h3 {
    margin: var(--spacing-l) 0;
  }

  &__close {
    position: absolute;
    top: var(--spacing-xxl);
    right: var(--spacing-xxl);
  }

  &__error {
    &__action {
      min-width: 240px;
    }
  }

  &__header {
    margin-bottom: var(--spacing-xl);
  }

  &__entity {
    &__label {
      font-weight: var(--text-weight-medium);
      display: block;
      margin-bottom: var(--spacing-s);
    }
  }

  &__name, &__branch, &__path {
    margin-bottom: var(--spacing-l);
  }

  &__branch-loader {
    float: right;
    position: relative;
    top: 36px;
    right: 10px;
  }

  &__branch-try-again {
    background: none;
    border: none;
    padding: 0px;
    color: var(--base-color-brand);
    cursor: pointer;
    outline: none;
    float: right;
    position: relative;
    top: 35px;
    right: var(--spacing-s);
  }

  &__branch-success {
    float: right;
    position: relative;
    top: 36px;
    right: 12px;
  }

  &__actions {
    margin-top: var(--spacing-xxl);
    margin-bottom: var(--spacing-xl);

    &__cancel {
      width: 112px;
      margin-right: var(--spacing-s);
    }

    &__submit {
      width: 264px;
      min-width: 264px;
    }
  }

  &__source {
    margin: var(--spacing-l) 0;
  }
}

.integrations-modal-content {
  &__schema-conflict {
    margin-bottom: var(--spacing-m);
  }
}

.integrations-api-schema-sync__actions {
  margin-top: var(--spacing-xxl);
}
