.api-definition-item {
  width: 100%;

  &__hover-above {
    position: relative;
    box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
  }

  &__hover-above &__folder-hover {
    background-color: transparent;
  }

  &__left-section {
    display: flex;
    flex-direction: row;
    margin-right: var(--spacing-s);
    height: 100%;
    align-items: center;

    &__indent {
      width: 11px;
      height: 100%;
      border-right: thin solid transparent;
    }
  }

  &__toggle-button {
    margin-left: var(--spacing-xs);
    height: var(--size-xs);
    width: var(--size-xs);
    background-color: unset;
    @include icon-color(var(--content-color-primary));

    @include hover {
      background-color: var(--highlight-background-color-tertiary);
      @include icon-color(var(--content-color-primary));
    }
  }

  &__create-definition {
    padding-left: var(--spacing-xs);
  }

  &__create-definition-button {
    cursor: 'pointer';
  }

  &__new {
    display: flex;
    height: 28px;
    align-items: center;
    font-size: var(--text-size-m);
    flex: 1;
    overflow: hidden;
    box-sizing: border-box;
    color: var(--content-color-primary);
    padding: var(--spacing-zero) var(--spacing-s);

    &__content {
      display: flex;
      flex: 1;
      min-width: 0;
      padding: var(--spacing-xs) var(--spacing-zero);
      align-items: center;
    }

    &__icon {
      display: flex;
      justify-content: flex-end;
      margin-right: var(--spacing-xs);
      align-items: center;
      height: var(--size-xs);
      min-width: var(--size-s);
    }

    &__name {
      margin-right: var(--spacing-xs);
      line-height: var(--line-height-m);
      display: flex;
      min-width: 0px;
      @include text-truncate-ellipsis;

      &__box {
        border-color: var(--border-color-strong);
        border-radius: var(--border-radius-s);
        font-size: inherit;
        height: auto;
        padding: 2px var(--spacing-xs);
      }

      .inline-input {
        height: var(--size-s);
      }
    }



    .api-definition-item__left-section__indent {
      border-color: transparent !important;
    }
  }

  .is-left-meta-visible {
    visibility: visible;
    display: flex;
    align-items: center;
  }

  .is-left-meta-not-visible {
    visibility: hidden;
    opacity: 0;
  }


}
.definition-item-focussed{
  box-shadow: 0px 0px 0px 2px var(--blue-30) inset
}

.api-definition-item-base {
  height: 28px;
  padding: var(--spacing-zero) var(--spacing-s);
}

.api-definition-item .sidebar-list-item__icon-container {
  width: 24px;
  justify-content: flex-end;
  margin-right: var(--spacing-xs);
}

.api-definition-sidebar {
  margin-right: 1px;
  display: flex;
  position: relative;

  &__root {
    background-color: var(--background-color-info);
    padding: var(--spacing-xs) var(--spacing-s);
    display: flex;
    align-items: center;
    border-radius: 16px;
  }

  &__root-title {
    color: var(--content-color-info);
    font-size: var(--text-size-s);
    font-weight: var(--text-weight-medium);
  }

  &-empty:last-child{
    .is-left-meta-not-visible{
      width: 0px;
    }
  }

  &__tour-trigger {
    height: 20px;
    width: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.api-definition-feature-branch {
  .api-definition-file-status {
    &__modify {
      path {
        fill: var(--yellow-40)
      }

      color:var(--yellow-40)
    }

    &__new {
      path {
        fill: var(--green-40)
      }

      color:var(--green-40)
    }
  }
}

.api-definition-loader {
  display: flex;
  height: 28px;
  padding: var(--spacing-zero) var(--spacing-s);

  &__loading {
    animation: fade 1s ease-in-out infinite;
    display: flex;
    align-items: center;
    padding: var(--spacing-s) 0;
    margin-left: var(--spacing-xl);
  }

  &__icon {
    height: 8px;
    width: 8px;
    background-color: var(--highlight-background-color-secondary);
    margin-left: var(--spacing-s);
    border-radius: var(--border-radius-s);
    margin-right: var(--spacing-m);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    height: 8px;
    background-color: var(--highlight-background-color-secondary);
    border-radius: var(--border-radius-s);
  }
}

.api-definition-migration {
  &__progress-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-l);
  }

  &__progress-container {
    position: relative;
    width: 250px;
    border-radius: 4px;
    height: 8px;
    border: 1px solid var(--content-color-tertiary);
  }

  &__progress {
    position: absolute;
    background-color: var(--content-color-tertiary);
    border-radius: 4px;
    height: 8px;
  }


  &__content-title {
    margin-bottom: var(--spacing-l);
  }

  &__content {
    border-radius: var(--border-radius-default);
    border: 1px;
    padding: var(--spacing-l);
  }

  &__content-header {

    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;


    &__text {
      font-weight: var(--text-weight-medium);
      background-color: var(--background-color-primary);
      padding-right: var(--spacing-m);
    }

    &__text-line {
      height: 1px;
      background-color: var(--border-color-strong);
      margin-top: var(--spacing-xs);
    }
  }

  &__content-point {
    position: relative;
    padding-left: var(--spacing-l);
    margin-top: 8px;

    &:before {
      position: absolute;
      content: '';
      height: 4px;
      width: 4px;
      left: 0px;
      top: 8px;
      border: 1px;
      border-radius: 50%;
      background-color: var(--content-color-primary);
    }
  }

  &__checkload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.api-definition-not-available {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-m);
}

.api-definition-delete-modal-content {
  line-height: var(--line-height-m);
}
