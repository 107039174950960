$request-snippet-background-color: var(--background-color-primary);
$request-snippet-header-color: var(--content-color-secondary);
$request-snippet-help-color: var(--content-color-secondary);
$request-editor-tabs-meta-color: var(--base-color-success);

$request-script-snippets-width: 250px;

.snippet-wrapper {
  flex: 1;

  // This is needed because of how flex calculates size.
  // See: https://www.w3.org/TR/css-flexbox-1/#min-size-auto
  min-height: 0;

  display: flex;
  flex-direction: column;

  width: $request-script-snippets-width;
  padding-left: 10px;
  box-sizing: border-box;

  font-size: var(--text-size-s);
  overflow-y: scroll;
  scrollbar-width: none;
}

.snippet-header {
  color: $request-snippet-header-color;
  display: flex;

  @include unselectable;

  &__help {
    margin-bottom: var(--spacing-s);

    &__text {
      color: $request-snippet-help-color;
      font-size: var(--text-size-s);
      font-family: var(--text-family-default);
    }

    &__link {
      padding-left: var(--spacing-zero);
      font-size: var(--text-size-s);
    }
  }

  &__title {
    flex: 1;
    align-self: center;
    color: $request-snippet-header-color;
  }

  &__left {
    margin-bottom: var(--spacing-xs);
  }

  .snippet-header__left {
    // Subtracting 36px to give space to the button 
    // placed with snippet-header__right class
    width: calc(100% - 36px);
  }

  .snippet-header__right {
    position: absolute;
    top: var(--spacing-xs);
    right: 0;
    z-index: 10;
  }

  &.ai-enabled {
    .snippet-header__help {
      margin-right: var(--spacing-m);
    }
  }
}

.snippet-items {
  flex: 1;
  min-height: 50%;
}

.snippet-item {
  padding: var(--spacing-xs) var(--spacing-s);
  padding-left: var(--spacing-zero);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  box-sizing: border-box;
  font-family: var(--text-family-default);

  @include unselectable;
  @include clickable;
}

.snippet-toggle-button {
  height: var(--size-m);
  width: var(--size-m);
}
