.import-container__offline-banner {
  display: flex;
  align-items: center;
  background-color: var(--background-color-warning);
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-l);

  .import-container__offline-banner__icon {
    @include icon-color(var(--content-color-warning));
  }

  .import-container__offline-banner__message {
    margin-left: var(--spacing-l);
    color: var(--content-color-warning);
  }
}

.import-files-options-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;

  .import-files-options-header {
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      flex-direction: row;
      &-title {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        color: var(--content-color-primary);
        margin-bottom: var(--spacing-m);
        margin-right: var(--spacing-s);
      }
      &-selected-files {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        margin-bottom: var(--spacing-m);
        margin-left: var(--spacing-s);
      }
    }
  }

  .import-files-options {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-shrink: 0;
    height: 100%;

    &-warning {
      margin-top: var(--spacing-xs);
    }

    .import-file-list-combined {
      max-height: 20vh;
      overflow-y: auto;
    }

    .import-file-list-container {
      .import-file-list {
        .data-editor {
          display: flex;
          flex-direction: column;
          overflow-y: auto;

          &__header {
            padding-left: var(--spacing-zero);
            flex: 0 0 30px;
            &__cell {
              font-size: var(--text-size-s);
              font-weight: var(--text-weight-medium);
              padding-left: var(--spacing-m);
              &__title {
                @include text-truncate-ellipsis;
                color: var(--content-color-secondary);
              }
            }
          }

          &__row {
            .dropdown {
              //check
              margin: var(--spacing-zero) 5px;
            }

            .dropdown-button {
              .btn {
                background-color: transparent;
                padding: var(--spacing-zero);
                color: var(--content-color-primary);
              }
            }
            &:last-child {
              display: none;
            }
            &:nth-last-child(2) {
              border-bottom: var(--border-width-default) solid var(--border-color-default);
            }
            .data-editor__custom-row {
              &.is-focused,
              &:hover {
                .data-editor__cells .data-editor__cell:last-child {
                  padding-right: var(--spacing-zero);
                }
              }
              .data-editor__cell {
                align-items: center;
                justify-content: flex-start;
                padding-left: var(--spacing-s);
                &__content .auto-suggest {
                  color: var(--content-color-primary);
                }
              }
            }
          }
        }
        &__column-enabled-cell, &__column-name-cell, &__column-format-cell {
          width: 100%;
          display: flex;
          padding: var(--spacing-xs);
          justify-content: flex-start;
          align-items: center;
          color: var(--content-color-primary);
          white-space: pre;
        }

        .data-editor__custom-row.is-disabled {
          .import-file-list {
            &__column-enabled-cell,
            &__column-name-cell,
            &__column-format-cell,
            &__import-as-format {
              color: var(--content-color-tertiary);
            }
          }
        }

        &__column-enabled-cell {
          justify-content: center;
          .is-selected {
            background-color: var(--base-color-brand);
          }
        }
        &__column-name-cell {
          &-content {
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &-folder-icon {
            .btn {
              cursor: default;
            }
          }
        }
        &__column-format-cell {
          &-content {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .import-select-workspace-container {
      margin-top: var(--spacing-xxl);

      .import-select-workspace-dropdown-header {
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
      }

      .import-select-workspace-dropdown {
        display: flex;
        margin-top: var(--spacing-s);

        .import-select-workspace-dropdown-button {
          flex: 0 0 300px;

          .btn {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }

    .import-generate-collection-container {
      margin-top: var(--spacing-xxl);

      .import-generate-collection-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: var(--spacing-s);

        .input-checkbox.is-selected {
          background-color: var(--base-color-brand);
        }

        .import-generate-collection-checkbox-label {
          // check
          margin-left: 10px;
          font-weight: var(--text-weight-medium);
          color: var(--content-color-primary);
        }
      }

      .import-select-api-element-dropdown-header {
        // check
        margin-left: 28px;
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        margin-bottom: var(--spacing-s);

        &.is-disabled {
          color: var(--content-color-tertiary);
          opacity: 0.6;
        }
      }
    }

    .import-options-container {
      margin-left: 28px;

      &.generation-disabled {
        margin-top: var(--spacing-l);
        margin-left: 0;
      }

      .import-options-header {
        display: flex;
        align-items: center;
        margin: var(--spacing-s) var(--spacing-zero);
        color: var(--content-color-primary);
        cursor: pointer;

        .toggle-button-icon {
          margin-right: 6px;
        }

        &.is-disabled {
          color: var(--content-color-tertiary);
          opacity: 0.6;

          .toggle-button-icon {
            @include icon-color(var(--content-color-tertiary))
          }
        }
      }

      .import-options-content-wrapper {
        margin-left: var(--spacing-xs);
        margin-top: var(--spacing-l);

        .import-options-content {
          margin-bottom: var(--spacing-xxl);

          .import-options-content-header {
            font-weight: var(--text-weight-medium);
            font-size: var(--text-size-s);
            margin-bottom: var(--spacing-s);
            color: var(--content-color-primary);
          }

          .options-wrapper {
            padding: 10px;
            border: 1px solid var(--border-color-default);
          }
        }
      }
    }
  }
}

.import-file-list {
  &__import-as-format {
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    margin: var(--spacing-zero) 5px;

    &--disabled-api-text {
      text-decoration: line-through;
      color: var(--content-color-secondary);
    }
  }
}
