.share-element-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  max-height: 196px;
  overflow-y: auto;
  padding-left: var(--spacing-s);

  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  // Added to display red border around the input field in case when 0 invites are left with the user.
  &__teamPlan_alert {
    border: var(--border-width-default) var(--border-style-solid) var(--base-color-error)
  }

  &__onFocus {
    border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
    box-shadow: 0px 0px 0px 2px var(--blue-30);
  }

  &__onError {
    border: var(--border-width-default) var(--border-style-solid) var(--base-color-error);
    box-shadow: unset;
  }

  &__empty-state--with-alert {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-error);
    margin-left: var(--spacing-xxl);
    margin-top: var(--spacing-s)
  }

  &__export {
    max-height: 116px;
  }

  .invite-placeholder {
    margin-left: var(--spacing-s);
    position: absolute;
    color: var(--content-color-tertiary);

    &__padding {
      margin-top: var(--spacing-s);
      margin-left: var(--spacing-m);
    }
  }

  .active-input {
    flex: 1;
    align-items: center;
    color:var(--content-color-primary);
    margin: var(--spacing-xs);
  }
}

.share-invite-tag {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;

  .avatar {
    margin: 0 var(--spacing-xs);
  }

  .user-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .user-group {
    width: calc(var(--size-s) / 2);
    height: calc(var(--size-s) / 2);
    padding: calc(var(--spacing-xs) / 2);
    margin: 0 var(--spacing-xs);
    align-items: center;
    justify-content: center;
    align-self: center;
    background: var(--background-color-tertiary);
    border-radius: var(--border-radius-max);
  }
}

.share-element-input-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: var(--text-size-m);
  height: 100%;

  .share-element-button {
    padding: var(--spacing-m) var(--spacing-l);
  }
}

.active-input-centered {
  line-height: var(--line-height-l);

  &:empty {
    &:before {
      content: attr(placeholder);
      color: var(--content-color-tertiary);
    }
  }
}

.share-element-team-users-dropdown {
  background-color: var(--background-color-primary);

  padding-right: var(--spacing-zero);

  .team-user-suggestion {
    height: var(--text-line-height-xxxl);
    margin: var(--spacing-zero);

    &__name {
      max-width: calc(8*var(--spacing-xxl));
      @include text-truncate-ellipsis;
    }

    .avatar {
      margin-right: var(--spacing-s);
    }

    &__email{
      margin-top:var(--spacing-xs);
      color: var(--content-color-secondary);
    }

    &__role{
      margin-top: var(--spacing-s);
      color: var(--content-color-secondary);
    }

    .team-user-suggestion__group-tag {
      width: var(--size-s);
      height: var(--size-s);
      margin-right: var(--spacing-s);
      align-items: center;
      justify-content: center;
      align-self: center;
      background: var(--background-color-tertiary);
      border-radius: var(--border-radius-max);
    }
  }
}
