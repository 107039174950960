$item-padding: 8px 10px;
$filter-item-hover-color: var(--highlight-background-color-secondary);

.search-bar-wrapper {
  position: relative;
  background-color: $filter-item-hover-color;
  border: 1px solid var(--border-color-strong);
  border-radius: var(--border-radius-default);

  ::-webkit-scrollbar {
    background-color: var(--background-color-secondary);
  }

  ul {
    list-style: none;
  }

  li {
    font-size: 12px;
    color: var(--content-color-primary);

    @include clickable;
  }

  .filtered-selector-input-wrapper {
    display: flex;
    align-items: center;

    .input-search-group {
      flex: 1;
      background-color: var(--background-color-tertiary);
      border: 1px solid transparent;

      @include hover {
        background-color: var(--highlight-background-color-tertiary);
      }

      @include focus {
        background-color: var(--background-color-primary);
      }
      .input{
        @include placeholder {
          font-size: 13px;
        }
      }
    }

    .btn {
      flex: 0 0 30px;
      height: 30px;
      padding: 0;
      margin-left: auto;
      background-color: var(--background-color-tertiary);
      border-left: 1px solid var(--border-color-strong);
      border-radius: 0;
      align-self: center;

      @include hover {
        background-color: var(--highlight-background-color-tertiary);
      }

      .dropdown-caret {
        margin-left: 0;
      }
    }
  }

  .sub-item:first-child {
    border-top: 0;
  }

  input {
    color: var(--content-color-primary);
  }

  .input-search-group {
    background-color: var(--background-color-secondary);
    border: 0;
    border-radius: 0;
 }

  &.is-overlaid {
    z-index: 1;

    .items-list {
      position: absolute;
      width: 100%;
      height: 0;
      border-radius: var(--border-radius-default);
      @include material-shadow-1;
    }
  }

  &.is-open {
    border: 1px solid var(--border-color-strong);

    .items-list {
      height: auto;
      max-height: 217px;
      margin-bottom: 10px;
      margin-top: 2px;
    }

    .input-search-group {
      background-color: var(--background-color-primary);
    }

  }

}

.items-list {
  height: 217px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.item {
  padding-bottom: 0;
  background-color: var(--background-color-primary);
  @include unselectable;

  .item-name {
    padding: $item-padding;
    word-break: break-all;

    @include focus {
      color: var(--content-color-primary);
      background-color: $filter-item-hover-color;
    }

    @include hover {
      color: var(--content-color-primary);
      background-color: $filter-item-hover-color;
    }
  }

  &.is-selected {
    .item-name {
      color: var(--content-color-constant);
      background-color: var(--base-color-brand);
    }
  }
}

.sub-item-wrapper {
  display: none;
  padding-left: 0;
  margin-left: -10px;
  background-color: $filter-item-hover-color;

  &.is-open {
    display: block;
    transition: all .4s ease-out;
  }
}

.sub-item {
  padding: $item-padding;
  margin-left: 20px;
  word-break: break-all;
  background-color: var(--background-color-primary);
  @include unselectable;
  @include focus {
    color: var(--content-color-primary);
    background-color: $filter-item-hover-color;
  }

  @include hover {
    color: var(--content-color-primary);
    background-color: $filter-item-hover-color;
  }

  &.is-selected {
    color: var(--content-color-constant);
    background-color: var(--base-color-brand);

    .matched-text {
      color: var(--content-color-primary);
    }
  }
}

.searched-item {
  padding: $item-padding;
  word-break: break-all;
  background-color: var(--background-color-secondary);

  @include focus {
    color: var(--content-color-primary);
    background-color: $filter-item-hover-color;
  }

  @include hover {
    color: var(--content-color-primary);
    background-color: $filter-item-hover-color;
  }

  &.is-selected {
    color: var(--content-color-constant);
    background-color: var(--base-color-brand);
}

}

.selected-item {
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
}

.highlight {
  color: var(--base-color-brand);
}

.is-selected {
  .highlight {
    color: var(--content-color-constant);
  }
}
