.pull-request-reviewers {
  &__option {
    .pm-user-icon {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-right: var(--spacing-s);

      svg {
        height: 12px;
        width: 12px;
      }
    }

    &-group-tag {
      width: var(--size-xs);
      height: var(--size-xs);
      margin-right: var(--spacing-s);
      margin-left: var(--spacing-s);
      align-items: center;
      justify-content: center;
      align-self: center;
      padding-left: var(--spacing-xs);
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);
      padding-right: var(--spacing-xs);
      background: var(--background-color-tertiary);
      border-radius: var(--border-radius-max);
    }

    &-reviewer {
      @include text-truncate-ellipsis;

      display: flex;
      max-width: 200px;
      vertical-align: top;
      margin-left: 8px;
      font-size: var(--text-size-m);
    }

    &-permission {
      color: var(--content-color-tertiary);
      float: right;
      position: relative;
      font-size: var(--text-size-m);
    }

    &-disabled {
      opacity: 0.5;
    }

    .avatar {
      margin-left: 8px;
    }
  }

  &__input {
    position: relative;

    .Select-control {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .Select--single {
      .Select-menu-outer {
        margin-top: 0;
        border-radius: 0;

        .Select-menu {
          .Select-option {
            padding-right: var(--spacing-l);
          }
        }
      }

      .Select-placeholder {
        padding-left: var(--spacing-xxl);
      }

      .Select-input {
        padding: 0 var(--spacing-xxl);
        width: 100%;

        & > input {
          max-width: 100% !important;
        }
      }
    }

    & > .pm-icon {
      position: absolute;
      top: 14px;
      left: 12px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &__selected {
    &-warning {
      margin-right: var(--spacing-xs);
      transform: translateY(20%);

      svg {
        color: var(--base-color-warning);
      }
    }

    &-group-tag {
      margin-left: var(--spacing-s);
      align-items: center;
      justify-content: center;
      align-self: center;
      padding-left: var(--spacing-xs);
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);
      padding-right: var(--spacing-xs);
      background: var(--background-color-tertiary);
      border-radius: var(--border-radius-max);
    }

    &-permission {
      color: var(--content-color-tertiary);
    }
  }
}
