@import './documentation-list';

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentation-workbench-view {
  --single-column__width: calc(32 * var(--size-m));
  --min-documentation-body-width: 360px;
  --api-documentation-sidebar-width: 285px;
  // @TODO: Remove the hardcoded 286px value
  // with the implementation of new scroll behavior, it won't be
  // needed in future as the it can be set as the container height
  --max-editor-height: calc(100vh - 286px);
  --tab-meta-view-height: 210px;
  --documentation-bottom-padding: calc(10 * var(--spacing-m));

  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  padding: var(--spacing-zero);
  font-size: var(--text-size-l);
  height: 100%;
  position: relative;

  &.is-loading {
    // Vertically center content as well, if we're just showing the loader. To be removed when we switch to the
    // skeleton loader.
    height: 100%;
    align-items: center;

    .loading-indicator-wrapper {
      height: 100%;
      align-items: center;
      display: flex;
    }
  }

  .config-bar {
    border-bottom: thin var(--border-style-solid) var(--border-color-default);
    background-color: var(--background-color-primary);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    z-index: 2; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */

    &__upper-deck,
    &__lower-deck {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      min-height: var(--size-xs);
      width: inherit;
      padding: var(--spacing-zero) var(--spacing-l);
    }

    .documentation-header {
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;

      .documentation-fdv-breadcrumb {
        > ul > li:last-child {
          color: var(--content-color-primary);

          > div {
            display: flex;
            align-items: center;
          }
        }
      }

      .documentation-title {
        color: var(--content-color-primary);
        font-weight: var(--text-weight-medium);

        @include ellipsis();
      }

      .copy-link-button {
        background-color: transparent;
        visibility: hidden;
      }

      @include hover {
        .copy-link-button {
          visibility: visible;
        }
      }
    }

    .config-options {
      display: flex;
      align-items: center;
      font-style: normal;

      .config-entity {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        padding-right: var(--spacing-l);

        label {
          color: var(--content-color-secondary);
          font-size: var(--text-size-m);
          font-weight: var(--text-weight-regular);
        }

        .btn-text {
          color: var(--content-color-primary);
          font-size: var(--text-size-m);
          font-weight: var(--text-weight-regular);
          @include icon-color(var(--content-color-primary));
        }

        .dropdown-caret {
          svg {
            color: var(--content-color-primary);
          }
        }

        .language-settings-icon {
          cursor: pointer;
        }
      }
    }

    &__upper-deck {
      padding: var(--spacing-s) var(--spacing-l);

      .config-options {
        .config-entity {
          svg > path {
            fill: var(--content-color-secondary);
          }
          svg {
            color: var(--content-color-secondary);
          }

          padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-s);

          .config-bar-button {
            height: var(--size-m);
            font-size: var(--text-size-m);
            color: var(--content-color-secondary);

            &.published {
              color: var(--content-color-success);

              .dropdown-caret {
                svg > path {
                  fill: var(--content-color-success);
                }
                svg {
                  color: var(--content-color-success);
                }
              }
            }

            .config-bar-icon {
              margin-right: var(--spacing-xs);

              &.published-icon {
                svg {
                  color: var(--content-color-success);
                }
              }
            }
          }
        }
      }
    }

    .documentation-dropdown-button {
      padding: var(--spacing-s) var(--spacing-m);
      color: var(--content-color-primary);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
    }

    .documentation-config-bar-selector {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .documentation-body-wrapper {
    flex: 1;
    overflow: auto;
  }

  .request-overview-container {
    width: 100%;
    box-sizing: border-box;
    max-width: var(--single-column__width);
    padding: var(--spacing-l) var(--spacing-xxl);
    margin: var(--spacing-zero) auto;

    .documentation-item-name__title input{
      font-size: 28px;
      margin-left: var(--spacing-s);
    }

    .doc-heading {
      font-size: 20px;
      line-height: var(--line-height-s);
      font-weight: var(--text-weight-medium);

      &-kv {
        line-height: var(--line-height-xxl);
        letter-spacing: 0px;
      }
    }

    .request-url-core{
      margin-left: var(--spacing-s);
    }

    .description-editor-container {
      margin-left: var(--spacing-xs);

      .editor__container {
        div {
          padding-left : var(--spacing-xs) !important;
          padding-bottom : var(--spacing-zero) !important;
        }
      }
    }
    .request-overview-header-edit{
      display: flex;
      flex-wrap: wrap;
      row-gap: var(--spacing-l);

      .documentation-item-name {
        flex: 1 1 1 !important;
        min-width: 250px;
      }
    }
  }

  .documentation-body-container {
    width: 100%;
    box-sizing: border-box;
    max-width: var(--single-column__width);
    padding: var(--spacing-zero) var(--spacing-xxl);
    margin: var(--spacing-l) auto var(--spacing-zero) auto ;
    display: grid;
    grid-template-columns: minmax(var(--min-documentation-body-width), 2.75fr) 1fr;
    grid-gap: var(--spacing-xxl);
    align-items: flex-start;

    &.api-documentation-body-container {
      max-width: calc(var(--single-column__width) - 285px);

      // setting only in network mode - owned by API Network
      &__network {
        grid-template-columns: unset;
      }
    }

    .documentation-body {
      padding-bottom: var(--documentation-bottom-padding);
      flex: 1;

      // .entity-documentation {
      //   margin-top: var(--spacing-l);
      // }

      & > * + * {
        margin-top: var(--spacing-xxl);
      }

      .open-request-button {
        flex-direction: row-reverse;
        padding-left: 10px;
        font-weight: var(--text-weight-regular);
        font-size: var(--text-size-l);
        line-height: var(--line-height-xxxl);
      }

      .documentation-wrapper {
        gap: var(--spacing-xxl);
      }
    }

    hr {
      height: 1px;
      border: none;
      margin: var(--spacing-m) var(--spacing-zero);
      background: var(--border-color-default);
    }

    .folder-documentation,
    .request-documentation {
      &.docs-workbench-view {
        height: auto;
      }
    }

    .side-navigation {
      border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      padding-left: var(--spacing-l);

      &__publisher-info {
        padding-left: var(--spacing-s);
        padding-bottom: var(--spacing-xl);
      }
    }
  }

  .context-bar-header {
    justify-content: start;
  }


  .documentation-item-name {
    flex: 1;
    border: 1px solid var(--base-color-info);
  }

  .documentation-core-item-name__text {
    cursor: text;
  }

  .documentation-core-item-name__text:hover {
    background-color: var(--background-color-secondary);
  }

  .documentation-description__editor-container {
    padding-top: var(--spacing-l);
  }

  .description-editor-container {
    width: calc(100% + var(--spacing-m));
    margin-left: -6px;
  }

  .entity-authorization {
    padding: 0 var(--spacing-s);
  }

  .core-request-items-table {
    padding: 0 var(--spacing-s);
  }

  .core-request-body {
    padding: 0 var(--spacing-s);
  }

  .example-wrapper {
    padding: 0 var(--spacing-s);
  }

  [data-entity-type="request-body"] {
    padding: 0 var(--spacing-s);
  }

  .collection-container {
    display: flex;
    flex-direction: column;
    word-break: break-word;

    .documentation-core-item-name__text {
      margin-top: var(--spacing-l) !important;
      h1 {
        border: 1px solid transparent;
        padding: 0;
        padding-left: var(--spacing-xs);
        span {
          padding: 0;
        }
      }
    }

    .context-bar-header {
      margin-top: var(--spacing-l) !important;
      h1 .inline-input__wrapper {
        height: calc(var(--line-height-xxxl));
      }
    }

    .input-field {
      padding: 0;
      padding-left: var(--spacing-xs);
    }

    .input-box {
      width: 100%;
      padding: 0;
      font-size: 28px;
      border: none;
    }
  }

  .folder-container {
    position: relative;
    flex-direction: column;
    gap: var(--spacing-l);
    word-break: break-word;
    margin-top: var(--spacing-zero);

    &:hover .entity-copy-button{
      display: block;
    }

    .documentation-core-item-name__text {
      h2 {
        border: 1px solid transparent;
        padding: 0;
        padding-left: var(--spacing-xs);
        div {
          padding: 0;
        }
      }
    }

    .context-bar-header {
      h2 .inline-input__wrapper {
        height: calc(var(--line-height-xxxl));
      }
    }

    .input-field {
      padding: 0;
      padding-left: var(--spacing-xs);
    }

    .input-box {
      width: 100%;
      padding: 0;
      font-size: var(--text-size-xxxl);
      line-height: var(--text-size-xxxl);
      border: none;
    }
  }

  .entity-copy-button{
    position: absolute;
    left: -28px;
    top: 6px;
    display: none;
  }

  .request-container {
    flex-direction: column;
    gap: var(--spacing-l);
    word-break: break-word;
    position: relative;
    margin-top: var(--spacing-zero);

    .context-bar-header {
      align-items: normal;

      h3 span:first-child {
        padding-right: var(--spacing-s);
      }

      h3 div:first-child {
        padding-left: 0;
      }

      .inline-input__wrapper {
        height: calc(var(--line-height-xxxl));
      }
    }
    .documentation-core-item-name__text {
      h3 {
        border: 1px solid transparent;
        padding: 0;
        margin-left: var(--spacing-s);
      }
    }

    .request-method {
      font-size: var(--text-size-xxxl);
      line-height: var(--text-size-xxxl);
    }

    .input-box {
      width: 100%;
      padding: 0;
      font-size: var(--text-size-xxxl);
      border: none;
      line-height: var(--text-size-xxxl);
    }

    code[data-aether-id='aether-text-code'] {
      font-size: var(--text-size-m);
    }

    .aether-dropdown__control {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    .aether-dropdown__control--is-focused {
      border: none !important;
      box-shadow: none !important;
    }

    &:hover .entity-copy-button{
      display: block;
    }
  }

  .example-response-container {
    position: relative;
    margin-top: var(--spacing-m);

    .example-response-tag {
      position: absolute;
      top: 28px;
      right: 0;
    }
  }
}

.documentation-dropdown {
  .select-dropdown-btn {
    span {
      max-width: calc(10 * var(--size-m));

      @include ellipsis;
    }
  }

  &--config-bar {
    .documentation-dropdown-menu-container {
      --version-selector-min-width: calc(5 * var(--size-s));
      --version-selector-max-width: calc(15 * var(--size-s));

      overflow: auto;
      padding: var(--spacing-s) var(--spacing-zero);
      max-height: calc(10 * var(--size-m));
      min-width: var(--version-selector-min-width);
      max-width: var(--version-selector-max-width);
    }
  }

  .documentation-dropdown-menu-container {
    .dropdown-menu-item {
      height: auto;
      min-height: var(--controls-size-default);
    }

    .input-search-group {
      border-radius: var(--border-radius-default);
      min-height: 30px;
      margin: var(--spacing-s);
    }

    .documentation-dropdown-no-results {
      padding: var(--spacing-m);
      font-size: var(--text-size-m);
      color: var(--content-color-tertiary);
    }

    .documentation-dropdown-item {
      padding: var(--spacing-xs) var(--spacing-s);
      @include ellipsis;

      .published-url {
        color: var(--content-color-link);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.language-dropdown {
    .documentation-dropdown-menu-container {
      min-width: 250px;
    }
  }
}
