.markdown-base-content {
  padding: 10px 0px 0px 0px;

  .markdown-editor-text-wrapper {
    width: 100%;

    .markdown-editor-text {
      color: var(--content-color-secondary);
      font-family: $font-primary;
      line-height: 21px;
      overflow: hidden;
      position: relative;
      @include markdown;
      @include selectable;

      p {
        word-break: break-word;
      }

      h1, h2, h3, h4, h5, h6 {
        line-height: 1.6
      }

      // http://sndsgn.github.io/headings/
      h1 {
        // font-size: 2em;
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
        font-weight: var(--text-weight-medium);
      }
      h2 {
        // font-size: 1.5em;
        font-size: var(--text-size-xxxl);
        line-height: var(--line-height-xxxl);
        font-weight: var(--text-weight-medium);
      }
      h3 {
        // font-size: 1.17em;
        font-size: var(--text-size-xxl);
        line-height: var(--line-height-xxl);
        font-weight: var(--text-weight-medium);
      }
      h4 {
        // font-size: 1em;
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
        font-weight: var(--text-weight-medium);
      }
      h5 {
        font-size: 0.83em;
      }
      h6 {
        font-size: 0.67em;
      }

      ol, ul {
        margin: var(--spacing-zero) var(--spacing-xl);
        padding: var(--spacing-zero);

        li {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          margin-bottom: var(--spacing-s);
          display: list-item;

          & ol, & ul {
            margin-top: var(--spacing-s);
            margin-bottom: var(--spacing-s);
          }
        }
      }
    }
  }
}
