.console-header {
  display: flex;
  align-items: center;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: 10px 10px 9px 10px; // Reducing the padding the bottom by 1px to account for the border
  background: var(--background-color-primary);
  z-index: 10;

  .console-header__left {
    flex: 2;
    display: flex;
    align-items: center;

    .search-input {
      margin-right: var(--spacing-m);
      flex-grow: 1;

      .input-search-group {
        border-radius: var(--border-radius-default);
        padding-right: var(--spacing-zero);

        .input-search-group__search-cancel-wrapper{
          padding-right: var(--spacing-s);
        }

        &.is-focused.is-highlighted {
          border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
          border-radius: var(--border-radius-default);
        }
      }
    }
  }

  .console-header__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    .clear-btn {
      min-width: 70px;
      margin-left: var(--spacing-m);
    }

    .copy-btn {
      margin-left: var(--spacing-s);
    }
  }
}
