.enable-auto-flex {
  &__action {
    display: flex;
    justify-content: center;
    align-items: center;

    &-cancel {
      margin-right: var(--spacing-s);
    }

    &-proceed {
      min-width: 140px;
    }
  }

  &__checkbox.is-selected {
    background-color: var(--base-color-brand);
  }

  &__checkbox-wrapper {
    display: flex;
    margin-top: var(--spacing-xl);
  }

  &__checkbox-label {
    margin-left: var(--spacing-s);
    color: var(--content-color-secondary);
  }
}
