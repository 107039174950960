@import '../../../../../../../../node_modules/react-image-crop/lib/ReactCrop';

.pm-image-input {
  text-align: center;

  &__input {
    position: relative;
    border: solid var(--border-width-default) var(--border-color-default);
    padding: calc(var(--spacing-xxl) * 2) 0;
    input {
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  &__input-prompt {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    text-align: center;
    margin: var(--spacing-l) 0;
  }

  &__input-prompt-drag {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    text-align: center;
    margin: var(--spacing-l) 0;
    font-weight: var(--text-weight-bold);
  }

  &__input-button {
    padding: var(--spacing-zero);
    transform: translateX(0);

    input {
      cursor: pointer;
    }

    label {
      height: 100%;
      display: block;
      cursor: pointer;
      line-height: var(--line-height-xxxl);
      padding: 0 var(--spacing-l);
    }
  }
}
