.schema-request-access-tooltip {
  
  .tooltip-wrapper {
    max-width: 240px;
    padding: var(--spacing-m);
    margin-left: var(--spacing-l);

    .btn {
      margin-top: var(--spacing-m);
    }
  }
}
