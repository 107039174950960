$collection-sidebar-list-item-folder-height: 28px;
$collection-sidebar-list-item-folder-icon-wrapper-width: 50px;
$collection-sidebar-list-item-folder-body-indent-width: 40px;

.collection-sidebar-list-item__folder {
  display: flex;
  flex-direction: column;

  .collection-sidebar-list-item__folder__head {
    flex: 0 0 auto;
  }

  .collection-sidebar-list-item__folder__body {
    flex: 0 0 auto;
  }
}

.collection-sidebar-list-item__folder {

  &.is-drop-hovered-top {
    box-shadow: 0 -2px 0 var(--base-color-brand);
  }

  &.is-drop-hovered-bottom {
    box-shadow: 0 2px 0 var(--base-color-brand);
  }

  &.is-hidden {
    display: none;
  }
}

.collection-sidebar-list-item__folder__head {
  display: flex;
  flex-direction: row;
  height: $collection-sidebar-list-item-folder-height;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--global-navigation-background-color);

  &.is-pending {
    .collection-sidebar-list-item__folder__head__actions {
      visibility: visible;
    }
  }


  &.is-active {
    background-color: var(--highlight-background-color-secondary);
  }

  &.is-focused {
    &.is-sidebar-in-focus {
      box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
    }
  }

  &.is-selected {
    background-color: var(--highlight-background-color-primary);
    &.is-sidebar-in-focus {
      background-color: var(--highlight-background-color-tertiary);
      box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset;
      &.is-focused {
        box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset, 0px 0px 0px 2px var(--blue-30) inset;
      }
    }
  }

  &.is-selected {
    &.is-sidebar-in-focus {
      .collection-sidebar__indent {
        &.active-indent {
          border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
        }
      }
    }
  }

  // We are temporarily disabling this until we figure out all the edge cases associated with cut state.
  // &.is-cut {
  //   opacity: 0.5;
  // }

  @include hover {
    background-color: var(--highlight-background-color-secondary);

    &.is-multi-selection-active {
      .collection-sidebar-list-item__folder__head__actions {
        visibility: hidden;
      }
    }
  }


  .collection-sidebar-list-item__folder__icon-wrapper {
    flex: 0 0 28px;
    display: flex;
    justify-content: flex-end;
    margin: var(--spacing-zero) var(--spacing-xs);
  }

  .collection-sidebar-list-item__folder__head__meta {
    flex: 1;
  }

  .collection-sidebar-list-item__folder__head__actions {
    flex: 0 0 var(--size-s);
  }

  &.is-right-above {
    box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
  }

  // &.is-dragged {
  //   opacity: 0.1;
  // }

  &.is-drop-hovered {
    position: relative;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 2px;
      background-color: var(--base-color-brand);
    }
  }

  @include unselectable;
  @include clickable;
}

.collection-sidebar-list-item__folder__body {
  padding-left: 10px;

  &.is-dragged {
    opacity: 0.1;
  }

  .collection-sidebar-list-item__folder__body--empty {
    padding-left: 20px;
  }
}

.collection-sidebar-list-item__folder__head__meta {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-family: $font-primary;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  align-items: center;

  .collection-sidebar-list-item__folder__head__name {
    line-height: var(--line-height-m);
    padding-right: var(--spacing-xs);
    @include text-truncate-ellipsis;
  }
}

.collection-sidebar-list-item__folder__head__icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.collection-sidebar-folder-dropdown-actions-wrapper {
  .btn {
    width: var(--size-s);
    height: var(--size-s);
    padding: var(--spacing-zero);

    .entity-actions-dropdown__icon {
      @include icon-color(var(--content-color-secondary));
    }

    &.is-active {
      .entity-actions-dropdown__icon {
        @include icon-color(var(--content-color-primary));
      }
    }

    @include hover {
      .entity-actions-dropdown__icon {
        @include icon-color(var(--content-color-primary));
      }

      &.is-active {
        .entity-actions-dropdown__icon {
          @include icon-color(var(--content-color-secondary));
        }
      }
    }
  }
}
