.image-upload-modal{
  &-close {
    position: absolute;
    right: var(--spacing-l);
    top: var(--spacing-l);
    cursor: pointer;
    padding: 0 var(--spacing-xs);
  }
  &-upload {
    flex-grow: 2;
  }
  &-back {
    margin-left: var(--spacing-zero) !important;
    flex-grow: 1;
  }
}
