$request-body-selector-dropdown-width: 210px;

.request-body-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.request-body-type-selector-wrapper {
  @include unselectable;
  padding: var(--spacing-xs) var(--spacing-l);

  display: flex;
  flex-direction: row;
  align-items: center;

  .request-body-type-selector-wrapper-container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .request-body-schema-selector-wrapper-container {
    display: flex;
    align-items: center;

    .layout-2-column & {
      justify-content: flex-start;
    }

    .layout-1-column & {
      flex: 1;
    }
  }

  .request-body-type-selector-wrapper-actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .request-body-type-selector-beautify {
      display: flex;
      align-items: center;

      @include unselectable;
      @include clickable;

      @include disabled {
        cursor: default;
      }

      @include hover {
        background-color: var(--background-color-brand);
      }
    }
  }

  .request-body-raw-language-selector {
    display: flex;
  }
}

.request-body-type-selector-buttons-dropdown .dropdown-button {
  .btn-secondary {
    box-shadow: none;
    background-color: var(--button-secondary-background-color);
  
    &:hover {
      background-color: var(--button-secondary-hover-background-color);
    }

    &.is-active {
      background-color: var(--button-secondary-active-background-color);
    }
  }
}

.request-body-type-selector-buttons {
  display: flex;
  height: var(--size-m);

  @include disabled {
    opacity: 0.3;
  }
}

.request-body-type-selector-button {
  display: inline-flex;
  align-items: center;
  margin: var(--spacing-xs) var(--spacing-s);

  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);

  input {
    margin-right: var(--spacing-xs);
  }
}

.request-body-raw-language-selector-dropdown {
  .dropdown-button {
    display: flex;
  }

  .dropdown-menu {
    min-width: $request-body-selector-dropdown-width;
  }
}

.request-body-graphql-schema-selector {
  display: inline-flex;

  .dropdown {
    display: flex;
  }

  .dropdown-button {
    display: flex;
    align-items: center;
  }

  .request-body-graphql-schema-selector-refresh-icon-wrapper {
    .btn {
      padding: var(--spacing-zero);
    }

    .request-body-graphql-schema-selector-refresh-icon {
      @include unselectable;
      @include clickable;

      @include hover {
        @include icon-color($icon-color-secondary);
      }

      @include active {
        @include icon-color($icon-color-secondary);
      }

      &.is-loading {
        cursor: default;
        -webkit-animation: rotating 1s linear infinite;

        @include hover {
          @include icon-color(var(--content-color-secondary));
        }
      }
    }
  }

  .request-body-graphql-schema-selector-introspection-state {
    margin-left: var(--spacing-s);
    padding: var(--spacing-zero);
    font-size: var(--text-size-s);
    font-weight: var(--text-weight-medium);
  }

  .request-body-graphql-schema-selector-warning-icon-wrapper {
    .btn{
      padding: var(--spacing-zero);
      margin-left: var(--spacing-s);

      .warning-icon {
        @include icon-color(var(--base-color-warning));
        position: relative;
        top: 1px;
        margin-right: 5px;
      }
    }
  }
}

.request-body-graphql-schema-selector-dropdown {
  display: flex;

  .dropdown-menu {
    max-width: $request-body-selector-dropdown-width;
  }

  .btn.btn-text {
    max-width: $request-body-selector-dropdown-width;
  }

  .request-body-graphql-current-schema {
    @include text-truncate-ellipsis;
  }

  .request-body-graphql-schema-list-entry {
    @include text-truncate-ellipsis;
  }
}

.request-body-graphql-schema-selector-offline-message {
  .alert {
    color: var(--content-color-tertiary);
  }
}

.request-body-graphql-schema-selector-signed-out-dropdown {
  text-align: center;
  min-width: 320px;

  .entity-empty {
    border: none;
    padding: var(--spacing-xxl) var(--spacing-l);

    .entity-empty__title {
      font-size: var(--text-size-l);
      margin-bottom: var(--spacing-l);
    }

    .entity-empty__subtitle {
      margin-bottom: var(--spacing-xl);
    }

    .entity-empty__title,
    .entity-empty__subtitle {
      @include unselectable;
    }

    .entity-empty__learn {
      .entity-empty__learn__mouse-click-icon {
        padding-right: var(--spacing-s);
      }
    }
  }
}

.request-body-unavailable {
  margin: var(--spacing-l);
}
