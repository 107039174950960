.console-actions {
  display: flex;
  align-items: center;
  @include unselectable;

  .console-actions__meta {
    display: flex;

    .meta__item {
      margin-right: var(--spacing-s);
      display: flex;
      align-items: center;
      .meta__error{
        color: var(--content-color-primary);
      }
      .meta__error--icon{
        margin-right: var(--spacing-xs);
      }
      .meta__warning{
        color: var(--content-color-primary);
      }
      .meta__warning--icon{
        margin-right: var(--spacing-xs)
      }
    }
  }

  .console-actions__separator {
    height: var(--size-xs);
    width: var(--border-width-default);
    background: var(--border-color-default);
  }

  .console-actions__clear-button {
    display: flex;
    margin-right: var(--spacing-s);
    .clear-btn {
      // we're setting this because the header height is 32px and smallest
      // button height is 30px.
      height: var(--size-s);
    }
  }

  .console-actions__settings {
    .dropdown-button {
      display: flex;
    }
    @include icon-color(var(--content-color-secondary));

    @include hover {
      @include icon-color(var(--content-color-primary));
    }
  }

  .console-actions__open-window-button {
    height: var(--size-xs);
    width: var(--size-xs);
    @include clickable;
  }
}
