/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.monitor-performance {
  border-bottom: var(--border-width-default) solid var(--border-color-strong);

  h4 {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-s);
  }

  &__actions {
    position: relative;
    margin-top: var(--spacing-l);
    padding-left: var(--spacing-l);
  }

  &__split-selector {
    display: block;
    margin-bottom: var(--spacing-l);
    color: var(--content-color-primary);

    label {
      font-size: var(--text-size-m);
      padding-right: var(--spacing-m);
      cursor: pointer;
    }

    .radio-button {
      vertical-align: middle;
      margin-right: var(--spacing-xs);
      -moz-appearance: initial;
    }
  }

  // @todo fix hover
  // @todo fix bottom border radius in styleguide
  &__request-selector,
  &__region-selector,
  &__aggregate-selector {
    margin-right: 14px;
    width: 176px; // according to design mock

    .input-select-v2-wrapper {
      align-items: center;
      background-color: var(--background-color-secondary);
      border: var(--border-width-default) solid var(--border-color-default);
      box-sizing: border-box;
      display: flex;
      height: var(--size-m);
      position: relative;
      width: inherit;
      font-weight: normal;

      .input-search-group .input {
        font-weight: normal;
        font-size: var(--text-size-m);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__empty-state {
    color: var(--content-color-primary);
    text-align: center;
    margin: 60px 0px 100px 0px;
    font-size: var(--text-size-m);

    .empty-state__title {
      font-size: inherit;
      font-weight: var(--text-weight-medium);
    }

    .empty-state__image {
      &.activity {
        @include icon("../assets/images/empty-state/monitor-activity.svg", 160px, 126px);
      }
    }

    &--loading {
      margin: 10% 0 0 0;

      .loading-indicator {
        transform: scale(1.5);
      }
    }
  }

  &__job-filters-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > label {
      font-size: var(--text-size-m);
      color: var(--content-color-primary);
      margin-right: var(--spacing-s);
    }

    @media (max-width: 1500px){
      & > label{
        min-width: 100%;
      }
      & > * {
        margin-bottom: var(--spacing-s);
      }
    }
  }

  &__job-filters-panel {
    text-align: left;

    .btn-secondary {
      width: inherit;
      color: var(--content-color-primary);
      padding: 0px 0px 0px 0px;
      height: var(--size-m);
      font-size: var(--text-size-m);
    }
  }

  &__job-filters-selector {
    margin-right: 14px;

    .pm-btn-secondary {
      text-align: left;
      background-color: var(--background-color-secondary);
      border: var(--border-width-default) solid var(--border-color-default);
      width: 136px; //according to design mock
      color: var(--content-color-primary);
      padding: 0px 0px 0px 10px;
      max-width: 400px;
      height: var(--size-m);
      font-style: normal;
      font-weight: normal;
      font-size: var(--text-size-m);
    }

    .filter-label {
      display: inline-block;
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }

    .filter-arrow {
      vertical-align: middle;
      display: inline-block;
    }

    .multi-select-entity__list {
      margin-top: 2px;
      border: 0.5px var(--border-style-solid) var(--border-color-strong);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      height: auto;
      width: 136px; // according to design mock
      overflow: auto;
      overflow-x: hidden;
      z-index: 1000;
      font-style: normal;
      font-weight: normal;
      font-size: var(--text-size-m);
      position: absolute;
      background-color: var(--background-color-secondary);

      .multi-select-entity__item {
        display: flex;
        height: 30px;
        align-items: center;
        padding: 0px 5px;
        cursor: pointer;
        color: var(--content-color-primary);

        .input-checkbox {
          margin-right: 10px;
          flex-shrink: 0;
        }

        .multi-select-entity__item--name {
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:first-child {
          border: var(--border-width-default) solid var(--border-color-default);
        }
      }
    }

    .dropdown-caret {
      margin-right: var(--spacing-s);
    }
  }

  &__job-filters-options--disabled {
    .multi-select-entity__item {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  &__job-filters-selector,
  &__job-filters-options {
    width: auto;
  }

  @media (min-width: $breakpoint-lg) {
    &__job-filters-options {
      width: 200px;
    }

    &__region-selector,
    &__request-selector {
      .Select {
        width: 200px;
      }
    }
  }
}
