.doc-workspace-list-item {
  display: flex;
  height: var(--size-m);
  align-items: center;
  @include clickable;

  .loading-indicator {
    background-color: var(--content-color-secondary);
    max-height: 10px;

    &:before {
      background-color: var(--content-color-secondary);
      max-height: 10px;
    }

    &:after {
      background-color: var(--content-color-secondary);
      max-height: 10px;
    }
  }

  span {
    font-weight: var(--text-weight-medium);
    color: var(--content-color-secondary);
  }

  .doc-workspace__join {
    display: flex;
    align-items: center;
    padding: 6px;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-s);
    line-height: var(--line-height-xs);
    height: var(--controls-size-small);
    border-radius: var(--border-radius-s);
    background: var(--background-color-tertiary);
    color: var(--content-color-secondary);
  }

  .doc-workspace__shared {
    display: flex;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    opacity: 1;
    color: var(--content-color-secondary);
  }

  .doc-workspace-list-item__name {
    flex: 1;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin-left: var(--spacing-s);
    font-family: $font-primary;
    color: var(--content-color-primary);
    user-select: none;
    @include text-truncate-ellipsis;
  }

  &.is-disabled {
    opacity: 0.5;
    cursor: default;
  }
}
