/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$enable-transitions:        true !default;
$enable-gradients:          false !default;
$transition-base:           all .2s ease-in-out !default;

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

// Carousel
$carousel-control-width:            15% !default;
$carousel-control-opacity:          .5 !default;

$carousel-indicator-width:          8px !default;
$carousel-indicator-height:         8px !default;
$carousel-indicator-spacer:         7px !default;

$carousel-caption-width:            70% !default;
$carousel-control-icon-width:       20px !default;

$carousel-transition:               transform .6s ease !default;

// Wrapper for the slide container and indicators
.carousel {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  justify-content: center;
  flex-direction: column;

  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  @include transition($carousel-transition);
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item-next,
.carousel-item-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.carousel-item.active {
  display: flex;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

// CSS3 transforms when supported by the browser
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  // margin-bottom: 100px;

  position: absolute;
  top: 0;
  bottom: 0;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: $carousel-control-width;
  color: var(--content-color-constant);
  text-align: center;
  opacity: $carousel-control-opacity;
  // We can't have a transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.

  // Hover/focus state
  @include hover {
    color: var(--content-color-constant);
    text-decoration: none;
    outline: 0;
    opacity: 1;
    cursor: pointer;
  }

  .pm-icon-sm {
    height: 38px;
    width: 16px;

    svg {
      height: 38px;
      width: 16px;
    }
  }
}
.captain {
  display: flex;
}


.carousel-control-prev {
  left: 0;
  bottom: 116px;
  @if $enable-gradients {
    background: linear-gradient(90deg, rgba(0, 0, 0, .25), rgba(0, 0, 0, .001));
  }
}
.carousel-control-next {
  right: 0;
  bottom: 116px;
  @if $enable-gradients {
    background: linear-gradient(270deg, rgba(0, 0, 0, .25), rgba(0, 0, 0, .001));
  }
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-indicators {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-left: 0; // override <ol> default
  // Use the .carousel-control's width as margin so we don't overlay those
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    position: relative;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    border-radius: 50%;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    background-color: var(--highlight-background-color-tertiary);

    // Use pseudo classes to increase the hit area by 10px on top and bottom.
    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: var(--content-color-tertiary);
  }
}

.carousel-caption {
  z-index: 10;
  padding: 10px 20px;
  color: var(--content-color-constant);
  text-align: center;
  @include unselectable;
}
