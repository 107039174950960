.snippet-modal {
  --max-content-height: 70vh;
  max-height: var(--max-content-height);

  .modal-content {
    overflow: hidden;
  }

  .highlighted-code--snippet-modal {
    max-height: calc(var(--max-content-height) - 4 * var(--spacing-xl));
  }
}
