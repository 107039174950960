.workspace-page-discoverable-teams {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xl);

  &__subtitle {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-l);
    .domain {
      color: var(--base-color-info);
    }
  }

  &-list {
    margin-top: calc(var(--spacing-xs) + var(--spacing-l));
    width: 400px;
    & > * + * {
      margin-top: var(--spacing-s);
    }
    &__item {
      @include clickable;
      display: flex;
      list-style-type: none;
      padding: var(--spacing-s);
      height: 48px;
      background: var(--background-color-primary);
      border: var(--border-width-default) solid var(--border-color-default);
      box-sizing: border-box;
      border-radius: 4px;
      align-items: center;
      max-width: 580px;

      &--team-icon {
        width: 32px;
        object-fit: contain;
      }

      &--team-name {
        @include text-truncate-ellipsis;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        margin-left: var(--spacing-s);
        color: var(--content-color-primary);
        max-width: 184px;
      }
    }
  }

  &-actions {
    margin-top: var(--spacing-l);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    font-weight: var(--text-weight-medium);
    color: var(--content-color-brand);
    width: 400px;
    &--center {
      justify-content: center;
    }

    &--view-more {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      padding-left: 0;
    }

    &--create-team {
      height: 32px;
      i {
        margin-right: var(--spacing-s);
      }
    }
  }
}
