.websocket-message-pane-label {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  height: var(--size-m);
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-l);

  &__text {
    @include clickable;
    user-select: none;
  }

  &__actions {
    display: flex;
    align-items: center;

    &__status {
      display: flex;
      align-items: center;
      cursor: default;
    }

    &__arrow {
      display: flex;
      margin-block: var(--spacing-s);
      padding-left: var(--spacing-s);

      @include clickable
    }
  }
}

.websocket-connection-statistics {
  width: 270px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--size-s);
    margin-top: var(--spacing-xs);

    &:first-child {
      margin-top: unset;
    }

    &__header {
      color: var(--content-color-secondary);
    }

    &__label {
      color: var(--content-color-secondary);
    }

    &__value {
      width: 60%;
      display: flex;
      align-items: center;
      color: var(--content-color-primary);

      span {
        @include text-truncate-ellipsis;
      }
    }
  }
}
