$request-editor-tab-text-font-size: $tab-text-font-size !default;

.request-editor-tabs-count {
  padding: var(--spacing-zero) var(--spacing-xs);
  color: var(--content-color-success);
}

.request-editor-tabs-comment-badge {
  height: 14px;
  min-width: 14px;
  background-color: var(--base-color-brand);
  font-size: var(--text-size-s);
  color: var(--content-color-constant);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-default);
  position: absolute;
  top: var(--spacing-zero);
  right: -6px;
  padding: var(--spacing-zero) 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-editor-tabs-badge {
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-left: var(--spacing-xs);
  border-radius: 6px;
  background-color: var(--base-color-success);
}

.request-editor-tabs-error-badge {
  display: inline-flex;
  background-color: var(--base-color-error);
  width: 5px;
  height: 5px;
  margin-left: calc(var(--spacing-xs) + 1px);
  transform: rotate(45deg);
}
