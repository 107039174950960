.api-documentation-dropdown {
  .api-documentation-dropdown-button-icon {
    display: flex;
    margin-left: var(--spacing-xs);
  }

  &-button {
    max-width: 200px;
  }

  .api-documentation-item {
    display: flex;
    margin-right: var(--spacing-s);
    width: 85%;
  }

  &-menu-item.dropdown-menu-item {
    margin: 0 var(--spacing-xs);
    padding: 0 var(--spacing-xs) 0 var(--spacing-m);
    border-radius: var(--border-radius-default);
  }

  &-menu-item {
    &:hover {
      .api-documentation-dropdown__more-actions {
        display: initial;
      }
    }

    &.is-selected {
      background-color: var(--highlight-background-color-secondary);
    }
  }

  &__more-actions {
    display: none;
    margin-left: auto;
  }

  &__extra-actions {
    border-top: var(--border-width-default) solid var(--border-color-default);
  }

  .dropdown-menu {
    width: 280px;
  }

  &__divider {
    border-top: 1px solid var(--border-color-strong);
    margin: var(--spacing-s) 0;
  }
}
