.websocket-saved-messages {
  padding-bottom: var(--spacing-l);
  overflow-y: auto;

  &--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: var(--spacing-xl);
    padding-right: var(--spacing-xl);
  }

  &__message {
    display: flex;
    align-items: center;
    gap: 0 var(--spacing-s);
    padding: 0 var(--spacing-l);
    height: 32px;
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    cursor: pointer;

    &:hover {
      background: var(--background-color-secondary);

      .websocket-saved-messages__message-menu {
        display: inline-block;
      }
    }

    .websocket-saved-messages__message-menu {
      display: none;
    }

    &--selected {
      background: var(--background-color-tertiary);
      cursor: default;

      .websocket-saved-messages__message-menu {
        display: inline-block;
      }

      &:hover {
        background: var(--background-color-tertiary);
      }
    }


    .event-badge {
      margin-left: 0;
    }
  }

  &__message-title {
    flex: 1;
    overflow-x: hidden;

    .inline-input_placeholder {
      @include text-truncate-ellipsis;
    }
  }

  &__right-side {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__more-options {
    height: var(--size-s) !important; // Override default sizing options for small buttons
    width: var(--size-s) !important; // Override default sizing options for small buttons

    &:hover {
      background: var(--highlight-background-color-primary);
    }

    &:not(:hover) {
      background: inherit;
    }

  }
}
