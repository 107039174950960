.example-editor-header {
  border-bottom: none;

  &__action {
    margin-right: var(--spacing-s);

    &:last-child {
      margin-right: unset;
    }

    .hasLabel {
      margin-right: var(--spacing-xs);
    }
  }
}
