.request-meta-viewer {
  border-bottom: 0 !important;
  .request-meta-saved-responses-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: var(--spacing-s);
  }

  .request-meta__options {
    margin-right: var(--spacing-s);
    line-height: var(--line-height-s);

    @include icon-color(var(--content-color-secondary));
    @include unselectable;
    @include clickable;
  }

  .requester-contents-group {
    flex: 0 0 20px;
    display: flex;
    flex-direction: row;
    background-color: var(--background-color-primary);
    padding: var(--spacing-xs) var(--spacing-s);
    position: relative;
    justify-content: space-between;
    align-items: center;
  }

  .request-meta-saved-responses-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: var(--spacing-l);
  }

  .request-editor-comments-info {
    font-size: var(--text-size-m);
    color: var(--content-color-tertiary);
    margin-left: var(--spacing-l);
  }

  .request-meta-name-group {
    display: flex;
    flex: 1;
    min-width: 0;
    flex-direction: row;
    align-items: center;
    @include unselectable;

    .request-meta-name-wrapper {
      display: flex;
      min-width: 0;
      @include clickable;

      .request-meta-name-wrapper__tooltip {
        display: flex;
        flex: 1;
        min-width: 0;
      }
    }

    &.is-editing {
      height: 20px;

      .request-meta-name-wrapper {
        width: 100%;

        .inline-input__wrapper {
          width: 100%;
        }
      }
    }

    .request-meta-name-toggle {
      &.is-open {
        transform: rotate(360deg);
      }

      &.is-hidden {
        display: none;
      }

      @include clickable;
    }

    .inline-input__wrapper {
      .input-box {
        font-size: 13px;
        font-weight: var(--text-weight-medium);
        padding: 2px;
      }
    }

    .request-meta-name__edit__icon-wrapper {
      cursor: pointer;
      padding-left: var(--spacing-s);
      display: flex;
      align-items: center;

      .request-meta-name__edit__icon {
        visibility: hidden;

        svg {
          height: 11px;
          width: 11px;
        }
      }
    }

    @include hover {
      .request-meta-name-toggle {
        &.is-hidden {
          display: none;
        }
      }

      &.editable {
        .request-meta-name__edit__icon {
          visibility: visible;
        }
      }
    }
  }

  .request-meta-name {
    color: var(--content-color-primary);
    font-size: 13px;
    font-weight: var(--text-weight-medium);

    @include text-truncate-ellipsis;

    // this is to ensure that name does not shift left/right when toggling
    // inline input mode
    padding-left: 3px;
  }

  .request-meta-name-untitled {
    cursor: default;
  }
}


// Status component styles

.status {
  padding: var(--spacing-zero) var(--spacing-s);
  line-height: var(--line-height-m);
  border-radius: 35px;
  font-size: var(--text-size-m);
  margin: var(--spacing-zero) var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--text-weight-medium);
  cursor: pointer;

  &-info {
    color: var(--content-color-secondary);
    background: var(--background-color-tertiary);

    .status__caret-icon {
      @include icon-color(var(--content-color-secondary));
    }
  }

  &-loading {
    color: var(--content-color-secondary);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .status__caret-icon {
      @include icon-color(var(--content-color-secondary));
    }
  }

  &-success {
    color: var(--content-color-success);
    background: var(--background-color-tertiary);

    .status__caret-icon {
      @include icon-color(var(--base-color-success));
    }
  }

  &-warn {
    color: var(--content-color-primary);
    background: var(--background-color-warning);

    .status__caret-icon {
      @include icon-color(var(--content-color-primary));
    }
  }

  &-error {
    color: var(--content-color-error);
    background: var(--background-color-error);

    .status__caret-icon {
      @include icon-color(var(--base-color-error));
    }
  }

  &__info-icon {
    @include icon-color(var(--content-color-tertiary));
  }

  &__loading-indicator {
    transform: scale(0.5);
    min-width: 8px;
    margin-left: var(--spacing-xs);
  }

  &__success-icon {
    background: var(--background-color-success);
    border-radius: 50%;
    height: 12px;
    width: 12px;
    justify-content: center;
    align-items: center;

    svg {
      height: 8px;
      width: 8px;

      use {
        fill: var(--background-color-primary);
      }
    }
  }

  &__warning-icon {
    @include icon-color(var(--base-color-warning));
  }

  &__error-icon {
    @include icon-color(var(--base-color-error));
  }

  &__label {
    margin: var(--spacing-zero) 1px var(--spacing-zero) 5px;
  }
}

.request-meta__mode-switcher {
  padding-left: var(--spacing-m);
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-left: var(--spacing-m);

  &.plugin__workspace-view-switcher__wrapper {
    .plugin__workspace-view-switcher__wrapper {
      display: flex;
      justify-content: center;
      height: var(--size-m);
      width: 70px;

      .plugin__workspace-view-switcher__switch {
        height: 28px;
        width: var(--size-m);

        &.right {
          left: 36px;
        }
      }

      .plugin__workspace-view-active-item {
        align-self: center;
        display: flex;
      }

      .plugin__workspace-view-switcher__switch__item {
        height: 100%;
        flex: 0 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  .request-meta__mode-switcher__icon {
    @include icon-color(var(--content-color-secondary));

    &.active {
      @include icon-color(var(--content-color-primary));
    }
  }

  &.use-browser-tabs {
    border-left: none;
  }
}

.request-meta__mode-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-tertiary);
  text-transform: uppercase;
  padding: var(--spacing-zero) var(--spacing-l);

  &.saved {
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }
}

.request-meta__save-request__modal {
  &-header {
    line-height: var(--line-height-s);
    text-transform: uppercase;
  }

  &-content {
    overflow: visible;
    line-height: var(--line-height-m);
  }

  &-footer {
    &__save-btn {
      margin-left: var(--spacing-s);

      @include focus {
        border: var(--border-width-default) var(--border-style-solid) $modal-button-focus-border-color;
      }
    }
  }
}

.request-meta-headers {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
}

.request-meta-successful {
  color: var(--content-color-success);
}

.request-meta-unsuccessful {
   color: var(--content-color-error);
}

.request-history-dropdown-options-menu-list, .request-history-dropdown-options-menu-button {
  z-index: 10000;
}