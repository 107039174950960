.fork-button__action {
  height: var(--size-m);
  min-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: var(--spacing-s);

  &.has-label i {
    margin-right: var(--spacing-xs);
  }
}

.fork-button__count {
  visibility: hidden;
  width: 0;

  &.visible {
    visibility: visible;
    width: auto;
  }

  &.no-click {
    pointer-events: none;
  }

  .btn {
    min-width: auto;
    height: var(---controls-size-default);
    display: inline-block;
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: var(--spacing-s) var(--spacing-m);
    border-left: var(--border-width-default) var(--border-style-solid)
      var(--border-color-strong);
  }
}

.fork-creator-popover {
  width: 256px;

  .tooltip-wrapper {
    padding: var(--spacing-l);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
    background-color: var(--background-color-primary);

    h4 {
      margin: 0;
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-medium);
      padding-bottom: var(--spacing-s);
      border-bottom: var(--border-width-default) var(--border-style-solid)
        var(--border-color-default);
    }
  }

  .loading-indicator-wrapper {
    margin: var(--spacing-l) 0;
    text-align: center;
  }

  &__error,
  &__empty {
    margin: var(--spacing-l) 0 0 0;
  }

  &__tooltip {
    color: var(--content-color-primary);
    font-size: var(--text-size-m);

    .tooltip-wrapper {
      padding: var(--spacing-l);
    }
  }
}

.fork-creator-list {
  list-style: none;
  padding-left: 0;
  margin: var(--spacing-l) 0 0 0;
  max-height: 128px;
  overflow: auto;

  &__item {
    display: flex;
    align-items: center;
    font-weight: var(--text-weight-regular);
    height: 32px;

    a {
      color: var(--content-color-primary);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__private {
    background: var(--background-color-tertiary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin-right: var(--spacing-s);
  }

  .avatar {
    margin-right: var(--spacing-s);
  }
}
