/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.mock-details-header {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  padding: 0 var(--spacing-l);

 // remove when new interface is released
  &__old {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-size-m);
    padding: 0 var(--spacing-l);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }

  &__mock-inactive-label {
    color: var(--content-color-tertiary);
    background-color: var(--background-color-tertiary);
    padding: var(--spacing-zero) var(--spacing-s);
    font-size: var(--text-size-s);
    font-weight: var(--text-weight-medium);
    white-space: pre;
    text-overflow: ellipsis;
    min-width: 0px;
    max-width: 100px;
    border-radius: 30px;
    cursor: auto;
    margin-left: var(--spacing-s);
  }

  &__mock-name-wrapper {
    display: flex;
    flex: 1;
    min-width: 0;
    color: var(--content-color-primary);
    margin-right: var(--spacing-l);
    line-height: var(--line-height-m);
    align-items: center;

    .mock-rename-btn {
      margin-left: var(--spacing-s);
      visibility: hidden;
      display: flex;
      align-items: center;
    }

    @include hover {
      .mock-rename-btn {
        visibility: visible;
        cursor: pointer;
      }
    }
  }

  &__copy-link {
    visibility: hidden;
  }

  &__mock-name-wrapper:hover &__copy-link {
    visibility: visible;
  }

  &__mock-name {
    @include text-truncate-ellipsis;
  }

  &__mock-actions {
    display: flex;
    align-items: center;

    &__copy, &__refresh, &__docs {
      margin-right: 2px;
      height: var(--size-m);
      padding: var(--spacing-s);

      &__icon {
        margin-right: var(--spacing-xs);
      }
    }

    &__more-options {
      min-width: var(--size-m);
      height: var(--size-m);
      padding: var(--spacing-s);
    }
  }
}

.mock-sub-tab {
  padding-top: var(--spacing-zero);
  padding-left: var(--spacing-zero);
  padding-right: var(--spacing-zero);
  ul {
    margin-top: var(--spacing-zero);
  }
  flex: 1;
  height: 100%;
  min-height: 0px;

  & > div:first-child {
    margin-bottom: 0px;
  }

  &__header {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: var(--spacing-l);
    width: 100%;
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }

  &__panels {
    height: 100%;
  }

  &__panel {
    height: 100%;
  }
}

.verticalDivider {
  border-left-color: rgb(214, 214, 214);
  border-width: 1px;
  border-left-style: solid;
  height: 16px;
  margin: 0 4px;
}


