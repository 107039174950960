/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$report-nav-width: 304px;
$navbar-height: 48px;
$es-image-width: 160px;
$empty-state-text-color: var(--content-color-tertiary);

.report-loading-indicator {
  left: 50%;
  position: absolute;
  top: 50%;
}

.report {
  background: var(--background-color-tertiary);
  min-height: calc(100vh - #{$navbar-height});
  display: flex;

  &--business-plan {
    display: block;
    overflow: auto;
  }

  .report-pm-container {
    margin-top: 0;
    margin-bottom: 0;
  }

  &-navigation,
  &-navigation-subnav,
  &-navigation-subnav-collapsed {
    @include unstyled-list;
    position: sticky;
    top: 0;
    float: left;
    width: $report-nav-width;
  }

  &-navigation {
    display: none;
    background: var(--background-color-primary);
    height: calc(100vh - #{$navbar-height});
    padding-left: var(--spacing-l);
    overflow: auto;
    flex: 0 0 320px;

    .pm-h4 {
      margin-top: var(--spacing-xl);
      margin-bottom: var(--spacing-m);
      margin-left: var(--spacing-l);
      color: var(--content-color-primary);
      font-size: var(--text-size-l);
    }

    &-count-badge {
      background: var(--background-color-tertiary);
      margin-left: var(--spacing-s);
      padding: var(--spacing-zero) 2px;
      line-height: var(--line-height-s);
      font-size: var(--text-size-s);
      display: inline-block;
      border-radius: var(--border-radius-s);
    }

    &--empty {
      color: $empty-state-text-color;
      margin: 20px auto;
      font-size: var(--text-size-l);
      text-align: center;
      width: 208px;

      &__image {
        display: inline-flex !important;
        margin: var(--spacing-m) 0 20px;
      }

      &__body {
        color: var(--content-color-secondary);
        font-size: var(--text-size-s);
      }
    }

    &--business-plan  {

      .pm-h2 {
        margin-top: var(--spacing-xl);
        margin-bottom: var(--spacing-zero);
        margin-left: var(--spacing-l);
        color: var(--content-color-primary);
        font-size: var(--text-size-xl);
      }
    }
  }

  &-navigation-subnav {
    padding-right: 32px;
    padding-left: var(--spacing-s);
    width: calc(#{$report-nav-width} - var(--spacing-xl));
    user-select: none;

    &__item {
      @include ellipsis;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      margin: 6px var(--spacing-zero);
      padding: var(--spacing-zero) var(--spacing-s);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-primary);

      &:hover {
        background: var(--background-color-brand);
        cursor: pointer;
      }

      &--active {
        background: var(--background-color-brand);
        font-weight: var(--text-weight-medium);

        &:hover {
          background: rgba(242, 107, 58, 0.1);
        }
      }

      &.disabled-item {
        background: var(--background-color-primary);
        color: var(--content-color-tertiary);
        cursor: not-allowed; 

        &:hover {
          background: var(--background-color-primary);
        }
      }

      &:last-child {
        margin-bottom: var(--spacing-l);
      }
    }

    &__list-item {
      display: flex;
      width: 100%;
      height: 100%;

      &__toggle-icon {
        display: flex;
        align-items: center;
        width: fit-content;
      }

      &__label {
        padding-left: var(--spacing-xs);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin: auto;

        &--left-indent {
          padding-left: 20px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    &-sublist {
      padding: var(--spacing-zero);
      width: calc(#{$report-nav-width} - var(--spacing-xl));
      position: relative;

      .vertical-line {
        position: absolute;
        width: 1px;
        left: var(--spacing-l);
        height: 100%;
        top: var(--spacing-zero);
        background-color: var(--border-color-default);
      }

      .left {
        left: 28px;
      }

      &__third-layer{
        padding-left: 60px;
      }

      &__item {
        display: flex;
        align-items: center;
        height: var(--spacing-xl);
        margin: 6px 0;
        padding: 6px var(--spacing-s);
        font-size: var(--text-size-m);
        color: var(--content-color-primary);
        word-break: break-word;

        &:hover {
          background: var(--background-color-brand);
          cursor: pointer;
        }

        &--active {
          background: var(--background-color-brand);
          font-weight: var(--text-weight-medium);

          // &:hover {
          //   background: rgba(242, 107, 58, 0.1);
          // }
        }
      }

      .indent {
        padding-left: 20px;
      }

      .double-indent {
        padding-left: 40px;
      }
    }


    &__back-button {
      margin: var(--spacing-xl) var(--spacing-zero) 38px var(--spacing-s);
      padding: var(--spacing-xs) var(--spacing-zero);
      height: var(--spacing-xl);
      width: calc(#{$report-nav-width} - 36px);
      background: var(--background-color-secondary);
      display: flex;
      align-items: center;

      .pm-icon {
        display: inline-block;
        vertical-align: middle;
        margin: var(--spacing-zero) var(--spacing-s);
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__search {
      height: var(--spacing-xxl);
      position: relative;
      margin: var(--spacing-l) var(--spacing-l) var(--spacing-s) var(--spacing-s);
      width: calc(#{$report-nav-width} - 36px);

      .input-search-group {
        border-radius: var(--border-radius-default);
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
        background-color: var(--background-color-primary);

        &:hover {
          border-color: var(--border-color-default);
        }

        &:focus-within {
          border-color: var(--base-color-brand);
        }
      }
    }

    &-filter-api-type {
      height: var(--size-s);
      margin: var(--spacing-s);
      border-radius: var(--border-radius-default);
      .dropdown-button .btn {
        font-size: var(--text-size-s);
        font-weight: var(--text-weight-regular);
      }
      .dropdown-button .btn-tertiary {
        padding: var(--spacing-xs);
        height: var(--size-s);
      }
      .dropdown-menu .dropdown-menu-item {
        padding-left: calc(var(--spacing-xs) + var(--spacing-xxl));
        &.api-page-type-item.api-page-type-item--active {
          padding-left: var(--spacing-s);
          i {
            margin-right: var(--spacing-m);
          }
        }
        &.api-page-type-item__text {
          font-size: var(--text-size-s);
        }

        &:nth-child(3) {
          border-bottom: 1px solid var(--border-color-default);
        }
      }
    }

    &-collapsed {
      padding: var(--spacing-zero);
      width: calc(#{$report-nav-width} - 80px);

      &__count {
        color: var(--content-color-tertiary);
        flex-shrink: 0;
        align-items: center;
        display: flex;

        .loading-indicator-wrapper {
          padding-right: 10px;
        }
      }

      &__item {
        height: var(--spacing-xl);
        color: var(--content-color-primary);
        display: flex;
        justify-content: space-between;

        .pm-icon {
          display: table-cell;
          vertical-align: middle;
        }

        &--active {
          background: var(--background-color-brand);
        }
      }
    }

    &--scrollable {
      max-height: calc(100vh - 242px - #{$navbar-height});
      overflow: auto;
    }
  }

  &__last-updated {
    position: relative;
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    display: inline-block;
    margin-left: var(--spacing-l);
    padding-bottom: var(--spacing-s);

    &-info {
      margin-left: var(--spacing-s);
      vertical-align: middle;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &__last-updated-subnav {
    padding: 9px var(--spacing-s);
    font-size: var(--text-size-s);
    color: var(--content-color-secondary);
    display: table;
    background: var(--background-color-primary);

    &-info {
      margin-left: var(--spacing-xs);
      display: none;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &-faq-text {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    display: block;
    margin-bottom: var(--spacing-s);
    margin-top: var(--spacing-xl);

    .pm-link {
      color: var(--content-color-secondary);
    }
  }

  .push--left {
    margin-left: 16px !important;
  }


  &-error-section {
    text-align: center;
    padding-top: var(--spacing-xxl);
    margin-top: 144px;
    overflow: hidden;

    &__image {
      display: inline-flex !important;
      max-width: $es-image-width;
      margin-bottom: var(--spacing-l);

      &.warning {
        @include icon("../assets/images/empty-state/warning.svg", 160px, 141px);
      }
    }

    &__title {
      color: var(--content-color-primary);
      margin-top: var(--spacing-l);
      font-size: var(--text-size-xl);
    }

    &__body {
      line-height: var(--line-height-xs);
      color: var(--content-color-secondary);
      margin: var(--spacing-l) auto var(--spacing-xxl);
      font-size: var(--text-size-l);
    }
  }
}

.report-graph-card {
  padding: var(--spacing-l);
  margin: var(--spacing-l) 0;
  background: var(--background-color-primary);
  border-radius: var(--border-radius-s);
  border: 1px solid var(--border-color-default);
  min-height: 94px;
  position: relative;

  &--clickable {
    cursor: pointer;
  }

  &__loader {
    margin: var(--spacing-xxl);
    text-align: center;
  }

  &__empty {
    text-align: center;
    padding: var(--spacing-xxl);
    font-size: var(--text-size-l);

    p {
      color: var(--content-color-primary);
    }
  }

  &__list {
    @include unstyled-list;

    margin-top: var(--spacing-l);
    max-height: 200px;
    overflow: auto;
    margin-left: -var(--spacing-s);
    margin-right: -var(--spacing-s);

    li {
      @include ellipsis;

      cursor: pointer;
      height: 20px;
      padding: 10px var(--spacing-s);
      border-radius: var(--border-radius-s);
      font-size: var(--text-size-l);
      color: var(--content-color-primary);

      &:hover {
        background: var(--background-color-secondary);
      }
    }
  }

  &__info {
    position: absolute;
    right: var(--spacing-l);
    top: var(--spacing-l);

    svg {
      height: 12px;
      width: 12px;
    }
  }

  &__title {
    font-weight: var(--text-weight-medium);
    margin-bottom: var(--spacing-l);
    margin-top: 0;
  }

  &__summary {
    font-size: var(--text-size-s);
    font-weight: var(--text-weight-medium);
    margin: var(--spacing-l) 0;
  }

  &__summary-metric {
    text-transform: uppercase;
    color: var(--content-color-secondary);
    font-size: var(--text-size-s);
    margin-left: var(--spacing-s);
    font-weight: var(--text-weight-medium);
  }

  &__count {
    display: inline-block;
    font-size: var(--text-size-xxl);
    font-weight: var(--text-weight-medium);
    vertical-align: middle;
    color: var(--content-color-secondary);
  }

  &__delta {
    position: relative;
    height: 20px;
    margin-left: var(--spacing-l);
    padding-left: 20px;
    font-size: var(--text-size-s);
    line-height: var(--line-height-xs);
    background: transparentize(#F9F9F9, 0.85);
    border-radius: var(--border-radius-s);
    display: inline-block;
    padding-right: var(--spacing-xs);

    .pm-icon {
      position: absolute;
      top: 2px;
      left: 2px;
    }

    &--success {
      background: transparentize(#0CBB52, 0.85);
      color: var(--base-color-success);
    }

    &--error {
      background: transparentize(#ED4B48, 0.85);
      color: var(--content-color-error);
    }
  }

  &__dots {
    margin-top: var(--spacing-l);
    font-size: var(--text-size-xs);
    color: var(--content-color-secondary);

    span {
      display: inline-block;
      margin-right: var(--spacing-s);
      height: var(--spacing-s);
      width: var(--spacing-s);
      border-radius: 50%;
      background-color: var(--base-color-success);
    }

    &--negative {
      span {
        background-color: var(--base-color-error);
      }
    }
  }

  &__column-name {
    margin-top: var(--spacing-l);
    border-bottom: 1px solid var(--border-color-default);
    padding-bottom: var(--spacing-s);
    font-weight: var(--text-weight-regular);
    color: var(--content-color-secondary);
    font-size: var(--text-size-l);
  }

  .push-half--bottom {
    margin-bottom: var(--spacing-s) !important;
  }
}

.report-table {
  .rt-td {
    padding: var(--spacing-s) !important;
  }

  .rt-tbody {
    max-height: 400px;
    overflow: auto;
  }

  &__missing {
    margin-top: var(--spacing-l);
    border-top: 1px solid var(--border-color-default);
    font-size: var(--text-size-m);

    span {
      display: inline-block;
      margin-top: var(--spacing-s);
    }

    p {
      margin: var(--spacing-xs) 0 0 0 ;
      color: var(--content-color-secondary);
    }
  }
}

.report-back {
  display: flex;
  justify-content: left;
  margin-top: var(--spacing-l);
  margin-left: -var(--spacing-s);
  width: fit-content;

  .pm-icon {
     margin-right: 10px;
  }

  &:hover {
    background: var(--background-color-tertiary);
  }
}

@media (min-width: $breakpoint-xs) {
  .report-navigation {
    border-right-style: solid;
    border-color: var(--border-color-strong);
    border-width: 1px;
    display: block;
    overflow-x: hidden;
  }

  .report > .report-pm-container {
    padding-left: calc(#{$report-nav-width} + var(--spacing-l));
  }
}

@import './report-pm-container';
@import '../components/empty-state';
@import './team-reports';
@import './api-reports';
@import './table';
@import './report-view';
