/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.sync-conflict {
  overflow-x: auto;

  &-modal {
    &__tabs {
      border-bottom: 1px solid var(--border-color-strong);
      width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__tab {
      position: relative;
      padding-bottom: 0;
      padding-left: 0;
      max-width: 200px;
      margin-right: 16px;

      &:first-child {
        margin-left: 20px;
      } 

      @include text-truncate-ellipsis;
    }

    &__content {
      padding-top: 4px;
      padding-bottom: 32px;
      overflow-y: hidden;
      padding-left: 0;
      padding-right: 0;
    }

    &__entity-name {
      display: inline-block;
      max-width: 170px;
      padding-right: 8px;

      @include text-truncate-ellipsis;
    }
  }

  &__model-icon {
    float: left;
    vertical-align: middle;

    &-disabled {
      opacity: 0.2;
    }
  }

  &-badge {
    color: var(--content-color-primary);
    background-color: var(--background-color-tertiary);
    height: 16px;
    min-width: 16px;
    margin-left: 8px;
    padding: 0px;
    font-weight: 600;
    pointer-events: none;
    position: absolute;
    right: 0;

    &-disabled {
      background-color: rgba(236, 236, 236, 0.6);
      color: rgba(80, 80, 80, 0.5);
    }
  }

  &__conflict-badge {
    margin-left: 8px;
    background-color: var(--base-color-warning);
    border-radius: var(--border-radius-default);
    font-size: 11px;
    color: var(--content-color-constant);
    padding: 0px 6px;
    height: 16px;
    font-weight: 600;
  }

  &__conflict-wrapper {
    margin-right: 4px;
    margin-top: 22px;
  }

  &__isLoading {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.sync-conflict-footer-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 10px 20px 0px;
}

.sync-conflict-body {
  overflow-y: auto;
  max-height: 100%;
  scroll-behavior: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
}

.sync-conflict-tooltip {
  display: flex;
}

.sync-conflict-info {
  color: var(--base-color-brand);
  margin-top: 22px;
}

.sync-conflict-wrapper {
  display: flex;
  align-items: flex-start;
}

.sync-conflict-data-column {
  padding-right: 10px;
}

.sync-conflict-data-header {
  display: flex;
  width: 135px;
  min-height: 45px;
  font-weight: 600;
  align-items: center;
}

.sync-conflict-data-value {
  width: 135px;
  padding: 10px 5px 0 0;
  word-break: break-word;
}

.sync-conflict-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
}

.sync-conflict-checkbox {
  flex: 0 0 16px;
  margin-right: 10px;
}

.sync-conflict-footer {
  display: flex;
  flex-direction: row-reverse;

  .btn {
    &.is-focused {
      box-shadow: var(--shadow-focus);
    }
  }
}

.sync-conflict-resolve {
  margin-left: 8px;
  width: 280px;
}

.sync-conflict-item {
  &__property {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: var(--content-color-tertiary);
    display: inline-block;
  }

  &__request {
    display: inline;

    &-method {
      display: inline;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  &-body {
    padding-left: 16px;
    border-left: 1px solid var(--border-color-strong);
    margin-left: 3px; // aligning toggle pointer with body
    margin-top: 24px;

    > div {
      margin-top: 16px;
    }
  }

  &-name {
    color: var(--content-color-primary);
  }

  &-wrapper {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.sync-conflict-radio-selector {
  display: inline;
  margin-left: 16px;
  vertical-align: middle;
}
