.plugin__learningCenter {
  .learning-center-button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    font-weight: var(--text-weight-regular);
    cursor: pointer;

    &.loading {
      background-color: var(--background-color-secondary);
      color: var(--content-color-tertiary);
      padding: var(--spacing-xs) var(--spacing-s);
      pointer-events: none;
      cursor: not-allowed;
    }

    .learning-center-label {
      margin-left: var(--spacing-xs);
      position: relative;

      &.dismissable {
        margin-right: 6px;
        margin-left: var(--spacing-xs);
      }
    }

    .sb__item__text {
      margin-bottom: var(--spacing-zero);
    }

    &.highlight {
      background: var(--background-color-brand);
      color: var(--content-color-brand);
      padding: var(--spacing-s);
      height: var(--size-s);
      box-sizing: border-box;
      .pm-icon {
        @include icon-color(var(--content-color-brand));
      }
    }

    .spinner {
      flex-direction: row-reverse;
    }
  }
}
