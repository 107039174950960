.api-dev-empty-state {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image-wrapper {
    margin: var(--spacing-xl) var(--spacing-zero);
  }

  &__blankState-wrapper {
    padding: var(--spacing-l)
  }

  &__title {
    margin: var(--spacing-s);
  }

  &__body {
    margin: var(--spacing-s) var(--spacing-zero);
    font-size: var(--text-size-l);
  }

  &__action-wrapper {
    display: flex;
    margin-bottom: var(--spacing-xl);

    & > * {
      margin: var(--spacing-s);
    }
  }

  &__subtext {
    margin: var(--spacing-l) var(--spacing-zero);
  }
}
