.collection-sidebar-empty-shell {
  height: 100%;
  border-right: 1px solid var(--border-color-default);
  border-left: 1px solid var(--border-color-default);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;

  .collection-sidebar-empty-shell--collection {
    height: 60px;
    border-bottom: 1px solid var(--border-color-default);
    display: flex;
    align-items: center;
  }

  .collection-sidebar-empty-shell--collection__dropdown,
  .collection-sidebar-empty-shell--collection__icon {
    height: 12px;
    width: 12px;
    background-color: var(--background-color-tertiary);
    margin-left: 8px;
    border-radius: var(--border-radius-default);
  }

  .collection-sidebar-empty-shell--collection__dropdown {
    height: 8px;
    width: 8px;
  }

  .collection-sidebar-empty-shell--collection__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .collection-sidebar-empty-shell--collection__info--name,
  .collection-sidebar-empty-shell--collection__info--count {
    background-color: var(--background-color-tertiary);
    width: 100px;
    height:8px;
    margin-left: 16px;
    border-radius: var(--border-radius-default);
  }

  .collection-sidebar-empty-shell--collection__info--count {
    margin-top: 8px;
    width: 55px;
  }


  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
}

