$icon-github-logo: $empty-state-path + 'github_logo.png';
$icon-bitbucket-logo: $empty-state-path + 'bitbucket_logo.png';
$icon-gitlab-logo: $empty-state-path + 'gitlab.png';
$icon-azure-logo: $empty-state-path + 'azure.png';


.integration-oauth-icon-github {
  @include icon(
    $icon-github-logo,
    56px,
    56px
  );
}

.integration-oauth-icon-bitbucket {
  @include icon(
    $icon-bitbucket-logo,
    56px,
    56px
  );
}

.integration-oauth-icon-gitlab {
  @include icon(
    $icon-gitlab-logo,
    56px,
    56px
  );
}

.integration-oauth-icon-azure {
  @include icon(
    $icon-azure-logo,
    56px,
    56px
  );
}
