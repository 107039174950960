.generic-sidebar-dropdown-actions-container {
  width: 201px;
}

.generic-sidebar-list {
  .api-sidebar-list__item-empty-state {
    padding: var(--spacing-s) var(--spacing-l);
  }
  .generic-sidebar-item-loader {
    display: flex;
    height: 28px;
    padding: var(--spacing-zero) var(--spacing-s);

    &__loading {
      animation: fade 1s ease-in-out infinite;
      display: flex;
      align-items: center;
      padding: var(--spacing-s) 0;
      margin-left: var(--spacing-xl);
    }

    &__icon {
      height: 8px;
      width: 8px;
      background-color: var(--highlight-background-color-secondary);
      margin-left: var(--spacing-s);
      border-radius: var(--border-radius-s);
      margin-right: var(--spacing-m);
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100px;
      height: 8px;
      background-color: var(--highlight-background-color-secondary);
      border-radius: var(--border-radius-s);
    }
  }
  &:hover {
    & .generic-sidebar-list__item__left-section__indent {
      border-right: thin solid var(--border-color-default);
    }
  }
}

.generic-sidebar-list-item {
  display: flex;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--content-color-primary);
  padding: var(--spacing-zero) var(--spacing-s);

  button[data-testid="sidebar-action-btn"] {
    width: 24px;
    height: 24px;
    &:hover {
      background-color: var(--sidebar-button-tertiary-hover-background-color);
    }

    &:active {
      background-color: var(--sidebar-button-tertiary-active-background-color);
    }
  }

  @include hover {
    background-color: var(--highlight-background-color-secondary);
  }

  &.is-active {
    background-color: var(--highlight-background-color-secondary);
  }

  @include focus {
    background-color: var(--background-color-brand);
    border-radius: var(--border-radius-default);
  }

  &.selected {
    color: var(--content-color-primary);
    background-color: var(--highlight-background-color-tertiary)
  }

  &__icon-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: var(--size-xs);
    min-width: var(--size-s);
  }

  // Inside text container
  &__name-wrapper {
    line-height: var(--line-height-m);
    display: flex;
    min-width: 0px;
    margin-right: var(--spacing-xs);

    @include text-truncate-ellipsis;

    .inline-input {
      height: var(--size-s);
    }

    &:hover{
      color: var(--content-color-primary);
    }

    // When 'use browser tabs' setting is enabled,
    // Removes selection when using `modifier+shift+click` to open a new tab.
    &.use-browser-tabs {
      @include unselectable;
      @include clickable;
    }

    &__icon-after-text-container {
      margin-left: var(--spacing-xs);
    }
  }

  &__content-wrapper.edit-mode {
    .generic-sidebar-list-item__name-wrapper {
      margin-right: var(--spacing-xs);
      width: 100%;
    }

    .inline-input__wrapper {
      width: 100%;
    }
  }

  // Status indicators
  &__status-indicators {
    display: flex;

    i {
      margin-right: var(--spacing-xs);
    }
  }

  &__link {
    flex: 1;
    overflow: hidden;
  }

  // InlineInput Component
  &__name {
    @include text-truncate-ellipsis;
  }
}

.generic-sidebar-list-item {
  &.focused {
    &.is-sidebar-in-focus {
      box-shadow: var(--sidebar-item-shadow-focus);
    }
  }

  .name {
    margin-right: var(--spacing-xs);
  }

  &.selected {
    background-color: var(--highlight-background-color-primary);
    &.is-sidebar-in-focus {
      background-color: var(--highlight-background-color-tertiary);
      box-shadow: 2px 0px 0 var(--base-color-brand) inset;
      &.focused {
        box-shadow: 2px 0px 0 var(--base-color-brand) inset, var(--sidebar-item-shadow-focus);
      }
    }
  }

  &.drop-hovered-on {
    box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
  }

  &.drop-hovered-top {
    box-shadow: 0 2px 0 var(--base-color-brand) inset;
  }

  &.drop-hovered-bottom {
    box-shadow: 0 2px 0 var(--base-color-brand);
  }
}

.generic-sidebar-list__item {
  &__empty-state {
    margin-left: calc(var(--spacing-l) + var(--spacing-xs));
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
    padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l);
  }

  &__left-section {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    &__indent {
      border-right: thin solid transparent;
    }

    &__toggle-list {
      margin-right: var(--spacing-xs);
      margin-left: var(--spacing-xs);
      max-height: var(--size-xs);
      width: var(--size-xs);

      @include icon-color(var(--content-color-primary));
    }

    &__toggle-list-placeholder {
      margin-right: var(--spacing-xs);
      margin-left: var(--spacing-xs);
      height: var(--size-xs);
      width: var(--size-xs);
      background-color: unset;
    }

    &__entity-type-icon {
      overflow: hidden;
      margin-right: var(--spacing-xs);
    }

    .is-left-meta-visible {
      display: flex;
      visibility: visible;
    }

    .is-left-meta-not-visible {
      visibility: hidden;
    }
  }
}

.generic-sidebar-list-item__body--empty {
  font-family: $font-primary;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);
}

.generic-sidebar-list-item__icon-container {
  justify-content: start;
  padding-right: var(--spacing-zero);
}

.generic-sidebar-list-item__name {
  padding-right: var(--spacing-xs);
}

.generic-sidebar-list-item__content-wrapper {
  margin-left: var(--spacing-xs);
}


.generic-sidebar-list-item__name-wrapper {
  align-items: center;
}

.generic-sidebar-list-item__actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
