.invite-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  max-height: 80px;

  // Added to display red border around the input field in case when 0 invites are left with the user.
  &__teamPlan_alert {
    border: 1px solid var(--base-color-error)
  }

  &__empty-state--with-alert {
    font-size: var(--text-size-m);
    line-height: 20px;
    color: var(--content-color-error);
    margin-left: var(--spacing-xxl);
    margin-top: var(--spacing-s)
  }

  .invite-placeholder {
    margin-left: 8px;
    position: absolute;
    color: var(--content-color-tertiary);
  }

  .active-input {
    flex: 1;
    padding-left: 8px;
  }
}

.user-suggestion {
  height: 56px;

  &__name {
    @include text-truncate-ellipsis;
  }

  .avatar {
    margin-right: 8px;
  }

  .user-suggestion__group-tag {
    width: var(--size-s);
    height: var(--size-s);
    margin-right: var(--spacing-s);
    align-items: center;
    justify-content: center;
    align-self: center;
    background: var(--background-color-tertiary);
    border-radius: var(--border-radius-max);
  }

  .user-suggestion-details {
    height: 100%;
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .user-suggestion__email {
      margin-top: 4px;
      color: var(--content-color-secondary);
    }
  }
}
