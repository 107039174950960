@import 'keyvalue-editor-colors';
@import 'keyvalue-form-header';
@import 'keyvalue-form-row';
@import 'keyvalue-bulk-editor';
@import 'keyvalue-form-sortable';
@import 'keyvalue-form-editor';

.key-value-editor {
  &.is-filterable {
    display: flex;
    flex-direction: column;
  }

  .search-variable {
    width: 240px;
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-s);
  }
}
