.runner-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  .runner-results-header__meta {
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: var(--spacing-s);
    }
  }

  .runner-results-header__name {
    display: inline-flex;
  }

  &__copy-btn {
    display: none;
  }

  &__meta:hover &__copy-btn {
    display: flex;
  }

  .runner-results-header__actions {
    display: flex;
    align-items: center;
    min-height: var(--size-m);
  }
}
