.request-to-join {
  &__actions {
    display: flex;
    align-items: center;
  }

  &__action {
    &-confirm {
      margin-left: 8px;
      width: 160px;
    }
  }
  &__character-usage{
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    color: var(--content-color-secondary);
  }
  & textarea{
    resize: none;
    background: var(--background-color-secondary);
    border-radius: var(--border-radius-default);
    padding: 8px;
    margin-top: 8px;
  }

  & .textarea-error-text{
    padding-left: 0;
    justify-content: space-between;
  }

  &__block {
    margin: 8px 0;
  }
}
