.addnew-description-help-text {
  font-size: 10px;
  line-height: 24px;
  color: var(--content-color-secondary);
  margin-top: 8px;
}

.collection-modal-variables-alert {
  margin-top: 10px;
}

.collection-modal-variables-container {
  flex: 1;
  overflow-y: auto;

  .key-value-form-row {
    &:hover {
      &:not(.row-focused) {
        .key-value-form-column:last-child {
          .auto-suggest {
            &.has-error,
            &.has-warning {
              margin-right: 45px;
            }
          }
        }
      }
    }
  }
}
