.linked-monitor-runs-wrapper {
  display: flex;
  min-width: 20%;
  flex-wrap: wrap;
  margin-right: var(--spacing-m);

  .linked-monitor-runs-error {
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
  }
  .linked-monitor-runs-item {
    .linked-monitor-runs-item-run-candle {
      cursor: pointer;
      width: 4px;
      height: 20px;
      margin-right: var(--spacing-xs);
      margin-bottom: var(--spacing-xs);
      margin-top: var(--spacing-xs);
    }
  }

  .run-success {
    background-color: var(--content-color-success);
  }

  .run-neutral {
    background-color: var(--content-color-tertiary);
  }

  .run-failed {
    background-color: var(--content-color-error);
  }

  .linked-monitor-runs-item-tooltip {
    min-width: max-content;
  }

  .run-running {
    background-color: var(--content-color-tertiary);
    opacity: 0.8;
  }

  .run-item-successful {
    opacity: 0.5;
  }

  .run-item-successful:hover {
    opacity: 1;
  }

  .run-item-errored {
    opacity: 0.2;
  }

  .run-item-errored:hover {
    opacity: 0.4;
  }

  .run-item-errored:focus {
    opacity: 0.8;
  }
}

.linked-monitor-runs {
  margin-right: var(--spacing-zero) !important;
}

.is-runs-shrunk {
  min-width: 10% !important;
}

.monitor-actions {
  flex: 0 0 5% !important;
  padding-right: var(--spacing-s);
}

.linked-monitors-tooltip-wrapper {
  min-width: max-content;
  color: var(--content-color-primary);
  .linked-monitors-tooltip-date-wrapper {
    display: flex;
    align-items: center;
    .linked-monitors-tooltip-status {
      margin-right: var(--spacing-xs);
    }
    .linked-monitors-tooltip-date {
      float: right;
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .linked-monitors-tooltip-header {
    font-weight: var(--text-weight-bold);
    font-size: var(--text-size-l);
    margin-left: 6px;
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-xs);
  }

  .linked-monitors-tooltip-metrics-wrapper {
    margin-left: 6px;
    margin-right: 6px;
    margin-top: var(--spacing-s);
    .linked-monitors-tooltip-metrics-row {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 80px 80px 80px;
      .linked-monitors-tooltip-metrics-row-separator {
        margin-right: 40px;
      }
      .linked-monitors-tooltip-metrics-row-value {
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .uptime-monitors-tooltip-row {
      grid-auto-columns: 80px 10px auto !important;
    }
  }

  .linked-monitors-tooltip-table {
    overflow: hidden;
    min-width: max-content;
  }
}

.monitor-run-button {
  margin-right: var(--spacing-s);
  .btn-small {
    height: var(--size-s);
  }
}

.run-monitor-btn {
  min-width: 63px;
  height: var(--size-s);
  margin-right: var(--spacing-s);
  padding: var(--spacing-s);
}

