.share-entity-model {
  .modal-content {
    overflow-y: hidden;

    .create-new-modal__content {
      height: 100%;
    }
  }

  .tabs {
    padding: 10px 0px 0px 0px;
    border-bottom: 1px solid var(--border-color-default);

    .tab {
      cursor: default;
    }
  }
}

.share-entity-tab-content {
  padding: 16px;
  max-height: 60vh;
  overflow-y: auto;

  .tab-share-content {
    .tab-share-content-meta-text {
      color: var(--content-color-primary);
      font-size: 12px;
    }
  }
}
.select-workspace-entity-container {
  .tabs {
    width: 50%;
  }
}

.share-collections-content-container {
  padding: 8px 0px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  .loading-indicator-wrapper {
    text-align: center;
  }

  .add-to-workspace-empty--container {
    width: 400px;
    display: flex;
    align-self: center;
  }

  .workspace-selector-container {
    display: flex;
    flex: 100%;
    justify-content: space-between;

    .workspace-selector-dropdown-container {
      display: flex;
      flex: 50%;

      .workspace-selector-value {
        display: flex;
        flex: 50%;
      }

      .workspace-selector-meta-container {
        flex: 50%;
        display: flex;
        align-items: center;
        align-content: center;

        .workspace-selector-meta {
          padding-left: 24px;
          color: var(--content-color-tertiary);
        }
      }
    }

    .workspace-selector {
      display: flex;
      flex: 50%;
      flex-direction: column;
      padding: 5px 0;

      .layout-2-column & {
        flex: 1;
      }

      .workspace-selector-label {
        padding-bottom: 5px;
        font-size: 11px;
        color: var(--content-color-primary);
        font-weight: 600;
      }

      .input-checkbox {
        margin-right: 10px;
      }
    }
  }

  .dropdown {
    width: 100%;
    .dropdown-button {
      width: 100%;
        .btn {
          width: 100%;
          justify-content: space-between;
        }
    }
  }
  .tab-share-content--subtab-entity {
    display: flex;
    flex: 100%;

    .entity-list-container-wrapper {
      height: 180px;
      display: flex;
      flex: 0 50%;
      .entity-list-container {
        height: 150px;
        overflow-y: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--border-color-default);
        margin-top: 8px;
        padding: 8px;

        .entity-checkbox-item-container {
          flex: 1 0 20px;
          margin-top: 16px;
          display: flex;
          flex-direction: row;

          .entity-checkbox-item-meta {
            margin-left: 8px;
          }
        }
        .entity-checkbox-item-container:nth-child(1) {
          margin-top: 0px;
        }
      }
    }
    .entity-selection-meta-container {
      display: flex;
      flex:0 50%;
      align-items: center;
      align-content: center;

      .entity-selection-meta {
        color: var(--content-color-tertiary);
        padding-left: 16px;
      }
    }
  }

  .share-collections-content-container_warning-text {
    align-self: flex-end;
  }
}

.setup-team-content {
  .tab-share-content-meta-text {
    color: var(--content-color-primary);
    font-size: 12px;
  }
}

.setup-team-container {
  margin-top: 24px;
  display: flex;

  .tab-share-content-meta-text {
    color: var(--content-color-primary);
    font-size: 12px;
  }

  .left {
    flex:50%;
  }

  .right {
    flex:50%;
  }

  .input-container {
    margin-bottom: 24px;

    .input-label {
      font-weight: 600;
    }
  }
  .input-field {
    margin-top: 8px;
  }
}
.entity-empty-text {
  display: block;
  min-height: 81px;
  color: var(--content-color-tertiary);
}

.setup-team-input-container {
  display: flex;

  .input-field {
    flex: 80%;
  }

  .setup-team-input-meta-container {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .setup-team-input-meta {
    padding-left: 8px;
  }
}

.team-setup-container {
  padding: 20px;
}
