.connected-integrations-wrapper {
  display: flex;
  flex: 0 0 30% !important;

  .connected-integrations-action-loading {
    margin-left: var(--spacing-xs);
  }

  .connected-integrations-cell {
    display: flex;
    flex-wrap: wrap;

    .connected-integrations-logo-wrapper{
      display: flex;
      cursor: pointer;
      .connected-integrations-logo {
        height: 20px;
        width: 20px;
        padding: 6px;
      }
    }
  }
}

.is-connected-integrations-shrunk {
  flex: 0 0 40% !important;
}

.connected-integrations-empty-state {
  display: flex;
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);

  .connected-integrations-empty-state-add-cta {
    margin-left: var(--spacing-xs);
  }
}

.connected-integrations-content-wrapper {
  padding: var(--spacing-s) var(--spacing-s);
  color: var(--content-color-primary);

  .connected-integrations-content-title {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-l);
    line-height: var(--line-height-s);
    padding-bottom: var(--spacing-xs);
  }

  .connected-integrations-actions {
    display: flex;
    padding-top: var(--spacing-xs);

    .connected-integrations-actions-edit {
      cursor: pointer;
      margin-right: var(--spacing-s);
    }

    .connected-integrations-actions-delete {
      cursor: pointer;
    }
  }

  .connected-integrations-content-row {
    display: flex;
    align-items: center;
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-xs);

    .connected-integrations-content-row-icon {
      display: flex;
      align-items: center;
    }

    .connected-integrations-content-row-label {
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
    }

    .connected-integration-content-row-action {
      display: flex;
      margin-left: var(--spacing-xs);
      align-items: center;
      color: var(--content-color-secondary);

      .connected-integration-row-action-tag {
        margin-left: var(--spacing-zero) !important;
      }

      .connected-integration-content-row-action-error {
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        color: var(--content-color-secondary);
        margin-left: var(--spacing-xs);
      }
    }
  }

  .is-hyperlink {
    cursor: pointer;
  }
  .is-action-link {
    cursor: pointer;
    color: var(--content-color-link) !important;
  }
  .is-spaced {
    padding-right: var(--spacing-xs);
  }
  .is-div {
    margin-left: var(--spacing-zero) !important ;
  }
}

.connected-integrations-tooltip {
  max-width: min-content !important;
  cursor: default;
}
