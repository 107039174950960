.mock-delay-selector {
  width: 100%;

  .mock-delay__checkbox {
    display: flex;
  }

  .mock-delay__checkbox-label {
    cursor: pointer;
    font-weight: var(--text-weight-medium);
  }
  .mock-delay__options {
      min-width: 293px;
      .delay-duration-type-dropdown {
        margin-top: var(--spacing-m);
        height: var(--spacing-xxl);
      }
      .delay-duration__input-wrapper {
        .delay-duration__input {
          display: flex;
          width: fit-content;
          .delay-duration__input-unit {
            margin: 6px auto;
            align-self: center;
            height:100%;
            justify-content: center;
            position: relative;
            left: 6px;
            cursor: default;
            &.is-disabled {
              color: var(--content-color-tertiary);
            }
          }
          .delay-duration__input-field {
            &.is-disabled {
              cursor: default;
              opacity: 0.4;
            }
          }
        }
      }

      .delay-duration__error {
        display: flex;
        color: var(--base-color-error);
      }
  }
}
