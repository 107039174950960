.fork-recommendation-modal {
  &__content {
    padding: 16px 24px;

    &-description {
      margin-bottom: 16px;
      margin-top: unset;
    }
  }

  &__option {
    border: 1px solid var(--border-color-strong);
    border-radius: var(--border-radius-default);
    margin-top: 8px;
    padding: 16px;

    &-disabled {
      @extend .fork-recommendation-modal__option;
      opacity: 0.5;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &-radio-button {
      margin-right: 16px;
    }

    &-description {
      margin: 4px 28px 0px;
    }

    &-title {
      cursor: pointer;
      position: relative;
      bottom: 1px;
    }

    &-title-tag {
      background-color: var(--background-color-warning);
      color: var(--content-color-warning);
      border-radius: var(--border-radius-default);
      margin-left: 12px;
      padding-left: 4px;
      padding-right: 4px;
      position: relative;
      bottom: 1px;
    }
  }
}
