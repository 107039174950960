.add-integrations-wrapper {
  width: 440px;
}

.integration-add-actions-separator {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}

.integrations-add-card {
  &__segment {
    display: flex;
    flex-direction: column;
  }
  &__label-title {
    color: var(--content-color-primary);
    font-style: normal;
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
  }
  &__label-title-v2 {
    font-weight: var(--text-weight-medium);
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);
  }
  &__label-description {
    color: var(--content-color-secondary);
    font-style: normal;
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
  }

  &__input {
    flex: 1;
    padding-top: var(--spacing-s);
  }

  .pm-link {
    text-decoration: underline;
    color: var(--content-color-secondary);

    &:hover {
      color: var(--content-color-secondary);
    }
  }
}

.integrations-add-actions {
  &__button {
    &--container {
      margin-top: var(--spacing-xl);
      float: right;
      padding-bottom: var(--spacing-xxxl);
    }

    &--cancel {
      border-radius: var(--border-radius-default);
    }

    &--save-changes {
      margin-right: var(--spacing-s);
      border-radius: var(--border-radius-default);
    }
  }
}

.align-left {
  float: left;
}

.aws-gateway {
  &__button {
    >.integrations-add-actions__button--container {
      margin-right: var(--spacing-m);
      margin-top: var(--spacing-xxl);
      float: left;
    }
  }
}

.api-version-add-actions {
  &__button {
    &--container {
      margin-top: var(--spacing-xl);
      float: right;
      padding-bottom: var(--spacing-xl);
    }

    &--cancel {
      border-radius: var(--border-radius-default);
      margin-right: var(--spacing-s);
    }

    &--save-changes {
      border-radius: var(--border-radius-default);
    }
  }
}
