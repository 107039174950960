.table-modal {
  --max-content-height: 70vh;
  max-height: var(--max-content-height);

  table {
    width: 100%;
    border-radius: var(--border-radius-default);
    border-spacing: 0;
    border-collapse: collapse;
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    tr {
      height: var(--size-m);
      min-height: var(--size-m);
    }

    th,
    td {
      min-width: 77px;
      max-width: 77px;
      padding: var(--spacing-xs) var(--spacing-l);
      word-break: normal;
      vertical-align: top;
      white-space: pre-line;
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    th {
      border-top: none;
      text-align: left;
      background-color: var(--background-color-secondary);
    }

    tr {
      &:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }
  }

  .click-to-expand-container {
    --max-table-height-without-scroll: calc(
      /* The height of the header is 2*spacing-xl and the padding in the modal container is 2*spacing-xl */
        var(--max-content-height) - 4 * var(--spacing-xl)
    );
    overflow: auto;
  }
}