.requester-library {
  display: flex;
  flex: 1;
  flex-direction: column;

  overflow-y: hidden;

  .requester-library-header {
    flex: 0 0 auto;
  }

  .requester-team-collections {
    flex: 1;
  }

  .requester-environment-templates {
    flex: 1;
  }
}

.requester-library--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.requester-library {
  .requester-library__offline-banner {
    display: none;
  }

  &.is-offline {
    .requester-library__offline-banner {
      display: flex;
    }

    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 40px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 80;
      background-color: var(--background-modal-backdrop);
    }
  }
}

.requester-library__offline-banner {
  background-color: var(--background-color-warning);
  font-size: 14px;
  color: var(--content-color-warning);
  height: 40px;
  align-items: center;
  justify-content: center;
  z-index: 80;

  .requester-library__offline-banner__button {
    text-decoration: underline;
    @include clickable;
  }
}

.requester-team-overview {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 10px;
  overflow-y: visible;

  .workspace-activity-item-content {
    .workspace-activity-item-details {
      border: 0;
    }
  }
}

.requester-team-collections {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-environment-templates {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-library-header {
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid $app-border-color;

  .tab:first-child {
    margin-left: 20px;
  }
}

.requester-library-team-collections {
  padding: 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-library-team-collections__contents {
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-library-tabs {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;

  .requester-library-tabs--team-meta {
    color: $app-generic-text-color;
    font-family: $font-primary;
    padding: 0px 15px 7px;
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0;
    top: 6px;

    &.not-admin {
      width: 220px;
    }
  }
}

.requester-library-wrapper--sync-disabled {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

  .requester-library--sync-disabled {
    color: $app-generic-text-color;
    display: flex;
    flex-direction: column;
    width: 560px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $font-primary;
  }

  .sync-disabled-description {
    font-size: 12px;
    padding: 10px 0px;
    opacity: 0.6;
  }

  .learn-more-sync {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}
