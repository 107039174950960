.pie-progress-wrapper {
  position: relative;
  background-color: var(--background-color-success);
}

.pie-progress-base {
  position: absolute;
  top: 0px;
  left: 0px;
}

.pie-progress-slice {
  background-color: var(--base-color-success);
  position: absolute;
  top: 0px;
  left: 0px;
}
