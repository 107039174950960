.auto-flex-modal {
  .modal-content {
    padding: var(--spacing-xxxl) var(--spacing-xxl) var(--spacing-l);
    max-height: 80vh;

    .header-title {
      font-size: 20px;
      display: flex;
      justify-content: center;
    }

    .header-description {
      margin-top: var(--spacing-l);
      text-align: center;

      span {
        font-size: var(--text-size-l);
        line-height: 22px;
        display: inline-block;
        color: var(--content-color-secondary);
      }
    }

    .content-container {
      display: flex;
      margin-top: var(--spacing-xl);
      justify-content: space-evenly;

      .section {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          width: 104px;
          height: 88px;
        }

        .title {
          margin-top: var(--spacing-l);
          line-height: var(--line-height-m);
          font-weight: var(--text-weight-medium);
        }

        .description {
          margin-top: var(--spacing-s);
          text-align: center;
          width: 228px;
          line-height: var(--line-height-m);
          color: var(--content-color-secondary);
        }
      }
    }
  }

  .modal-footer {
    flex-direction: column;
    padding-bottom: var(--spacing-xxxl);

    .btn-primary {
      padding: var(--spacing-s) var(--spacing-m);
    }

    .btn-secondary {
      margin-top: var(--spacing-l);
      padding: var(--spacing-s) var(--spacing-m);
    }
  }
}
