.contextCustomScroll {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
}

.loader-link {
  --margin-small: 5px;
  color:var(--content-color-link);
  margin-right: var(--margin-small);
  cursor: pointer;
  text-decoration: underline;
}

.loader-activity-logs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-link-name {
  --margin-small: 5px;
  font-weight: var(--text-weight-regular);
  cursor: pointer;
  color: var(--content-color-link);
  margin-right: var(--margin-small);
}

.pm-activity-section__refresh__btn {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  margin-top: var(--margin-spacing-custom-top);
  margin-right: var(--margin-spacing-custom-right);
  height: var(--size-m);
  width: var(--size-m);
  padding: var(--spacing-zero);

  @include hover {
    background-color: var(--highlight-background-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  @-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
  }

  &.is-loading {
    cursor: default;
    .pm-activity-section__refresh__btn__icon {
      -webkit-animation: rotating 1s linear infinite;
    }
  }
}

.monitor-activity {
  --activity-feed-content-margin: 56px;
  padding: var(--spacing-zero) var(--spacing-s);
  overflow-y: auto;

  .pm-activity-list-item {
    list-style-type: none;

    .pm-user-icon--custom {
      background-color: var(--background-color-primary);
    }
  }

  .pm-activity-section {

    .show-user ~ .show-user {
      border-top: 1px solid var(--highlight-background-color-primary);
    }
  }

  .pm-monitor-activity-section__date {
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-secondary);
  }

  .pm-activity-list-item__text{
    .user-info {
      display: inline-block;
      align-items: center;
      --margin-small: 5px;
      font-weight: var(--text-weight-medium);
      margin-right: var(--margin-small);

      &.is-clickable {
        cursor: pointer;
      }
    }
  }

  .pm-activity-list-item {
    color: var(--content-color-primary);
    border: none;
    padding: var(--spacing-s) 0;
    margin: var(--spacing-s) 0;
  }

  .pm-monitor-activity-list-item__icon {
    position: absolute;
    margin-left: var(--spacing-s);
    width: var(--size-m);
    height: var(--size-m);
    border-radius: var(--size-m);
  }

  .pm-activity-list-item__text {
    color: var(--content-color-primary);
    margin-left: var(--activity-feed-content-margin);
  }

  .pm-activity-list-item__time {
    color: var(--content-color-tertiary);
    margin-left: var(--activity-feed-content-margin);
  }

  &__load-more {
    padding: var(--spacing-l) 0;
    text-align: center;
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
  }

  .pm-activity-list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: var(--spacing-l);
    overflow: scroll;
    height: 100%;
  }

  .pm-activity-list-item__description {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    strong {
      font-weight: var(--text-weight-medium);
    }
  }

}

.activity-title {
  margin-top: 0;
  margin-bottom: var(--spacing-l);
}

/* //////////////////////////////////////////// */

.pm-activity-list-item {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  position: relative;
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-xxl);
  border-left: 1px solid var(--border-color-default);
  border: none;
  margin: var(--spacing-s) 0;
}


.pm-activity-list-item__time {
  margin: 0;
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
  display: inline-block;
}

.activity-logs-public-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-l);

  &__entity {
    margin-bottom: var(--spacing-l);
    font-size: var(--text-size-m);
  }
}

.activity-logs-empty-state-no-logs {
  text-align: center;
  margin: 60px 0px 100px 0px;
  font-size: var(--text-size-s);

  .empty-state__title {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
  }

  .empty-state__body {
    color: var(--content-color-primary);
  }

  .empty-state__image {
    &.activity {
      @include icon("../assets/images/empty-state/monitor-activity.svg", 160px, 126px);
    }
  }
}

.activity-logs-empty-state-no-logs-with-filters {
  @extend .activity-logs-empty-state-no-logs;

  padding-top: var(--activity-feed-content-margin);

  .empty-state__image {
    &.activity {
      @include icon("../assets/images/empty-state/monitor-activity-with-filters.svg", 56px, 56px);
    }
  }
}

.activity-user-filter-dropdown-no-user {
  padding: var(--spacing-s) var(--spacing-xs);
}

.activity-logs-offline {
  pointer-events: none;
}
