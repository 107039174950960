/**
 * Styling for `RequestConfigurationList` component
 */
.request-configuration-list {
  padding: var(--spacing-s) var(--spacing-zero);
}

.request-configuration-unavailable {
  margin: var(--spacing-l);
}
