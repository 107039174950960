$postman-logo-url: '../assets/images/thumbnails/postman-logo.svg';

.update-not-available {
  .update-not-available__message {
    display: flex;
    align-items: center;

    .postman-logo {
      height: 100px;
      width: 100px;
      flex: 0 0 100px;
      background-image: url($postman-logo-url);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 20px;
    }
  }

  .update-not-available__button {
    margin-top: 10px;
  }
}
