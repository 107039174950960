$capture-modal-width: 440px;

.capture-feed-container {
  position: absolute;
  right: 0;
}

.capture-feed {
  display: flex;
  flex-direction: column;
  overflow: overlay;
  box-sizing: border-box;
  width: $capture-modal-width;
  height: 100%;
  font-family: $font-primary;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  @include material-shadow-1;
  margin-bottom: var(--spacing-m);
  margin-top: var(--spacing-xs);
  padding: var(--spacing-xl) var(--spacing-xl);

  &.is-hidden {
    display: none;
  }
}
