.workspace-visibility--title {
  color: var(--content-color-primary);
  font-weight: 600;
  font-size: 12px;
  margin: unset;
  margin-top: 16px;
  user-select: none;
}

.workspace__enterprise_visibility--disabled {
  opacity: 0.5;
  display: flex;
  margin-top: 16px;
  width: fit-content;

  .workspace__enterprise_info {
    margin-left: 8px;
    color: var(--content-color-primary);
    user-select: none;
    cursor: default;
  }

  .input-checkbox:hover {
    .pm-icon-normal {
      opacity: 0;
    }
  }
}

.workspace__enterprise_visibility {
  display: flex;
  margin-top: 8px;

  .workspace__enterprise_info {
    margin-left: 8px;
    color: var(--content-color-primary);
    user-select: none;
    cursor: default;
  }
}

.workspace__enterprise-tooltip {
  margin-left: 5px;
}

.workspace-visibility-team {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  color: var(--content-color-primary);

  .workspace-visibility-team--name {
    margin-left: 10px;
  }

  .pm-icon {
    display: flex;
    justify-content: center;
    padding: 2px;
  }

  &--roles {
    .dropdown {
      max-width: 140px;
    }

    .dropdown-button {
      .btn {
        background: unset;
        color: var(--content-color-secondary);
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 0;
      }
      span {
        @include text-truncate-ellipsis;
      }
    }
  }
}
