.context-bar-code-snippet-view {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.context-bar-code-snippet-body {
  display: flex;
  flex-direction: column;
  height: inherit;
  padding: var(--spacing-zero) var(--spacing-s);

  .context-bar-codegen-controls {
    display: flex;
    flex-direction: row;
    height: 32px;
    justify-content: space-between;
    .context-bar-codegen-controls-left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .context-bar-codegen-controls-left__setting {
        display: flex;
        margin-left: var(--spacing-m);
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        padding-right: var(--spacing-l);

        .language-settings-icon {
          cursor: pointer;
        }
      }
    }
    .context-bar-codegen-controls-right {
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn {
        width: var(--size-m);
        height: var(--size-m);
      }

      .context-bar-codegen-controls-right__copy {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;

        .code-copy-icon {
          cursor: pointer;
        }
      }
    }
  }

  .context-bar-code-snippet-editor {
    display: flex;
    margin-top: var(--spacing-l);
    height: inherit;
  }
}

.context-bar-dropdown-wrapper {
  padding: 0px;
  width: 200px;
}
