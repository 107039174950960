.websocket-events {
  overflow-y: auto;
  margin: var(--spacing-s) var(--spacing-m);
}


.table-editor {
  &-cell__name:not(:focus-within) {
    // Important to override default style of the TableEditor
    border-right: none !important;
  }

  &-header__name {
    // Important to override default style of the TableEditor
    border-right: none !important;
  }

  &-cell__subscribeOnConnect {
    // Important to override default style of the TableEditor
    align-items: center !important;
  }

  &-cell__description {
    // Important to override default style of the TableEditor
    padding-left: var(--spacing-l) !important;
  }

  &-header__description {
    // Important to override default style of the TableEditor
    padding-left: var(--spacing-l) !important;
  }

  &-header__subscribeOnConnect {
    // Important to override default style of the TableEditor
    padding-right: var(--spacing-xs) !important;
    justify-content: center !important;
  }
}
