.activity-item-content {
  padding: 10px 0;
  width: 100%;
}

.activity-feed-connect-line {
  position: relative;
  top: 20px;
  left: 26px;
  z-index: -1;
  border-left: 1px solid $app-border-color;
}

.activity-item-header {
  display: flex;
  padding-bottom: 10px;

  .activity-item-head {
    flex: 1;
    margin-right: 20px;

    & > * {
      margin-bottom: 5px;
    }
  }
}

.activity-item-body {
  padding: 10px;
  margin-left: 50px;
  border: 1px solid var(--border-color-strong);
}

.activity-item-details-wrapper {
  padding: 0 10px;
}

.activity-item-details {
  border-left: 2px solid var(--border-color-default);
  margin-left: 14px;
  padding-left: 24px;
}

.activity-item__action {
  margin-left: 50px;
  color: var(--content-color-link);
  display: inline-block;

  @include unselectable;
  @include clickable;

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .activity-item-remove-tag {
    &.is-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  .activity-item-restore {
    margin-left: var(--spacing-s);
    padding-left: var(--spacing-s);
    border-left: 1px solid var(--border-color-default);
  }

  .activity-item-restore-loader {
    display: inline-block;
    margin-left: 16px;
    position: relative;
    top: 4px;
  }
}

.activity-item-profile-pic-wrapper {
  margin: 0 16px auto 0;
  cursor: pointer;
}

.activity-item-meta {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 600;
  color: $activity-item-meta-content-color;

  .version-label-wrapper {
    display: flex;
    max-width: 100%;
  }

  .activity-item-tagged_version__label {
    margin-left: 8px;
    border-radius: var(--border-radius-default);
    padding: 0px 6px;
    border: 1px solid var(--border-color-default);

    &.is-tag {
      color: $activity-item-meta-tag-color;
      background: var(--backgrouund-color-tertiary);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      max-width: 70px;
    }
  }
}

.activity-item-heading {
  color: $activity-feed-header-content-color;

  .activity-item-head-content {
    display: inline;
  }
}

.activity-item-model {
  font-weight: 600;
  color: $activity-feed-font-color;
}

.activity-item-user {
  font-weight: 600;
  color: $activity-item-meta-user-color;
  cursor: pointer;
  &:hover {
    color: var(--content-color-link);
    text-decoration: underline;
  }
}

.activity-item-wrapper:last-child {
  .activity-feed-connect-line {
    visibility: hidden;
  }
}
