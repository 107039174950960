.collection-sidebar-request-actions-dropdown {
  &.collection-sidebar-request-actions-dropdown--single-menu {
    .dropdown-menu {
      width: 155px;
    }
  }

  .dropdown-menu {
    margin-top: 0;
    margin-right: 3px;
    width: 175px;
  }
}
