.extensible-collection-editor-header {
  &.entity-tab-header {
    border-bottom: unset;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > * {
      margin-right: var(--spacing-s);
    }
  }
}
