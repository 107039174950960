.pm-list {
  overflow-y: scroll;
}

.pm-row {
  overflow-x: scroll;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
}
