/* Variables */
$dropdown-menu-background-color: var(--background-color-secondary);
$dropdown-menu-item-color: var(--content-color-primary);
$dropdown-menu-item-shortcut-label-color: var(--content-color-tertiary);
$dropdown-separator-color: var(--border-color-default);

$dropdown-menu-min-width: 170px !default;
$dropdown-menu-z-index: 50 !default;

.dropdown {
  position: relative;
  display: inline-block;

  &.full-width {
    width: 100%;
    .dropdown-button {
      .btn {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &.scroll-menu {
    .dropdown-menu {
      max-height: 120px;
      overflow-y: auto;
    }
  }
}

.dropdown-menu {
  padding: var(--spacing-s) var(--spacing-zero);
  box-sizing: border-box;
  min-width: $dropdown-menu-min-width;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-strong);

  .dropdown-menu-item--delete {
    color: var(--content-color-error)
  }

  .dropdown-menu-item--delete:hover {
    color: var(--content-color-constant);
    @include icon-color(var(--content-color-constant));
    background-color: var(--base-color-error);

    .dropdown-menu-item-shortcut {
      color: var(--content-color-constant);
    }
  }

  .is-destructive {
    color: var(--content-color-error)
  }

  .is-destructive:hover {
    color: var(--content-color-constant);
    @include icon-color(var(--content-color-constant));
    background-color: var(--base-color-error);

    .dropdown-menu-item-shortcut {
      color: var(--content-color-constant);
    }
  }
}

.dropdown-menu-item-shortcut {
  color: var(--content-color-tertiary);
}

.dropdown-menu-item {
  position: relative;
  box-sizing: border-box;
  height: var(--controls-size-default);
  padding: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  border-radius: var(--border-radius-default);
  margin: var(--spacing-zero) var(--spacing-s);

  display: flex;
  align-items: center;

  @include unselectable;
  @include clickable;

  @include hover {
    background-color: var(--highlight-background-color-primary);
    .dropdown-menu-item-shortcut {
      color: var(--content-color-primary);
    }
  }

  @include focus {
    background-color: var(--highlight-background-color-secondary);
  }

  &.align-right {
    text-align: right;
  }

  &.is-focused {
    background: var(--highlight-background-color-secondary);
  }

  &.is-selected {
    background:  var(--highlight-background-color-tertiary);
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.4;
    position: relative;

    .dropdown-menu-item__tooltip {
      position: absolute;
      padding: 10px;
    }
  }

  span {
    @include text-truncate-ellipsis;
  }
}

.dropdown-menu-item-icon {
  flex: 0 0 20px;
  margin-right: 10px;
}

.dropdown-menu-item-label {
  flex: 1;
}

.dropdown-caret {
  margin-left: 10px;

  &.pm-icon {
    display: inline-flex;
  }

  .btn-group-separated & {
    margin-left: var(--spacing-zero);
  }
}

.dropdown-sub-menu-item {
  position: absolute;
  top: 0;
  left: 100%;
  margin-top: var(--spacing-zero);
  visibility: hidden;
  border-radius: var(--border-radius-default);

  &.show {
    visibility: visible;
  }
}

.is-sub-item-available {

  .expand-icon-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: var(--spacing-s);
    justify-content: flex-end;
    align-items: center;
  }
}

.menu-item-header {
  font-size: var(--text-size-xs);
  text-transform: uppercase;
  color: var(--content-color-tertiary);
  height: var(--size-s);
  padding: var(--spacing-s) var(--spacing-l);
  box-sizing: border-box;
}
