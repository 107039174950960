.raw-websocket-messages-documentation {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);

  &__item {
    display: flex;
    flex-direction: column;
  }

  & > span {
    font-weight: var(--text-weight-bold);
  }
}
