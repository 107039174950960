.free-trial-activation {
  padding: 0 !important;
  overflow-y: hidden;

  &__illustration {
    text-align: center;

    padding: var(--spacing-xxxl) 0;
    background-color: var(--background-color-secondary);
  }

  &__content {
    margin: var(--spacing-xl);

    &-actions {

      .action-icon {
        border-radius: var(--border-radius-max);
        width: var(--size-m);
        height: var(--size-m);
        background-color: var(--background-color-secondary);

        i {
          margin: var(--spacing-s);
        }
      }
    }
  }

  &__footer{
    padding-bottom: var(--spacing-l);
  }
}
