$empty-state-path: '../assets/images/empty-state/' !default;
$icon-no-collections-url: $empty-state-path + 'collection_cloud.svg';

.entity-empty {
  .entity-empty__illustration-wrapper--collections {
    .collections-empty__illustration {
      @include icon(
        $icon-no-collections-url,
        115px,
        95px
      )
    }
  }
  .entity-empty__action--text {
    margin-top: 10px;
  }
}
