$welcome-modal-left-background-color: var(--background-color-secondary);
$welcome-modal-right-background-color: var(--background-color-primary);
$table-border-color: var(--border-color-default);

.user-welcome-modal {
  .modal-content {
    display: flex;
    padding: 0;
    height: 570px;
    overflow-y: initial;
  }

  .user-welcome-modal--dismiss-icon {
    position: absolute;
    right: 10px;
    top: 14px;
    cursor: pointer;
  }

  .user-welcome__left-section {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    padding: 10px 32px;
    box-sizing: border-box;
    background-color: $welcome-modal-left-background-color;
    border-right: 1px solid var(--border-color-default);
    max-height: 100%;

    .section-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 4;
    }

    .section-subtext {
      font-size: 14px;
      line-height: 1.7;
      flex: 1;

      &.intro-text {
        flex: 0 0 150px;
      }

      .section-subtext__info {
        color: var(--base-color-warning);
      }

      .section-subtext__text {
        .btn {
          color: inherit;
          font-size: 14px;
          text-decoration: underline;
          padding: 0;
        }

        &.links-label {
          font-size: 12px;
        }
      }

      .help-link {
        font-size: 16px;
        padding: 20px 0;
      }
    }

    .section-action {
      font-size: 14px;

      .section-action__action {
        font-weight: 300;
      }
    }
  }

  .user-welcome__right-section {
    display: flex;
    min-width: 560px;
    padding: 10px 32px;
    box-sizing: border-box;
    background-color: $welcome-modal-right-background-color;
    overflow-y: auto;
    flex-direction: column;

    .tabs {
      border-bottom: 1px solid var(--border-color-default);
      margin: 0 -32px 20px;
      flex: 0 0 35px;
    }

    .section__cards {
      display: flex;
      flex-direction: column;
      flex: 1;

      .section__card {
        display: flex;
        min-height: 80px;
        border: 1px solid var(--border-color-default);
        border-radius: var(--border-radius-default);
        margin: 8px 0;
        box-sizing: border-box;
        align-items: center;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 20px;
        }

        .section__card__illustration-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 96px;
          height: 100%;
        }

        .section__card__text {
          padding: 24px;
          max-width: 350px;

          .btn-text {
            padding: 0;
          }
        }

        &.section__card--collections {
          .section__card__illustration {
            @include icon(
              '../assets/images/thumbnails/organize-collections.svg',
              48px,
              39px
            )
          }
        }

        &.section__card--sharing {
          .section__card__illustration {
            @include icon(
              '../assets/images/thumbnails/share-collections.svg',
              64px,
              31px
            )
          }
        }

        &.section__card--docs {
          .section__card__illustration {
            @include icon(
              '../assets/images/thumbnails/automatic_docs.svg',
              50px,
              50px
            )
          }
        }

        &.section__card--monitors {
          .section__card__illustration {
            @include icon(
              '../assets/images/thumbnails/test-collections.svg',
              72px,
              36px
            )
          }
        }

        &.section__card--mocks {
          .section__card__illustration {
            @include icon(
              '../assets/images/thumbnails/mock-collection.svg',
              46px,
              36px
            )
          }
        }
      }
    }

    .activity-feed-list {
      overflow: auto;
      padding-left: 15px;

      .activity-feed-list-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;
        margin: 10px 0;
        border-radius: var(--border-radius-default);

        @include hover {
          background-color: var(--background-color-tertiary);
        }

        &:before {
          content: ' ';
          position: absolute;
          top: 15px;
          width: 10px;
          left: -12px;
          height: 10px;
          background-color: $table-border-color;
          border-radius: 50%;
        }
      }

      .activity-feed-list-item--name {
        font-weight: 600;
      }

      .activity-feed-list-item--date {
        opacity: 0.4;
      }

      .timeline {
        position: relative;
        &:before {
          content: ' ';
          position: absolute;
          top: 20px;
          bottom: 40px;
          left: -8px;
          width: 2px;
          background-color: $table-border-color;
        }
      }
    }
  }
}
