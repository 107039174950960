.websocket-message-viewer__editor {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-secondary);
  flex: 1 auto;
  height: 100%;

  // This is added to fix horizontal scroll in streaming area of SocketIO
  // @todo [SocketIO]: Fix this
  min-width: 0;

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--size-m);
    padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-xs) var(--spacing-l);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

    &.is-binary {
      justify-content: flex-end;
    }

    &__section-l, &__section-r {
      display: flex;
      align-items: center;
    }

    &__section-l  {
      .line-wrap-active i svg {
        color: var(--content-color-brand);
      }

      & > * {
        margin-right: var(--spacing-s);
      }
    }
  }

  &__monaco-editor {
    height: 100%;

    .monaco-editor {
      .monaco-editor-background {
        background-color: var(--background-color-secondary);
      }

      .margin-view-overlays {
        background-color: var(--background-color-secondary);

        .line-numbers {
          color: var(--content-color-tertiary);
        }
      }
    }
  }
}

.websocket-message-viewer__iframe {
  display: flex;
  flex: 1 auto;
  height: 100%
}
