.env-sidebar-menu__actions-refresh-wrapper {
  @include unselectable;
  @include clickable;
  @include active;
  @include hover {
    @include icon-color($icon-color-secondary);
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.4;

    .env-sidebar-menu__actions-refresh-loading {
      @include hover {
        @include icon-color(var(--content-color-secondary));
      }
    }
  }
}

.env-sidebar-menu__actions-refresh-loading {
  cursor: default;
  -webkit-animation: rotating 1s linear infinite;

  @include hover {
    @include icon-color(var(--content-color-secondary));
  }
}
