.documentation-scratchpad-prompt {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);

  &__icon-container {
    height: calc(2 * var(--size-m));
    width: calc(2 * var(--size-m));
    background-color: var(--background-color-tertiary);
    border-radius: var(--border-radius-max);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .documentation-scratchpad-prompt__icon {
      height: var(--size-m);
      width: var(--size-m);

      svg {
        height: var(--size-m);
        width: var(--size-m);
      }
    }
  }

  &__title {
    color: var(--content-color-secondary);
    padding: var(--spacing-xs) var(--spacing-xxxl);
    text-align: center;

    h4 {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      margin-bottom: var(--spacing-zero);
      margin-top: var(--spacing-l);
    }
  }

  &__body {
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-l);
    padding: var(--spacing-xs) var(--spacing-m);
    text-align: center;
  }

  &__action {
    justify-content: start;

    &__icon {
      margin-right: var(--spacing-s);
      @include icon-color(var(--content-color-brand))
    }
  }
}
