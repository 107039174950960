.changelog-offline__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.changelog-offline {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 200px;

  &__content {
    text-align: center;
    line-height: var(--line-height-m);
    margin-top: var(--spacing-l);

    &__header {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      margin-bottom: var(--spacing-s);
      color: var(--content-color-primary);
    }

    &__sub-header {
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
    }
  }
}
