$share-collection-modal-copy-link-background-color: var(--background-color-secondary);
$share-collection-modal-copy-link-border-color: var(--border-color-strong);
$share-collection-modal-secondary-text-color: var(--content-color-tertiary);
$share-collection-env-selector-width: 320px;

.share-collection-modal-container-wrapper {
  flex: 1;

  display: flex;
  flex-direction: column;

  .tab {
    min-width: 90px;
  }

  .add-to-workspace-modal__contents {
    padding: 20px;
  }
}

.share-collection-modal-container {
  flex: 1;

  display: flex;
  flex-direction: column;

  padding: 0;
}

.share-collection-modal-container--new-collection {
  padding: 20px;
  .collection-modal-onboarding-wrapper {
    padding-bottom: 0px;
  }
}

.share-collection-modal-content-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.share-collection-modal-tabs-header {
  padding-top: 8px;
  border-bottom: 1px solid var(--border-color-default);
}

.share-collection-modal-header {
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 24px;
}

.share-collection-modal-runinpostman-container,
.share-collection-modal-collection-link-container {
  height: 250px;
  margin-top: 15px;
}

.share-collection-modal-header-description {
  font-size: 11px;
}

.share-collection-modal-section-separator {
  padding: 10px;
}

.share-collection-modal-access-dropdown {
  margin-top: 10px;
}

.share-collection-modal-access-checkbox-label {
  padding-left: 10px;
}

.share-collection-modal-access-user-filtered-selector-container {
  padding: 10px;
  border: 1px solid $share-collection-modal-copy-link-border-color;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.share-collection-modal-access-show-users-description {
  padding-bottom: 10px;
  font-size: 11px;
  color: $share-collection-modal-secondary-text-color;
}

.share-collection-modal-access-user-wrapper-container {
  flex: 1;
  min-height: 250px;
}

.share-collection-modal-access-user-list-container {
  height: 140px;
  padding: 5px 10px;
  overflow-y: auto;
  border: 1px solid $share-collection-modal-copy-link-border-color;
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.share-collection-modal-fetching-link-button {
  color: var(--content-color-constant);
  background-color: $button--main-background-color;
  @include hover {
    color: var(--content-color-constant);
    background-color: $button--main--hover-background-color;
  }

  @include active {
    background-color: $button--main--active-background-color;
  }
}

.share-collection-modal-text-wrapper {
  flex: 1;
  margin-right: 10px;
}

.share-collection-modal-collection-link-text-wrapper {
  margin-bottom: 4px;
  color: var(--content-color-primary);
}

.share-collection-modal-collection-link-subtext-wrapper {
  color: var(--content-color-tertiary);
  margin-bottom: 16px;
  .share-collection-modal-header-description-link {
    color: var(--content-color-tertiary);
    text-decoration: underline;

    span:hover {
      // Overrides the blue link color from Link component
      color: var(--content-color-tertiary);
    }
  }
}

.share-collection-modal-collection-link-footer-wrapper {
  background-color: var(--background-color-warning);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .share-collection-modal-collection-link-footer-warning-icon {
    margin: 16px 16px 0px 16px;
    position: absolute;

    &.pm-icon-normal use {
      fill: var(--base-color-warning);
    }
  }

  .share-collection-modal-collection-link-footer-close-icon {
    margin-left: 16px;
    margin-right: 16px;
    align-self: center;
    @include clickable;
  }

  .share-collection-modal-collection-link-footer-text {
    display: flex;
    flex-direction: column;
    margin: 16px 0px 16px 54px;
    font-size: 12px;
    line-height: 20px;
    color: var(--content-color-primary);

    .share-collection-modal-collection-link-footer-text-heading {
      font-weight: var(--text-weight-medium);
      margin-bottom: 4px;
    }

    .share-collection-modal-collection-link-footer-content {
      color: var(--content-color-primary);
    }
  }
}

.share-collection-modal-uploadcollection-button {
  width: 100px;
}

.share-collection-modal-collection-link-wrapper {
  margin-bottom: 10px;
}

.share-collection-modal-collection-link-wrapper__link {
  display: flex;

  .input-field {
    flex: 0;
  }
}

.share-collection-modal-collection-link-wrapper__clipboard {
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
}

.share-collection-modal-collection-link {
  height: 30px;
  width: 355px;
  padding: 0 5px;
  margin-right: 5px;
  font-size: 10px;
  background-color: $share-collection-modal-copy-link-background-color;
  border: 1px solid $share-collection-modal-copy-link-border-color;
  border-radius: var(--border-radius-default);
  align-items: center;

  @include hover {
    background-color: $share-collection-modal-copy-link-background-color;
  }

  @include focus {
    background-color: $share-collection-modal-copy-link-background-color;
  }
}

input[disabled] {
  &.share-collection-modal-collection-link {
    @include selectable;
  }
}

.share-collection-modal-collection-link-lastupdated-info {
  font-size: 11px;
  line-height: 18px;
  color: var(--content-color-secondary);
  align-self: center;
  @include selectable;
}

.share-collection-modal-current-share-status {
  font-weight: 600;
  color: var(--content-color-brand);
}

.edit-collection-description-editor {
  min-height: 100px;
}

.share-collection-modal-button {
  width: 100%;

  &.is-disabled {
    @include unselectable;
    background-color: var(--highlight-background-color-brand);
  }
}

.share-collection-modal-upgrade-trial-link {
  padding-top: 20px;
}

.share-collection-modal-shareunshare-button-wrapper {
  align-self: baseline;
}

.share-collection-modal-rip-container {
  margin-top: var(--spacing-s);

  .accordion .accordion-item {
    padding-bottom: var(--spacing-l);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .title {
      height: var(--size-s);

      i, svg {
        height: var(--size-s);
        width: var(--size-s);
      }
    }

    .panel {
      overflow: visible;
    }
  }

  .share-collection-modal-rip-content {
    &__desc {
      display: inline-block;
      margin-top: var(--spacing-s);
      width: 70%;
    }

    .infobar {
      margin: var(--spacing-l) 0 0 0;
      background-color: var(--background-color-info);

      i {
        margin-right: var(--spacing-xs);
      }
    }

    &__env-selector {

      &--icon-wrapper {
        margin-right: var(--spacing-xs);
        height: var(--size-xs);
      }

      &--label {
        display: flex;
        align-items: center;
        margin-bottom: var(--spacing-s);

        label {
          margin-right: var(--spacing-xs);
        }

        span {
          display: flex;
        }
      }

      .share-collection-modal-dropdown.dropdown {
        width: $share-collection-env-selector-width;
      }
    }

    &__code-container {
      padding: var(--spacing-s);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      border-radius: var(--border-radius-default);

      &.team-collection {
        margin: var(--spacing-zero);
      }

      &--copy {
        i {
          margin-right: var(--spacing-xs);
        }
      }

      &--heading {
        margin-bottom: var(--spacing-s);
      }

      &--code {
        margin-top: var(--spacing-m);
        color: var(--content-color-primary);
        font-size: var(--text-size-m);

        code {
          white-space: pre-wrap;
          word-break: break-all;
        }
      }
    }

    &__static-actions {
      margin-top: var(--spacing-s);

      &--date span {
        font-weight: var(--text-weight-medium);
      }

      .btn:first-child {
        margin-right: var(--spacing-s);
      }
    }

    &__success-btn {
      color: var(--content-color-success);
      cursor: default;

      i {
        margin-right: var(--spacing-xs);
      }

      &, &:hover {
        background: none;

        i svg {
          color: var(--content-color-success);
        }
      }

    }

    &__create {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--spacing-s);

      span {
        max-width: 70%;
      }
    }
  }
}

.share-collection-modal-dropdown {
  &.dropdown {
    width: $share-collection-env-selector-width;
  }

  .dropdown-button .btn {
    display: flex;
    width: 100%;

    .share-collection-modal-rip-environment-selector-selected-label {
      flex: 1;
      text-align: left;
      @include text-truncate-ellipsis;
    }
  }

  .share-collection-modal-rip-environment-selector-dropdown-menu {
    width: 100%;
    max-width: $share-collection-env-selector-width;
    max-height: 190px;
    overflow-y: auto;
  }

  .dropdown-menu-item {
    display: block;
    padding-top: 7px;
    @include text-truncate-ellipsis;
  }
}

.share-collection-modal-collection-link-delete-button {
  margin-left: 5px;
  background-color: var(--background-color-tertiary);
  @include focus {
    background-color: var(--highlight-background-color-tertiary);
  }

  @include hover {
    background-color: var(--highlight-background-color-tertiary);
  }

  @include active {
    background-color: var(--highlight-background-color-tertiary);
  }
}

.share-collection-modal-collection-link-delete {
  @include hover {
    @include icon-color($icon-color-secondary);
  }
}

.share-collection-modal-copied-button,
.share-collection-modal-copy-button {
  width: 130px;
}

.share-collection-modal-run-in-postman-copied-button,
.share-collection-modal-copied-button {
  background-color: var(--base-color-success);

  @include hover {
    background-color: var(--base-color-success);
  }

  @include unselectable;
}

.share-collection-modal-permissions {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 415px;
  .share-collection-modal-permissions--container {
    padding: 10px 0 5px;
  }
}

.share-collection-modal-permissions--team {
  display: flex;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  align-items: center;

  .share-collection-modal-permissions--team-label {
    flex: 1;
  }
}

.share-collection-modal-permissions-unshare-message {
  min-height: 35px;
  padding: 10px 0 5px;
}

.share-collection-modal-permissions-controls {
  margin: 5px 0;
  justify-content: flex-end;

  .share-collection-modal-permissions-buttons {
    display: flex;
    margin-top: 10px;

    .btn {
      margin-left: 10px;
    }

    .share-collection-modal-permissions-buttons--subscribe {
      min-width: 120px;
    }

    .share-collection-modal-permissions-buttons--cancel {
      margin-left: auto;
      align-self: center;
    }
  }
}

.share-collection-modal-login-empty-message-container {
  display: flex;
  height: 300px;
  align-items: center;

  .share-collection-modal-login-empty-message {
    flex: 1;
    text-align: center;
    align-self: center;
  }

  .share-collection-modal-login-link {
    color: var(--content-color-brand);
    cursor: pointer;
  }
}


.share-collection-permissions-empty-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  padding-top: 20px;

  .non-pro-text {
    .btn-text {
      padding: 0 3px;
    }
  }

  .signout-out-signin-btn,
  .learnmore-link {
    font-weight: 300;
    font-size: 12px;
    margin-top: 16px;
  }
}

.share-collection-modal-container__tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.share-collection-modal--invite-user-empty-container {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;

  .share-collection-modal--invite-user-empty-text {
    text-align: center;
    color: var(--content-color-tertiary);
  }
}

.share-collection-modal--create-team {
  margin-top: 32px;
}

.share-collection-modal-collection-link-container {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.share-collection-modal-collection-link-content-wrapper {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.share-collection-modal-collection-link-section {
  flex:1;
  margin-bottom: var(--spacing-l);
}

.share-collection-modal-link {
  color: var(--content-color-secondary);

  &__disabled {
    color: var(--content-color-primary);
  }
}
