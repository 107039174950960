.schema-editor__wrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;

  &.comment-available__light {
    .view-lines {
      cursor: url('../assets/images/thumbnails/select-cursor-dark.svg') 4 8, text;

      .view-line {
        span {
          cursor: url('../assets/images/thumbnails/comment-select-cursor-dark.svg') 4 8, text;
        }
      }
    }
  }

  &.comment-available__dark {
    .view-lines {
      cursor: url('../assets/images/thumbnails/select-cursor-light.svg') 4 8, text;

      .view-line {
        span {
          cursor: url('../assets/images/thumbnails/comment-select-cursor-light.svg') 4 8, text;
        }
      }
    }
  }
}
