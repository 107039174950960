$requester-app-min-width: 972px;

body,
.app-root,
.app-requester {
  position: absolute;
  height: 100%;
  width: 100%;

  .requester-content-loader {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}

body {
  background-color: $app-background-color;

  // To disable OSX elastic scrolling at boundaries
  overflow: hidden;
}

.app-root {
  overflow-x: auto;
}

.app-requester {
  display: flex;
  flex-direction: column;
  min-width: $requester-app-min-width;
  overflow: hidden;

  .requester-contents {
    flex: 1;
  }

  .offline-state-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .action-button {
      margin-top: var(--spacing-s);
    }
  }
}

.loader-blocker {
  background-color: var(--background-color-primary);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  margin: auto;
  z-index: 3000;
  animation: fadein 0.2s;
}

.sidebar {
  z-index: 1;
}

.requester-contents {
  position: relative;
  display: flex;
  flex-direction: row;

  overflow: hidden;
  min-height: 0;

  .is-hidden {
    display: none;
  }
}

.requester-content {
  flex: 1;
  min-height: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

.requester-sidebar-header {
  height: 100%;

  .requester-sidebar-header__tabs {
    display: flex;
    flex-direction: column;

    .pm-icon {
      padding: 8px;
      @include icon-color(var(--content-color-secondary));
      @include clickable;

      &:hover {
        @include icon-color(var(--content-color-primary));
      }
    }
  }
}
