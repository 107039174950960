@-webkit-keyframes bounce-middle {
  0% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
  50% {
    height: 20px;
    margin-top: var(--spacing-zero);
    margin-bottom: var(--spacing-zero);
  }
  100% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
}

@keyframes bounce-middle {
  0% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
  50% {
    height: 20px;
    margin-top: var(--spacing-zero);
    margin-bottom: var(--spacing-zero);
  }
  100% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
}

.loading-indicator-wrapper {
  height: 20px;

  .loading-indicator{
    position: relative;
    display: inline-block;
    -webkit-animation: bounce-middle 0.6s ease 0.1s infinite;
            animation: bounce-middle 0.6s ease 0.1s infinite;

    &,
    &:before,
    &:after {
      width: 4px;
      height: 20px;
      border-radius: var(--border-radius-s);
      background-color: var(--highlight-background-color-tertiary);
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 50%;

      -webkit-transform: translateY(-10px) translateZ(0);
              transform: translateY(-10px) translateZ(0);
    }

    &:before {
      left: -6px;

      -webkit-animation: bounce-middle 0.6s ease 0s infinite;
              animation: bounce-middle 0.6s ease 0s infinite;
    }

    &:after {
      left: 6px;

      -webkit-animation: bounce-middle 0.6s ease 0.2s infinite;
              animation: bounce-middle 0.6s ease 0.2s infinite;
    }
  }
}
