.collection-sidebar-list-item__loading {
  height: 100%;
  overflow: hidden;
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);

  .sidebar-loading-state--item {
    display: flex;
    align-items: center;
    padding: var(--spacing-s) var(--spacing-zero);
    margin-left: var(--spacing-xxl);
    animation: fade 1s ease-in-out infinite;

    .sidebar-loading-state__icon {
      height: 8px;
      width: 8px;
      background-color: var(--highlight-background-color-secondary);
      margin: var(--spacing-zero) var(--spacing-s);
      border-radius: var(--border-radius-s);
      visibility: hidden;

      &.has {
        visibility: visible;
      }
    }

    .sidebar-loading-state-indentation {
      height: var(--size-s);
      border-right: 1px solid var(--border-color-default);
      width: 0;
      margin: var(--spacing-zero) var(--spacing-s);
    }

    .sidebar-loading-state__pre-info {
      height: 8px;
      width: var(--size-s);
      background-color: var(--highlight-background-color-secondary);
      margin-left: var(--spacing-xs);
      border-radius: var(--border-radius-s);
      margin-right: var(--spacing-m);
    }

    .sidebar-loading-state__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100px;
      height: 8px;
      background-color: var(--highlight-background-color-secondary);
      border-radius: var(--border-radius-s);
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}
