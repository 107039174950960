.account-events {
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: var(--spacing-xxxl);

    &-icon {

      svg {
        height: 100%;
        width: 100%;
      }

      height: 150px;
      width: 150px;
    }
  }
  &__loader {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__list-heading {
    display: flex;
    font-size: var(--text-size-m);
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-medium);
    border-bottom: 1px solid var(--border-color-default);
    padding: var(--spacing-s);
    padding-top: 0;

    &-date {
      width: 25%;
    }
  }

  &__list-item {
    display: flex;
    padding: var(--spacing-m) var(--spacing-s);
    border-bottom: 1px solid var(--border-color-default);

    &-date {
      width: 25%;
      font-size: var(--text-size-m);
      color: var(--content-color-tertiary);
    }

    &-details {
      width: 75%;
    }

    &-event-type {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      margin-bottom: var(--spacing-s);
    }

    &-message {
      font-size: var(--text-size-m);
      color: var(--content-color-tertiary);
    }
  }
}

.account-history__description {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-xl);
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
}
