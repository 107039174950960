.entity-tab-empty-shell {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: var(--background-color-primary);
  animation: fade 1s linear infinite;

  &__header-wrapper {
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

    &__content {
      display:flex;
      justify-content: space-between;
      margin: var(--spacing-m) var(--spacing-l);

      &__name-strip {
        height: var(--size-s);
        width: 196px;
      }

      &__actions {
        display: flex;
        width: 96px;
        justify-content: space-around;
      }
    }
  }

  &__body-wrapper {
    display: flex;
    flex: 1;

    &__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: var(--spacing-l);

      .tab-strip {
        display: flex;
        margin-bottom: var(--spacing-l);

        .tab {
          height: 20px;
          margin-right: var(--spacing-l);

          &.xl {
            width: 196px;
          }

          &.md {
            width: 80px;
          }

          &.s {
            width: 56px;
          }

          &.xs {
            width: 40px;
          }
        }
      }

      &__primary {
        height: 120px;
        margin-bottom: var(--spacing-m);
      }

      &__secondary {
        width: 320px;
        height: 40px;
      }
    }

    &__context-bar {
      display: flex;
      flex-direction: column;
      width: 40px;
      border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

      & > * {
        margin: var(--spacing-s) var(--spacing-s) var(--spacing-zero);
      }
    }
  }

  &__left_editor {
    flex: 1;
  }

  &__right-context-bar {
    width: 440px;
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    padding: var(--spacing-xs) var(--spacing-xl) var(--spacing-s) var(--spacing-m);
    display: flex;
    box-sizing: border-box;

    .context-bar-options {
      display: flex;
      flex-direction: column;
      .box {
        margin-bottom: var(--spacing-m);
        margin-top: var(--spacing-xs);
      }
    }

    .context-bar-body {
      margin: var(--spacing-s)  var(--spacing-m)  var(--spacing-s)  var(--spacing-xl);
      display: flex;
      justify-content: space-between;
      flex : 1;
    }

    .context-bar-header {
      height: var(--size-s);
      width: 120px;
      background-color: var(--background-color-tertiary);
    }
    .context-bar-close-icon {
      height: var(--size-s);
      width: var(--size-s);
      background-color: var(--background-color-tertiary);
    }
  }
  .box {
    height: var(--size-s);;
    width: var(--size-s);;
  }

  .block {
    background-color: var(--background-color-tertiary);
    border-radius: var(--border-radius-default);
  }
}
