.alert {
  padding: 16px;
  display: flex;
  font-family: $font-primary;
  color: var(--content-color-primary);
  position: relative;

  a {
    font-weight: var(--text-weight-regular);
    text-decoration: underline;

    &:hover {
      color: var(--content-color-link);
    }
  }

  .alert-icon {
    margin: var(--spacing-xs) var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
  }

  .alert-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;

    .alert-title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;

      * {
        margin: 0;
      }
    }

    .alert-body {
      font-size: 12px;
      line-height: 20px;

      * {
        margin: 0;
      }
    }

    .alert-actions {
      .alert-primary-action {
        margin-right: 16px;
      }
    }
  }

  .alert-content-shrink {
    padding-right: 30px;
  }

  .alert-actions {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .alert-dismiss {
    width: 48px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 4px 4px 0px;

    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    &:hover,
    .alert-close:hover {
      @include clickable;
    }
  }
}

.alert-info {
  background-color: var(--background-color-secondary);
}

.alert-success {
  background-color: var(--background-color-success);
}

.alert-error {
  background-color: var(--background-color-error);
}

.alert-warn {
  background-color: var(--background-color-warning);
}

.alert-notification {
  background-color: var(--background-color-info);
}
