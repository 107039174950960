.request-urlparams-editor-container {
  .urlparams-editor {
    &__params,
    &__path-variables {
      margin-right: var(--spacing-l);
      margin-left: var(--spacing-l);
      margin-bottom: var(--spacing-m);
      .key-value-form-editor {
        border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default) !important;
        border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      .key-value-form-editor-sortable__selectable {
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }
  }
}
