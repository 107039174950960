.env-sidebar-list-item {
  display: flex;
  margin: var(--spacing-zero) var(--spacing-s);
  height: 32px;
  align-items: center;
  border-radius: var(--border-radius-default);
  @include unselectable;
  @include clickable;

  @include hover {
    background-color: var(--highlight-background-color-primary);

    .env-sidebar-list-item__actions {
      display: flex;
      margin-left: var(--spacing-s);

      .btn {
        background-color: transparent;
      }
    }
  }

  &.selected {
    background-color: var(--background-color-brand)
  }

  &__icon{
    display: flex;
    align-items: center;
    margin: var(--spacing-zero) var(--spacing-s);

    @include icon-color(var(--content-color-secondary))
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 0;
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
    font-family: $font-primary;
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    @include unselectable;
    @include clickable;

    &__name {
      padding: 3px;
      @include text-truncate-ellipsis;
    }

    .inline-input__wrapper {
      .input-box {
        padding: 2px //this is done to prevent jumping of inline input when it goes in editing mode
      }
    }
  }

  &__actions {
    display: none;
  }

  &__meta {
    display: none;
    color: var(--content-color-brand);
    margin: var(--spacing-zero) var(--spacing-s);

    &.is-active {
      display: flex;
      align-items: center;
    }
  }
}
