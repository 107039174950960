.api-dev-table-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);

  .api-dev-table-header__cell:last-child {
    margin-left: auto;

    &.shrinked {
      flex: 0;
    }
  }
  .empty-column {
    margin-right:var(--spacing-xxl);
  }

  .api-dev-table-header__cell {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
    flex: 0;

    &-name{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .api-dev-table-header__helper-icon {
    display: flex;
    padding: var(--spacing-zero) var(--spacing-xs);
    margin-right: var(--spacing-s);
  }
}
