.context-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  /**
   * Fix for Safari: Safari seems to be collapsing the context bar header/footer when the container
   * is a flex container and has the height set to 100% -- which is the case for us because scrolling
   * doesn't work otherwise. Forcibly setting the flex-shrink for the header and footer seems to fix
   * this in Safari without breaking it in other browsers.
   *
   * Tracking the removal of this (some day) in https://postmanlabs.atlassian.net/browse/DOCUMENTER-2159
   */
  flex-shrink: 0;

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    margin: var(--spacing-zero);
    color: var(--content-color-primary);
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
    font-weight: var(--text-weight-medium);
  }

  h1 > .documentation-item-name__title {
    font-size: var(--text-size-xxxl);
    line-height: var(--line-height-xxxl);
  }

  h2 > .documentation-item-name__title {
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
  }

  h3 > .documentation-item-name__title {
    font-size: var(--text-size-xl);
    line-height: var(--line-height-xl);
  }

  &__auth-icon {
    margin-left: var(--spacing-s);
  }

  .context-bar-actions {
    display: flex;
    align-items: center;
  }
}
