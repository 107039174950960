.collection-sidebar {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  .collection-sidebar-menu {
    flex: 0 0 40px;
    box-sizing: border-box;
  }

  .collection-sidebar-list {
    flex: 1;
  }

  &.is-hidden {
    display: none;
  }
}
.collection-sidebar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.collection-sidebar__indent {
  flex: 0 0 var(--spacing-m);
  box-sizing: border-box;
  height: inherit;

  &.active-indent {
    border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  }
}

.collection-sidebar-empty__branch {
  display: flex;
}

.collection-sidebar-drag-layer {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.collection-sidebar-drag-preview {
  background-color: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-s) var(--spacing-m);
  box-shadow: var(--shadow-default);
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: 18px;
  font-weight: var(--text-weight-regular);
  text-align: left;
  display: inline-block;
  word-break: break-word;
  max-width: 220px;
}

