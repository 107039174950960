.plugin__startProxySession {
  .start-proxy-label-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    .start-proxy-label-text {
      margin-left: var(--spacing-xs);
    }

    .start-proxy-label-live-text {
      margin-left: var(--spacing-xs);
      color: var(--content-color-success);
      font-weight: bold;
    }
  }

  .start-proxy-label-button.proxy-active {
    @include icon-color(var(--content-color-success));
  }
}

@media only screen and (max-width: 1024px) {
  .start-proxy-label-text {
    display: none;
  }

  .start-proxy-label-live-text {
    display: none;
  }
}
