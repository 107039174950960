.creation-error {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .creation-error__blank-state {
    padding: var(--spacing-l);
  }

  .creation-error__icon {
    @include icon(
      '../assets/images/thumbnails/warning_security.svg',
      149px,
      132px
    );

    background-position: 50%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .creation-error__title {
    font-size: 18px;
    color: $create-new-primary-color;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .creation-error__subtitle {
    font-size: 14px;
    color: $create-new-primary-color;
    margin-bottom: 20px;
    max-width: 410px;
    text-align: center;
  }

  .creation-error__actions {
    .btn {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
}
