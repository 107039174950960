@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentation-publish-page-container {
  --publish-page-max-width: 750px;
  --publish-page-width: 60%;
  --button-height: 40px;

  display: flex;
  justify-content: center;
  color: var(--content-color-primary);
  height: 100%;
  min-height: 0;
  overflow-y: auto;

  .loading-indicator-wrapper {
    position: absolute;
    top: 50%;
  }

  hr {
    display: block;
    height: var(--border-width-default);
    border: 0;
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    margin: var(--spacing-zero);
    padding: var(--spacing-zero);
  }

  .documentation-publish-settings-page-before-customisation {
    .documentation-publish-settings {
      --input-height: 40px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      overflow-y: auto;

      &--limit-reached-banner {
        position: sticky;
        top: 0;
        z-index: 89;
      }

      .documentation-publish-settings-left-sidebar {
        display: flex;
        flex-direction: column;
        max-width: 275px;
        width: 30%;
        position: sticky;
        top: 0;
        margin: var(--spacing-xxl) var(--spacing-zero);

        &--collection-name {
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-l);
          line-height: var(--line-height-m);
          color: var(--content-color-secondary);
          margin-top: var(--spacing-l);
          margin-bottom: var(--spacing-s);
          word-break: break-word;
        }

        &--page-name {
          font-weight: bold;
          font-size: var(--text-size-xl);
          line-height: var(--line-height-xl);
          margin: var(--spacing-zero);
        }

        &--list {
          width: 275px;
        }

        &--list-item {
          height: var(--input-height);
          display: flex;
          align-items: center;
        }

        .pm-sidebar-navigation {
          margin-top: var(--spacing-l);

          ul {
            list-style: none;
            padding: var(--spacing-zero);
            margin: var(--spacing-zero);
            color: var(--content-color-secondary);
          }


          li {
            font-size: var(--text-size-l);
            line-height: var(--line-height-l);
            margin: var(--spacing-zero) calc(var(--spacing-s) * -1);
            padding-left: var(--spacing-s);
          }

          .active-item {
            background: var(--background-color-brand);
          }

          &__item {
            display: block;
            text-decoration: none;
            padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-zero);
            line-height: var(--line-height-l);
            color: var(--content-color-primary);

            @include ellipsis;

            &:hover {
              color: var(--content-color-primary);
            }
          }

          .preview-documentation {
            color: var(--content-color-link);
            display: flex;
            align-items: center;
            text-align: center;
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            font-weight: var(--text-weight-regular);
            cursor: pointer;
            height: var(--input-height);

            i > svg {
              color: var(--content-color-link);
            }

            span {
              padding-left: var(--spacing-s);
            }
          }
          .preview-label {
            color: var(--content-color-link);
          }
        }
      }

      .documentation-publish-settings-main-content {
        display: flex;
        flex-direction: column;
        max-width: var(--publish-page-max-width);
        width: var(--publish-page-width);
        margin-left: 60px;
        margin-top: calc(2*var(--spacing-xxl));
        margin-bottom: calc(2*var(--spacing-xxl));

        &--main-label {
          font-size: var(--text-size-l);
          line-height: var(--line-height-m);
          margin-bottom: var(--spacing-l);
          margin-top: unset;
        }

        &--sub-label {
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-secondary);
          margin-bottom: var(--spacing-xs);
          margin-top: unset;
        }

        .content--helper-text {
          display: inline-block;
          width: 48%;
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-tertiary);
        }

        .governance--helper-text {
          display: inline-block;
          width: 48%;
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-tertiary);
        }

        &--content, &--url, &--governance {
          margin-bottom: 36px;

          .url--header-container {
            margin-bottom: var(--spacing-l);
            .url--heading {
              display: inline;
            }

            .url--optional {
              font-size: var(--text-size-m);
              line-height: var(--line-height-m);
              color: var(--content-color-secondary);
            }
          }

          .documentation-config-bar-selector, .content--versions--dropdown {
            display: inline-block;
            width: 48%;
            border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
            box-sizing: border-box;
            border-radius: var(--border-radius-default);
            height: 32px;

            .select-dropdown-btn {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 32px;
              color: var(--content-color-primary);
              font-size: var(--text-size-m);
              line-height: var(--line-height-m);
              font-weight: var(--text-weight-regular);

              i > svg > path {
                fill: var(--content-color-secondary);
              }

              i > svg {
                color: var(--content-color-secondary);
              }
            }

            .dropdown {
              width: 100%;

              .btn-group-separated {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn-text {
                  border: none;
                }
              }
            }
          }

          .content {
            &--dropdown {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 40px;
            }

            &--versions {
              margin-bottom: var(--spacing-xl);
            }

            &--environment {
              margin-bottom: var(--spacing-zero);
            }
          }

          .governance {
            &--textarea {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }

          .loading-indicator-wrapper {
            display: flex;
            flex: 1;
            justify-content: center;
            margin-top: var(--spacing-xxl);
            position: static;
          }
        }

        &--styling {
          margin-bottom: var(--spacing-xxl);

          .styling {
            &--colors {
              margin-top: var(--spacing-l);

              &--code, &--highlight {
                margin-top: var(--spacing-l);
              }

              .color-picker {
                position: relative;
                padding-right: var(--input-height);

                &--input {
                  height: var(--input-height);
                  margin-right: var(--spacing-s);
                  text-transform: uppercase;
                }

                &--preview {
                  top: 0;
                  right: 0;
                  position: absolute;
                  width: 38px;
                  height: 38px;
                  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
                  border-radius: var(--border-radius-default);

                  &--invalid {
                    background: var(--background-color-primary) !important;
                    &:after {
                      content: '';
                      height: 52px;
                      border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
                      position: absolute;
                      transform: rotate(45deg);
                      left: 19px;
                      top: -7px;
                    }

                    &:before {
                      content: '';
                      height: 52px;
                      border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
                      position: absolute;
                      transform: rotate(-45deg);
                      left: 18px;
                      top: -7px;
                    }
                  }
                }

                &--warning {
                  color: var(--content-color-error);
                  font-size: var(--text-size-m);
                }

                .pm-form-control {
                  text-transform: uppercase;
                }
              }
            }
          }

          &--layout {
            margin-bottom: var(--spacing-l);

            >div:first-child {
              padding: var(--spacing-zero) !important;

              >ul:first-child {
                margin-top: var(--spacing-zero);
              }
            }

            .plugin__workspace-view-switcher__wrapper {
              // Spacings and sizes are according to designs.
              white-space: nowrap;
              height: var(--size-m);
              padding: 1px 2px;
              width: 196px;
              justify-content: space-around;
              display: block;

              .plugin__workspace-view-switcher__switch {
                padding: var(--spacing-xs);
                margin: 0.5px;
                height: 22px;
                width: 90px;

                .plugin__workspace-view-active-item {
                  top: 1px;
                  position: relative;
                }
              }

              .left {
                left: 2.5px;
              }

              .right {
                left: 99px;
              }

              .plugin__workspace-view-switcher__switch__item {
                display: inline-block;
                width: 90px;
                padding: var(--spacing-xs);
              }
            }
          }
        }

        &--ctas {
          display: flex;
          justify-content: space-between;
          // We've added a large amount of bottom padding because we want to make room for the
          // share to public workspace dropdown.
          padding-bottom: calc(7*var(--spacing-xxl));
          margin-top: var(--spacing-xxl);

          &--buttons {
            display: inline-flex;

            .publish-doc-cancel-button {
              height: var(--button-height);
              background-color: var(--background-color-tertiary);
              font-weight: var(--text-weight-medium);
              font-size: var(--text-size-m);
              margin-right: var(--spacing-s);
            }

            .publish-button {
              height: var(--button-height);
              font-weight: var(--text-weight-medium);
              font-size: var(--text-size-m);
              margin-right: var(--spacing-s);

              .loading-indicator-wrapper {
                position: static;
              }
            }
          }
        }

        .alert-wrapper {
          margin-top: var(--spacing-s);
        }
      }
    }

    .documentation-default-link {
      color: inherit;
      text-decoration: underline;
      &__text {
        border-bottom: none;
      }
      &:hover {
        color: inherit;
      }
    }
  }

  .documentation-publish-status-page-before-customisation {
    height:100%;
    width: 100%;
    display: flex;
    justify-content: center;

    .documentation-published-status {
      display: flex;
      flex-direction: column;
      max-width: var(--publish-page-max-width);
      width: var(--publish-page-width);
      margin-top: var(--spacing-xl);

      .documentation-published-breadcrumbs {
        a:hover {
          border-bottom: none;
        }
      }

      .documentation-published-status-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--buttons {
          display: inline-flex;
        }

        div {
          h1 {
            font-size: var(--text-size-xxxl);
            line-height: var(--line-height-xxxl);
            display: inline;
            margin-right: var(--spacing-s);
          }
        }

        .edit-settings-button {
          line-height: var(--text-size-m);
          margin-right: var(--spacing-s);
        }

        .unpublish-button {
          background-color: var(--base-color-error);
          line-height: var(--text-size-m);

          .loading-indicator-wrapper {
            position: static;
          }

          &--disabled {
            background-color: var(--red-40);
          }
        }

        .approve-button {
          line-height: var(--text-size-m);
          margin-right: var(--spacing-s);

          .loading-indicator-wrapper {
            position: static;
          }
        }
      }

      .documentation-published-status-body {
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);

        &--public-workspaces {
          margin-bottom: calc(3 * var(--spacing-xxxl));
        }

        &--token-banners {
          margin-top: var(--spacing-l);
        }

        &--label {
          margin-bottom: var(--spacing-s);
          font-size: var(--text-size-l);
          line-height: var(--line-height-m);
          letter-spacing: -0.12px;
          margin-top: var(--spacing-zero);
        }

        &--limit-reached-banner {
          margin-bottom: var(--spacing-xl);
          position: sticky;
          top: 0;
          z-index: 89;
        }

        &--private-collection-banner {
          margin-top: 24px;
        }

        &--note {
          margin-top: 24px;
        }

        &--content {
          margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
          display: flex;
          flex-direction: column;

          &--collection-name {
            margin: var(--spacing-zero);
            line-height: var(--line-height-l);
            word-break: break-word;
          }

          &--versions {
            color: var(--content-color-tertiary);
            margin: var(--spacing-zero);
            line-height: var(--line-height-l);
          }

          &--note-to-cm {
            color: var(--content-color-secondary);
            margin: var(--spacing-zero);
            line-height: var(--line-height-l);
            background-color: var(--background-color-tertiary);
            padding: 12px 10px;
          }
        }

        &--environment {
          margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
          display: flex;
          flex-direction: column;

          &--top {
            margin-top: var(--spacing-s);
            margin-bottom: var(--spacing-zero);
          }

          &--environment {
            margin-top: var(--spacing-zero);
          }
        }

        &--url {
          margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
          display: flex;
          flex-direction: column;

          a {
            color: var(--content-color-link);

            &:hover {
              cursor: pointer;
            }
          }
        }

        &--copy-to-clipboard {
          margin-left: var(--spacing-s);
        }

        &--styling {
          margin: var(--spacing-l) var(--spacing-zero);
          margin-top: var(--spacing-xxl);
        }

        &--collection-discovery {
          .documentation-published-status-body--heading {
            display: flex;
            justify-content: space-between;
            margin-bottom: var(--spacing-l);
          }

          &__section {
            margin-bottom: var(--spacing-xl);
            h4 {
              line-height: var(--line-height-m);
              margin-bottom: var(--spacing-s);
            }
          }
        }
      }
    }
  }

}

.select-dropdown-container {
  .dropdown-content--dropdown-menu, .dropdown-menu {
    max-width: calc(750px * 0.48);
    width: 28.8%;

    .select-dropdown {
      width: unset;
    }
  }
}

.documentation-dropdown {
  --publish-page-dropdown-max-height: calc(10 * var(--size-xs));

  &--publish-page {
    .documentation-dropdown-menu-container, .dropdown-menu  {
      max-height: var(--publish-page-dropdown-max-height) !important;
      max-width: calc(750px * 0.48);
      width: 28.8%;
      overflow: auto;

      .select-dropdown {
        width: unset;
      }
    }
  }

  &--empty {
    .select-dropdown-btn {
      color: var(--content-color-tertiary) !important;
    }
  }
}

.documentation-published-status-modal {
  &__remove-btn {
    background-color: var(--base-color-error);

    &:hover {
      background-color: var(--content-color-error);
    }
  }
}
