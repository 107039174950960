/* stylelint-disable postman-stylelint/color-no-hex-rgb, postman-stylelint/no-legacy-color */
.menu {
  position: absolute;
  background: var(--background-color-primar);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin-top: 8px;
  min-width: 300px;
  max-width: 420px;
  box-shadow: 0 0 10px -5px #000;
}

.menu-section {
  border-bottom: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
}

.menu-section:first-of-type {
  border-top: 0;
}

.menu-section:last-of-type {
  border-bottom: 0;
}

.menu-section + .menu-section {
  border-top: 0;
}

.menu-section__title {
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: 700;
}

.menu-section-title:empty {
  padding: 0;
}

.menu-section-title:empty + .menu-section {
  border-top: 0;
}

.option {
  min-height: 24px;
  padding: 8px 16px;
}

.option.focused,
.option:hover {
  background: var(--background-color-secondary);
  cursor: pointer;
}

.option:focus {
  border: 10px solid pink;
}

.option.disabled {
  opacity: 0.5;
  background: var(--background-color-secondary);
}

.option.disabled:hover {
  cursor: no-drop;
}

.Select {
  position: relative;
}

.Select,
.Select div,
.Select input,
.Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}

.Select-control {
  background: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--border-color-default);
  color: var(--content-color-primary);
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 32px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Select-control .Select-input:focus {
  outline: none;
}

.is-searchable.is-open > .Select-control {
  cursor: text;
}

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: var(--background-color-primary);
  border-color: #bbb #d4d4d4 #e1e1e1;
}

.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent var(--content-color-primary);
  border-width: 0 4px 4px;
}

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.is-focused:not(.is-open) > .Select-control {
  border-color: #f26b3a #f47d52 #f47d52;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1),
    0 0 5px -1px fade(#f26b3a, 50%);
}

.Select--single > .Select-control .Select-value,
.Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-input {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  max-width: calc(100% - 24px);
  overflow: hidden;
}

.Select-input > input {
  background: none transparent;
  border: 0;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  margin-left: 14px;
  padding: 6px 0 10px;
  -webkit-appearance: none;
}

.is-focused .Select-input > input {
  cursor: text;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  text-align: center;
}

.Select-loading,
.Select-loading-zone {
  position: relative;
  vertical-align: middle;
  width: 16px;
}

.Select-loading {
  -webkit-animation: Select-animation-spin 0.4s linear infinite;
  -o-animation: Select-animation-spin 0.4s infinite linear;
  animation: Select-animation-spin 0.4s linear infinite;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid var(--border-color-default);
  border-right-color: var(--content-color-primary);
  display: inline-block;
}

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 0.2s;
  -o-animation: Select-animation-fadeIn 0.2s;
  animation: Select-animation-fadeIn 0.2s;
  color: var(--content-color-primary);
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 50%;
  right: 15px;
  transform: translateY(50%);
  width: 15px;
  height: 15px;
  margin-bottom: 1px;
}

.Select-clear-zone:hover {
  color: #d0021b;
}

.Select-clear {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
}

.Select--multi .Select-clear-zone {
  width: 15px;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  padding-right: 4px;
}

.Select-arrow {
  border-color: var(--content-color-primary) transparent transparent;
  border-style: solid;
  border-width: 4px 4px 2px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #535353;
}

@-webkit-keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Select--single.pm-select {
  font-size: 12px;
  height: 32px;
  max-width: 320px;
}

.Select--single.pm-select .Select-value {
  pointer-events: none;
}

.Select--single.pm-select .Select-control {
  display: inline-block;
  overflow: visible;
  max-width: 100%;
  width: auto;
  cursor: pointer;
}

.Select--single.pm-select .Select-control:hover {
  box-shadow: none;
}

.Select--single.pm-select .Select-placeholder,
.Select--single.pm-select .Select-value {
  position: static;
  padding-right: 24px;
}

.Select--single.pm-select .Select-input {
  top: 0;
  left: 0;
  position: absolute;
}

.Select--single.pm-select .Select-arrow-zone {
  top: 50%;
  right: 0;
  padding: 0;
  position: absolute;
  display: inline-block;
  transform: translateY(-50%);
}

.Select--single.pm-select .Select-menu-outer {
  width: auto;
  margin-top: 4px;
  min-width: 120px;
  max-width: 300px;
  max-height: 400px;
  border-radius: var(--border-radius-default);
  font-size: 14px;
}

.Select--single.pm-select .Select-menu {
  max-height: 398px;
}

.Select--single.pm-select .Select-option {
  overflow: hidden;
  padding-left: 16px;
  padding-right: 32px;
  color: var(--content-color-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select--single.pm-select .Select-option:first-child {
  margin-top: 8px;
}

.Select--single.pm-select .Select-option:last-child {
  margin-bottom: 8px;
}

.Select--single.pm-select .Select-option:hover {
  background: var(--background-color-tertiary);
  color: var(--content-color-primary);
}

.Select--single.pm-select .Select-option:active {
  background: var(--background-color-secondary);
}

.Select--single.pm-select--align-right .Select-menu-outer {
  right: 0;
}

.Select--single.pm-select--loading .Select-arrow {
  display: none;
}

.Select--single.pm-select .Select-placeholder {
  color: #7e7e7e;
}

.Select--single.pm-select .Select-value {
  color: var(--content-color-primary);
}

.Select--single.pm-select-text {
  display: inline-block;
}

.Select--single.pm-select-text .Select-control {
  background: none;
  border: thin solid transparent;
}

.Select--single.pm-select-text .Select-arrow,
.Select--single.pm-select-text .Select-placeholder,
.Select--single.pm-select-text .Select-value {
  color: #f15a24;
  border-top-color: #f15a24;
}

.Select--single.pm-select-text:active .Select-arrow,
.Select--single.pm-select-text:active .Select-placeholder,
.Select--single.pm-select-text:active .Select-value,
.Select--single.pm-select-text:hover .Select-arrow,
.Select--single.pm-select-text:hover .Select-placeholder,
.Select--single.pm-select-text:hover .Select-value {
  color: #dd5524;
  border-top-color: #dd5524;
}

.Select--single.pm-select-text.is-open .Select-arrow {
  border-bottom-color: #f15a24;
}

.Select--single.pm-select-text.is-open:active .Select-arrow,
.Select--single.pm-select-text.is-open:hover .Select-arrow {
  border-bottom-color: #dd5524;
}

.Select--single.pm-select-text:not(.is-focused) .Select-control {
  border: thin solid transparent;
}

.Select--single.pm-select-text:not(.is-focused) .Select-control:hover {
  box-shadow: none;
}

.Select--single.pm-select-text--dark .Select-control {
  border: thin solid transparent;
}

.Select--single.pm-select-text--dark .Select-arrow,
.Select--single.pm-select-text--dark .Select-placeholder,
.Select--single.pm-select-text--dark .Select-value,
.Select--single.pm-select-text--dark:active .Select-arrow,
.Select--single.pm-select-text--dark:active .Select-placeholder,
.Select--single.pm-select-text--dark:active .Select-value,
.Select--single.pm-select-text--dark:hover .Select-arrow,
.Select--single.pm-select-text--dark:hover .Select-placeholder,
.Select--single.pm-select-text--dark:hover .Select-value {
  color: #fff;
  border-top-color: #fff;
}

.Select--single.pm-select-text--dark.is-open .Select-arrow,
.Select--single.pm-select-text--dark.is-open:active .Select-arrow,
.Select--single.pm-select-text--dark.is-open:hover .Select-arrow {
  border-bottom-color: #fff;
}

.Select--single .pm-select-loader {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.Select--single.pm-select-md {
  font-size: 14px;
}

.Select--single.pm-select-md,
.Select--single.pm-select-md .Select-control,
.Select--single.pm-select-md .Select-input {
  height: 40px;
}

.Select--single.pm-select-md .Select-input,
.Select--single.pm-select-md .Select-placeholder,
.Select--single.pm-select-md .Select-value {
  line-height: 40px;
}

.Select--single.pm-select-lg {
  font-size: 16px;
}

.Select--single.pm-select-lg,
.Select--single.pm-select-lg .Select-control,
.Select--single.pm-select-lg .Select-input {
  height: 48px;
}

.Select--single.pm-select-lg .Select-input,
.Select--single.pm-select-lg .Select-placeholder,
.Select--single.pm-select-lg .Select-value {
  line-height: 48px;
}

.Select--single.pm-select--block,
.Select--single.pm-select--block .Select-control {
  width: 100%;
  max-width: 100%;
}

.Select--single.pm-select--block .Select-menu-outer {
  width: 100%;
  max-width: none;
}

.Select--single.is-searchable .Select-control,
.Select--single.is-searchable .Select-multi-value-wrapper {
  display: block;
}

.react-16-dropdown {
  display: inline-block;
}

.react-16-dropdown .trigger-renderer {
  position: relative;
  padding: 8px;
}

.react-16-dropdown .trigger-renderer:focus {
  outline: none;
}

.react-16-dropdown.pm-dropdown--block,
.react-16-dropdown.pm-dropdown--block .trigger {
  display: block;
}

.react-16-dropdown.pm-dropdown--block .trigger-renderer {
  width: 100%;
  display: block;
}

.react-16-dropdown.pm-more-options-dropdown .pm-btn__default-icon svg {
  height: 14px;
  width: 14px;
}

.react-16-dropdown.pm-dropdown-md .trigger-renderer {
  font-size: 14px;
  line-height: 24px;
}

.react-16-dropdown.pm-dropdown-lg .trigger-renderer {
  font-size: 16px;
  line-height: 28px;
}

.react-16-dropdown-portal .option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-16-dropdown-portal .menu {
  padding: 8px 0;
  max-width: 300px;
  min-width: 120px;
  z-index: 100;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.react-16-dropdown-portal .menu:focus,
.react-16-dropdown-portal .menu > div:focus {
  outline: none;
}

.Select-menu-outer {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-top-color: var(--border-color-default);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Select-option:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.Select-option.is-selected {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}

.Select-option.is-focused {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}

.Select-option.is-disabled {
  color: #c1c1c1;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: #8e8e8e;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}

.Select--multi.has-value .Select-input {
  margin-left: 5px;
}

.Select--multi .Select-value {
  background-color: var(--background-color-primary);
  border-radius: 2px;
  border: 1px solid #c9e6f2;
  color: #08c;
  display: inline-block;
  font-size: 0.9em;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
}

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
}

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}

.Select--multi a.Select-value-label {
  color: #08c;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c9e6f2;
  padding: 1px 5px 3px;
}

.Select--multi .Select-value-icon:focus,
.Select--multi .Select-value-icon:hover {
  background-color: #ddeff7;
  color: #0077b3;
}

.Select--multi .Select-value-icon:active {
  background-color: #c9e6f2;
}

.Select--multi.is-disabled .Select-value {
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  color: #333;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon:active,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:hover {
  background-color: var(--background-color-primary);
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
