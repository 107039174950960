.documentation-overview-container {
  position: relative;
  overflow: hidden;
  max-height: 390px;
  background: var(--background-color-secondary);
  padding: var(--spacing-xl) var(--spacing-xxl);

  .collection-container {
    .documentation-core-item-name__text {
      margin-top: 0px;
      padding: var(--spacing-s);
    }
  }

  &.error-container {
    height: auto;

    .documentation-overview-container-content {
      display: flex;
      align-items: center;
    }
  }

  &.empty-container {
    height: auto;
    text-align: center;
  }

  &-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: flex-end;
    padding-bottom: var(--spacing-l);
    padding-right: var(--spacing-xxxl);
    height: 50%;
    display: flex;
    justify-content: right;
    background: linear-gradient(180deg, transparent 0%, var(--background-color-tertiary) 82.81%);
  }

  &-content {
    overflow: hidden;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .documentation-overview-container-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxl);

    .collection-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-l);
      word-break: break-word;
      flex: 1;

      .description-editor-container {
        height: 100%;

        .description-editor-wrapper {
          height: 100%;
        }
      }
    }

    .folder-container {
      flex-direction: column;
      gap: var(--spacing-l);
      word-break: break-word;
    }

    .request-container {
      flex-direction: column;
      gap: var(--spacing-l);
      word-break: break-word;
      position: relative;

      code[data-aether-id='aether-text-code'] {
        font-size: var(--text-size-m);
      }

      .aether-dropdown__control {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }

      .aether-dropdown__control--is-focused {
        border: none !important;
        box-shadow: none !important;
      }

    }
  }
}

.documentation-overview-header {
  &__collection-name-wrapper {
    padding: var(--spacing-m) var(--spacing-xs);
    cursor: pointer;
  }

  &__arrow-icon {
    display: flex;
  }
}
