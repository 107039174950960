/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$autocomplete-border-color: var(--border-color-default);
$autocomplete-label-color: var(--content-color-secondary);
$autocomplete-focused-background-color: var(--background-color-tertiary);

.autocomplete-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid $autocomplete-border-color;
  font-family: $font-primary;
  justify-content: space-between;

  // To ensure the variables with empty key are also getting proper height
  min-height: var(--text-size-l);

  &.autocomplete-item-focused {
    background-color: $autocomplete-focused-background-color;
  }

  .autocomplete-item-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .autocomplete-item-scope {
    display: inline-block;
    position: absolute;
    height: 17px;
    width: 17px;
    border-radius: 1px;
    text-align: center;
    line-height: 17px;
    color: var(--content-color-constant);
    font-weight:600;
    font-size: 12px;
  }

  .autocomplete-item-key {
    padding-left: 15px;
    color: var(--content-color-primary);
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    font-weight: 600;
  }
}

.autocomplete-dropdown-menu {
  border-right: 1px solid $autocomplete-border-color;
  position: relative;
  width: 150px;
  background: var(--background-color-primary);
  cursor: pointer;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 11px;
  max-height: 196px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-background-color);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-background-color);
  }
}

.autocomplete-menu-wrapper {
  position: absolute;
  margin-top: 20px;
  border-radius: var(--border-radius-default);
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.37);
  width: 386px;
  height: 196px;
  display: flex;
  font-family: $font-primary;
  z-index: 100;
  padding: var(--spacing-s);
  background-color: var(--background-color-primary);
  
  .autocomplete-meta-container {
    width: 220px;
    background: var(--background-color-primary);
    color: var(--content-color-primary);
    padding: var(--spacing-s);

    .override-label {
      background: var(--base-color-warning);
      border-radius: 2px;
      padding: 1px 2px;
      color: var(--content-color-constant);
      width: 62px;
      font-size: var(--text-size-xs);
      text-align: center;
    }

    .overriding-help-info {
      font-size: var(--text-size-xs);
      padding-top: var(--spacing-xs);
      border-top: 1px solid $autocomplete-border-color;
      color: $autocomplete-label-color;
    }

    .autocomplete-meta-item--override {
      text-decoration: line-through;
      max-height: 42px !important;
      -webkit-line-clamp: 3 !important;
      color: $autocomplete-label-color;
    }

    .autocomplete-meta-item {
      font-size: var(--text-size-xs);
      display: flex;
      margin-bottom: var(--spacing-s);
    }

    .autocomplete-meta-item--label {
      flex: 2;
      text-align: left;
      color: $autocomplete-label-color;
    }

    .autocomplete-meta-item--content {
      flex: 6;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 68px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      word-wrap: break-word;

      & input{
        height: var(--spacing-m);
        line-height: unset;
        text-overflow: ellipsis;
      }
    }
  }
}
