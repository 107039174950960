$team-member-item-height: 32px;
$team-member-left-spacing: 40px;
$user-icon-margin-top: 3px;

.user-label {
  &__info {
    position: relative;
    height: $team-member-item-height;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }
	
  &__name {
    position: absolute;
    left: $team-member-left-spacing;
    line-height: $team-member-item-height;
	  color: var(--content-color-primary);
  }

  &__email {
	  margin-left: var(--spacing-xs);
	  color: var(--content-color-secondary);
  }
}

.Select-value {
  .user-label__info {
	  margin-top: $user-icon-margin-top;
  }
}
