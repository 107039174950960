$network-mode-tabs-height: 49px;
$network-mode-sticky-top: 152px;

.documentation-split-container {
  display: flex;
  justify-content: space-between;
  height: calc(2 * var(--size-s));;
  align-items: center;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-zero) var(--spacing-l);

  &__left-deck {
    display: flex;

    & > * + * {
      margin-left: var(--spacing-l);
    }
  }

  &__right-deck {
    display: flex;

    & > * + * {
      margin-left: var(--spacing-l);
    }
  }

  .config-bar-button {
    height: var(--size-m);
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);

    &.published {
      color: var(--content-color-success);

      .dropdown-caret {
        svg > path {
          fill: var(--content-color-success);
        }
        svg {
          color: var(--content-color-success);
        }
      }
    }

    .config-bar-icon {
      margin-right: var(--spacing-xs);

      &.published-icon {
        svg {
          color: var(--content-color-success);
        }
      }
    }
  }

  .dropdown-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-content: stretch;

    label {
      color: var(--content-color-secondary);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
    }

    .btn-text {
      color: var(--content-color-primary);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
      @include icon-color(var(--content-color-primary));
    }

    .dropdown-caret {
      use {
        fill: var(--content-color-primary);
      }
      svg {
        color: var(--content-color-primary);
      }
    }

    .language-settings-icon {
      cursor: pointer;
    }
  }
}
