.key-value-form-editor-sortable{
  display: flex;
  flex: 1;
  flex-direction: column;

  .key-value-form-editor-sortable__selectable{
    display: flex;
    flex-direction: column;
  }
}
