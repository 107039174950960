.release-notes-editor {
  padding: 20px;
  background-color: var(--background-color-primary);
  flex: 1;
  overflow: auto;

  .markdown-base-content {
    max-width: 640px;
    margin: 0 auto;
    padding: 0;
  }

  // The important is added here to make sure that no higher
  // specificity from the base styles overrides the ones that are provided
  // here, and also it's currently overriding multiple styles with higher
  // specificity of the base styles.
  .markdown-editor-text {
    h1, h2, h3, h4, h5, h6 {
      text-transform: none !important;
      margin: 1em 0 0 0 !important;
    }

    h1 {
      margin-top: 0 !important;
    }

    img {
      margin: 1em 0 !important;
    }

    p {
      margin-bottom: 1em !important;
    }
  }
}
