.inline-input__wrapper {
  width: 95%;

  .input-box {
    border-color: var(--border-color-strong);
    border-radius: var(--border-radius-s);
    font-size: inherit;
    padding: 2px var(--spacing-m);
    height: auto;

    &.is-error {
      border-color: var(--base-color-error);
    }
  }
}

.inline-input__placeholder {
  word-break: break-all;
}
