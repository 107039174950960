.workspace-list-loading-state {
  height: 452px;
  width: 960px;
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
  display: flex;
  flex-direction: column;
  
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-l);
    margin-top: var(--spacing-xl);

    .left-content{
      display: flex;

      .search-input {
        background-color: var(--highlight-background-color-secondary);
        animation: fade 1s ease-in-out infinite;
        min-width: 228px;
        height: var(--size-m);
        border-radius: var(--border-radius-default);
        margin-right: var(--spacing-m);
      }
      .visibility-filter {
        background-color: var(--highlight-background-color-secondary);
        animation: fade 1s ease-in-out infinite;
        min-width: 88px;
        height: var(--size-m);
        border-radius: var(--border-radius-default);
        padding-right: var(--spacing-zero);
      }
    }

    .sort{
      background-color: var(--highlight-background-color-secondary);
      animation: fade 1s ease-in-out infinite;
      min-width: 136px;
      height: var(--size-m);
      border-radius: var(--border-radius-default);
      padding-right: var(--spacing-zero);
      margin-left: calc(8 * var(--spacing-xxxl));
    }
  }

  .list-item {
    display: flex;
    flex:1;
    padding: var(--spacing-l) var(--spacing-s);
    box-sizing: border-box;
    border-top: var(--border-width-default) solid var(--highlight-background-color-primary);

    .list-item-icon{
      height: var(--size-s);
      width:var(--size-s);
      background-color: var(--highlight-background-color-secondary);
      animation: fade 1s ease-in-out infinite;
      border-radius: var(--border-radius-default);
    }

    .list-item-info{
      display: flex;
      flex:1;   
      flex-direction: column;
      margin-left:  var(--spacing-m);

      .list-item-name{
        display: flex;
        flex:1;  
        background-color: var(--highlight-background-color-secondary);
        animation: fade 1s ease-in-out infinite;
        border-radius: var(--border-radius-default);
        margin-right:  calc(12 * var(--spacing-xxxl));
        margin-bottom: var(--spacing-m);
      }

      .list-item-summary{
        display: flex;
        flex:1;  
        background-color: var(--highlight-background-color-secondary);
        animation: fade 1s ease-in-out infinite;
        border-radius: var(--border-radius-default);
        margin-right: calc(13 * var(--spacing-xxxl));
      }

    }
  }
}



