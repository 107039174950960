.proxy-settings-content {
  padding: 20px;
  font-size: 12px;
  overflow: auto;
}

.proxy-settings-connection-wrapper{
  padding: 0px;
}

.proxy-settings-toggle-switch-container {
  padding: 8px 20px 0px 20px;
  font-size: 12px;
}

.proxy-capture-requests-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.proxy-settings-label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 16px;
}
.proxy-settings-save-filters {
  padding-bottom: 16px;
}
