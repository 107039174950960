.external-links-widget-container {
  .external-links {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0;

    .external-link {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-primary);
      display: flex;
      align-items: center;
      @include clickable;
      &__text {
        margin-left: var(--spacing-s);
      }
    }
    & > * + * {
      margin-top: var(--spacing-m);
    }
  }
}
