.invite-user-team-link {
  display: flex;
  flex-direction: column;

  .invite-user-share-title {
    color: var(--content-color-primary);
    font-weight: 600;
  }

  .invite-user-share-subtitle {
    color: var(--content-color-secondary);
  }

  .invite-user-actions {
    display: flex;
    flex-direction: row;

    // Fixing the button width so that loading state
    // doesn't cause the UI to move
    .btn-secondary {
      width: 116px;
    }
  }

  .invite-user-actions,
  .invite-user-share-subtitle {
    margin-top: 8px;
  }

  .invite-user-team-link-input-container {
    flex: 1;
    display: flex;
    position: relative;

    .btn-secondary {
      width: 84px;
    }

    .invite-user-team-link-input {
      margin-right: 8px;
      cursor: pointer;

      &.is-disabled {
        @include unselectable;
        color: var(--content-color-tertiary);
        cursor: not-allowed;
      }

      &.is-copied {
        border: 1px solid var(--base-color-success);
      }
    }

    .invite-user-team-link-action {
      position: absolute;
      right: 0;
      padding: 8px;
      cursor: pointer;

      &.is-disabled {
        cursor: not-allowed;
        svg {
          use {
            fill-opacity: 0.5;
          }
        }
      }

      &.success {
        @include icon-color(var(--base-color-success));
      }
    }

    .dropdown {
      background-color: var(--background-color-tertiary);
      height: 30px;
      width: 30px;
      margin-left: 8px;
      cursor: pointer;

      .dropdown-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
      }
    }
  }

  .invite-user-team-link--loading {
    cursor: not-allowed;
  }
}
