.schema-import-confirmation-modal {
  &__content {
    display: flex;
  
    &__icon {
      margin-top: 2px;
    }

    &__body {
      display: flex;
      margin-left: var(--spacing-s);
    }
  }

  &__footer {
    &__submit {
      margin-left: var(--spacing-s);
    }
  }
}
