$default-spacing: 8px;

.error-card-container {
  font-family: $font-primary;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--content-color-secondary);
  height: 100%;;

  .icon-button {
    background: var(--background-color-error);
  }

  .error-card-container__icon, .error-card-container__subtitle {
    margin-bottom: $default-spacing * 2;
  }
  .error-card-container__title {
    margin-bottom: $default-spacing;
  }

  .error-card-container__actions {
    display: flex;
    color: var(--base-color-brand);
    cursor: pointer;

    .error-card-container__actions-icon {
      margin-right: $default-spacing;
    }
  }
}
