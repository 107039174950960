.socketio-messages-documentation {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);

  &:first-child {
    margin-bottom: var(--spacing-l);
  }

  & > span {
    font-weight: var(--text-weight-bold);
  }

  &__event {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);

    &__snippet {
      display: flex;
      flex-direction: column;

      &__multi-arg {
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
        border-radius: var(--border-radius-default);
      }

      &__code {
        min-width: 0;
        border-radius: unset;
        border: unset;
        height: 100%;
        width: 100%;
      }
    }
  }
}
