.extensible-collection-editor-content {
  display: flex;
  height: 100%;
  flex-direction: column;

  // This is done so that the scroll shows up adjacent to container's border
  &__variables-editor {
    & > * {
      padding: var(--spacing-l) var(--spacing-l) var(--spacing-zero);
    }
  }
}
