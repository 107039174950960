.share-empty-shell {
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fade 1s ease-in-out infinite;
  overflow: hidden;

  .share-empty-shell--search {
    height: 42px;
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-tertiary);
    margin: 16px 0 8px 0;
  }

  .share-empty-shell--filter {
    height: 56px;
    border-radius: var(--border-radius-default);
    margin-top: 8px;

    .share-empty-shell--filter-title {
      width: 70px;
      height: 12px;
      background-color: var(--background-color-tertiary);
      border-radius: var(--border-radius-default);
    }

    .share-empty-shell--filter-type {
      width: 430px;
      height: 12px;
      background-color: var(--background-color-tertiary);
      border-radius: var(--border-radius-default);
      margin-top: 8px;
    }
  }

  .share-empty-shell--workspaces-title {
    height: 12px;
    width: 130px;
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-tertiary);
    margin-bottom: 8px;
  }

  .share-empty-shell--workspaces-item {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--background-color-tertiary);

    .share-empty-shell--workspaces-item--avatar {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: var(--background-color-tertiary);
    }

    .share-empty-shell--workspaces-item--details {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      .share-empty-shell--workspaces-item--details-title {
        height: 12px;
        width: 100px;
        border-radius: var(--border-radius-default);
        background-color: var(--background-color-tertiary);
      }

      .share-empty-shell--workspaces-item--details-members {
        height: 12px;
        width: 250px;
        border-radius: var(--border-radius-default);
        margin-top: 5px;
        background-color: var(--background-color-tertiary);
      }
    }
  }

  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
}
