.definition-create-container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  color: var(--content-color-primary);

  .definition-create-container-content {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-xxl) var(--spacing-xl);
    margin: var(--spacing-zero) auto;
    width: 50%;

    h6 {
      font-size: var(--text-size-m);
      margin-bottom: var(--spacing-m);
    }

    .definition-create-add-file__file-uploader {
      padding-bottom: var(--spacing-xs);
    }

    .definition-create-type {
      margin-top: var(--spacing-l);
    }

    .definition-create-format {
      margin-top: var(--spacing-l);
    }

    .definition-create-container-footer {
      margin-top: var(--spacing-xxl);
      display: flex;

      .definition-create-container-footer_create_button {
        margin-right: var(--spacing-l);
      }
    }
  }
}

.create-def-from-scratch-buttons {
  padding-top: 34px !important;
}

.create-definition-cta-footer {
  width: 100%;
  align-self: flex-end;
  padding-top: var(--spacing-l) !important;
  padding-bottom: var(--spacing-s);
}

.import-definition-radio-button {
  margin-top: 1px;
}

.import-definition-radio-button-container {
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: var(--spacing-xs);
  margin-left: var(--spacing-s);
}

.definition-author-scratch-heading-pad {
  padding-top: var(--spacing-xxxl) !important;
}