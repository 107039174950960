.invitation-error-modal {
  .invitation-error-modal__contents {
    display: flex;
    flex-direction: column;

    .invitation-error-modal__info {
      color: var(--content-color-primary);
    }
  
    .invitation-error-modal__help {
      color: var(--content-color-primary);
      margin-top: 16px;
    }
  }
}
