.pm-post-proxy-session-page {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: var(--spacing-l);
  height: 100%;
  width: 100%;
}

.pm-post-proxy-session-page-header {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l) !important;
  display: flex !important;
}

.pm-post-proxy-session-details {
  height: var(--size-m);
}

.pm-proxy-session-tabs {
  height: calc(100% - 40px);
  width: 100%;
}

.pm-proxy-overview-page-tabs{
  height: calc(100% - 36px);
}

.pm-proxy-overview-page-tab-panels{
  width: 100%;
  height: calc(100% - 66px);
}

.pm-proxy-overview-page-tab-panels_tab-panel {
  width: 100%;
  height: 100%;
}

.pm-post-sessions-requests-tab {
  width:100%;
  height:100%;
  .pane-group .pane-container .pane-resize-handle.ns {
    opacity: 0;
  }
}

.pm-proxy-overview-page-wrapper {
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: auto;
  padding-bottom: var(--spacing-l);
}

.pm-post-proxy-session-page {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: var(--spacing-l);
  height: 100%;
  width: 100%;
}

.pm-session-table-wrapper-overview-page {
  position: relative;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.pm-expanded-row-container {
  overflow-x: hidden;
}

.pm-overview-chart {
  margin-top: var(--spacing-l);
  width: 100%;
  height: 300px;

  canvas {
    height: 100% !important;
  }
}

.pm-overview-table-wrapper {
  margin-top: var(--spacing-l);
  height: fit-content;
}

.pm-overview-table {
  height: calc(100% - var(--spacing-l));
}

.overview-table{
  height: 100%;
}

.pm-post-session-proxy-badge {
  display: flex;
  align-items: center;
}

.pm-proxy-empty-state-container {
  margin: auto;
}

.pm-proxy-overview-tab-container{
  width: 50%;
  height: 100%;
}

.pm-proxy-interceptor-select-all-section {
  display: 'flex';
  align-items: 'center';
  background-color: var(--background-color-tertiary);
  height: var(--size-m);
}

.post-proxy-session-table tbody tr td {
  padding: 0 !important;
  vertical-align: middle !important;
  height: 24px;
}

.post-proxy-session-table thead th {
  height: 16px;
}

.post-proxy-session-table thead th:first-child > div {
  width: 13px;
  height: 13px;
}

.post-proxy-session-table tbody tr td [class^="Table__StyledCheckboxWrapper"] {
  margin: var(--spacing-zero) var(--spacing-s) !important;
}

.pm-proxy-expanded-view [class^="Tabs__StyledTabsWrapper"]  {
  padding: var(--spacing-zero) !important;
}

.pm-proxy-expand-button{
  padding: var(--spacing-zero) !important;
}

.proxy-table-selected-row {
  background: var(--background-color-secondary) !important;
}

.pm-post-proxy-side-pane {
  padding-left: var(--spacing-s);
}

.pm-post-proxy-expanded-view__pane {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l) var(--spacing-xs) var(--spacing-s) var(--spacing-l) !important;
  height: calc(100vh - 304px);
  overflow: scroll;
}

.pm-proxy-expanded-view__body-wrapper > div:first-child{
  width: fit-content;
}

.pm-proxy-expanded-view__body-wrapper {
  min-width: 300px;
  padding-top: 10px !important;
  padding-left: 4px !important;
}

.pm-proxy-side-pane-body-tab > div:first-child {
  padding: var(--spacing-zero) !important;
}

.pm-proxy-expanded-view {
  width: 100%;
  top: 10px;
  padding-bottom: var(--spacing-s);

  .pm-session-tab-header-table {
    padding: 10px 0;
    table-layout: fixed;
    .p {
      flex-wrap: wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
    }
  }

  &__details-wrapper {
    padding: 0 0 var(--spacing-l) var(--spacing-zero);

    &__show-hide-details {
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      color: var(--content-color-secondary);
      text-decoration: underline;
    }

    .btn {
      padding: 0;
    }
    .btn:hover{
      color: var(--content-color-secondary);
    }
  }
}
