.request-header-editor-container {
  .headers-editor {
    &__value-editor {
      margin-right: var(--spacing-l);
      margin-left: var(--spacing-l);
      margin-bottom: var(--spacing-m);
      .key-value-form-editor {
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }
  }
}
