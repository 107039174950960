.agent-onboarding-modal {
  position: relative;
  @include unselectable;
  &-header {
    text-align: center;
    color: var(--content-color-primary);
    margin-left: var(--spacing-xxl);
    margin-right: var(--spacing-xxl);
    border-bottom: var(--border-width-default) var(--border-style-solid)
      var(--border-color-default);

    h1 {
      margin-top: var(--spacing-xl);
    }

    &-subtitle {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
    }

    p {
      margin: var(--spacing-l) 0;
    }
  }

  &-close-button {
    @include clickable;
    position: absolute;
    right: 26px;
    top: 26px;
    width: 12px;
    height: 12px;
  }

  &-container {
    box-sizing: border-box;
    background: var(--background-color-primary);

    .modal-content {
      overflow-y: auto;
      max-height: 60vh;
      padding: var(--spacing-zero);
    }

    .modal-footer {
      padding: var(--spacing-xl);
    }

    .agent-onboarding-list-items {
      border-bottom: var(--border-width-default) var(--border-style-solid)
        var(--border-color-strong);
      margin-left: var(--spacing-xxl);
      margin-right: var(--spacing-xxl);

      .agent-onboarding-list-item {
        display: flex;
        margin: var(--spacing-xl) var(--spacing-xxl);

        &-icon-container {
          margin-right: 12px;
          margin-top: 2px;
        }

        &-content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          .title {
            font-weight: var(--text-weight-medium);
            font-size: var(--text-size-l);
            line-height: var(--line-height-m);
            margin-bottom: var(--spacing-s);
            color: var(--content-color-primary);

            &.done {
              color: var(--content-color-secondary);
            }
          }
          .subtitle {
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            color: var(--content-color-secondary);
            font-weight: 400;

            &-icon {
              vertical-align: middle;
            }
          }
          .custom-subtitle {
            .pm-icon {
              vertical-align: middle;
            }
            path {
              fill: var(--content-color-secondary);
            }
            svg {
              color: var(--content-color-secondary);
            }
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__action {
    &-cancel {
      width: 120px;
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
    }

    &-confirm {
      margin-left: var(--spacing-s);
      flex: 1;
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-s);
    }
  }

  &-download__action-confirm {
    font-weight: var(--text-weight-medium);
    padding: var(--spacing-s) var(--spacing-m);
    width: 147px;
    height: var(--controls-size-default);
    background-color: var(--base-color-brand);
  }
}
