.accordion {
  border-top: 1px solid var(--border-color-default);
  font-family: $font-primary;

  .accordion-item {
    margin-top: 16px;

    .title {
      background-color: var(--background-color-primary);
      color: var(--content-color-primary);
      cursor: pointer;
      font-size: 12px;
      user-select: none;
      height: 32px;
    }

    .panel {
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 12px;
      padding-bottom: 8px;
    }
  }
}
