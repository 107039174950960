.documentation-item-name {
  margin: var(--spacing-zero);
  display: flex;
  overflow: hidden;
  align-items: baseline;
  color: var(--content-color-primary);
  position: relative;

  // The parent is responsible for enforcing any width,
  // but the component should know not to step out of bounds.
  max-width: 100%;

  &--edit-mode {
    border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
    border-radius: var(--border-radius-default);
  }

  &__request {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: var(--spacing-zero) var(--spacing-s);

    &--edit-mode {
      background-color: var(--background-color-secondary);
    }
  }

  .request-method {
    text-transform: uppercase;

    &--edit-mode {
      background-color: var(--background-color-tertiary);
    }
  }

  &__title {
    overflow: hidden;
    overflow-wrap: break-word;
    flex: 1;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--content-color-primary);

    &--compact {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-wrap: normal;

      .documentation-item-name__text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .documentation-item-name__text {
      // The InlineInput component is using the older --content-primary token. For consistency, we have to
      // override it until the underlying component is changed.
      color: var(--content-color-primary);

      // Remaining styles set to match the styles of the input box within the InlineInput component. Avoid visual
      // jitter when switching between the two modes.
      padding: var(--spacing-zero) var(--spacing-s);
      box-sizing: border-box;
    }

    .inline-input__wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      & .input-box {
        border-color: transparent;
      }
    }
  }

  &:hover &__edit-icon {
    opacity: 1;
  }

  &__edit-icon {
    padding: var(--spacing-zero) var(--spacing-s);
    opacity: 0;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--background-color-secondary);
      border-radius: var(--border-radius-default);
      z-index: -1;
      opacity: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

h1 .inline-input__wrapper {
  height: calc(var(--line-height-xxxl) - 2px);
}

h2 .inline-input__wrapper {
  height: calc(var(--line-height-xxl) - 2px);
}

h3 .inline-input__wrapper {
  height: calc(var(--line-height-xl) - 2px);
}
