.form-content {
  display: flex;
  padding: 0;
  overflow: hidden;

  .create-new-monitor__error {
    --monitor-form-error-boundary: 150px;
    flex: 1;
    display: flex;
    .creation-error__icon {
      margin-top: 0px;
    }
    margin-top: var(--monitor-form-error-boundary);
  }

  .monitor-form__empty-state {
    --monitor-form-empty-state-boundary: 320px;
    margin-top: var(--monitor-form-empty-state-boundary);
  }

.create-new-monitor-form__content {
    --input-components-width: 320px;

    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .create-new-monitor-form__header {
      .tabs {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid var(--border-color-default);
        background-color: var(--background-color-primary);
        position: relative;
      }
    }

    .create-new-monitor-form__body {
      display: flex;
      flex-direction: column;
      flex: 1;

      .tabs {
        flex: 0 0 30px;
        justify-content: center;
        width: fit-content;
      }

      .create-new-monitor-form__right {
        overflow: hidden;

        .create-new-monitor-form__right__table {
          height: 100%;
          flex: 1;

          .create-new-monitor-form__data-editor {
            height: 100%;

            .data-editor {
              height: 100%;

              .data-editor {
                overflow-y: auto;
                height: 100%;
              }
            }
          }
        }
      }

      .create-new-monitor-form__footer__helptext {
        margin-top: 20px;
      }
    }

    .step1 {
      overflow: auto;

      .entity-empty__illustration-wrapper,
      .entity-empty__subtitle {
        margin-bottom: 10px;
      }

      &.create-new-monitor-form__body {
        padding: 20px;

        .tabs {
          border: 1px solid var(--border-color-default);
          margin-bottom: 20px;
          border-radius: var(--border-radius-default);

          .tab--collections {
            border-left: 1px solid var(--border-color-default);
            border-right: 1px solid var(--border-color-default);
          }
          .tab-primary {
            border-bottom: 0px;
            background-color: var(--background-color-tertiary);
            &.is-active {
              background-color: var(--background-color-primary);
              color: var(--content-color-brand);
              .tab-text-wrapper {
                box-shadow: none;
              }
            }
          }
        }

        .create-new-monitor-form__right__intro {
          font-size: 12px;
          line-height: 16px;
          color: var(--content-color-secondary);
          width: 100%;
          margin-bottom: 20px;

          .btn-text {
            line-height: 16px;
            padding: 0 3px;
          }
        }

        .create-new-monitor-form__right {
          flex: 1;

          .create-new-monitor-form__footer__helptext {
            color: var(--content-color-primary);
            margin-top: 20px;

            .btn-text {
              height: 0px;
            }
          }

          .create-new-monitor-form__data-editor {
            margin-right: 1px;
          }

          .data-editor {
            display: flex;
            flex-direction: column;

            .data-editor__header {
              margin-right: 12px;
              flex: 0 0 30px;
            }

            .data-editor__rows {
              overflow-y: scroll;

              &::-webkit-scrollbar-track {
                background-color: $app-background-color;
              }
            }

            .data-editor__header {
              .data-editor__header__cell {
                font-size: 11px;
                color: var(--content-color-primary);
                font-weight: 600;

                .data-editor__header__cell__title {
                  @include text-truncate-ellipsis;
                }
              }

              .create-new-monitor-form__test-header {
                overflow: hidden;

                .input-checkbox {
                  min-width: 16px;
                }

                span {
                  @include text-truncate-ellipsis;
                }
              }
            }

            .data-editor__row {
              .data-editor__reorder {
                width: 14px;
              }
              .dropdown {
                margin: 0 5px;
              }

              .dropdown-button {
                .btn {
                  background-color: transparent;
                  padding: 0;
                }
              }

              .data-editor__cell--time {
                display: flex;
                flex: 1;
                margin-right: 30px;

                .postfix {
                  font-size: 12px;
                  padding-left: 5px;
                  line-height: 25px;
                  color:var(--content-color-secondary);
                }
              }

              .data-editor__custom-row {
                &.is-focused,
                &:hover {
                  .data-editor__cell--time {
                    margin-right: 0;
                  }
                }
              }

              &:last-child {
                .data-editor__custom-row {
                  &.is-focused,
                  &:hover {
                    .data-editor__cell--time {
                      margin-right: 30px;
                    }
                  }
                }
              }
            }
          }

          .create-new-monitor-form__test-header {
            display: flex;

            .input-checkbox {
              margin-right: 5px;
            }
          }

          .create-new-monitor-form__right__table {
            flex: 1;
          }

          .create-new-monitor-form__card {
            display: flex;
            align-items: center;
            flex: 0 0 230px;
            height: 40px;
            padding: 10px;
            cursor: pointer;
            border-radius: var(--border-radius-default);

            @include hover {
              background-color: var(--highlight-background-color-primary);
            }

            .create-new-monitor-form__card__icon {
              margin-right: 10px;
              flex: 0 0 22px;
            }

            .create-new-monitor-form__card__title {
              display: flex;
              align-items: center;
              max-width: 190px;

              span {
                color: var(--content-color-primary);
                @include text-truncate-ellipsis;
                font-size: 14px;
              }
            }

            .create-new-monitor-form__card__subtitle {
              display: flex;
              align-items: center;
              color: var(--content-color-secondary);
              font-size: 12px;
              overflow: hidden;
              max-width: 190px; // Following the structure of card title
            }

            .create-new-monitor-form__card__requests {
              white-space: nowrap;
              margin-right: 8px;
            }
          }

          .create-new-monitor-form__right__collections,
          .create-new-monitor-form__right__team-library {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid var(--border-color-default);
            overflow: auto;
            margin-bottom: 20px;
            max-height: 35vh;
            padding: 10px;
          }

          .create-new-monitor-disabled {
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        .create-new-monitor-form-offline {
          cursor: not-allowed;
          color: var(--content-color-primary);
          opacity: 0.4;
          @include icon-color(var(--content-color-primary));
        }
      }
    }

    .step2 {
      &.create-new-monitor-form__body {
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow-y: auto;
        max-width: 900px;
        margin: 0 auto;

        .create-new-monitor__body__input-group {
          margin-bottom: 16px;

          .create-new-monitor__body__label {
            font-size: 12px;
            line-height: 36px;
            color: var(--content-color-primary);

            &.is-error {
              color: var(--base-color-error);
            }
          }

          .create-new-monitor__body__label--schedule-selector{
            margin-bottom: var(--spacing-xs);
          }

          .region-label {
            color: var(--content-color-primary);
            font-size: var(--text-size-m);
            margin-bottom: calc((-1) * var(--spacing-s));
          }

          .create-new-monitor__body__input {
            display: flex;

            .create-new-monitor__body__input__helptext {
              margin-top: 18px;
              padding-left: 20px;
              color: var(--content-color-tertiary);
            }

            .create-new-monitor__body__input__helptext--schedule-selector {
              margin-top: 0;
            }

            .create-new-monitor__body__field {
              &__dropdown{
                width: var(--input-components-width);

                &__collection-item-container-selected{
                  // bound the max width as there is a overflow bug in Aether's dropdown item component for large texts
                  max-width: 270px;
                }

                &__collection-item-container{
                  // bound the max width as there is a overflow bug in Aether's dropdown item component for large texts
                  max-width: 250px;
                }
              }

              display: flex;
              flex: 0 0 500px;
              margin-bottom: auto;
              height: 100%;
            }

            .input-checkbox {
              margin-right: 5px;
            }

            &.create-new-monitor__body__input--regions {
              margin-left: var(--spacing-xl);
            }
          }

          .create-new-monitor__body__input-error {
            color: var(--base-color-error);
            font-size: 11px;
          }

          .create-new-monitor__body__show-more {
            .advanced-section-toggle {
              margin-left: -10px;
            }

            .advanced-section {
              .advanced-section {
                margin: 10px 0;
                color: var(--content-color-primary);

                &__field-error {
                  color: var(--base-color-error);
                  font-size: 11px;
                  margin: 5px 25px;
                }

                .advanced-section__checkbox {
                  display: flex;
                }

                .advanced-section__field {
                  display: flex;
                  align-items: center;

                  &__input {
                    width: 300px;
                    margin: 5px 10px 5px 25px;
                  }

                  .advanced-section__field__meta {
                    margin: 5px 25px;
                    font-size: 11px;
                  }

                  &.advanced-section__field--notifications {
                    display: block;
                  }

                  &--notification-limit {
                    .monitor-notification-limit__input{
                      width: 75px;
                      margin: 0 8px;
                    }

                    .input-error-section {
                      top: 3px;
                      margin-top: 0px;
                      display: inline-block;
                    }
                  }

                  &.advanced-section__field--timeout,
                  &.advanced-section__field--delay {
                    .advanced-section__field__input {
                      width: 150px;
                    }

                    font-size: 11px;
                  }
                }

                &__select {
                  margin: 5px 10px 5px 25px;
                  display: flex;
                  align-items: center;

                  &--retry > * {
                    font-size: 11px;
                    margin-right: 10px;
                  }

                  &__dropdown--retry{
                    width: 75px;
                  }
                }

                .advanced-section__checkbox__label {
                  margin-left: var(--spacing-xs);
                }

                &__checkbox-label-helper {
                  font-size: var(--text-size-m);
                  line-height: var(--line-height-s);
                }
              }
            }
          }

          .create-new-monitor__body__select-group--regions {
            white-space: pre-line;
            margin-bottom: 8px;
            width: fit-content;
          }

          .create-new-monitor__body__select-group--regions-manual{
            @extend .create-new-monitor__body__select-group--regions;

            margin-bottom: unset;
            margin-top: calc((-1) * var(--spacing-s));
          }

          .create-new-monitor__body__select-group--regions__radio {
            margin-right: 5px;
          }

          .create-new-monitor__body__input--help {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            cursor: pointer;
          }

          .create-new-monitor__body__subtext {
            color: var(--content-color-secondary);
            font-size: 12px;
            line-height: 24px;

            .btn-text {
              padding: 0 3px;
            }
          }

          &.create-new-monitor__body__input-group--private {
            margin-top: 10px;
          }
        }



        .create-new-monitor__body__input-group--regions {
          .create-new-monitor__body__input {
            &.create-new-monitor__body__input-regions--auto {
              display: none;
            }

            &.create-new-monitor__body__input-regions--manual {
              display: flex;
            }

            .create-new-monitor__body__field {
              flex-wrap: wrap;
            }

            .create-new-monitor__body__input__checkbox {
              display: flex;
              width: 500px;
              color: var(--content-color-primary);
              margin-top: 8px;
            }

            .create-new-monitor__body__input__checkbox-icon {
              display: inline-block;
              padding-left: 4px;
              vertical-align: -2px;
              @include icon-color(var(--content-color-tertiary));
            }

            .create-new-monitor__body__input__checkbox-region {
              height: 18px;
            }
          }
        }

        .create-new-monitor__body__input-group--name {
          color: var(--content-color-primary);
        }
      }
    }

    .is-loading {
      justify-content: center;
    }

    .version-dropdown {
      width: 500px;

      &-button {
        @include text-truncate-ellipsis;
      }
    }

    &.enable_scrolling_tab {
      overflow: auto;
    }
}
  @media screen and (max-width: 1230px) {
    .create-new-monitor__body__input__helptext--regions {
      padding-top: var(--spacing-s);
    }
  }
}

.tab-disabled-input {
  @include text-truncate-ellipsis;
  background-color: var(--background-color-primary);
  &:hover{
    background-color: var(--background-color-primary);
  }
}

.tab-enable-scrolling {
  overflow: auto;
}

.create-new-monitor-form.tab-body {
  z-index: 0;
  border: none; // To remove border visible in high-contrast themes
}

.create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.tab-helpertext {
  margin-top: 0;
  margin-left: -32px;
}

.create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field.tab-form {
  width: 320px;
  flex: 0 0 320px;
}

.create-new-monitor__body__select-group--regions__radio.tab-radio-button {
  visibility: visible;
  -webkit-appearance: none;
  vertical-align: middle;
}

.create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox.tab-region-listing {
  width: 320px;
  font-size: var(--text-size-m);
}

.tab-footer {
  text-align: center;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.tab-button_cta {
  float: right;
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.tab-button_cta--primary {
  width: 280px;
  margin-left: var(--spacing-s);
}

.edit-monitor-loader {
  margin-top: 20%;
  text-align: center;
}

.monitor-schedule-selector-tab {
  width: 320px;
  box-sizing: border-box;

  >*{
    width: 100%;
    margin: 0 10px var(--spacing-s) 0;
  }

  >*:last-child {
    margin: 0;
  }
}

.create-new-monitor__body__input__helptext {
  .form-helper-link {
    color: var(--content-color-tertiary);
    @include icon-color(var(--content-color-tertiary));
    &:hover {
      color: var(--content-color-link);
      @include icon-color(var(--content-color-link));
    }
    .form-helper-link--text {
      font-weight: var(--text-weight-regular);
      text-decoration: underline;
    }

    .form-helper-link--icon {
      vertical-align: -2px;
    }
  }
}


.create-new-monitor__body__input__helptext--regions{
  @extend .create-new-monitor__body__input__helptext;

  padding-top: var(--spacing-xl);
}

.create-new-monitor__form__body__input__helptext--regions{
  @extend .create-new-monitor__body__input__helptext;

  padding-top: var(--spacing-xs);
}

.create-new-monitor__body__input__helptext__link {
  text-indent: 2px;
}

.create-monitor-collection-item {
  display: flex;
  max-width: 100%;
  align-items: center;

  &__name,
  &__qualifiers {
    flex: 0 1 auto;
  }

  &__name {
    @include text-truncate-ellipsis;

    margin-right: var(--spacing-s);
  }

  &__fork-label {
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-regular);

    svg > path[fill] {
      fill: currentColor !important;
    }

    svg {
      color: currentColor !important;
    }
  }
}

.monitor-form-help-text {
  flex: 1;
  box-sizing: border-box;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}

.modal-vertical {
  flex-direction: column;
}

.monitor-uptime-banner {
  height: 0;
  margin-left: 64px;
}

.monitor-file-upload {
  flex-direction: column;

  &__error {
    display: flex;
    color: var(--content-color-error);
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    margin-top: var(--spacing-xs);

    .error-icon {
      margin-right: var(--spacing-xs);
      margin-top: calc(var(--spacing-xs)/2);
    }
  }
}
