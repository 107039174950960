.ci-cd-configure-newman-container {
  display:flex;
  flex-direction:column;
  overflow-y:scroll;
  position: relative;
  height: 100%;

  .ci-cd-configure-newman-wrapper {
    justify-content: flex-start;

    .ci-cd-configure-newman-content-wrapper {
      margin-top: var(--spacing-l);
      display:flex;
      flex-direction: column;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 984px;

      .ci-cd-configure-newman-heading {
        display: flex;
      }

      .ci-cd-configure-newman-collections-heading {
        margin-right: var(--spacing-m);
        flex: 1;
       }

      .ci-cd-configure-newman-checkbox-heading {
        margin-right: var(--spacing-l);
      }

      .ci-cd-configure-newman-environments-heading {
        flex: 1;
      }

      .ci-cd-configure-newman-dropdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--spacing-s) var(--spacing-zero);

        .ci-cd-configure-newman-checkbox {
          margin-right: var(--spacing-l);
          height: var(--size-xs);
          width: var(--size-xs);
         }

         .ci-cd-configure-newman-collections {
          margin-right: var(--spacing-m);
          flex: 1;
         }

         .ci-cd-configure-newman-environments {
          flex: 1;
        }
        .ci-cd-configure-newman-remove-button {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-end;
          margin-left: var(--spacing-m);
        }
      }

      .ci-cd-configure-newman-button-stub{
        height: 32px;
        width: 44px;
      }

      .ci-cd-configure-newman-add-more-button {
        display: flex;
        align-items: center;
        padding-top: var(--spacing-s)
      }

      .ci-cd-configure-newman-service,
      .ci-cd-configure-newman-os {
        display: flex;
        flex: 1;
        position: relative;
        box-sizing: border-box;

        > div {
          flex: 1;
        }
      }

      .ci-cd-configure-newman-service{
        margin-right:var(--spacing-m);
      }

      .configure-newman-box-wrapper {

        .configure-newman-box {
          border-radius: var(--border-radius-default);

          .text-editor {
            overflow: scroll;
            min-height: fit-content;
          }
          .monaco-editor {
            min-height: 324px;

            .line-numbers {
              color: var(--content-color-tertiary);
            }
          }
        }
      }
    }
  }

}

.ci-cd-configure-newman-back-button {
  position: absolute;
  left: 4px;
  top: 4px;
}

.ci-cd-configure-newman-button-wrapper {
  margin-top: var(--spacing-xxl);
}


.ci-cd-configure-newman-api-creation-panel__content {
  button {
    flex-shrink: 0;
  }
}
