.status-bar-container {
  &.status-bar {
    display: flex;
    /* put status bar at the bottom of the pane in collapsed + uncollapsed states */
    position: absolute;
    bottom: 0;
    right: 0;
    height: var(--size-s);
  }

  .sb-section {
    display: flex;
    flex: 1;
  }
}
