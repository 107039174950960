$mycollection-collections-list-border-bottom-color: var(--border-color-default);
$mycollection-collection-label-color: var(--content-color-secondary);

.mycollections-collections-list-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  overflow-y: auto;

  .mycollections-collections-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .mycollections-collections-list-item-details {
      flex: 1;
      @include selectable;
    }
  }
}

.mycollections-collections-list-item {
  padding: 10px 0;
  color: $mycollection-collection-label-color;

  .mycollections-collections-list-item-head {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }

  .mycollections-collections-list-item-link,
  .mycollections-collections-list-item-last-updated {
    font-size: 10px;
    line-height: 20px;
  }

  .mycollections-collections-list-item-last-updated {
    opacity: 0.6;
  }
}




.mycollections-collections-list-item-actions {
  .btn-group {
    margin-left: 10px;
    border-radius: var(--border-radius-default);

    .btn-icon {
      background-color: transparent;
    }
  }
}

.mycollections-footer {
  border-top: 1px solid $mycollection-collections-list-border-bottom-color;
}

.mycollections-footer-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.mycollections-footer-description {
  flex: 1;
  font-size: 12px;
  color: $app-generic-text-color;
}

.mycollections-buttons-wrapper {
  display: flex;
  align-items: center;
  .mycollections-button {
    margin-left: 10px;
  }
}
