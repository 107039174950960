.workspace-activity-feed-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.workspace-activity-feed-name-header {
  padding: 20px 20px 30px;
  font-family: $font-primary;
  font-size: 16px;
  color: $workspace-activity-feed-font-color;
  background-color: $collection-browser-header-wrapper-background-color;
  border-bottom: 1px solid var(--border-color-strong);
  @include text-truncate-ellipsis;

  .workspace-activity-feed-name-header-content {
    display: inline;
  }
}

.workspace-activity-feed {
  padding: 0 176px;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  color: $workspace-activity-feed-font-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.workspace-activity-feed-loading-container {
  padding: 20px;
  text-align: center;
}

.workspace-activity-feed-loadmore-container {
  flex: 1;
  text-align: center;
}

.workspace-activity-item-loadfeed {
  height: 50px;
}

.workspace-activity-feed-date-group-wrapper {
  margin-top: var(--spacing-s);
}

.workspace-activity-feed-date-group {
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
}

.workspace-activity-feed-empty-container {
  padding: 30px 0;
  font-family: $font-primary;
  font-size: 12px;
  line-height: 18px;
  color: $collection-browser-meta-label-color;
  text-align: center;

  .workspace-activity-feed-empty-signin-link {
    color: $button--text-color;
    cursor: pointer;
  }
}

.workspace-activity-feed__end {
  color: var(--content-color-secondary  );
  text-align: center;
  margin-bottom: 48px;
  font-size: 12px;
}
