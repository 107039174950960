.interceptor-encryption-key-tooltip-wrapper {
  max-width: 310px;
}
.interceptor-encryption-key-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
  .info-button {
    display: inline-flex;
    margin-left: 5px;
  }
  .info-button-new__tooltip {
    display: inline-flex;
    margin-left: 5px;
  }
}

.encryption-key-mismatch-btn {
  @include active {
    background-color: var(--highlight-background-color-primary);
  }
  height: 24px;
  width: 24px;
}

.interceptor-encryption-info {
  display: inline-flex;
  margin-left: 5px;
}

.interceptor-encryption-key-warning {
  @include icon-color(var(--base-color-error));
}

.interceptor-secure-lock-icon {
  @include icon-color(var(--base-color-success));
}

.interceptor-encryption-key-tooltip {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  background-color: var(--background-color-secondary);
}

.interceptor-tooltip__text {
  margin-bottom: 16px;
}

.interceptor-encryption-indicator-icon {
  padding-right: 2px;
}

.interceptor-encryption-key-input {
  width: 100%;
  max-width: 280px;
}

.interceptor-secure-connection-label {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: 14px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
  .key-match {
    color:  var(--content-color-success);
  }
  .key-mismatch {
    color: var(--content-color-error);
  }
}

.interceptor-encryption-key-input-help {
  color: var(--content-color-secondary);
  padding-top: 4px;
  padding-bottom: 24px;
  .interceptor-key-validation-fail {
    color: var(--content-color-error);
  }
}

.interceptor-encryption-key-save-btn {
  padding: 0px;
}
