.history-sidebar {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  .history-sidebar-menu {
    display: flex;
    box-sizing: border-box;
    padding: var(--spacing-s);

    .requester-left-sidebar__actions-container {
      flex: 1;
      padding: var(--spacing-zero);
      height: var(--size-s);
    }
  }

  .multiple-request-selected {
    background-color: var(--background-color-brand);
  }

  .history-sidebar-list {
    flex: 1;
  }
}

.history-sidebar__header-actions-dropdown--menu {
  &-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-item .btn {
    padding: var(--spacing-zero);
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
  }
}

.history-sidebar-list__notification {
  display: flex;
  justify-content: center;
}

.history-sidebar-list-permission-error {
  .sidebar-empty-state__title {
    padding: 0;
  }
}

.history-sidebar-list-error {
  text-align: center;
  margin-top: var(--spacing-xl);

  h4 {
    color: var(--content-color-secondary);
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    margin-bottom: var(--spacing-s);
  }

  p {
    color: var(--content-color-tertiary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin: 0 auto var(--spacing-xl) auto;
    max-width: 256px;
    padding: 0 var(--spacing-l);
  }
}

.history-sidebar-list__more-on-top,
.history-sidebar-list__refresh {
  position: absolute;
  height: 20px;
  border-radius: 10px;
  padding: var(--spacing-xs) var(--spacing-s);
  margin-right: var(--spacing-m);
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  z-index: 10;
  font-weight: var(--text-weight-medium);
  cursor: pointer;
  display: flex;
  align-items: center;

  .pm-icon {
    margin-right: var(--spacing-xs);
  }
}

.history-sidebar-menu {
  display: flex;
  flex-direction: row;

  &__left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__right {
    flex: 0 0 auto;
    align-items: center;
    display: flex;

    .history-sidebar-menu__actions-delete-history-wrapper {
      display: flex;

      .history-sidebar-menu__actions-spinner-wrapper {
        display: inline-flex;
        height: var(--size-s);
        width: var(--size-s);
        background-color: transparent;
        justify-content: center;
      }

      .btn {
        height: var(--size-s);
        width: var(--size-s);
        background-color: transparent;
        @include icon-color(var(--content-color-secondary));

        &:hover {
          @include icon-color(var(--content-color-primary));
        }
      }
    }
  }
}

.history-sidebar-info-header {
  &__icon {
    margin-right: var(--spacing-s);
    margin-left: var(--spacing-m);
  }

  display: flex;
  align-items: center;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  font-size:  var(--text-size-m);
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
}

.history-sidebar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
