.invite-to-team-modal {
  &__content {
    margin-bottom: var(--spacing-l) !important;

    &-error {
      div {
        margin-top: var(--spacing-xs);
      }
    }

    .invite-file-upload{
      :hover {
        color: var(--content-color-primary);
      }

      cursor: pointer;
      height: 100%;
      font-size: var(--text-size-s);
    }
  }

  .ws-invite-link {
    padding: unset;
  }
}
