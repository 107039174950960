.environment-selector-container {
  display: flex;
  flex-direction: row;
  margin-right: 10px;

  .environment-selector-wrapper {

  &.without-browser-tabs-wrapper {
    .input-select-v2-wrapper {
      @media screen and (min-width: 769px) and (max-width: 1129px) {
        max-width: 210px;
      }
      @media screen and (max-width: 768px) {
        max-width: 160px;
      }
    }
  }

    width: 100%;
    .input-search-group__search-cancel-button {
      @include icon-color(var(--content-color-secondary));
    }

    @include hover {
      color: var(--content-color-primary);

      .input-search-group__search-cancel-button {
        @include hover {
          @include icon-color(var(--content-color-primary));
        }
      }
    }

    .input-select-v2-wrapper{
    	background-color: initial;
    	border: none;
      border-radius: 0;

      .input-search-group.is-focused, .input-search-group.is-hovered {

        & + .btn.dropdown-button {
          .dropdown-caret {
            @include icon-color(var(--content-color-primary));
          }
        }
        .input {
          color: var(--content-color-primary);
        }
      }
      @include hover {
        .input-search-group {
          &__input-wrapper input {
            color: var(--content-color-primary);

            @include hover {
              color: var(--content-color-primary);
            }
          }
        }

        color: var(--content-color-primary);

        .btn.dropdown-button {
          .dropdown-caret {
            @include icon-color(var(--content-color-primary));
          }
        }
      }

      .input{
        font-family: var(--text-family-default);
        font-weight: var(--text-weight-regular);
        font-size: var(--text-size-s);
        line-height: var(--line-height-xs);
        color: var(--content-color-secondary);
      }
      .user-selected-environment-input{
          font-weight: var(--text-weight-bold);
          color: var(--content-color-primary);
      }

      .user-selected-environment-input-loading {
        font-weight: var(--text-weight-lighter);
        color: var(--content-color-primary);
      }

      .dropdown-button:hover .input-search-group {
        .input-search {
          color: var(--content-color-primary);
        }
      }
    }
  }
}

.environment-selector-menu {
  .menu-item-header {
    // We are unsetting these values as the base menu item `src/renderer/js/components/base/Dropdowns.js~MenuItemHeader`
    // has incompatible design
    text-transform: unset;
    font-size: unset;
    display: flex;
    align-items: center;

    .parent-wrapper .parent-label {
      flex: initial;
      font-weight: bold;
      color: var(--content-color-secondary);
    }
  }

  .dropdown-menu-item {
    .item-wrapper .item-label {
      flex: initial;
      margin-left: var(--spacing-s);
    }

    .item-wrapper .item-label.is-forked {
      max-width: 100px;
    }

    &.is-focused:not(.is-selected) {
      background: var(--background-color-tertiary);
    }

    &.is-selected {
      background: var(--highlight-background-color-tertiary);
    }

    &,
    &.is-focused,
    &.is-selected {
      // this should override all other background colors
      @include hover {
        background: var(--highlight-background-color-secondary);
      }
    }
  }
}
