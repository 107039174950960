.delete-confirmation-modal-subscriber-message {
  padding-bottom: 10px;
}

.delete-confirmation-modal-delete-button {
  background-color: var(--base-color-error);
  margin-left: 10px;
  @include hover {
    background-color: var(--highlight-background-color-error);
  }
  @include active {
    background-color: var(--highlight-background-color-error);
  }

  @include focus {
    background-color: var(--highlight-background-color-error);
  }

  @include disabled {
    @include hover {
      background-color: var(--highlight-background-color-error);
    }
    @include active {
      background-color: var(--highlight-background-color-error);
    }

    @include focus {
      background-color: var(--highlight-background-color-error);
    }
  }
}

.delete-confirmation-modal {
  .loading-indicator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.leave-private-workspace-modal {
  &--message {
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
  }

  &--info {
    display: flex;
    padding: var(--spacing-l);
    margin-top: var(--spacing-l);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-info);

    .pm-icon {
      margin-right: var(--spacing-s);
      @include icon-color(var(--content-color-info));
    }
  }
}

.cannot-remove-from-workspace-modal-message {
  display: flex;
  align-items: center;
  padding: 4px;

  .help-text {
    padding-left: 16px;

    .btn-text {
      padding: 0;
      font-weight: 600;
      line-height: 16px;
    }

    .btn-text.delete-link {
      color: var(--base-color-error);
    }

    .btn-text.share-link {
      color: var(--base-color-brand);
    }
  }
}

.remove-from-workspace-modal-message {
  .helptext {
    padding-top: 20px;
  }
}

.delete-collection-text {
  position: relative;
  min-height: 44px; //size should not fluctuate due to unequal icon sizes

  &__loading-indicator.loading-indicator-wrapper {
    display: inline-block;
    margin-left: 8px;
    margin-top: 16px;
  }

  &-message {
    position: absolute;
    margin-left: 16px;
    top: 18px;

    &__loading {
      margin-left: 16px;
      color: var(--content-color-tertiary);
    }

    &__permanent-delete {
      color: var(--content-color-warning);
    }

    &__info {
      color: var(--content-color-tertiary);
    }
  }

  .delete-collection__status-icon {
    margin-top: 1px;
  }
}

.delete-confirmation-modal-controls {
  display: flex;
  flex: 0 0 80px;
  box-sizing: border-box;
  padding-top: 20px;
  flex-direction: row-reverse;
  align-items: center;

  .btn {
    &.is-focused {
      box-shadow: var(--shadow-focus);
    }
  }
}


.delete-confirmation-modal__warning-text {
  padding-top: var(--spacing-l);
}

.delete-monitor-modal {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l);
}
