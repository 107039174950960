@mixin text-wrap {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

@mixin font-style {
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.markdown-editor {
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  &__content-container {
    position: relative;
    min-height: 100%;
  }

  &__content {
    &--placeholder {
      opacity: 0;
    }

    & > pre {
      margin: var(--spacing-zero);
      width: 100%;
      font-family: inherit;
      @include text-wrap;
      @include font-style;
      color: var(--content-color-secondary);

      .bold {
        color: var(--content-color-primary);
      }

      .bold,
      .title {
        font-weight: var(--text-weight-medium);
      }

      .italic {
        font-style: italic;
      }

      .tag,
      .attr-value {
        color: var(--content-color-link);
      }

      .url {
        color: var(--content-color-link);
      }

      .tag {
        padding: var(--spacing-zero);
        max-width: none;
        overflow: visible;
        white-space: inherit;
      }

      .title {
        color: var(--content-color-primary);
      }

      .code.keyword {
        background-color: var(--highlight-background-color-primary);
        border-radius: var(--border-radius-s);
      }

      .code-block {
        font-weight: var(--text-weight-medium);
        color: var(--content-color-secondary);
      }
    }
  }

  &__textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    padding: var(--spacing-zero);
    margin: var(--spacing-zero);
    resize: none;
    /**
     * -webkit-text-fill-color is used instead of color because setting color to
     * transparent hides the text cursor, wherease text-fill-color doesn't
     */
    -webkit-text-fill-color: transparent;
    caret-color: var(--content-color-secondary);
    overflow: hidden;
    @include text-wrap;
    @include font-style;

    &--visible {
      -webkit-text-fill-color: var(--content-color-tertiary);
    }

    &:focus {
      outline: none;
    }
  }
}
