.socketIO-compose {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin-right: var(--spacing-s);

  &__multi-arg {
    padding-top: var(--spacing-l);

    &__editor {
      height: 100%;
      flex: 1;
      overflow: hidden;

      // If the editor container is shrinked, the text-editor was not getting resized
      // accordingly in Firefox. This fixes the resizing issue.
      min-height: 0;

      .text-editor .monaco-editor {
        .margin-view-overlays .line-numbers {
          color: var(--content-color-tertiary);
        }

        .monaco-placeholder {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-secondary);
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s) var(--spacing-zero);
    flex-wrap: wrap;
    margin-left: var(--spacing-l);
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    row-gap: var(--spacing-xs);

    &__left, &__right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__left {
      gap: var(--spacing-s) var(--spacing-s);
    }

    &__right {
      margin-left: var(--spacing-s);
      gap: var(--spacing-s) var(--spacing-m);
    }

    &__send-btn {
      background-color: var(--base-color-info);
      color: var(--content-color-constant);

      &.is-disabled {
        background-color: var(--base-color-info);
        color: var(--content-color-constant);
        opacity: 0.4;
      }

      &:not(.is-disabled){
        @include hover {
          background-color: var(--highlight-background-color-info);
        }

        @include active {
          background-color: var(--highlight-background-color-info);
        }
      }
    }
  }
}
