
$runner-summary-list-item-height: 32px;
$runner-summary-meta-min-width: 340px;
$runner-summary-list-item-padding-left: 16px;
$runner-summary-list-item-timeline-width: 50%;

.runner-summary {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include unselectable;

  &.is-hidden {
    display: none;
  }
}

.runner-summary__section-top {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  &__header {
    display: flex;
    justify-content: space-between;
    margin: var(--spacing-s) var(--spacing-xl) var(--spacing-zero) var(--spacing-l);
    height: var(--size-m);
    align-items: center;
  }

  &__label {
    white-space: pre; // prevent text from wrapping into newline
    color: var(--content-color-tertiary);
  }

  &__filter {
    flex: 0 1 200px;
  }
}

.runner-summary__section-bottom {
  min-height: 0;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
}

.runner-summary__header-iterations {
  // keep the iterations header at the top // for Safari
  position: sticky;
  top: 0;
  flex: 0 0 36px;
  background-color: var(--background-color-primary);
  z-index: 2;
  opacity: 0.95;

  display: flex;

  .runner-summary__meta--placeholder {
    flex: 1 0 $runner-summary-meta-min-width;
  }

  .runner-summary__header-iteration__tabs {
    display: flex;
    width: $runner-summary-list-item-timeline-width;
    border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .runner-summary__header-iteration__tab {
      display: flex;
      align-items: center;
      justify-content: center;

      .runner-summary__header-iteration__tab__count {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.runner-summary__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  &--empty {
    color: $runner-color--secondary;

    /* TODO: move it to Text component */
    font-size: var(--text-size-l);

    /* Make it centered */
    margin: var(--spacing-xl) auto;
  }
}

.runner-summary__body__timeline {
  display: flex;
  height: $runner-summary-list-item-height;

  &__keyframe-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__keyframe {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.runner-summary__header__back {
  margin-left: 10px;

  .runner-summary__back-icon {
    margin-right: 10px;
  }
}

.runner-summary__request-list-item {
  &__header {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;

    height: $runner-summary-list-item-height;
    box-sizing: border-box;
  }

  &__meta {
    display: flex;
    flex: 1 0 ($runner-summary-meta-min-width - $runner-summary-list-item-padding-left);
    align-items: center;
    line-height: var(--line-height-m);
    padding-left: $runner-summary-list-item-padding-left;
    @include text-truncate-ellipsis;
  }

  &__expand {
    @include clickable;

    &--placeholder {
      width: var(--size-xs);
    }
  }

  &__method {
    font-size: var(--text-size-xs);
    line-height: var(--line-height-s);
    font-weight: var(--text-weight-medium);
    padding: var(--spacing-zero) var(--spacing-s);
    color: var(--content-color-primary);
    @include clickable;

    &--GET {
      color: $request-method--GET-color
    }

    &--POST {
      color: $request-method--POST-color;
    }

    &--PATCH {
      color: $request-method--PATCH-color;
    }

    &--PUT {
      color: $request-method--PUT-color;
    }

    &--DELETE {
      color: $request-method--DELETE-color;
    }

    &--OPTIONS {
      color: $request-method--OPTIONS-color;
    }

    &--HEAD {
      color: $request-method--HEAD-color;
    }
  }

  &__name {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
    color: var(--content-color-primary);
    margin: var(--spacing-zero) var(--spacing-xs);
    max-width: 250px;

    @include text-truncate-ellipsis;
    @include selectable;
  }

  &__test-count {
    display: flex;
    white-space: pre;
    margin-left: auto;
    padding: var(--spacing-zero) var(--spacing-s);
    color: var(--content-color-tertiary);

    .test-count--passed {
      color: var(--base-color-success);
    }

    .test-count--failed {
      color: var(--base-color-error);
    }
  }
}

.runner-summary__test-list-item {
  $accent-horizontal-margins: 32px;
  $accent-width: 2px;

  display: flex;
  flex: 1;
  align-items: center;

  font-size: 12px;
  height: $runner-summary-list-item-height;
  box-sizing: border-box;
  color: $runner-color--primary;

  &::before {
    content: '';
    width: $accent-width;
    height: 100%;
    margin: var(--spacing-zero) $accent-horizontal-margins var(--spacing-zero) (
      $runner-summary-list-item-padding-left + $accent-horizontal-margins
    );
  }

  &.is-passed::before {
    background-color: var(--base-color-success);
  }

  &.is-skipped::before {
    background-color: var(--content-color-tertiary);
  }

  &.is-failed::before {
    background-color: var(--base-color-error);
  }

  &__meta {
    display: flex;
    flex: 1 0 (
      $runner-summary-meta-min-width -
      $runner-summary-list-item-padding-left -
      $accent-width -
      (2 * $accent-horizontal-margins)
    );
    align-items: center;
  }

  &__name {
    margin-left: 10px ;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include selectable;
  }
}


/* timeline stuff */

$runner-timeline-keyframe-width: 24px;
$runner-timeline-keyframe-height: 24px;

.runner-summary__request-list-item__timeline,
.runner-summary__test-list-item__timeline {
  overflow: auto;
  width: $runner-summary-list-item-timeline-width;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  &::-webkit-scrollbar {
    display: none;
  }

  // for non-webkit browsers
  .no-scrollbar {
    scrollbar-width: none;
  }
}

.runner-summary__body__timeline__keyframe {
  width: $runner-timeline-keyframe-width;
  height: $runner-timeline-keyframe-height;

  &.is-hovered {
    &:after {
      content: '';
      background-color: var(--runner-summary-vertical-bar-hover-background-color);
      width: $runner-timeline-keyframe-width;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.1;
      z-index: 1;
      pointer-events: none;
    }
  }

  &.is-green,
  &.is-red {
    .pm-icon {
      display: none;
    }
    &.is-hovered {
      .pm-icon {
        display: flex;
      }
    }
  }

  &.is-green {
    background-color: var(--background-color-success);
  }

  &.is-red {
    background-color: var(--base-color-error);
  }

  &.is-grey {
    background-color: var(--background-color-tertiary);
  }
}

.runner-summary__header-iteration__tabs {
  position: relative;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  // for non-webkit browsers
  scrollbar-width: none;
}

.runner-summary__header-iteration__tab {
  font-family: $font-primary;
  color: $runner-color--secondary;
  font-size: 13px;
  min-width: $runner-timeline-keyframe-width;
  position: relative;

  @include hover {
    &:after {
      content: '';
      background-color: var(--runner-summary-vertical-bar-hover-background-color);
      width: $runner-timeline-keyframe-width;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.1;
      z-index: 1;
      pointer-events: none;
    }

    .runner-summary__header-iteration__tab__count {
      background-color: $runner-summary-iterations--hover-background-color;
    }
  }

  .runner-summary__header-iteration__tab__count {
    z-index: 2;
    width: 20px;
    height: 20px;
    border-radius: var(--border-radius-default);
  }
}
