.sidebar-offline-state {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: var(--spacing-xxl) var(--spacing-l) var(--spacing-zero);
  align-items: center;

  &__title {
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-s);
  }

  &__body {
    color: var(--content-color-secondary);
    text-align: center;
  }

  &__illustration {
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: var(--spacing-l);
    background-color: var(--background-color-tertiary);

    &__icon {
      height: var(--size-m);
      width: var(--size-m);

      svg {
        height: var(--size-m);
        width: var(--size-m);
      }
    }
  }
}
