.monitor-info-context-view-wrapper {

  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .right-context-bar__title {
    color: var(--content-color-primary);
  }

  .monitor-info-context-view {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-zero) var(--spacing-s);
    overflow-y: auto;
    --base-height: 20px;
    --min-height: 2px;

    &__entity {
      margin-bottom: var(--spacing-l);

      &__label {
        font-size: var(--text-size-s);
        font-weight: var(--text-weight-medium);
        line-height: var(--line-height-s);
        color: var(--content-color-tertiary);
        margin-bottom: var(--min-height);
      }

      &__icon {
        margin-right: var(--spacing-s);
        margin-top: var(--min-height);
      }

      &__content {
        color: var(--content-color-primary);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        display: flex;
        flex: 1;
        padding-right: var(--spacing-xxl);
        align-items: center;

        .activity-item-profile-pic-wrapper {
          margin: 0;

          .pm-user-avatar-icon {
            height: var(--size-xs);
            width: var(--size-xs);
          }
        }

        .activity-item-user {
          margin-left: var(--spacing-s);
          font-weight: var(--text-weight-regular);
        }

        .monitor-info-btn {
          color: var(--content-color-primary);
          font-weight: var(--text-weight-regular);
          pointer-events: none;
        }

        .monitor-info-text-link {
          .create-monitor-collection-item__link {
            width: 100%;

            >.aether-link-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          padding: var(--spacing-s);
        }

        &__id {
          @include text-truncate-ellipsis;
          padding: 6px var(--spacing-s) 6px var(--spacing-s);
          background-color: var(--background-color-secondary);
          border-radius: var(--border-radius-default);
          width: 100%;
        }

        &__collection, &__environment {
          @include text-truncate-ellipsis;
        }

        &__collection, &__environment {
          margin-left: calc(var(--spacing-s) * -1);
        }

        &__environment-empty {
          color: var(--content-color-primary);
        }

        &__collection-empty {
          color: var(--content-color-primary);
        }

        &__toggle {
          background-color: transparent;
          height: var(--size-s);
          width: var(--size-s);
          margin-left: var(--spacing-m);

          &__copy {
            --spacing-medium: 8px;
            padding: var(--spacing-medium);
            border-radius: var(--border-radius-default);
            @include icon-color(var(--content-color-secondary));

            @include hover {
              @include icon-color(var(--content-color-primary));
              background-color: var(--background-color-tertiary);
            }
          }

          &__success {
            cursor: default;
            @include icon-color(var(--content-color-success));
          }
        }
      }

      &__content--collection {
        padding-right: var(--spacing-l);
      }

      &__content--environment {
        padding-right: var(--spacing-zero);
      }
    }

    .user-info {
      display: flex;
      align-items: center;

      &.is-clickable {
        cursor: pointer;
      }

      &__avatar {
        margin-top: var(--spacing-xs);
        margin-right: var(--spacing-s);
      }

      &__text {
        margin-top: var(--spacing-xs);
      }
    }
  }
}

.monitor-info-empty-state {
  visibility: hidden;
  max-height: 0;
}

.monitor-info-userinfo-offline {
  pointer-events: none;
}

.monitor-info-collection {
  display: flex;
  align-items: center;
  padding: var(--spacing-s);

  &__collection-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    >.aether-link-text{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__collection-name-offline {
    padding: 0;
  }

  &__collection-name-text {
    @include text-truncate-ellipsis;
  }

  &__qualifiers {
    flex: 0 1 auto;
    margin-left: 8px;
    text-overflow: ellipsis;
  }

  &__name {
    margin-right: var(--spacing-s);
  }

  &__fork-label {
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-regular);

    @include icon-color(var(--content-color-secondary));

    @include hover {
      @include icon-color(var(--content-color-primary));
    }
  }

  &__collection-version-tag {
    padding: 0px var(--spacing-s);
    border-radius: 30px;
    font-size: var(--text-size-s);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-secondary);
    background-color: var(--background-color-tertiary);
    line-height: var(--line-height-s);

    &__current {
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      background-color: var(--background-color-primary);
      color: var(--content-color-tertiary);
    }
  }
}
