.websocket-editor-header {
  border-bottom: 0;

  &__actions {
    display: flex;
    align-items: center;

    &__type-selector {
      &__btn {
        background-color: var(--background-color-info);
        border-radius: var(--border-radius-default);

        .btn {
          // @todo: Remove this once the `DropdownButton` base component
          // is updated to use Button from Aether
          height: var(--size-s);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          font-weight: var(--text-weight-regular);
          font-family: var(--text-family-default);
          color: var(--content-color-primary);
        }
      }

      .dropdown-caret {
        margin-left: var(--spacing-xs);
      }
    }

    // This is to override the default margin right set by .request-editor-save-button
    & .request-editor-save-button {
      margin-right: var(--spacing-zero);
    }

    & > *:not(:last-child) {
      margin-right: var(--spacing-s);
    }

    .request-editor-save-button--tertiary  .btn-group {
      .btn {
        min-width: var(--size-s);
        border-left: var(--button-group-separator);
        padding: var(--spacing-s) var(--spacing-xs);
        margin-left: -1px; // To prevent 2px thick separator in high-contrast themes
      }

      button {
        border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
      }
    }
  }
}
