/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.modify-permissions-container {
  &__content {
    height: 430px;
    padding-right: var(--spacing-zero);
    padding-top: var(--spacing-zero);

    &--extend {
      padding-right: var(--spacing-xl);
    }

    &__banner-container {
      padding-right: var(--spacing-xl);
    }
  }

  &__no-access-content {
    height: 500px;
  }

  .infobar {
    margin: -20px -20px 8px -20px;
  }

  &--infobar__info-icon {
    margin-right: 10px;
    @include icon-color(var(--base-color-brand));
  }

  .modify-permissions-container--infobar-icon {
    margin-right: 10px;
    @include icon-color(var(--base-color-error));

    &.is-offline {
      @include icon-color(var(--base-color-warning));
    }
  }
}

.modify-permissions--banner {
  display: flex;
  align-items: center;
  background: var(--background-color-success);
  margin-bottom: 16px;
  padding: 0px 16px;
  flex: 1 0 auto;

  &-success{
    svg{
      use{
        fill: var(--base-color-success);
      }
    }
  }

  &-text{
    margin: 10px 0px 10px 16px;
    line-height: 20px;
  }

  &-close{
    cursor: pointer;
    margin-left: auto;
  }
}

.modify-permissions-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.modify-permissions {
  display: flex;
  overflow: hidden;
  height: 100%;

  &.is-loading {
    align-items: center;
    justify-content: center;
  }

  .modify-permissions--editor {
    flex: 2;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .modify-permissions--editor__information {
      display: flex;
      flex-direction: row;
      margin: 12px 0px 12px 0px;
      align-items: flex-start;

      .modify-permissions--editor--information__workspace-name {
        font-weight: 600;
      }

      .modify-permissions--editor--information__text {
        padding-left: 8px;
      }

      .modify-permissions--editor--information__icon {
        padding-top: var(--spacing-xs);
      }

      .modify-permissions--editor--information__plan-link {
        padding-left: 4px;
      }
    }

    .modify-permissions--search {
      margin-right: 10px;
      margin-top: var(--spacing-s);

      &__input-container {
        padding-left: 2px;

        .user-suggestion {
          .avatar {
            margin-right: var(--spacing-s);
          }
        
          &__group-tag {
            width: var(--size-s);
            height: var(--size-s);
            padding: var(--spacing-zero) var(--spacing-xs);
            margin-right: var(--spacing-s);
            align-items: center;
            justify-content: center;
            align-self: center;
            background: var(--background-color-tertiary);
            border-radius: var(--border-radius-max);
          }
        
          &-details {
            height: 100%;
            width: 100%;
        
            &__name {
              max-width: 600px;
            }

            &__email {
              margin-top: var(--spacing-zero);
            }
          }
        }
      }

      .input-search-group {
        height: 40px;
        border-radius: var(--border-radius-default);
      }
    }

    .modify-permissions--roles-container {
      flex: 1;
      overflow-y: auto;
      padding-right: var(--spacing-s);

      &--full-width {
        padding-right: var(--spacing-xl);
      }

      .permission-list {
        margin-bottom: var(--spacing-zero);
      }

      &__infobar {
        .infobar-warn {
          background-color: rgba(242, 107, 58, 0.1);
        }

        .infobar__text-wrapper {
          p {
            a {
              font-weight: normal;
              color: var(--content-color-brand);
            }
          }
        }

        .infobar {
          margin: unset;
        }
      }
    }

    .modify-permissions--main {
      display: flex;
      align-items: center;

      .modify-permissions--main-title {
        flex: 1;
        margin-left: 8px;
      }

      .modify-permissions--main-roles {
        margin-right: 28px;

        .dropdown {
          width: 170px;
        }

        .dropdown-button {
          .btn {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
        }

        .modify-permissions--main-roles--name {
          @include text-truncate-ellipsis;
        }
      }

      &.is-bulk {
        margin-left: 0;

        .modify-permissions--main-title {
          margin-left: 0;
        }

        .modify-permissions--main-roles {
          margin-right: 4px;
        }
      }

      .modify-permissions--main-image {
        .team-logo--image {
          height: 24px;
          width: 24px;
        }
      }
    }

    .modify-permissions--workspaces__team-access {
      display: inline-flex;

      .pm-icon {
        padding-top: 2px;
      }

      .btn-text {
        padding: 0;
        margin-left: 4px;
      }

      .modify-permissions--workspaces__team-access-text {
        margin-left: 8px;
        color: var(--content-color-secondary);
      }
    }
  }

  .modify-permissions--separator {
    margin-right: var(--spacing-l);
    margin-left: var(--spacing-s);
    background-color: var(--border-color-strong);
    width: 1px;
  }

  .modify-permissions--workspaces {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .modify-permissions--workspaces__shared-in {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-right: var(--spacing-xl);

      .workspace-list-item-container {
        pointer-events: none;
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      .modify-permissions--workspaces__shared-in__is-loading {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .modify-permissions--workspaces__shared-in--title {
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 8px;
    }

    .modify-permissions--workspaces__shared-in--team {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .modify-permissions--workspaces__shared-in--team-text {
        color: var(--content-color-secondary);
      }

      .info-button__icon {
        margin-left: 8px;
      }
    }
  }
}
