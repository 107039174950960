.runner-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .runner-label-text {
    margin-left: var(--spacing-xs);
  }
}

@media only screen and (max-width: 480px) {
  .runner-label-text {
    display: none;
  }
}
