.folder-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);

  &__item {
    margin-right: var(--spacing-xs);

    @include icon-color(var(--content-color-tertiary));
  }
}
