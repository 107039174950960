.tab-conflict-confirmation-modal {

  .tab-conflict-confirmation-modal__content {
    padding-top: 15px;
  }

  .tab-conflict-confirmation-modal__header {
    display: flex;
  }

  .tab-conflict-confirmation-modal__icon {
    margin-left: -5px;
    margin-top: 5px;
    @include icon-color(var(--base-color-warning));
  }

  .tab-conflict-confirmation-modal__info__title {
    font-size: 14px;
  }

  .tab-conflict-confirmation-modal__info__desc {
    color: var(--content-color-secondary);
    padding-top: 5px;
  }

  .tab-conflict-confirmation-modal__info {
    margin-left: 10px;
    margin-top: 3px;
  }

  .tab-conflict-confirmation-modal__options {
    padding: 10px 0 0 0;
  }

  .tab-conflict-confirmation-modal__option {
    cursor: pointer;
    display: flex;
    padding: 10px 0;
  }

  .tab-conflict-confirmation-modal__option__info {
    display: flex;
    flex-direction: column;
    margin-top: -3px;
    margin-left: 10px;
  }

  .tab-conflict-confirmation-modal__option__desc {
    color: var(--content-color-secondary);
    font-size: 11px;
    padding-top: 3px;
  }

  .tab-conflict-confirmation-modal__footer {
    flex-direction: row;
    margin-top: -25px;
  }

  .tab-conflict-confirmation-modal__footer-wrapper {
    display: flex;
    flex: 1;
  }

  .tab-conflict-confirmation-modal__footer__left {
    flex: 1;

    & > .btn {
      @include focus {
        border: 1px solid $modal-button-focus-border-color;
      }
    }
  }

  .tab-conflict-confirmation-modal__dont-save-button {
    margin-right: 10px;
  }

  .tab-conflict-confirmation-modal__footer__right {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .tab-conflict-confirmation-modal__footer__right {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    & > .btn {
      margin-left: var(--spacing-m);
      min-width: 80px;

      @include focus {
        border: 1px solid $modal-button-focus-border-color;
      }

      &.btn-text {
        height: $button-height
      }
    }

    .btn-primary {
      min-width: 80px;
    }
  }
}
