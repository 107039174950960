.compound-input{
  &__label {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-bold);
    margin-bottom: var(--spacing-s);
    color: var(--content-color-primary);
  }

  &__field {
    input {
      height: 40px;

      &:active {
        border-color: var(--base-color-brand);
      }
    }
  }

  &__message {
      margin: var(--spacing-xs) var(--spacing-zero);
      display: inline-block;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
  }


  &__error {
    margin: var(--spacing-xs) var(--spacing-zero);
    color: var(--content-color-error);
    display: inline-block;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }
}
