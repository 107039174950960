.customize-template-modal {
  .modal-footer {
    border-top: 1px solid $create-new-card-border-color;

    .btn-secondary {
      margin-right: 10px;
    }
  }

  .customize-template {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 480px;
    margin: auto;

    .customize-template__error {
      .creation-error__icon {
        margin-top: 80px;
      }
    }

    .customize-template__cards {
      display: flex;
      flex-wrap: wrap;
    }

    .loading-indicator-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 20px;
    }

    .customize-template__description {
      width: 100%;
      font-size: 12px;
      color: $create-new-primary-color;
      margin-bottom: 15px;

      b {
        font-weight: 600;
      }
    }

    .customize-template__card {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      box-sizing: border-box;
      overflow: hidden;
      color: $create-new-primary-color;

      .customize-template__card__icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-width: 38px;
        min-height: 38px;
        margin-right: 15px;
      }

      .customize-template__card__text {
        align-self: flex-start;

        .customize-template__card__title {
          font-size: 14px;
          color: $create-new-primary-color;

          b {
            font-weight: 600;
            width: 250px;
            @include text-truncate-ellipsis;
          }
        }

        .customize-template__card__description {
          font-size: 12px;
          color: $create-new-disabled-color;
        }
      }
    }

    .customize-template__separator {
      border-top: 1px solid $create-new-card-border-color;
      position: relative;
      margin: 30px 0px 30px;
      font-size: 12px;
      font-weight: 600;
      color: $create-new-disabled-color;
      .customize-template__separator__text {
        position: absolute;
        background: var(--background-color-primary);
        top: -9px;
        padding-right: 5px;
      }
    }

    .customize-template__advanced {
      .btn-text {
        padding-left: 0;
        margin-bottom: 10px;
      }

      .customize-template__advanced__title {
        font-size: 12px;
        color: $create-new-primary-color;
        margin: 10px 0 20px 0;
      }

      .customize-template__options__option {
        margin-bottom: 20px;

        .customize-template__options__option__label {
          font-size: 12px;
          line-height: 20px;
          color: $create-new-disabled-color;
          margin-bottom: 5px;
        }

        .customize-template__options__option__value {
          margin-right: 10px;

          input {
            max-width: 480px;
          }
        }
      }
    }
  }
}
