
.integrations-browse-view-breadcrumbs {
  padding: 0 var(--spacing-s);
  margin-bottom: var(--spacing-l);
  &__team-name {
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    word-break: break-all;
    padding-right: var(--spacing-s);
    cursor: pointer;
  }

  &__divider {
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  &__integration-page {
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    padding: 0 var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    cursor: pointer;
  }
  &__text{
    color: var(--content-color-tertiary);
    font-weight: var(--text-weight-regular);
    padding-left: var(--spacing-s);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }
}


