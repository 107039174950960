.sb__drawer {
  position: relative;

  .dropdown {
    .dropdown-menu {
      direction: ltr;
      bottom: 25px;
      right: -20px;
      min-width: initial;
    }
  }
}