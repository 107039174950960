.validation-drawer {

  &__header-link:hover {
    text-decoration: underline;
  }

  &__header {
    display: flex;
    align-items: center;
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-regular);
    line-height: var(--line-height-s);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    background-color: var(--background-color-primary);
    height: 40px;
    box-sizing: border-box;
    color: var(--content-color-secondary);

    &__icon {
      margin-right: var(--spacing-s);
    }

    &__content {
      display: inline-flex;
      align-items: center;
      flex: 1;
      cursor: pointer;
      padding: var(--spacing-xs) var(--spacing-l);

      &__issue-info {
        flex: 1;
        display: flex;
        align-items: center;

        &__tab {
          display: flex;
          align-items: center;
          padding: var(--spacing-xs) var(--spacing-s);
          margin-left: var(--spacing-m);
          border-radius: var(--border-radius-default);
          border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
          color: var(--content-color-primary);

          &:hover {
            background-color: var(--background-color-tertiary);
          }
        }

        &__tab--selected {
          background-color: var(--highlight-background-color-primary);
          border: var(--border-width-default) var(--border-style-solid) var(--highlight-background-color-primary);
        }

        &__badge {
          pointer-events: none;
        }
      }
    }

    &__right-section {
      display: flex;

      &__expand-button, &__collapse-button {
        display: flex;
        align-items: center;
      }
    }

    &__validating, &__validated, &__empty, &__syntax-error {
      display: flex;
      align-items: center;
    }

    &__validating {
      .loading-indicator-wrapper {
        margin-right: var(--spacing-l);
        margin-left: var(--spacing-s);
        transform: scale(0.6);
      }
    }

    &__validated {
      .pm-icon {
        margin-right: var(--spacing-s);
      }

      @include icon-color(var(--content-color-success));
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    &__stale_warning {
      height: 40px;

      &_btn {
        height: var(--size-s);
      }
    }

    &_text {
      padding: var(--spacing-m);
    }

    &__empty, &__valid, &__loading, &__syntax-error {
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: var(--text-size-m);
      color: var(--content-color-primary);
    }

    &__run_button {
      color: var(--content-color-constant);
      height: var(--size-s);
      padding: 0 var(--spacing-m);
    }

    &__loading-text {
      padding-top: var(--spacing-m);
      color: var(--content-color-secondary);
    }

    &__empty {
      &__illustration {
        @include icon(
          '../assets/images/thumbnails/empty-schema.svg',
          120px,
          120px
        );
      }
    }

    &__syntax-error {
      &__illustration {
        @include icon(
          '../assets/images/empty-state/alert.svg',
          120px,
          120px
        );
      }
    }

    &__header {
      display: flex;
      flex: 1;
      padding: var(--spacing-s) var(--spacing-l);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);
      line-height: var(--line-height-s);
      align-items: center;
      color: var(--content-color-secondary);
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      max-height: var(--size-m);
      box-sizing: border-box;
      user-select: none;

      &__file {
        width: 120px;
      }

      &__line {
        width: 82px;
      }

      &__type {
        width: 116px;
      }

      &__description {
        flex: 1;
      }

      &__cell {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
      }
    }

    &__items {
      overflow-y: auto;
      user-select: none;
      height: 100%;
    }

    &__item {
      display: flex;
      flex: 1;
      padding: var(--spacing-s) var(--spacing-l);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      align-items: center;
      color: var(--content-color-primary);
      box-shadow: inset 0px -1px 0px var(--border-color-default);
      cursor: pointer;
      max-height: var(--size-m);
      box-sizing: border-box;

      &:hover {
        background-color: var(--background-color-secondary)
      }

      &:hover &__description__learn-more {
        display: flex;
      }

      &__file {
        width: 120px;
        @include text-truncate-ellipsis;
        padding-right: var(--spacing-s);
        box-sizing: border-box;
      }

      &__line {
        width: 82px;
        align-items: center;
        @include text-truncate-ellipsis;
        padding-right: var(--spacing-s);
        box-sizing: border-box;
      }

      &__type {
        width: 116px;
        display: flex;
        align-items: center;
        @include text-truncate-ellipsis;
        padding-right: var(--spacing-s);
        box-sizing: border-box;
      }

      &__description {
        flex: 1;
        display: flex;
        align-items: center;
        @include text-truncate-ellipsis;
      }
    }

    &__warning-button {
      color: var(--content-color-link);
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-m);
      padding: var(--spacing-s) var(--spacing-l);
      cursor: pointer;
    }
  }
}
