.create-documentation-entity-modal {
  .modal-content {
    line-height: var(--line-height-m);
    padding: var(--spacing-l);
    display: flex;

    .create-documentation-entity-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 0;

      .intro-content-wrapper {
        margin-bottom: var(--spacing-xl);

        .intro-subtitle_learn-more {
          color: var(--content-color-link);
          cursor: pointer;
        }
      }

      .collection-name-input-wrapper {
        margin-bottom: var(--spacing-xl);

        .collection-name-input-header {
          font-weight: var(--text-weight-medium);
          margin-bottom: var(--spacing-s);
        }

        .collection-name-input {
          width: 60%;
        }
      }
    }
  }

  .modal-footer {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .actions {
      display: flex;
      flex-basis: 55%;

      .btn {
        font-weight: var(--text-weight-medium);
        height: var(--size-m);

        &.btn-secondary {
          margin-right: var(--spacing-s);
        }

        &.btn-primary {
          flex: 1;
        }
      }
    }
  }
}
