/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.bg-act-action-cta {
  padding-right: var(--spacing-xs) !important;

  &.single-activity {
    width: var(--spacing-l);
    padding-right: var(--spacing-zero);
    margin-right: var(--spacing-s);
  }
}

.bg-activity-status-wrapper {
  z-index: 1;
  padding-left: var(--spacing-m);
}

.bg-activity-status-badge {
  cursor: pointer;

  &__icon {
    vertical-align: middle;
    margin-right: var(--spacing-xs);
  }
}

.bg-activity-status-badge-text {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 768px) {
  .bg-activity-status-badge-text {
    display: none;
  }
}

.bg-activty-content-flex {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bg-act-task-status-icon {
  padding-right: var(--spacing-m) !important;
  
  &.heading {
    padding-right: var(--spacing-xs) !important;
  }
}

.bg-act-task-status-link {
  width: var(--spacing-xl);
  height: var(--spacing-xl) !important;
  margin-right: 8px !important;
}

.bg-act-task-status-link-heading {
  width: var(--spacing-xl);
  height: var(--spacing-xl);
  margin-left: calc(-1 * var(--spacing-xs));
}

.bg-act-heading-retry-text {
  font-size: 11px;
}

.bg-act-content-flex {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
  margin-left: calc(-1 * var(--spacing-s));
  background-color: var(--background-color-secondary);
  padding-left: var(--spacing-s) !important;
}

.bg-act-section-heading {
  background-color: var(--background-color-primary);
  height: 52px;
  padding-left: var(--spacing-m) !important;
  margin-top: calc(-1 * var(--spacing-s));

  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-right: var(--spacing-s) !important;

  &.single-activity {
    margin-top: -6px;
    margin-bottom: -6px;
  }
}

.bg-act-section-content {
  background-color: var(--background-color-secondary);
  height: var(--spacing-xxl);
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
  padding-left: var(--spacing-m) !important;

  &.blink {
    background-color: var(--background-color-success);
    transition: background-color 0.5s linear;
  }

  &:hover {
    background-color: var(--highlight-background-color-primary);
  }
}

.bg-act-section-wrapper {
  cursor: pointer;
}

.bg-act-section-content-component-text {
  max-width: 170px !important;

  &.child-failed {
    max-width: 120px !important;
  }
}

.bg-act-section-content-component-tooltip {
  margin-left: var(--spacing-xs);
}

.bg-act-content-top-border {
  background-color: var(--border-color-default);
  margin-left: calc(-1 * var(--spacing-s));
  height: 1px;
  width: 360px;
}

.bg-act-content-top-header {
  height: var(--spacing-xs);
  background-color: var(--background-color-secondary);
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
}

.bg-act-content-footer {
  margin-bottom: calc(-1 * var(--spacing-s));
  border-bottom-right-radius: var(--spacing-xs);
  border-bottom-left-radius: var(--spacing-xs);
  height: var(--spacing-xs);
  background-color: var(--background-color-secondary);
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
}

@-webkit-keyframes badge-color-transition {
	0% {
		background-color: var(--content-color-secondary);
	}
	100% {
		background-color: var(--content-color-tertiary);
	}
}

@keyframes badge-color-transition {
	0% {
    background-color: var(--content-color-secondary);
	}
	100% {
		background-color: var(--content-color-tertiary);
	}
}

.bg-act-badge-counter {
  padding: var(--spacing-zero) 2.5px;
  background-color: var(--content-color-secondary);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  min-width: 7px;
  height: var(--spacing-m);

  &.error {
    background-color: var(--base-color-error);
  }

  &.blink {
    -webkit-animation-name: badge-color-transition;
    animation-name: badge-color-transition;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }

}

.bg-activity-popover {
  padding-bottom: 6px;
}