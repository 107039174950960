.mock-info-context-view-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mock-info-context-view-wrapper {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  .right-context-bar__title {
    color: var(--content-color-primary);
  }

  .mock-info-context-view {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: var(--spacing-zero) var(--spacing-s);

    &__error__wrapper {
      display: flex;
      flex: 1;
      overflow-y: auto;
    }

    &__error {
      display: flex;
      flex: 1;
      align-items: center;
      margin-top: var(--spacing-l);
      flex-direction: column;
      min-height: 300px;

      &__content {
        text-align: center;

        &__header {
          color: var(--content-color-primary);
          margin-top: var(--spacing-l);
          font-size: var(--text-size-l);
          font-weight: var(--text-weight-medium);
          line-height: var(--line-height-l);
        }

        &__sub-header {
          font-size: var(--text-size-m);
          color: var(--content-color-secondary);
          line-height: var(--line-height-m);
          margin: var(--spacing-l) var(--spacing-xl);
        }
      }

      &__retry-button {
        height: var(--size-m);
        width: 100px;
      }
    }

    &__entity {
      margin-bottom: var(--spacing-xl);

      &__label {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
        line-height: var(--line-height-s);
        color: var(--content-color-secondary);
        padding-top: var(--spacing-xs);
        padding-bottom: var(--spacing-xs);
      }

      &__content {
        color: var(--content-color-primary);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        display: flex;
        flex: 1;
        margin-right: var(--spacing-s);
        margin-top: var(--spacing-xs);
        align-items: center;

        &__button {
          background-color: transparent;
          height: var(--size-xs);
          width: var(--size-xs);
          margin-left: var(--spacing-m);

          &__copy {
            @include icon-color(var(--content-color-secondary));

            @include hover {
              @include icon-color(var(--content-color-primary));
            }
          }

          &__success {
            cursor: default;
            @include icon-color(var(--content-color-success));
          }
        }

        .activity-item-profile-pic-wrapper {
          margin: var(--spacing-zero);

          .pm-user-avatar-icon {
            height: var(--size-s);
            width: var(--size-s);
          }
        }

        .activity-item-user {
          margin-left: var(--spacing-s);
          font-weight: var(--text-weight-regular);
        }

        &__id, &__url, &__collection, &__environment {
          @include text-truncate-ellipsis;
        }

        &__id, &__url {
          line-height: var(--line-height-m);
          background-color: var(--background-color-secondary);
          padding: 6px var(--spacing-s);
          border-radius: var(--border-radius-default);
        }

        &__collection, &__environment {
          margin-right: var(--spacing-s);
          color: var(--content-color-primary);
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        &__collection {
          max-width: 50%;
        }

        &__collection-fork-label {
          max-width: 25%;
        }

        &__collection-version-tag {
          @include text-truncate-ellipsis;

          margin-left: var(--spacing-xs);
          padding: 0px var(--spacing-s);
          border-radius: 30px;
          font-size: var(--text-size-s);
          font-weight: var(--text-weight-medium);
          color: var(--content-color-secondary);
          background-color: var(--background-color-tertiary);
          line-height: var(--line-height-s);
          max-width: 25%;

          &__current {
            border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
            background-color: var(--background-color-primary);
            color: var(--content-color-tertiary);
          }
        }
      }
    }
  }
}
