.monitor-sidebar-tab-content {
  flex: 1;
  overflow-y: auto;
}

.monitor-listing {
  flex: 1;

  .sidebar-list-item__name-wrapper {
    @include text-truncate-ellipsis;
  }

  // this class is only applied to the indicator when the actions are hidden
  // the token var(--size-s) is the same as the width of the sidebar actions icon when shown
  // so having a margin when the dropdown actions is not show, enables us to prevent 'hover jerks'
  .monitor-list-item-health-status.actions-hidden {
    margin-right: 28px;

  // for no-tabs state we use Aether Button for menu, which has larger size than base button
  &.use-browser-tabs {
    margin-right: 36px;
  }
  }

  // To align the monitor icon just below the `+` icon in Sidebar container - this will ensure we align with other squads wrt indentation
  .sidebar-list-item {
    padding: var(--spacing-zero) var(--spacing-xs);
  }
  // To align with other squads for sidebar and fix additional indentation/padding - removing margin-left
  .sidebar-list-item__content-wrapper {
    margin-left: var(--spacing-zero);
  }
}
