$integration-icon-dimension: 120px;
$breakpoint-md: 768px !default;


.integration-service {
  &__title-available {
    margin-top: 0px;
    margin-bottom: var(--spacing-xs);
  }

  &__overview {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      width: 288px;
      margin-right: var(--spacing-xl);
    }

    hr {
      margin: var(--spacing-l) 0;
    }
  }

  &__detailed-view {
    margin-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
    min-height: 640px;

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
      padding-bottom: initial;
    }
  }

  &__details-wrapper {
    padding: var(--spacing-l) 0 var(--spacing-l) var(--spacing-zero);

    &__show-hide-details {
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      color: var(--content-color-secondary);
      text-decoration: underline;
    }
    .btn {
      padding: 0;
    }
    .btn:hover{
      color: var(--content-color-secondary);
    }
  }


  &__icon-wrapper {
    height: 32px;
    width: 32px;
    position: absolute;
    top: var(--spacing-l);
    left: var(--spacing-l);
    border: 1px solid var(--border-color-default);
    box-sizing: border-box;
    border-radius: var(--border-radius-s);
    margin-right: var(--spacing-l);

    @media (min-width: $breakpoint-md) {
      height: 120px;
      width: 120px;
      padding: var(--spacing-xl);
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    background: center / contain no-repeat;

    @media (min-width: $breakpoint-md) {
      width: 100%;
      height: auto;
     
    }
  }

  &__fallback-icon {
    @media (min-width: $breakpoint-md){
     @include icon('../assets/images/thumbnails/integration-stroke.svg',72px,72px);
    }
  }

  &__name {
    font-size: var(--text-size-xl);
    font-weight: var(--text-weight-medium);
    margin: var(--spacing-xl);
    margin-left: 40px;

    @media (min-width: $breakpoint-md) {
      margin: 0px;
    }
  }

  &__description {
    font-size: var(--text-size-m);
    color: var(--grey-60);
    margin: var(--spacing-xs) 0 0 40px;
    line-height: var(--line-height-s);

    @media (min-width: $breakpoint-md) {
      margin: var(--spacing-s) 0 var(--spacing-l);
    }
  }


  &__extended-description {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    font-weight: var(--text-weight-regular);
    color: var(--content-color-secondary);

    p a {
        color: var(--content-color-secondary);
      }
  }

  &__actions {
    margin: 0;
    padding: 0;

    .pm-btn {
      margin: 0 var(--spacing-s) var(--spacing-s) 0;
    }
  }


  &__metadata {
    $cell-height: 36px;
    $icon-width: 30px;

    position: relative;
    min-height: $cell-height;
    line-height: $cell-height;

    .pm-icon {
      top: $cell-height * 0.5;
      left: $icon-width * 0.5;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);

      // HACK: svgs need to be uniform and take the parents
      // dimensions as well as position
      svg {
        position: inherit;
        color: var(--grey-40);
      }

      path {
        fill: var(--grey-40);
      }
    }

    &--categories {
      ul {
        @include unstyled-list;
      }

      li {
        display: inline-block;
        margin-right: var(--spacing-s);

        .pm-badge {
          span {
            color: var(--grey-60);
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: none;

            font-weight: var(--text-weight-medium);
            font-style: normal;
            font-size: var(--text-size-xs);
            line-height: var(--line-height-xs);
          }
        }
      }
    }

    &--status {
      font-size: var(--text-size-s);
      color: var(--grey-80);
    }
  }

  &__types-list {
    @include unstyled-list;

    > li:first-child {
      padding-top: 20px;
    }
    > li:last-child {
      border-bottom: none;
    }
    li {
      width: 100%;
    }
  }

  &__types-item {

    &__status-summary {
      clear: both;
      padding-top: var(--spacing-l);

      &--link {
        text-decoration: none;
      }
    }

    &__configured {
      clear: both;
      padding-top: var(--spacing-m);
      color: var(--content-color-secondary);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      &-integrations-loading {
        display: flex;
        flex-direction: row;
        color: var(--content-color-tertiary);
        .loading-indicator-wrapper {
          margin-left: var(--spacing-m);
          padding-right: var(--spacing-m);
        }
      }

      .link {
        text-decoration: underline;
        cursor: pointer;
        color: var(--content-color-secondary);
      }
    }

    border-radius: var(--border-radius-s);
    border-bottom: var(--border-width-default) solid var(--border-color-default);
    padding: var(--spacing-l) 0;

    @media (min-width: $breakpoint-md) {
      padding: var(--spacing-l) 0;
    }
  }

  &__types-item-info {
    padding: 0;
  }

  &__types-item-actions {
    padding: 0;
    margin-top: var(--spacing-s);

    .btn-secondary {
      height: 32px;
      padding: 8px 12px;
    }
    .btn-secondary.is-disabled{
      opacity: 0.6;
    }

    @media (min-width: $breakpoint-md) {
      text-align: right;
      margin-top: 0;
    }
  }
  &__types-item-actionables {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-description {
      display: flex;
      flex-direction: column;
      flex-basis: 65%;
    }

  }
}

.integration-service__metadata .search-result-badge{
  margin-left: 0;
}

.integration-service__extended-description{
  a {
    text-decoration: underline;
  }
}

.integrations-service--badge {
  margin: var(--spacing-xl) 0 var(--spacing-s) var(--spacing-zero);
  height: var(--spacing-l);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  text-transform: capitalize;
  &-noteworthy {
    background-color: var(--background-color-warning);
    color: var(--content-color-warning);
    border-radius: 12px;
    padding: 0 var(--spacing-s);
  }
  &-new {
    background-color: var(--background-color-info);
    color: var(--content-color-info);
    border-radius: 12px;
    padding: 0 var(--spacing-s);
  }
}
