
.contextbar-offline-container {
  --custom-height: 280px;
  --custom-margin: 40px;
  width: auto;
  height: var(--custom-height);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 0 auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-right: var(--custom-margin);

  &__title {
    color: var(--content-color-secondary);
    padding: var(--spacing-xs);
    text-align: center;

    h4 {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      margin-bottom: 0;
      margin-top: var(--spacing-l);
    }
  }

  &__subtitle {
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-l);
    padding: var(--spacing-xs) var(--spacing-m);
    text-align: center;
  }
}

.icon-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
