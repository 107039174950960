/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.pm-proxy-session-container {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: var(--spacing-l);
  height: 100%;
  width: 100%;
}
.proxy-session-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-m) var(--spacing-l);
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-zero) !important;
}

.pm-proxy-session-configuration {
  padding: var(--spacing-m) var(--spacing-l) var(--spacing-s) var(--spacing-l);
  margin-bottom: var(--spacing-zero) !important;
}

.pm-live-proxy-side-pane {
  padding-left: var(--spacing-s);
}

.pm-live-proxy-expanded-view__pane {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l) var(--spacing-xs) var(--spacing-s) var(--spacing-l) !important;
  height: calc(100vh - 313px);
  overflow: scroll;
}

.pm-live-proxy-side-pane {
  padding-left: 8px;
}

.pm-live-proxy-expanded-view__pane {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l) var(--spacing-xs) var(--spacing-s) var(--spacing-l) !important;
  height: calc(100vh - 313px);
  overflow: scroll;
}

.pm-proxy-session-header-top {
  padding-top: var(--spacing-s) !important;
}

.apply-bottom-border {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding-bottom: var(--spacing-l) !important;
}

.pm-live-sessions-requests-tab {
  width:100%;
  height:100%;
  .pane-group .pane-container .pane-resize-handle.ns {
    opacity: 0;
  }
}

.pm-search-text-input {
  width: 20%;
}

.filter-button {
  padding-bottom: var(--spacing-s);
}

.pm-proxy-session-tabs {
  margin-top: calc(-1 * var(--spacing-s));
}

.proxy-session-name-input {
  min-width: var(--size-xs);
  margin-right: var(--spacing-xs);
  @include text-truncate-ellipsis;
  display: none;
}

.fix-bottom {
  display: flex;
  position: fixed;
  bottom: 10px;
  width: 100%;
  height: 64px;
}

.fix-right {
  display: flex;
  position: absolute;
  padding-top: --var(spacing-xs);
  right: var(--spacing-l);
}

.session-tab-footer-right-section {
  display: flex;
  padding-right: var(--spacing-xxxl);
  padding-top: var(--spacing-s);
}

.session-tab-new-footer-right-section {
  display: flex;
  padding-right: var(--spacing-xs);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
}

.proxy-session-table tbody tr td {
  padding: 0 !important;
  vertical-align: middle !important;
  height: 24px;
}

.proxy-session-table thead th {
  height: 16px;
}

.proxy-session-table thead th:first-child > div {
  width: 13px;
  height: 13px;
}

.proxy-session-table tbody tr td [class^="Table__StyledCheckboxWrapper"] {
  margin: var(--spacing-zero) var(--spacing-s) !important;
}

.session-tab-footer-right-section-time {
  padding-right: var(--spacing-s);
  font-family: 'IBMPlexMono';
  font-size: var(--text-size-m);
  line-height: 20px;
  letter-spacing: 0px;
  color: #6B6B6B;
}

.apply-border-right {
  border-right: 1px solid var(--border-color-default);
}

.session-tab-footer-right-section-size {
  padding-left: var(--spacing-s);
  margin-right: var(--spacing-l);
  font-family: 'IBMPlexMono';
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #6B6B6B;
}

.pm-proxy-session-footer {
  z-index: 100;
}

.pm-proxy-session-footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  z-index: 100;
  left: 0;
  padding-left: 20px;
  border-top: 1px solid var(--border-color-default);
}

.apply-light-background {
  background: #fff;
}

.apply-dark-background {
  background: #212121;
}

.pm-session-table-wrapper {
  position: fixed;
  bottom: 64px;
  top: 194px;
  left: 20px;
  right: 20px;
  z-index: 200;
}

.pm-table-url {
  width: 100%;
  padding-right: var(--spacing-xs);
}

.pm-proxy-configure-url-popover {
  padding-top: 6px;
  padding-left: var(--spacing-m);
  cursor: pointer;
}

.pm-active-session-tab-panels {
  height: calc(100% - 40px);
  width: 100%;

  .pm-active-session-tab-panels-tab-panel {
      position: relative;
      bottom: 0px;
      top: 0px;
      left: 0px;
      right: 0px;
    }
  }
