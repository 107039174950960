$button--send--font-size: 14px !default;

.request-editor-send-button {
  .send-request-split-button {
    height: 100%;
    width: 100px;
    background-color: transparent;
  }

  .aether-split-button__primary-btn,
  .aether-split-button__secondary-btn {
    height: 100%;
    background-color: var(--button-main-background-color);

    @include hover {
      background-color: var(--button-main-hover-background-color);
      box-shadow: 0 0 0 1px var(--button-main-outline-color) inset;
    }

    @include active {
      background-color: var(--button-main-active-background-color);
      box-shadow: 0 0 0 1px var(--button-main-outline-color) inset;
    }

    @include focus {
      z-index: 1;
    }
  }

  .aether-split-button__primary-btn {
    width: 70px;
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    color: var(--button-main-content-color);
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  }

  .aether-split-button__secondary-btn {
    width: var(--size-m);
    border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  }

  .aether-split-button__splitter {
    background-color: var(--button-main-background-color);
    border-left: var(--button-group-separator) !important;
    height: 100%;
  }

  &.is-sending, &.is-disabled, &.hide-download {
    .aether-split-button__primary-btn {
      flex: 100%;
      border-radius: var(--border-radius-default) !important;
    }

    .aether-split-button__secondary-btn,
    .aether-split-button__splitter {
      display: none;
    }
  }

  &.is-sending {
    .aether-split-button__primary-btn {
      color: var(--content-color-primary);
      background-color: var(--background-color-tertiary);
      font-weight: var(--text-weight-regular);

      @include hover {
        background-color: var(--highlight-background-color-tertiary);
      }
    }
  }

  &.is-loading,
  &.is-disabled {
    .aether-split-button__primary-btn {
      opacity: 0.5;
      background-color: var(--button-main-disabled-background-color);
      color: var(--button-main-content-color);

      @include hover {
        background-color: $button--main-background-color;
      }

      @include active {
        background-color: $button--main-background-color;
      }
    }
  }
}

.send-button-tooltip {
  &__container.tooltip {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);

    .tooltip-wrapper {
      padding: var(--spacing-l);
    }
  }

  &__header {
    color: var(--content-color-error);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
  }

  &__body {
    margin-top: var(--spacing-xs);
    max-width: 250px;
  }

  &__body-action {
    @include clickable;
    color: var(--content-color-brand);
  }
}
