.monitor-sidebar-menu{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--size-m);
  justify-content: space-between;
  padding: 0px 10px;
  border-bottom: 1px solid var(--border-color-default);
  margin-bottom: var(--spacing-s);
}
