.assign-mock-collection-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-s); 

  &__dropdown {
    width: 240px;
    margin-top: var(--spacing-s);
  }

  &__checkbox {
    margin-bottom: var(--spacing-xs);
    margin-top: var(--spacing-l);
  }

  &__helpertext {
    color: var(--content-color-secondary);
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-xxl);

    &__error-message {
      margin-top: var(--spacing-m);
      text-align: center;
  
      &__description {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
        color: var(--content-color-primary);
        margin-right: var(--spacing-xs);
      }
  
      &__link-with-icon {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
        margin-top: var(--spacing-m);
        color: var(--content-color-secondary);
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;
        cursor: pointer;
      }

      &__link {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
        color: var(--content-color-link);
        cursor: pointer;
      }
    }
  }
}