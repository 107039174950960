$requester-left-sidebar-z-index: 20;

.requester-left-sidebar-wrapper {
  flex: 0 0 auto;
  z-index: $requester-left-sidebar-z-index;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  &.is-overlaid {
    position: absolute;
    height: 100%;
  }

  &.is-hidden {
    display: none;
  }
}
.survey-modal-container-wrapper  {
  background-color: var(--global-navigation-background-color);

}
.requester-left-sidebar-container {
  display: flex;
  background-color: var(--global-navigation-background-color);
  z-index: $requester-left-sidebar-z-index;
  flex: 1;
  overflow: hidden;
}

.requester-left-sidebar {
  contain: strict;
  background-color: var(--global-navigation-background-color);
  z-index: $requester-left-sidebar-z-index;
  box-shadow: inset -1px 0px 0px var(--background-color-secondary);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
}

.requester-left-sidebar__search-container {
  flex: 0 0 40px;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}

.requester-left-sidebar__tabs-container {
  flex: 0 0 40px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .tab {
    font-size: 14px;
  }
}

.requester-left-sidebar__tab-content-wrapper {
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.requester-left-sidebar__tab-content {

  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &.is-hidden {
    display: none;
  }
}

.requester-left-sidebar-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  z-index: 100;
  cursor: ew-resize;
}

.requester-sidebar-horizontal-header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--global-navigation-background-color);

  &__active-workspace-name {
    display: flex;
    align-items: center;
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    margin-left: var(--spacing-m);
    height: 20px;

    i {
      margin-right: var(--spacing-s);
    }

    &:hover {
      color: var(--base-color-info);
      @include icon-color(var(--base-color-info));
      cursor: pointer;
    }
  }

  &__right-container {
    padding: var(--spacing-s);
  }

  .actions-container {
    display: flex;

    .import-collection-button,
    .create-new-button {
      color: var(--content-color-primary);
      background: var(--highlight-background-color-secondary);
    }

    .workspace-join-btn {
      min-width: 38px;
    }
  }

  .actions-container > * {
    height: var(--size-s);
    font-weight: 600;
    font-size: var(--text-size-s);
    line-height: var(--text-size-m);
    padding: 6px 8px;
    min-width: 0;
  }

  .actions-container > *:not(:last-child) {
    margin-right: var(--spacing-xs);
  }

}

.requester-left-sidebar__header-collapsed {

  height: 100%;
  position: relative;
  background-color: var(--global-navigation-background-color);
  min-width: 40px;

  .requester-left-sidebar__header-icons {
    padding: var(--spacing-s);
    cursor: pointer;
  }

  &.withLabels {
    width: 80px;
  }

  // Whole area is clickable and used to open sidebar overlay in overlay mode.
  &.sidebarOverlay {
    cursor: pointer;
  }
}

.requester-left-sidebar__header-uncollapsed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--border-color-default);
  min-width: 40px;
  background-color: var(--global-navigation-background-color);
  align-items: center;

  .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.withLabels {
    width: 80px;
  }
}

.requester-left-sidebar__header-uncollapsed-tab {
  width: 80px;
  height: 52px;
  padding: var(--spacing-zero) var(--spacing-xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--content-color-secondary);
  font-size: var(--text-size-xs);
  line-height: var(--text-size-xl);
  text-align: center;
  flex: none;
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-xs);
  border-radius: var(--border-radius-default);

  &.more-options-tab {
    margin: var(--spacing-xs) var(--spacing-zero);
  }

  @include icon-color(var(--content-color-secondary));

  &:hover:not(.more-options-tab) {
    position: relative;
    background: var(--highlight-background-color-primary);
    @include icon-color(var(--content-color-primary));
    color: var(--content-color-primary);
  }

  &.active {
    background: var(--highlight-background-color-tertiary);
    position: relative;
    box-shadow: var(--base-color-brand);
    @include icon-color(var(--content-color-primary));
    color: var(--content-color-primary);
  }

  &.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    background: var(--base-color-brand);
    border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;
    top: var(--spacing-xs);
    bottom: var(--spacing-xs);
  }

}

.requester-left-sidebar__header-collapsed-tab {
  width: 40px;
  height: 40px;
  padding: var(--spacing-s);
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  @include icon-color(var(--content-color-secondary));

  &.more-options-tab-without-labels {
    margin: var(--spacing-xs) auto var(--spacing-zero) auto;
    border-radius: var(--border-radius-default);
  }

  span {
    height: var(--size-xs);
  }

  & .tabs_more {
    min-width: 40px;
    height: 40px;
  }

  &:hover {
    position: relative;
    background: var(--highlight-background-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  &.active {
    background: var(--highlight-background-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  &.active::before {
    content: '';
    position: absolute;
    left: 0;
    height: 40px;
    width: 2px;
    background: var(--base-color-brand);
    top: 0;
  }

}

.requester-left-sidebar-toggle-tabs {
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: var(--spacing-xs);

  background-color: transparent;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-s);
  @include icon-color(var(--content-color-secondary));

  &:hover {
    @include icon-color(var(--content-color-primary));
    background-color: var(--highlight-background-color-tertiary);
  }

  &.is-disabled:hover {
    background: none ;
  }
}

.requester-left-sidebar__actions-container {
  display: flex;
  height: 40px;
  padding: var(--spacing-s);
  box-sizing: border-box;
  align-items: center;
  position: relative;

  .btn {
    background: none;
    height: var(--size-s);
    width: var(--size-s);
    border-radius: var(--border-radius-s);

    &.is-disabled:hover {
      background: none ;
    }

    &:hover {
      background-color: var(--highlight-background-color-secondary);
      @include icon-color(var(--content-color-primary));
    }
  }

  .create-new__btn {
    margin-right: var(--spacing-xs);
    width: var(--size-s);
  }

  .input-search-group {
    height: var(--size-s);
    flex: 1;
    border-radius: var(--border-radius-s);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    background-color: var(--global-navigation-background-color);
    @include icon-color(var(--content-color-secondary));

    &.is-hovered {
      background-color: var(--highlight-background-color-primary);
    }

    &.is-focused {
      background-color: var(--background-color-primary);
      border-color: var(--border-color-strong);
      @include icon-color(var(--content-color-primary));
    }

    &.is-hovered.is-focused {
      background-color: var(--background-color-primary);
      border-color: var(--border-color-strong);
      @include icon-color(var(--content-color-primary));
    }
  }

  .secondary-actions-container {
    display: flex;
    margin-left: var(--spacing-xs);
  }
}

.requester-left-sidebar__header-tab-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80px;
}

.requester-left-sidebar__loading-container {
  margin-top: 40px;
}

.sidebar {
  &.overlay-mode {
    + .pane-resize-handle {
      pointer-events: none;
    }
  }
}
