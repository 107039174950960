.response-body-image-viewer {
  flex: 1;
  position: relative;
  display: flex;
}

.response-body-image-viewer-image-container {
  flex: 1;
  box-sizing: border-box;
  border: none;
  min-width: 0;
}
