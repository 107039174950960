.textarea {
  width: 100%;
  background-color: var(--background-color-secondary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default); // default
  outline: none;
  padding: var(--spacing-s);
  box-sizing: border-box;
  color: var(--content-color-primary);
  vertical-align: bottom;
  resize: vertical;

  @include hover {
    background-color: var(--highlight-background-color-secondary);
    border-color: var(--border-color-strong);
  }

  @include focus {
    background-color: var(--background-color-primary);
  }

  &.textarea-warning {
    border: var(--border-width-default) var(--border-style-solid) var(--base-color-warning);
  }

  &.textarea-error {
    border: var(--border-width-default) var(--border-style-solid) var(--base-color-error);
  }
}

.textarea-warning-text {
  display: flex;

  font-size: var(--text-size-xs);
  color: var(--content-color-warning);
}

.textarea-error-text {
  display: flex;

  font-size: var(--text-size-xs);
  color: var(--content-color-error);
}
