.Popover {
  box-shadow: var(--shadow-default);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
  border: 1px var(--border-color-default) solid;
  border-radius: 8px;
  padding: 5px;
  margin: -5px;
  display: flex;

  .bubble-menu-icon {
      border: 1px var(--border-color-default) solid;
      border-radius: 5px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.disabled {
          pointer-events: none;
      }
  }

  .bubble-menu-icon:hover, .bubble-menu-icon.is-active {
      background-color: var(--background-color-tertiary);
      color: var(--content-color-tertiary);
      cursor: pointer;
  }

  .bubble-menu-icon + .bubble-menu-icon {
      margin-left: 6px;
  }
}
