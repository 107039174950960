.workspace-row {
  border-top: var(--border-width-default) solid var(--highlight-background-color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--highlight-background-color-primary);
  }

  &-details {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-self: stretch;
    align-items: center;
    padding: var(--spacing-l) var(--spacing-s);

    &__icon {
      width: var(--size-s);
      height: var(--size-s);
      background-color: var(--background-color-secondary);
      display: grid;
      place-content: center;
      border-radius: var(--border-radius-default);
    }

    &-meta {
      max-width: 736px;
    }
  }
}

.workspace-members-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 192px;
  padding: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  &-more {
    color: var(--content-color-tertiary);
  }
  &-item__name {
    @include text-truncate-ellipsis;
    max-width: 140px;
    display: block;
  }

  & > * + * {
    margin-top: var(--spacing-xs);
  }
}

.workspace-members {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-s);

  & > * + * {
    margin-left: -8px;
  }

  .workspace-member {
    border: var(--border-width-l) solid var(--background-color-primary);
    border-radius: 24px;
    &:hover {
      z-index: 5;
    }

    &:nth-child(2) {
      z-index: 1;
      &:hover {
        z-index: 5;
      }
    }

    &:nth-child(3) {
      z-index: 2;
      &:hover {
        z-index: 5;
      }
    }

    &-show-more {
      min-width: var(--size-s);
      height: var(--size-s);
      display: grid;
      place-content: center;
      background: var(--background-color-tertiary);
      color: var(--content-color-secondary);
      z-index: 3;
      &-text {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-s);
        line-height: var(--line-height-s);
      }
      &:hover {
        z-index: 5;
      }
    }
  }
}
