.info-pane {
  &__mock-servers {
    margin-top: var(--spacing-xl);

    &__loading {
      display: flex;
      justify-content: center;
      margin-top: var(--spacing-l);
    }

    &__header-wrapper {
      display: flex;
      color: var(--content-color-secondary);
      margin-bottom: var(--spacing-xs);

      &__label {
        line-height: var(--line-height-s);
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        padding-top: var(--spacing-xs);
        padding-bottom: var(--spacing-xs);
        cursor: default;
        flex: 1;
      }
      
      &__create-new-mock {
        padding-top: 6px;
        padding-bottom: 6px;
        height: var(--size-s);
      
        &__icon {
          padding-right: var(--spacing-xs);
        }
      }
    }

    &__show-all-mocks-label {
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
      line-height: var(--line-height-m);
      padding-top: 2px;
      padding-bottom: 2px;
  
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &__toggle-wrapper {
      float: left;
      cursor: pointer;
    }

    &__toggle {
      margin-left: var(--spacing-zero);
      margin-top: var(--spacing-s);
      margin-right: var(--spacing-s);
      border-radius: var(--border-radius-s);

      :hover {
        background: var(--highlight-background-color-secondary);
      }
    }

    &__mock-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-xs);

      &__link {
        display: flex;
        overflow: hidden;

        &__name {
          @include text-truncate-ellipsis;
          line-height: var(--line-height-s);
          font-size: var(--text-size-m);
          color: var(--content-color-primary);
          padding-top: 2px;
          padding-bottom: 2px;
  
          .aether-link-text {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__active {
        display: flex;
        align-items: center;
      }
    }

    &__empty-state {
      margin-top: 6px;
      margin-bottom: 6px;
      line-height: var(--line-height-s);
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
    }

    &__error-state {
      margin-top: 6px;
      margin-bottom: 6px;
      line-height: var(--line-height-s);
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
    }

    &__create-new {
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-m);
      margin-top: 6px;
      margin-bottom: 6px;
      padding: var(--spacing-zero);

      &__icon {
        padding-right: var(--spacing-s);
      }
    }
  }
}
