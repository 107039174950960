.api-entity-dropdown {

  &.api-performance-dropdown {
    margin: var(--spacing-l) var(--spacing-zero);
  }

  .api-entity-dropdown-trigger {

    .api-entity-dropdown-icon-wrapper {
      padding-left: var(--spacing-xs);
      padding-right: var(--spacing-xs);
    }

    .monitoring-illustrator-add-cta {
      display: flex;
      padding: var(--spacing-l);
      min-width: max-content;

      .monitoring-illustrator-add-cta-text {
        color: var(--content-color-primary);
        font-weight: var(--text-weight-regular);
        font-size: var(--text-size-m);
      }
    }
  }
  .monitoring-illustrator {
    align-items: center;
    color: var(--content-color-primary);
    width: 100%;

    .monitoring-dashboard-links {
      margin-left: auto;
    }
    .monitoring-illustrator-description {
      margin-top: var(--spacing-m);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      text-align: center;
      color: var(--content-color-secondary);
    }
    .monitoring-table-title-wrapper {
      display: flex;
      margin-left: 18px;
      align-items: center;
      .monitoring-table-title {
        height: 20px;
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        color: var(--content-color-primary);
      }
    }
  }
}

.monitoring-loading-indicator {
  position: absolute;
  top: 50%;
  left:50%;
}

.monitoring-services-loading-indicator {
  position: relative;
  top: 50%;
  left:50%;
}

.table-loading-indicator {
  position: relative;
  margin-top: var(--spacing-xxl);
  left: 50%;
}



.deployments-table-title {
  margin-top: var(--spacing-xxl);
}

.api-monitoring-stats-empty-state {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-l);
  margin-top: 22px;
}

.monitoring-services-table {
  margin: var(--spacing-xs) var(--spacing-xl) var(--spacing-l);

  &.monitoring-services-table--wo_bleed {
    margin-left: var(--spacing-zero);
    margin-right: var(--spacing-zero);
  }
  .monitoring-services-table-success {
   .monitoring-services-table-header {
     position: static;
     display: flex;
     height: var(--size-m);
     width: 100%;
     border-bottom: var(--border-style-solid) var(--border-width-default) var(--border-color-default);
     .monitoring-services-table-header-cell-empty {
        width: var(--size-m);
      }
      .monitoring-services-table-header-cell-services {
        width: 30%;
        float: left;
        padding: var(--spacing-s) var(--spacing-zero);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }

      .monitoring-services-table-header-cell-stats {
        width: 33%;
        float: left;
        padding: var(--spacing-s);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }
      .monitoring-services-table-header-cell-opened-at {
        width: 15%;
        float: left;
        padding: var(--spacing-s);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }
      .monitoring-services-table-header-cell-closed-at {
        width: 15%;
        float: left;
        padding: var(--spacing-s);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }
      .monitoring-services-table-header-cell-event-name {
        width: 50%;
        float: left;
        padding: var(--spacing-s);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }
      .monitoring-services-table-header-cell-severity {
        width: 15%;
        float: left;
        padding: var(--spacing-s);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }
      .monitoring-services-table-header-latency {
        float: left;
        width: 15%;
        padding: var(--spacing-s) var(--spacing-zero);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }

      .monitoring-services-table-header-cell-error-rate {
        float: left;
        width: 15%;
        padding: var(--spacing-s) var(--spacing-zero);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }

      .monitoring-services-table-header-cell-appdex {
        float: left;
        width: 15%;
        padding: var(--spacing-s) var(--spacing-zero);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }
      .monitoring-services-table-header-cell-status {
        float: left;
        width: 15%;
        min-width: 115px;
        padding: var(--spacing-s) var(--spacing-zero);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
      }

    }
    .monitoring-services-table-row-wrapper {
      .monitoring-services-table-row {
        display: flex;
        position: static;
        align-items: center;
        font-size: var(--text-size-m);
        height: fit-content;
        min-height: 32px;
        width: 100%;
        border-bottom: var(--border-style-solid) var(--border-width-default) var(--border-color-default);
        .monitoring-services-table-row-cell-empty {
          width: var(--size-m);
        }
        .monitoring-services-table-row-cell-services {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width:30%;
          float: left;
          height: 40px;
          line-height: var(--line-height-xxxl);
          padding: var(--spacing-s) var(--spacing-zero);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .monitoring-services-table-row-cell-stats {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width:33%;
          float: left;
          height: 32px;
          line-height: var(--line-height-xxxl);
          padding: var(--spacing-xs) var(--spacing-s);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
          display: flex;
          align-items: center;
        }
        .monitoring-services-table-row-cell-status-critical {
          width: 15%;
          color: var(--base-color-error);
        }
        .monitoring-services-table-row-cell-status-warning {
          width: 15%;
          color: var(--base-color-warning);
        }
        .monitoring-services-table-row-cell-opened-at {
          width: 15%;
        }
        .monitoring-services-table-row-cell-closed-at {
          width: 15%;
        }
        .monitoring-services-table-row-cell-event-name {
          width: 50%;
        }
        .monitoring-services-table-row-cell-latency {
          float: left;
          width: 15%;
          padding: var(--spacing-s) var(--spacing-zero);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }

        .monitoring-services-table-row-cell-error-rate {
          float: left;
          width: 15%;
          padding: var(--spacing-s) var(--spacing-zero);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .monitoring-services-table-row-cell-apdex {
          float: left;
          width: 15%;
          padding: var(--spacing-s) var(--spacing-zero);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .monitoring-services-table-row-cell-status {
          float: left;
          width: 15%;
          min-width: 115px;
          padding: var(--spacing-s) var(--spacing-zero);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
          .health-status-badge {
            margin: var(--spacing-zero);
          }
        }
      }
      .monitoring-services-table-row:hover {
        background-color: var(--background-color-secondary);
        .monitoring-services-table-row-cell-services {
          cursor: pointer;
          background-color: var(--background-color-secondary);
          color: var(--content-color-link);
          text-decoration: underline;
       }
        .monitoring-services-table-row-cell-latency {
          background-color: var(--background-color-secondary);
        }
        .monitoring-services-table-row-cell-stats {
          background-color: var(--background-color-secondary);
        }
        .monitoring-services-table-row-cell-error-rate {
          background-color: var(--background-color-secondary);
        }
        .monitoring-services-table-row-cell-apdex {
          background-color: var(--background-color-secondary);
        }
        .monitoring-services-table-row-cell-status {
          background-color: var(--background-color-secondary);
        }
      }
    }
  }
}

.monitoring-typeswitcher {
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-l);
  .plugin__workspace-view-switcher__wrapper {
    width: 165px;
    height: var(--size-m);

    .plugin__workspace-view-switcher__switch {
      height: 30px;
      line-height: var(--line-height-s);
      padding: var(--spacing-s) var(--spacing-m);
      box-sizing: border-box;
    }

    .plugin__workspace-view-switcher__switch.left {
      width: 70px;
      left: 1px;
    }
    .plugin__workspace-view-switcher__switch.right {
      width: 95px;
      left: 69px;
    }

    .plugin__workspace-view-switcher__switch__item {
      padding: var(--spacing-s) var(--spacing-m);
      line-height: var(--line-height-s);
      box-sizing: border-box;
    }

    .plugin__workspace-view-switcher__switch__item--left {
      flex: 0 0 70px;
      height: var(--size-m);
    }
    .plugin__workspace-view-switcher__switch__item--right {
      flex: 0 0 95px;
      height: var(--size-m);
    }
  }

}

.api-service-details-view-container-wrapper {
  display: flex;
  flex-direction: column;
  .no-monitoring-builds{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-xxl);
    width: 507px;
    height: 237px;
    background: var(--background-color-primary);
    .no-monitoring-builds-illustration-wrapper {
    margin-top: var(--spacing-xxl);
    margin-bottom: var(--spacing-s);
    }
    .no-monitoring-builds-title {
      position: static;
      height: 20px;
      left: 0px;
      right: 0px;
      top: 0px;
      font-family: Inter;
      font-style: normal;
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
      text-align: center;
      letter-spacing: -0.12px;
      color: var(--content-color-primary);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: var(--spacing-s) var(--spacing-zero);
    }
    .no-monitoring-builds-subtitle {
      position: static;
      height: 40px;
      left: 0px;
      right: 0px;
      top: 28px;


      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      text-align: center;
      color: var(--content-color-secondary);
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  .monitoring-configured-integrations {
    > div > div {
      overflow: unset;
    }

    &-table {
      margin-left: var(--spacing-xxl);
    }
  }

  .monitoring-available-integrations {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--background-color-primary);
    .monitoring-no-available-service {
      width:400px;
      margin:auto;
      display:flex;
      flex-direction:column;
      align-items:center;
      .monitoring-no-available-service-illustration {
        display: flex;
        width:140px;
        height:auto;
        margin:auto;
        align-items:center;
      }
      .monitoring-no-available-service-content {
        width: 210px;
        margin: auto;
        display:flex;
        color: var(--content-color-secondary);
        font-family: Inter;
        font-style: normal;
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        text-align: center;
        letter-spacing: -0.12px;
    }
   }
    .monitoring-integrations-card-list {
     display: grid;
     justify-content: space-around;
     width: 100%;
     grid-column-gap: var(--spacing-xl);
     grid-template-columns:  repeat(auto-fit, minmax(240px, 1fr));
     margin-left: var(--spacing-l);
     margin-right: var(--spacing-l);
     .monitoring-integrations-card-list-item {
      width:240px;
      height:128px;
      cursor: pointer;
      border: var(--border-style-solid) var(--border-width-default) var(--border-color-strong);
      display: flex;
      box-sizing: border-box;
      padding: var(--spacing-m);
      border-radius: var(--border-radius-default);
      margin:auto;
      margin-bottom:var(--spacing-m);
      flex-direction: column;
      .monitoring-integrations-card-list-item-content-wrapper {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        .monitoring-integrations-card-list-item-icon-container {
          display: flex;
          height: var(--size-s);
          width: var(--size-s);
          .monitoring-integrations-card-list-item-icon {
            width: 100%;
            height: auto;
          }
          .monitoring-integrations-card-list-item-fallback-icon {
            width: 100%;
            height: auto;
          }
        }
        .monitoring-integrations-card-list-item-heading {
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          font-weight: var(--text-weight-regular);
          margin-left: var(--spacing-s);
          color: var(--content-color-primary);
        }
        .monitoring-integrations-card-list-item-description {
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          font-weight: var(--text-weight-regular);
          color: var(--content-color-secondary);
          margin-top: var(--spacing-xs);
          height: 50px;
        }
        .monitoring-integrations-card-list-item-heading-disabled {
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          font-weight: var(--text-weight-regular);
          margin-left: var(--spacing-s);
          color: var(--content-color-secondary);
        }
      }
     }
      .list-item-disabled {
        cursor: not-allowed;
      }
    }
  }
}

.api-monitoring-dashboard {
  height: -webkit-fill-available;
  align-items: center;

  .api-monitoring-dashboard-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;

    .api-monitoring-dashboard-wrapper__inner {
      margin: 0 0 0 var(--spacing-s);
      overflow: auto;
    }

    .api-monitoring-titlebar-wrapper {
      width: -webkit-fill-available;
      width: -moz-available;
      background-color: var(--background-color-primary);

      .api-monitoring-service-selector {

        .aether-dropdown__value-container {
          padding-left: 0;
        }

        .aether-dropdown__control {
          border: none !important;
        }

        .aether-dropdown__single-value {
          font-size: var(--text-size-l);
          font-weight: var(--text-weight-medium);
          letter-spacing: -0.12px;
        }

        .aether-dropdown__control--is-focused,
        .aether-dropdown__control--is-focused:hover {
          border: none !important;
          box-shadow: none !important;
        }

        .integrations-add-card__segment {
          margin-top: var(--spacing-zero);
          .integrations-add-card__input {
            padding-top: var(--spacing-zero);
          }
        }

        .monitoring-service-selector-icon-wrapper {
          display: contents;
          align-items: center;
          padding: 6px var(--spacing-xs) 6px var(--spacing-s);
          .aether-dropdown__menu {
            width: 300px;
          }
        }
        .monitoring-service-selector-selected-item-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: var(--content-color-primary);
        }
      }


      .api-monitoring-titlebar {
        display: flex;
        align-items: center;
        height: 30px;
        .api-monitoring-back-icon {
          display: flex;
          cursor: pointer;
        }

        .monitoring-service-selector-health-status {
          min-width: max-content;
          align-items: center;
          display: flex;
        }
        .api-monitoring-timeline-selector {

          .integrations-add-card__segment {
            margin-top: var(--spacing-m);

            .integrations-add-card__input {
              padding-top: var(--spacing-zero);
            }
          }
          .monitoring-timeline-selector-icon-wrapper {
            display: flex;
            align-items: center;
            padding: 6px var(--spacing-m) 6px var(--spacing-m);
          }
          .monitoring-timeline-selector-selected-item-text {
            width: 154px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: var(--content-color-primary);
          }
        }
        .monitoring-service-selector-icon-wrapper:hover {
          background-color: var(--background-color-tertiary);
          border-radius: var(--border-radius-default);
        }

        .api-monitoring-newrelic-timeframe {
          float: right;
          margin-left: var(--spacing-m);
        }
      }

      .api-monitoring-more-options-icon-wrapper {
        display: flex;
        align-items: center;
      }
    }

    .api-monitoring-titlebar-divider {
      height: 47px;
    }

    .api-monitoring-chart-title-wrapper {
      display: grid;
      grid-column: auto;
      grid-auto-flow: column;
      margin-top: var(--spacing-xl);
      margin-left: var(--spacing-l);
      .api-monitoring-chart-title {
        height: var(--size-xs);
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        color: var(--content-color-primary);
      }
    }

    .api-monitoring-web-transaction-chart {
      display: grid;
      height: 447px;
      background-color: var(--background-color-tertiary);
      margin: var(--spacing-s) var(--spacing-l) var(--spacing-l) var(--spacing-l);
      align-items: center;
      .api-monitoring-web-transaction-chart-title-wrapper {
        display: flex;
        grid-area: 1 / 1;
        height: 431px;
        .api-monitoring-web-transaction-chart-title {
          font-size: var(--text-size-xxl);
          font-weight: var(--text-weight-medium);
          margin-top: var(--spacing-xxl);
          margin-left: var(--spacing-xxxl);
          z-index: 1;
        }
      }
      .api-monitoring-web-transaction-chart-iframe-wrapper {
        display: flex;
        align-items: center;
        grid-area: 1 / 1;
        height: 431px;
        margin-left: var(--spacing-s);
        margin-right: var(--spacing-s);
        background-color: white;
        .web-transaction-chart {
          width: 100%;
          height: 431px
        }
      }
    }

    .api-monitoring-chart-wrapper {
      display: flex;
      height: 325px;
      background-color: var(--background-color-tertiary);
      margin: var(--spacing-s) var(--spacing-l) var(--spacing-l) var(--spacing-l);
      align-items: center;
      .api-monitoring-web-throughput-chart-iframe-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 309px;
        margin-left: var(--spacing-s);
        align-items: center;
        background-color: white;
        .throughput-chart {
          width: 100%;
          height: 309px;
        }
      }
      .api-monitoring-web-error-rate-chart-iframe-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 309px;
        margin-left: var(--spacing-s);
        align-items: center;
        background-color: white;
        .error-rate-chart {
          width: 100%;
          height: 309px;
        }
      }
      .api-monitoring-web-apdex-chart-iframe-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 309px;
        margin-left: var(--spacing-s);
        margin-right: var(--spacing-s);
        align-items: center;
        background-color: white;
        .apdex-chart {
          width: 100%;
          height: 309px;
        }
      }
    }
  }
}

.integrations-add-card__label-title {
  margin-bottom: var(--spacing-zero);
}

.integrations-add-actions__button--container {
  margin-top: var(--spacing-zero);
}

.iframe-loading-indicator {
  position: relative;
  margin-left: 50%;
}

.monitoring-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: var(--size-m);
  min-width: 250px;
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-xs);
  color: var(--content-color-primary);
  .monitoring-menu-item-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.monitoring-empty-dashboards {
  color: var(--content-color-secondary);
}

.popover-loading {
  display: flex;
  align-items: center;
  margin-left: 50%;
  height: var(--size-m);
  width: 250px;
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
}

.monitoring-popover-option-wrapper {
  max-height: 300px;
  overflow-y: auto;
}

.monitoring-menu-item:hover {
  background-color: var(--background-color-secondary);
}

.monitoring-service-selector-dropdown-title {
  margin-left: 23px;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  line-height: var(--line-height-xxl);
}

.monitoring-service-selector-dropdown-item {
  cursor: pointer;
  display: flex;
  min-width: 245px;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-right: var(--spacing-s);
  color: var(--content-color-primary);
  .monitoring-service-selector-dropdown-item-selected {
    display: flex;
    padding-right: var(--spacing-s);
    align-items: center;
    width: 16px;
  }
  .monitoring-service-selector-dropdown-item-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.monitoring-timeline-selector-dropdown-item {
  cursor: pointer;
  display: flex;
  width: 190px;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-right: var(--spacing-s);
  color: var(--content-color-primary);
  .monitoring-timeline-selector-dropdown-item-selected {
    display: flex;
    margin-left: var(--spacing-l);
    padding-right: var(--spacing-s);
    align-items: center;
    width: 16px;
  }
}

.monitoring-service-selector-dropdown-item:hover {
  background-color: var(--background-color-secondary);
}
.monitoring-timeline-selector-dropdown-item:hover {
  background-color: var(--background-color-secondary);
}
.bootstrap-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.add-integration-step-panel {
  padding: var(--spacing-zero);

  .integrations-add-card__segment {
    margin-top: var(--spacing-zero) !important;
  }
}

.monitor-integration-description {
  margin-bottom: var(--spacing-xl) !important;
  margin-top: var(--spacing-m) !important;
}

.api-monitoring-edit-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-secondary);
}

.api-monitoring-edit-disabled:hover {
  background-color: var(--background-color-secondary);
}

.api-monitoring-delete-disabled:hover {
  background-color: var(--background-color-secondary);
}

.api-monitoring-delete-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--red-40);
}

.alert-config-info-popover {
  display: flex;
  margin: var(--spacing-s);
  width: max-content;
  color: var(--content-color-primary);
  .alert-config-info-popover-link {
    color: var(--content-color-primary);
    margin-left: var(--spacing-xs);
  }
}

.datadog-table {
  padding-left: var(--spacing-s);
}


.apm-services-table {
  td {
    vertical-align: middle;
  }

  td > span {
    display: block;
  }

  .health-status-badge {
    margin-left: var(--spacing-zero);
  }
}

.monitoring-dashboard-links__menu__item {
  text-align: left;
}
