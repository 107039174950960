$report-nav-width: 320px;

@mixin no-activity() {
  max-width: 480px;
  margin: var(--spacing-l);

  @media (min-width: $breakpoint-md) {
    margin: var(--spacing-xxl) auto;
    padding: 0 var(--spacing-xxl);
  }
}

.report-empty-state {
  text-align: center;

  &--absolute-center {
    padding-left: $report-nav-width;
  }

  &__image {
    margin: auto;
    margin-top: 80px;
    max-width: $es-image-width;
    margin-bottom: var(--spacing-l);

    &.activity {
      @include icon("../assets/images/empty-state/activity.svg", 160px, 126px);
    }
  }

  &__title {
    color:  var(--content-color-primary);
    font-size: var(--text-size-l);
    margin-bottom: var(--spacing-s);
  }

  &__body {
    max-width: 600px;
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
    margin: var(--spacing-l) auto var(--spacing-xxl);
    font-size: var(--text-size-s);
  }

  &__action {
    margin-bottom: var(--spacing-xl);
  }

  &__subtext {
    text-align: center;
    margin-bottom: var(--spacing-xl);
    line-height: var(--line-height-s);
  }
}
