.connected-accounts {
  &--user-name {
    max-width: 150px;
  }

  &--create-installed-app {
    z-index: 10;
    position: fixed;
    background-color: var(--background-color-primary);
    width: 100%;
    height: 100%;
    top: var(--spacing-xxxl) !important;
    left: var(--spacing-zero) !important;

    &--form {
      min-width: 480px !important;
      width: 35% !important;
      margin-top: var(--spacing-xxl);
    }
  }

  &--update-installed-app {
    z-index: 10;
    position: fixed;
    background-color: var(--background-color-primary);
    width: 100%;
    height: 100%;
    top: var(--spacing-xxxl) !important;
    left: var(--spacing-zero) !important;

    &--form {
      min-width: 480px !important;
      width: 35% !important;
      margin-top: var(--spacing-xxl);
    }
  }

  &--delete-modal {
    &--banner {
      margin-top: var(--spacing-m);
    }
  }

  &--spinner {
    width: 100%;
    height: 100% !important;
  }

  &--blank-state-wrapper {
    margin-left: calc(var(--spacing-xl) + var(--spacing-xs));
  }

  &--collapse-wrapper {
    @include clickable;

    margin-bottom: var(--spacing-m) !important;
  }

  &--collapse-icon {
    margin-right: var(--spacing-m) !important;
  }

  &--card {
    @include clickable;
    width: 288px;
    height: 184px;

    &-github {
      margin-top: var(--spacing-xl)
    }
  }

  .is-disabled {
    @include disabled {
      cursor: default;
      opacity: 0.5;
      @include unselectable;
    }
  }

  &--icon {
    rect {
      fill: var(--background-color-tertiary);
    }

    svg {
      border-radius: var(--border-radius-s);
      transform: scale(2.25);
      margin-left: var(--spacing-xxl);
      margin-bottom: var(--spacing-l);
      margin-top: var(--spacing-xxl);
      background-color: var(--background-color-tertiary);
      padding: var(--spacing-xs);
    };
  }


  &--app-name {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-l);
    margin-left: var(--spacing-l);
  }

  &--app-details {
    margin-top: var(--spacing-xs);
    margin-bottom: var(--spacing-m);
    margin-left: var(--spacing-l);
  }

  &--card {
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    border-radius: var(--border-radius-default);
  }

  &--delete-container {
    display: flex;
    justify-content: flex-end;
    padding-right: var(--spacing-s) !important;

    &--delete-button {
      cursor: pointer;
      visibility: hidden;
      position: relative;
      top: 3px;
      height: var(--spacing-l) !important;
      margin-top: 3%;
    }
  }

  &--slack-delete {
    margin-left: calc(var(--spacing-xxl) + var(--spacing-l));
  }

  &--edit-container {
    display: flex;
    justify-content: flex-end;
    padding-right: var(--spacing-s) !important;

    &--edit-button {
      cursor: pointer;
      visibility: hidden;
      position: relative;
      top: 3px;
      height: var(--spacing-l) !important;
      margin-top: 3%;
      margin-left: var(--spacing-l) !important;
    }
  }

  &--update-container {
    display: flex;
    justify-content: flex-end;
    padding-right: var(--spacing-s) !important;
    &--update-button {
      cursor: pointer;
      visibility: hidden;
      position: relative;
    }
  }

  .connected-accounts-table {
    &--connectedto-column {
      display: flex;
      align-items: center;
    }
    &--setupby-column {
      display: flex;
      align-items: center;
    }
    &--lastrun-column {
      display: flex;
      align-items: center;
    }
    &--createdby-column {
      display: flex;
      align-items: center;
    }
  }

  .info-button {
    display: inline-block;
  }

  .entity-learn-more-link {
    color: var(--content-color-secondary);
    cursor: pointer;

    &:hover {
      color: var(--content-color-link);
    }
  }
}

.delete-connected-accounts-modal-header {
  font-size: var(--text-size-m);
}
.delete-connected-accounts-modal-content {
  font-size: var(--text-size-m);
}

.connected-account-breadcrumbs {
  margin-bottom: var(--spacing-xxl);
}

.connected-account--back-btn {
  @include clickable;

  &:hover {
    color: var(--content-color-link);

    path {
      fill: var(--content-color-link);
    }
  }
}

.connected-account--back-btn-wrapper {
  width: 108px;
  margin-bottom: var(--spacing-xl) !important;
  display: flex;
  direction: row;
  align-items: center;
  gap: var(--spacing-s);
}

.connected-account-empty-integrations {
  width: 480px;
  margin-top: calc(var(--spacing-xxl) + var(--spacing-xl));
  margin-right: var(--spacing-xxl);
}

.connected-account-integrations-list {
  margin-right: var(--spacing-xxl) !important;

  &--table {
    margin-bottom: var(--spacing-m);
  }
}

.connected-account__user {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  white-space: nowrap;

  &-icon {
    margin-right: var(--spacing-s);
    position: relative;
    vertical-align: text-top;
  }
  &-label {
    font-weight: var(--text-weight-medium);
    padding-left: var(--spacing-s);
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.is-clickable {
      cursor: pointer;
    }
  }
  &-name {
    font-weight: var(--text-weight-medium);
    padding-left: 2px;
  }
}

.integrations-list {
  &--set-up-by {
    margin-top: var(--spacing-m);
  }
}
