.mock-tab-error {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    width: 415px;
    padding: var(--spacing-l);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error__illustration {
      margin-bottom: var(--spacing-l);
    }

    &__title {
      color: var(--content-color-primary);
      margin-bottom: var(--spacing-s);
    }

    &__body {
      color: var(--content-color-secondary);
      text-align: center;
    }

    &__refresh-button {
      margin-top: var(--spacing-l);
    }
  }
}

