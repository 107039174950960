$missing-current-workspace-thumbnail-url: '../assets/images/thumbnails/browse-view-offline.svg';

.app-crash-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: $font-primary;
  background-color: var(--background-color-primary);
  height: 100%;

  .app-crash-wrapper__blank-state {
    padding: var(--spacing-l);
  }

  .app-crash-thumbnail {
    height: 200px;
    width: 200px;
    background: url($missing-current-workspace-thumbnail-url) no-repeat center;
    background-size: contain;
    margin-bottom: 10px;
  }

  .app-crash-content-container{
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .app-crash-header {
    font-size: 18px;
    font-weight: 600;
    color: var(--content-color-primary);
  }

  .app-crash-content {
    font-size: 14px;
    text-align: center;
    margin: 20px 0;
    color: var(--content-color-secondary);
  }
}
