.example-response-list {
  &--wrapper {
    position: relative;
    line-height: var(--line-height-m);
    font-size: var(--text-size-m);

    .dropdown-caret {
      margin-left: var(--spacing-zero);
    }
  }

  &__button {
    padding: var(--spacing-zero);
    height: auto;
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-medium);

    .example-button {
      &__badge {
        background: var(--background-color-tertiary);
        border-radius: 2px;
        font-size: var(--text-size-xs);
        line-height: var(--line-height-s);
        margin: var(--spacing-zero) var(--spacing-xs);
        min-width: 16px;
        padding: var(--spacing-zero) var(--spacing-xs);
        box-sizing: border-box;

        // horizontally center
        display: flex;
        justify-content: center;
      }

      &__caret {
        margin: unset;
        float: unset;
        @include icon-color(var(--content-color-secondary));
      }
    }

    @include active {
      color: var(--content-color-primary);

      .example-button {
        &__caret {
          @include icon-color(var(--content-color-primary));
        }
      }
    }
  }
}

.example-response-list--menu {
  width: 320px;
  background-color: $dropdown-menu-background-color;
  z-index: 12;
  font-size: var(--text-size-m);
  color: $dropdown-menu-item-color;
  text-align: center;
  padding: var(--spacing-xs) var(--spacing-zero);
  word-break: break-word;
  border-radius: var(--border-radius-default);

  @include material-shadow-1;

  &.empty-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 200px;
    align-items: center;
    padding: var(--spacing-s);

    @include unselectable;

    .add-new-example {
      width: initial;
      margin-left: var(--spacing-zero);
    }

    .example-response-learn-more {
      padding: var(--spacing-zero);
    }

    .example-response-list {
      &--title {
        font-weight: var(--text-weight-medium);
        padding: var(--spacing-s) var(--spacing-l);
        margin-top: var(--spacing-s);
      }

      &--body {
        padding: var(--spacing-s) var(--spacing-l);
      }

      &--add-button {
        padding: var(--spacing-s);
        margin-bottom: var(--spacing-s);
      }

      &--permission-denied {
        border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        font-size: var(--text-size-s);
        color: var(--content-color-secondary);
        margin-top: var(--spacing-s);
        padding: var(--spacing-l);
      }
    }
  }

  .add-new-example {
    width: 100%;
    height: 28px;
    justify-content: flex-start;
    padding: var(--spacing-xs) var(--spacing-s);

    &.example-list {
      color: $dropdown-menu-item-color;
      border-radius: 0;

      @include hover {
        background-color: var(--highlight-background-color-secondary);
      }
    }
  }

  .example-response-list {
    &--item {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: var(--spacing-xs) var(--spacing-s);
      position: relative;

      @include hover {
        .example-response-list--actions {
          display: flex;

          @include clickable;
        }
      }

      &.add-example {
        border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }

    &--actions {
      display: none;
    }

    &--name {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      text-align: left;
      line-height: var(--line-height-m);
    }

    &--label {
      display: flex;
      align-items: center;

      @include clickable;
    }

    &--delete {
      display: flex;

      @include clickable;

      @include hover {
        @include icon-color(var(--base-color-error));
      }

      @include active {
        @include icon-color(var(--base-color-error));
      }
    }
  }
}
