/* Variables */
$button--main-background-color: var(--button-main-background-color);
$button--main--hover-background-color: var(--button-main-hover-background-color);
$button--main--active-background-color: var(--button-main-active-background-color);
$button--text-color: var(--content-color-link);

$button-height: 40px !default;
$button-min-width: 100px !default;
$button--small-min-width: 60px !default;
$button--huge-height: 50px !default;
$button--icon--rect-width: 40px !default;
$button--text-height: 20px !default;

/* Styles */
.btn {
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: $button-height;
  padding: var(--spacing-s) 10px;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  font-family: var(--text-family-default);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  @include icon-color(var(--content-color-secondary));
  @include unselectable;
  @include clickable;

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.btn-fluid {
  display: flex;
}

.btn-primary {
  background-color: var(--button-primary-background-color);
  box-shadow: 0 0 0 1px var(--button-primary-outline-color) inset;
  min-width: $button-min-width;
  color: var(--button-primary-content-color);
  @include icon-color(var(--button-primary-content-color));
  & .dropdown-caret {
    @include icon-color(var(--button-primary-content-color));
  }

  @include hover {
    background-color: var(--button-primary-hover-background-color);
  }

  @include active {
    background-color: var(--button-primary-active-background-color);
  }

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
    @include hover {
      background-color: var(--button-primary-hover-background-color);
    }
    @include active {
      background-color: var(--button-primary-active-background-color);
    }
  }
}

.btn-secondary {
  background-color: var(--button-secondary-background-color);
  min-width: $button-min-width;
  color: var(--content-color-primary);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
  @include icon-color(var(--content-color-primary));

  @include hover {
    background-color: var(--button-secondary-hover-background-color);
    color: var(--content-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  @include active {
    background-color: var(--button-secondary-active-background-color);
    color: var(--content-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
    @include hover {
      background-color: var(--background-color-tertiary);
      color: var(--content-color-secondary);
      @include icon-color(var(--content-color-secondary));
    }
    @include active {
      background-color: var(--background-color-tertiary);
      color: var(--content-color-secondary);
      @include icon-color(var(--content-color-secondary));
    }
  }
}

.dropdown-button .btn-secondary { // Secondary button inside a dropdown
  background-color: transparent;
  box-shadow: 0 0 0 1px var(--input-border-color-default) inset;
}

.btn-tertiary {
  background-color: transparent;
  min-width: var(--size-m);
  height: var(--size-m);
  padding: var(--spacing-s);
  color: var(--content-color-secondary);
  @include icon-color(var(--content-color-secondary));

  @include hover {
    background-color: var(--highlight-background-color-primary);
    color: var(--content-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  @include active {
    background-color: var(--highlight-background-color-primary);
    color: var(--content-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
    @include hover {
      background-color: transparent;
      color: var(--content-color-secondary);
      @include icon-color(var(--content-color-secondary));
    }
    @include active {
      background-color: transparent;
      color: var(--content-color-secondary);
      @include icon-color(var(--content-color-secondary));
    }
  }
}

.btn-header {
  color: var(--header-color-content);
  @include icon-color(var(--header-color-content));
  background-color: var(--header-background-color-secondary);
  & .dropdown-caret {
    @include icon-color(var(--header-color-content));
  }

  @include hover {
    color: var(--content-color-constant);
    background-color: var(--header-background-highlight-color-secondary);
    @include icon-color(var(--content-color-constant));
  }

  @include active {
    color: var(--content-color-constant);
    background-color: var(--header-background-highlight-color-secondary);
    @include icon-color(var(--content-color-constant));
  }

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
    @include hover {
      background-color: var(--header-background-color-secondary);
      color: var(--header-color-content);
      @include icon-color(var(--header-color-content));
    }
    @include active {
      background-color: var(--header-background-color-secondary);
      color: var(--header-color-content);
      @include icon-color(var(--header-color-content));
    }
  }
}

.btn-text {
  color: var(--content-color-link);
  @include icon-color(var(--content-color-link));
  background-color: transparent;
  height: $button--text-height;

  @include hover {
    background-color: transparent;
    color: var(--content-color-link);
    @include icon-color(var(--content-color-link));
  }

  @include active {
    background-color: transparent;
    color: var(--content-color-link);
    @include icon-color(var(--content-color-link));
  }

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
    @include hover {
      background-color: transparent;
      color: var(--content-color-link);
      @include icon-color(var(--content-color-link));
    }
    @include active {
      background-color: transparent;
      color: var(--content-color-link);
      @include icon-color(var(--content-color-link));
    }
  }
}

.btn-small {
  height: var(--size-m);
  padding: var(--spacing-s) 10px;
  min-width: $button--small-min-width;
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
}

.btn-medium {
  height: var(--size-m);
  padding: var(--spacing-s) 10px;
  min-width: $button-min-width;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}

.btn-huge {
  height: $button--huge-height;
  padding: var(--spacing-m) var(--spacing-l);
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
}

.btn-icon {
  background-color: var(--background-color-tertiary);
  height: var(--size-m);
  width: var(--size-m);
  padding: var(--spacing-zero);

  @include hover {
    background-color: var(--highlight-background-color-secondary);
  }

  @include active {
    background-color: var(--highlight-background-color-secondary);
  }

  &.btn-icon-rect {
    width: $button--icon--rect-width;
  }

  &.btn-icon-circle {
    border-radius: calc(var(--size-m)*0.5);
  }

  @include disabled {
    opacity: 0.4;
    cursor: not-allowed;
    @include hover {
      background-color: var(--background-color-tertiary);
    }

    @include active {
      background-color: var(--background-color-tertiary);
    }
  }
}

/* Button Group */

.btn-group {
  display: flex;
  flex-direction: row;

  .btn {
    border-radius: 0;
  }

  .btn:first-child {
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
  }

  .btn:last-child {
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
  }
}

.btn-group-separated {
  .btn:not(:last-child) {
    border-right: var(--button-group-separator);
  }
}
