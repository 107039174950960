.requester-tab-empty-shell {
  flex: 1;
  display: flex;
  
  .requester-tab-content-panes {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  }

  .requester-tab-content-pane {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
  }

  .requester-contents-top {
    background-color: var(--background-color-primary);
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  }

  .dummy-request-meta-viewer {
    height: 48px;
    display: flex;
    width: 100%;
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    box-sizing: border-box;
    align-items: center;
  }
  .dummy-entity-tab-header {
    height: var(--size-s);
    width: 20%;
    background-color: var(--background-color-tertiary);
    margin-left: var(--spacing-s);
    border-radius: var(--border-radius-default);
  }
  .flexed {
    display: flex;
  }

  .request-editor__tabs-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    margin-bottom: var(--spacing-s);
  }

  .strip {
    background-color: var(--background-color-tertiary);
    width: 50px;
    height: var(--size-s);
    margin-right: var(--spacing-l);
    margin-left: var(--spacing-s);
    border-radius: var(--border-radius-default);
  }
  .strip-cookie {
    margin-right: var(--spacing-s);
  }
  .tab-param {
    width: 42px;
  }
  .tab-auth {
    width: 76px;
  }
  .tab-header {
    width: 69px;
  }
  .tab-body {
    width: 28px;
  }
  .tab-pre-req-script {
    width: 100px;
  }
  .tab-tests {
    width: 30px;
  }
  .tab-settings {
    width: 44px;
  }

  .url {
    flex: 1;
    height: 40px;
    margin: var(--spacing-s);
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-tertiary);
  }

  .grouped-buttons {
    flex: 0 0 100px;
    display: flex;
  }

  .btn-item {
    flex: 1;
    height: 40px;
    margin: var(--spacing-s);
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-tertiary);

    &.send {
      background-color: var(--base-color-info);
      opacity: 0.2;
      width: 100px;
    }
  }
  .response-viewer-empty__header {
    .strip-response {
      width: 64px;
      margin-left: 0;
    }
  }
}