.interceptor-settings-filters-container {
  padding-bottom: 16px;
}
.interceptor-settings-filters-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.interceptor-settings-filters-label {
  font-style: normal;
  font-weight: 600;
  flex: 0 0 32px;
  padding-right: 20px;
}

.interceptor-settings-filters-input {
  flex: 1;
}

.interceptor-settings-filters-input-help {
  color: var(--content-color-secondary);
  padding-top: 4px;
}

.interceptor-settings-filters-wrapper {
  overflow: auto;
  .interceptor-bootcamp-lesson-banner {
    background-color: var(--background-color-primary);
    margin-bottom: 16px;
  }
}

.interceptor-settings-show-filters {
  margin-top: 16px;
  max-height: 100px;
  .show-filters-btn {
    margin: 0px;
    padding: 0px;
  }
}

.interceptor-show-filters-btn {
  margin-top: 16px;
  padding: 0px;
}
