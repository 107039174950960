.environment-preview-container {
  margin-right: var(--spacing-s);


 .environment-preview-button {
    background: none;

    @include hover {
      background-color: var(--highlight-background-color-primary);
    }

    @include active {
      background-color: var(--highlight-background-color-primary);

      .environment-preview-icon {
        // set fill
        @include icon-color(var(--content-color-primary));
      }
    }

    &.is-open {
      background-color: var(--highlight-background-color-primary);
    }
  }
}

.environment-preview-button:hover .environment-preview-icon{
  @include icon-color(var(--content-color-primary));
}

.environment-preview {
  background-color: var(--background-color-secondary);
  position: relative;
  z-index: 120;
  border-radius: var(--border-radius-default);
  display: block;
  width: 720px;

  /*Media query to prevent clipping of the environment preview below 736px */
  @media screen and (max-width: 736px) {
    max-width: 600px;
  }

  -webkit-user-select: text;
  &.is-closed {
    display: none;
  }
}

.environment-preview-dropdown {
  & .dropdown-menu {
    background-color: var(--background-color-secondary);
    padding-bottom: var(--spacing-zero);
  }
}

.environment-preview-wrapper {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);

  .environment-header__meta {
    display: flex;
    padding: var(--spacing-s) var(--spacing-l);
    align-items: center;
    justify-content: space-between;

    .environment-header__meta__title__wrapper{
      flex: 1;
      display: flex;
      overflow: hidden;
      align-items: center;

      .environment-header__meta__title {
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
        @include text-truncate-ellipsis;
      }
    }
  }

  .environment-preview-list {
    .environment-preview-virtualized-list {
      height: 200px;
      background: var(--background-color-primary);

      @media screen and (max-width: 736px) {
        max-width: 100%;
      }
    }

    .environment-preview-list-item .input{
      height: fit-content;
    }

    .environment-preview-list-item, .environment-preview-list-header {
      display: flex;
      padding: var(--spacing-s) var(--spacing-l);
      word-wrap: break-word;
      word-break: break-word;
      & > *:not(:last-child) {
        padding-right: var(--spacing-xs);
      }

      .environment-preview-list-item__key, .environment-preview-list-header__key,
      .environment-preview-list-item__value, .environment-preview-list-header__value,
      .environment-preview-list-item__session-value, .environment-preview-list-header__session-value {
        text-align: left;
        box-sizing: border-box;
      }

      .environment-preview-list-item__key, .environment-preview-list-header__key {
        flex: 0 0 20%;
      }

      .environment-preview-list-item__value, .environment-preview-list-header__value {
        flex: 0 0 38%;
      }

      .environment-preview-list-item__session-value, .environment-preview-list-header__session-value {
        flex: 0 0 38%;
        display: flex;
        justify-content: space-between;
        @include hover {
          .environment-preview-list-item__session-value__edit-icon {
            visibility: visible;
          }
        }
      }

      .environment-preview-list-header__key,
      .environment-preview-list-header__value,
      .environment-preview-list-header__session-value {
        font-weight: var(--text-weight-medium);
        color: var(--content-color-secondary);
      }

      .environment-preview-list-item__key,
      .environment-preview-list-item__value,
      .environment-preview-list-item__session-value {
        color: var(--content-color-primary);
      }

      .environment-preview-list-item__value input,
      .environment-preview-list-item__session-value input{
        text-overflow: ellipsis;
      }

      .environment-preview-list-item__session-value__edit-icon-wrapper {
        margin-left: var(--spacing-xs);
        display: flex;
      }

      .environment-preview-list-item__session-value__edit-icon {
        visibility: hidden;
      }

      .environment-preview-list-item__session-value {
        display: flex;
        align-items: center;

        .warning-button {
          margin-left: var(--spacing-xs);
        }
      }

      .environment-preview-list-item__mask-icon {
        display: inline-block;
        margin-top: var(--spacing-xs);
        margin-right: var(--spacing-s);
        margin-left: var(--spacing-s);
        cursor: pointer;
      }

      &.is-session-value-deleted {
        .environment-preview-list-item__session-value {
          color: var(--content-color-tertiary);
        }
      }
    }

    .environment-preview-list-item {
      align-items: flex-start;

      @include hover {
        background-color: var(--highlight-background-color-primary);
      }
    }

    .globals-preview-list-item {
      &.is-overridden {
        .environment-preview-list-item__session-value {
          .collection-browser-details-header__name,
          .environment-preview-list-item__session-value__masked {
            color: var(--content-color-tertiary);
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.environment-preview-list-item--empty-environment,
.environment-preview-list-item--no-environment,
.environment-preview-list-item--empty-globals {
  padding: var(--spacing-l);
  display: flex;
  flex-direction: column;
  align-items: center;

    .environment-preview-list-item--no-environment-text,
    .environment-preview-list-item--empty-globals-text {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      margin-top: var(--spacing-s);
      width: 380px;
      text-align: center;
    }

    .environment-preview-list-item--no-environment-header,
    .environment-preview-list-item--empty-globals-header {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-primary);
    }
}

.environment-preview-divider {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
