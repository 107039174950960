.team-details-widget {
  margin-top: var(--spacing-xxl);

  // Reset List Styles
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-team-logo-container {
    height: 100px;
    width: 100px;
    background: var(--background-color-primary);
    display: grid;
    place-content: center;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    border: 1px solid var(--border-color-default);
    border-radius: 4px;
    .team-details-widget-team-logo {
      width: 56px;
      object-fit: contain;
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: var(--spacing-s);
    width: 250px;

    &__name {
      @include text-truncate-ellipsis;
      font-size: var(--text-size-xxl);
      line-height: var(--line-height-xxl);
      font-weight: var(--text-weight-medium);
      color: var(--content-color-primary);
      text-decoration: none;
    }

    &__domain {
      @include text-truncate-ellipsis;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      margin-top: var(--spacing-s);
    }
  }

  &-members-container {
    display: flex;
    margin-top: var(--spacing-s);
    .team-details-widget-invite-button {
      width: 69px;
      height: 32px;
      margin-left: var(--spacing-s);
      background-color: var(--base-color-info);
      color: var(--content-color-constant);

      .pm-icon {
        margin-right: var(--spacing-xs);
      }

      & svg path {
        fill: var(--content-color-constant);
      }
      
      & svg {
        color: var(--content-color-constant);
      }

      &:hover {
        background-color: var(--highlight-background-color-info);
        & svg path {
          fill: var(--content-color-constant);
        }
        & svg {
          color: var(--content-color-constant);
        }
      }
    }
    .team-details-widget-members-avatar-list {
      display: flex;

      & > * + * {
        margin-left: -8px;
      }

      .team-details-widget-members-avatar-list-item {
        border: 1px solid var(--background-color-primary);
        border-radius: 32px;
        height: 32px;
        &:nth-child(2) {
          z-index: 1;
        }
      }

      .team-details-widget-members-show-more {
        @include clickable;
        width: 32px;
        height: 32px;
        display: grid;
        place-content: center;
        background: var(--background-color-tertiary);
        color: var(--content-color-secondary);
        &-text {
          font-weight: var(--text-weight-medium);
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
}

.team-details-widget-members-list {
  list-style: none;
  padding: 8px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 192px;
  color: var(--content-color-primary);
  &-title {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  &-item {
    display: flex;
    align-items: center;
    height: 48px;
    &__name {
      padding: 0 var(--spacing-s);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      max-width: 132px;
      @include text-truncate-ellipsis;
    }
  }

  &-team-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacing-s);
    font-weight: var(--text-weight-medium);
    font-size: 11px;
    line-height: 12px;
    color: var(--content-color-link);
    i {
      margin-left: var(--spacing-s);
      svg {
        path {
          fill: var(--content-color-link);
        }
        color: var(--content-color-link);
      }
    }
  }
}
