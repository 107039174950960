.entity-tab-header {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s) var(--spacing-l);
  align-items: flex-start;
  box-sizing: border-box;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  &__name {
    display: flex;
    flex: 1;
    min-width: 0;
    align-items: center;
    line-height: var(--line-height-m);
    @include unselectable;

    &__container {
      display: flex;
      width: 100%;
    }

    &__input {
      padding-left: 3px;
      color: var(--content-color-primary);
      font-family: $font-primary;
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);
      @include text-truncate-ellipsis;
    }

    &__wrapper {
      @include text-truncate-ellipsis;
    }

    &.is-editing {
      .entity-tab-header__name__wrapper {
        width: 100%;
      }
      .inline-input__wrapper {
        width: 100%;
      }
    }

    .inline-input__wrapper {
      .input-box {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
        padding: 2px
      }
    }

    .breadcrumb__container {
      padding-left: 3px;

      &:hover {
        .breadcrumb__text:not(:last-child) .breadcrumb__name {
          transition: color .2s ease-in-out;
        }
      }

      .breadcrumb__text {
        &:last-child:hover .breadcrumb__name {
          cursor: auto;
          user-select: text;
          color: var(--content-color-primary);
        }

        &:not(:last-child) .breadcrumb__name {
          color: var(--content-color-tertiary);

          &:hover {
            color: var(--content-color-link);
          }
        }
      }
    }

    .entity-tab-header__edit-btn {
      @include clickable;
      background-color: transparent;
      width: var(--size-s);
      height: var(--size-s);
      margin-left: var(--spacing-xs);
      @include hover {
        @include icon-color(var(--content-color-primary));
      }
    }

    &.show-on-hover {
      .entity-tab-header__copy-btn {
        visibility: hidden;
      }

      &:hover {
        .entity-tab-header__copy-btn {
          visibility: visible;
        }
      }
    }
  }

  &__actions {
    display: flex;
    margin-left: var(--spacing-s);
    justify-content: flex-end;
    align-items: center;
  }

  .entity-tab-header__copy-btn {
    @include clickable;
  }

  &__left-meta-component {
    display: flex;
    align-items: center;
    margin-right: var(--spacing-xs);
  }

  &__subtitle {
    max-width: 100%;

    & > *:first-child {
      margin-top: var(--spacing-xs);
    }
  }

  // Following styles are added for rendering Enviornment Selector in Header when 'use browser tabs' setting is enabled
  .environment-container {
    border-radius: var(--border-radius-default);
    margin-left: var(--spacing-m);
    // Matches the height of other actions present in request headers.
    height: var(--size-m);

    .input-select-v2-wrapper .input-search-group {
      border-top-left-radius: var(--border-radius-default);
      border-bottom-left-radius: var(--border-radius-default);
    }

    .input-select-v2-wrapper.is-open {
     // Used !important as the base Dropdown component set’s the width using inline styles which we need to override according to design requirements.
     // We are adding custom styles for this specific instance of Environment Selector.
     // https://www.figma.com/file/DipeGl7WZHfCa52reJe91F/Workspace-Tabs?node-id=857%3A44255
      width: 210px !important;
    }

    .input-search-group:hover, .input-search-group.is-hovered {
      background-color: transparent;
      border-color: transparent;
    }

    .environment-splitter{
      height: 20px;
      border-left: var(--border-width-default) var(--border-style-solid);
      border-color: var(--environment-divider-border-color);
    }

    &:hover, &:active, &:focus-within {
      .environment-splitter {
      }
    }

    .environment-selector-container{
      align-items: center;
      border-radius: var(--border-radius-default);
      border: var(--border-width-l) var(--border-style-solid) transparent;
      padding: 0 0 0 var(--spacing-s);
      margin-right: 0;
      
      &:focus-within {
        border: var(--border-width-l) var(--border-style-solid) var(--environment-input-focus-color);
       }
      
      &-header--browser-tabs-enabled {
        border: none;
        &:focus-within {
          border: none;
        }
      }
      
    }

    .environment-preview-container {
      margin-right: 0;
    }
  }
}

.environment-selector-container-no-margin-header {
  margin-right: 0px;
}

.environment-splitter-new {
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xs);
  height: var(--size-xs);
  border-left: var(--border-width-default) var(--border-style-solid);
  border-color: var(--environment-divider-border-color);
}