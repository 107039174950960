.websocket-request-documentation {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s);

  &__body {
    display: flex;
    flex: 1 auto;
    height: 100%;
    flex-direction: column;
    margin-right: var(--spacing-l);
    gap: var(--spacing-xl);

    & > *:not(:first-child) {
      margin-left: var(--spacing-s);
    }
  }
}

.ws-documentation-table {
  & > div {
    padding-top: var(--spacing-zero);
  }
}
