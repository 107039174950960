.create-new-modal {
  .modal-content {
    padding: 0;
    overflow-y: hidden;

    .create-new-modal__content {
      height: 100%;
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 35px;
    background-color: $create-new-intro-footer-color;
    border-top: 1px solid $create-new-card-border-color;
    padding: 0 20px;
    font-size: 12px;

    .btn-text {
      padding: 0;
    }

    .btn-text {
      font-weight: 600;

    }
  }
}

.create-new-modal__content {
  overflow-y: auto;

  .create-new-modal__intro {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .create-new-modal__intro__heading {
    font-size: 12px;
    font-weight: 600;
    color: $create-new-secondary-color;
    margin-bottom: 5px;
  }

  .create-new-modal__intro__help {
    font-size: 12px;
    color: $create-new-secondary-color;
  }

  .create-new-modal__intro__cards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    position: relative;

    .create-new-modal__intro__card {
      display: flex;
      align-items: flex-start;
      border-radius: 4px;
      width: 33%;
      height: 80px;
      padding: 10px 5px 10px 0;
      cursor: pointer;
      box-sizing: border-box;

      @include hover {
        background-color: $create-new-card-background-color--hover;
      }

      @include disabled {
        cursor: default;
        opacity: 0.5;
        @include unselectable;
      }

      .create-new-modal__intro__card__icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex: 0 0 64px;
        height: 64px;
        margin-right: 10px;
        padding-top: 10px;

        .create-new-modal__intro__card__icon__icon {
          @include icon-color(var(--content-color-primary));
        }
      }

      .create-new-modal__intro__card__content {
        display: flex;
        flex-direction: column;

        .create-new-modal__intro__card__title {
          font-size: var(--text-size-l);
          font-weight: 600;
          color: var(--content-color-link);
          margin-bottom: 5px;
        }

        .create-new-modal__intro__card__description {
          color: $create-new-secondary-color;
          font-size: 12px;
        }
      }

      &--websocket-request {
        .create-new-modal__intro__card__title {
          display: flex;
        }
      }

      &--grpc-request {
        .create-new-modal__intro__card__title {
          display: flex;
        }
      }
    }

    &.create-new-modal__intro__cards--basic {
      margin-bottom: 20px;
    }
  }
}
