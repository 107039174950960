.update-user-profile-modal {
  z-index: 120;

  &-header {
    .modal-header-title {
      font-size: var(--text-size-xxl);
      line-height: var(--line-height-xl);
      font-weight: var(--text-weight-medium);
      padding-left: var(--spacing-xxl);
      padding-top: var(--spacing-xl);
    }
  }

  &-container {
    padding-left: var(--spacing-xxl);
    padding-right: var(--spacing-xxl);

    &__text {
      font-size: var(--text-size-l);
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-l);
      margin-bottom: var(--spacing-xl);
    }

    &__input-name, &__input-username {
      .label {
        margin-top: var(--spacing-xl);
        margin-bottom: var(--spacing-s);
        font-weight: var(--text-weight-medium);
      }

      .input-field {
        margin-bottom: var(--spacing-xs);
      }
    }

    &__info {
      margin-top: var(--spacing-m);
      line-height: var(--line-height-m);
      color: var(--content-color-tertiary);

      .profile-link {
        line-break: anywhere;
      }
    }

    &__actions {
      width: 100%;
      display: flex;

      .btn-secondary {
        margin-right: var(--spacing-s);
      }

      .btn-primary {
        flex: 1;
      }
    }
  }

  &-footer {
    padding: var(--spacing-xl) var(--spacing-xxl); 
  }
}
