.request-documentation {
  &--url {
    margin-bottom: var(--spacing-l);

    .request-url {
      padding: var(--spacing-xs) var(--spacing-s);
      color: var(--content-color-primary);
      background: var(--background-color-secondary);
      font-weight: var(--text-weight-regular);
      word-break: break-all;
      border-radius: var(--border-radius-default);
      border: var(--border-width-default) var(--border-style-solid)
        var(--border-color-default);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
    }
  }

  &.docs-workbench-view {
    .open-request-button {
      flex-direction: row-reverse;
      padding-left: 10px ;
    }

    .open-example-button {
      flex-direction: row-reverse;
      padding-left: 10px ;
    }
  }
}

.documentation-description {
  .request-items-table {
    .title {
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
    }
  }
}
