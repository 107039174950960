.header-preset-list-item {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs) 0;

  &:not(:last-child) {
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }

  &__name-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__name {
    @include text-truncate-ellipsis;
    @include clickable;
  }

  &__actions {
    display: flex;
    gap: var(--spacing-xs);

    .btn-icon {
      background-color: transparent;
    }
  }
}

