.permission-list-v2-container {
  @include unselectable;

  .permission-list--workspace {
    position: relative;
    display: flex;
    align-items: center;
  }

  .permission-list--workspace__member-details,
  .permission-list--workspace__member-list-icon {
    cursor: pointer;
  }

  .permission-list--workspace__member-details {
    margin-left: var(--spacing-s);
  }

  .permission-list--workspace__member-list-icon {
    // Helps in increasing the hit area instead of using margin
    padding-left: var(--spacing-l);
  }

  .permission-list--workspace__roles {
    position: absolute;
    right: 20px;
  }

  .permission-list {
    margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-s) var(--spacing-xs);
    padding-bottom: var(--spacing-s);

    &__public-info {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-m);

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: var(--spacing-s);
        background-color: var(--background-color-tertiary);
        border-radius: var(--border-radius-max);
        height: var(--size-s);
        width: var(--size-s);
      }
    }

    .permission-list-item-v2 {
      position: relative;
      display: flex;
      align-items: center;
      margin: var(--spacing-m) var(--spacing-zero);

      .permission-list-item--name {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: var(--spacing-s);
        color: var(--content-color-primary);

        @include text-truncate-ellipsis;
      }

      .permission-list-item--name__user-group {
        cursor: pointer;
      }

      .permission-list-item__user-group {
        width: var(--size-s);
        height: var(--size-s);
        align-items: center;
        justify-content: center;
        align-self: center;
        background: var(--background-color-tertiary);
        border-radius: var(--border-radius-max);
        cursor: pointer;
      }

      .permission-list-item--info {
        display: flex;
        align-items: center;
        padding: var(--spacing-xs);
        margin-right: var(--spacing-xs);

        .permission-list-item--info-icon {
          @include icon-color(var(--content-color-secondary));
        }
      }

      .permissions-list-item--permissions {
        .permissions-list-item--role {
          @include text-truncate-ellipsis;
          height: var(--size-s);
          width: 72px;
        }

        .dropdown-button {
          .btn {
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: var(--size-s);
            font-weight: normal;
          }
        }
      }

      .permissions-list-item--delete {
        display: flex;
        margin-left: var(--spacing-xs);
        height: var(--size-s);
        width: var(--size-s);
        @include clickable;

        &--hide {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .permission-list-item-v2:nth-of-type(1) {
      margin-top: var(--spacing-l);
    }
  }
}

.role-dropdown {
  .dropdown-menu-item.is-disabled {
    cursor: not-allowed;
  }
}

.manage-role-dropdown {
  width: 100%;
  min-width: 250px;
  background-color: var(--background-color-primary);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  border: none;

  .dropdown-menu-item.is-disabled {
    cursor: not-allowed;
  }

  .dropdown-menu-item{
    height: auto;
    padding: var(--spacing-zero);
  }

  .role-list-item {
    max-width: 100%;
    padding-right: var(--spacing-s);
    padding-left: var(--spacing-s);
  }

  .dropdown-menu-item span {
    white-space: unset;
  }

  .role-list-item-data {
    width: 100%
  }

  .role-remove-button{
    color: var(--content-color-error);
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  }

  .role-remove-button:hover{
    color: var(--content-color-constant);
    background-color: var(--base-color-error);
  }
}

.permission-list-item__members-list {
  width: 240px;
  max-height: 300px;
  z-index: 10000;

  .tooltip-wrapper {
    padding: var(--spacing-xl);
  }

  &__title {
    display: flex;
    font-weight: var(--text-weight-bold);
    margin-bottom: var(--spacing-xs);
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);

    &__group-name {
      max-width: 110px;
      @include text-truncate-ellipsis;
    }

    &__no-members {
      max-width: unset;
    }
  }

  &__content {
    max-height: 188px;
    overflow-y: auto;
    margin-bottom: var(--spacing-l);

    &__no-members {
      margin-top: var(--spacing-xs);

      label {
        line-height: var(--line-height-m);
      }
    }

    &__item {
      display: flex;
      margin-top: var(--spacing-s);
      align-items: center;

      &__name {
        margin-left: var(--spacing-s);
        max-width: 176px;
        @include text-truncate-ellipsis;
      }
    }
  }

  &__no-members {
    margin-bottom: var(--spacing-s);
  }
}
