.collection-modal-onboarding-wrapper {
  .collection-modal-onboarding-helptext {
    margin-bottom: 10px;
  }

  .collection-name-label {
    margin-bottom: 5px;
  }
}

.learn-more-link {
  padding: 0px;
  font-weight: var(--text-weight-regular);
}

.learn-more {
  padding: 15px 0px;
  font-weight: var(--text-weight-regular);
}
