
.console-filter {
  .filter-btn {
    font-size: var(--text-size-s);
    font-weight: var(--font-weight-regular);
    color: var(--content-color-secondary);
    height: 22px;
    @include icon-color(var(--content-color-secondary));
    .dropdown-caret {
      margin-left: var(--spacing-xs);
    }
  }
}

.filter__menu-item {
  padding: var(--spacing-l);

  .menu-item__wrapper {
    display: flex;
    height: 30px;
    flex: 1;
    align-items: center;
  }

  .menu-item__checkbox {
    &.is-selected {
      background-color: var(--checkbox-checked-background-color);
    }
  }

  .menu-item__label {
    margin-left: var(--spacing-m);
  }
}
