.response-header-viewer__infobar {
  background-color: var(--background-color-secondary);
  color: var(--content-color-primary);

  .infobar__dismiss_icon {
    @include icon-color(var(--content-color-tertiary));
  }

  .response-header__infobar-icon {
    margin-right: var(--spacing-s);
    @include icon-color(var(--content-color-tertiary));
  }
}

.response-header-viewer {
  padding-bottom: var(--spacing-xs); // response tab content has 8px padding so this sets the padding to 12px x 16px;
  overflow-y: auto;
  height: 100%;


}

.response-header-empty-message {
  margin: auto;
  text-align: center;

  @include unselectable;
}
