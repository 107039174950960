/**
 * Styling for `ConfigurationListItem` component
 */
.configuration-list-item {

  $item-multi-select-bg: var(--background-color-secondary);
  $item-bg-input-on-hover: var(--highlight-background-color-secondary);
  $item-border-input-on-hover: var(--border-color-strong);

  &__container {
    display: flex;
    flex-direction: row;
    min-height: 48px;
    padding: var(--spacing-s) var(--spacing-l);
    box-sizing: border-box;
    width: 100%;
  }

  &__meta-text {
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: var(--spacing-s);
  }

  &__description {
    margin-top: var(--spacing-xs);
  }

  &__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 30%;
    min-width: 30%;
    margin-left: var(--spacing-l);
  }

  &__input {
    display:flex;

    &--input-box {
      width: 80px;
    }

    &--multi-select {
      width: 70%;
      padding: var(--spacing-xs);
      border-radius: var(--border-radius-default);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      background-color: $item-multi-select-bg;

      @include hover {
        border-color: $item-border-input-on-hover;
        background-color: $item-bg-input-on-hover;
      }
    }

    &--multi-input {
      border-radius: var(--border-radius-default);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      background-color: $item-multi-select-bg;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      height: 80px;
      width: 70%;
      padding: var(--spacing-xs);
      overflow-y: auto;
      position: relative;

      font-size: var(--text-size-m);

      @include hover {
        border-color: $item-border-input-on-hover;
        background-color: $item-bg-input-on-hover;
      }

      &--placeholder {
        margin: var(--spacing-xs);
        padding: var(--spacing-s) var(--spacing-xs);
        color: var(--content-color-tertiary);
        position: absolute;
      }
    }

    &-field {
      flex-grow: 1;
      max-width: 90%;
      margin: var(--spacing-s) var(--spacing-xs);
      padding: var(--spacing-xs);
      color: var(--content-color-primary);
    }

    .editable-tag {
      width: max-content;
      max-width: 90%;

      &__content {
        margin-right: var(--spacing-s);
        @include text-truncate-ellipsis;
      }
    }
  }

  &__dropdown-btn {
    justify-content: space-between;
  }

  &__selected-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 38px;
    height: auto;
    padding: var(--spacing-zero);

    .editable-tag {
      width: max-content;
    }
  }

  &__action {
    margin-top: var(--spacing-s);
    display: flex;

    @include unselectable;

    &__override {
      display: inline-flex;

      &-btn {
        padding: var(--spacing-zero);
      }
    }

    &__normal {
      padding: var(--spacing-zero);
      @include text-truncate-ellipsis;

      &-text > span {
        text-decoration: underline;
        @include clickable;
      }
    }
  }
}
