.example-editor {
  display: flex;
  flex-direction: column;
  width: 100%;

  .request-editor__main__url-group {
    margin-right: var(--spacing-zero);
  }

  &__send-as-request-button {
    margin-left: var(--spacing-s);

    > button {
      height: 100%;
      background-color: var(--button-main-background-color);
      font-size: 14px;
      font-weight: var(--text-weight-medium);
      color: var(--button-main-content-color);
      box-shadow: 0 0 0 1px var(--button-main-outline-color);
      min-width: 100px;

      @include hover {
        background-color: var(--button-main-hover-background-color);
      }

      @include active {
        background-color: var(--button-main-active-background-color);
      }
    }


    &.is-sending {
      .btn {
        @include hover {
          background-color: var(--highlight-background-color-tertiary);
        }

        background-color: var(--background-color-tertiary);
        color: var(--content-color-primary);
        font-weight: var(--text-weight-medium);
        font-size: var(--font-size-m);
      }

      .btn:last-child {
        display: none;
      }
    }

    &.is-loading,
    &.is-disabled {
      .btn:first-child {
        opacity: 0.5;
        @include unselectable;

        @include hover {
          background-color: $button--main-background-color;
        }

        @include active {
          background-color: $button--main-background-color;
        }
      }
    }
  }
}
