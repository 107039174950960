.team-library-invite__wrapper {
  text-align: center;
  font-size: 12px;
  font-family: $font-primary;
  color: $app-generic-text-color;
  padding-top: 10vh;
  flex: 1;
  overflow-y: auto;
  line-height: 1.7;

  &.empty-activity-feed {
    margin: 100px 0;
    padding-top: 0;

    .empty-activity-feed__label {
      margin-top: var(--spacing-s);
      font-size: 14px;
      font-weight: 600;
    }

    .empty-activity-feed__description {
      margin-top: var(--spacing-s);
      color: var(--content-color-secondary);
    }
  }
}
