.assign-mock-templates-container {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
  grid-column-gap: var(--spacing-xs);
  grid-row-gap: var(--spacing-xs);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  overflow: overlay;
  max-height: 212px;
  padding: var(--spacing-l);
  width: 482px;
  margin-left: -22px;
  margin-top: var(--spacing-m);
  border-radius: 3px;

  .is-active {
    background-color: var(--highlight-background-color-primary);
  }

  &__card {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 222px;
    flex-wrap: wrap;
    align-items: center;
    height: 60px;
    padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) 20px;
    cursor: pointer;
    border-radius: var(--border-radius-default);

    @include hover {
      background-color: var(--highlight-background-color-primary);
    }

    &__icon {
      margin-top: var(--spacing-xs);
      flex: 0 0 22px;
    }

    &__title, &__subtitle {
      display: flex;
      align-items: center;
      max-width: 172px;
      color: var(--content-color-primary);
      font-size: var(--text-size-m);
    }

    span {
      @include text-truncate-ellipsis;
    }

    &__subtitle {
      flex-basis: 100%;
      margin-left: 22px;
      color: var(--content-color-secondary);
      font-size: var(--text-size-s);
    }
  }
}

.assign-mock-template-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxl);
  
  &__error-message {
    text-align: center;
    margin-top: var(--spacing-m);
  
    &__description {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-regular);
      color: var(--content-color-primary);
    }
    
    &__link {
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      font-weight: var(--text-weight-regular);
      margin-top: var(--spacing-m);
      color: var(--content-color-secondary);
      display: flex;
      align-items: center;
      gap: 6px;
      justify-content: center;
      cursor: pointer;
    }
  }
}