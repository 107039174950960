$thumbnail-path: '../assets/images/thumbnails/' !default;

.workspace-join-modal {
  font-family: $font-primary;

  .workspace-join-modal--dismiss-icon {
    position: absolute;
    right: 10px;
    top: 14px;
    cursor: pointer;
  }

  .workspace-join-modal__wrapper {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .workspace-join-thumbnail__container {
    height: 180px;
    width: 190px;
    .workspace-join-thumbnail {
      height: inherit;
      width: inherit;
      background-repeat: no-repeat;
      background-image: url($thumbnail-path + 'workspace-join.svg');
    }
  }
  .workspace-join-modal__content {
    margin-top: 10px;
    color: var(--content-color-secondary);
  }
  .workspace-join-modal__header {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.8;
    cursor: default;
  }
  .workspace-join-modal__subtext {
    font-size: 12px;
    margin: 30px 0;
    text-align: center;
    cursor: default;

    span {
      line-height: 20px;
    }

    .workspace-join-modal__type-switcher-toggle {
      display: inline-block;
      margin: 0px 5px 0px 5px;
    }
  }

  .workspace-join-modal__action {
    display: flex;
    justify-content: center;
  }

  .always-show-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    .checkbox-label{
      margin-left: 5px;
    }
  }
}
