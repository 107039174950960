.pm-multi-select {
  list-style: none;

  li:first-of-type {
    background: var(--background-color-secondary);
    text-transform: uppercase;
    color: var(--content-color-secondary);
    font-size: var(--font-size-m);
    padding-top: var(--spacing-m);
    margin-bottom: var(--spacing-l);

    .pm-checkbox {
      top: 3px !important;
    }

    .pm-label {
      line-height: var(--line-height-m);
    }
  }

  &__item {
    @include text-truncate-ellipsis;

    .pm-checkbox {
      top: 4px !important;
    }

    .pm-label {
      @include text-truncate-ellipsis;

      display: block;
      margin-left: var(--spacing-s);
    }

    padding-left: var(--spacing-l);
    padding-right: var(--spacing-l);
    padding-top: 0;
    padding-bottom: 0;

    height: 32px;
  }

  &__label {
    color: var(--content-color-primary);
  }

  &__checkbox {
    display: inline-block;
    margin-right: 16px;
    border: var(--border-width-default) var(--border-style-solid)
      var(--border-color-strong);

    .pm-icon {
      padding: 2px;
    }
  }
}
