.scratchpad-button-wrapper {
  display: flex;
  align-items: center;
  padding: var(--spacing-zero) var(--spacing-s);
  width: 100px;
  height: 24px;
  background-color: var(--background-color-tertiary);
  border-radius: 12px;
  line-height: var(--text-size-s);
  cursor: pointer;

  .scratchpad-button-icon {
    padding: var(--spacing-zero) var(--spacing-xs);
  }

  .scratchpad-button-text {
    font-size: var(--text-size-s);
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-medium);
    padding: var(--spacing-zero) var(--spacing-xs);
  }
}
