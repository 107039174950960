.define-category {
  display: flex;
  flex: 1;
  flex-direction: column;

  .define-category__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--background-color-primary);
    overflow-y: hidden;

    .define-category__loading-state {
      display: flex;
      justify-content: center;
      margin-top: 100px;
    }
  }

  .define-category__input-file {
    display: none;
  }
}

.api-definition_upgrade_info_wrapper {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px auto;

  .api-definition_upgrade_illustration {
    display: flex;
    justify-content: center;
    padding: var(--spacing-s);
  }

  .api-definition_upgrade_text {
    text-align: center;
    padding: var(--spacing-s);

    .api-definition_refresh_text {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .api-definition_upgrade_button {
    padding: var(--spacing-s);
    min-width: 160px;
  }

  .api-definition_upgrade_link {
    text-align: center;
    display: flex;
    color: var(--content-color-primary);
    text-decoration: underline;
    padding: var(--spacing-s);
  }
  
  .api-definition_upgrade_heading {
    color: var(--content-color-primary);
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-bold);
  }
}
