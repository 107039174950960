.runner-results__iterations__tabs {
  flex: 0 0 var(--spacing-xl);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
  font-size: var(--text-size-xs);
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  div {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.runner-results__iterations__tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: var(--size-s);
  @include clickable;

  &.is-done {
    color: var(--content-color-primary);

    &.is-failed {
      color: var(--content-color-error);
    }

    @include hover {
      background-color: var(--highlight-background-color-primary);
    }
  }

  &.is-running {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    background-color: var(--background-color-secondary);
  }

  &.is-active {
    background-color: var(--background-color-tertiary);

    @include hover {
      background-color: var(--highlight-background-color-tertiary);
    }
  }

  &.is-pending {
    cursor: default;
  }
}
