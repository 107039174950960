.creating-team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: calc(4 * var(--spacing-xxl));

  &-title {
    margin-top: var(--size-s);
    color: var(--content-color-primary);
    font-size: var(--text-size-l);
    margin-bottom: var(--spacing-s);
  }

  &-subtext {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    max-width: calc(15 * var(--size-m));
    text-align: center;

    p {
      margin: unset;
    }
  }

  &-subtitle {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    max-width: calc(15 * var(--size-m));
    text-align: center;
  }

  &-info {
    color: var(--content-color-tertiary);
    font-size: var(--text-size-m);
    margin-top: var(--spacing-s);
  }

  &-alert {
    margin: var(--spacing-s) var(--spacing-zero);
    max-width: calc(15 * var(--size-m));
  }

  &-action {
    margin-top: var(--spacing-l);
    width: 126px;

    &-outline {
      margin-top: var(--spacing-l);
    }
  }

  &-icon {
    border-radius: var(--size-m);
    height: calc(var(--size-m) + var(--size-s));
    width: calc(var(--size-m) + var(--size-s));
    display: flex;
    background: var(--background-color-success);
    justify-content: center;
    align-items: center;

    i {
      height: unset;
      width: unset;

      svg {
        height: var(--size-m);
        width: var(--size-m);
      }
    }
  }
}
