.websocket-request-tab-content {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;

  &.is-hidden {
    display: none;
  }

  .websocket-headers{
    margin-bottom: var(--spacing-m);
  }
}
