.variables-empty {
  margin-top: 96px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &__base-text {
    font-size: 14px;
    font-weight: var(--text-weight-medium);
    font-family: $font-primary;
    color: var(--content-color-primary);
  }

  &__request-access-text {
    font-size: 12px;
    font-family: $font-primary;
    color: var(--content-color-secondary);
    margin-top: var(--spacing-s);
  }
}

