
.request-type-switcher-dropdown {
  &__button {
    box-shadow: 0 0 0 var(--border-width-default) var(--border-color-default);
    border-radius: var(--border-radius-default);

    .btn {
      color: var(--content-color-primary);
      background-color: var(--background-color-secondary);
      height: var(--size-s);
      font-weight: var(--font-weight-regular);
      padding: var(--spacing-xs);

      @include hover {
        background-color: var(--highlight-background-color-primary);
      }

      @include active {
        background-color: var(--highlight-background-color-tertiary);
      }
    }

    &__content {
      display: flex;
      align-items: center;
    }

    &__label {
      margin-left: var(--spacing-s);
    }
  }

  &__option {
    &__label {
      margin-left: var(--spacing-s);
    }
  }
}
