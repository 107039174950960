/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.import-multifile-warning-banner {
  margin-top: var(--spacing-m);
}
.import-container-loading {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.import-hover-link {
  color: var(--content-color-link);
  display: flex;
}

.import-container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: var(--spacing-l);
  color: var(--content-color-primary);

  .import-container-content {
    display: flex;
    flex-direction: column;
    width: 60%;

    h2 {
      font-size: var(--text-size-xxl);
      margin-bottom: var(--spacing-xl);
    }

    .import-container-entity {
      display: flex;
      flex-direction: column;


      .import-entity-header {
        display: flex;
        flex-direction: column;
        padding-bottom: var(--spacing-m);

        .import-entity-title {
          flex-grow: 2;
          font-size: var(--text-size-xl);
          font-weight: var(--text-weight-bold);
          display: flex;

          .import-entity-title-text {
            margin-left: var(--spacing-s);
          }
        }

        .import-entity-summary {
          font-size: var(--text-size-m);
          color: var(--content-color-secondary);
          padding-top: var(--spacing-s);
          padding-left: var(--spacing-s);
          padding-bottom: var(--spacing-xs);
        }

        .import-add-entity {
          color: var(--content-color-secondary);
          font-size: var(--text-size-m);
          display: flex;
          cursor: pointer;
        }
      }

      .import-definition-entity-header {
        display: flex;
        flex-direction: column;
        border-bottom: var(--border-width-default) solid var(--border-color-default);
        padding-bottom: var(--spacing-m);
      }

      .import-entity-table {
        display: flex;
        flex-direction: column;
        font-size: var(--text-size-m);
        margin-right: 2px;

        .import-entity-table-header {
          display: flex;
          padding: var(--spacing-s) 0;
          border-bottom: var(--border-width-default) solid var(--border-color-default);
          font-weight: var(--text-weight-bold);

          .import-entity-table-header-name {
            color: var(--content-color-secondary);
            flex-grow: 2;
            margin-left: var(--spacing-s);
          }

          .import-entity-table-header-type {
            width: 15%;
            color: var(--content-color-secondary);
          }
        }

        .import-entity-table-content-container {
          display: flex;
          flex-direction: column;
          color: var(--content-color-primary);
          border-bottom: var(--border-width-default) solid var(--border-color-default);

          .import-generate-collection-container {
            margin-left: var(--spacing-xl);
          }

          .import-generate-collection-tooltip {
            margin-left: var(--spacing-s);
          }

          .import-generate-collection-checkbox {
            display: flex;
            align-items: center;
            margin-bottom: var(--spacing-s);

            .input-checkbox.is-selected {
              background-color: var(--base-color-brand);
            }

            .import-generate-collection-checkbox-label {
              // check
              margin-left: 10px;
              font-weight: var(--text-weight-medium);
              color: var(--content-color-primary);
            }
          }

          .import-select-api-element-dropdown-header {
            // check
            margin-left: 28px;
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            margin-bottom: var(--spacing-s);

            &.is-disabled {
              color: var(--content-color-tertiary);
              opacity: 0.6;
            }
          }

          .import-select-api-element-dropdown {
            margin-left: 28px;
            margin-bottom: 10px;
            width: 272px;
          }

          .import-entity-file-table-container {
            padding: 0;
            margin-bottom: var(--spacing-s);
            margin-left: var(--spacing-xl);
            width: 100%;
            padding-right: 30px;
          }

          .import-entity-file-table-margin {
            border-left-color: var(--border-color-default);
            border-left-style: solid;
            border-left-width: var(--border-width-default);
            height: 5px;
            margin-left: var(--spacing-s);
          }

          .import-entity-file-table-margin-max {
            border-left-color: var(--border-color-default);
            border-left-style: solid;
            border-left-width: var(--border-width-default);
            height: 5px;
            margin-left: 30px;
          }

          .import-entity-table-content {
            display: flex;
            padding: var(--spacing-m) 0;

            .bold {
              font-weight: var(--text-weight-bold);
            }

            .import-entity-table-content-type {
              width: 15%;
            }

            .import-entity-table-content-file-list {
              display: flex;
              flex-direction: column;
              flex-grow: 2;
              margin-left: var(--spacing-s);
              width: 70%


            }
          }

          .import-entity-table-content-file-list-toggle {
            cursor: pointer;
            display: flex;
            margin: -3px var(--spacing-xl) var(--spacing-s);
          }
        }

        .import-options-container {

          &.generation-disabled {
            margin-top: var(--spacing-l);
            margin-left: 0;
          }

          .import-options-header {
            display: flex;
            align-items: center;
            margin: var(--spacing-l) var(--spacing-zero);
            font-weight: var(--text-weight-medium);
            color: var(--content-color-secondary);
            cursor: pointer;

            .import-options-dropdown-icon {
              margin-left: calc(var(--spacing-s) + var(--spacing-xs) * 0.5);
              margin-right: var(--spacing-s);
            }

            .toggle-button-icon {
              margin-right: 6px;
            }

            &.is-disabled {
              color: var(--content-color-tertiary);
              opacity: 0.6;

              .toggle-button-icon {
                @include icon-color(var(--content-color-tertiary))
              }
            }
          }

          .import-options-content-wrapper {
            margin-left: var(--spacing-xs);
            margin-top: var(--spacing-l);

            .import-options-content {
              margin-bottom: var(--spacing-xxl);

              .import-options-content-header {
                font-weight: var(--text-weight-medium);
                font-size: var(--text-size-s);
                margin-bottom: var(--spacing-s);
                color: var(--content-color-primary);
              }

              .options-wrapper {
                padding: 10px;
                border: 1px solid var(--border-color-default);
              }
            }
          }
        }
      }
    }

    .import-container-footer {
      padding: var(--spacing-xl) 0;
      display: flex;

      .import-container-footer_import_button {
        margin-right: var(--spacing-l);
      }
    }
  }

  .import-container-progress {
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: calc(10 * var(--spacing-xxxl));

    .import-container-progress-header {
      h6 {
        font-size: var(--text-size-m);
        margin: var(--spacing-s) var(--spacing-zero);
        color: var(--content-color-primary);
      }
    }

    .import-container-progress-content {
      display: flex;
      flex-direction: column;
      padding: var(--spacing-xs) var(--spacing-zero);

      .import-container-progress-content-item {
        display: flex;
        flex-direction: row;
        padding: var(--spacing-s) var(--spacing-zero);

        .import-container-progress-content-item-icon {
          padding: calc(var(--spacing-xs) - var(--spacing-xs) / 4) var(--spacing-xs);
        }

        .import-container-progress-content-item-data {
          padding-left: var(--spacing-s);
        }
      }

      .import-container-progress-content-summary {
        display: flex;
        flex-direction: row;
        padding-top: var(--spacing-m);

        .import-container-progress-content-summary-spinner {
          padding-right: var(--spacing-m);
        }
      }
    }

    .import-container-progress-footer {
      padding-top: var(--spacing-l);
    }

    .import-container-progress-footer-banner {
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      padding-top: var(--spacing-l);
    }
  }
}

.import-definition-subfile-margin {
  margin-left: var(--spacing-xxl);
}

.import-table-cell_padding {
  width: var(--spacing-m);
  height: 28px;
  border-left-color: var(--border-color-default);
  border-left-style: solid;
  border-left-width: var(--border-width-default);
  margin-left: 9px;
}

.import-table-cell_padding_no_nesting {
  min-height: 28px;
}

.import-table-cell_root_candidate_row_background {
    background-color: var(--background-color-tertiary);
    width: 100%;
};

.import-table-cell_container {
  display: flex;
  align-items: center;

  .import-table-cell_row {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .import-table-cell_header_row {
    display: flex;
    width: 100%;
    font-weight: var(--text-weight-bold);
    height: var(--size-s);
    padding: var(--spacing-s) 0;
    align-items: center;
    margin-left: var(--spacing-s);
    color: var(--content-color-secondary)
  }

  .import-table-cell_root_candidate_row {
    cursor: pointer;
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-regular);
    padding-right: 31px;
  }

  .import-table-cell_left {
    display: flex;
    flex-grow: 1;

    .import-table-cell_name_icon {
      margin-right: var(--spacing-s);
      margin-left: var(--spacing-s);
    }

    .import-table-cell_name {
      display: flex;

      .import-table-cell_name_text {
        display: flex;

        .import-table-cell_name_rootFile_badge {
          display: flex;
          margin-left: var(--spacing-s);
          align-items: center;

          .rootFile_badge {
            text-transform: none;
          }
        }
      }
    }

    .import-table-cell_cursor_pointer {
      cursor: pointer;
    }
  }

}

.import-filetable-padding {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  width: 100%;
  padding-left: auto;
  padding-right: var(--spacing-xxxl);
}

.import-filetable-file-cell-padding {
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.import-table-cell_name_icon_style {
  padding-right: 5px;
  align-self: center;
}

.rootFile_badge_pad {
  padding-left: 10px;
  align-self: center;
}

.icon-minor-pad {
  cursor: pointer;
  padding-bottom: 2px;
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  margin-left: -6px;
  width: 22px;
}

.single-cell-major-pad {
  padding-left: 40px;
  padding-right: 20px;
}

.set-as-root-flex {
  cursor: pointer;
  margin-left: auto;
  padding-top: 2px;
  align-self: center;
  padding-left: auto;
}

.entity-filetable-container {
  padding-left: var(--spacing-m);
}

.import-generate-collection-container-collection {
  padding-left: 72px;
  padding-top: var(--spacing-xs);
}

.pad-free-space {
  padding-top: 10px;
}

.table-highlight {
  &:hover {
    background-color: var(--background-color-secondary);
  }
}

.api-select-checkbox {
  padding-left: 10px;
}

.import-filetable-add-gap {
  gap: 2px;
}

.multifile-arrow-pad {
  cursor: pointer;
  margin-left: calc(var(--spacing-xs) * -1);
  margin-top: 1px;
}

.element-table-overflow-scroll {
  overflow-y: scroll;
  height: 80%;
  margin-top: var(--spacing-xxl);
}

.import-entity-gap {
  margin-top: var(--spacing-xxl);
}

.element-table-height-fill {
  height: 100%;
}

.import-container-entity-margin-override {
  margin: 0px !important;
}

.import-search-input {
  padding-bottom: var(--spacing-s);
}

.api-definition-name-text-details {
  margin-top: -2px;
  cursor: pointer;
}

.entity-row-margin-alignment {
  margin-top: -2px;
}

.import-options-container {

  &.generation-disabled {
    margin-top: var(--spacing-l);
    margin-left: 0;
  }

  .import-options-header {
    display: flex;
    align-items: center;
    margin: var(--spacing-s) var(--spacing-zero);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-secondary);
    cursor: pointer;
    margin-left: calc(var(--spacing-m) * -1);;

    .import-options-dropdown-icon {
      margin-left: calc(var(--spacing-s) + var(--spacing-xs) * 0.5);
      margin-right: var(--spacing-s);
    }

    .toggle-button-icon {
      margin-right: 6px;
    }

    &.is-disabled {
      color: var(--content-color-tertiary);
      opacity: 0.6;

      .toggle-button-icon {
        @include icon-color(var(--content-color-tertiary))
      }
    }
  }

  .import-options-content-wrapper {
    margin-left: calc(var(--spacing-xs) * -1);
    height: 350px;

    .import-options-content {
      margin-left: var(--spacing-s);

      .import-options-content-header {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-s);
        margin-bottom: var(--spacing-s);
        color: var(--content-color-primary);
      }

      .options-wrapper {
        padding: 10px;
        border: 1px solid var(--border-color-default);
        border-radius: var(--spacing-xs);
        overflow-y: scroll;
        height: 312px;
      }
    }
  }
}

.import-api-gateway-icon {
  width: var(--spacing-l);
  height: var(--spacing-l);
}

.import-gateway-icon-modifier {
  margin-top: 4px;
}

.aether-icon-modifier {

  svg {
    color: inherit;
  }

  $icon-colors: (
    "#00AC69": #00AC69,
    "#1CE783": #1CE783,
    "#1D252C": #1D252C
  );

  @each $fill, $color in $icon-colors {
    svg path[fill="#{$fill}"] {
      fill: $color;
    }

    &:hover {
      svg path[fill="#{$fill}"] {
        fill: $color;
      }
    }
  }
}

.aether-icon-modifier-dark {

  svg {
    color: inherit;
  }

  $icon-colors: (
    "#00AC69": #00AC69,
    "#1CE783": #1CE783,
    "#1D252C": #FFFFFF
  );

  @each $fill, $color in $icon-colors {
    svg path[fill="#{$fill}"] {
      fill: $color;
    }

    &:hover {
      svg path[fill="#{$fill}"] {
        fill: $color;
      }
    }
  }
}

.remove-bg-rect {
  svg rect {
    fill: unset;
  }
}

.tertiary-button-unset-hover-color {
  &:hover {
    svg path[fill="#00AC69"] {
        fill: #00AC69;
    }

    svg path[fill="#1CE783"] {
        fill: #1CE783;
    }

    svg path[fill="#1D252C"] {
        fill: #1D252C;
    }
  }
}

.tertiary-button-unset-hover-color-dark {
  &:hover {
    svg path[fill="#00AC69"] {
        fill: #00AC69;
    }

    svg path[fill="#1CE783"] {
        fill: #1CE783;
    }

    svg path[fill="#1D252C"] {
        fill: #FFFFFF;
    }
  }
}


.import-modal-single-select-override.dark-mode {
  [class^="aether-dropdown__control"] {
    &:hover {
      box-sizing: border-box;
      background-color: var(--highlight-background-color-primary);
      color: var(--content-color-primary);
    }
  }

  [class^="CustomSingleSelectComponents__EmptySelectedIconPlaceholder"] {
    width: 0px;
  }
}

.import-modal-single-select-override {
  [class^="aether-dropdown__control"] {
    &:hover {
      box-sizing: border-box;
      background-color: var(--highlight-background-color-primary);
      color: var(--content-color-primary);
    }
  }

  [class^="CustomSingleSelectComponents__EmptySelectedIconPlaceholder"] {
    width: 0px;
  }
}

.single-select-disable-click-wrapper {
  cursor: not-allowed;
}

.single-select-disable-click {
  pointer-events: none !important;
}

.import-modal-azure-icon-text {
  margin-top: 2px;
  margin-left: 1px;
}

.import-preview-padding-fix {
  margin-left: -2px;
  cursor: pointer;
}

.import-modal-cross-button {
  margin-left: auto;
  margin-top: var(--spacing-s);
  margin-right: var(--spacing-m);
  height: var(--spacing-xxl);
  margin-bottom: var(--spacing-xs);
}

.import-modal-cross-button-wrapper-default {
  margin-right: var(--spacing-xs);
}

.import-modal-cross-button-wrapper {
  height: 36px;
}

.import-modal-cross-button-wrapper-multifile {
  padding-top: var(--spacing-s);
  margin-right: var(--spacing-xs);
}

.import-modal-cross-button-margin-modifier {
  margin-right: var(--spacing-xs);
}

.import-modal-margin-remove {
  // override aether modal
  margin-bottom: 0px !important;
}

.import-modal-height-modifier {
  // override aether modal
  height: 490px !important;
}

.import-modal-max-height-modifier {
  // override aether modal
  max-height: 100vh !important;
}

.import-modal-tooltip-banner-flex-parent {
  padding-top: 4px !important;
}

.import-modal-tooltip-banner-dismiss-button {
  position: absolute;
  right: 16px;
  top: 14px;
}

.import-modal-tooltip-banner-flex {
  margin-top: calc(var(--spacing-xs) * -1);
  margin-bottom: calc(var(--spacing-xs) * -1);
}

.import-modal-tooltip-banner button {
  bottom: var(--spacing-xs);
}

.import-modal-content-margin-fix {
  margin-left: calc(var(--spacing-xs) * -1);
  margin-right: calc(var(--spacing-xs) * -1);
}

.import-modal-content-padding-fix {
  padding-bottom: var(--spacing-xs) !important;
  padding-right: var(--spacing-l) !important;
  padding-left: var(--spacing-l) !important;
}

.import-modal-horizontal-seperator {
  width: 580px;
  border-bottom: 1px;
  border-color: var(--content-color-secondary);
  margin-left: 22px;
}

.import-modal-integration-single-repo {
  display: none;
}

.import-modal-integration-icons {
  margin-left: auto;
  align-items: center;
  margin-right: auto;
  transform: scale(1.5);
  max-width: 14px;
}

.import-modal-shortcut-helper-text {
  height: var(--spacing-xxl);
  display: flex;
  align-items: center;
}

.import-modal-shortcut-helper-button {
  padding-top: 2px !important; // important because overriding aether
  padding-bottom: 2px !important;
}

.import-modal-api-collection-preview-line {
  position: relative;
  width: 1px;
  background-color: var(--border-color-strong);
  height: 205px; /* Set the height of the line */
  margin-left: 30px;
  margin-right: 15px !important;
  margin-top: var(--spacing-xl);
}

.import-modal-api-collection-preview-line::before {
  content: "1";
  color: var(--content-color-secondary);
  position: absolute;
  width: var(--spacing-l);
  height: var(--spacing-l);
  line-height: var(--spacing-l);
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--border-color-strong);
  font-size: 11px;
  left: -9px; /* Position the dots to the left of the line */
  top: 40px;
  background-color: transparent;
}

.import-modal-api-collection-preview-line::after {
  content: "2";
  color: var(--content-color-secondary);
  position: absolute;
  width: var(--spacing-l);
  height: var(--spacing-l);
  line-height: var(--spacing-l);
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--border-color-strong);
  background-color: transparent;
  font-size: 11px;
  left: -9px; /* Position the dots to the left of the line */
}

.import-modal-api-collection-preview-line::before {
  top: calc(var(--spacing-xl) * -1);
}

.import-modal-api-collection-preview-line::after {
  bottom: calc(var(--spacing-xl) * -1);
}

.import-modal-heading-on-api {
  padding-left: var(--spacing-xl);
  padding-top: var(--spacing-xl);
  padding-bottom: 18px;
}

.import-modal-multifile-table-heading {
  color: var(--content-color-primary);
  padding-left: var(--spacing-xl);
  margin-top: var(--spacing-xs) !important;
}

.import-modal-multifile-setting-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.import-modal-multifile-setting-heading {
  color: var(--content-color-primary);
  padding-left: 10px;
}

.import-modal-multifile-setting-back-button {
  cursor: pointer;
}

.import-modal-configuration-component-container {
  overflow: scroll;
  padding: 10px;
  border: 1px solid var(--border-color-default);
  border-radius: 4px;
  overflow-x: unset;
}

.import-modal-multifile-settings-button {
  cursor: pointer;
  display: flex;
  flex-grow: 2;
  align-items: center;
}

.import-modal-multifile-settings-text {
  color: var(--content-color-secondary);
  margin-left:var(--spacing-s);
}

.import-modal-import-settings {
  align-self: flex-end;
}

.import-modal-import-settings-back-icon {
  transform: scale(1.5);
  padding-left: var(--spacing-xl);
  padding-top: 6px;
  cursor: pointer;
}

.import-modal-api-collection-preview {
  margin-top: var(--spacing-s);
  height: 0px;
  overflow: hidden;
  transition: height 0.3s;
}

.import-modal-collection-name {
  margin-top: var(--spacing-s);
}

.import-modal-api-collection-preview.expanded {
  margin-top: var(--spacing-s);
  height: 260px;
  overflow: visible;
}

.import-modal-view-import-settings {
  display: flex;
  gap: var(--spacing-xs);
}

.import-modal-view-import-settings-button-modifier {
  cursor: pointer;
}

.apm-import-multi-select-container {
  transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  height: 200px;
}

.apm-import-multi-select-container.expanded {
  height: 410px;
  overflow: visible;
  margin-bottom: 24px;
  max-width: 600px;
}

.import-modal-preview-spacing {
  padding-top: var(--spacing-xs);
}

.import-modal-preview-textarea {
  width: 535px;
}

.import-modal-loading-state-width-modifier {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.import-modal-multifile-table-name-count {
  color: var(--content-color-secondary);
  padding-right: var(--spacing-l);
}

.import-modal-multifile-definition-checkbox-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  background-color: var(--background-color-tertiary);
  line-height: var(--line-height-l);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-xs) var(--spacing-s);
  margin-right: var(--spacing-m);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
}
.import-modal-multifile-definition-checkbox-text {
  margin-left: var(--spacing-s);
}

.import-modal-multifile-table-name-type {
  color: var(--content-color-secondary);
}

.import-modal-multifile-create-definition-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.import-modal-footer-import-left-align {
  place-content: flex-start !important;
}

.import-modal-multifile-choose-options-helper-text {
  padding-top: var(--spacing-xs);
  display: flex;
}

.import-modal-multifile-choose-options-container {
  flex-grow: 1;
  padding: var(--spacing-m) var(--spacing-s);
}

.import-modal-multifile-choose-options-container-border {
  border-bottom: solid var(--border-width-default) var(--border-color-default);
}

.import-modal-multifile-table-api-option-container {
  display: inline-block;
  height: 22px;
}

.import-modal-multifile-table-api-option-text {
  margin-left: var(--spacing-s);
  padding-right: var(--spacing-xs);
}

.import-modal-multifile-table-api-option-cell {
  margin-left: var(--spacing-s);
}

.import-modal-multifile-table-container {
  max-height: 33vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.import-loading-screen_container {
  padding: var(--spacing-s);
  padding-left: var(--spacing-l);

  .import-loading-screen_message {
    padding: var(--spacing-s);
  }

  .import-loading-screen_left-border {
    padding-left: var(--spacing-xl);
    border-left: var(--border-width-default) solid var(--highlight-background-color-primary);
    margin-left: var(--spacing-xs);
    margin-bottom: var(--spacing-l);
    color: var(--content-color-secondary);
  }

  .import-loading-screen_button-container {
    display: flex;
    place-content: flex-end;
    gap: var(--spacing-m);
    padding-top: var(--spacing-xl);
  }
}

.import-api-usage-banner {
  padding-top: var(--spacing-xs);
  margin-left: 22px;
}

.import-api-usage-radiobutton-info-icon {
  margin-top: var(--spacing-xs);
}
