.workspace-filter {
  margin: 8px 0;

  .workspace-filter__text-container {
    user-select: none;
    display: flex;

    .workspace-filter__text-container--filter-by {
      display: flex;
      align-items: center;
      cursor: pointer;

      .workspace-filter__text-container-title {
        color: var(--content-color-secondary);
        font-weight: 600;
      }

      .workspace-filter__text-container-title--icon {
        margin-left: var(--spacing-xs);
        display: flex;
      }
    }

    .workspace-filter__text-container--clear {
      color: var(--content-color-secondary);
      font-size: 11px;
      margin-left: 16px;
      cursor: pointer;
    }

    .workspace-filter__text-container--clear--highlight {
      color: var(--content-color-brand);
    }

    .dropdown-button {
      line-height: 0;
    }
  }

  .workspace-filter__filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 8px;
    user-select: none;
    min-height: 30px;

    .workspace-filter__filters-ws {
      display: flex;

      .workspace-filter__filters-ws__title {
        color: var(--content-color-secondary);
        font-size: 11px;
        font-weight: 600;
      }

      .workspace-filter__filters-ws-types {
        display: flex;
        color: var(--content-color-secondary);

        .workspace-filter__filters-ws-type {
          display: flex;
          margin: 0 8px;
          cursor: pointer;

          .workspace-filter__filters-ws-type-name {
            margin-left: 8px;
          }
        }
      }
    }

    .workspace-filter__separator {
      height: 24px;
      width: 1px;
      background-color: var(--border-color-strong);
      margin: 0 8px;
    }

    .workspace-filter__people-container {
      display: flex;
      align-items: center;
      user-select: none;
      position: relative;
    }

    .workspace_filter__tag {
      margin: 2px;
    }
  }
}
