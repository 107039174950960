.requester-bottom-pane-header-container {
  /**
    We have picked this z-index value for the bottom pane because the following reasons:
    1. It should be greater than 1 for the status bar items to show when the bottom pane is open.
    2. It should be greater than 8 so that the .pane-resize-handle does not overlap the branch switcher.
    3. Needs to be at least 90 to allow Postbot to be expanded over the top of the app header.
  */
  z-index: 90;
}

.requester-bottom-pane-header {
  display: flex;
  align-items: center;

  .collapsedY & { // Change status bar background when collapsed
    background-color: var(--global-navigation-background-color);
  }


  .pane-header__section-left {
    flex: 1;
    display: flex;
    align-items: center;

    .tab-text-wrapper {
      padding: var(--spacing-xs) var(--spacing-zero);
    }
  }

  .pane-header__tabs {
    .tab {
      display: flex;
      align-items: center;
      font-size: var(--text-size-s);
      line-height: var(--line-height-s);

      .pm-icon {
        margin-right: var(--spacing-xs);
        @include icon-color(var(--content-color-secondary));
      }

      &.is-active,
      &:hover {
        .pm-icon {
          @include icon-color(var(--content-color-primary));
        }
      }
    }
  }

  .pane-header__options {
    margin-left: var(--spacing-s);
  }

  .pane-header__actions {
    display: flex;

    .actions__close-icon {
      margin: var(--spacing-s) var(--spacing-s) var(--spacing-xs) var(--spacing-s);
      @include icon-color(var(--content-color-secondary));
      @include clickable;
      @include hover {
        @include icon-color(var(--content-color-primary));
      }
    }
  }
}

.bottom-pane-container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.bottom-pane-tab-content {
  flex: 1 auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.bottom-pane-status-bar {
  flex-shrink: 0;
  background-color: var(--background-color-primary);
  z-index: 1;
  height: var(--size-s);
}

.bottom-pane-tab-content--console,
.bottom-pane-tab-content--find-replace {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .console-container {
    flex: 1 auto;
  }
}

.plugin__sidebar-shortcut {
  margin-right: var(--spacing-s);
  height: 20px;

  @include hover {
    background-color: transparent;
  }
}

@media only screen and (max-width: 1024px) {
  .pane-header-tabs--find-replace-label {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .pane-header-tabs--console-label {
    display: none;
  }
}
