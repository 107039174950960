/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$material-shadow-right-color: rgba(0, 0, 0, 0.20) !default;

@mixin material-shadow-1 {
  box-shadow: var(--shadow-default);
}

@mixin material-shadow-bottom-1 {
  box-shadow: var(--shadow-default);
}

@mixin material-shadow-right-1 {
  box-shadow: 1px 0 4px 0 $material-shadow-right-color;
}

@mixin aether-popover-shadow {
  box-shadow: var(--aether-popover-shadow);
}
