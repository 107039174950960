.report-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--background-color-tertiary);
}

.report-view-section {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-xxl);

  &-wrapper {
    width: 100%;
    flex: 1;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  &--header {
    color: var(--content-color-primary);
    line-height: var(--line-height-m);
    font-size: var(--text-size-l);
    margin-bottom: var(--spacing-l);
  }

  &--tiles {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: var(--spacing-xl);
    color: var(--content-color-primary);
  }
}

.page-loader {
  display: grid;
  justify-content: center;
  margin: var(--spacing-l) var(--spacing-zero);
}

.section-loader {
  display: grid;
  justify-content: center;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.error-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;

  &-primary-text {
    margin-bottom: var(--spacing-s);
    margin-top: var(--spacing-l);
  }

  &-secondary-text {
    margin-bottom: var(--spacing-l);
  }
}

.drill-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxl);
  
  &__title {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-m);
  }

  &__redirect-text{
    margin-top: var(--spacing-xxxl);
    margin-bottom: var(--spacing-m);
  }
}
