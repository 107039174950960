.file-input {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);

  &.is-bleed {
    padding: 0;
  }

  /*
    Over-write the default behavior of native file input element
  */
  input[type=file]::-webkit-file-upload-button {
    width: 0px;
    height: 0px;
  }

  .file-input__fake {
    position: relative;
    height: 20px;
    min-width: 0;
    @include text-truncate-ellipsis;
    @include clickable;

    .file-input__fake-loading {
      transform: scale(0.6);
      margin: var(--spacing-zero) var(--spacing-xl);
    }
  }

  .file-input__native {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
    pointer-events: none;

    &:focus + .file-input__fake{
      background-color: var(--highlight-background-color-tertiary);
    }
  }

  .file-input__name {
    box-sizing: border-box;
    display: flex;
    min-width: 0;
    height: 20px;
    align-items: center;
    border-radius: var(--border-radius-default);
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    font-size: var(--text-size-m);
    font-family: var(--text-family-default);
    color: var(--content-color-primary);
    background-color: transparent;
    padding: var(--spacing-xs) 2px;

    &.show-focus {
      @include focus {
        border-color: var(--border-color-strong);
      }
    }

    .file-input__name__title {
      @include text-truncate-ellipsis;
    }

    .file-input__name__delete-icon {
      display: flex;
      align-items: center;
      margin-left: var(--spacing-s);
      padding-right: 2px;
      @include clickable;
    }
  }
}

.file-input-container {
  max-width: 100%;

  .tooltip {
    .tooltip-wrapper {
      padding: var(--spacing-l);
    }

    .tooltip__header {
      color: var(--content-color-primary);
    }

    .tooltip__body {
      margin-top: var(--spacing-xs);
      max-width: 250px;
    }
  }

  &__warning-icon {
    position: absolute;
    top: 10px;
    right: var(--spacing-s);
    background: unset;
    height: unset;
    width: unset;
    z-index: 1;
    cursor: default;
  }

  &.has-warning {
    margin-right: var(--spacing-xl);
  }
}
