.text {
  font-family: var(--text-family-default);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-heading-h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
}

.text-heading-h2 {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--text-weight-medium);
}

.text-heading-h3 {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--text-weight-medium);
}

.text-heading-h4 {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}

.text-heading-h5 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-heading-h6 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-body-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
}

.link-text,
.text-body-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.text-body-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
}

.text-button-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}

.text-button-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-button-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-input-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
}

.text-input-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.text-input-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
}

.text-input-xSmall {
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
}

.text-label-primary-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-primary-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-primary-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-label-secondary-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-secondary-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-secondary-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-status-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}

.text-status-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-status-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-status-xSmall {
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-code-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-code);
}

.text-code-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-code);
}

.link-text {
  color: var(--content-color-link);
  @include icon-color(var(--content-color-link));
  cursor: pointer;
  &:hover {
    color: var(--content-color-link);
    @include icon-color(var(--content-color-link));
  }
}
