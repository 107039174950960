
.collection-browser-list-item__folder {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  @include hover {
    background-color: $collection-browser-list-item-head--hover-background-color;
  }
}

