
.env-dropdown-menu {
  min-width: 200px;

    .dropdown-menu-item {
      padding: 0 var(--spacing-l);

      .dropdown-menu-item-icon {
        margin-right: var(--spacing-s);
      }
    }

    .env-action-item {
      display: flex;
      flex: 1;
    }
}

.env-actions-icon-container {
  .btn {
    display: none;
  }

  &.isHovered {
    .active-env-icon {
      display: none;
    }
    .inactive-env-icon {
      display: flex;
      @include icon-color(var(--content-color-secondary));
    }
  }

  &.isActive {
    .active-env-icon {
      display: flex;
    }
    .inactive-env-icon {
      display: none;
    }
  }

  &:hover {
    .inactive-env-icon {
      @include icon-color(var(--content-color-primary));
    }
  }
}

.env-actions__more-actions-placeholder {
  height: var(--size-s);
  width: var(--size-s);
  background-color: transparent;
}

.environment-sidebar__indent {
  width: var(--spacing-m);
}

.api-sidebar-list {
  .environment-sidebar-item {
    .sidebar-list-item__icon-container {
      margin-left: 36px;
    }
  }
}

