//Create new public workspace modal styles

.create-new-public-workspace-modal {

  .modal-header {
    background-color: var(--background-color-primary);
    height: calc(2*var(--size-m));

    .modal-header-title {
      padding: var(--spacing-m) var(--spacing-xl);
      color: var(--content-color-primary);
    }
  }

  &__create-workspace-label {
    color: var(--content-color-secondary);
    margin: var(--spacing-zero);
  }

  &__content {
    padding: var(--spacing-zero) var(--spacing-xl) var(--spacing-zero) var(--spacing-xl);
  }

  &__input-group {

    &__loading-indicator {
      height: var(--size-m);

      .loading-indicator {
        top: 15%;
      }
    }

    &__label {
      font-weight: var(--text-weight-medium);
      color: var(--content-color-secondary);
      font-family: var(--text-family-default);
      font-style: normal;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      margin: var(--spacing-l) var(--spacing-zero) var(--spacing-xs);
    }

    &__visibility {
      margin-bottom: var(--spacing-l);
      margin-top: var(--spacing-l);
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: calc(2*var(--spacing-xxl));
    }

    &__cancel-button {
      margin-right: var(--spacing-s);
    }

    &--error {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing-l);
      color: var(--content-color-error);

      .pm-icon {
        margin-right: var(--spacing-s);
        @include icon-color(var(--content-color-error));
      }
    }
  }
}
