.empty-greeting-shell {
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: fade 1s ease-in-out infinite;
  overflow: hidden;
  margin-bottom: var(--spacing-l);

  &__title {
    height: var(--size-s);
    width: calc(6 * var(--size-m));
    border-radius: var(--border-radius-default);
    background-color: var(--background-color-tertiary);
  }

  &__subText {
    height: 12px;
    width: 350px;
    border-radius: var(--border-radius-default);
    margin-top: 12px;
    background-color: var(--background-color-tertiary);
  }

  @keyframes fade {
    0% {
      opacity: 0.25;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.25;
    }
  }
}
