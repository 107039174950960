.share-to-workspace-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-xxl);

  .share-to-workspace-heading {
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    font-family: var(--text-family-default);
    letter-spacing: -0.12px;
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-l);
  }

  .share-to-workspace-permissions-banner {
    margin-bottom: var(--spacing-l);
  }

  .share-to-workspace-optional {
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
  }

  .share-to-workspace-label {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s)
      var(--spacing-zero);
    color: var(--content-color-secondary);
  }

  .share-to-workspace-dropdown-and-text {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .workspace-helper-text {
      display: inline-block;
      // This width comes from how we’ve structured the two columns in the publish view.
      width: 48%;
      position: relative;
      top: -4px;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
    }

    .public-workspace-dropdown {
      display: inline-block;
      // This width comes from how we’ve structured the two columns in the publish view.
      width: 48%;
      box-sizing: border-box;
      border-radius: var(--border-radius-default);
      height: var(--size-m);

      .input-select-wrapper {
        background-color: var(--background-color-primary) !important;
      }

      .select-dropdown-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: var(--input-height);
        color: var(--content-color-primary);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);

        i > svg > path {
          fill: var(--content-color-secondary);
        }
        i > svg {
          color: var(--content-color-secondary);
        }
      }

      .dropdown {
        width: 100%;

        .btn-group-separated {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .btn-text {
            border: none;
          }
        }
      }
    }
  }
}

.share-to-workspace-dropdown {
  --publish-page-dropdown-max-height: 250px;
  --dropdown-max-width: calc(750px * 0.48);

  .documentation-dropdown-menu-container,
  .dropdown-menu {
    max-height: var(--publish-page-dropdown-max-height) !important;
    // The dropdown component is not, hierarchy wise, inside the parent container.
    // As a result, we have to give it width based on the parent separately.
    // max-width of root=750px, max-width of parent=60%, max-width of parent 80%
    max-width: var(--dropdown-max-width);
    // max-width of parent=60%, max-width of parent 48%, 0.6*0.48=0.288
    width: 28.8%;
    overflow: auto;
    background-color: var(--background-color-primary);

    .input-search-group {
      background-color: var(--background-color-primary);
    }

    .select-dropdown {
      width: unset;
    }

    .dropdown-menu-item {
      &.is-disabled {
        opacity: 1;

        .doc-workspace-list-item__name,
        i {
          opacity: 0.4;
        }

        &:hover {
          background-color: var(--background-color-primary) !important;
        }
      }

      .is-hovered {
        background-color: var(--highlight-background-color-primary);
      }

      .documentation-dropdown-item {
        width: 100%;
        padding: 0;

        span,
        .doc-workspace__join {
          font-weight: var(--text-weight-medium);
          color: var(--content-color-secondary);
        }
      }
    }
  }
}

.share-to-workspace-empty-dropdown {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-xxxl) var(--spacing-s);
  align-items: center;
  justify-content: space-evenly;

  > svg {
    height: 44px;
    width: 44px;
  }

  > p {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    color: var(--content-color-primary);
    margin-top: var(--spacing-xl);
  }
}

.documentation-published-status-body {
  &--workspace-list {
    display: flex;
    align-items: center;

    a {
      margin-left: var(--spacing-s);
      color: var(--content-color-primary);

      &:hover {
        color: var(--content-color-link);
        text-decoration: none;
      }
    }
  }
}

// INPUT SELECT DROPDOWN
.share-to-public-workspace-dropdown {
  display: flex;
  flex-direction: row;
  height: 284px;
}

.share-to-public-workspace-dropdown-menu {
  background-color: var(--background-color-primary);
  max-height: 322px;
  padding: var(--spacing-xs) var(--spacing-zero);
  box-sizing: border-box;
  min-width: 170px;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--hairline-strong--shade--one);
  box-shadow: var(--shadow-default);
  padding-bottom: var(--spacing-zero);;

  &__spinner {
    display: flex;
    justify-content: center;
    margin-bottom: var(--spacing-xs);
  }

  &__list {
    height: calc(8 * var(--size-m));
    overflow-y: scroll;
    border-bottom: var(--border-width-default) var(--border-style-solid)
      var(--background-color-primary);

    &__no-results {
      color: var(--content-color-secondary);
      font-family: var(--text-family-default);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      padding: 6px var(--spacing-l) 6px var(--spacing-l);
    }

    &__button {
      cursor: pointer;
      width: 100%;
      background-color: var(--background-color-primary);
      height: var(--size-m);
      box-shadow: inset 0px 1px 0px var(--highlight-background-color-tertiary);
      border-radius: 0px 0px 3px 3px;
      color: var(--content-color-secondary);
      font-family: var(--text-family-default);
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      justify-content: start;
      padding: var(--spacing-s) var(--spacing-zero) var(--spacing-s) 18px;

      &.btn.is-disabled {
        color: var(--content-color-secondary) !important;
      }

      &:hover {
        color: var(--content-color-brand);
      }
    }
  }
}

.share-to-public-workspace-dropdown-item {
  background-color: var(--background-color-primary);
}

// dropdown menu starts here
.input-select-wrapper {
  background-color: var(--background-color-tertiary);
  border: var(--border-width-default) var(--border-style-solid)
    var(--border-color-default);
  border-radius: var(--border-radius-default);
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: var(--controls-size-default);
  position: relative;
  width: 210px;

  &.is-disabled {
    opacity: 0.4;
    cursor: not-allowed;
    .input {
      cursor: not-allowed;
    }
  }

  &.highlight {
    background-color: var(--highlight-background-color-secondary);
  }
  @include hover {
    background-color: var(--highlight-background-color-secondary);
  }
  &.is-open {
    background-color: var(--background-color-primary);
    border: var(--border-width-default) var(--border-style-solid)
      var(--border-color-strong);
  }

  .input-search-group {
    flex: 1;
    background: transparent;
    border: 0;
    border-radius: 0;
    padding-right: var(--spacing-zero);

    .input {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
      @include placeholder {
        font-size: var(--text-size-m);
      }
    }
  }

  .dropdown-button {
    align-self: center;
    border-left: 0;
    background: transparent;
    border-radius: 0;
    flex: 0 0 30px;
    height: var(--controls-size-default);
    margin-left: auto;
    padding: var(--spacing-zero);

    .dropdown-caret {
      margin-left: var(--spacing-zero);
    }
  }
}

.input-select-dropdown-menu {
  .dropdown-menu-item {
    cursor: pointer;

    &.is-disabled {
      opacity: 1;

      .doc-workspace-list-item__name,
      i {
        opacity: 0.4;
      }

      &:hover {
        background-color: var(--background-color-primary) !important;
      }
    }

    .is-hovered {
      background-color: var(--highlight-background-color-primary);
    }

    &.is-selected {
      background-color: var(--highlight-background-color-primary);
    }

    .input-select-item {
      @include text-truncate-ellipsis;
      flex: 1;
    }
    .item-wrapper {
      overflow: hidden;
      display: flex;
      flex: 1;
      align-items: center;
      .item-label {
        @include text-truncate-ellipsis;
        flex: 1;
      }
      .item-actions {
        visibility: hidden;
        display: flex;

        .item-action {
          display: flex;
          justify-content: center;
          align-items: center;
          height: var(--size-m);
          width: var(--size-m);
          @include hover {
            @include icon-color(var(--content-color-brand));
          }
        }
      }
    }
    @include hover {
      .item-wrapper {
        .item-actions {
          visibility: visible;
        }
      }
    }

    &.is-selected {
      .highlight {
        color: var(--content-color-brand);
      }
    }

    &.dropdown-menu-item--save_method {
      border-top: var(--border-width-default) var(--border-style-solid)
        var(--border-color-strong);
    }

    &.dropdown-menu-item--no_result {
      color: var(--content-color-tertiary);
      cursor: default;
      @include hover {
        background-color: inherit;
      }
      &.is-focused {
        background-color: inherit;
      }
    }

    &.dropdown-menu-item--no_methods {
      color: var(--content-color-tertiary);
      cursor: default;
      @include hover {
        background-color: inherit;
      }
      &.is-focused {
        background-color: inherit;
      }
    }
  }
}
