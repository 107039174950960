$response-body-viewer-tab-text-font-size: $tab-text-font-size !default;

.response-cannot-render-message {
  padding: var(--spacing-l);
  text-align: center;
}

.response-body-text-viewer, // TODO: [APICLIENT-370] Remove when removing older response viewer
.response-body-media-viewer {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;

  &.disabled,
  &.waiting {
    pointer-events: none;
    opacity: 0.2;
  }
}

.response-body-viewer-tab-contents {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.response-body-viewer-tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.is-hidden {
    display: none;
  }
}

.response-body-pretty-viewer {
  flex: 1;
  display: flex;
  min-height: 0;

  .editor {
    flex: 1;
  }
}

.response-body-raw-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;

  .textarea-wrapper {
    display: flex;
    flex: 1;
  }

  .textarea {
    flex: 1;
    resize: none;
  }
}

.response-body-visualizer-empty {
  display: flex;
  margin: auto var(--spacing-zero);
  flex-direction: column;
  align-items: center;
  color: var(--content-color-primary);
  text-align: center;
  gap: var(--spacing-s);

  @include unselectable;

  &__description {
    max-width: 470px;
  }

}

.response-body-iframe-viewer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--content-color-constant);
  border-radius: var(--border-radius-default);
  min-height: 0;

  .response-body-viewer-preview {
    flex: 1;
    width: 100%;
    border: 0;
    min-height: 0;
  }
}

.response-visualizer-viewer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  .response-visualizer-webview-viewer__webview {
    flex: 1;
    border: 0;
  }
}
