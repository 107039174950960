.addrequest-top-container-modal,
.save-multiple-requests-modal {

  .addrequest-top-container{
    height: 70vh;
    display: flex;
    flex-direction: column;

    .addrequest-collection-selector-row{
      flex: 1;
      min-height: 0;

      .collection-explorer{
        height: 100%;

        .explorer-row__loading_icon{
          margin-right: var(--spacing-s)
        }
      }
    }
  }
}

.addrequest-top-container {
  .row {
    padding: 7px 0;
  }

  .add-request-helptext {
    padding-bottom: 20px;
    .btn {
      padding: 0px;
    }
  }

  .addrequest-top-container-name-row {
    padding-top: 0px;
  }

  .field-label {
    margin-bottom: var(--spacing-s);
  }

  .addrequest-description-row{
    color: var(--content-color-tertiary);

    .add-description-button{
      margin-bottom: var(--spacing-m);
    }
  }
}

.addrequest-collection-name-container,
.addrequest-collection-selector-row {
  .input-search {
    font-size: 12px;
  }
  .addrequest-new-collection-name-row {
    padding: 20px 0 0 0;
  }
  .addrequest-collection-selector-row {
    padding: 0;

    .field-label {
      text-align: center;
      position: relative;
      display: flex;
      flex: 1;
      margin-bottom: 10px;
      background-color: var(--background-color-primary);
    }


  }
}

.addrequest-top-container-name-row{
  .addrequest__request-name{
    &:not(:focus-within){
      &:not(.error){
        border-color: var(--border-color-default);
      }
      background-color: var(--background-color-secondary);
    }
  }
}

.addrequest-request-description-editor {
  height: 70px;

  .text-editor .monaco-editor {
    .margin{
      background-color: var(--background-color-secondary);
    }

    .monaco-editor-background {
      background-color: var(--background-color-secondary);
    }

    &.focused {
      .monaco-editor-background {
        background-color: var(--background-color-primary);
      }
    }

    .monaco-placeholder {
      color: var(--content-color-secondary);
    }
  }
}

.save-request-modal,
.save-multiple-requests-modal {
  .modal-footer .btn-primary .content {
    max-width: 200px;
    @include text-truncate-ellipsis;
  }

  .modal-footer{
    .action-button-wrapper{
      .save-button{
        margin-left: var(--spacing-m);
      }

      .cancel-button{
        margin-top: var(--spacing-zero);
      }
    }

    .create-button-wrapper{
      display: flex;
      margin-right: auto;
      margin-left: var(--spacing-xs);
    }
  }
}

.save-multiple-requests-modal {

      .field-label {
        margin-bottom: 10px;
      }

}
