.homepage-team-apis {
  margin-bottom: var(--spacing-xxl);

  &-header {
    display: flex;

    &-title {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-xxl);
      line-height: var(--line-height-xxl);
      margin: var(--spacing-zero);
      color: var(--content-color-primary);
    }

    &-explore {
      background: none;
      border: none;
      align-self: center;
      color: var(--content-color-link);
      margin-left: var(--spacing-l);
      font-size: 11px;
      line-height: 12px;

      i {
        vertical-align: text-bottom;
        margin-left: var(--spacing-s);
        svg {
          use {
            fill: var(--content-color-link);
          }
          color: var(--content-color-link);
        }
      }
    }
  }

  &-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat( auto-fill, 260px);
    grid-row: auto;
    grid-column-gap: var(--spacing-xxl);
    grid-row-gap: var(--spacing-l);
    margin-top: var(--spacing-l);

    &__card {
      box-sizing: border-box;
      border: 0.5px solid var(--border-color-default);
      min-height: 160px;
      border-radius: 4px;
      padding: 20px 24px;
      @include clickable;

      &:hover {
        background-color: var(--background-color-secondary);
      }

      &-name {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
        margin: 0 0 4px 0;
        word-break: break-word;
        display: -webkit-box;
        color: var(--content-color-primary);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &-metric {
        display: flex;
        margin-bottom: 8px;

        &-versions {
          color: var(--content-color-tertiary);
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          margin: 0 var(--spacing-l) 0 0;
        }

        &-views {
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          color: var(--content-color-tertiary);
          margin: 0;
        }
      }

      &-description {
        color: var(--content-color-secondary);
        overflow: hidden;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        margin: 0;
        display: -webkit-box;
        word-break: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
