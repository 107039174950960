/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$tooltip-background-color: var(--background-color-secondary);

$tooltip-max-width: 300px;
$tooltip-max-height: 500px;
$tooltip-arrow-negative-margin: -5px;
$tooltip-arrow-wrapper-negative-margin: -7px;
$tooltip-negative-margin-size: -3px;
$tooltip-margin-size: 3px;
$tooltip-arrow-wrapper-color: rgba(0, 0, 0, 0.08);


.tooltip {
  position: absolute;
  z-index: 1010;
  display: block;
  font-family: var(--text-family-default);
  margin: var(--spacing-zero);
  opacity: 0;
  max-width: $tooltip-max-width;
  padding: var(--spacing-zero) var(--spacing-xs);
  word-wrap: break-word;

  // this attribute is added when the target element for the tooltip gets out of
  // the boundaries provided. this is used to hide the tooltip when target is not visible
  // refer: https://popper.js.org/docs/v1/#modifiershide
  &[data-x-out-of-boundaries]{
    display: none;
  }
}

.tooltip.show {
  opacity: 1;
}

.tooltip-wrapper {
  max-height: $tooltip-max-height;
  overflow-y: auto;
  padding: var(--spacing-s);
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--tooltip-border-color);
  @include material-shadow-1;
}

.tooltip-header {
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
  margin-bottom: var(--spacing-m);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.tooltip-body {
  font-size: var(--text-size-m);
  .tooltip-body-title {
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    margin: var(--spacing-s);
  }
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^='top'] {
  padding: var(--spacing-xs) var(--spacing-zero);
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
  filter: drop-shadow(-2px 2px 2px rgba(0,0,0,.2));
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--background-color-secondary);
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^='right'] {
  padding: 0 var(--spacing-xs);
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
  filter: drop-shadow(-2px 2px 2px rgba(0,0,0,.2));
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--background-color-secondary);
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^='bottom'] {
  padding: var(--spacing-xs) 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
  filter: drop-shadow(-2px -2px 2px rgba(0,0,0,.2));
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--background-color-secondary);
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^='left'] {
  padding: 0 var(--spacing-xs);
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
  filter: drop-shadow(2px -2px 1px rgba(0, 0, 0, 0.08));
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--background-color-secondary);
}
