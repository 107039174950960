$history-sidebar-list-item-label-wrapper-width: 50px;

.history-sidebar-list-item {
  display: flex;
  flex-direction: row;
  position: relative;

  .history-sidebar-list-item-updatedBy {
    align-items: center;
    display: flex;
    height: var(--size-xs);
    width: var(--size-xs);
  }

  .sidebar-list-item__icon-container {
    min-width: 30px;
  }

  .history-sidebar-list-item__label-wrapper {
    display: flex;
    flex: 0 0 $history-sidebar-list-item-label-wrapper-width;
    justify-content: center;
    align-items: center;
  }

  // TODO: Remove this
  .history-sidebar-list-item__meta {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .history-sidebar-list-item__actions {
    position: absolute;
    height: 100%;
    justify-content: space-around;
    right: 0px;
    background-color: inherit;
  }

  @include hover {
    .history-sidebar-list-item__actions {
      display: flex;
      justify-content: space-around;
      .btn {
        background-color: transparent;
        @include hover {
          background-color: transparent;
        }
      }
    }
  }

  &.is-hidden {
    display: none;
  }

  @include unselectable;
  @include clickable;
}

.history-sidebar-list-item {
  max-height: 60px;
  -webkit-line-clamp: 3;
}

.history-sidebar-list-item__actions {
  display: none;

  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.history-sidebar-list-item__button__options {
  @include icon-color(var(--content-color-secondary));
  &:hover {
    @include icon-color(var(--content-color-primary));
  }
}

.history-sidebar-list-empty-item {
  font-family: $font-primary;
  font-size: 12px;
  padding: 20px 15px;
  color: var(--content-color-secondary);
  text-align: center;

  .entity-empty {
    border: none;

    .entity-empty__title {
      font-size: 14px;
      margin-bottom: 16px;
    }

    .entity-empty__subtitle {
      margin-bottom: 24px;
    }

    .entity-empty__title,
    .entity-empty__subtitle {
      @include unselectable;
    }

    .entity-empty__learn {
      .entity-empty__learn__mouse-click-icon {
        padding-right: 8px;
      }
    }
  }
}

.history-sidebar-list-item__request,
.history-sidebar-list-item__collection,
.history-sidebar-list-item__proxy-history{
    &.sidebar-list-item {
      height: 100%;
      min-height: var(--size-s);
  }

  .sidebar-list-item__actions-container {
    background-color: inherit;
    height: 100%;
  }

  .sidebar-list-item__content-wrapper {
    height: 100%;
    min-height: var(--size-xs);
  }

  .sidebar-list-item__name-wrapper {
    display: inline-flex;
    align-items:center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
  }
  .sidebar-list-item__color-proxy{
    color: var(--content-color-success)
  }
}

.history-sidebar-list-item-avatar-tooltip {
  display: flex;
  flex-direction: column;
}
