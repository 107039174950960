.activity-item-details-content {
  &.normal-view {
    display: inline-flex;
    align-items: center;
  }
  margin: 0 10px 10px;
  font-size: 11px;
  line-height: 12px;
}

.activity-item-details-content-left {
  margin-right: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-self: baseline;
}

.activity-item-details-content-right {
  font-style: normal;
  color: $activity-item-details-right-text-color;
}

.activity-item-details-content-data {
  font-style: italic;;
}

.activity-item-details-diff-view {
  margin-top: 10px;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);

  .diff-wrapper {
    max-height: 300px;
  }
}
