// this value depends on the width of "data file type" field width. verify that
// it does not break when changing this.
$runner-runs-selector-min-width: 350px;
$runner-runs-selector-meta-width: 100px;
$runner-runs-selector-meta-margin: 8px;
$runner-runs-selector-input-max-width: 300px;

$runner-runs-customizer-min-width: 330px;

.runner-runs {
  display: flex;
  height: 100%;
  overflow-x: auto;

  .runner-runs-selector {
    flex: 1 0 $runner-runs-selector-min-width;
    margin-top: 40px;

    .runner-runs-selector__item--environment,
    .runner-runs-selector__item--iterations,
    .runner-runs-selector__item--delay,
    .runner-runs-selector__item--data-file,
    .runner-runs-selector__item--data-file-type,
    .runner-runs-selector__item--persist,
    .runner-runs-selector__item--customize,
    .runner-runs-selector__item--initial-cookies,
    .runner-runs-selector__item--save-cookies,
    .runner-runs-selector__item--response {
      display: flex;
      align-items: center;
      text-align: left;
      position: relative;

      .input-field {
        flex: 1;
        max-width: $runner-runs-selector-input-max-width;
      }
    }

    .runner-runs-selector__meta--persist,
    .runner-runs-selector__meta--response,
    .runner-runs-selector__meta--customize,
    .runner-runs-selector__meta--initial-cookies,
    .runner-runs-selector__meta--save-cookies {
      display: flex;
      justify-content: flex-end;
    }

    .runner-runs-selector__environment {
      flex: 1;

      .input-search {
        position: relative;
      }
    }

    .runner-runs-selector__meta {
      flex: 0 0 $runner-runs-selector-meta-width;
    }
  }

  .runner-runs-customizer__emptystate {
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-image: repeating-linear-gradient(45deg, var(--border-color-default) 0, var(--border-color-default) 5px, transparent 5px, transparent 10px) 1;
    border-radius: var(--border-radius-default);
  }

  .runner-runs-customizer__non-emptystate {
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-radius: var(--border-radius-default);
  }

  .runner-runs-customizer {
    display: flex;
    flex-direction: column;
    flex: 1 0 $runner-runs-customizer-min-width;
    position: relative;
    margin: var(--spacing-l) var(--spacing-zero) var(--spacing-l) var(--spacing-l);
    padding: var(--spacing-l);
    padding-left: var(--spacing-s);
    @include unselectable;

    .runner-runs-customizer__header {
      margin-bottom: var(--spacing-l);
      display: flex;
      justify-content: space-between;
      padding-left: var(--spacing-s);

      .runner-runs-customizer__header-buttons {
        display: flex;
        align-items: center;

        .button {
          padding: var(--spacing-zero) var(--spacing-xs);
          font-weight: var(--text-weight-regular);
          color: var(--content-color-secondary);

          @include hover {
            color: var(--content-color-primary);
          }
        }

        .separator {
          margin: var(--spacing-zero) var(--spacing-xs);
          background-color: var(--border-color-default);
          height: var(--size-xs);
          width: var(--border-width-default);
        }

        .close {
          padding: var(--spacing-zero) var(--spacing-xs);
          margin-left: var(--spaing-xl);

          cursor: pointer;
          @include unselectable;
          @include clickable;
        }
      }
    }

    .runner-runs-customizer__container {
      flex: 1;
      display: flex;
      background-color: var(--background-color-primary);
      contain: strict;
      min-height: 200px;

      .runner-runs-customizer__container--empty {
        margin: auto;
        max-width: 310px;
        text-align: center;
        color: var(--content-color-tertiary);
      }

      .runner-runs-customizer__container--hydrating {
        margin: auto;
      }

      .runner-runs-customizer__list-layout {
        display: flex;
        width: 100%; 
        align-items: center;
        gap: var(--spacing-xs);

        .runner-runs-customizer__list-item {
          flex-grow: 1;
        }
      }

      .runner-runs-customizer__list-item  {
        :hover:not(div[data-aether-id=aether-checkbox]):not(i):not(svg) {
            background-color: var(--background-color-secondary);
        }

        .runner-runs-customizer__list {
          margin-left: 24px;
        }
      }

      .runner-runs-customizer__list {
        flex:1;

        .runner-runs-customizer__list-item__row {
          height: var(--size-m);
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 4px;

          .customizer__sort-icon {
            opacity: 0;
            padding: 0 4px;
            cursor: -webkit-grab;
          }

          .customizer__row_check,
          .customizer__row_request-icon,
          .customizer__row_folder-icon,
          .customizer__row_name {
            margin: 0 4px;
          }

          .customizer__row_folder-icon {
            @include icon-color($runner-color--tertiary);
          }

          .customizer__row_request-icon {
            width: 32px;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            color: $runner-color--secondary;
          }

          .request-method--GET {
            color: $request-method--GET-color;
          }

          .request-method--PUT {
            color: $request-method--PUT-color;
          }

          .request-method--POST {
            color: $request-method--POST-color;
          }

          .request-method--PATCH {
            color: $request-method--PATCH-color;
          }

          .request-method--OPTIONS {
            color: $request-method--OPTIONS-color;
          }

          .request-method--HEAD {
            color: $request-method--HEAD-color;
          }

          .request-method--PATCH {
            color: $request-method--PATCH-color;
          }

          .request-method--DELETE {
            color: $request-method--DELETE-color;
          }

          .customizer__row__parents {
            display: flex;
            margin-left: 4px;

            .customizer__row_icons {
              display: flex;
              align-items: center;
            }
          }

          .customizer__row_name {
            flex: 1;
            white-space: nowrap;
          }

          &.is-dragged {
            opacity: 0.1;
          }

          &.is-drop-hovered-top {
            position: relative;
            box-shadow: 0 2px 0 var(--base-color-brand) inset;
          }

          &.is-drop-hovered-bottom {
            position: relative;
            box-shadow: 0 2px 0 var(--base-color-brand);
          }

          @include hover {
            .customizer__sort-icon {
              opacity: 1;

              @include disabled {
                opacity: 0;
                cursor: default;
              }
            }
          }
        }
      }

      @include disabled {
        opacity: 0.1;
        cursor: default;

        .runner-runs-customizer__list-item__row {
          @include hover {
            .customizer__sort-icon {
              opacity: 0;
              cursor: default;
            }
          }
        }
      }
    }

    &__history-import-link {
      margin-top: 24px;
    }

    &__history-import-button {
      padding: var(--spacing-xs);
      font-weight: var(--text-weight-regular);
    }

    .runner-runs-customizer__disable-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-weight: var(--text-weight-medium);
          font-size: 14px;
          line-height: 24px;
          text-align: center;

          color: $runner-color--primary;
        }

        .description {
          width: 360px;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          margin-top: 8px;

          color: $runner-color--primary;
        }

        .button {
          width: 240px;
          height: 32px;
          font-weight: 600;
          line-height: 20px;
          font-size: 12px;
          margin-top: 24px;
        }
      }
    }
  }

  .runner-recent-runs {
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;

    .runner-recent__onboarding-recommendation-viewer {
      padding: 16px;
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }

    .runner-recent-runs__header {
      display: flex;

      .runner-recent-runs__header__section-left {
        display: flex;

        .runner-recent-runs__header__tabs {
          display: flex;
          flex: 0 0 100px;

          .runner-recent-runs__header__tab {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1
          }
        }
      }

      .runner-recent-runs__header__section-right {
        display: flex;
        flex: 1;
        position: relative;
      }

      .runner-recent-runs__header__section-right {
        justify-content: flex-end;

        .input-search-group {
          flex: 0.5;
          min-width: 140px;
        }

        .runner-recent-runs__header__bulk-action {
          background: none;

          &:hover {
            background: none;
          }
        }
      }
    }
  }
}

.runner-runs {
  &.is-hidden {
    display: none;
  }
}

.runner-recent-runs__runs-list {
  .loading-indicator-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .runner-recent-runs__runs-list-item {
    display: flex;

    .runner-recent-runs__runs-list-item__section-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 280px;
    }

    .runner-recent-runs__runs-list-item__section-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .runner-recent-runs__runs-list-item__indicator-wrapper {
      display: flex;
      align-items: center;
    }

    .runner-recent-runs__runs-list-item__actions {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      .runner-recent-runs__runs-list-item__action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    &.is-selected {
      background-color: var(--highlight-background-color-secondary);
    }
  }
}

.runner-runs-selector {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  overflow-y: auto;
  @include unselectable;

  // we're putting the padding in the wrapper because padding is not counted in
  // overflow in Firefox
  // See: https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  .runner-runs-selector__contents {
    padding: var(--spacing-l);
  }

  .collection-explorer {
    height: 240px;

    .explorer-row {
      .explorer-row__icon {
        margin-top: 9px;
      }
    }
  }

  .runner-runs-selector__item {
    display: flex;
    margin-bottom: var(--spacing-l);
    color: var(--content-color-primary);

    &.runner-runs-selector__item--target {
      .item {
        border-bottom: 1px var(--border-style-solid) var(--border-color-strong);
      }
    }

    .runner-runs-selector__meta {
      margin-right: $runner-runs-selector-meta-margin;
    }

    .runner-runs-selector__postfix {
      color: var(--content-color-secondary);
      margin-left: var(--spacing-s);

      .runner-runs-selector__postfix-icon{
        vertical-align: middle;
      }
    }

    .runner-runs-selector__postfix--response {
      @include hover {
        cursor: pointer;
      }
    }

    .runner-runs-selector__meta--target {
      margin-bottom: 10px;
    }

    .runner-runs-selector__item--environment {
      width: auto;
    }

    .runner-runs-selector-selected-env-label > span {
      @include text-truncate-ellipsis;
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .runner-runs-selector__item__value {
      display: flex;
      min-width: 0;
    }

    .runner-runs-selector__item__value__text {
      margin-left: 8px;
    }

    .runner-runs-selector__item__value--data-file {
      min-width: 80px;
      width: 80px;

      &.input {
        position: absolute;
        left: $runner-runs-selector-meta-width + $runner-runs-selector-meta-margin;
        opacity: 0;
      }
    }

    .runner-runs-selector__item__value--persist,
    .runner-runs-selector__item__value--response,
    .runner-runs-selector__item__value--customize,
    .runner-runs-selector__item__value--initial-cookies,
    .runner-runs-selector__item__value--save-cookies {
      cursor: pointer;
    }

    .runner-runs-selector__postfix--data-file {
      text-align: left;
      @include text-truncate-ellipsis;
    }

    .runner-runs-selector__postfix--data-file-remove {
      background-color: transparent;
    }

    .runner-runs-selector__item__value--data-file-preview {
      margin-left: 8px;
    }
  }

  .runner-runs-selector__start-test {
    flex: 1;
    background-color: $button--main-background-color;
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-zero);
    max-width: $runner-runs-selector-input-max-width;

    span {
      max-width: 200px;
      @include text-truncate-ellipsis;
    }

    @include focus {
      background-color: $button--main-background-color;
    }

    @include hover {
      background-color: $button--main--hover-background-color;
    }

    @include active {
      background-color: $button--main--active-background-color;
    }
  }
}

.runner-recent-runs {
  background-color: var(--background-color-primary);
  border-left:  var(--border-style-solid) var(--border-color-default);
  position: relative;
  @include unselectable;

  .runner-recent-runs__header {
    border-bottom: 1px var(--border-style-solid) var(--border-color-default);

    .runner-recent-runs__header__section-left {
      font-size: 12px;
      font-weight: 300;
      color: $runner-color--primary;
      align-items: center;

      .runner-recent-runs__header__tabs {
        padding: 0 10px;
      }
    }

    .runner-recent-runs__header__section-right {
      padding: 10px;
      align-items: center;

      .input-search-group {
        margin: 0 10px;
        background-color: var(--background-color-secondary);
      }

      .runner-recent-runs__header__import-selector {
        position: absolute;
        top: 10px;
        right: 93px;
        width: 100px;
        opacity: 0;
        padding-top: 30px;
      }

      .runner-recent-runs__header__import {
        width: 100px;
        font-weight: 600;
      }

      .separator {
        margin: 0 4px;
        background-color: var(--border-color-default);
        height: 16px;
        width: 1px;
      }

      .runner-recent-runs__header__delete-all {
        font-weight: 600;
      }
    }
  }
}

.runner-recent-runs__runs-list {
  overflow-y: auto;
  bottom: 0;
  width: 100%;

  .runner-recent-runs__runs-empty-list-item {
    color: $runner-color--secondary;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
  }

  .runner-recent-runs__runs-list-item {
    height: 35px;
    padding: 20px;
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    @include clickable;

    .runner-recent-runs__runs-list-item__indicator-wrapper {
      padding-right: 15px;
    }

    .runner-recent-runs__runs-list-item__indicator {
      width: 15px;
      height: 15px;
      border-radius: var(--border-radius-default);
      background-color: $runner-runs-test-color--paused;

      &.is-passed {
        background-color: var(--base-color-success);
      }

      &.is-failed,
      &.is-stopped {
        background-color: var(--base-color-error);
      }

      &.is-running {
        background-color: $runner-runs-test-color--running;
      }
    }

    .runner-recent-runs__runs-list-item__collection {
      font-size: 13px;
      font-weight: 300;
      max-width: 260px;
      color: $runner-color--primary;

      @include text-truncate-ellipsis;
    }

    .runner-recent-runs__runs-list-item__environment {
      font-size: 11px;
      color: $runner-color--secondary;
    }

    .runner-recent-runs__runs-list-item__status {
      font-size: 11px;
      font-weight: 600;
      color: $runner-color--secondary;

      &.is-passed {
        color: var(--base-color-success);
      }

      &.is-failed,
      &.is-stopped {
        color: var(--base-color-error);
      }

      &.is-running {
        color: $runner-runs-test-color--running;
      }
    }

    .runner-recent-runs__runs-list-item__date {
      font-size: 11px;
      color: $runner-color--secondary;
    }

    .runner-recent-runs__runs-list-item__action--export-icon,
    .runner-recent-runs__runs-list-item__action--delete-icon {
      display: none;
    }

    @include hover {
      background-color: $runner-runs-list-item-background-color--hover;

      .runner-recent-runs__runs-list-item__action--export-icon,
      .runner-recent-runs__runs-list-item__action--delete-icon {
        display: initial;
      }
    }
  }
}

.preview-data-header-wrapper,
.preview-data-row-wrapper {
  display: flex;
  align-items: center;

  &.highlighted-row {
    background: var(--yellow-10);
  }
}

.preview-data-header {
  flex: 0 0 150px;
  min-height: var(--spacing-xxxl);
  padding: 0px 5px;
  word-break: break-all;
  align-self: baseline;
  @include selectable;

  &.hidden {
    display: none;
  }
}

.preview-data-value {
  flex: 0 0 150px;
  min-height: 30px;
  padding: 5px;
  word-break: break-all;
  align-self: baseline;
  @include selectable;

  &.hidden {
    display: none;
  }

  &.disabled {
    color: var(--grey-40);
    align-self: auto;
    user-select: none;
  }
}

.preview-data-value:first-child,
.preview-data-header:first-child {
  flex: 0 0 65px;
}



.runner-recent-runs__runs-list-item__actions {
  .runner-recent-runs__runs-list-item__action {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    background-color: transparent;

    @include hover {
      background-color: $runner-recent-runs-action--hover-background-color;
    }

    @include active {
      background-color: $runner-recent-runs-action--active-background-color;
    }
  }
}

.runner-runs-selector__postfix-tooltip {
  margin-left: 5px;
}

.runner-runs__environment-selector-menu {
  .dropdown-menu-item .item-wrapper .item-label {
    flex: initial;

    .highlight {
      color: var(--content-color-brand)
    }
  }
}

/**
When 'use browser tabs' setting is enabled, environment selector is rendered in run screen with custom styles.
*/
.runner-runs-selector__item {
  .artemis-environment-selector {
    border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    border-radius: var(--border-radius-default);
    width: 300px;
    min-width: 210px;

    .environment-selector-container{
      margin: 0;
    }

    .input-search-group:hover, .input-search-group.is-hovered {
      border-top-left-radius: var(--border-radius-default);
      border-bottom-left-radius: var(--border-radius-default);
    }

  }

  .artemis-environment-selector:hover{
    border-color: var(--border-color-strong);
  }

  .input-select-v2-wrapper {
    width: 100%;
  }

  .runner-runs-selector__item__value {

    &.input.use-browser-tabs {
      background-color: transparent;
    }

    &.input.use-browser-tabs:hover {
      background-color: var(--highlight-background-color-secondary);
      border-color: var(--border-color-strong);
    }

    &.input.use-browser-tabs:focus {
      background-color: transparent;
    }
  }
}
