.workspace-visibility-dropdown-container {
  &__notifier {
    height: 6px;
    width: 6px;
    position: absolute;
    top: 5px;
    left: 17px;
    z-index: 1;
    background: var(--base-color-success);
    border-radius: var(--border-radius-max);
    border: var(--border-width-l) var(--border-style-solid) var(--background-color-primary);
    pointer-events: none;
  }

  &:hover {
    .workspace-visibility-dropdown-container__notifier {
      border-color: var(--border-color-default);
    }
  }

  &__header {
    padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs);
  }

  &__item {
    &__icon {
      margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xl);

      &--active {
        margin-left: var(--spacing-s);
      }
    }
  }
}
