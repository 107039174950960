.share-entity-modal-container {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);

  .share-entity-modal-container--modal-content {
    overflow-y: visible;

    margin-bottom: var(--spacing-l);

    .share-modal{
      padding: 0px 4px;
    }
  }

  .has-options{
    padding-bottom: 0px;
  }

  .infobar {
    margin: -20px -20px 0 -20px;
  }

  .move-only-infobar {
    .infobar {
      margin: -8px 0 var(--spacing-l) 0;
      padding-right: var(--spacing-l);
    }
  }

  .share-entity-modal-container--tabs {
    margin-bottom: var(--spacing-s);

    :first-child {
      .tab-text-wrapper {
        margin-left: 0;
      }
    }
    + .infobar {
      margin-top: -16px;
    }
  }

  .share-entity-modal-container--action {
    margin-left: 8px;
  }

  .share-entity-modal-container--infobar-icon {
    margin-right: var(--spacing-s);
    @include icon-color(var(--base-color-error));

    &.is-offline {
      display: flex;
      @include icon-color(var(--base-color-warning));
    }
  }

  .share-entity-modal-container--move-only-infobar-icon {
    margin-right: var(--spacing-s);
  }

  &__header {
    flex-grow: 0;
  }

  &__subheading {
    margin: var(--spacing-xl);
    margin-top: -8px;
  }

  &__via-workspace {
    padding-top: var(--spacing-l);

    &__heading {
      margin-top: var(--spacing-l);
    }

    &__link {
      margin-top: var(--spacing-s);

      &__input {
        input {
          color: transparent;
          text-shadow: 0 0 0 var(--content-color-primary);
        }
      }
    }

    &__move-button {
     margin-left: var(--spacing-xs);
     font-size: var(--text-size-s);
    }

    &__move-section {
      padding-top: var(--spacing-s);
      margin-top: var(--spacing-s);
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }
  }
}
