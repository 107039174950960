.inline-editor-wrapper {
  .inline-editor__text-editor-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    // If the editor container is shrinked horizontally, the text-editor was not getting resized
    // accordingly in Firefox. This fixes the resizing issue.
    min-width: 0;

    .inline-editor__text-editor-body {
      position: relative;
    }

    .inline-editor__text-editor-monaco {
      border-radius: var(--border-radius-default);

      .overflow-guard {
        border-radius: var(--border-radius-default);
      }

      .monaco-placeholder {
        color:var(--content-color-secondary);
        font-size: var(--text-size-xs);
        opacity: 0.6;
        cursor: text;
        line-height: var(--line-height-s);
      }
    }

    .inline-editor__text-editor {
      .ace_active-line {
        background: none;
      }
      min-height: 80px;
    }
  }

  .inline-editor__text-editor-footer {
    display: flex;
    justify-content: space-between;

    .inline-editor__help-text {
      font-size: var(--text-size-xs);
      color: var(--content-color-tertiary);
      line-height: var(--text-size-xxxl);
    }
  }

  .inline-editor__actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  }

  .inline-editor__cancel-button {
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
    font-family: var(--text-family-default);
    padding: var(--spacing-s);
    text-align: center;
    width: 50px;

    @include unselectable;
    @include clickable;

    @include hover {
      color: var(--content-color-primary);
    }
  }

  .inline-editor__update-button {
    min-width: 65px;
  }
}

.inline-editor-text-wrapper-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.inline-editor-text-wrapper {
  align-items: flex-start;
  display: flex;
  position: relative;

  .inline-editor__add__link-wrapper {
    display: flex;
  }

  .inline-editor__add__link {
    color: var(--content-color-link);
    cursor: pointer;
    font-size: var(--text-size-s);
  }

  .inline-editor-text {
    color: var(--content-color-secondary);
    font-family: var(--text-family-default);
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
    overflow: hidden;
    position: relative;
    @include markdown;
    @include selectable;

    p {
      word-break: break-word;
    }
  }

  .inline-editor-text__edit-icon-wrapper {
    cursor: pointer;
    display: flex;
    // can not change margin, padding value to token, mutiple factors are associated
    // will break edit icon alignment
    margin-left: var(--spacing-xs);
    padding: var(--spacing-xs);
  }

  .inline-editor-text__edit-icon {
    cursor: pointer;
    visibility: hidden;
  }

  @include hover {
    &.inline-editor-text-wrapper--editable {
      .inline-editor-text__edit-icon {
        visibility: visible;
        @include icon-color(var(--content-color-secondary));
      }
    }
  }
}

.inline-editor__view-more-wrapper {
  display: flex;
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);

  .inline-editor__view-more {
    color: var(--content-color-link);
    cursor: pointer;
    flex: 1;
    font-size: var(--text-size-m);
  }
}

.inline-editor__markdown-link {
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-xs);
  font-size: var(--text-size-xs);
}
