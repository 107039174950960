@mixin text-truncate-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-clip {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
