.requester-content-builder {
  display: flex;
  flex-direction: row;

  // IMPORTANT: The builder needs to set the containment to strict to prevent the sidebar and
  // other regions from being repainted
  contain: strict;

  .requester-builder {
    flex: 1;

    display: flex;
    flex-direction: column;
  }
}

.response-pane,
.example-request-editor-pane,
.example-response-pane,
.request-editor-pane {
  &__container {
    flex: 1;
    display: flex;
    flex-direction: inherit;
    min-height: 0;
    min-width: 0;
  }

  &__section-top {
    background-color: var(--background-color-primary);
  }

  &__section-content {
    background-color: $app-background-color;
    flex-direction: column;
  }
}

.pane-header.horizontal.response-pane__section-top {
  padding-left: var(--spacing-s);
}

.requester-contents-group {
  .requester-contents-group__section-top {
    background-color: var(--background-color-primary);
  }

  .requester-contents-group__section-content {
    background-color: $app-background-color;
  }
}

.requester-content-library {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-content-blanket {
  display: flex;
  height: 100%;
  width: 100%;
}

.example-response-pane {
  &__section-top {
    padding: var(--spacing-zero) var(--spacing-s);
  }
}