.homepage-get-started {
  margin-bottom: var(--spacing-xxl);
  $manage-card-badge-header-width: 300px;

  &-header {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
    color: var(--content-color-primary);
    margin-top: 0;
  }

  &-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat( auto-fill, 260px);
    grid-row: auto;
    grid-column-gap: var(--spacing-xxl);
    grid-row-gap: var(--spacing-l);
    margin-top: var(--spacing-l);

    &__manage {
      display: flex;
      width: $manage-card-badge-header-width;
      align-items: center;
    }

    &__badge {
      margin-left: var(--spacing-s);
      margin-bottom: var(--spacing-xs);
    }

    &__card {
      box-sizing: border-box;
      border: 0.5px solid var(--border-color-default);
      height: 160px;
      border-radius: 4px;
      padding: 20px 24px;

      &-name {
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-l);
        line-height: var(--line-height-l);
        margin: 0 0 4px 0;
        color: var(--content-color-primary);
      }

      &-description {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        margin: 0;
        height: 60px;
        color: var(--content-color-secondary);
      }

      &-button {
        display: flex;
        margin-top: 16px;
        font-weight: var(--text-weight-regular);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        padding: 0;
        color: var(--content-color-link);
        border: none;
        background: none;

        i {
          vertical-align: middle;
          margin-left: var(--spacing-s);
          svg {
            use {
              fill: var(--content-color-link);
            }
            color: var(--content-color-link);
          }
        }
      }

      .is-disabled {
        opacity: 0.5;
        cursor: progress;
      }
    }
  }
}
