.import-files-options-container {
  .import-files-banner {
    padding: var(--spacing-l);
    background-color: var(--background-color-error);
    border-radius: var(--border-radius-default);
    margin-bottom: var(--spacing-m);

    .error {
      display: flex;

      &__content-right {
        margin-left: var(--spacing-s);
        flex: 1;
      }

      &__message {
        margin-top: var(--spacing-xs);
      }
    }
  }
}
