.mock-edit-tab {
  &__loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    min-height: 300px;

    &__illustration {
      @include icon(
        '../assets/images/empty-state/alert.svg',
        120px,
        120px
      );
    }

    &__content {
      text-align: center;
      font-size: var(--text-size-m);

      &__header {
        color: var(--content-color-primary);
        margin-top: var(--spacing-l);
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-medium);
        line-height: var(--line-height-l);
      }

      &__sub-header {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        line-height: var(--line-height-m);
        margin: var(--spacing-l) var(--spacing-zero);
      }
    }

    &__retry-button {
      height: var(--size-m);
      width: 180px;
    }
  }
}
