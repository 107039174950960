.ca-certificate-wrapper {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-top: var(--spacing-l);

  .ca-certificate-wrapper__heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &-desc {
    margin-top: var(--spacing-xs);
  }

  .ca-certificate__field {
    display: flex;
    margin-top: var(--spacing-xs);

    .ca-certificate__field__label {
      flex: none;
      margin-right: var(--spacing-s);
      display: flex;
      align-items: center;

      .warning-button {
        margin: 0 0 1px 5px;
      }
    }

    &.disabled {
      display: none;
    }

    // Overriding base file uploader file input button styles
    .file-input {
      padding: var(--spacing-zero);

      .btn {
        color: var(--content-color-primary);
        height: var(--size-s);

        &.is-disabled {
          cursor: not-allowed;
        }

        &:hover {
          background-color: var(--highlight-background-color-primary);
        }

        &:not(.is-disabled):hover { // Change button border color only if it is not disabled
          border-color: var(--button-outline-hover-border-color);
        }

        .text {
          font-weight: var(--text-weight-regular);
        }
      }
    }

    // Overriding base file uploader file chip styles
    .file-input__name {
      background-color: var(--background-color-tertiary);
      border: none;
      height: var(--size-s);
      padding: var(--spacing-xs) var(--spacing-s);
    }
  }
}
