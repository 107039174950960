
.response-body-viewer-tabs-wrapper {
  $response-body-viewer-wrap-line-button-color: var(--background-color-tertiary);
  $response-body-viewer-wrap-line-button-hover-color: var(--highlight-background-color-tertiary);

  display: flex;

  .response-body-viewer {
    &__language-dropdown,
    &__line-wrap-button {
      margin-left: var(--spacing-s);
    }

    &__line-wrap-button {
      background-color: $response-body-viewer-wrap-line-button-color;

      @include hover {
        background-color: $response-body-viewer-wrap-line-button-hover-color;
      }

      @include active {
        background-color: $response-body-viewer-wrap-line-button-color;
        @include icon-color(var(--base-color-brand));
      }
    }
  }

}

