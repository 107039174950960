
.loading-indicator-wrapper.sign-out-loading-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.sign-out-loading--text {
  margin-left: 150px;
}

.sign-out-warning--checkbox {
  position: absolute;
  margin-top: 0px;

  &-text {
    margin-left: 24px;
  }
}

.sign-out-warning-container {
  background-color: var(--background-color-error);
  padding: 10px 10px 10px 15px;
  margin-bottom: 15px;
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .sign-out-warning--header {
    color: var(--content-color-error);
    font-weight: 600;

    & i {
      vertical-align: middle;
      padding-right: 5px;
    }
  }

  .sign-out-warning--warning-icon {
    @include icon-color(var(--base-color-error));
    margin-right: 7px;
    margin-top: 2px;
    flex-shrink: 0;
  }

  .sign-out-warning--text {
    padding-right: 10px;
    margin-left: 24px;
  }

  .sign-out-warning--list {
    list-style-type: decimal;
    padding-left: 0;
    list-style-position: inside;
    line-height: 1.5;
  }

  .sign-out-settings-icon {
    vertical-align: middle;
  }
}


