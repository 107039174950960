.monitor-contextbar-error-view {
  --custom-height: 280px;
  --custom-margin: 40px;
  width: auto;
  height: var(--custom-height);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 0 auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-right: var(--custom-margin);

  &__illustration {
    height: 120px;
    width: 140px;
    margin-bottom: var(--spacing-l);
  }

  &__title {
    margin-bottom: var(--spacing-s);
    text-align: center;
  }

 &__subtitle {
    text-align: center;
    display: flex;

    span {
      display: inline-block;
    }
  }
}
