.mock-edit-container {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-xxxl);

  &__title {
    font-size: var(--text-size-xxl);
    font-weight: var(--text-weight-medium);
    padding-top: var(--spacing-xxl);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
    overflow-x: overlay;
    overflow-y: overlay;

    .permissions-loading {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .mock-edit-form__error {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-xxl);
      flex-direction: column;
      flex: 1;
      justify-content: center;

      .creation-error__icon {
        @include icon(
          '../assets/images/empty-state/alert.svg',
          120px,
          120px
        );

        margin-top: var(--spacing-zero);
        margin-bottom: var(--spacing-zero);
      }

      .creation-error {
        &__title {
          font-size: var(--text-size-l);
          font-weight: var(--text-weight-medium);
          margin-top: var(--spacing-l);
          margin-bottom: var(--spacing-zero);
          color: var(--content-color-primary);
        }

        &__subtitle {
          font-size: var(--text-size-m);
          margin: var(--spacing-l) var(--spacing-xl);
          color: var(--content-color-secondary);
        }

        &__actions {
          .btn-primary {
            font-size: var(--text-size-m);
            height: var(--spacing-xxl);
            min-width: 60px;
            line-height: var(--line-height-xs);
          }
        }
      }
    }

    .mock-edit-form__content {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-right: 70px;

      .step1 {
        &.create-mock-form__body {
          display: flex;
          flex-direction: column;
          padding: var(--spacing-xl) var(--spacing-zero);
        }

        .create-mock-form__body__input-group {
          margin-bottom: var(--spacing-l);

          .create-mock-form__body__label {
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            margin-bottom: var(--spacing-s);
            font-weight: var(--text-weight-medium);

            &.is-error {
              color: var(--base-color-error);
            }
          }

          .create-mock-form__body__input {
            display: flex;

            .create-mock-form__body__input__helptext {
              padding-left: var(--spacing-xxl);
              color: var(--content-color-tertiary);
              max-width: 522px;

              // To be removed once Text component supports customizing text width using prop
              &__content {
                max-width: 100%;
              }

              .learn-more-link {
                color: var(--content-color-link);
                font-weight: var(--text-weight-regular);
              }
            }

            .create-mock-form__body__field {
              display: flex;
              width: 320px;
              height: 100%;

              &__label {
                cursor: pointer;
                font-weight: var(--text-weight-medium);
              }

              .environment-dropdown__button-label {
                @include text-truncate-ellipsis;
              }

              .input-box {
                border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
              }

              .input-box__error {
                border: var(--border-width-default) var(--border-style-solid) var(--content-color-error);
                background-color: var(--background-color-primary);
              }

              .btn-secondary {
                border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
                border-radius: var(--border-radius-default);
                background:var(--background-color-secondary);
              }
            }

            .input-checkbox {
              margin-right: var(--spacing-s);
            }
          }

          .mock-name-empty__msg-container {
            margin-top: var(--spacing-xs);

            &__text {
              color: var(--content-color-error)
            }
          }
        }
      }

      .is-loading {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }

      .create-mock-form__version-dropdown {
        width: 320px;

        .create-mock-form__version-dropdown__button-label {
          @include text-truncate-ellipsis;
        }
      }
    }
  }

  &__footer {
    margin-right: 70px;
    padding-left: var(--spacing-s);
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .btn-secondary {
      margin-right: var(--spacing-s);
    }
  }

  &__footer__button__container {
    display: flex;
    padding-top: var(--spacing-xxl);
    padding-bottom: var(--spacing-xxl);
  }
}

// Doing this to override the width of TextInput component by Aether
// which isn't accepting a maximum width of the component right now.


.mock-delay-selector-v2__custom-input {
  max-width: 200px;

  &__field + p {
    width: max-content
  }

  &__metric {
      margin-top: var(--spacing-s);
  }
}

