/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.vault-status-container {
  position: relative;
  z-index: 0;

  .vault-button-container {
    &.hovered .vault-shortcut-container {
      display: flex;
    }

    .vault-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      .vault-buton-label {
        margin-left: var(--spacing-xs);
      }
    }

    .vault-shortcut-container {
      position: absolute;
      top: -56px;
      padding: 6px var(--spacing-s);
      background-color: var(--tooltip-background-color);
      border-radius: var(--border-radius-s);
      box-shadow: var(--tooltip-box-shadow);
      border: 1px solid var(--tooltip-border-color);
    }

    .vault-feature-highlighter {
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 100%;
      overflow: clip;
      background-color: transparent;

      svg {
        margin: -8px auto;

        > path, &:hover > path {
          fill: #E4D8F6 !important;
        }
      }
    }
  }
}

.vault-sidebar-container {
  .sidebar-list-item__content-wrapper {
    margin-left: 0px !important;
  }
}

.vault-sidebar-dropdown-menu {
  min-width: 100px; 
}

.vault-sidebar-cloud-vault-info {
  text-align: center;
}