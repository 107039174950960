$response-viewer-tab-text-font-size: $tab-text-font-size !default;

.response-viewer-tabs {
  .tab {
    font-size: $response-viewer-tab-text-font-size;
  }

  .dropdown-label {
    font-weight: var(--text-weight-medium);
    white-space: pre;
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
  }

  .dropdown-caret.pm-icon {
    margin-left: var(--spacing-xs);
  }
}

.response-viewer-tabs-content-count {
  margin-left: var(--spacing-xs);
  color: var(--content-color-success);

  &.response-viewer-tabs-test-failed-count {
    color: var(--content-color-error);
  }

  &.response-viewer-security-tabs-count {
    color: var(--content-color-error);
  }
}

.response-viewer-tabs-test-error-badge {
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-left: var(--spacing-xs);
  background-color: var(--base-color-error);
  border-radius: 6px;
}
