$tabs--primary-border-width: 1px !default;

$tabs--secondary-border-radius: 3px !default;
$tabs--secondary-border-width: 1px !default;
$tabs--secondary-height: 30px !default;

$tabs--tertiary-height: 30px !default;

$tab-text-font-size: 12px !default;
$tab-text-font-weight: 400 !default;
$tab-text--hover-font-weight: 400 !default;
$tab-text--active-font-weight: 400 !default;

$tab--primary-highlight-height: 3px !default;
$tab--primary-padding-x: 15px !default;
$tab--primary-padding-y: 6px !default;

$tab--primary-small-padding-x: 8px !default;
$tab--primary-small-padding-y: $tab--primary-padding-y !default;

$tab--secondary-padding-x: 15px !default;

$tab--tertiary-padding-x: 15px !default;
$tab--tertiary-padding-y: 6px !default;
// component specific token added to provide bottom border to the active tab
// only intended to be used inside tabs base component styles
$tab-primary-active-border-color: inset 0px -1px 0px var(--base-color-brand);

// This is a component specific token intended to be used only inside tabs base component
// This was required to remove the bottom border shadow of vertical tabs
$tab-box-shadow-none: none;

/* Tabs */

.tabs {
  display: inline-flex;
  flex-direction: row;

  &.tabs-fluid {
    display: flex;
  }
}

.tabs-secondary {
  box-sizing: border-box;
  height: $tabs--secondary-height;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
}

.tabs-tertiary {
  box-sizing: border-box;
  height: $tabs--tertiary-height;
}

/* Tab */

.tab {
  flex: 0 0 auto;
  @include unselectable;
  @include clickable;
  box-sizing: border-box;
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
  text-align: center;

.tabs-fluid & {
    flex: 1;
  }
}

.tab-primary,
.tab-primary-small {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);

  @include hover {
    color: var(--content-color-primary);
  }

  &.is-active {
    color: var(--content-color-primary);
    .tab-text-wrapper{
      box-shadow: $tab-primary-active-border-color;
    }
  }

  &.is-disabled {
    color: var(--content-color-secondary);
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.tab-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-primary .tab-text-wrapper {
  margin-left: var(--spacing-m);
  padding: var(--spacing-s) var(--spacing-zero);
}

.tab-primary-small .tab-text-wrapper {
  margin: var(--spacing-zero) var(--spacing-s);
  padding: var(--spacing-s) var(--spacing-zero);
}

.tab-secondary {
  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-l);

  &:first-child {
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
  }

  &:last-child {
    border-top-right-radius: var(--border-radius-default);
    border-bottom-right-radius: var(--border-radius-default);
  }

  color: var(--content-color-secondary);

  @include hover {
    color: var(--content-color-primary);
  }

  @include active {
    color: var(--content-color-primary);
    background-color: var(--highlight-background-color-tertiary);
  }
}

.tab-tertiary {
  padding: $tab--tertiary-padding-y $tab--tertiary-padding-x $tab--tertiary-padding-y $tab--tertiary-padding-x;

  color: var(--content-color-secondary);
  font-weight: $tab-text-font-weight;

  @include hover {
    color: var(--content-color-primary);
    font-weight: $tab-text--hover-font-weight;
  }

  @include active {
    color: var(--content-color-primary);
    font-weight: $tab-text--active-font-weight;
  }
}

.tabs-vertical {
  flex-direction: column;
  text-align: left;

  .tab {
    display: flex;
    align-items: center;
    color: var(--content-color-secondary);
    box-shadow: $tab-box-shadow-none;
    height: 35px;
    padding-left: 20px;

    &.is-active {
      box-shadow: $tab-box-shadow-none;
      color: var(--content-color-primary);
      border-bottom: none;
    }
  }
}

// added the class for setting the text color of the disabled tab (The step tabs)
.incomplete-tab-steps {
  color: var(--content-color-secondary);
  cursor: default;
  @include hover{
    color: var(--content-color-secondary);
  }
}
