.sb__item {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--content-color-secondary);
  @include icon-color(var(--content-color-secondary));
  cursor: pointer;
  margin: var(--spacing-zero) var(--spacing-s);
  box-sizing: border-box;

  .sb__item__icon {
    height: inherit;
    display: flex;
    align-items: center;
  }

  @include hover {
    .sb__item__icon {
      svg {
        color: var(--content-color-primary);
      }
    }

    .sb__item__text {
      color: var(--content-color-primary);
      @include icon-color(var(--content-color-primary));
    }

    .copyright-text .copyright-text__meta {
      color: var(--content-color-secondary);
      cursor: default;
    }
  }

  &.is-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.is-active {
    .sb__item__text {
      color: var(--content-color-primary);
    }
  }
}
