.unsupported-integrations-error-cta {
  color: var(--content-color-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__subtitle {
    width: max-content;
    &__link {
      padding-left: var(--spacing-xs);
    }
  }
}
