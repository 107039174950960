$activity-feed-mock: "../assets/images/empty-state/activity-feed-mock.svg";

.homepage-right-section-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-bottom: var(--spacing-xxl);
  padding: var(--spacing-xxl) var(--spacing-l);

  &--responsive {
    padding-top: 0;
  }

  @media only screen and (min-width: 1600px) {
    overflow-y: unset;
    width: unset;
    max-width: 552px;
    margin-left: auto;
  }

  @media only screen and (max-width: 1300px) {
    grid-column: 2;
    max-width: 584px;
  }
}

.team-activity-feed-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--spacing-xxl);
  flex: 0 0 320px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
  }

  @media only screen and (max-width: 1300px) {
    grid-column: 2;
  }

  &-main-title {
    font-size: var(--text-size-xxl);
    line-height: var(--line-height-xxl);
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
    align-self: flex-start;
  }
  &-illustration {
    margin-top: var(--spacing-xxxl);
    @include icon($activity-feed-mock, 140px, 120px);
  }
  &-title {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-top: var(--spacing-xxl);
  }
  &-subtitle {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-secondary);
  }
  &--create-team {
    margin-top: var(--spacing-xl);
    width: 107px;
    height: var(--size-m);
  }
}
