/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$icon-position: 24px;
$empty-states-path: '../assets/images/empty-states/' !default;

@function getImagePath ($name) {
  @return $empty-states-path + $name;
}
.contextual-sign-in-modal-container {
  position: relative;
  margin-top: 10vh;
  height: 75vh;
  min-width: 760px;
  max-height: 570px;
  width: 80vw;

  &-content{
    height: 100%;
  }

  .modal-content {
    padding: 0;
  }

  &-loading {
    position: absolute;
    background-color: rgba(0, 0, 0, .8);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loader {
      border: 3px solid transparent;
      border-top: 3px solid var(--content-color-constant);
      border-right: 3px solid var(--content-color-constant);
      border-left: 3px solid var(--content-color-constant);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .loading-text {
      text-align: center;
      margin-top: 16px;
      color: var(--content-color-primary);
    }
  }
}

.contextual-sign-in-modal {
  display: flex;
  height: 100%;
  min-height: 420px;
  position: relative;
  background-color: var(--background-color-primary);

  .contextual-sign-in-modal__left-section--back-icon,
  .contextual-sign-in-modal__right-section--close-icon {
    position: absolute;
    top: $icon-position;
    cursor: pointer;

    &.is-enabled {
      z-index: 1;
    }
  }

  .contextual-sign-in-modal__left-section--back-icon {
    left: $icon-position;
  }

  .contextual-sign-in-modal__right-section--close-icon {
    right: $icon-position;
  }

  .contextual-sign-in-modal__left-section,
  .contextual-sign-in-modal__right-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .contextual-sign-in-modal__left-section {
    flex: 1;
    min-width: 210px;
    padding: 32px;
  }

  .contextual-sign-in-modal__right-section {
    flex: 2;
  }

  .contextual-sign-in-modal__left-section--icon {
    margin-bottom: 24px;
  }

  .contextual-sign-in-modal__left-section--invite-icon {
    @include icon(
      getImagePath('share_collection.svg'),
      100px,
      100px
    );
  }

  .contextual-sign-in-modal__left-section--mock-icon {
    @include icon(
      getImagePath('mock_cloud.svg'),
      100px,
      100px
    );
  }

  .contextual-sign-in-modal__left-section--monitor-icon {
    @include icon(
      getImagePath('monitor-logo.svg'),
      100px,
      100px
    );
  }

  .contextual-sign-in-modal__left-section--documentation-icon {
    @include icon(
      getImagePath('documentation-logo.svg'),
      100px,
      100px
    );
  }

  .contextual-sign-in-modal__left-section--title,
  .contextual-sign-in-modal__right-section--title {
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    @include unselectable;
  }

  .contextual-sign-in-modal__left-section--subtitle {
    margin-top: 8px;
    text-align: center;
    @include unselectable;
  }

  .contextual-sign-in-modal__left-section--title,
  .contextual-sign-in-modal__left-section--subtitle {
    color: var(--content-color-primary);
  }

  .contextual-sign-in-modal__left-section--create-btn {
    margin-top: 24px;
  }

  .contextual-sign-in-modal__separator-block {
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .contextual-sign-in-modal__separator-block--separator {
      width: 54px;
      height: 1px;
      background-color: var(--border-color-strong);
    }

    .contextual-sign-in-modal__separator-block--separator-text {
      margin: 0px 16px;
      color: var(--content-color-secondary);
    }
  }


  // Right section css starts here
  .contextual-sign-in-modal__right-section {
    background-color: var(--background-color-secondary);
  }

  .contextual-sign-in-modal__right-section--title {
    margin-bottom: 16px;
    color: var(--content-color-primary);
  }

  .contextual-sign-in-modal__right-section--carousel {
    width: 500px;
  }

  .contextual-sign-in-modal--invite-1 {
    @include icon(getImagePath('team.svg'), 170px, 170px);
  }

  .contextual-sign-in-modal--invite-2 {
    @include icon(getImagePath('share_collection.svg'), 170px, 170px);
  }

  .contextual-sign-in-modal--invite-3 {
    @include icon(getImagePath('collaborate.svg'), 170px, 170px);
  }

  .contextual-sign-in-modal--monitor-1 {
    @include icon(getImagePath('monitoring-empty-state-1.png'), 250px, 160px);
  }

  .contextual-sign-in-modal--monitor-2 {
    @include icon(getImagePath('monitoring-empty-state-2.png'), 250px, 160px);
  }

  .contextual-sign-in-modal--monitor-3 {
    @include icon(getImagePath('monitoring-empty-state-3.svg'), 150px, 150px);
  }

  .contextual-sign-in-modal--mock-1 {
    @include icon(getImagePath('mock-empty-state-1.png'), 170px, 170px);
  }

  .contextual-sign-in-modal--mock-2 {
    @include icon(getImagePath('mock_cloud.svg'), 170px, 170px);
  }

  .contextual-sign-in-modal--mock-3 {
    @include icon(getImagePath('collaborate.svg'), 170px, 170px);
  }

  .contextual-sign-in-modal--documentation-1 {
    @include icon(getImagePath('documentation-empty-state-1.png'), 250px, 160px);
    box-shadow: var(--shadow-default);
    background-size: cover;
  }

  .contextual-sign-in-modal--documentation-2 {
    @include icon(getImagePath('documentation-empty-state-2.png'), 250px, 160px);
    box-shadow: var(--shadow-default);
    background-size: cover;
  }

  .contextual-sign-in-modal--documentation-3 {
    @include icon(getImagePath('documentation-empty-state-3.png'), 250px, 160px);
    box-shadow: var(--shadow-default);
    background-size: cover;
  }

  .contextual-sign-in-modal__right-section--carousel {
    // This is to prevent jumping of component on the basis of
    // height change - since some elements might have more
    // context than the other
    .carousel {
      .carousel-inner {
        height: 250px;
      }
    }

    &.is-small {
      .carousel-inner {
        height: 220px;
      }
    }
  }

  .contextual-sign-in-modal__right-section--learn-more {
    margin-top: 24px;
  }
}
