.history-sidebar-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    .history-sidebar__indent {
      flex: 0 0 var(--spacing-s);
      box-sizing: border-box;
      height: inherit;
      border-right: thin solid var(--border-color-default);
    }
  }
}
