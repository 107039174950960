.page-offline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;

  &__icon-container {
    height: calc(var(--size-xs) * 3.5);
    width: calc(var(--size-xs) * 3.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: var(--spacing-l);
    background: var(--background-color-tertiary);

    &--icon,
    &--icon > svg {
      height: var(--size-m);
      width: var(--size-m);
    }
  }

  &__title {
    font-weight: 600;
    font-size: var(--text-size-l);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-s);
  }

  &__subtitle {
    font-weight: 400;
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
  }
}
