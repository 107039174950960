.datadog-apm-dashboard {
  height: -webkit-fill-available;
  align-items: center;

  .datadog-apm-dashboard-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;

    .datadog-apm-dashboard-wrapper--inner {
      overflow: auto;
    }

    .apm-titlebar-wrapper {
      width: -webkit-fill-available;
      width: -moz-available;
      background-color: var(--background-color-primary);

      .apm-service-selector {
        .aether-dropdown__value-container {
          padding-left: 0;
        }

        .aether-dropdown__single-value {
          font-size: var(--text-size-l);
          font-weight: var(--text-weight-medium);
          letter-spacing: -0.12px;
        }

        .aether-dropdown__control {
          border: none !important;
        }

        .aether-dropdown__control--is-focused,
        .aether-dropdown__control--is-focused:hover {
          border: none !important;
          box-shadow: none !important;
        }

        .integrations-add-card__segment {
          margin-top: var(--spacing-zero);
          .integrations-add-card__input {
            padding-top: var(--spacing-zero);
          }
        }

        .monitoring-service-selector-icon-wrapper {
          display: contents;
          align-items: center;
          padding: 6px var(--spacing-xs) 6px var(--spacing-s);

          .aether-dropdown__menu {
            width: 300px;
          }
        }
        .monitoring-service-selector-selected-item-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: var(--content-color-primary);
        }
      }

      .monitoring-service-selector-health-status {
        min-width: max-content;
        align-items: center;
        display: flex;
      }

      .apm-timeline-selector {
        cursor: pointer;
        width: 190px;
        min-width: 90px;
        margin-left: var(--spacing-s);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        .integrations-add-card__segment {
          margin-top: var(--spacing-m);
          .integrations-add-card__input {
            padding-top: var(--spacing-zero);
          }
        }
        .monitoring-timeline-selector-icon-wrapper {
          display: flex;
          align-items: center;
          padding: 6px var(--spacing-m) 6px var(--spacing-m);
        }
        .monitoring-timeline-selector-selected-item-text {
          width: 154px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: var(--content-color-primary);
        }
      }

      .monitoring-service-selector-icon-wrapper:hover {
        background-color: var(--background-color-tertiary);
        border-radius: var(--border-radius-default);
      }

      .apm-timeline-selector:hover {
        background-color: var(--background-color-tertiary);
        border-radius: var(--border-radius-default);
      }

      .apm-btn {
        display: flex;
        align-items: center;
        min-width: max-content;
        float: right;
        margin-left: auto;
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        color: var(--content-color-secondary);
        cursor: pointer;
      }

      .apm-btn:hover {
        color: var(--content-color-link);
      }

      .apm-timeframe {
        float: right;
        margin-left: var(--spacing-m);
      }

      .api-monitoring-more-options-icon-wrapper {
        display: flex;
        align-items: center;
      }

      .apm-refresh-cta {
        display: flex;
        align-items: center;
        cursor: pointer;
        float: right;
        margin-left: var(--spacing-l);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        color: var(--content-color-secondary);
      }
    }

    .datadog-apm-titlebar-divider {
      height: 47px;
    }

    .datadog-apm-chart-title-wrapper {
      display: grid;
      grid-column: auto;
      grid-auto-flow: column;
      margin-top: var(--spacing-xl);
      margin-left: var(--spacing-l);
      .datadog-apm-chart-title {
        height: var(--size-xs);
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        color: var(--content-color-primary);
      }
    }
    .datadog-apm-chart-wrapper {
      display: flex;
      height: 426px;
      background-color: var(--background-color-tertiary);
      margin: var(--spacing-l) var(--spacing-l) var(--spacing-xs) var(--spacing-l);
      align-items: center;
      padding-right: var(--spacing-s);
      border-radius: var(--border-radius-default);

      .datadog-apm-metric-chart-iframe-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 405px;
        margin-left: var(--spacing-s);
        align-items: center;
        background-color: white;
        .metric-chart {
          width: 100%;
          height: 405px;
        }
      }
    }
  }
}
