
.response-body-text-viewer {
  &__header {
    display: flex;
    flex-direction: row;

    &__left {
      display: flex;
      flex: 0 0 auto;
    }

    &__right {
      flex: 1;

      display: flex;
      flex-direction: row-reverse;

      align-items: center;
    }
  }

  &__content {
    padding-top: var(--spacing-s);
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  &__search-button {
    margin-right: 10px;
    cursor: pointer;
  }

  &__copy-button {
    margin-right: 10px;
    cursor: pointer;
  }

  & .tabs.tabs-secondary {
    height: 32px;
  }

  & .dropdown-button {
    .btn-secondary {
      box-shadow: none;
      font-weight: var(--text-weight-regular);
      background-color: var(--highlight-background-color-tertiary);
    
      &:hover {
        background-color: var(--button-secondary-hover-background-color);
      }
  
      &.is-active {
        background-color: var(--button-secondary-active-background-color);
      }
    }
  }
}

