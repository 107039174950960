.team-info-dropdown {
  .team-button {
    background-color: var(--button-secondary-background-color);
    font-weight: var(--text-weight-medium);
    box-shadow: 0 0 0 1px var(--button-secondary-outline-color);

    &:hover {
      background-color: var(--button-secondary-hover-background-color);
    }
    &:active {
      background-color: var(--button-secondary-active-background-color);
    }
  }

  .upgrade-dropdown {
    height: var(--controls-size-default);

    .btn {
      height: var(--controls-size-default);
    }
    .btn:nth-child(2) {
      padding-left: 5px;
      padding-right: 5px;
      min-width: 0px;
      border-left: var(--button-group-separator);
      margin-left: -1px; // To reduce separator thickness to 1px instead of 2px
      z-index: 2;

      &.btn-secondary {
        background-color: var(--button-secondary-background-color);
        box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;

        &:hover {
          background-color: var(--button-secondary-hover-background-color);
        }
      }
    }
  }

  .with-upgrade-banner {
    padding-top: 0px;
  }

  .dropdown-menu {
    width: 340px;
    background-color: var(--background-color-primary);

    .dropdown-menu-item--sign-in,
    .dropdown-menu-item--enable-sync,
    .dropdown-menu-item--upgrade-to-pro,
    .dropdown-menu-item--teamname {
      opacity: 1;
    }

    .dropdown-free-user-header-menu-item {
      display: contents;
      cursor: default;

      &_wrapper {
        padding: var(--spacing-l);
      }
    }

    .dropdown-free-user-header-menu-item:hover {
      background-color: var(--highlight-background-color-secondary);
    }

    .dropdown-menu-item--teamname {
      display: flex;
      align-items: flex-start;
      height: 70px;
      padding: 16px;

      @include hover {
        background-color: transparent;
      }

      .team-info-dropdown__team-image {
        display: flex;
        height: 32px;
        width: 32px;

        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
        }

        @include hover {
          cursor: pointer;
        }
      }

      .team-info-dropdown__team-details {
        .team-info-dropdown__team-details-meta {
          max-width: 130px;
        }
        display: flex;
        flex-direction: row;
        margin-left: 16px;
        flex: 1;
        justify-content: space-between;
        overflow: hidden;

        .team-info-dropdown__team-details__name {
          font-size: 14px;
          font-weight: 600;
          line-height: normal;

          @include hover {
            cursor: pointer;
          }
        }

        .team-info-dropdown__team-details__domain {
          font-size: 12px;
          line-height: normal;
        }

        .team-info-dropdown__team-details__name,
        .team-info-dropdown__team-details__domain {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .dropdown-menu-item--sign-in,
    .dropdown-menu-item--enable-sync,
    .dropdown-menu-item--upgrade-to-pro {
      display: flex;
      flex-direction: column;
      height: 180px;
      padding: 20px;

      .dropdown-menu-item__subtitle {
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 20px;
        color: var(--content-color-primary);
        text-align: center;
      }

      .dropdown-menu-item__learn-more {
        margin-top: 20px;
      }
    }

    .dropdown-menu-item--upgrade-to-pro {
      height: 220px;
    }
  }
}
