.response-error-viewer {
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: $app-background-color;
    overflow: auto;

    &__editor {
      flex: 1;

      margin: var(--spacing-s);
      display: flex;
      flex-direction: row;

      &__content {
        flex: 1;

        display: flex;
        flex-direction: column;

        align-items: center;
        margin: auto; // keep it in the center
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    @include icon('../assets/images/thumbnails/astronaut.svg', 120px, 120px);
  }

  &__heading {
    font-size: var(--text-size-l);
    color: var(--content-color-primary);
    margin-top: var(--spacing-l);
  }

  &__error {
    font-family: $font-primary;
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    margin-top: var(--spacing-l);
    display: flex;
    flex-direction: column;

    &-item {
      position: relative;
      padding: var(--spacing-xs) var(--spacing-s);
      background-color: var(--background-color-error);
      display: flex;
      align-items: center;
      border-radius: 20px;
      margin: var(--spacing-m) var(--spacing-xxxl) var(--spacing-zero) var(--spacing-xxxl);
      justify-content: center;
      align-self: center;

      &__description {
        padding: var(--spacing-zero) var(--spacing-s);
        text-align: center;
        word-break: break-all;
        white-space: pre-wrap;
      }

      &__action {
        border-radius: 0;
        border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-active);
        color: var(--content-color-brand);

        @include hover {
          opacity: 0.6;
        }

        // TODO: implement --postbot-content in aether
        &__postbot {
          // override default aether button height
          height: unset !important;
          // color: var(--purple-60) !important;
          svg > path[fill] {
            // fill: var(--purple-60) !important;
          }

          &:hover {
            // override default aether button background-color
            background-color: unset !important;
          }
        }
      }
    }
  }

  &__link {
    margin-top: var(--spacing-l);
    color: var(--content-color-link);
    font-size: var(--text-size-m);
  }
}
