.add-schema__content {
  display: flex;
  flex: 1;

  .add-schema__wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    .add-schema__heading {
      padding: 10px;
      font-family: $font-primary;
      font-size: 12px;
      font-weight: 600;
      color: var(--content-color-primary);
    }

    .add-schema__dropdown-wrapper {
      padding: 5px;

      .add-schema__button {
        width:150px;
      }

      .add-schema__button-text {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .add-schema__description {
      padding: 10px;
      font-family: $font-primary;
      font-size: 11px;
      font-weight: 400;
      color: var(--content-color-secondary);
    }
  }
}

// Dropzone component added an extra div in AddSchema component that broke the flex layout 
.add-schema-file-drop {
  display: flex;
  flex: 1;
}
  
.add-schema__dropdown-menu {
  width: 240px;
}

.add-schema__dropdown-menu__banner {
  width: 240px;
  padding-bottom: var(--spacing-zero);
}