.remove-tag-confirmation-modal__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.remove-tag-confirmation-modal__action {
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;

  .btn {
    &.is-focused {
      box-shadow: var(--shadow-focus);
    }
  }
}

.remove-tag-confirmation-modal__delete-button {
  background: var(--base-color-error);
  margin-left: 10px;
  @include hover {
    background-color: var(--highlight-background-color-error);
  }
  @include active {
    background-color: var(--highlight-background-color-error);
  }

  @include focus {
    background-color: var(--highlight-background-color-error);
  }

  @include disabled {
    @include hover {
      background-color: var(--highlight-background-color-error);
    }
    @include active {
      background-color: var(--highlight-background-color-error);
    }

    @include focus {
      background-color: var(--highlight-background-color-error);
    }
  }
}
