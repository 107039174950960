.file-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow-y: hidden;

  &__network {
    overflow: visible;
  }
}

.file-editor {
  display: flex;
  flex: 1 auto;
  flex-direction: column;

  min-height: 0;

  .file-tab-content {
    display: flex;
    flex: 1;
    flex-direction: column;

    min-height: 0;

    .file-tab-content-pane {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}
