.manage-header-preset-modal .modal-content {
  display: flex;
  flex-direction: column;
}

.manage-header-preset-modal-content-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.manage-header-preset-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.manage-header-preset-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  &.is-hidden {
    display: none;
  }
}

.manage-header-preset-buttons {
  margin: 10px 0;

  .btn {
    margin-right: 5px;
  }
}
