.entity-authorization {
  &__title-content-container {
    display: flex;

    & > * + * {
      margin-left: var(--spacing-s);
    }
  }

  &__title {
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    padding: 2px var(--spacing-zero);

    &--secondary {
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-regular);
    }

    &--tertiary {
      color: var(--content-color-tertiary);
      font-weight: var(--text-weight-regular);
      line-height: 22px;
      padding: 1px var(--spacing-zero);
    }
  }

  &__content {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    color: var(--content-color-secondary);
  }

  &__link {
    color: var(--content-color-link);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.entity-authorization-accordian {
  &__title-container {
    border-radius: var(--border-radius-default);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-size-m);
  }

  &__title {
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
  }

  &__toggle-action {
    padding: var(--spacing-zero);
    margin: var(--spacing-zero);
    background-color: transparent;
    color: var(--content-color-brand);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: var(--text-size-s);

    &:focus {
      outline: none;
    }

    & > * + * {
      margin-left: var(--spacing-xs);
    }
  }
}

.entity-authorization-small {
  & > * + * {
    margin-top: var(--spacing-s);
  }

  &__title-content-container {
    display: flex;

    & > * + * {
      margin-left: var(--spacing-s);
    }
  }

  &__title {
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    padding: 2px var(--spacing-zero);

    &--secondary {
      color: var(--content-color-secondary);
      font-weight: var(--text-weight-regular);
    }

    &--tertiary {
      color: var(--content-color-tertiary);
      font-weight: var(--text-weight-regular);
      line-height: 22px;
      padding: 1px var(--spacing-zero);
    }
  }

  &__content {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
  }

  &__link {
    color: var(--content-color-link);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
