$empty-states-path: '../assets/images/empty-state/' !default;
$permission-denied-icon: $empty-states-path + 'no-permission.svg';

.permission-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--content-color-primary);
  padding: 10px 10px 20px;
  box-sizing: border-box;

  .permission-denied--img {
    @include icon(
      $permission-denied-icon,
      75px,
      75px
    );

    margin-bottom: 16px;
  }

  .permission-denied--title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .permission-denied--title,
  .permission-denied--subtitle {
    @include unselectable;
  }
}
