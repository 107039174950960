.fs-gn__auto-input {
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: unset;
  background-color: var(--grey-40);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  position: relative;

  .fs-gn__auto-handle {
    cursor: pointer;
    position: relative;
    margin: 0px;
    align-items: center;
    height: 6px;
    width: 6px;
    transform: unset;
    border: unset;
    top: unset;
    right: unset;
    left: unset;
    background: unset;
    z-index: 2;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: var(--background-color-primary);

    &.react-flow__handle-connecting.react-flow__handle-valid {
      cursor: crosshair;
    }

    &::before {
      --hitbox-padding: 4px;
      --hitbox-size: calc(100% + var(--hitbox-padding) * 2);
      --hitbox-position: calc(var(--hitbox-padding) * -1);
      content: "";
      width: var(--hitbox-size);
      height: var(--hitbox-size);
      background: none;
      position: absolute;
      left: --hitbox-position;
      top: --hitbox-position;
    }

    &.connected {
      background-color: var(--grey-40);
      &.error {
        background-color: var(--content-color-error);
      }

      &.active {
        background-color: var(--base-color-success);
      }
    }

    &:hover {
      height: 8px;
      width: 8px;
    }
  }

  .fs-gn__auto-connector {
    width: 24px;
    height: 4px;
    position: absolute;
    right: 0;
  }

  .connector {
    stroke-dasharray: 5 5 10;
    stroke: var(--grey-40);
    stroke-width: 2;
    background-color: var(--background-color-primary);
  }

  &.error {
    background-color: var(--content-color-error);
    .connector {
      stroke: var(--content-color-error);
    }
  }

  &.active {
    background-color: var(--base-color-success);
    .connector {
      stroke: var(--base-color-success);
    }
  }

  &.connected {
    &.active {
      border-color: var(--base-color-success);
    }
    &.error {
      border-color: var(--content-color-error);
    }
    border-color: var(--grey-40);
    background-color: var(--background-color-primary);
    border-width: 2px;
    border-style: solid;
  }

  &.react-flow__handle-connecting.react-flow__handle-valid {
    cursor: crosshair;
  }

  &:hover {
    .fs-gn__auto-handle {
      height: 8px;
      width: 8px;
    }
  }
}

.fs-gn__auto-output-container {
  margin-left: auto;
}

.fs-gn__auto-output {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  position: relative;

  .fs-gn__auto-handle {
    cursor: pointer;
    position: relative;
    margin: 0px;
    align-items: center;
    height: 8px;
    width: 8px;
    transform: unset;
    border: unset;
    top: unset;
    right: unset;
    left: unset;
    background: unset;
    background-color: var(--grey-40);
    border-radius: 2px;
    z-index: 2;
    box-sizing: border-box;

    &::before {
      --hitbox-padding: 4px;
      --hitbox-size: calc(100% + var(--hitbox-padding) * 2);
      --hitbox-position: calc(var(--hitbox-padding) * -1);
      content: "";
      width: var(--hitbox-size);
      height: var(--hitbox-size);
      background: none;
      position: absolute;
      left: var(--hitbox-position);
      top: var(--hitbox-position);
    }

    &.react-flow__handle-connecting.react-flow__handle-valid {
      cursor: crosshair;
    }

    &.error {
      background-color: var(--content-color-error);
    }

    &.active {
      background-color: var(--base-color-success);
    }

    &:hover {
      height: 10px;
      width: 10px;
    }
  }

  .fs-gn__auto-connector {
    width: 16px;
    height: 4px;
    position: absolute;
    left: 2px;
  }

  .connector {
    stroke-dasharray: 7 5 5;
    stroke: var(--grey-40);
    stroke-width: 2;
  }

  &.error {
    .connector {
      stroke: var(--content-color-error);
    }
  }

  &.active {
    .connector {
      stroke: var(--base-color-success);
    }
  }

  &.auto-width {
    width: auto;
    .fs-gn__block {
      width: auto;
    }
  }
}

.react-flow__node .gn-port__handle {
  background: unset;
  border: unset;
  border-radius: unset;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  right: unset;
  left: unset;
  top: unset;
  transform: unset;
  cursor: pointer;
}

.fql-postbot-icon {
  display: flex !important;
  svg {
    color: var(--purple-60) !important;
  }

  &.dark {
    svg {
      color: var(--purple-40) !important;
    }
  }
}

@import "./AnnotationNode/index";
@import "./Edges/index";
