$icon-no-monitor-url: '../assets/images/empty-state/monitor_cloud.svg';
.monitor-listing-empty-block{
  font-size: 12px;
  padding: 10px 15px;
  color: var(--content-color-secondary);

  .entity-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 10px 20px;
    border: none;
    box-sizing: border-box;
    .monitor-list-empty__title,
    .monitor-list-empty__subtitle{
      font-style: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--content-color-primary);
      margin-bottom:10px
    }
    .monitor-list-empty__subtitle{
      font-weight: normal;
    }
  }

  .entity-empty__illustration-wrapper--monitor{
    margin-bottom: 16px;

    .monitor-empty__illustration{
      @include icon(
          $icon-no-monitor-url,
          75px,
          75px
        );
    }
  }
}
