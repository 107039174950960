.sb__item__text {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-family: var(--text-family-default);
  cursor: pointer;
  max-width: 175px;

  @include unselectable;
  @include text-truncate-ellipsis;
}
