.tab-unsaved-confirmation-modal {
  .tab-unsaved-confirmation-modal_body-title {
    font-weight: 600;
  }

  .tab-unsaved-confirmation-modal--confirmation-setting {
    flex: 1;
  }

  .confirmation-checkbox {
    display: flex;
    flex: 1;
    padding: 20px 0px 2px;
  }

  .confirmation-text {
    padding: 0px 0px 0px 10px;
  }

  .confirmation-helper-text {
    padding-left: 30px;
    opacity: 0.5;
  }

  .tab-unsaved-confirmation-modal__footer {
    flex-direction: row;

    &.conflict {
      flex: 0 0 110px;
    }

    .tab-unsaved-confirmation-modal__footer-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;

      .tab-unsaved-confirmation-modal__footer-actions {
        display: flex;
        flex-direction: row;
        flex: 1;

        .tab-unsaved-confirmation-modal__footer__left {
          display: flex;
          flex: 1;

          & > .btn.is-focused {
            @include focus {
              box-shadow: var(--shadow-focus);
            }
          }
        }

        .tab-unsaved-confirmation-modal__footer__right {
          flex: 0 0 auto;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;

          & > .btn {
            margin-left: var(--spacing-m);

            &.btn-text {
              height: $button-height
            }
          }
          & > .btn.is-focused {
            @include focus {
              box-shadow: var(--shadow-focus);
            }
          }
        }
      }

      .tab-unsaved-confirmation-modal__footer-additional-actions {
        display: flex;
        margin-top: 8px;

        & > .btn {
          margin-left: var(--spacing-m);
          min-width: 80px;

          &.btn-text {
            height: 20px;
          }
        }
        & > .btn.is-focused {
          @include focus {
            box-shadow: var(--shadow-focus);
          }
        }
      }


      &.conflict {
        .btn-primary {
          &.large {
            width: 140px;
          }
        }

        .btn-text {
          padding: 0;
          margin-left: 0;
          width: 140px;
        }
      }
    }
  }
}
