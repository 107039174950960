.websocket-query-params {
  overflow-y: auto;
  flex: 1;
  padding: var(--spacing-zero) var(--spacing-l);

  .key-value-bulk-editor .bulk-editor__controls {
    padding-right: var(--spacing-xs);
  }

  .urlparams-editor {
    margin-bottom: var(--spacing-m);
  }
}
