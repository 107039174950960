$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.diff-entity {
  font-size: var(--text-size-l);
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: var(--spacing-l);
  width: 75%;
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);

  @media (min-width: $breakpoint-sm) {
    margin-bottom: 0;
  }

  &__separator {
    display: none;
    margin: 0 var(--spacing-xs);

    @media (min-width: $breakpoint-sm) {
      display: inline-flex;
    }
  }

  &__fork-label {
    margin-left: var(--spacing-s);
    max-width: 25%;

    span {
      font-weight: var(--text-weight-regular);
    }
  }

  &__label-group {
    display: flex;
    align-items: center;
  }

  &__source {
    color: var(--content-color-brand);
    margin-right: var(--spacing-xs);
    font-weight: var(--text-weight-medium);
    display: inline-block;
    font-size: var(--text-size-m);
  }

  &__dest {
    color: var(--content-color-info);
    margin-right: var(--spacing-xs);
    font-weight: var(--text-weight-medium);
    display: inline-block;
    font-size: var(--text-size-m);
  }

  &__collection-name {
    @include text-truncate-ellipsis;

    color: var(--content-color-primary);
    display: inline-block;
    vertical-align: middle;
    font-size: var(--text-size-m);

    &-source {
      max-width: 50%;

      @media (min-width: $breakpoint-xs) {
        max-width: 60%;
      }

      @media (min-width: $breakpoint-sm) {
        max-width: 45%;
      }

      @media (min-width: $breakpoint-md) {
        max-width: 50%;
      }
    }

    &-destination {
      max-width: 60%;
      margin-right: var(--spacing-xs);

      &-fork {
        max-width: 40%;

        @media (min-width: $breakpoint-xs) {
          max-width: 50%;
        }

        @media (min-width: $breakpoint-sm) {
          max-width: 25%;
        }

        @media (min-width: $breakpoint-md) {
          max-width: 30%;
        }
      }
    }
  }

  &__conflict-source {
    display: inline-block;
    background: rgba(var(--highlight-background-color-primary), 0.1);
  }

  &__conflict-dest {
    display: inline-block;
    background: rgba(var(--highlight-background-color-primary), 0.1);
  }

  &__alert-icon {
    svg {
      height: 12px;
      width: 12px;
    }
  }

  &__collection-unavailable {
    position: relative;
    top: 2px;
  }

  &__link--disabled {
    pointer-events: none;

    .diff-entity__collection-name-source,
    .diff-entity__collection-name,
    .pull-request__fork-label {
      opacity: 0.5;
    }
  }
}
