.documentation-context-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  .right-context-bar-header {
    flex-shrink: 0;
  }

  .loading-indicator-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__entity-body-container {
    overflow: scroll;
    display: flex;
    flex: 1 1 auto;
    height: calc(100% - 48px);
    padding-left: var(--spacing-s);
    padding-right: var(--spacing-s);

    .docs-context-view {
      width: 100%;
      height: 100%;
    }

    .documentation-description__editor-container {
      height: 100%;
      overflow: hidden;
    }
  }

  .collection-container,
  .folder-container,
  .request-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-l);
    word-break: break-word;
    height: 100%;
  }

  .request-container {
    margin-top: 0;

    code[data-aether-id='aether-text-code'] {
      font-size: var(--text-size-m);
    }

    .aether-dropdown__control {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    .aether-dropdown__control--is-focused {
      border: none !important;
      box-shadow: none !important;
    }

    .request-url-core + .description-editor-container > .entity-description-container {
      margin-top: 0;
    }
  }
}
