/* stylelint-disable postman-stylelint/color-no-hex-rgb */
figure.job-history-chart {
  --bar-width: 32px;
  --top-bar-height: 240px;
  --bottom-bar-height: 80px;
  $bottom-bar-height: 80px; // TODO - not using CSS variable as ceil() and floor() are used
  --bar-spacing: 4px;
  --y-axis-label-width: 30px;
  --y-axis-label-height: 16px;
  --top-bottom-spacing: calc(var(--text-size-l)*3);
  --breakpoint-md: 768px;

  @include no-select;

  display: block;
  padding-left: calc(var(--y-axis-label-width) + var(--spacing-l));
  position: relative;
  margin: var(--spacing-l) 0;

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  // The Y axis shows the percentage of passed/failed tests
  ol.y-axis {
    @include unstyled-list;

    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - var(--spacing-xl));
    // height: 100%;
    min-height: var(--top-bar-height);
    z-index: 0;
    padding-left: var(--spacing-l);
    margin-top: calc(var(--spacing-l) / 8);

    li {
      font-size: 10px;
      height: var(--y-axis-label-height);
      color: var(--content-color-error);
      margin-bottom: calc((-1) * var(--y-axis-label-height) / 2);

      > div {
        width: 2.5em;
        text-align: right;
      }

      &:after {
        content: ' ';
        display: block;
        border-top: solid var(--border-width-default) var(--border-color-default);
        position: relative;
        top: calc((-1) * var(--y-axis-label-height) / 2);
        left: var(--y-axis-label-width);
        width: calc(100% - (var(--y-axis-label-width) + var(--spacing-l)));
      }

      &.response-time-tick {
        color: var(--content-color-info);
        margin-top: calc((var(--top-bar-height)/ 5) - (var(--y-axis-label-height) / 2));
      }
    }

    > li.failed-tick {
      margin-top: 34px; // HACK

      ~ li.failed-tick {
        margin-top: calc((var(--bottom-bar-height) / 2) - (var(--y-axis-label-height) / 2));
      }
    }
  }

  // The X axis shows time, in days
  div.x-axis {
    width: calc(100% - var(--spacing-xl));
    min-height: var(--top-bar-height);
    overflow: auto;
    position: relative;
    z-index: 1;

    .clearfix {
      display: inline-flex;
      position: relative;

      &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }
    }

    div.day {
      position: relative;
      padding-bottom: 16px;

      time.date {
        display: block;
        position: absolute;
        bottom: 10px;
        left: calc(50% - 27px);   // HACK: couldn't think of a better way to center the text
        padding: 0 var(--spacing-l);
        font-size: 10px;
        text-align: center;
        color: var(--content-color-primary);
        background-color: var(--background-color-primary);
      }

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 16px;
        left: 3px;
        width: calc(100% - 6px);
        border-top: solid var(--border-width-default) var(--border-color-default);
      }
    }

    .response-line-graph {
      position: absolute;
      width: 100%;
      height: 100%;
      fill: none;
      stroke: var(--content-color-info);
      z-index: 5;
      pointer-events: none;

      polyline {
        fill: none;
        stroke-width: 1;
      }

      circle {
        fill: white;
        stroke: var(--content-color-info);
        stroke-width: 1;
      }
    }

  }

  ol.jobs {
    @include inline-list;
    white-space: nowrap;

    li.job {
      cursor: pointer;
      position: relative;

      .top {
        background-color: var(--content-color-tertiary);
      }

      .passed {
        background-color: var(--content-color-success);
      }

      .failed {
        background-color: var(--content-color-error);
      }

      --job-bar-animation-ribbon-color: rgba(51, 51, 51, 0.22);
      --job-bar-animation-background-size: 20px;

      .unknown{
        background-image: linear-gradient(
            -45deg,
            transparent 25%,
            var(--job-bar-animation-ribbon-color) 25%,
            var(--job-bar-animation-ribbon-color) 50%,
            transparent 50%,
            transparent 75%,
            var(--job-bar-animation-ribbon-color) 75%,
            var(--job-bar-animation-ribbon-color)
        );
        background-size: var(--job-bar-animation-background-size) var(--job-bar-animation-background-size);
        background-color: var(--content-color-tertiary);
      }

      .unknown.animated{
        animation: job-progress-loader 2s infinite linear;
      }

      @keyframes job-progress-loader {
        0%{
          background-position: 0px var(--job-bar-animation-background-size);
        }

        100%{
          background-position: var(--job-bar-animation-background-size) var(--job-bar-animation-background-size);
        }
      }

      .error {
        background-color: var(--content-color-error);
      }

      .bars {
        .top,
        .unknown,
        .error {
          opacity: 0.2;
        }

        .passed {
          opacity: 0.5;
        }
      }

      &:hover {
        .bars {
          .top,
          .unknown,
          .error {
            opacity: 0.35;
          }

          .passed {
            opacity: 0.75;
          }
        }

        .start-time {
          background-color: var(--highlight-background-color-tertiary);

          &:after {
            border-bottom: 10px solid var(--highlight-background-color-tertiary);
          }
        }
      }

      &.selected,
      &:active,
      &:focus {
        .bars {
          .top,
          .unknown,
          .error {
            opacity: 0.5;
          }

          .passed {
            opacity: 0.85;
          }
        }

        .start-time {
          color: var(--background-color-primary);
          background-color: var(--content-color-primary);
        }

        .start-time:after {
          border-bottom: 10px solid var(--content-color-primary);
        }
      }

      .passed,
      .top,
      .failed,
      .unknown,
      .error {
        width: var(--bar-width);
        margin: 0 calc(var(--bar-spacing) / 2);
      }

      .passed-0,
      .failed-0,
      .unknown-0,
      .error-0 {
        display: none;
      }

      .passed-100,
      .failed-100,
      .unknown-100,
      .error-100 {
        height: var(--bottom-bar-height);
      }

      .top {
        height: calc(var(--top-bar-height) - var(--y-axis-label-height));
        margin-bottom: var(--top-bottom-spacing);
      }

      // Calculate the bar height for each percentage
      // Add a 5px spacer between .failed and .passed (the 5px goes on whichever bar is larger)
      @for $i from 1 through 99 {
        .failed-#{$i} {
          height: ceil(($i * $bottom-bar-height) * 0.01);
        }

        .error-#{$i} {
          height: floor(($i * $bottom-bar-height) * 0.01);
        }

        .passed-#{$i} {
          height: floor(($i * $bottom-bar-height) * 0.01);
        }
      }

      time.start-time {
        display: inline-block;
        font-size: 10px;
        font-weight: var(--text-weight-medium);
        font-stretch: normal;
        text-align: center;
        color: var(--content-color-tertiary);
        background-color: transparent;
        padding: 0 2px 2px 2px;
        margin: 15px 0 25px 0;
        width: calc(var(--bar-width) + var(--bar-spacing));
        border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
        position: relative;

        .time-hours-minutes,
        .time-am-pm {
          display: block;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          top: -10px;
          margin-left: -2px;
          border-bottom: solid 10px transparent;
          border-left: solid 20px transparent;
          border-right: solid 20px transparent;
        }
      }
    }
  }

  &:hover > .slider-control {
    display: block;
  }

  .slider-control {
    z-index: 2;
    position: absolute;
    top: 50%;
    margin-top: -62px; // (time row height + button height/2)
    display: block;

    // Always show scroll buttons on mobile screen
    @media (min-width: $breakpoint-md) {
      display: none;
    }

    &.back-button {
      left: 20px;
    }

    &.next-button {
      right: 0px;
    }
  }
}

.job-bar-tooltip-wrapper {
  margin-bottom: 280px;

  .job-tooltip-body {
    display: block;
    visibility: visible;
    font-size: var(--text-size-l);

    .error-red {
      color: var(--content-color-error);
    }

    .response-blue {
      color: var(--content-color-info);
    }
  }

  .arrow {
    margin-bottom: 280px;
  }
}
