.plugin__webSocketProxy {
  .web-socket-proxy-button {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-default);
    cursor: pointer;

    .web-socket-proxy-label {
      margin-left: var(--spacing-xs);
    }

    .sb__item__text {
      margin-bottom: var(--spacing-xs);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .web-socket-proxy-label {
    display: none;
  }
}
