.monitor-schedule-selector {
  display: flex;
  width: 500px;
  box-sizing: border-box;

  .dropdown {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.time-type-dropdown {
      flex: 0 0 130px;
    }

    &.time-option-dropdown {
      flex: 0 0 240px;
    }

    &.time-dropdown {
      flex: 0 0 100px;
    }
  }
}
