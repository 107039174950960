$code-component-max-height: calc(20 * var(--size-m));

// Default styles for the code component.
.documentation-code-default {
  & > * + * {
    margin-top: var(--spacing-s);
  }

  &--title {
    color: var(--content-color-tertiary);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);


    span:first-child {
      color: var(--content-color-primary);
      margin-right: var(--spacing-s);
    }
  }

  &--content {
    pre {
      padding: var(--spacing-s);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      border-radius: var(--border-radius-default);
      background-color: var(--background-color-tertiary);
      color: var(--content-color-secondary);
      max-height: $code-component-max-height;
    }
  }
}
