.empty-documentation-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__illustration {
    margin-bottom: var(--spacing-l);
  }

  &__heading {
    margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
  }

  &__description {
    text-align: center;
    color: var(--content-color-secondary);
  }

  &__components {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
  }
}
