.service-details-view-container-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-xxl);
}

.ci-cd-available-service-icon {
  width: 100%;
  height: auto;
}

.ci-cd-loading-indicator {
  position:relative;
  top:50%;
  left:50%;
}

.ci-cd-test-header {
  display:flex;
  background: var(--background-color-secondary);
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  height: var(--spacing-xxl);
  align-items:center;
  padding: 0px var(--spacing-l);
  border-radius: var(--border-radius-default);
  .drop-icon {
    height: var(--spacing-xxl);
    padding: 0px;
    cursor:pointer;
  }
  .title {
    margin-left: var(--spacing-xs);
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-bold);
    display:flex;
    align-items:center;
    height: var(--spacing-xxl);
  }
}
.cancel-button {
  margin-top:var(--spacing-xl);
  float:left;
  margin-left:var(--spacing-s);
}
