.ci-cd-builds-page-container {
  display:flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .ci-cd-builds-page-info-container {
    display:flex;
    z-index: 2;
    margin: var(--spacing-zero);
    padding: var(--spacing-zero) var(--spacing-s);
    min-height: 48px;
    align-items:center;

    .ci-cd-builds-page-back-button-container{
      display:flex;
      cursor:pointer;
      align-items:center;
      padding:var(--spacing-s);
    }
    .project-service-name {
      width: 65%;
      display: flex;
      color: var(--content-color-secondary);
      align-items: center;
      font-size: var(--text-size-m);
      overflow: hidden;
      text-overflow: ellipsis;
      .source {
        align-items: center;
        display: flex;
        margin-left: var(--spacing-s);
      }
    }
    .right-hand-buttons {
      margin-left: auto;
      width:30%;
      display:flex;
      justify-content:flex-end;
      align-items: center;
      .configure-newman-button {
        display: flex;
        align-items: center;
        font-size: var(--text-size-m);
        margin-right:var(--spacing-xs);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        .configure-icon {
          margin: var(--spacing-zero) var(--spacing-xs);
        }
      }
      .integration-options {
        display:flex;
        align-items:center;
        justify-content: center;
      }
    }
  }
  .ci-cd-builds-page-button-container {
   margin:var(--spacing-s) var(--spacing-l);
   display: flex;
   justify-content: space-between;
   .filter-container {
      display: flex;
      .filter-text {
        display: flex;
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        padding: var(--spacing-s) var(--spacing-xs);
        align-items: center;
      }
      .filter-dropdowns {
        display: flex;
        padding: var(--spacing-s) var(--spacing-xs);
        align-items: center;
        .filter-dropdowns--branch {
          margin-right: 2px;
          min-width: 122px;
          .aether-dropdown__control {
            border: none; // this is required as the Aether dropdown doesn't provide a way to remove the border
          }
        }
      }
      .ci-cd-builds-dropdown {
        display:flex;
        align-items:center;
        padding:var(--spacing-xs) var(--spacing-zero);
        .dropdown-button {
          .ci-cd-chosen-branch {
            padding-top:var(--spacing-m);
            color:var(--content-color-secondary);
            font-weight: var(--text-weight-regular);
          }
        }
      }
   }
   .run-refresh-container {
     display:flex;
     justify-content:flex-end;
    .btn.ci-cd-run-builds-button {
      font-weight: var(--text-weight-regular);
      display:flex;
      justify-content:flex-end;
    }
    .btn.ci-cd-refresh-builds-button {
      font-weight: var(--text-weight-regular);
      display:flex;
      justify-content: flex-end;
    }
   }
  }

  .ci-cd-loading-build-indicator {
    position:absolute;
    top:50%;
    left:50%;
  }

  .ci-cd-builds-table {
    .ci-cd-builds-table-success {
      margin: var(--spacing-m);
      overflow:hidden;
    }
  }

  .view-more-button {
    width:20%;
    display:flex;
    color: var(--content-color-link);
    font-size: var(--text-size-m);
    padding: var(--spacing-zero) 20px;
    margin-left: var(--spacing-xxl);
    margin-top: var(--spacing-m);
    margin-bottom: var(--spacing-m);
    cursor: pointer;
  }

  .compact-builds-configure-newman-button {
    width:20%;
    display:flex;
    color: var(--content-color-link);
    font-size: var(--text-size-m);
    padding: var(--spacing-zero) var(--spacing-m);
    margin-left: var(--spacing-xxl);
    margin-top: var(--spacing-s);
    cursor: pointer;
  }

  .ci-cd-empty-state-illustration-wrapper {
    margin-bottom: var(--spacing-l);
  }

  .table-content {
    td {
      vertical-align: middle;

      > span {
        display: block;
      }

      [class*="Table__StyledExpandCellWrapper"] { // To remove CI table row expander icon default margin
        margin-top: var(--spacing-zero);
      }
    }
  }
}

.ci-cd-view-all-builds{
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-xxl) var(--spacing-xxl);
}

.ci-cd-run-builds-modal__dropdown {
  .integrations-add-card__segment {
    .Select--single.pm-select {
      font-size: var(--text-size-m);
      height: var(--spacing-xxl);
      width: 432px;
      margin: var(--spacing-zero);
    }
    .Select--single.pm-select .Select-menu {
      max-height:350px;
      overflow-y: auto;
    }
  }
  .integrations-add-card__label-title {
    font-weight: var(--text-weight-bold);
  }
}

.delete-option {
  color:var(--content-color-error);
  &:hover {
    color: var(--content-color-secondary);
  }
}

.compact-empty-state-content {
  margin:var(--spacing-s) var(--spacing-xl);
  padding:var(--spacing-zero) var(--spacing-l);
  display:flex;
  color:var(--content-color-secondary);
  font-size:var(--text-size-m);
}

.error-compact-builds {
  padding: var(--spacing-zero) var(--spacing-s);
  display:flex;
  color:var(--content-color-secondary);
  font-size: var(--text-size-m);
  margin: var(--spacing-l);
}

.ci-cd-configured-test-header {
  display:flex;
  background: var(--background-color-secondary);
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  height: var(--spacing-xxl);
  align-items:center;
  padding: var(--spacing-zero) var(--spacing-l);
  .drop-icon {
    height: var(--spacing-xxl);
    padding: var(--spacing-zero);
    cursor:pointer;
    background: var(--background-color-secondary);
  }
  .title {
    margin-left: var(--spacing-xs);
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    font-weight: 600;
    width: 80%;
  }
  .run-build-btn {
    float: right;
    margin-left: auto;
    flex: none;
  }
  .integration-more-options {
    display:flex;
    cursor: pointer;
  }
}

.menu-item-checkbox {
  border-radius: var(--spacing-zero);
  &.is-selected {
    background-color: var(--base-color-info);
  }
}



.run-build-modal-button {
  width:25%;
  margin: var(--spacing-l) var(--spacing-zero);
}

.builds-page-icon {
  display:flex;
  padding:var(--spacing-xs);
}

.builds-page-icon-spaced {
  display:flex;
  padding:var(--spacing-zero) var(--spacing-l);
}
.menu-item-div {
  width: 100%;
}

.span_text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dynamic-container{
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.rb-modal {
  &__content {
    overflow:visible;
    margin: var(--spacing-s) var(--spacing-s);
    padding: var(--spacing-xs) var(--spacing-l) var(--spacing-s) !important;
  }
  &__footer {
    justify-content: flex-end !important;
    gap: var(--spacing-m);
    padding-bottom: var(--spacing-s) !important;
  }
}


.configure-newman-button-tooltip {
  padding: var(--spacing-l);

  &__header {
    font-size: var(--spacing-m);
    line-height: var(--spacing-l);
    font-weight: var(--text-weight-medium);
    margin-bottom: var(--spacing-s);
    color: var(--content-color-primary);
  }

  &__content {
    font-size: var(--spacing-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
    margin-bottom: var(--spacing-l);
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
  }
}

.configure-newman-button-popover {
  min-width: 320px;
}

.integration-options-dropdown-button {
  height: var(--size-s);
  min-width: var(--size-s);
  width: var(--size-s);
}

.ci-cd-builds-button {
  margin-top: var(--spacing-s);
}

.row_icon {
  padding: var(--spacing-m) var(--spacing-s);
  height: var(--size-xs);
  width: var(--size-xs);
}

.table-without-head{
  thead{
    display: none;
  }
}

.build-expand-table-wrapper {
  background-color: var(--background-color-secondary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

  .build-expand-table {
    margin-left: var(--spacing-xxl);
  }
}

.build-row-text-with-icon {
  margin-left: var(--spacing-s);
}

build-row__link__icon {
  margin-top: var(--spacing-zero);
}
