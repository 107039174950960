.monitor-offline-container {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.monitor-offline-detail-tab {
  justify-content: center;
}

.monitor-offline {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 200px;
}

.monitor-offline-text-container {
  text-align: center;
  line-height: var(--line-height-m);
  padding-bottom: 20px;

  &__header {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-l);
    margin-bottom: var(--spacing-s);
    color: var(--content-color-secondary);
  }

  &__body {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
  }
}

.monitor-offline-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-l);
}

.monitor-offline-icon {
  height: var(--size-m);
  width: var(--size-m);

  svg {
    height: var(--size-m);
    width: var(--size-m);
  }
}

.monitor-offline-detail-body-results {
  margin-top: 60px;
}
