$requester-close-icon-width: 9px;
$requester-close-icon-height: 9px;

.requester-tabs {
  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    left: 0;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
}

.requester-tabs {
  height: 41px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  overflow-x: scroll;
  // We need to add the scrollbar-width explicitly here because of an unexpected behavior of scrollbars
  // in Firefox on Ubuntu Systems. Check more information about this here: https://postmanlabs.atlassian.net/browse/APPSDK-1003
  scrollbar-width: none;

  &.is-options-open {
    padding-bottom: 200px;
  }

  .requester-tab-title-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    overflow: hidden;

    .requester-tab-title__text-wrapper {
      display: flex;
      overflow: hidden;
    }

    .requester-tab__icon {
      opacity: var(--requester-tab-icon-opacity);
      padding: var(--spacing-zero);

      .collection-sidebar-list-item__request__label-wrapper {
        margin-top: 1px;
      }
    }
  }

  .requester-tab {
    box-sizing: border-box;
    width: 172px;
    flex: 1 1;
    background-color: var(--background-color-primary);
    border-left: var(--border-width-default) var(--border-style-solid);
    border-right: 0;
    border-bottom: 0;

    &:last-child {
      border-right: 0;
    }

    .requester-tab-title-wrapper {
      margin-bottom: 1px;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--border-color-default);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 8px;
      right: 8px;
      height: 1px;
      background-color: var(--base-color-brand);
      opacity: 0;
    }
  }

  .requester-tab.is-active {
    height: 41px;
    border-left: var(--border-width-default) var(--border-style-solid);
    border-bottom: 0;

    &::after {
      opacity: 1;
    }
  }

  .requester-tab-create,
  .requester-tab-options {
    flex: 0 0 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.is-scrolling-right {
    &:before {
      display: none;
    }

    &:after {
      background-image: linear-gradient(to right, transparent, $app-background-color);
    }
  }

  &.is-scrolling-left {
    &:before {
      background-image: linear-gradient(to left, transparent, $app-background-color);
    }

    &:after {
      display: none;
    }
  }

  &.is-scrolling-both {
    &:before {
      background-image: linear-gradient(to left, transparent, $app-background-color);
    }

    &:after {
      background-image: linear-gradient(to right, transparent, $app-background-color);
    }
  }
}

.requester-tab,
.requester-tab-create {
  box-sizing: border-box;
  overflow: hidden;

  margin: var(--spacing-zero);
  padding: var(--spacing-zero) var(--spacing-s);

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 11px;
  font-family: $font-primary;

  @include unselectable;
  @include clickable;
}

.requester-tab-create > .requester-tab-create-icon,
.requester-tab-options > .requester-tab-options-icon{
  padding: var(--spacing-xs);
}

.requester-tab-options {
  box-sizing: border-box;
  border: 0;
  height: 39px;
  background-color: $app-background-color;
  margin-right: 10px;
  padding: 0 6px;
}

.tab-titles-list {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-titles-list::-webkit-scrollbar {
  display: none;
}


.requester-tab {
  color: var(--content-color-secondary);
  height: 41px;
  min-width: 76px;
  position: relative;
  border-top: 0;
  border-image-slice: 0 1 0 1;
  border-image-source: linear-gradient(
    to bottom,
    var(--background-color-primary) 30%,
    var(--border-color-default) 30%,
    var(--border-color-default) 70%,
    var(--background-color-primary) 70%,
    var(--background-color-primary) calc(100% - 1px),
    var(--border-color-default) calc(100% - 1px)
  );

  &.is-active {
    color: var(--content-color-primary);

    .requester-tab__icon {
      opacity: 1;
    }
  }

  &.is-hidden {
    display: none;
  }

  &.is-preview {
    font-style: italic;
  }

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: var(--border-width-default) var(--border-style-solid);
  }

  &:first-child.is-active{
    border-left: 0;
  }

  &.is-dragged {
    opacity: 0.1;
  }

  // DSUI-821 Validate
  &.is-drop-hovered {
    &.is-hover-left {
      box-shadow: inset 3px 0px 0px 0px var(--background-color-tertiary);
    }

    &.is-hover-right {
      box-shadow: inset -3px 0px 0px 0px var(--background-color-tertiary);
    }
  }

  .requester-tab__fade {
    height: 32px;
    width: 16px;
    position: absolute;
    background: var(--background-color-primary);
    right: 0;
  }

  &:hover {
    .requester-tab__fade {
      width: 32px;
    }

    .requester-tab__icon {
      opacity: 1;
    }

    .requester-tab__name {
      color: var(--content-color-primary);
    }
    .requester-tab__name {
      color: var(--content-color-primary);
    }
  }

  .requester-tab__close {
    position: absolute;
    right: 2px;
    z-index: 1;
  }

  &:not(:hover) {
    .requester-tab__close {
      &.is-dirty {
        height: 6px;
        width: 6px;
        background-color: var(--base-color-brand);
        background-image: none;
        border-radius: 16px;
        border: 6px solid var(--background-color-primary);
      }

      &.is-dirty {
        .requester-tab__cross {
          display: none;
        }
      }
    }
  }

  .requester-tab__cross {
    display: none;
    width: 20px;
    height: 20px;
  }

  @include hover {
    .requester-tab__cross {
      display: inline-flex;
    }
  }
}

.requester-tab-create,
.requester-tab-options {
  justify-content: center;
  padding: 0;
  margin-right: 0px;
}

.requester-tab-create {
  margin: var(--spacing-xs) 2px var(--spacing-xs) var(--spacing-xs);
  border-left: none;
}

.requester-tab-options__label {
  flex: 1;
  white-space: nowrap;
}

.requester-tab-options__shortcut {
  padding-left: 10px;
  color: $dropdown-menu-item-shortcut-label-color;
}

.requester-tab__name {
  font-family: var(--text-family-default);
  line-height: var(--line-height-s);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
  padding-left: var(--spacing-xs);
  flex: 1;
  align-self: center;
  white-space: nowrap;
}

.requester-tab__conflict-message {
  display: inherit;
  margin-right: var(--spacing-xs);
  line-height: var(--line-height-m);
}

.tab-actions-dropdown {
  .dropdown-menu {
    min-width: 220px;

    .dropdown-menu-item--force-close-all,
    .dropdown-menu-item--force-close-active {
      @include hover {
        background-color: var(--base-color-error);
        color: var(--content-color-constant);

        .requester-tab-options__shortcut {
          color: var(--content-color-constant);
        }
      }
    }

    .dropdown-sub-menu-item {
      width: 220px;
    }
  }
  .requester-tab-options-btn {
    width: var(--size-m);
    height: var(--size-m);
    margin-right: var(--spacing-s);
  }
}

.requester-tab__icon {
  padding: var(--spacing-zero) var(--spacing-xs);
  display: flex;
}

.requester-tabs {
  &.is-navigation-button {
    .requester-tab {
      &:last-child {
        border-right: 0;
      }
    }
  }
}
