.monitor-topbar-actions-tooltip-wrapper,
.monitor-sidebar-tooltip-wrapper {
  display: flex;
  flex:1;

  &.d-flex {
    display: flex;
  }

  &.d-block {
    display: block;
  }

  &.d-inline-block {
    display: inline-block;
  }
}
