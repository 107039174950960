.share-workspace-modal {
  @extend .create-new-workspace-modal;
  width: 100%;

  // Overriding the css properties that are derived from create-new-workspace-modal css class
  .modal-content {
    padding: var(--spacing-xl) var(--spacing-xl);
  }

  .modal-footer {
    margin: unset;
  }

  .add-user__list {
    height: 240px;
  }

  .empty-state--no-users {
    height: 200px;
    color: var(--content-color-secondary);
    font-size: var(--text-size-s);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-roles__container {
    height: 200px;
  }

  .create-new-workspace__team_info {
    margin: 6px var(--spacing-zero) var(--spacing-l) var(--spacing-zero);
  }

  .create-new-workspace__input-group__label {
    margin: var(--spacing-xs) var(--spacing-zero);
    line-height: 1.67;
  }

  &__subtext {
    color: $workspace-create-new-disabled-color;
    a {
      flex: 1;
      color: $button--text-color;
      cursor: pointer;
    }
  }

  &__alert {
    margin-bottom: var(--spacing-l);
  }

  &__copy-invite-link {
    margin-right: auto;
    margin-left: var(--spacing-zero);
  }

  .button-medium {
    width: 120px;
  }

  .button-large {
    width: 200px;
  }
}
