$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.pm-invite-form {
  position: relative;

  &__field {
    .pm-icon.pm-icon-md {
      margin: 8px;

      svg {
        width: 16px;
      }

      path {
        fill: var(--grey-40);
      }
    }

    .pm-form-control-md {
      border-color: var(--border-color-default);
      font-size: var(--text-size-m);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 48px 0 var(--spacing-xxl);
    }
  }

  &__add {
    position: absolute;
    top: 12px;
    font-size: var(--text-size-m);
    right: var(--spacing-l);
  }

  &__actions {
    $secondary-action-width: 125px;

    position: relative;
    margin: var(--spacing-xxl) 0 var(--spacing-l);
    padding-left: $secondary-action-width + var(--spacing-s);

    .pm-btn-secondary {
      left: 0;
      width: $secondary-action-width;
      position: absolute;
    }
  }
}

.pm-invite-list {
  $role-card-width: 200px;
  $role-button-width: 110px;
  $role-select-width: 120px;

  list-style: none;
  margin: 0;
  padding: 0;

  $item-padding-right: 48px;

  height: 200px;
  overflow: auto;
  border: 1px solid var(--border-color-default);
  border-top: none;
  font-size: var(--text-size-m);
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);

  &__item {
    height: 40px;
    width: 100%;

    .avatar {
      margin-left: 8px;
    }
  }

  &__empty {
    position: relative;

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--content-color-secondary);
    }
  }

  .pm-select {
    max-width: $role-select-width;

    .Select-control {
      width: $role-select-width;
    }
  }

  &__custom-tooltip {
    margin-top: -4px;
  }

  &__roles {
    position: absolute;
    right: 40px;
    top: var(--spacing-xs);

    & + p {
      padding: 10px 168px var(--spacing-s) $item-padding-right;
    }
  }

  .pm-select.is-disabled {
    .Select-control {
      cursor: not-allowed;
    }
  }

  &__icon {
    background-size: contain;
    position: absolute;
    top: var(--spacing-xs);
    left: var(--spacing-s);
  }

  &__email-container {
    width: 60%;
    overflow: hidden;
  }

  &__email {
    display: inline-block;
    padding: 10px var(--spacing-xxl) var(--spacing-s) 8px;
    margin: 0;
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    color: var(--content-color-primary);
  }

  &__remove {
    //margin: var(--spacing-s);
    right: 0;
    padding: 0;
    top: 4px;
    //margin-top: 8px;
  }

  &__additive-info {
    margin-right: var(--spacing-s) + var(--spacing-xs);
    position: absolute;
    right: 0;
    padding: 0;
    height: 16px;
    width: 16px;
  }

  &__additive-warn {
    position: absolute;
    right: 160px;
    top: 50%;
    margin-right: var(--spacing-s);
    transform: translateY(-50%);
  }

  &__user-icon {
    top: var(--spacing-s);
    left: var(--spacing-s);
    height: 24px;
    width: 24px;
  }

  &__button {
    display: block;
    margin-left: auto;
    background-color: var(--background-color-secondary);
    text-align: center;
    min-width: $role-button-width;

    &:hover {
      background-color: var(--background-color-secondary);
    }

    &-down-arrow {
      padding-left: var(--spacing-xs);
      vertical-align: top;
    }
  }

  &__label {
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
  }

  &__disabled-option-tooltip {
    font-size: var(--text-size-m);
    margin: 0;

    @media (max-width: $breakpoint-sm) {
      max-width: 90px;
    }
  }

  &__disabled-option-tooltip-heading {
    font-weight: var(--text-weight-medium);
  }

  &__disabled-option-tooltip-description {
    color: var(--content-color-secondary);
    margin: var(--spacing-xs) 0;
  }
}
