.capture-requests-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  .capture-requests-label-text {
    margin-left: var(--spacing-xs);
  }
}

@media only screen and (max-width: 1024px) {
  .capture-requests-label-text {
    display: none;
  }
}
