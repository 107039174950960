.settings-container {
  color : var(--content-color-primary);
}

.capture-settings-content {
  padding: 20px 20px 0px 20px;
  font-size: 12px;
}

.capture-settings-connection-wrapper{
  padding: 0px;
}

.capture-settings-toggle-switch-container {
  padding: 8px 20px 0px 20px;
  font-size: 12px;
}
