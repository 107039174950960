/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.console-list {
  flex: 1;
  overflow-y: auto;
  position: relative;

  &.is-disabled {
    opacity: 0.4;
  }
}

.console-list__empty {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  text-align: center;
  padding: var(--spacing-l);
  @include unselectable;

  .console-list__empty--description {
    margin-top: var(--spacing-s);
  }
}

.console-list__empty__container {
  height: 100%;
  width: 100%;
  display: flex;

  // @note this is a hack here because height won't be 100% without it
  position: absolute;

  .console-list__empty {
    margin: auto;
  }
}

.console-list__loading {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.console-list-item {
  font-size: var(--text-size-m);
  line-height: var(--line-height-xs);
  font-family: var(--text-family-code);
  color: var(--content-color-primary);

  .c-flex {
    padding: 6px 10px var(--spacing-xs);
    display: flex;
    align-items: flex-start;
    min-height: var(--size-s);
    box-sizing: border-box;
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .c-flex__left {
      flex: 1;
      display: flex;

      .c-li__icon {
        margin-right: 5px;
      }
    }

    .c-flex__right {
      display: flex;
      flex: 0;

      .c-li__icon {
        margin-left: var(--spacing-s);
      }
    }

    &.is-error {
      background-color: var(--background-color-error);
      color: var(--content-color-error);
    }

    &.is-warning {
      background-color: var(--background-color-warning);
    }

    &.is-cloud {
      background-color: var(--background-color-tertiary);
    }

    &.is-indirect {
      .c-li__network-msg {
        opacity: 0.6;
      }
    }
  }

  .c-li__icon {
    width: 12px;

    &.empty {
      padding: var(--spacing-zero) 6px;
      box-sizing: border-box;
    }
  }

  .c-li__timestamp {
    margin-right: var(--spacing-s);
    color: var(--content-color-tertiary);
  }

  .c-li__msg-wrap {
    display: flex;
    flex: 1;
    flex-flow: row wrap;

    .c-li__r-fill {
      height: 4px;
      width: 0;
      overflow: hidden;
      flex-basis: 100%;
    }

    .c-li__msg-obj {
      margin-right: var(--spacing-m);
      white-space: pre-wrap;
      word-break: break-all;
      li > ol > li {
        // to override element level style written in the codebase for react-inspector
        // other option was forking react-inspector
        margin: var(--spacing-xs) var(--spacing-zero) var(--spacing-zero) !important;
      }
    }

    .c-li__msg-str {
      margin-right: var(--spacing-m);
    }
  }

  .c-li__network-wrap {
    flex: 1;

    .c-li__network-msg {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      word-break: break-all;

      .network-msg__caret {
        margin-top: -2px;
      }
    }

    .c-li__network-string {
      padding-left: 18px;

      .network-string__name {
        cursor: pointer;
        margin-left: -18px;
        display: flex;
        color: var(--content-color-secondary);
        line-height: var(--line-height-m);
        align-items: center;

        .info-button {
          margin-left: var(--spacing-s);
        }
      }

      .network-string__value {
        word-break: break-all;
        color: var(--content-color-success);
        line-height: var(--line-height-m);

        &.body {
          background: var(--background-color-tertiary);
          padding: var(--spacing-s);
          color: var(--content-color-primary);
          border-radius: var(--border-radius-default);
          margin: 2px var(--spacing-zero) var(--spacing-zero) -var(--spacing-s);
        }
      }

      .network__value {
        word-break: break-all;
        color: var(--content-color-info);
      }
    }

    .c-li__network-wrap--expanded {
      margin-left: var(--spacing-s);

      .c-li__network-tabs {
        margin: var(--spacing-xs) var(--spacing-zero);
      }

      .c-li__network-expanded {
        display: flex;

        .network-expanded__left {
          flex: 1;

          // to override element level style written in the codebase for react-inspector
          li > ol > li {
            line-height: var(--line-height-m);
          }

          .console-net-item {
            word-break: break-all;

            .console-net-item__body {
              padding-bottom: var(--spacing-zero);
              border-bottom: none;
            }

            .console-net-item__body-left__request,
            .console-net-item__body-left__request-body,
            .console-net-item__body-left__response,
            .console-net-item__body-left__response-body {
              padding-left: var(--spacing-zero);
            }
          }
        }

        .network-expanded__left > * {
          margin: 7px var(--spacing-zero);
        }

        .network-expanded__right {
          margin-top: 7px;
          min-width: 52px;
          text-align: right;

          .network__switch-view {
            font-weight: var(--text-weight-medium);
            padding: var(--spacing-zero);
          }
        }
      }

      .c-li__network-error,
      .c-li__network-warning {
        margin: var(--spacing-s) var(--spacing-zero);
      }
    }

    .network-expand__caret {
      display: inline-block;
      font-size: 8px;
      margin-right: var(--spacing-xs);
      user-select: none;
      transform: rotateZ(0deg);
      color: var(--content-color-secondary);

      &.down {
        transform: rotateZ(90deg);
      }
    }

    .network__message {
      flex: 1;
    }

    .network__response-meta {
      color: var(--content-color-success);
      display: flex;

      .separator {
        margin: var(--spacing-zero) var(--spacing-s);
        height: 11px;
      }
    }
  }

  .c-li__empty::after {
    content: '∅';
    opacity: 0.6;
  }

  .c-li__clear-msg{
    font-style: italic;
    opacity: 0.6;
  }
}

// Whitespace formatter styles
.wf__item {
  white-space: pre-wrap;

  .wf__sp {
    background: radial-gradient(circle, var(--content-color-primary), rgba(192,192,0,0) 1px);
    opacity: 0.6;
  }

  .wf__tb {
    font-size: 6px;
  }
  .wf__nl {
    // To establish some dimension
    display: block;
    width: 8px;
    position: relative;
  }

  .wf__tk {
    word-break: break-all;
    line-height: 14px;
  }

  .wf__tb::before {
    content: '→';
    opacity: 0.6;
    font-size: var(--text-size-xs);
    position: absolute;
  }

  .wf__nl::before {
    content: '↵';
    opacity: 0.4;
    font-size: var(--text-size-xs);
    position: absolute;
    padding-left: .5px;
  }

  .wf__ch {
    opacity: 0.6;
    margin: 1px;
  }

  .wf__qt {
    &::after {
      content: '"';
      opacity: 0.6;
    }
  }
}

.c__react-inspector-value {
  color: var(--content-color-success);
  word-break: break-all;
  margin-left: var(--spacing-xs);
}

.is-hidden {
  display: none;
}

.node-renderer__root-node {
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  .info-button {
    margin-left: var(--spacing-s);
  }
}

.network-formatter__item {
  line-height: var(--line-height-m);
}

.console-list-item {
  &.is-overflown:last-of-type {
    .c-flex {
      border-bottom: none;
    }
  }
}

.console-list__pretty {
  span {
    li {
      div {
        display: inline-flex;

        span {
          line-height: var(--line-height-m);
        }
      }
    }
  }
}

.console__large-body__btn {
  font-weight: var(--text-weight-medium);
  margin-left: 2px;
  background: none;
  height: unset;
  margin-bottom: 2px;

  &:hover {
    background: none;
  }
}

.console__body-viewer__open-icon {
  @include icon-color(var(--base-color-primary));
}

.console__inline__open-icon {
  vertical-align: middle;
  margin-bottom: 2px;

  @include icon-color(var(--base-color-brand));
}

.console__unsupported-body {
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
