.team-integrations-no-search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  color: $app-generic-text-color;

  .no-search-results-image {
    @include icon(
      '../assets/images/thumbnails/emptysearch.svg',
      120px,
      105px
    );
    margin-bottom: 20px;
  }
  &__label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: var(--spacing-s);
  }

  &__subtext {
    font-size: 12px;
    &--body {
      opacity: unset;
      .external-link {
        &__icon {
          svg {
            height: var(--spacing-m);
            width: var(--spacing-m);
            margin-left: var(--spacing-xs);
            color: var(--content-color-link);
          }
        }
      }
      .pm-link {
        color: var(--content-color-link);
      }

    }
  }

  &__illustration-wrapper {
    margin-bottom: var(--spacing-l);
  }

}

