$modal-button-focus-border-color: var(--base-color-info);

$modal-header-height: 48px !default;
$modal-footer-height: 80px !default;
$modal-footer-separator-width: 1px !default;


.ReactModal__Overlay--after-open {
  background-color: var(--background-modal-backdrop) !important;
}

.modal {
  border-radius: var(--border-radius-l);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 120;
  font-family: var(--text-family-default);
  border: 1px solid var(--modal-border-color);
  box-shadow: var(--modal-box-shadow);

  .modal-header {
    border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
    flex: 0 0 $modal-header-height;
    box-sizing: border-box;
  }

  .modal-content {
    flex: 1;
    box-sizing: border-box;
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);

    &.external-link {
      @include clickable;
      color: var(--content-color-link);
    }

    .learn-more-button {
      padding-left: var(--spacing-zero);
    }
  }

  .modal-footer {
    border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);
    flex: 0 0 $modal-footer-height;
    box-sizing: border-box;
  }
}

.modal-header {
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: row;

  .modal-header-title {
    border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
    @include unselectable;
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
  }
}

.modal-header-title {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  padding: var(--spacing-m) var(--spacing-l);
}

.modal-header-close-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: $modal-header-height;
  padding: var(--spacing-s);
  box-sizing: border-box;
}

.modal-header-close-button {
  @include unselectable;
  @include clickable;
}

.modal-content {
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-xl);
  color: var(--content-color-primary);
  overflow-y: auto;

  &.is-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-footer {
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-xl);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  & > .btn {
    margin-left: var(--spacing-m);
  }

  &.is-separated {
    border-top:
      $modal-footer-separator-width
      solid
      var(--border-color-default);
  }
}

.invite-signed-out-modal {
  height: 60vh;
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;

    .btn-text {
      padding: var(--spacing-zero);
    }

    .modal-text {
      .btn-text {
        padding: var(--spacing-zero) 3px;
      }
    }
  }

  .signout-out-signin-btn {
    margin-top: var(--spacing-xxl);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
  }
}

.changelog-content {
  hr {
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
    border-bottom-width: 0;
    color: var(--content-color-secondary);
  }

  a {
    color: var(--content-color-link);
    text-decoration: none;
  }

  img {
    width: 100%;
  }
}

.release-notes-modal {
  .release-notes-modal--view-changelog-link {
    padding-top: var(--spacing-xl);
    padding-left: var(--spacing-zero);
  }
}
