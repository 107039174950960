/**
 * Styling for `CollectionConfigurationList` and `FolderConfigurationList` component
 */
.collection-configuration-list {
  height: 100%;
  overflow-y: auto;
  padding: 0px 10px;
  width: 100%;
}

.collection-configuration-unavailable {
  margin-left: var(--spacing-l);
}
