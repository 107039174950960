.response-viewer-empty {
  flex: 1;

  display: flex;
  flex-direction: column;
  overflow: auto;

  &__header {
    flex: 0 0 32px;
    background-color: var(--background-color-primary);
    border-style: solid;
    border-width: var(--border-width-default) 0 var(--border-width-default) 0;
    border-color: var(--border-color-default);
    line-height: var(--line-height-m);

    .layout-2-column & {
      border-top-width: 0;
    }

    color: var(--content-color-secondary);
    font-size: var(--text-size-l);
    display: flex;
    align-items: center;
    padding-left: var(--spacing-s);
  }

  &__content {
    flex: 1;
    background-color: $app-background-color;

    display: flex;
    flex-direction: column;

    &__editor {
      flex: 1;

      margin: var(--spacing-s);
      display: flex;
      flex-direction: row;

      &__content {
        flex: 1;

        display: flex;
        flex-direction: column;

        align-items: center;
        margin: auto; // keep it in the center
      }
    }
  }

  &__onboarding {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include unselectable;

    &__heading {
      font-family: var(--text-family-default);
      font-size: var(--text-size-m);
      color: var(--content-color-secondary);
      line-height: var(--line-height-m);
      margin-top: var(--spacing-l);
    }
  }

  &__onboarding-recommendation-viewer {
    position: sticky;
    margin: var(--spacing-zero) var(--spacing-l);
    bottom: 16px;
  }
}

.onboarding-intro-video-modal {
  .modal-content {
    padding: var(--spacing-zero);
    overflow: hidden;
  }
}
