.add-to-workspace-empty--container {
  border: 1px solid var(--border-color-default);
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 40px 75px;

  .add-to-workspace-empty--title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .add-to-workspace-empty--subtext {
    opacity: 0.6;
    margin-bottom: 20px
  }

  .docs-link {
    margin: 15px 0 20px;
  }
}
