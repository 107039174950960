.fetching-team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.team-onboarding__page-loader {
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
}

.team-onboarding {
  overflow-y: auto;
  height: 100vh;
}
