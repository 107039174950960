
.team-integrations {
  &__list {
    @include unstyled-list;
    display: flex;
    flex-wrap: wrap;

    padding: 0 0 var(--spacing-l) 0;
    padding-left: var(--spacing-m);

    li {
      margin-bottom: var(--spacing-l);
      display: inline-flex;
      flex-direction: column;
      border: solid thin var(--border-color-default);
      border-radius: var(--border-radius-default);
      box-sizing: border-box;

      width: 288px;
      margin: var(--spacing-m);
      height: 135px;
      padding: var(--spacing-xl);

      &:hover {
        cursor: pointer;
        border: 1px solid var(--border-color-strong);
      }
    }
    &-item {
      &-service-icon-wrapper {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius-default);
        position: relative;
        &__fallback-icon{
          @include icon('../assets/images/thumbnails/integration-stroke.svg',16px,16px);
        }
      }

      &-service-icon {
        height: auto;
        width: 100%;
      }

      &-service-name {
        font-weight: var(--text-weight-regular);
        font-size: var(--text-size-m);
        line-height: var(--line-height-xl);
        color: var(--content-color-primary);
        padding-left: var(--spacing-s);
        padding-right: var(--spacing-l);

        &__div {
          max-width: calc(4*var(--spacing-xl));
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &-rule-name {
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-medium);
        line-height:  var(--line-height-l);
        color: var(--content-color-primary);
        margin-bottom: var(--spacing-l);
        display: flex;
        flex-grow: 1;
        height: var(--spacing-xxxl);
      }
      &-rule-details {
        display: flex;
        flex-direction: row;
      }
      &-rule-details &-service-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }


      &-status-item {
        // Unused style
        .pm-icon-error path {
          fill: var(--content-color-error);
        }
      }

    }
  }



}

.team-integrations.page-1{
  .integrations-search {
    padding-left: 0px;
  }
}
