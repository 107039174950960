.no-edit-integration, .no-create-integration {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  &__illustration-wrapper {
    margin-bottom: 35px;
  }
  &__title {
    font-size: var(--text-size-xl);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-bottom: 20px;
  }
  &__subtitle {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    text-align: center;
    margin-bottom: 40px;
    max-width: 500px;
    line-height: var(--line-height-m);
  }

}


.no-edit-integration, .no-create-integration {
  &__illustration-wrapper--integrations {
    .integrations-empty__illustration {
      @include icon(
        '../assets/images/empty-state/alert.svg',
        120px,
        120px
      );
    }
  }
}
