.new-schema-editor-options {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__active-language {
    margin-right: var(--spacing-m);
    color: var(--content-color-primary);
    display: flex;
    align-items: center;
    font-size: var(--text-size-m);
    font-weight: var(--text-weight-medium);
  }

  .new-schema-editor-options__normal-btn {
    margin-right: var(--spacing-m);
    height: var(--size-s);
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    @include icon-color(var(--content-color-primary));
  }

  .new-schema-editor-options__icon-button--is-active {
    @include icon-color(var(--base-color-brand));
  }

  .new-schema-editor-options__beautify-button {
    &.is-disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}


