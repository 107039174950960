.folder-editor-header__action {
  margin-right: var(--spacing-s);

  &.last-item {
    margin-right: 0;
  }

  i {
    margin-right: var(--spacing-xs);
  }
}

.folder-editor-header__share-button {
  @extend .folder-editor-header__action;

  margin-right: var(--spacing-xs);

  i {
    margin-right: 0;
  }
}
