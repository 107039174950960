.workspace-list-item {
  padding: 0 8px;
  min-height: 58px;
  max-height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color-default);
  @include unselectable;
  @include clickable;

  &:hover {
    background-color: var(--highlight-background-color-primary);
  }

  &.is-selected {
    background-color: var(--background-color-brand);
  }

  &.is-disabled:not(.is-view-mode) {
    cursor: default;

    .avatar-group,
    .workspace-list-item__details {
      opacity: 0.5;
    }
  }

  &.is-disabled,
  &.is-view-mode {
    background-color: transparent;
  }

  &.is-view-mode {
    padding: 0 0 0 4px;
  }

  .avatar-group {
    margin: 0;
  }

  &__workspace-subtitle {
    max-width: 100%;
    font-size: 11px;
    display: inline-flex;
    color: var(--content-color-secondary);
    @include text-truncate-ellipsis;
  }

  &__public-share-tooltip {
    display: inline-flex;
    align-items: center;

    .pm-icon {
      width: 14px;
      height: 14px;
      margin: var(--spacing-zero) var(--spacing-s);
    }
  }

  .workspace-list-item__details-container {
    flex: 1;
    display: flex;
    min-width: 0;

    .workspace-list-item__details {
      flex: 1;
      margin-left: 8px;
      min-width: 0;

      .workspace-list-item__workspace-name {
        @include text-truncate-ellipsis;
        font-weight: 600;
      }

      .workspace-list-item__workspace-members {
        max-width: 100%;
        font-size: 11px;
        padding-right: 4px;
        display: inline-flex;
        color: var(--content-color-secondary);
        @include text-truncate-ellipsis;
      }
    }

    .workspace-list-item__workspace-action {
      font-weight: 600;
      font-size: 11px;
      margin-left: 12px;
      color: var(--content-color-secondary);
      display: flex;
      align-items: center;

      .btn-secondary {
        min-width: 54px;
        height: 24px;
      }

      .is-unjoined-and-shared {
        margin-right: 24px;
      }

      .radio {
        height: 16px;
        width: 16px;
        border: 1px solid var(--base-color-brand);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .radio-inner {
          height: 14px;
          width: 14px;
          background-color: var(--base-color-brand);
          border-radius: 50%;
        }
      }
    }
  }

  &__visibility-icon {
    margin-top: var(--spacing-m);
    margin-left: var(--spacing-m);
  }
}
