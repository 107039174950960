.radial-progress {
  position: relative;

  .progress {
    display: flex;
    align-items: center;
    justify-content: center;

    transform: rotate(270deg);
    stroke-width: 3px;

    .radial-progress__progress {
      z-index: 2;
      transition: stroke-dashoffset 1s;
    }

    .radial-progress__background {
      stroke: var(--background-color-tertiary);
    }
  }

  &.is-running {
    .progress {
      stroke: var(--base-color-info);
    }

    &:after {
      color: var(--base-color-info);
    }
  }

  &.is-finished {
    .progress {
      stroke: var(--base-color-success);
    }

    &:after {
      color: var(--base-color-success);
    }
  }

  &:after {
    content: attr(data-progress);
    position: absolute;
  }
}
