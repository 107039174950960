.user-card {
  display: flex;
  width: 240px;
  background: transparent;
  padding: var(--spacing-s) var(--spacing-l);

  .user-card__left {
    padding-right: var(--spacing-s);
  }

  .user-card__right {
    display: flex;
    flex-direction: column;
    max-width: 200px;
  }
}
