body {
  background-color: $app-background-color;

  // To disable OSX elastic scrolling at boundaries
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
}

.console-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .separator {
    margin: var(--spacing-zero) var(--spacing-xs);
    background-color: var(--border-color-default);
    height: var(--size-xs);
    width: var(--border-width-default);
  }
}

.console__text-editor__container {
  display: flex;

  .console__text-editor {
    display: flex;
    flex: 1;
  }
}

.console__text-editor__header {
  display: flex;

  .truncated-info__button {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: var(--spacing-xs);

    .truncated-info__icon {
      @include icon-color(var(--content-color-tertiary));
    }
  }
}

.console__blob-viewer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-color-primary);
  z-index: 10;
  display: flex;
  flex-direction: column;

  .is-hidden {
    display: none;
  }

  .console__text-editor__header {
    display: flex;
    justify-content: space-between;
    color: var(--content-color-primary);
    align-items: center;
    height: var(--size-m);
    padding: var(--spacing-zero) var(--spacing-s);
    @include unselectable;

    .header__section-left {
      display: flex;
      line-height: var(--line-height-s);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-medium);
    }

    .header__section-right {
      display: flex;
    }

    .header__section-right__close-icon {
      @include clickable;
    }
  }

  .console__text-editor__container {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding: var(--spacing-s);

    .console__text-editor {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
