.monitor-details-header {
  display: flex;
  height: var(--spacing-xxxl);
  flex: var(--spacing-xxxl) 0 0;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  padding: 0 var(--spacing-l);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  box-sizing: border-box;
  --monitor-name-loader-width: 200px;
  --status-badge-border-radius: 30px;

  &__monitor-name-wrapper {
    display: flex;
    flex: 1;
    min-width: 0;
    color: var(--content-color-primary);
    align-items: center;

    .status-badge {
      font-size: var(--text-size-s);
      line-height: var(--line-height-s);
      font-weight: var(--text-weight-medium);
      flex-shrink: 0;
      display: inline-block;
      padding:  var(--spacing-zero) var(--spacing-s);
      border-radius: var(--status-badge-border-radius);

      &.paused {
        color: var(--content-color-secondary);
        background-color: var(--background-color-tertiary);
      }

      &.healthy {
        color: var(--content-color-success);
        background-color: var(--background-color-success);
      }

      &.unhealthy {
        color: var(--content-color-error);
        background-color: var(--background-color-error);
      }
    }

    > .monitor-lock-icon {
      margin-left: var(--spacing-xs);
    }
  }

  &__monitor-name {
    @include text-truncate-ellipsis;

    &__loading {
      animation: fade 1s linear infinite;
      background-color: var(--background-color-tertiary);
      border-radius: var(--border-radius-default);
      height: var(--size-s);
      width: var(--monitor-name-loader-width);
    }
  }

  &__copy-link {
    visibility: hidden;
  }

  &__monitor-name-wrapper:hover &__copy-link {
    visibility: visible;
  }

  &__monitor-actions {
    display: flex;
    align-items: center;

    &__run-btn {
      display: inline-flex;
      align-items: center;

      .pm-icon {
        margin-right: var(--spacing-xs);
      }
    }

    &__refresh {
      display: flex;
      cursor: pointer;

      &__text {
        margin-left: var(--spacing-s);
        color: var(--content-color-secondary);
      }
    }

    &__refresh--loading {
      margin-right: var(--spacing-xxl);
      margin-left: var(--spacing-xxl);
    }

    &__loading-block {
      animation: fade 1s linear infinite;
      height: var(--spacing-xl);
      width: var(--spacing-xl);
      margin-left: var(--spacing-s);
      background-color: var(--highlight-background-color-transparent);
      border-radius: var(--border-radius-default);
    }
  }
}
