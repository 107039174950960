.sync-offline-status-bar {
  min-height: 32px;
  line-height: 18px;

  .infobar__icon-wrapper {
    display: none;
  }
}

.workspace-switcher__list .sync-offline-status-bar {
  height: auto;
}
