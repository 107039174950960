.schema-navigation-drawer {
  &__content {
    display: flex;
    flex: 1;
    min-height: 0px;
    min-width: 0px;

    &__loading {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    &__error {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 56px;
      padding-bottom: var(--spacing-m);
      padding-right: var(--spacing-s);
      overflow-y: overlay;
      overflow-x: hidden;

      .unable-to-load-illustration {
        min-width: 140px;
        min-height: 120px;
        margin-bottom: var(--spacing-m);
        margin-right: var(--spacing-xxl);
        margin-left: var(--spacing-xxl);
      }

      &__title, &__subtitle {
        margin-left: var(--spacing-s);
        margin-right: var(--spacing-s);
        text-align: center;
        color: var(--content-color-primary);

        &__secondary {
          margin-left: var(--spacing-s);
          margin-right: var(--spacing-s);
          margin-bottom: var(--spacing-xl);
          text-align: center;
        }
      }

      &__title {
        margin-bottom: var(--spacing-s);
      }

      &__try-again {
        font-size: var(--text-size-s);
      }
    }

    &__vertical-header {
      min-width: 48px;
      padding: var(--spacing-zero);
      display: inline;

      i {
        margin-top: 18px;
        margin-left: var(--spacing-l);
        cursor: pointer;
      }
    }

    &__container {
      width: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
      padding-bottom: var(--spacing-m);
      padding-top: var(--spacing-m);


      &:hover {
        overflow-y: auto;
      }

      &:hover &__section__node__padding {
        opacity: 1;
      }

      &__syntax-error-banner {
        color: var(--content-color-primary);
        background: var(--background-color-secondary);
        min-height: 86px;
        max-width: 350px;
        align-items: center;
        justify-content: center;
        display: flex;
        bottom: var(--spacing-zero);
        position: sticky;
        padding-right: var(--spacing-m);
        padding-left: var(--spacing-xs);
        margin-top: var(--spacing-m);
        margin-right: var(--spacing-xs);

        &__content {
          display: flex;
          margin-top: var(--spacing-xl);
          margin-bottom: var(--spacing-xl);

          &__icon {
            margin-left: var(--spacing-s);
            margin-right: var(--spacing-s);
          }
        }
      }

      &__section {
        .outline-empty-state-node {
          cursor: default;

          &:hover {
            background: unset;
          }

          &__label {
            margin-left: 20px;
            color: var(--content-color-secondary);
          }
        }

        &__node {
          display: flex;
          padding-left: var(--spacing-s);
          cursor: pointer;

          &:hover {
            background: var(--highlight-background-color-secondary);
          }

          &__padding {
            border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
            padding-left: var(--spacing-m);
            margin-left: var(--spacing-s);
            opacity: 0;
          }

          &__label {

            font-size: var(--text-size-m);
            margin-top: var(--spacing-xs);
            margin-bottom: var(--spacing-xs);
            font-weight: var(--text-weight-regular);
            line-height: var(--line-height-m);
            color: var(--content-color-primary);
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: auto 1fr;
            overflow: hidden;

            &__text{
              @include text-truncate-ellipsis;
            }

            &__top {

              margin-top: 6px;
              margin-bottom: 6px;
              font-weight: var(--text-weight-medium);
              line-height: var(--line-height-s);
              font-size: var(--text-size-m);
              color: var(--content-color-secondary);
              cursor: pointer;
              display: grid;
              grid-auto-flow: column;
              grid-template-columns: auto 1fr;
              overflow: hidden;
            }

            &__button {
              display: inline;
              padding: var(--spacing-zero);

              &__icon {
                margin-right: var(--spacing-xs);
                vertical-align: text-top;

                :hover {
                  background: var(--highlight-background-color-tertiary);
                }
              }
            }

            &__request-icon {
              top: 10%;
              float: right;
              position: relative;
            }

            &__buffer {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

.schema-editor-custom-line-decoration {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: var(--background-color-info);
}
