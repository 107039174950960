$cookies-management-domain-list-item-color--primary: var(--content-color-primary);
$cookies-management-domain-list-item-color--secondary: var(--content-color-secondary);
$cookies-management-domain-list-item-background-color: var(--background-color-primary);
$cookies-management-domain-list-item--hover-background-color: var(--highlight-background-color-primary);
$cookies-management-domain-list-item--selected-background-color: var(--highlight-background-color-secondary);
$cookies-management-cookie-list-item-background-color: var(--background-color-primary);
$cookies-management-cookie-list-item-color: var(--content-color-secondary);
$cookies-management-cookie-list-item--hover-background-color: var(--highlight-background-color-primary);
$cookies-management-cookie-list-item--selected-background-color: var(--highlight-background-color-secondary);
$cookies-management-cookie-list-item--selected-color: var(--content-color-primary);
$cookies-management-cookies-list-wrapper-border-color: var(--border-color-strong);
$cookies-management-cookie-editor-border-color: var(--border-color-strong);
$cookies-management-domain-whitelist-border-color: var(--border-color-strong);

.cookies-management-container {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.interceptor-bootcamp-lesson-banner {
  position: relative;
  padding: 0px 8px;
  background-color: var(--background-color-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  &-description {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
  &-button {
    height: 24px;
    font-weight: 600;
    line-height: 20px;
  }
  &--dismiss {
    margin-left: var(--spacing-xs);
    flex-shrink: 0;
    @include clickable;
  }
}
.cookies-management-domain-list-empty {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: var(--text-weight-medium);
  font-size: 16px;
  line-height: 28px;

  .cookies-management-interceptor-lesson {
    display: flex;
    flex-direction: column;
  }
  .cookies-management-interceptor-lesson__text {
    font-weight: normal;
    padding-top: 8px;
    padding-bottom: 36px;
    font-size: 14px;
    line-height: 24px;
  }
}
.cookies-management-banner-domain-list-wrapper {
  flex: 1;
}

.cookies-management-domain-list-wrapper {
  flex: 1;
  height: 460px;
  overflow-y: auto;
  border-radius: var(--border-radius-default);
}

.cookies-management-domain-list-item {
  min-height: 30px;
  align-items: center;
  border-bottom: 1px solid $cookies-management-cookies-list-wrapper-border-color;

  .cookies-management-domain-list-item__header {
    display: flex;
    padding: 5px 10px;
    align-items: center;

    .cookies-management-domain-list-item__header__actions {
      visibility: hidden;
    }

    .cookies-management-domain-list-item__header__action-delete{
      margin-right: 10px;
    }

    .cookies-management-domain-list-item__header__name {
      color: $cookies-management-domain-list-item-color--primary;
      margin: 7px 0;
    }

    .cookies-management-domain-list-item__header__cookie-count {
      padding-left: 10px;
      color: $cookies-management-domain-list-item-color--secondary;
      flex: 1;
    }
  }

  @include clickable;
  @include hover {
    .cookies-management-domain-list-item__header {
      background-color: $cookies-management-domain-list-item--hover-background-color;
    }

    .cookies-management-domain-list-item__header__actions {
      visibility: visible;
    }
  }

  &.is-selected {
    color: $cookies-management-domain-list-item-color--primary;
    background-color: $cookies-management-domain-list-item--hover-background-color;

    @include hover {
      .cookies-management-domain-list-item__header {
        background-color: $cookies-management-domain-list-item--selected-background-color;
      }
    }
  }
}

.cookies-management-cookies-list-wrapper {
  overflow-y: auto;
  font-size: 10px;
  padding-left: 10px;
  margin-top: 10px;
}

.cookies-management-cookies-empty-list {
  padding: 10px;
  text-align: center;
}

.cookies-management-cookies-list-item {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  padding: 5px 5px 5px 10px;
  border-radius: var(--border-radius-default);
  margin-right: 10px;
  margin-bottom: 10px;
  color: $cookies-management-cookie-list-item-color;
  background-color: $cookies-management-cookie-list-item-background-color;

  .cookies-management-cookies-list-item__name {
    color: $cookies-management-cookie-list-item-color;
  }

  .cookies-management-cookies-list-item__remove {
    padding: 5px 5px;
    margin-left: 10px;

    @include clickable;
    @include hover {
      .cookies-management-cookies-list-item__remove-icon {
        @include icon-color($icon-color-secondary);
      }
    }
  }

  &.is-selected {
    background-color: $cookies-management-cookie-list-item--selected-background-color;

    .cookies-management-cookies-list-item__remove-icon {
      // set fill
    }

    .cookies-management-cookies-list-item__name {
      color: $cookies-management-cookie-list-item--selected-color;
    }

    @include hover {
      background-color: $cookies-management-cookie-list-item--selected-background-color;
    }
  }

  @include clickable;
  @include unselectable;
  @include hover {
    background-color: $cookies-management-cookie-list-item--hover-background-color;
  }
}

.cookies-management-cookie-preview-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}


.cookies-management-cookie-domain-preview-interceptor-lesson {
  height: 32px;
}

.cookies-management-cookie-preview {
  flex: 2;
  margin-left: 20px;
}

.cookies-management-cookie-actions-container {
  display: flex;
  flex-direction: row;
}

.cookies-management-cookie-domain-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.cookies-management-cookie-actions {
  display: flex;
  flex: 2;
  margin-left: 20px;
  justify-content: flex-end;
}

.cookies-management-cookie-action-button {
  margin-left: 10px;
  height: 32px;
}

.cookies-management-cookie-editor-wrapper {
  margin: 0 10px;

  .cookies-management-cookie-editor {
    margin: 10px 0;
    border: 1px solid $cookies-management-cookie-editor-border-color;
    border-radius: var(--border-radius-default);
    height: 100px;
  }

  .cookies-management-cookie-editor__actions {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .cookies-management-cookie-editor__action {
      margin-left: 10px;
    }
  }
}

.cookie-management-container-domain-whitelist__back {
  cursor: pointer;
  display: flex;
  flex-direction: row;

  .cookie-management-container-domain-whitelist__back__text {
    font-size: 12px;
    font-weight: 600;
    color: var(--content-color-brand);
    margin-left: 8px;
  }

  .cookie-management-container-domain-whitelist__back__icon {
    display: flex;
    align-items: center;
  }
}

.cookies-management-domain-whitelist-back_button {
  margin-left: -8px; // Negative margin added to achieve the left alignment for the tertiary button
}

.cookies-management-domain-whitelist-container {
  display: flex;
  flex:1;
  flex-direction: column;
  height: 496px;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-xl);

  .cookies-management-domain-whitelist-container__add-domain {
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing-l);

    .cookies-management-domain-whitelist-container__input {
      height: 35px;
    }

    .cookies-management-domain-whitelist-container__add-button {
      height: 35px;
      max-width: 60px;
      min-width: 60px;
      margin-left: 10px;
      color: var(--content-color-primary);
    }
  }

  .cookies-management-domain-whitelist-list__empty {
    color: var(--content-color-tertiary);
    margin-top: 25px;
    user-select: none;
  }
}

.cookies-management-domain-whitelist-list {
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height:350px;
}

.cookies-management-domain-whitelist-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  padding: 0 10px;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: var(--background-color-secondary);
  }

  .cookies-management-domain-whitelist-list-item__name {
    color: var(--content-color-primary);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cookies-management-domain-whitelist-list-item__action {
    display: flex;
    cursor: pointer;
  }
}

.cookies-management-cookies__actions--add {
  margin: 0 0 10px 0;
}

.cookie-management-container__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--spacing-xl);
  .pm-link {
    text-decoration: underline;
    color: var(--content-color-secondary);
  }
  .learn-more__section {
    color: var(--content-color-secondary);
    padding: var(--spacing-s) 10px;
    float: right;
    display: inline-flex;
    align-items: center;
    font-size: calc(var(--size-xs) - 3);
  }
}
