.message-relational-info-toolbar {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: var(--size-m);
  padding: var(--spacing-xs) var(--spacing-l);

  &__deselect {
    line-height: 1.6;
  }

  & > * {
    margin-right: var(--spacing-l);
  }
}
