$es-image-width: 160px;

.request-integration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: var(--spacing-l);
  margin: auto;
  max-width: 718px;
  text-align: left;
  &__error-dog {
    width: 175px;
    height: 185px;
  }

  &__image {
    max-width: var(--es-image-width);
    margin-bottom: var(--spacing-xl);
  }

  &__title {
    color:  var(--content-color-primary);
  }

  &__body {
    line-height: var(--line-height-xs);
    color: var(--content-color-primary);
    margin: var(--spacing-l) auto var(--spacing-xxl);
  }

  &__action {
    margin-bottom: var(--spacing-xl);
    padding-top: var(--spacing-l);
    color: var(--content-color-link);
    font-weight: var(--text-weight-medium);
    .external-link {
      &__icon {
        svg {
          height: var(--spacing-m);
          width: var(--spacing-m);
          margin-left: var(--spacing-xs);
          color: var(--content-color-link);
        }
      }
    }
  }

  &__description {
    padding: var(--pacing-xxl) 0;
    display: flex;
    flex-direction: column;
  }

  &__crash-handler {
    margin-top: 200px;

    .empty-state__body {
      max-width: 500px;
    }
  }
  .pm-link {
    color: var(--content-color-link);
  }
}
