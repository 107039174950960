.modal-content {

  &.is-modal-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-content.version-tag__modal-content {
  overflow-y: hidden;
  padding-bottom: var(--spacing-m);
  padding-top: var(--spacing-m);
}

.version-tag__modal-content-heading {
  padding-bottom: 10px;
}

.version-tag__learn-btn-text {
  padding: 0px;
}

.version-tag__modal-content-dropdown {
  padding: 10px 0px;
}

.api-select__dropdown, .api-version-select__dropdown, .api-relation-select__dropdown {
  padding: 4px 0px;

  .dropdown-label {
    font-weight: 600;
    line-height: 20px;
    padding-bottom: 4px;
  }

  .dropdown-head {
    width: 310px;
    justify-content: space-between;
    border-radius: var(--border-radius-default);
    border: 1px solid var(--border-color-strong);
    background: $activity-feed-version-tag-dropdown-background;
  }

  .dropdown-head-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    color: $activity-feed-version-tag-dropdown-label;

    &.placeholder-text {
      color: $activity-feed-version-tag-dropdown-placeholder-text;
    }
  }

  .dropdown-menu-list {
    min-height: 30px;
  }
}

.version-tag__modal-content-action {
  display: flex;
  padding-top: 10px;

  .modal-cancel-btn {
    margin-right: 10px;
    font-weight: 600;
  }

  .modal-version-tag-btn {
    width: 100%;
    font-weight: 600;
  }
}

.version-tag-modal-error {
  height: 100%;
  transform: scale(0.8);
}
