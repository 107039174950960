.user-dropdown {
  max-width: 110px;

  .dropdown-button .btn {
    display: flex;

    & > span:first-child {
      @include text-truncate-ellipsis;
    }
  }
}
