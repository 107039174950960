$response-tests-item-color: var(--content-color-primary);

.response-tests-viewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.response-tests-viewer-tabs {
  padding-bottom: var(--spacing-m);
}

.response-tests-empty-message {
  font-size: var(--text-size-m);
  color: $response-tests-item-color;
  text-align: center;
  margin: auto; // make it centered
}

.response-tests-list {
  flex: 1;
  overflow-y: auto;
  font-size: var(--text-size-m);

  .response-test-item {
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-s);
  }
}

.response-test-status {
  min-width: 60px;
  padding: var(--spacing-xs) var(--spacing-zero);
  margin-right: var(--spacing-s);
  font-size: var(--text-size-m);
  font-weight: 500;
  color: var(--content-color-constant);
  text-transform: uppercase;
  border-radius: var(--border-radius-default);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.pass {
    background-color: var(--base-color-success);
  }

  &.fail {
    background-color: var(--base-color-error);
  }

  &.skipped {
    background-color: var(--background-color-tertiary);
    color: var(--content-color-secondary);
  }
}

.response-test-text {
  color: $response-tests-item-color;
  align-self: center;
}

.response-tests-error-container {
  padding: var(--spacing-s);
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  text-align: center;
  background-color: var(--background-color-error);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);

  .response-tests-error-message {
    font-weight: var(--text-weight-medium);
  }
}