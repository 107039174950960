/* stylelint-disable postman-stylelint/color-no-hex-rgb */
$breakpoint-xs: 480px !default;
$breakpoint-sm: 568px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1024px !default;
$breakpoint-xl: 1280px !default;

.collection-merge {
  $cancel-width: 88px;
  $merge-width: 332px;

  max-width: 480px;
  margin: 0 auto;
  position: relative;

  &__name {
    margin-top: var(--spacing-s);
    font-size: var(--text-size-l);
    color: var(--content-color-secondary);
  }

  &__close {
    position: absolute;
    top: var(--spacing-xxl);
    right: var(--spacing-xxl);
  }

  &__option {
    border: 1px solid var(--border-color-default);
    padding: var(--spacing-l);
    border-radius: var(--border-radius-default);
    margin-bottom: var(--spacing-l);

    > label {
      cursor: pointer;
    }

    &--active {
      border: 1px solid var(--base-color-brand);
      background: transparentize(#ff6c37, 0.97);
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;

      > label {
        cursor: not-allowed;
      }
    }
  }

  &__label {
    display: block;
    font-size: var(--text-size-m);
    margin-bottom: var(--spacing-s);
    font-weight: var(--text-weight-medium);

    &-summary {
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
    }
  }

  &__actions {
    margin-top: var(--spacing-xxl);
    position: relative;
    display: flex;

    &-merge {
      margin-left: var(--spacing-s);
    }

    &-cancel {
      width: 30%;
    }
  }

  &__cancel {
    width: $cancel-width;
    max-width: $cancel-width;
  }

  @media (min-width: $breakpoint-md) {
    &__close {
      right: var(--spacing-xxl);
    }
  }

  &__strategy-description {
    margin-top: var(--spacing-s);
  }

  &__strategy-label {
    margin-left: var(--spacing-m);
  }

  &__radio {
    position: relative;
    top: -8px;
  }

  &__option-tooltip {
    z-index: 100;
  }

  &__option-tooltip-anchor {
    display: block;
    color: unset;
  }
}
