.requester-library-header-container {
  display: flex;
  flex: 0 0 40px;
  flex-direction: row;
  padding: 0 10px;
  margin-top: 15px;
  align-items: center;
}

.requester-library-header__section__left {
  display: flex;
  flex-direction: row;

  .input-search-group {
    width: 300px;
  }

  .requester-library-header__actions {
    display: flex;
    align-items: center;

    .requester-library-header__actions-sort-button {
      display: flex;
      width: 150px;
      justify-content: space-between;
    }
  }
}

.requester-library-header__section__right {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
}

.requester-library-header__actions {
  height: 30px;

  .requester-library-header__actions__meta {
    padding: 0 10px;
    font-family: $font-primary;
    font-size: 12px;
    color: var(--content-color-primary);
  }
}

.requester-library-invite__wrapper {
  display: flex;
  padding: 20px;
  font-size: 12px;
  font-family: $font-primary;
  color: $app-generic-text-color;

  .requester-library-invite__description {
    flex: 0 0 400px;
  }

  .requester-library-invite__heading {
    font-size: 16px;
    font-weight: var(--text-weight-medium);
  }

  .requester-library-invite__textarea {
    flex: 1;
    display: flex;
    margin-right: 50px;
    .textarea-wrapper {
      flex: 1;
      padding: 0px 10px;
    }
  }

  .requester-library-invite__action {
    display: flex;
    flex-direction: column;
  }
}
