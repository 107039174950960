// Icon Sizes
$icon-dim-xs: 12px !default;
$icon-dim-sm: 16px !default;
$icon-dim-md: 24px !default;
$icon-dim-lg: 32px !default;
$icon-dim-xl: 48px !default;
$icon-dim-xxl: 64px !default;

$icon-color-normal: var(--content-color-secondary);
$icon-color-primary: var(--content-color-constant);
$icon-color-secondary: var(--base-color-brand);
$icon-color-tertiary: var(--content-color-secondary);
$icon-color-header: var(--content-color-constant);

.pm-icon {
  display: inline-flex;
}

// Icon size based modifiers
.pm-icon-xs {
  @include icon-size($icon-dim-xs);
}

.pm-icon-sm {
  @include icon-size($icon-dim-sm);
}

.pm-icon-md {
  @include icon-size($icon-dim-md);
}

.pm-icon-lg {
  @include icon-size($icon-dim-lg);
}
.pm-icon-xl {
  @include icon-size($icon-dim-xl);
}

.pm-icon-xxl {
  @include icon-size($icon-dim-xxl)
}

// Icon style based modifiers
.pm-icon-normal {
  @include icon-color($icon-color-normal);
}

.pm-icon-primary {
  @include icon-color($icon-color-primary);
}

.pm-icon-secondary {
  @include icon-color($icon-color-secondary);
}

.pm-icon-tertiary {
  @include icon-color($icon-color-tertiary);
}
