.runner-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--content-color-primary);

  @include unselectable;

  &.is-hidden {
    display: none;
  }

  .runner-contents__loading_container {
    margin: auto;
  }

  .runner-results__section-top {
    display: flex;
    flex-direction: column;

    .runner-results__section-top__tabs {
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }
  }

  .runner-results__section-bottom {
    flex: 1;
    display: flex;
  }
}

.runner-results__automate-run__menu-list{
  background-color: var(--background-color-primary);
}
