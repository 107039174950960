.collection-sidebar-list {
  display: flex;
  flex-direction: column;
  // DO NOT CHANGE this: https://github.com/bvaughn/react-virtualized/issues/773#issuecomment-328440309
  overflow: hidden;
  height: 100%;
  margin-right: 1px;

  &.prerender-scroll {
      overflow-y: auto;
    }

  @include promote-to-layer;

  &:hover {
    .collection-sidebar__indent {
      &:not(.active-indent) {
        border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      }
    }
    .is-selected {
      .collection-sidebar__indent {
          border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
      }
    }
  }
}

/* Filter */

.collection-sidebar-list[data-filter='me'] {
  .collection-sidebar-list-item.is-subscribed {
    display: none;
  }
}

.collection-sidebar-list[data-filter='team'] {
  .collection-sidebar-list-item.is-unshared {
    display: none;
  }
}

.collection-sidebar-list-item__toggle-btn {
  margin-left: var(--spacing-xs);
  height: var(--size-xs);
  width: var(--size-xs);
  background-color: unset;

  @include icon-color(var(--content-color-primary));

  @include hover {
    background-color: var(--highlight-background-color-tertiary);
    @include icon-color(var(--content-color-primary));
  }
}

.collection-sidebar-list-item__example-icon__wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 28px;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xl);
}

.collection-sidebar-list-item__ec-item-icon__wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 28px;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}

.collection-sidebar-fork-label__text {
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-xs);
  line-height: var(--line-height-xs);
  @include text-truncate-ellipsis;
  vertical-align: top;
  display: inline-block;
}

.collection-sidebar-list__request-spacing {
  height: inherit;
  width: var(--size-xs);
  margin-left: var(--spacing-xs);
}
