.monitor-details {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__monitor-actions {

    &__run{
      display: flex;
      margin-right: var(--spacing-m);

      &--loading {
        margin-right: var(--spacing-xl);
      }

      &__text {
        margin-left: var(--spacing-s);
      }
    }
  }

  &__body {
    flex: auto;
    overflow-y: auto;
  }
}

.monitor-no-jobs {
  .empty-state {
    max-width: 500px;
    margin: 0 auto;
    color: var(--content-color-primary);
    text-align: center;
    padding-top: var(--spacing-xxxl);
    font-size: var(--text-size-m);

    &__title {
      font-size: inherit;
      font-weight: var(--text-weight-medium);
      padding-top: var(--spacing-m);
    }

    &__body {
      margin: 16px auto 32px;
      line-height: 20px;
    }

    &__image {
      &.monitor-logo {
        @include icon("../assets/images/empty-state/monitor-logo.svg", 160px, 160px);
      }
    }
  }
}
