.multi-arg-message-editor {
  display: flex;
  flex: 1 auto;
  height: 100%;
  align-items: center;
  min-height: 0;
}

.args-nav-bar {
  display: flex;
  flex-direction: column;
  flex: 0 0 64px;
  height: 100%;
  overflow-y: auto;
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

  &::-webkit-scrollbar {
    display: none;
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
  }
}

.args-nav-bar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--size-m);
  padding: var(--spacing-xs);
  cursor: pointer;
  position: relative;

  -webkit-user-select: none;
  user-select: none;

  &__close {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
    top: 0px;

    &:hover {
      background-color: var(--background-color-error);
    }
  }

  &:hover {
    background-color: var(--background-color-secondary);
  }

  &.is-active {
    background-color: var(--background-color-tertiary);
  }
}
