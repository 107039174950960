.request-editor-save-button {
  .btn-group { // Save button in HTTP request header
    background-color: var(--button-secondary-background-color);
    border-radius: var(--border-radius-default);

    button {
      background-color: transparent;
      border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
      box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;

      &:hover {
        background-color: var(--button-secondary-hover-background-color);
        box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
      }

      &:disabled:hover{
        background-color: var(--button-secondary-disabled-background-color);
        box-shadow: 0 0 0 1px var(--button-secondary-disabled-outline-color) inset;;
      }

      &:focus:not(:focus-visible) {
        box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
      }
    }

    .btn {
      min-width: var(--size-s);
      height: var(--size-m);
      padding: var(--spacing-s) var(--spacing-xs);
      margin-left: -1px; // To reduce separator thickness to 1px instead of 2px
      border-left: var(--button-group-separator);
      box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
      z-index: 2;

      &:hover {
        background-color: var(--button-secondary-hover-background-color);
        border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
        box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
      }
    }
  }

  &__label {
    margin-left: var(--spacing-xs);
  }

  .request-editor-save-button-dropdown {
    min-width: 100px;
  }

  .use-browser-tabs-header.dropdown-button {

    &::after {
      position: absolute;
      width: 1px;
      height: 20px;
      background-color: var(--environment-divider-border-color);
      content: '';
      right: 24px;
      top: 6px;
    }

    &:hover, &:focus {
      &::after {
        background-color: transparent;
      }
    }

    .btn-secondary{
      background-color: transparent;

      &:hover, &:focus {
        background-color: var(--highlight-background-color-primary);
      }

      &.is-disabled {
        background-color: transparent;
      }
    }
  }

  .btn {
    height: var(--size-m);

    &:first-child {
      min-width: 50px; // override min-width of btn-small
    }

    &:not(:last-child) {
      border-right: none; // override border of btn-group
    }
  }

  .btn-group > .btn {
    min-width: var(--size-s); // override min-width of btn-small
    padding: var(--spacing-s) var(--spacing-xs); // override padding of btn-small
  }

  &--new-share-experience {
    margin-right: 0;

    .btn-group { // Save button in HTTP request header
      background-color: transparent;
    }
  }
}
