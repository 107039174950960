.runner-results__body {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-s);
  flex: 1;
  // the overflow was value was change from 'auto' to 'hidden' as there is a issue in react-virtualized library.
  // this is a hack to prevent flickering on zoom out on collection runner window.
  // visit the link to know more this hack-fix : https://github.com/bvaughn/react-virtualized/issues/773
  overflow-y: hidden;

  .runner-results__body__activity {
    display: flex;
    align-items: center;
  }
}
