$report-nav-width: 248px;
$navbar-height: 48px;

.report-pm-container {
  margin-top: 24px;
  padding: var(--spacing-zero) var(--spacing-l);
  margin-bottom: 24px;
  min-width: 320px;

  // Spacing is double on desktop
  @media (min-width: $breakpoint-md) {
    margin: 24px auto;
    padding: var(--spacing-zero) var(--spacing-s);
  }
}

.report-pm-container:before, .report-pm-container:after {
  content: " ";
  display: table;
}

.report-pm-container:after {
  clear: both;
}

@media (max-width: 768px) {
  .report-pm-container {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 768px) {
  .report-pm-container {
    max-width: 994px;
  }
}

@media (min-width: 1024px) {
  .report-pm-container {
    max-width: 1104px;
  }
}

@media (min-width: 1280px) {
  .report-pm-container {
    max-width: 1104px;
  }
}