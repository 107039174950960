.integration-page-banner {
  a {
    text-decoration: underline;
    color: var(--content-color-primary);
  }
  .alert-info {
    background-color: var(--background-color-info);
    .alert-content .alert-title{
      font-size: var(--text-size-m);
    }
  }
}

.integrations-add-actions {
  &__button {
    &--container {
      margin-top: var(--spacing-xl);
      float: right;
      padding-bottom: var(--spacing-xxxl);
    }

    &--cancel {
      border-radius: var(--border-radius-default);
      margin-right: var(--spacing-s);
    }

    &--proceed {
      border-radius: var(--border-radius-default);
    }
  }
}

.align-left {
  float: left;
}

.integration-add-actions-separator {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-s);
}

.integration-add-consent {
  margin-bottom: var(--spacing-xxl);
}

.integrations-add {
  width: 440px;
}

.integrations-form {
  &__segment {
    margin-top: var(--spacing-l);

    &__label {
      &-title {
        color: var(--content-color-primary);
        font-style: normal;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);

        span {
          color: var(--content-color-secondary);
        }
      }

      &-description {
        margin-top: 0;
        color: var(--content-color-secondary);
        font-style: normal;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);

        p {
          display: inline;
        }
      }
    }

    &__input {
      flex: 1;
      padding-top: var(--spacing-s);

      &__error {
        font-size: var(--text-size-m);
        color: var(--content-color-error);
        margin-top: var(--spacing-xs);
        &__btn {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &__helper {
        font-size: var(--text-size-m);
        margin-top: var(--spacing-xs);
        &__btn {
          text-decoration: none;
          color: var(--content-color-info);
          cursor: pointer;
        }
      }
    }
  }

  &-actions {
    &__button {
      &--container {
        margin-top: var(--spacing-xl);
        float: right;
        padding-bottom: var(--spacing-xxxl);
      }

      &--cancel {
        border-radius: var(--border-radius-default);
        margin-right: var(--spacing-s);
      }

      &--proceed {
        border-radius: var(--border-radius-default);
      }
    }
  }
}

.integrations-add-card {
  width: 100%;
  height: 100%;

  &__label-title {
    color: var(--content-color-secondary);
    font-style: normal;
    font-size: var(--text-size-m);
    font-weight: 600;
    line-height: var(--line-height-s);
    span {
      color: var(--content-color-secondary);
    }
  }

  &__segment {
    margin-top: var(--spacing-l);
  }

  &__alert {
    margin-bottom: 24px;
  }

  &__label-description {
    margin-top: 0;
    color: var(--content-color-secondary);
    font-style: normal;
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);
  }
  &__label-description-external-link {
    margin-top: 0;
    display: flex;
    color: var(--content-color-secondary);
    font-style: normal;
    font-size: var(--text-size-s);
    line-height: var(--line-height-s);
  }
  &__external-link{
    display: flex;
    align-items: center;
    color: var(--content-color-primary);
  }

  &__description {
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-xl);
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);
  }

  &__input {
    flex: 1;
    padding-top: var(--spacing-s);
  }

  &__error {
    font-size: var(--text-size-m);
    color: var(--content-color-error);
    margin-top: var(--spacing-xs);
    &__btn {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .loading-placeholder {
    justify-content: space-between;
    align-items: center;
  }

  .pm-link {
    text-decoration: underline;
    color: var(--content-color-secondary);

    &:hover {
      color: var(--content-color-secondary);
    }
  }

  &__advanced {
    color: var(--orange-40);
    margin: var(--spacing-s) 0;
    cursor: pointer;

    svg {
      color: var(--orange-40);
    }
  }

  &__select {
    width: 100%;
  }

  &__name {
    text-align: left;
  }

  &-actions {
    margin-top: var(--spacing-l);

    &__cancel {
      width: 96px;
      float: left;
    }
  }

  &__authed-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--spacing-xl);
    font-size: var(--text-size-m);
    margin-right: 8px;

    &__text {
      margin-right: 8px;
    }

    &___name {
      color: var(--content-color-secondary);
    }

    p {
      font-weight: 600;
    }

    img {
      border-radius: 50%;
      width: var(--spacing-xxl);
      margin-right: var(--spacing-xs);
    }

    span {
      font-size: var(--font-size-m);
      color: var(--content-color-secondary);
    }
  }

  &__fail-alert {
    margin: var(--spacing-l) 0;
  }

  &__workspace-switcher-icon {
    margin-right: var(--spacing-l);
    vertical-align: sub;

    // hide the warning in extra small screen to prevent any overflow
    @media (max-width: 480px) {
      visibility: hidden;
    }
  }

  &__auth-tabs {
    display: flex;
    justify-content: space-between;
    background-color: var(--background-color-primary);
    padding: 0px var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
    border-bottom: var(--border-width-default) solid var(--border-color-default);
    min-width: 440px;

    &-tab {
     &-title {
      display: flex;
      align-items: center;
      }
      &-title__label {
        font-weight: var(--text-weight-regular);
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
      }
    }
    &-tab.tab--authenticate.tab-primary {
       .tab-text-wrapper {
        margin-left: 0px;
      }
    }
    .tab-primary.is-active .tab-text-wrapper {
      box-shadow: inset 0px -1px 0px var(--content-color-primary);
    }
    .is-active .integrations-add-card__auth-tabs-tab-title__label {
        color: var(--content-color-primary);
    }
    .integrations-add-card__auth-tabs-tab-title__label {
      display: flex;
    }
    .integrations-add-card__authenticated-icon {
      margin-left: var(--spacing-xs);
    }
  }
}

.integrations-add {
  .tab-primary .tab-text-wrapper {
    margin: var(--spacing-zero) var(--spacing-m);
   }
}

.integrations-radio-button__select-group--regions {
  margin-bottom: var(--spacing-s);
  font-size: 12px;
}

.integrations-radio-button__select-group--options {
  margin-bottom: var(--spacing-s);
  font-size: 12px;
  span {
    margin-left: 4px;
  }
}

.integrations-radio-button__select-group--regions__radio {
  margin-right: var(--spacing-xs);
}

.integrations-consent {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  margin-bottom: 16px;
}

.custom-owner-input-field {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  .pm-link {
    text-decoration: underline;
    color: var(--content-color-secondary);
  }
}

.integrations-checkbox {
  margin-left: 4px;
  &__select-consent {
    display: flex;
    flex: 1;
    color: var(--content-color-secondary);
    margin-top: var(--spacing-s);
    margin-bottom: 16px;
    font-size: var(--text-size-m);

    .input-checkbox {
      margin-right: var(--spacing-s);
    }
    .input-checkbox.is-selected {
      background-color: var(--orange-40);
      border-color: var(--orange-40);
    }
  }
}


.integrations-checkbox__select-group--events {
  display: flex;
  flex: 1;
  color: var(--content-color-primary);
  margin-top: var(--spacing-s);
  font-size: var(--text-size-m);

  .input-checkbox {
    margin-right: var(--spacing-s);
  }
  .input-checkbox.is-selected {
    background-color: var(--orange-40);
    border-color: var(--orange-40);
  }
}

.integrations-checkbox__select-group {
  margin-bottom: 32px;
  &--actions {
    display: flex;
    flex: 1;
    color: var(--content-color-primary);
    margin-top: var(--spacing-m);
    font-size: var(--text-size-m);

    .input-checkbox {
      margin-right: var(--spacing-s);
    }
    .input-checkbox.is-selected {
      background-color: var(--orange-40);
      border-color: var(--orange-40);
    }

    .checkbox__helper-text {
      color: var(--content-color-secondary);
      font-style: normal;
      font-size: var(--text-size-m);
      margin-top: var(--spacing-xs);
    }
  }
  &--label-title {
    color: var(--content-color-primary);
    font-style: normal;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin-top: var(--spacing-l);
  }
}

.push-double--bottom {
  margin-bottom: 32px !important;
  &__add-to-team {
    margin-bottom: var(--spacing-l) !important;
  }
}

.custom-apigateway {
  &__input {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    margin-top: var(--spacing-s);
  }
}

.add-new-relic {
  margin-bottom: var(--spacing-xxl);
  margin-top: var(--spacing-l);
}

.integrations-add-card__input {
  .input.input-box.is-disabled {
    color: var(--content-color-secondary);
    cursor: not-allowed;
    opacity: 0.4;
  }
  .hide-toggle .password-input__toggle{
    visibility: hidden;
  }
}

.integrations-tags-segment-input__container {
  padding: 0px;
  position: relative;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  min-height: var(--controls-size-default);
  max-height: 80px;
  overflow-y: auto;
  align-items: stretch;
  background-color: var(--background-color-secondary);
}
.integrations-tags-segment-input__container:hover {
  background-color: var(--background-color-tertiary);
  cursor: text;
}

.integrations-tags-segment-input__container:focus-within {
  background-color: var(--background-color-primary);
}

.integrations-tags-segment-active__input {
  padding-left: 10px;
  padding-right: 20px;
  font-size: var(--text-size-m);
}
.integrations-tags-segment-text__content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: var(--spacing-s);
}
.integrations-tags-segment-editable__tag {
  height: 20px;
  border-radius: var(--border-radius-default);
  margin: var(--spacing-xs);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);

  .text:hover {
    cursor: text;
  }

  .pm-icon {
    margin-right: var(--spacing-s);
  }

  .tag-remove-btn:hover {
    cursor: pointer;
  }

  &-regular {
    background-color: var(--background-color-secondary);
    border: solid 1px var(--border-color-default);
  }

  &-error {
    border: 1px dashed var(--base-color-error);
    background-color: var(--background-color-error);
    color: var(--content-color-error);
  }

  &.is-selected {
    border: 1px solid var(--base-color-info);
  }
}

.integrations {
  &__external-link {
    color: var(--content-color-secondary);
    text-decoration: underline;
  }
  &__external-link:hover {
    color: var(--content-color-link);
    text-decoration: underline;
    svg {
      color: var(--content-color-link);
    }
  }
  &-form {
    &__disclosed {
      padding-left: 28px;
    }
  }
}

.integrations-banner {
  height: 92px;
  padding-top: var(--spacing-l);
  font-size: var(--text-size-m);

  &__container {
    display: flex;
    background-color: var(--background-color-info);
    padding: var(--spacing-l);
  }
  &__text-container {
    margin-left: var(--spacing-s);
  }
  &__heading {
    font-weight: var(--text-weight-medium);
    size: var(--text-size-m);
    line-height: var(--line-height-s);
    margin-bottom: var(--spacing-xs);
  }
  &__text {
    line-height: var(--line-height-m);
  }
  &__close-btn {
    float: right;
    margin-left: auto;
    cursor: pointer;
  }
}

.integrations-add-step {
  margin-top: var(--spacing-xl);
  &__title {
    font-weight: var(--text-weight-medium);
    size: var(--text-size-l);
    line-height: var(--line-height-l);
    margin-bottom: var(--spacing-s);
  }
  &__text {
    line-height: var(--line-height-m);
    font-size: var(--text-size-m);
  }
}

.integrations-info-segment {
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  &__title {
    font-weight: var(--text-weight-medium);
  }
  &__description {
    line-height: var(--line-height-s);
    font-size: var(--text-size-s);
  }
  &__value-container {
    display: flex;
  }
  &__value-segment {
    margin-top: var(--spacing-s);
  }
  &__value {
    font-weight: var(--text-weight-regular);
    line-height: var(--line-height-xxl);
  }
  &__copy-btn {
    margin-top: var(--spacing-s);
    margin-left: var(--spacing-xs);
    width: var(--size-m);
    height: var(--size-m);
    padding: var(--spacing-zero);
    cursor: pointer;
  }
  &__copy-btn-container {
    margin: var(--spacing-s);
  }

  &--account-id {
    >.integrations-info-segment {
      margin-top: var(--spacing-m);
    }
  }
}

.integrations-field-segment {
  &__role-arn {
    >.integrations-add-card__segment {
      margin-top: var(--spacing-m);
    }
  }
}

.integrations-connected-account {
  &__form {
    &__header {
      &__primary-text {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-medium);
      }
      margin-top: var(--spacing-m);
      color: var(--content-color-primary);

      &__helper {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        margin-top: var(--spacing-xs);
        &__btn {
          text-decoration: none;
          color: var(--content-color-info);
          cursor: pointer;
        }
      }

      &__btn {
        margin-left: var(--spacing-l);
      }
    }
  }
}
