.update-available {
  .update-available__version {
    font-weight: 600;
  }

  .update-available__loading-container {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
  }
  .update-available__notes-loader {
    flex: 1;
    text-align: center;
  }
}
