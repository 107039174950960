$workspace-switcher-dropdown-z-index: 1000;
// component specific token defined for the invite button in the requester header
// this token is not intended to be use anywhere else
$button-secondary-padding: 6px;

.workspace-switcher {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    .workspace-switcher__icon {
      @include icon-color(var(--content-color-primary));
    }
  }

  .error-state-wrapper {
    &__button {
      margin-top: var(--spacing-l);
    }
  }


  .workspace-switcher__active,
  .workspace-switcher__disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include unselectable;
    @include clickable;
  }

  .workspace-switcher__icon {
    margin-left: var(--spacing-xs);
    height: var(--size-xs);
    width: var(--size-xs);
    @include icon-color(var(--content-color-primary));
  }

  .workspace-switcher__disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .workspace-switcher__selector {
    outline: 1px solid var(--popover-outline-color);

    &__no-workspaces {
      max-width: 360px;
    }

    .workspace-switcher__state {
      padding: 60px 24px 24px;
      text-align: center;

      &.workspace-switcher__upgrade_to_pro {
        padding: 35px 20px 24px;
      }

      .workspace-switcher__state__title {
        color: $workspace-switcher-list-item-color;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 16px;
      }

      .workspace-switcher__state__description {
        color: $workspace-switcher-list-item-color;
        font-size: 12px;
        margin-bottom: 16px;
      }

      .workspace-switcher__state__learn-more {
        margin-bottom: 32px;

        .btn {
          padding-left: 0;
        }
      }
    }

    .workspace-switcher__search {
      font-weight: var(--text-weight-regular);
      display: flex;
      flex: 1;
      .input-search-group {
        width: 100%;
        height: 32px;
        border-radius: var(--border-radius-default);
        padding-right: var(--spacing-zero);

        .input-search-group__search-cancel-wrapper{
          padding-right: 10px;
        }
      }
    }

    .workspace-switcher__header {
      display: flex;
      align-items: center;
      padding: var(--spacing-l) var(--spacing-l) var(--spacing-s);

      .workspace-switcher__actions {
        display: flex;
        align-items: center;

        .workspace-switcher__action {
          &.workspace-switcher__action--create {
            margin-left: var(--spacing-l);
          }
        }
      }
    }

    .workspace-switcher__list {
      height: 100%;
      overflow: auto;
      position: relative;
      font-weight: var(--text-weight-regular);

      .loading-indicator-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 10px;
      }

      .workspace-switcher__no-result {
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: var(--content-color-tertiary);
        margin: 0 40px;
      }

      .workspace-switcher__spinner {
        display: flex;
        justify-content: center;
        padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-s);
      }

      .workspace-switcher__error {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-s);

        &-btn {
          color: var(--content-color-secondary);
        }
      }

      .workspace-switcher__list__group {
        .workspace-switcher__list__group__header {
          display: flex;
          align-items: center;

          .workspace-switcher__list__group__title {
            line-height: var(--line-height-m);
            margin-left: var(--spacing-l);
          }
        }

        .workspace-switcher__list__group__list {
          .workspace-switcher__list__group__list-item {
            display: flex;
            padding: var(--spacing-zero) var(--spacing-l);
            height: var(--size-m);
            align-items: center;
            box-sizing: border-box;
            @include clickable;

            .workspace-actions-dropdown, .workspace-switcher__join {
              display: none;
              font-size: 12px;
              font-weight: 600;
            }

            .workspace-switcher__join,
            .workspace-actions-dropdown-button {
              height: var(--size-s);
              .loading-indicator-wrapper {
                padding-top: 0;
              }

              &:hover {
                background-color: var(--highlight-background-color-tertiary);
              }
            }

            .workspace-switcher__list__group__list-item__active-icon,
            .workspace-switcher__list__group__list-item__placeholder
            {
              height: var(--size-xs);
              width: var(--size-xs);
              margin-right: var(--spacing-s);
            }

            .workspace-switcher__list__group__list-item__details {
              visibility: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
            }

            .workspace-switcher__list__group__list-item__name {
              flex: 1;
              font-size: 12px;
              margin-left: var(--spacing-s);
              font-family: $font-primary;
              color: $workspace-switcher-list-item-color;
              user-select: none;
              display: flex;

              @include text-truncate-ellipsis;
            }

            @include hover {
              background-color: $workspace-switcher-list-item--hover-background-color;

              .workspace-type-icon {
                @include icon-color(var(--content-color-primary));
              }

              .workspace-switcher__list__group__list-item__details,
              .workspace-actions-dropdown, .workspace-switcher__join {
                display: flex;
              }
            }

            &.is-hovered {
              .workspace-switcher__list__group__list-item__details,
              .workspace-actions-dropdown, .workspace-switcher__join {
                display: flex;
              }
            }

            &.is-disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }

            &.is-focused {
              background-color: $workspace-switcher-list-item--hover-background-color;

              .workspace-type-icon {
                @include icon-color(var(--content-color-primary));
              }

              .workspace-switcher__list__group__list-item__details,
              .workspace-actions-dropdown, .workspace-switcher__join {
                display: flex;
              }
            }
          }
        }
      }
    }

    .workspace-switcher__footer {
      cursor: pointer;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      color: var(--content-color-secondary);
      padding: 6px var(--spacing-zero) 6px var(--spacing-l);
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

      &:hover {
        color: var(--content-color-link);
      }
    }
  }
}

.workspace-invite-btn {
  background-color: var(--button-main-background-color);
  color: var(--button-main-content-color);

  padding: $button-secondary-padding var(--spacing-s);
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  height: var(--size-s);
  box-shadow: 0 0 0 1px var(--button-main-outline-color) inset;

  @include icon-color(var(--button-main-content-color));

  &.is-disabled {
    cursor: not-allowed;

    &:hover{
      background-color: var(--blue-40);
      color: var(--content-color-constant);
      @include icon-color(var(--content-color-constant));
    }
  }

  &:hover{
    background-color: var(--button-main-hover-background-color);
    color: var(--button-main-content-color);
    @include icon-color(var(--button-main-content-color));
  }

  &:active{
    background-color: var(--button-main-active-background-color);
  }

  &:active {
    background-color: var(--button-main-active-background-color);
  }
}
