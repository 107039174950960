.sidebar-mock-listing {
  &__error__wrapper {
    display: flex;
    flex: 1;
    overflow-y: auto;
  }

  &__error {
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: var(--spacing-xxl);
    flex-direction: column;
    min-height: 300px;

    &__content {
      text-align: center;

      &__header {
        color: var(--content-color-primary);
        margin-top: var(--spacing-l);
        font-size: var(--text-size-l);
        font-weight: var(--text-weight-medium);
        line-height: var(--line-height-l);
      }

      &__sub-header {
        font-size: var(--text-size-m);
        color: var(--content-color-secondary);
        line-height: var(--line-height-m);
        margin: var(--spacing-l) var(--spacing-xl);
      }
    }

    &__retry-button {
      height: var(--size-m);
      width: 100px;
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__list {
      flex: 1;
    }
  }
}
.mock-action-item-wrapper {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  padding: 0 var(--spacing-l);
  width: 100%;
}

.mock-request-access-tooltip-body {
  padding: var(--spacing-xs);

  .title {
    padding-bottom: var(--spacing-m);
    line-height: 18px;
    font-size: var(--text-size-s);
  }
}
