.workspace-drop-zone-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}
.import-tab-contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .import-tab-content {
    padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &.is-hidden {
      display: none;
    }
    .import-by-file-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .import-by-file-text {
        color: var(--content-color-secondary);
        padding-top: var(--spacing-m);
        padding-left: var(--spacing-xs);
        &-link {
          color: var(--content-color-link);
        }
      }
      .drop-files-dropzone-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .drop-files-dropzone {
          display: flex;
          flex-grow: 1;
          border: 2px dashed var(--border-color-strong);
          &-text {
            font-size: var(--text-size-l);
            font-weight: var(--text-weight-medium);
            color: var(--content-color-tertiary);
            padding-bottom: var(--spacing-l);
            &__OR {
              font-size: var(--text-size-s);
              font-weight: var(--text-weight-medium);
              color: var(--content-color-tertiary);
              margin: var(--spacing-l) var(--spacing-zero);
            }
          }
          &-formats {
            display: flex;
          }
          &-format {
            padding: var(--spacing-s);
            margin: var(--spacing-zero) var(--spacing-s);
            font-weight: var(--text-weight-medium);
            border: dashed var(--border-width-default) var(--border-color-default);
            color: var(--content-color-tertiary);
            display:flex;
          }
          .btn {
            color: var(--content-color-secondary);
            font-weight: var(--text-weight-medium);
          }
        }
      }
    }
    .import-by-folder-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .drop-files-dropzone-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .drop-files-dropzone {
          border: 2px dashed var(--border-color-strong);
          display: flex;
          flex-grow: 1;
          &-text {
            font-size: var(--text-size-l);
            font-weight: var(--text-weight-medium);
            color: var(--content-color-tertiary);
            padding-bottom: var(--spacing-zero);
            &__OR {
              font-size: var(--text-size-s);
              font-weight: var(--text-weight-medium);
              color: var(--content-color-tertiary);
              margin: var(--spacing-l) var(--spacing-zero);
            }
          }
          .btn {
            color: var(--content-color-secondary);
            font-weight: var(--text-weight-medium);
          }
        }
      }
    }
    .import-by-url-container {
      .import-by-url {
        &-label {
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-s);
          color: var(--content-color-primary);
        }
        &-input {
          margin: var(--spacing-m) var(--spacing-zero);
        }
      }
    }
    .import-by-text-container {
      .import-by-raw-text {
        &-label {
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-s);
          color: var(--content-color-primary);
        }
        &-input {
          margin: var(--spacing-m) 0 0 0;
          border: 2px dashed var(--border-color-strong);
        }
      }
    }
  }

  .import-tab-content-v2 {
    padding: 0 var(--spacing-zero) var(--spacing-zero);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &.is-hidden {
      display: none;
    }
    .import-by-file-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-height: 252px;
      padding-bottom: 32px;
      .import-by-file-text {
        color: var(--content-color-secondary);
        padding-top: var(--spacing-m);
        padding-left: var(--spacing-xs);
        &-link {
          color: var(--content-color-link);
        }
      }
      .drop-files-dropzone-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        cursor: auto;

        .drop-files-dropzone {
          display: flex;
          flex-grow: 1;
          border: 1px dashed var(--border-color-strong);
          border-radius: 4px;
          cursor: auto;

          &-text {
            font-size: var(--text-size-l);
            font-weight: var(--text-weight-medium);
            color: var(--content-color-tertiary);
            padding-bottom: var(--spacing-l);
            &__OR {
              font-size: var(--text-size-s);
              font-weight: var(--text-weight-medium);
              color: var(--content-color-tertiary);
              margin: var(--spacing-l) var(--spacing-zero);
            }
          }
          &-formats {
            display: flex;
          }
          &-format {
            padding: var(--spacing-s);
            margin: var(--spacing-zero) var(--spacing-s);
            font-weight: var(--text-weight-medium);
            border: dashed var(--border-width-default) var(--border-color-default);
            color: var(--content-color-tertiary);
            display:flex;
          }
          .btn {
            color: var(--content-color-secondary);
            font-weight: var(--text-weight-medium);
          }
        }
      }
    }
    .import-by-text-container {
      .import-by-raw-text {
        &-label {
          font-weight: var(--text-weight-medium);
          font-size: var(--text-size-s);
          color: var(--content-color-primary);
        }
        &-input {
          margin: var(--spacing-m) 0 0 0;
        }
      }
    }
  }
}


.disable-import-cta {
  pointer-events: none;
  opacity: 0.4
}

.import-options-seperator-line {
  border-left: 2px solid var(--border-color-strong);
  height: 16px;
  margin-top: 8px;
}

.import-raw-text-area-cover {
  padding-bottom: var(--spacing-s);
}

.import-text-container-interactive {
  height: 32px; /* set initial height value */
  overflow: hidden;
  z-index: 999;
}

.import-text-container-interactive.focused {
  height: 320px;
  overflow-y: scroll;
  font-family: var(--text-family-code);
}

.import-text-container-interactive.focusedbanner {
  height: 360px;
  overflow-y: scroll;
  font-family: var(--text-family-code);
}


.import-text-container-interactive.expanded {
  height: 222px;
  overflow-y: scroll;
  padding-top: 0px;
  font-family: var(--text-family-code);
}

.import-file-container-height-control {
  max-height: 100px;
  min-height: 100px;
}

.import-modal-import-settings.file-upload {
  max-height: 380px;
}

.import-paste-feature-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.import-paste-feature-icon {
  padding-right: 6px;
}

.import-folder-button {
  margin-bottom: 291px;
  margin-left:10px;
}

.import-repository-modal-state {
  margin-left: auto;
  margin-right: auto;
}

.drop-zone-height-manip {
  height: 280px;
  transition: height 0.1s;
}

.import-modal-footer-top {
  padding-bottom: 6px;
}

.drop-zone-height-manip.invisible {
  height: 0px !important;
  visibility: hidden;
  display: none;
}


.import-modal-import-definition-files-checkbox-padding {
  padding-top: 4px;
  padding-left: 22px;
  height: 52px;
  max-width: 558px;
}

.import-modal-import-definition-files-preview-padding {
  padding-top: 8px;
  padding-left: 22px;
  width: 558px;
}

.import-api-preview-heading {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-bold);
}
