$invite-email-avatar-background-color: var(--background-color-tertiary);
$invite-email-pending-info-color: var(--content-color-secondary);

.invite-users-file-drop {
  display: flex;
  flex: 1;
}

.invite-users-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.invite-users_error_wrapper {
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  margin-top: var(--spacing-s);
}

.invite-users_footer_message_wrapper {
  color: var(--content-color-tertiary);
  font-size: 12px;
  margin-top: var(--spacing-s);
}

.invite-users-file-drop_border_alert {
  display: flex;
  flex: 1;
  border: 1px solid var(--base-color-error);
}

.add-user__list {
  flex: 1;
  border: 1px solid $dropdown-separator-color;
  border-radius: var(--border-radius-default);
  height: 240px;
  overflow: hidden;
}

.invite-user-tag {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;

  .avatar {
    margin: 0 var(--spacing-xs);
  }

  .user-email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .user-group {
    width: calc(var(--size-s) / 2);
    height: calc(var(--size-s) / 2);
    padding: calc(var(--spacing-xs) / 2);
    margin: 0 var(--spacing-xs);
    align-items: center;
    justify-content: center;
    align-self: center;
    background: var(--background-color-tertiary);
    border-radius: var(--border-radius-max);
  }
}

.add-user__option {
  display: flex;
  align-items: center;

  .pm-icon {
    margin-right: 8px;
  }
}

.empty-state--no-users {
  .upload {
    color: var(--content-color-link);
    cursor: pointer;
  }
}

.item-roles__container {
  height: 200px;
  overflow-y: auto;

  .permission-list-container {
    .permission-list {
      padding-bottom: 0;
      border-bottom: none;
      margin: 0;

      .permission-list-item {
        margin: 8px;

        &--user {
          margin-right: 24px;

          &.can-remove {
            margin-right: initial;
          }
        }
      }
    }
  }
}

.add-user-list-container {
  height: calc(100% - 80px);
}

.add-user__item {
  display: flex;
  min-height: 40px;
  padding: 0px 5px;
  justify-content: space-between;
  align-items: center;

  &.invalid-email {
    color: var(--base-color-error);

    .add-user__invite-pending {
      color: var(--base-color-error);
    }
  }

  .add-user__item__anonymous-icon-container {
    background-color: $invite-email-avatar-background-color;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-user__avatar-icon {
    flex: 0 0 20px;
    margin-right: 10px;
  }

  .add-user__avatar-name {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 8px;
    flex: 1;
  }

  .add-user__invite-pending {
    color: $invite-email-pending-info-color;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 170px; // Same as roles dropdown
  }

  .add-user__item__label {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
    margin-left: 4px;
  }

  .add-user__item__roles__container {
    display: flex;
    align-items: center;
    margin-right: 30px;

    &.can-delete {
      margin-right: 0;
    }

    .add-user__item__warning {
      margin-right: 8px;
      @include icon-color(var(--base-color-warning));
    }
  }

  .add-user__item__roles {
    &.dropdown {
      width: 170px;
    }

    .dropdown-button {
      .btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      span {
        @include text-truncate-ellipsis;
      }
    }
  }

  &.add-user__input {
    padding: 0px;
    position: relative;
    border-bottom: 1px solid $dropdown-separator-color;
    display: flex;
    max-height: 80px;
    overflow-y: auto;
    align-items: stretch;

    .dropdown {
      display: flex;
      position: sticky;
      top: 0;

      .dropdown-button {
        border-right: 1px solid $dropdown-separator-color;
        border-radius: 3px 0 0 3px;
        display: flex;
        align-items: center;
      }
    }

    .input-suggestion-group {
      flex: 1;
    }

    .input {
      border-width: 0;
      border-radius: 0px;
      margin-left: 10px;
      background-color: transparent;
      padding: 0 70px 0 5px;
      height: 40px;
    }

    @include hover {
      background-color: $input--box-background-color;
    }
  }

  .add-user__input-label {
    position: absolute;
    z-index: 1;
    border-radius: 0px;
    padding: 10px 11px;
  }

  .add-user__button {
    position: sticky;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  .add-user__delete-icon {
    @include unselectable;
    @include clickable;
    flex: 0 0 20px;
    margin-left: 10px;
  }
}
