.unsaved-api-version-confirmation-modal {

  .unsaved-api-version-confirmation-modal__footer {
    display: flex;
    flex-direction: row;
    flex: 1;

    .unsaved-api-version-confirmation-modal__footer__left {
      display: flex;
      flex: 1;
    }

    .unsaved-api-version-confirmation-modal__footer__right {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row-reverse;
      gap: var(--spacing-m);
      align-items: center;
    }
  }
}
