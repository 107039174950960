.add-certificate-fileuploader-wrapper { // To style the file uploader button to look like Aether small outline button

  .file-input-container {
    width: fit-content; // To restrict width of base DropZone component container so the tooltip appears over the disabled button
  }

  .file-input {
    padding: var(--spacing-zero);

    .btn {
      background-color: var(--button-secondary-background-color);
      color: var(--content-color-primary);
      height: var(--size-m);

      &:hover {
        background-color: var(--button-secondary-hover-background-color);
      }

      &.is-disabled {
        cursor: not-allowed;
      }

      &:not(.is-disabled):hover { // Change button border color only if it is not disabled
        border-color: var(--button-outline-hover-border-color);
      }

      .text {
        font-size: var(--text-size-m);
        font-weight: var(--text-weight-regular);
      }
    }
  }

  // Overriding base file uploader file chip styles
  .file-input__name {
    background-color: var(--background-color-tertiary);
    border: none;
    height: var(--size-m);
    padding: var(--spacing-xs) var(--spacing-s);
  }
}
