.requester-scratchpad-info-container,
.public-info-container,
.requester-info-container {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--content-color-primary);
  border-bottom: var(--border-width-default) var(--border-style-solid)
    var(--border-color-default);
  box-sizing: border-box;

  div {
    height: 100%;
  }
}

.requester-scratchpad-info-container {
  &__light {
    background: var(--yellow-30);
  }

  &__dark {
    background: var(--yellow-70);
  }

  .requester-scratchpad-info {
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-small);
    position: relative;

    span {
      margin-left: var(--spacing-s);
    }

    &__switch {
      font-weight: var(--text-weight-medium);
      color: var(--content-color-primary);
      padding-left: var(--spacing-xs);
      padding-right: var(--spacing-xs);
    }

    &__close{
      background-color: inherit;
      position: absolute;
      right: var(--spacing-s);
      height: var(--size-m);
      width: var(--size-m);

      @include icon-color(var(--content-color-primary));
    }
  }
}

.public-info-container {
    background: var(--background-color-tertiary);

  .public-info {
    display: flex;
    margin-left: var(--spacing-s);
    flex: 1;
    align-items: center;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-medium);
    color:var(--content-color-secondary);

    .btn{
      width: fit-content;
      padding: 0 var(--spacing-s);

      span {
        margin-left: var(--spacing-s);
      }
    }
  }
}

.requester-info-container {
  &__light {
    background: var(--yellow-10);
  }

  &__dark {
    background: var(--yellow-90);
  }

  .requester-info {
    display: flex;
    margin-left: var(--spacing-s);
    flex: 1;
    align-items: center;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-small);

    span {
      margin-left: var(--spacing-s);
    }

    .btn {
      text-decoration: underline;
      font-weight: var(--text-weight-primary);
      color: var(--content-color-primary);
      padding-left: var(--spacing-xs);
    }
  }
}
