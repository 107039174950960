.entity-tab-error {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    width: 415px;
    padding: var(--spacing-l);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__illustration {
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-bottom: var(--spacing-l);
      background-color: var(--background-color-tertiary);

      &__icon {
        height: var(--size-m);
        width: var(--size-m);

        svg {
          height: var(--size-m);
          width: var(--size-m);
        }
      }
    }

    &__illustration-wrapper {
      margin-bottom: var(--spacing-l);
    }

    &__title {
      color: var(--content-color-primary);
      margin-bottom: var(--spacing-s);

      &--forbidden {
        width: 80%;
        text-align: center;
      }
    }

    &__body {
      color: var(--content-color-secondary);
      text-align: center;

      &--forbidden {
        width: 80%;
      }
    }

    &__action {
      margin-top: var(--spacing-xl);
      height: var(--controls-size-default);
      min-width: 60px;
    }
  }
}
