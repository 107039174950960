.session-tab-header__port__wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-content: space-between;
  @include text-truncate-ellipsis;
}

.session-tab-header__port {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  line-height: var(--line-height-m);
  @include unselectable;

  &__container {
    display: flex;
    width: 100%;
  }

  &__input {
    color: var(--content-color-primary);
    font-family: $font-primary;
    font-size: var(--text-size-l);
    font-weight: var(--text-weight-medium);
    @include text-truncate-ellipsis;
  }

  &.is-editing {
    .session-tab-header__name__wrapper {
      width: 100%;
    }
    .inline-input__wrapper {
      width: 100%;
    }
  }

  .inline-input__wrapper {
    .input-box {
      padding: 2px
    }
  }

  .breadcrumb__container {
    padding-left: 3px;
  }

  .session-tab-header__edit-btn {
    @include clickable;
    background-color: transparent;
    visibility: hidden;
    width: var(--size-s);
    height: var(--size-s);
    margin-left: var(--spacing-xs);
    @include hover {
      @include icon-color(var(--content-color-primary));
    }
  }

  @include hover {
    &.editable {
      .session-tab-header__edit-btn {
        visibility: visible;
      }
    }
    .session-tab-header__copy-btn {
      visibility: visible;
    }
  }
}

.session-tab-header.session-port-editor{
  padding: var(--spacing-s) calc((var(--spacing-m) / 2))
}
