.socketIO-stream-item {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--size-m);
    padding: var(--spacing-xs) var(--spacing-m) var(--spacing-xs) var(--spacing-zero);
    cursor: pointer;

    &__left-section {
      display: flex;
      align-items: center;
      flex: 1;
      overflow-x: hidden;

      &__checkbox {
        margin-left: var(--spacing-xs);

        // Checkbox component from @postman/aether
        // has margin-right (8px) even when there is no label
        // This can be removed once this https://postmanlabs.atlassian.net/browse/DSUI-1041 is fixed
        div span span {
          margin-right: unset;
        }
      }

      &__type-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: var(--size-xs);
        min-width: var(--size-xs);
        margin-left: var(--spacing-xl); // applied when there is no checkbox
      }

      &__checkbox + &__type-icon {
        margin-left: 6px; // In accordance with design
      }

      &__message {
        margin-left: var(--spacing-xl);

        @include text-truncate-ellipsis;

        &.add-ellipsis {
          &::after {
            content: '...';
            font-family: var(--text-family-default);
          }
        }
      }

      &__arg-count-badge {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-left: var(--spacing-s);

        .badge {
          text-transform: unset;
        }
      }

      .event-badge {
        margin-left: var(--spacing-xl);
      }
    }

    &__right-section {
      display: flex;
      align-items: center;
      min-width: 96px;
      margin-left: var(--spacing-xl);
      justify-content: flex-end;

      &__time, &__quick-actions {
        display: flex;
        align-items: center;
        margin-right: var(--spacing-s); // applied when there is a expand icon

        &:last-child {
          margin-right: var(--spacing-xl) // applied when there is no expand icon
        }
      }

      &__time {
        font-variant-numeric: tabular-nums;
      }

      &__quick-actions {
        .info-button {
          align-items: center;
          justify-content: center;
          padding: var(--spacing-s);
          box-sizing: border-box;
        }
      }
    }

    &.is-hovered {
      background-color: var(--background-color-secondary);
    }

    &.is-selected {
      background-color: var(--background-color-secondary);
    }
  }

  &__body {
    display:flex;
    flex-direction: column;

    &__message-body-viewer {
      height: 250px;
      box-sizing: border-box;
      border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    }

    &__obj-inspector {
      display: flex;
      flex-direction: column;

      height: 240px;
      padding-left: var(--spacing-xl);
      overflow: auto;
      white-space: pre-wrap;
      word-break: break-all;

      &__error {
        font-family: var(--text-family-default);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        color: var(--content-color-primary);
      }

      & > * {
        margin-bottom: var(--spacing-s);
      }

      &.no-details {
        height: var(--size-m);
      }
    }

    &__plain-text {
      display: flex;
      align-items: center;
      height: var(--size-s);
      padding-left: var(--spacing-xl);
      margin-bottom: var(--spacing-s);
      white-space: pre;
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      font-family: var(--text-family-default);
      font-weight: var(--text-weight-regular);
      color: var(--content-color-primary);

      &__description {
        @include text-truncate-ellipsis;
      }
    }
  }

  &.no-body {
    .socketIO-stream-item__header {
      cursor: default;
    }
  }

  &.server-message {
    .socketIO-stream-item__header {
      &__left-section {
        &__message {
          font-family: var(--text-family-code);
        }

        &__type-icon {
          background-color: var(--background-color-info);
        }
      }
    }
  }

  &.client-message {
    .socketIO-stream-item__header {
      &__left-section {
        &__message {
          font-family: var(--text-family-code);
        }

        &__type-icon {
          background-color: var(--background-color-warning);
        }
      }
    }
  }
}

.socketIO-stream-info-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    margin-bottom: var(--spacing-s)
  }

  span:last-child {
    margin-bottom: unset
  }
}
