.create-new-monitor-modal,
.create-new-documentation-modal {
  .modal-header {
    flex: 0 0 45px;
    background-color: var(--background-color-primary);
    border-bottom: 1px solid var(--border-color-default);

    .modal-header-title {
      display: flex;
      align-items: center;
      padding-top: 5px;
      padding-bottom: 0;
      padding-left: 0;

      .tabs {
        flex: 1;
        height: 100%;

        .tab {
          display: flex;
          padding: var(--spacing-zero);
          font-size: 12px;
          line-height: 24px;
          height: 100%;
          cursor: default;

          &.is-active {
            @include hover {
              color: var(--content-color-primary);
            }
          }

          &.is-done {
            color: var(--content-color-secondary);
            @include icon-color(var(--content-color-secondary));
            @include hover {
              color: var(--content-color-primary);
              @include icon-color(var(--content-color-primary));
            }

            span {
              padding-left: 12px;
            }
          }
        }
      }

      .btn-text {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .modal-content {
    display: flex;
    padding: 0;
    overflow: hidden;

    .create-new-monitor__error {
      flex: 1;
      display: flex;
      .creation-error__icon {
        margin-top: 0px;
      }
    }
    .create-new-monitor-modal__content {
      display: flex;
      flex-direction: row;
      flex: 1;
      width: 100%;

      .create-new-monitor-modal__header {
        .tabs {
          width: 100%;
          height: 50px;
          border-bottom: 1px solid var(--border-color-default);
          background-color: var(--background-color-primary);
          position: relative;
        }
      }

      .create-new-monitor-modal__body {
        display: flex;
        flex-direction: column;
        flex: 1;

        .tabs {
          flex: 0 0 30px;
          justify-content: center;
          width: fit-content;
        }

        .create-new-monitor-modal__right {
          overflow: hidden;

          .create-new-monitor-modal__right__table {
            height: 100%;
            flex: 1;

            .create-new-monitor-modal__data-editor {
              height: 100%;

              .data-editor {
                height: 100%;

                .data-editor {
                  overflow-y: auto;
                  height: 100%;
                }
              }
            }
          }
        }

        .create-new-monitor-modal__footer__helptext {
          margin-top: 20px;
        }
      }

      .step1 {
        overflow: auto;

        .entity-empty__illustration-wrapper,
        .entity-empty__subtitle {
          margin-bottom: 10px;
        }

        &.create-new-monitor-modal__body {
          padding: 20px;

          .tabs {
            border: 1px solid var(--border-color-default);
            margin-bottom: 20px;
            border-radius: var(--border-radius-default);

            .tab--collections {
              border-left: 1px solid var(--border-color-default);
              border-right: 1px solid var(--border-color-default);
            }
            .tab-primary {
              border-bottom: 0px;
              background-color: var(--background-color-tertiary);
              &.is-active {
                background-color: var(--background-color-primary);
                color: var(--content-color-brand);
                .tab-text-wrapper {
                  box-shadow: none;
                }
              }
            }
          }

          .create-new-monitor-modal__right__intro {
            font-size: 12px;
            line-height: 16px;
            color: $create-new-secondary-color;
            width: 100%;
            margin-bottom: 20px;

            .btn-text {
              line-height: 16px;
              padding: 0 3px;
            }
          }

          .create-new-monitor-modal__right {
            flex: 1;

            .create-new-monitor-modal__footer__helptext {
              color: $create-new-primary-color;
              margin-top: 20px;

              .btn-text {
                height: 0px;
              }
            }

            .create-new-monitor-modal__data-editor {
              margin-right: 1px;
            }

            .data-editor {
              display: flex;
              flex-direction: column;

              .data-editor__header {
                margin-right: 12px;
                flex: 0 0 30px;
              }

              .data-editor__rows {
                overflow-y: scroll;

                &::-webkit-scrollbar-track {
                  background-color: $app-background-color;
                }
              }

              .data-editor__header {
                .data-editor__header__cell {
                  font-size: 11px;
                  color: $create-new-primary-color;
                  font-weight: 600;

                  .data-editor__header__cell__title {
                    @include text-truncate-ellipsis;
                  }
                }

                .create-new-monitor-modal__test-header {
                  overflow: hidden;

                  .input-checkbox {
                    min-width: 16px;
                  }

                  span {
                    @include text-truncate-ellipsis;
                  }
                }
              }

              .data-editor__row {
                .data-editor__reorder {
                  width: 14px;
                }
                .dropdown {
                  margin: 0 5px;
                }

                .dropdown-button {
                  .btn {
                    background-color: transparent;
                    padding: 0;
                  }
                }

                .data-editor__cell--time {
                  display: flex;
                  flex: 1;
                  margin-right: 30px;

                  .postfix {
                    font-size: 12px;
                    padding-left: 5px;
                    line-height: 25px;
                    color: $create-new-secondary-color;
                  }
                }

                .data-editor__custom-row {
                  &.is-focused,
                  &:hover {
                    .data-editor__cell--time {
                      margin-right: 0;
                    }
                  }
                }

                &:last-child {
                  .data-editor__custom-row {
                    &.is-focused,
                    &:hover {
                      .data-editor__cell--time {
                        margin-right: 30px;
                      }
                    }
                  }
                }
              }
            }

            .create-new-monitor-modal__test-header {
              display: flex;

              .input-checkbox {
                margin-right: 5px;
              }
            }

            .create-new-monitor-modal__right__table {
              flex: 1;
            }

            .create-new-monitor-modal__card {
              display: flex;
              align-items: center;
              flex: 0 0 230px;
              height: 40px;
              padding: 10px;
              cursor: pointer;
              border-radius: var(--border-radius-default);

              &.is-disabled {
                opacity: 0.5;
                cursor: default;
                user-select: none;
                pointer-events: none;
              }

              @include hover {
                background-color: $create-new-card-background-color--hover;
              }

              .create-new-monitor-modal__card__icon {
                margin-right: 10px;
                flex: 0 0 22px;
              }

              .create-new-monitor-modal__card__title {
                display: flex;
                align-items: center;
                max-width: 190px;

                span {
                  color: $create-new-primary-color;
                  @include text-truncate-ellipsis;
                  font-size: 14px;
                }
              }

              .create-new-monitor-modal__card__subtitle {
                display: flex;
                align-items: center;
                color: $create-new-secondary-color;
                font-size: 12px;
                overflow: hidden;
                max-width: 190px; // Following the structure of card title
              }

              .create-new-monitor-modal__card__requests {
                white-space: nowrap;
                margin-right: 8px;
              }
            }

            .create-new-monitor-modal__right__collections,
            .create-new-monitor-modal__right__team-library {
              display: flex;
              flex-wrap: wrap;
              border: 1px solid $create-new-card-border-color;
              overflow: auto;
              margin-bottom: 20px;
              max-height: 35vh;
              padding: 10px;
            }

            .create-new-monitor-disabled {
              pointer-events: none;
              cursor: not-allowed;
            }
          }

          .create-new-monitor-modal-offline {
            cursor: not-allowed;
            color: var(--content-color-primary);
            opacity: 0.4;
            @include icon-color(var(--content-color-primary));
          }
        }
      }

      .step2 {
        --input-components-width: 500px;

        &.create-new-monitor-modal__body {
          display: flex;
          flex-direction: column;
          padding: 20px;
          max-width: 950px;
          margin: 0 auto;

          .create-new-monitor__body__input-group {
            margin-bottom: 16px;

            @media screen and (max-width: 1183px) {
              &.create-new-monitor__body__input-group--version-select{
                margin-bottom: 8px;
              }
              &.create-new-monitor__body__input-group--frequency{
                margin-bottom: 4px;
              }
            }

            .create-new-monitor__body__label {
              font-size: 12px;
              line-height: 36px;

              &.is-error {
                color: var(--base-color-error);
              }
            }

            .create-new-monitor__body__label--schedule-selector{
              margin-bottom: var(--spacing-xs);
            }

            .create-new-monitor__body__label--regions{

              @extend .create-new-monitor__body__label;

              line-height: var(--line-height-m);
              padding-top: var(--spacing-s);
            }

            .region-label {
              color: var(--content-color-primary);
              font-size: var(--text-size-m);
              margin-bottom: calc((-1) * var(--spacing-s));
            }

            .create-new-monitor__body__input {
              display: flex;

              .create-new-monitor__body__input__helptext {
                margin-top: 18px;
                padding-left: 20px;
                color: $create-new-placeholder-color;

                @media screen and (max-width: 1183px) {
                  &.create-new-monitor__body__input__helptext--version-select {
                    margin-top: 12px;
                  }
                }

                &.create-new-monitor__body__input__helptext--schedule-selector{
                  margin-top: -8px;
                }

                &.create-new-monitor__body__input__helptext--regions {
                  margin-top: 0;
                }
              }

              .create-new-monitor__body__field {
                display: flex;
                flex: 0 0 500px;
                margin-bottom: auto;
                height: 100%;

                &__dropdown{
                  width: var(--input-components-width);

                  &__collection-item-container-selected{
                    // bound the max width as there is a overflow bug in Aether's dropdown item component for large texts
                    max-width: 470px;
                  }

                  &__collection-item-container{
                    // bound the max width as there is a overflow bug in Aether's dropdown item component for large texts
                    max-width: 420px;
                  }
                }

                .dropdown-button {
                  .create-monitor-collection-item {
                    // Ideally, the dropdown component should prevent overflows. But until then, we're forced to
                    // enforce a max-width on the selected option.
                    max-width: 270px;
                  }
                }
              }

              .input-checkbox {
                margin-right: 5px;
              }

              &.create-new-monitor__body__input--regions {
                margin-left: var(--spacing-xl);
              }
            }

            .create-new-monitor__body__input-error {
              color: var(--base-color-error);
              font-size: 11px;
            }

            .create-new-monitor__body__show-more {
              .advanced-section-toggle {
                margin-left: -10px;
              }

              .advanced-section {
                .advanced-section {
                  margin: 10px 0;

                  &__field-error {
                    color: var(--base-color-error);
                    font-size: 11px;
                    margin: 5px 25px;
                  }

                  .advanced-section__checkbox {
                    display: flex;
                  }

                  .advanced-section__field {
                    display: flex;
                    align-items: center;

                    &__input {
                      width: 300px;
                      margin: 5px 10px 5px 25px;
                    }

                    .advanced-section__field__meta {
                      margin: 5px 25px;
                      font-size: 11px;
                    }

                    &.advanced-section__field--notifications {
                      display: block;
                    }

                    &--notification-limit {
                      .monitor-notification-limit__input{
                        width: 75px;
                        margin: 0 8px;
                      }

                      .input-error-section {
                        top: 3px;
                        margin-top: 0px;
                        display: inline-block;
                      }
                    }

                    &.advanced-section__field--timeout,
                    &.advanced-section__field--delay {
                      .advanced-section__field__input {
                        width: 150px;
                      }

                      font-size: 11px;
                    }
                  }

                  &__select {
                    margin: 5px 10px 5px 25px;
                    display: flex;
                    align-items: center;

                    &--retry > * {
                      margin-right: 10px;
                    }

                    &__dropdown--retry{
                      width: 75px;
                    }
                  }

                  .advanced-section__checkbox__label {
                    margin-left: 10px;
                  }

                  &__checkbox-label-helper {
                    font-size: 10px;
                  }
                }
              }
            }

            .create-new-monitor__body__select-group--regions {
              white-space: pre-line;
              width: fit-content;
            }

            .create-new-monitor__body__select-group--regions-manual{
              @extend .create-new-monitor__body__select-group--regions;

              margin-bottom: unset;
            }

            .create-new-monitor__body__select-group--regions__radio {
              margin-right: 5px;
            }

            .create-new-monitor__body__input--help {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              cursor: pointer;
            }

            .create-new-monitor__body__subtext {
              color: $create-new-secondary-color;
              font-size: 12px;
              line-height: 24px;

              .btn-text {
                padding: 0 3px;
              }
            }

            &.create-new-monitor__body__input-group--private {
              margin-top: 10px;
            }
          }

          .create-new-monitor__body__input-group--regions {
            .create-new-monitor__body__input {
              &.create-new-monitor__body__input-regions--auto {
                display: none;
              }

              &.create-new-monitor__body__input-regions--manual {
                display: flex;
              }

              .create-new-monitor__body__field {
                flex-wrap: wrap;
              }

              .create-new-monitor__body__input__checkbox {
                display: flex;
                width: 500px;
                color: $create-new-primary-color;
                margin-top: 8px;
              }

              .create-new-monitor__body__input__checkbox-icon {
                display: inline-block;
                padding-left: 4px;
                vertical-align: -2px;
                @include icon-color(var(--content-color-tertiary));
              }

              .create-new-monitor__body__input__checkbox-region {
                height: 18px;
              }
            }
          }

          .monitor-schedule-selector-tab {
            display: contents;
            >*{
              margin-bottom: var(--spacing-s);
            }

            >*:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }

      .is-loading {
        justify-content: center;
      }

      .version-dropdown {
        width: 500px;

        &-button {
          @include text-truncate-ellipsis;
        }
      }

      .step3 {
        .create-new-monitor-modal__card__center{
          width: 480px;
          margin: 0 auto;
        }
        &.create-new-monitor-modal__body {
          padding: 30px 0;
          overflow-y: auto;


          &.is-error {
            justify-content: center;
          }

          .loading-indicator-wrapper {
            display: flex;
            flex: 1;
            justify-content: center;
          }

          .create-new-monitor-modal__content__header {
            display: flex;
            margin-bottom: 20px;

            .create-new-monitor-modal__content__header__icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 28px;
              height: 28px;
              margin-right: 12px;
            }

            .create-new-monitor-modal__content__header__text {
              flex: 1;

              .create-new-monitor-modal__content__header__text__title {
                font-size: 14px;
                line-height: 20px;
                color: $create-new-primary-color;

                b {
                  @include text-truncate-ellipsis;
                  font-weight: 600;
                }
              }

              .create-new-monitor-modal__content__header__text__text {
                font-size: 12px;
                color: $create-new-placeholder-color;
              }
            }
          }

          .separator {
            display: flex;
            color: $create-new-placeholder-color;
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 20px;
            margin-left: 40px;
            padding-top: 10px;

            .separator-line {
              flex: 1;
              height: 1px;
              margin-top: 9px;
              margin-left: 10px;
              background-color: $create-separator-line-border-color;
            }
          }

          .create-new-monitor-modal__content__footer {
            position: relative;

            .create-new-monitor-modal__step {
              margin-bottom: 20px;
              position: relative;
              margin-left: 40px;
              &::before {
                content: '\2219';
                font-size: 40px;
                position: absolute;
                margin-left: -33px;
                top: 2px;
                color: $create-new-primary-color;
              }

              .create-new-monitor-modal__step__title {

                color: $create-new-primary-color;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
              }

              .create-new-monitor-modal__step__text {
                color: $create-new-secondary-color;
                font-size: 12px;

                .btn-text {
                  padding-left: 0;
                }

                .monitor-url {
                  &.btn-text {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }

      &.enable_scrolling_tab {
        overflow: auto;
      }
    }
  }

  .modal-footer {
    padding-top: 20px;
    flex: 0 0 80px;
    border-top: 1px solid $create-new-card-border-color;

    .create-new-monitor-modal__footer {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      font-size: 12px;
      position: relative;

      .create-new-monitor-modal__footer__info {
        flex: 1;
        align-self: center;
        margin-left: 150px;
      }

      .create-new-monitor-modal__footer__note {
        font-weight: 600;
        color: $create-new-highlight-color-blue;
        margin-right: 10px;
      }

      .create-new-monitor-modal__footer__text {
        color: $create-new-primary-color;

        .btn-text {
          padding: 0 3px;
          margin-bottom: 1px;
        }
      }

      .btn-secondary {
        margin-right: 10px;
      }
    }
  }

  .loading-indicator-wrapper {
    display: flex;
    align-self: center;
  }

  .modal-content.step2{
    overflow-y: scroll;
  }
}

.create-new-monitor__body__input--help-tooltip {
  .btn-text {
    padding-left: 3px;
  }
}


.create-new-monitor__modal__body__input__helptext--regions {
  margin-top: -4px;
  padding-left: 20px;
  color: var(--content-color-tertiary);
}

.create-monitor-modal-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-new-documentation-modal {
  .modal-content {
    overflow-x: auto;

    .create-new-monitor-modal__content {
      // Step 2 and 3 of documentation modal contain a lot of content which can break at 640px breakpoint
      // Add a min-width to prevent content from shrinking on lower viewport widths as the UI looks cluttered.
      &.step2, &.step3 {
        min-width: 800px;
      }
    }
  }
}

