$text-editor-font-size: 12px !default;

.texteditor-header {
  display: inline-flex;
  padding-bottom: var(--spacing-xs);
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}

/*
  TextEditor2 Styles
*/

// Fix for https://postmanlabs.atlassian.net/browse/APPSDK-740
// https://webaim.org/techniques/css/invisiblecontent/
.monaco-aria-container {
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.text-editor-wrapper {
  font-size: $text-editor-font-size;
  border: var(--border-width-default) var(--border-style-solid)
    var(--border-color-strong);
  border-radius: var(--border-radius-default);
}

.text-editor {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: relative;

  // If the editor container is shrinked, the text-editor was not getting resized
  // accordingly in Firefox. This fixes the resizing issue.
  & > div {
    min-height: 0;
  }

  .monaco-editor {
    .monaco-placeholder {
      color: var(--content-color-secondary);
      font-size: var(--text-size-m);
      opacity: 0.6;
      cursor: text;
      line-height: var(--line-height-m);
    }

    // Due to overflowing nature of monaco suggest/parameters-hint/hover widgets, they might get over layed on
    // neighboring elements. Id the z-index of these neighboring elements is greater than that of these widgets
    // they get clipped off. Hence the z-index for widgets is being kept at 100 (similar to Dropdown), to make sure
    // they don't get clipped off by other element with z-index greater than the existing z-index of these
    // widgets.
    // Since the original z-index in monaco follows a hierarchy for these widgets, we also try to
    // maintain the same.
    .parameter-hints-widget {
      // Original z-index in monaco - 10
      z-index: 100;

      // At some position, the parameter hints widget is displayed very close to the cursor,
      // which makes it hard for the user to find the current position of the cursor. Hence we add a
      // left margin to parameter hints widget box to provide some space between the cursor and the box
      margin-left: var(--spacing-s);
    }
    .suggest-widget {
      // Original z-index in monaco - 40
      z-index: 101;
    }
    .monaco-editor-hover {
      // Original z-index in monaco - 50
      z-index: 102;
    }
  }
}

.text-editor__lazy_loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.monaco-editor {
  .suggest-widget {
    p.docs {
      // To felicitate the display of masked values of secure variables
      font-family: 'IBMPlexMono', "Courier New", monospace;
    }
  }
}

.text-editor__feedback-text {
  margin-left: var(--spacing-s);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}

.text-editor__loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.text-editor-tab {
  display: inline-flex;
  align-items: center;

  .tab-beta-label {
    font-size: var(--text-size-xs);
    color: var(--content-color-info);
    font-weight: var(--text-weight-medium);
    position: relative;
    bottom: var(--spacing-xs);
    padding-left: var(--spacing-xs);
  }

  .tab-content-indicator {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-left: var(--spacing-xs);
    background-color: var(--base-color-success);
    display: inline-flex;
  }
}

.text-editor-dropdown {
  .text-editor-dropdown-button__label {
    text-transform: capitalize;
  }
}

.text-editor__language-dropdown,
.text-editor__line-wrap-button {
  margin-left: var(--spacing-s);
}

.text-editor__line-wrap-button {
  background-color: var(--background-color-tertiary);

  &.is-active {
    background-color: var(--background-color-tertiary);
    @include icon-color(var(--content-color-brand));
  }

  @include hover {
    background-color: var(--highlight-background-color-tertiary);
  }
}

.text-editor__copy-button,
.text-editor__search-button {
  margin-right: var(--spacing-s);
  cursor: pointer;
}


