.import-modal {
  height: 68vh;
  .import-modal-header {
    flex-grow: 0;
  }
  .import-modal-content {
    display: flex;
    flex: 1;
    font-size: var(--text-size-m);
    .import-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-shrink: 0;
      .import-input-tabs-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex-grow: 1;
      }
    }
    .import-loading-indicator {
      margin: auto;
    }
  }

  .import-modal-footer {
    border-top: 1px solid var(--border-color-default);
    flex-direction: row-reverse;
    .btn {
      margin-left: var(--spacing-m);
      margin-top: var(--spacing-xl);
    }
  }
}

.import-modal-integration-flex {
  margin: auto;
}

.import-modal-v2 {
  max-height: 68vh;
  transition: height 0.3s;
  overflow: hidden;

  .import-modal-header {
    flex-grow: 0;
  }
  .import-modal-content {
    display: flex;
    flex: 1;
    font-size: var(--text-size-m);
    .import-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-shrink: 0;
      .import-input-tabs-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex-grow: 1;
      }
    }
    .import-loading-indicator {
      min-height: 300px;
    }
  }

  .import-modal-footer {
    flex-direction: row-reverse;
    .btn {
      margin-left: var(--spacing-m);
      margin-top: var(--spacing-xl);
    }
  }
}

.import-modal-footer-import-button {
  margin-top: auto;
  margin-left: 6px;
  padding-top: var(--spacing-xl) !important;
  padding-bottom: var(--spacing-m) !important;
}

.import-offline-banner {
  margin-bottom: var(--spacing-xs);
  align-self: center;
}

.is-hidden {
  display: none;
}

.import-modal-api-options {
  padding-left: 6px !important;
  width: 100%;

  &.import-settings {
    max-width: 100%;
    padding-left: 0px !important;
  }
}

.import-more-icons-padding-azure {
  margin-left: calc(var(--spacing-m) * -1);
}
