.team-profile {
  display: block;
  color: var(--content-color-primary);

  &__main {
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
    padding-bottom: var(--spacing-xl);
  }

  &--actions {
    margin-top: var(--spacing-xl);
    display: flex;

    &-primary {
      width: 152px;
      font-size: var(--text-size-l);
    }

    &-secondary {
      padding: var(--spacing-s) var(--spacing-l);
      font-size: var(--text-size-l);
    }
  }

  &__setup-team {
    max-width: $breakpoint-sm;
    margin: var(--spacing-xxxl) auto var(--spacing-xl);
    position: relative;

    .input {
      font-size: var(--text-size-l);
    }
  }

  &__team-name {
    margin: var(--spacing-s) var(--spacing-l) 0 0;
    width: 50%;
    display: inline-block;
  }

  &__url-title {
    margin-top: var(--spacing-xl);
    font-weight: var(--text-weight-bold);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
  }

  &__url-subtitle {
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    margin-top: var(--spacing-s);
    color: var(--content-color-secondary);
  }

  &__domain {
    max-width: 400px;
    margin-top: var(--spacing-s);
    display: flex;

    &-field {
      display: inline-block;
      flex-basis: 208px;

      input {
        text-align: right;
      }
    }

    &-suffix {
      display: inline-block;
      line-height: var(--line-height-xxxl);
      margin-left: var(--spacing-s);
      font-size: var(--text-size-l);
      color: var(--content-color-primary);
    }
  }

  &__team-discovery {
    font-weight: var(--text-weight-bold);
    font-size: var(--text-size-l);
    line-height: var(--line-height-m);
    margin-top: var(--spacing-xl);
    color: var(--content-color-primary);

    &-content {
      margin-top: var(--spacing-s);
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-l);
      line-height: var(--line-height-l);
      max-width: 435px;
      color: var(--content-color-secondary);
    }
  }
}

.team-profile-picture-container {
  margin-top: var(--spacing-l);
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: var(--spacing-m);

  .pm-user-icon {
    display: inline-block;
    vertical-align: middle;
    background-size: 100%;
    width: 68px;
    height: 68px;
  }

  .team-profile-picture-icon {
    display: inline-block;

    .pm-user-icon--custom {
      background: center/cover no-repeat;
      background-size: 100%;
    }
  }

  .team-profile-picture-upload {
    display: inline-block;
    vertical-align: middle;
    margin-left: var(--spacing-s);
    color: var(--content-color-brand);
    cursor: pointer;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
  }
}

.team-discovery {
  &__toggle {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-l);
  }

  &__switch {
    width: 70px;
  }

  &__loader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  &__turn-on-message {
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
    margin-left: var(--spacing-l);
  }
}
