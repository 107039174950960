@import './sidebar/GlobalsSidebarListItem/index';
@import './contextbar/GlobalsInfoCBView/index';


.globals-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__action {
    margin-right: var(--spacing-s);

    i {
      margin-right: var(--spacing-xs);
    }
  }

  .globals-editor__action:last-child {
    margin-right: unset;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .variables-editor:first-child {
      .variables-editor__content:first-child .key-value-form-editor {
        border-top: none;
      }
    }
  }

  &__variables-editor {
    margin-top: var(--spacing-m);

    .key-value-form-editor {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        height: 5px;
        width: calc(100% - .75rem);
        border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: calc(100% - .75rem);
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }

      .key-value-form-header-row {
        overflow-y: scroll;
      }
    }
  }

  .entity-tab-header + .variables-editor,
  .request-permission-infobar + .variables-editor {
    .variables-editor__content:first-child .key-value-form-editor {
      border-top: none;
    }
  }
}
