.collection-info-cb {
  display: flex;
  flex-direction: column;
  overflow: auto hidden;

  .right-context-bar-header {
    color: var(--content-color-primary);
  }

  &__container{
    height: 100%;
    overflow-y: auto;
    padding: var(--spacing-zero) var(--spacing-s);
  }
}
