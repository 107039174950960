.custom-obj-inspector {
  display: flex;
  flex-direction: column;

  &__item {
    font-size: var(--text-size-m);
    color: var(--content-color-primary);
    line-height: var(--line-height-m);
    font-family: var(--text-family-code);

    &.padded {
      padding-left: var(--spacing-l);
    }

    &__name {
      cursor: pointer;
      margin-left: -16px;
      display: flex;
      color: var(--content-color-secondary);
      align-items: center;
    }

    &__value {
      word-break: break-all;
      color: var(--content-color-info);
    }

    &__caret {
      display: inline-block;
      font-size: 8px;
      margin-right: var(--spacing-xs);
      user-select: none;
      transform: rotateZ(0deg);
      color: var(--content-color-secondary);

      &.down {
        transform: rotateZ(90deg);
      }
    }
  }
}
