.collection-sidebar-menu {
  .collection-sidebar-menu__filter {
    flex: 1;
  }

  .collection-sidebar-menu__actions {
    flex: 1;
  }
}

.collection-sidebar-menu__filter {
  .tabs {
    margin-top: 5px;
    margin-left: 10px;
  }

  .tab {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.collection-sidebar-menu__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px;
}

.collection-sidebar-menu__actions-trash {
  padding: 0;
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);

  &.btn-text:hover, &.btn-text.is-disabled:hover {
    color: var(--content-color-primary);
  }

  &__icon {
    margin-right: var(--spacing-s);
  }
}

.collection-sidebar-menu__actions-new-collection {
  @include unselectable;
  @include clickable;

  @include active {
    // @todo set the fill
  }
}

.collection-sidebar-menu__actions-new-button {
  @include icon-color($icon-color-secondary);
  padding-right: 6px;
}

.collection-sidebar-menu__actions-sort-collection-wrapper {
  padding-top: 11px;
  margin-right: 15px;

  .dropdown .btn {
    height: auto;
    padding: 0;
  }
}

.collection-sidebar-menu__actions-sort-collection-dropdown {
  background-color: transparent !important;
}
