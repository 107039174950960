$cookie-table-row-padding: 10px 5px;

.response-cookie-viewer {
  $cell-width: 100px;

  font-family: $font-primary;
  flex: 1;

  .cookie-table {
    min-width: 400px;
  }

  .cookie-table-header {
    display: flex;
    padding: var(--spacing-xs) var(--spacing-zero);
    justify-content: space-around;
    border-bottom: var(--border-width-l) var(--border-style-solid) var(--border-color-default);

     &__cookie-detail {
      width: $cell-width;
    }
  }

  .cookie-table-content {
    padding: var(--spacing-zero);
    display: flex;
    flex-direction: column;
    font-size: var(--text-size-m);
    color: var(--content-color-primary);

    .cookie-table-content-row {
      padding: var(--spacing-zero);
      display: flex;
      padding-bottom: var(--spacing-s);
      word-wrap: break-word;
      justify-content: space-around;
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      height: 30px;

      @include selectable;

      &__cookie-detail {
        width: $cell-width;
        padding-top: var(--spacing-s);

        .DraftEditor-root {
          width: 100%;
        }

        .DraftEditor-editorContainer {
          padding: 3px;
        }

        .is-focused {
          .DraftEditor-root {
            background: $app-background-color;
          }

          .DraftEditor-editorContainer {
            border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
            padding: 3px;
          }
        }
      }
    }

    .is-selected{
      background-color: var(--background-color-secondary);
    }
  }
}

.response-cookies-unavailable {
  align-items: center;
  margin: auto;

  &__icon {
    @include icon('../assets/images/empty-state/cookie.svg', 120px, 120px);
  }

  &__message {
    text-align: center;
    width: 416px;
  }
}
