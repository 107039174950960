.mock-overview-container {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: var(--spacing-zero);
    overflow:inherit;
    max-width: 560px;
    padding-left: var(--spacing-l);
    padding-right: var(--spacing-l);

    &__wrapper {
      display: flex;
      justify-content: center;
      height: 100%;
    }

    &__border {
      max-width: 480px;
      padding-top: var(--spacing-l);
      border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    }

    &__dropdown {
      max-width: 320px;

      &__label {
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        margin-bottom: var(--spacing-s);
        font-weight: var(--text-weight-bold);
        display: flex;
        color: var(--content-color-secondary);
      }

      &__icon {
        margin-left: var(--spacing-xs);
        display: flex;
        align-items: center;
      }

      &__container {
        margin-top: var(--spacing-xl);
      }
    }

    &__delay-container {
      padding-top: var(--spacing-l);

      &__label {
        padding-bottom: var(--spacing-s);
      }
    }

    &__delay-input-unit {
      display: flex;
      align-items: center;
      padding-left: var(--spacing-xs);
    }

    &__delay-input-wrapper {
      padding-top: var(--spacing-m);
      max-width: 158px;
      display: flex;
    }

    &__is-loading {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    &__isPrivate {
      &__checkbox {
        padding-top: var(--spacing-s);
        padding-bottom: var(--spacing-s);
      }

      &__container {
        padding-top: 28px;
      }
    }

    &__delay-duration-error {
      color: var(--base-color-error);
      padding-top: var(--spacing-xs);
    }
  }

  &__error {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-xxl);
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }

  &__save-header {
    border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    display: flex;
    justify-content: space-between;
    padding-top: var(--spacing-s);
    padding-bottom: var(--spacing-s);
    padding-left: var(--spacing-l);
    padding-right: var(--spacing-l);
    align-items: center;

  }
}

.mock-warning-text-wrapper {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-xs);

  &__label {
    padding-left: var(--spacing-xs);
  }
}

.mock-request-url-editor {
  flex: 1;
  min-width: 0;
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  height: 40px;

  @include focus {
    outline: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
    height: 100%;
  }

  .auto-suggest {
    height: 100%;
    border-right: solid var(--border-width-default) var(--border-color-strong);
    border-left: solid var(--border-width-default) var(--border-color-strong);
    box-sizing: border-box;
    margin-right: -1px;

    @include hover {
      height: 100%;
      background-color: var(--highlight-background-color-secondary);

    }

    &.is-focused {
      background-color: var(--background-color-primary);
      height: unset;
      border-bottom: solid var(--border-width-default) var(--border-color-strong);
    }
  }

  .DraftEditor-root{
    padding: 10px;
    padding-bottom: var(--spacing-m);
  }

  .public-DraftEditor-content, .public-DraftEditorPlaceholder-root {
    font-size: var(--text-size-m);
    line-height: 1.5;
  }
}

.mock-endpoint-overview {
  margin-left: var(--spacing-l);
  margin-right: var(--spacing-l);
  margin-bottom: var(--spacing-s);

  &__editor-wrapper {
    padding-top: var(--spacing-xl);

    &__label {
      padding-bottom: var(--spacing-s);
    }
  }
}

.mock-request-method-editor {
  min-width: 100%;
  justify-content: space-between;
  border-radius: 3px 0px 0px 3px;
  background-color: var(--background-color-secondary);
}
