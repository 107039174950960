.cookie-jar-capture-cookies-section {
  .cookie-jar-capture-cookies-section_tabs {
    padding-right: var(--spacing-zero);
    padding-left: var(--spacing-zero);
    padding-bottom: var(--spacing-zero);
    padding-top: var(--spacing-m);
  }

  .cookie-jar-capture-cookies-section_web {
    margin-top: var(--spacing-l);
  }
}

.cookie-jar-capture-cookies-button {
  width: calc((var(--controls-size-small) * 4) + 8);
}

.cookie-jar-domain-list-item {
  align-items: center;
  font-size: calc(var(--size-xs) - 4);
  padding: var(--spacing-xs) var(--spacing-s);
  background-color: var(--background-color-tertiary);
  border-radius: calc(var(--size-xs) / 4);
  margin-bottom: var(--spacing-xs);
}

.cookie-jar-domain-list-item-close-icon {
  padding-top: var(--spacing-xs);
  cursor: pointer;
}
