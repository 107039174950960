.scratchpad-setup-modal {
  min-height: 232px;

  &__content {
    .message {
      display: inline-block;
    }

    .message-error {
      margin-left: 0;
      vertical-align: top;
    } 
    
    .message-success {
      margin-left: var(--spacing-s);
      vertical-align: top;
    }

    .description {
      margin-top: var(--spacing-s);
      color: var(--content-color-primary);
    }

    .link {
      color: var(--content-color-link);
      cursor: pointer;
    }

    &__enterprise {
      margin-left: var(--spacing-s);
    }

    &__banner {
      margin-top: var(--spacing-s);
    }
  }

  &__footer.modal-footer {
    position: relative;
    padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
    flex-basis: 72px;

    .btn {
      height: var(--controls-size-default);
      min-width: 50px;
      padding: var(--spacing-s) var(--spacing-m);
      margin-left: var(--spacing-s);
    }
  }

  &__footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 90%;
    background-color: var(--border-color-strong);
    margin: 0 var(--spacing-xl);
  }
}
.scratchpad-migration-modal-container{

  .scratchpad-migration-modal {
    &__modal-content {
      min-height: 450px;

      &__inner {
        padding-top: var(--spacing-zero);
      }

      &__initial-error {
        flex-grow: 1;
        max-width: 390px;
        margin-top: var(--spacing-xxl);
      }
    }

    &__no-access {
      flex-grow: 1;
      margin-top: 56px;
    }
  }

  .migrate-entity-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__filters {
      display: flex;
      margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
      height: var(--size-m);

      &--no-margin {
        margin-top: var(--spacing-zero);
      }

      .input-search-group {
        border-radius: var(--border-radius-default);
      }

      .workspace-visibility-dropdown-container {
        margin-left: var(--spacing-s);

        .dropdown-button {
          .btn {
            height: 30px;
          }
        }
      }
    }

    &__empty-state {
      max-width: 390px;
      padding: var(--spacing-s);
    }

    .workspace-list-container {
      height: 288px;
      overflow: auto;
    }

    &__workspace-list {
      .workspace-list-container {
        height: 288px;
        overflow: auto;
      }

      &--extended {
        .workspace-list-container {
          height: 340px;
        }
      }
    }
  }

  .scratchpad-move-entity-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__filters {
      display: flex;
      margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
      height: var(--size-m);

      &--no-margin {
        margin-top: var(--spacing-zero);
      }

      .input-search-group {
        border-radius: var(--border-radius-default);
      }

      .workspace-visibility-dropdown-container {
        margin-left: var(--spacing-s);

        .dropdown-button {
          .btn {
            height: 30px;
          }
        }
      }
    }

    &__empty-state {
      max-width: 390px;
      padding: var(--spacing-s);
    }

    .workspace-list-container {
      height: 288px;
      overflow: auto;
    }

    &__workspace-list {
      .workspace-list-container {
        height: 288px;
        overflow: auto;
      }

      &--extended {
        .workspace-list-container {
          height: 340px;
        }
      }
    }
  }
}
