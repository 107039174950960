.requester-bottom-pane-content {
  cursor: default;
  font-family: $font-primary;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;
  color: var(--content-color-secondary);

  .find-replace-wrapper {
    flex: 1 auto;
    border: solid var(--border-color-default);
    border-width: 1px 0;
    display: flex;
    height: 100%;
  }

  .find-replace-options-wrapper {
    flex: 0 0 450px;
    border-right: 1px solid var(--border-color-default);
    overflow: auto;

    .find-box-container {
      padding: 20px;
    }

    .section-label {
      font-weight: 600;
    }

    .find-box-wrapper {
      display: flex;
      margin: 10px 0;
    }

    .find-input-wrapper {
      flex: 1;
      margin-right: 10px;
    }

    .find-input-extra-options {
      display: flex;
      float: left;
      margin-top: 10px;
      justify-content: space-between;
    }
    .find-input-extra-option-item {
      display: flex;
      &:not(:last-child) {
        margin-right: 7px;
      }
      .input-checkbox {
        margin-right: 7px;
      }

      span {
        cursor: pointer;
      }
    }

    .find-target-wrapper {
      margin-top: 20px;

      .find-target-options-wrapper {
        margin-top: 10px;
      }

      .find-target-option-item {
        line-height: 25px;
        .radio-button {
          margin-right: 7px;
        }
      }
      .find-target-option-entities {
        margin-left: 30px;

        &.is-disabled {
          opacity: 0.4;
        }
      }

      .find-target-option-entity {
        display: flex;
        height: 25px;
        align-items: center;
        .input-checkbox {
          margin-right: 5px;
          flex: 0 0 16px;
        }

        &.collection-entity {
          position: relative;
        }

        &.environment-entity {
          position: relative;
        }

        .entity-wrapper {
          display: flex;
          align-items: center;
          cursor: pointer;
          .expand-entity {
            margin-left: 5px;
          }
        }

        span {
          cursor: pointer;
        }
      }
    }

    .replace-box-wrapper {
      margin-top: 10px;
      padding: 20px;
      border-top: 1px solid var(--border-color-strong);
      .replace-input-wrapper {
        display: flex;
        margin-top: 10px;
        .replace-input-option-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-right: 10px;
        }
        .replace-input-extra-options {
          display: flex;
          margin-top: 10px;
          .input-checkbox {
            margin-right: 7px;
          }

          span {
            cursor: pointer;
          }
        }
        .input-field {
          width: 100%;
        }
      }

      &.is-disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    hr {
      margin: 10px -20px 15px;
      border-color: var(--border-color-default);
    }
  }

  .find-replace-results-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    box-sizing: border-box;
    height: 100%;
    flex: 1;
    overflow: auto;

    .loading-indicator-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
    }

    .find-replace-tab-content {
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    .find-replace-results--tabs {
      border-bottom: 1px solid var(--border-color-default);
    }

    .find-replace--field-selector {
      padding: 5px 0;
      position: relative;

        .find-replace--field-selector-button {
          color: $app-generic-text-color;
          font-size: 11px;
          font-weight: 600;
        }

        .find-replace--field-selector-expand {
          margin-left: 5px;
        }
    }

    .find-replace-empty-results-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: var(--content-color-secondary);
      line-height: 20px;
      font-weight: 600;
      margin-top: 50px;
      @include unselectable;

      &.empty-results {
        margin-top: 0;
      }
    }

    .find-replace-empty-results-image {
      @include icon(
        '../assets/images/thumbnails/emptysearch.svg',
        90px,
        75px
      );
      margin-bottom: 20px;
      background-position: 50%;
    }

    .find-replace-empty-results-text {
      max-width: 550px;
    }

    .find-replace-empty-results-header {
      margin-bottom: 20px;
      opacity: 0.4;
    }

    .find-replace--found-results {
      overflow: auto;
      font-size: 11px;

      .input-checkbox {
        margin-right: 10px;
      }
    }

    .find-replace-info-panel {
      display: flex;
      margin: var(--spacing-zero) var(--spacing-xs);
      align-items: center;

      @include unselectable;

      &__icon {
        margin: var(--spacing-zero) var(--spacing-xs);
      }
    }

    .found-in-target {
      position: relative;
      margin: 20px;
      padding-bottom: 20px;

      &.empty-name {
        border-top: 0;
      }

      .identifier--GET {
        color: var(--base-color-success);
        margin-right: 5px;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-medium);
      }

      .identifier--PUT {
        color: $request-method--PUT-color;
        margin-right: 5px;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-medium);
      }

      .identifier--POST {
        color: var(--base-color-warning);
        margin-right: 5px;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-medium);
      }

      .identifier--DELETE {
        color: var(--base-color-error);
        margin-right: 5px;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-medium);
      }

      .identifier--FOLDER {
        display: inline-flex;
        margin-right: 5px;
        opacity: 0.3;
      }

      .identifier--default {
        margin-right: 5px;
        font-size: 10px;
        font-weight: 600;
      }
    }

    .found-in-target__fork {
      max-width: 100px;

      span {
        font-size: 10px;
      }
    }

    .found-in-target--header {
      position: relative;
      font-weight: 600;
      background-color: var(--background-color-secondary);
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      -webkit-user-select: none;
              user-select: none;

      .target-collection {
        margin-right: 8px;
      }

      .target-environment {
        margin-right: 8px;
      }
      .found-in-target--count {
        color: var(--content-color-secondary);
        font-weight: 400;
      }
      .found-in-target--name-wrapper {
        display: flex;
        align-items: center;
      }

      .found-in-target--name {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .arrow {
        position: absolute;
        left: -16px;
      }
    }

    .found-in-target-entity {
      margin-top: 10px;
      border: 1px solid var(--border-color-strong);
      padding: 0 10px 10px;
      border-radius: var(--border-radius-default);
      position: relative;
    }

    .open-entity {
      position: absolute;
      font-size: 11px;
      font-weight: 600;
      right: 0;
      top: 7px;
      line-height: 20px;
      &.entity--request {
        top: 10px;
      }
    }

    .found-in-target-entity__path-wrapper {
      display: flex;
      align-items: center;
      padding-top: 10px;
    }

    .found-in-target-entity__path {
      display: flex;
      justify-content: space-between;
      line-height: 20px;

      .entity-breadcrumb {
        max-width: 500px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
      }

      .entity-name-wrapper {
        display: flex;
        align-items: center;
        max-width: 180px;

        .entity-name {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: var(--text-size-m);
          line-height: var(--line-height-m);
          font-weight: var(--text-weight-regular);
        }
      }

      .path-prefix {
        opacity: 0.4;
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
      }

    }

    .found-in-target-entity__field {
      padding: 10px 0 0 16px;
    }

    .found-in-target-entity__field-label-row {
      display: flex;
    }

    .found-in-target-entity__field-label {
      opacity: 0.4;
    }

    .search-instance-row {
      display: flex;
      align-items: center;
      padding-left: 16px;
    }

    .found-in-target-entity--instance {
      height: 24px;
      line-height: 24px;
      padding: 0px 10px;
      margin: 5px 0;
      background-color: var(--background-color-tertiary);
      border-radius: var(--border-radius-default);
      position: relative;
      flex: 1;

      .instance-tooltip {
        position: absolute;
        right: 20px;
      }

      .instance-highlight-text {
        color: var(--content-color-info); //DSUI-821 Validate
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        font-weight: var(--text-weight-regular);
      }
    }
  }

}

.search-target-selector-wrapper {
  position: absolute;
  background-color: var(--background-color-secondary);
  left: 20px;
  z-index: 1;
  width: 300px;
  height: 200px;
  font-size: 11px;
  top: 0;
  border-radius: var(--border-radius-default);
  margin-bottom: 20px;
  @include material-shadow-1;

  .search-target-selector-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-target-selector-header {
    padding: 5px 10px;
    background-color: var(--background-color-primary);
  }

  .input-search-group {
    margin: 10px 0px;
    border-radius: var(--border-radius-default);
  }

  .search-target-selector-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-target-selector-meta--count {
    opacity: 0.4;
  }

  .search-target-selector-meta--count-selected {
    padding: 0px 10px;
    opacity: 0.4;
  }

  .search-target-selector-meta--action {
    padding: 0 0 0 10px;
    border-left: 1px solid var(--border-color-default);
    border-radius: 0px;
    line-height: 20px;
    font-size: 11px;
  }

  .search-target-selector--list {
    padding: 5px 0;
    border-top: 1px solid var(--border-color-default);
    height: 90px;
    overflow-y: auto;
  }

  .search-target-selector--list-item-fork {
    margin-left: 8px;
    margin-bottom: 4px;
    max-width: 100px;

    .pm-icon {
      height: 10px;
      width: 10px;
    }

    span {
      font-size: 10px;
    }
  }

  .search-target-selector--list-item {
    display: flex;
    padding: 5px 10px;
    cursor: pointer;

    @include hover {
      background-color: var(--highlight-background-color-secondary);
    }

    span {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.search-field-selector-wrapper {
  position: absolute;
  background-color: $dropdown-menu-background-color;
  color: $dropdown-menu-item-color;
  top: 30px;
  left: 5px;
  font-size: 10px;
  width: 150px;
  line-height: 20px;
  padding: 10px 15px;
  z-index: 1;
  max-height: 280px;
  overflow: auto;
  border-radius: var(--border-radius-default);
  @include material-shadow-1;

  .search-field-selector--head {
    padding-bottom: 10px;
    font-weight: 600;
    opacity: 0.6;
  }

  .search-field-selector--list {
    margin-top: 5px;
  }

  .search-field-selector--section {
    border-top: 1px solid var(--border-color-default);
    position: relative;
    padding: 10px 0;
  }

  .search-field-selector--section-label {
    position: absolute;
    background-color: $dropdown-menu-background-color;
    top: -11px;
    padding-right: 5px;
    font-weight: var(--text-weight-medium);
  }

  .search-field-selector--section-item {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 3px 0;
    .input-checkbox {
      margin-right: 10px;
    }
  }
}
