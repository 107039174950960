.tag {
  padding: var(--spacing-xs) var(--spacing-s);
  border-radius: var(--border-radius-default);
  max-width: 150px;
  @include text-truncate-ellipsis;

  &.tag-info {
    background-color: var(--background-color-tertiary);
    color: var(--content-color-secondary);
  }

  &.tag-warn {
    background-color: var(--background-color-warning);
    color: var(--content-color-warning);
  }

  &.tag-success {
    background-color: var(--background-color-success);
    color: var(--content-color-success);
  }

  &.tag-error {
    background-color: var(--background-color-error);
    color: var(--content-color-error);
  }
}
