.request-editor-tab-body {
  position: relative;
}

.request-editor-tab-contents {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 0;

   // CAUTION: Do not remove this style as it causes the raw body editor to shrink in Safari
  height: 100%;
}

.request-editor-tab-content {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;

   // CAUTION: Do not remove this style as it causes the raw body editor to shrink in Safari
  height: 100%;


  &.is-hidden {
    display: none;
  }
}

.request-script-wrapper,
.request-tests-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s);
  padding-right: var(--spacing-zero); // snippets will handle the spacing at the right
  min-height: 0;
}

.request-tests-editor-container,
.request-prscript-editor-container,
.prscript-editor-container,
.tests-editor-container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;

  // If the editor container is shrinked, the text-editor was not getting resized
  // accordingly in Firefox. This fixes the resizing issue.
  min-height: 0;

  // Layout context for
  // request-prscript-editor-resize-handle
  // request-tests-editor-resize-handle
  position: relative;
  min-width: 0;
}

.request-prscript-editor-group,
.request-tests-editor-group,
.prscript-editor-group,
.tests-editor-group {
  flex: auto;
  display: flex;
  flex-direction: row;
  min-height: 0;
}
