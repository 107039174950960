.trash-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 626px; // to match the trash table width
  margin: auto;

  &__title {
    color: var(--content-color-primary);
    font-size: var(--text-size-l);
    margin-left: var(--spacing-xl);
  }
}

.trash-banner {
  display: flex;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-xl);
  font-size: var(--text-size-m);
  background: var(--background-color-info);
  color: var(--content-color-primary);

  &__text {
    display: flex;
    align-items: center;
  }

  &__icon {
    @include icon-color(var(--content-color-info));
    margin-right: var(--spacing-s);
  }

  .btn {
    padding: 0;
    height: 16px;
    min-width: auto;
    margin-left: var(--spacing-m);
  }

  .loading-indicator-wrapper {
    display: inline-block;
    margin-left: var(--spacing-xl);
  }
}

.trash-tabs {
  margin: var(--spacing-s) var(--spacing-l);
}

.trash-list {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin: var(--spacing-xxl) auto var(--spacing-xl) auto;
  width: 85%;
  table-layout: fixed;

  thead {
    font-weight: var(--text-weight-medium);
    border-bottom: var(--border-width-default) var(--border-style-solid)
      var(--border-color-default);
    color: var(--content-color-secondary);

    td {
      padding: var(--spacing-m);
      width: 40%;
    }

    td:first-child {
      width: 50%;
    }

    td:nth-child(3) {
      width: 10%;
    }
  }

  tbody {
    font-size: var(--font-size-m);
    color: var(--content-color-secondary);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-regular);

    td {
      padding: 0 var(--spacing-m);
      height: 44px;
    }

    td:nth-child(3) {
      .actions {
        display: none;
        justify-content: flex-end;

        .btn {
          font-weight: var(--text-weight-medium);
          padding: 0;
          min-width: auto;
          font-size: var(--text-size-m);
        }

        .btn:first-child {
          margin-right: var(--spacing-s);
        }
      }

      .options {
        display: flex;
        justify-content: flex-end;
      }

      &:hover {
        .actions {
          display: flex;
        }

        .options {
          display: none;
        }
      }
    }

    tr {
      border-bottom: var(--border-width-default) var(--border-style-solid)
        var(--border-color-default);

      &:hover {
        background: var(--background-color-secondary);
        border-radius: var(--border-radius-default);

        .trash-list__actions {
          visibility: visible;
        }
      }
    }
  }

  &__name {
    display: flex;

    i {
      margin-right: var(--spacing-m);
    }

    &-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
    }

    .collection-fork-label {
      margin-left: var(--spacing-m);
    }
  }

  &__time {
    min-width: 120px;
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
  }

  &__restore-btn {
    transform: scaleX(-1);
  }
}

.trash-modal {
  width: initial !important;
  background: var(--background-color-primary);
  padding: var(--spacing-xxl);

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-size: var(--text-size-xxl);
    }
  }

  &-body {
    margin: var(--spacing-l) 0;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin-top: 0;
    color: var(--content-color-primary);
    font-weight: var(--text-weight-medium);
  }

  &-actions {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-s);
    width: 200px;

    .btn {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-m);
    }

    &__cancel {
      width: 30%;
    }

    &__confirm {
      width: 70%;
      margin-left: var(--spacing-m);
    }
  }

  &__collection-name {
    margin-bottom: var(--spacing-xl);
    color: var(--content-color-secondary);
  }

  &__collection-icon {
    margin-right: var(--spacing-xs);
    vertical-align: top;
  }
}

.trash-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.trash-workspace {
  &__select {
    padding-left: 0;
    border: var(--border-width-default) solid var(--border-color-default);
    border-radius: var(--border-radius-default);
    max-height: 300px;
    overflow: auto;
  }
}

.trash-offline {
  position: relative;
  top: 25%;

  &-image {
    @include icon(
      '../assets/images/thumbnails/browse-view-offline.svg',
      160px,
      160px
    );
    margin: auto;
  }
}

.entity-empty.trash-empty {
  border: none;
  padding-top: var(--spacing-xxl);
  position: relative;
  top: 25%;
  height: auto;
}

.trash {
  overflow: auto;
  height: 100%;
}

.trash-modal__restore {
  width: 440px;
  height: 520px;

  .modal-header {
    background-color: var(--background-color-primary);
  }

  .modal-header-title {
    margin-top: var(--spacing-m);
    margin-left: var(--spacing-s);
  }

  .modal-content {
    padding-top: 0;
    overflow: hidden;
  }

  .workspace-empty-state {
    margin-top: 50px;
  }
}
