.pull-request-settings {
  &__is-loading {
    display: flex;
    height: var(--spacing-xxxl);
    align-items: center;
    justify-content: center;
  }

  &__error {
    display: flex;
    height: var(--spacing-xxl);
    margin-top: var(--spacing-m);

    &-text {
      height: 20px;
      margin-left: var(--spacing-s);
    }
  }

  .merge-user-heading {
    height: 20px;
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-s);
    font-weight: var(--text-weight-medium);
  }

  .list-selection-radio {
    height: var(--spacing-xxl);
    display: flex;
    align-items: center;

    .option-text {
      display: inline-block;
      margin-left: var(--spacing-s);
    }
  }

  .select-users {
    margin-left: var(--spacing-xl);

    &__hint {
      margin-bottom: var(--spacing-s);
      color: var(--content-color-secondary);
    }
  }

  .merge-checks {
    margin-top: var(--spacing-s);
    margin-left: var(--spacing-s);

    &__description {
      margin-bottom: var(--spacing-s);
      color: var(--content-color-secondary);
    }

    &__container {
      margin-bottom: var(--spacing-s);

      .checks-checkbox.input-checkbox {
        display: inline-flex;
        margin-right: var(--spacing-s);
      }

      .checks-checkbox.input-checkbox.is-selected {
        background-color: var(--base-color-brand);
      }

      .checks-text {
        vertical-align: text-bottom;
        color: var(--content-color-primary);
      }
    }
  }
}

.mergers-selection {
  overflow-y: auto;
  min-height: 40px;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid)
    var(--border-color-default);
  box-sizing: border-box;
  margin-right: 10px;
}

.user-invite-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  max-height: 80px;

  &__placeholder {
    display: flex;
    position: relative;
    margin-left: var(--spacing-s);

    &-text {
      margin-left: var(--spacing-s);
      color: var(--content-color-secondary);
    }
  }

  .active-input {
    flex: 1;
    padding-left: var(--spacing-s);
  }
}

.merge-user-suggestion {
  height: 56px;

  .avatar {
    margin-right: var(--spacing-s);
  }

  .user-suggestion-details {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .user-suggestion__email {
      margin-top: var(--spacing-xs);
      color: var(--content-color-secondary);
    }
  }
}
