$collection-sidebar-list-item-height: 28px;

.collection-sidebar-list-item__dummy {
  display: flex;
  flex-direction: column;

  .collection-sidebar-list-item__dummy__head {
    flex: 0 0 auto;
  }
}

.collection-sidebar-list-item__dummy__head {
  display: flex;
  flex-direction: row;
  height: $collection-sidebar-list-item-height;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--background-color-secondary);

  @include focus {
    background-color: var(--highlight-background-color-tertiary);
  }

  @include hover {
    background-color: var(--highlight-background-color-secondary);
  }

  .collection-sidebar-list-item__dummy__dropdown-icon {
    display: flex;

    &.is-disabled {
      visibility: hidden;
    }
  }

  .collection-sidebar-list-item__dummy__icon-wrapper {
    flex: 0 0 28px;
    display: flex;
    justify-content: flex-end;
    margin: var(--spacing-zero) var(--spacing-xs);

    .icon-collection {
      padding: 0 5px;
    }
  }

  .collection-sidebar-list-item__dummy-api-collection__icon-wrapper {
    flex: 0 0 28px;
    display: flex;
    justify-content: flex-end;
    margin-left: var(--spacing-xs);
    .icon-collection {
      padding: 0 5px;
    }
  }

  .collection-sidebar-list-item__dummy__meta {
    flex: 1;

    &__transient {
      flex: 1;
      margin-left: var(--spacing-zero);
    }

    &.is-environment {
      margin-left: 28px;
    }

    .collection-sidebar-list-item__dummy__name__wrapper {
      display: flex;
      align-items: center;
      height: var(--size-s);

      .inline-input__wrapper {
        height: var(--size-s);
      }

      .inline-input {
        font-size: var(--text-size-m);
      }
    }

    &.is-collection:not(.source-api), &.is-extensibleCollection {
      margin-left: var(--spacing-s);
    }
  }

  @include unselectable;
}

.collection-sidebar-list-item__dummy__meta {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.collection-sidebar-list-item__dummy__name__wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;

  .collection-sidebar-list-item__dummy__name {
    font-size: var(--text-size-m);
    font-family: $font-primary;
    color: var(--content-color-primary);
    line-height: var(--line-height-m);
    @include text-truncate-ellipsis;
  }
}

.collection-sidebar-list-item__dummy__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
