.plugin__xFlowActivityFeed {
  position: relative;
  .sb__item__icon {
    width: 1.25em;
    height: 1.25em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
    }
  }
  &.ripple-effect {
    .sb__item__icon {
      animation: ripple 0.7s linear infinite;
    }
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--base-color-brand), 0.3),
                0 0 0 0.25em rgba(var(--base-color-brand), 0.3),
                0 0 0 0.5em rgba(var(--base-color-brand), 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.25em rgba(var(--base-color-brand), 0.3),
                0 0 0 0.5em rgba(var(--base-color-brand), 0.3),
                0 0 0 1em rgba(var(--base-color-brand), 0);
  }
}
