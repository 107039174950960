.delete-environment {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.delete-environment-message {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-environment-controls {
  display: flex;
  flex-direction: row-reverse;

  .btn {
    margin-left: 10px;
  }
}
