.add-header-preset,
.edit-header-preset {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;

    &__title {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  &__section-name {
    margin: var(--spacing-s) var(--spacing-zero);
  }

  &__section-values {
    flex: 1;
    height: 0;
    overflow-y: auto;
  }

  &__section-controls {
    display: flex;
    flex-direction: row-reverse;
    margin-top: var(--spacing-l);

    &__cancel {
      margin-right: var(--spacing-m);
    }
  }
}
