$integration-empty-url: $thumbnail-path + 'integrations.svg';

.no-team-integrations {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  .no-team-integrations__illustration-wrapper {
    margin-bottom: var(--spacing-l);
  }
  .no-team-integrations__title {
    font-size: var(--text-size-xl);
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    margin-bottom: var(--spacing-s);
  }
  .no-team-integrations__subtitle {
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    text-align: center;
    margin-bottom: var(--spacing-l);
    max-width: 500px;
    line-height: var(--line-height-m);
  }

}


.no-team-integrations {
  .no-team-integrations__illustration-wrapper--integrations {
    .integrations-empty__illustration {
      @include icon(
        $integration-empty-url,
        115px,
        95px
      )
    }
  }
}
