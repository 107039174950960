.response-loader {
  &.collapsed {
    opacity: 0;
  }
}

.response-loading-cta,
.response-large-cta {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  .response-loading-message,
  .response-large-message {
    color: var(--content-color-primary);
    font-style: normal;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-l);
    line-height: var(--line-height-l);
  }

  .response-loading-message {
    margin-top: 90px;
  }

  .response-large-message {
    margin-top: 50px;
  }

  .response-large-summary {
    color: var(--content-color-primary);
    margin-top: var(--spacing-s);
    font-style: normal;
    font-weight: normal;
    font-size: var(--spacing-m);
    line-height: var(--line-height-m);
    text-align: center;
  }

  .response-download-button,
  .response-cancel-button {
    margin-top: var(--spacing-xl);
    height: var(--size-m);

    font-style: normal;
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
  }

  .response-cancel-button {
    width: 72px;
  }

  .response-download-button {
    width: 240px;
  }

  .response-open-settings {
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-medium);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    margin-top: var(--spacing-s);
  }
}

.response-large-cta {
  margin-top: 40px;
}

.layout-2-column {
  .response-large-cta {
    margin-top: 80px;
  }
}
