.variables-editor {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &__meta {
    margin-bottom: var(--spacing-l);
    @include unselectable;

    .btn {
      padding: var(--spacing-zero);
    }

    .btn-text {
      font-weight: var(--text-weight-regular);
    }

    &.is-bleed {
      padding: var(--spacing-zero) var(--spacing-l);
    }
  }

  .external-link {
    font-size: var(--text-size-m);
  }

  &__content {
    height: 100%;
    overflow-y: auto;

    .key-value-form-row {
      &:hover {
        &:not(.row-focused) {
          .key-value-form-column:last-child {
            .auto-suggest {
              &.has-error,
              &.has-warning {
                margin-right: var(--spacing-xxxl);
              }
            }
          }
        }
      }
    }
  }

  &__alert {
    margin: var(--spacing-l) var(--spacing-l);

    &:empty {
      display: none;
    }
  }
}

.variables-editor__content.is-virtualized {
  height: 100%;
  overflow: hidden;
  padding: 0 var(--spacing-l);
  .key-value-editor {
    height: 100%;
    .key-value-form-editor {
      height: 100%;
      .key-value-form-editor-sortable {
        height: 100%;
        .key-value-form-editor-sortable__selectable {
          height: 100%;
        }
      }
    }
  }
}
