.urlparams-editor {
  display: flex;
  background: $app-background-color;

  &__group {
    width: 100%;
    flex: 1;
  }

  &__title {
    display: flex;
    align-items: center;
    height: var(--size-m);
    font-size: var(--text-size-m);
    color: var(--content-color-secondary);
    font-weight: var(--text-weight-medium);

    &--bleed {
      padding-left: var(--spacing-l);
    }
  }

  &__path-variables {
    &.is-hidden {
      display: none;
    }
  }
}
