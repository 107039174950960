.context-bar__header {
    width: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
}

.context-bar {
  display: flex;
  flex-direction: row;
}
