.pm-proxy-session-config-wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: var(--spacing-m);
  padding-top: var(--spacing-l);
  padding-right: var(--spacing-l);
}

.pm-proxy-session-config-body-wrapper{
  width: 100%;
  height: calc(100% - 20px);
}

.pm-proxy-session-config-body{
  width: 100%;
  height: 100%;
}

.pm-proxy-config-body-tabs {
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-top: var(--spacing-s);
  padding-right: 0;
  padding-bottom: 0;
}

.pm-proxy-config-body-tab-panels {
  width: 100%;
  height: calc(100% - 128px);
  display: flex !important;
  justify-content: center;
  overflow-y: auto;
}

.pm-proxy-config-body-tab-panels-proxy-panel {
  height: 100%;
  width: 40%;
}

.pm-proxy-panel-wrapper{
  width:480px;
  max-width:480px;
  margin: auto;
}

.pm-proxy-capture-button {
  margin-top: var(--spacing-l);
  bottom: var(--spacing-l);
  width: 30%;
}

.pm-interceptor-capture-button {
  bottom: var(--spacing-l);
  margin-top: var(--spacing-s);
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 1124px;
  height: 64px;
  z-index: 100;
}

.pm-capture-body-container {
  margin: calc(-1 * var(--spacing-l));
}

.pm-capture-panel-header {
  padding-left: var(--spacing-zero);
  font-size: var(--size-xs);
  font-weight: 600;
  line-height: var(--size-s);
  color: var(--content-color-primary);
}

.pm-proxy-port-settings-tab {
  width: 40%;
}

.pm-proxy-tabs {
  padding-top: var(--spacing-zero);
}

.pm-clear-requests-button {
  width: 150px;
}

.pm-session-cookie-table-wrapper {
  margin-top: calc(-1 *  (var(--spacing-xs) + var(--spacing-xl)));
}
