.create-test-suite-entity-modal {
  .modal-content {
    line-height: var(--line-height-m);
    padding: var(--spacing-l);
    display: flex;

    .create-test-suite-entity-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 0;

      .intro-content-wrapper {
        margin-bottom: var(--spacing-l);

        .intro-subtitle_learn-more {
          color: var(--content-color-link);
          cursor: pointer;
        }
      }

      .test-suite-name-input-wrapper {
        margin-bottom: var(--spacing-l);

        .test-suite-name-input-header {
          font-weight: var(--text-weight-medium);
          margin-bottom: var(--spacing-s);
        }

        .test-suite-name-input {
          width: 60%;
        }
      }

      .generation-config-options__toggle-button {
        display: inline-flex;
        align-items: center;
        font-weight: var(--text-weight-medium);
        color: var(--content-color-link);
        cursor: pointer;

        .toggle-button-icon {
          margin-right: var(--spacing-xs);
        }
      }

      .generation-config-options-wrapper {
        margin-top: var(--spacing-s);
        padding: var(--spacing-l);
        display: flex;
        flex-direction: column;
        flex: 1 0;
        overflow-y: auto;
        border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
      }
    }
  }

  .modal-footer {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);

    .actions {
      display: flex;
      flex-basis: 60%;

      .btn {
        font-weight: var(--text-weight-medium);
        height: var(--spacing-xxl);

        &.btn-secondary {
          margin-right: var(--spacing-s);
        }

        &.btn-primary {
          flex: 1;
        }
      }
    }
  }
}
