@import './api-observability-azure';
@import './api-observability-import-schema';

.observability-service-details-view-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .no-observability-deployments{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 507px;
    background: var(--background-color-primary);
    .no-observability-deployments-illustration-wrapper {
    }
    .no-observability-deployments-title {
      position: static;
      height: 20px;
      left: var(--spacing-zero);
      right: var(--spacing-zero);
      top: var(--spacing-zero);
      font-family: Inter;
      font-style: normal;
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
      text-align: center;
      letter-spacing: -0.12px;
      color: var(--content-color-primary);
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin: var(--spacing-s) var(--spacing-zero);
    }
    .no-observability-deployments-subtitle {
      position: static;
      height: 40px;
      max-width: 448px;
      left: var(--spacing-zero);
      right: var(--spacing-zero);
      top: 28px;


      font-family: Inter;
      font-style: normal;
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-m);
      line-height: var(--line-height-m);
      text-align: center;
      color: var(--content-color-secondary);
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
  .observability-available-integrations {
    display: flex;
    width: 720px;
    margin-top: var(--spacing-l);
    background: var(--background-color-primary);
    .observability-no-available-service {
      width:400px;
      margin:auto;
      display:flex;
      flex-direction:column;
      align-items:center;
      .observability-no-available-service-illustration {
        display: flex;
        width:140px;
        height:auto;
        margin:auto;
        align-items:center;
      }
      .observability-no-available-service-content {
        width: 210px;
        margin: auto;
        display:flex;
        color: var(--content-color-secondary);
        font-family: Inter;
        font-style: normal;
        font-weight: var(--text-weight-medium);
        font-size: var(--text-size-m);
        line-height: var(--line-height-m);
        text-align: center;
        letter-spacing: -0.12px;
    }
   }
    .observability-integrations-card-list {
     width: 720px;
     display: flex;
     flex-direction: row;
     margin: auto;
     justify-content: center;
     align-items: center;
     grid-template-columns:  repeat(auto-fit, minmax(180px, auto));
     .observability-integrations-card-list-item {
      width:fit-content;
      cursor: pointer;
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: var(--spacing-s) var(--spacing-m);
      border-radius: var(--border-radius-default);
      color: var(--content-color-primary);
      margin-right: var(--spacing-s);
      .observability-integrations-card-list-item-content-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        .observability-integrations-card-list-item-icon-container {
          display: flex;
          align-items: center;
          height: var(--size-xs);
          width: var(--size-xs);
          .observability-integrations-card-list-item-icon {
            width: 100%;
            height: auto;
          }
          .observability-integrations-card-list-item-fallback-icon {
            width: 100%;
            height: auto;
          }
        }
        .observability-integrations-card-list-item-heading {
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          font-weight: var(--text-weight-regular);
          margin-left: var(--spacing-s);
        }
      }
     }
     .list-item-disabled {
       cursor: not-allowed;
       color: var(--content-color-secondary);
     }
    }
  }
}

.observability-deployments-page-container {
  overflow-x: hidden;
  align-items: center;
  margin-left: calc(var(--spacing-xxl) + var(--spacing-m));
  margin-right: calc(var(--spacing-xxl) + var(--spacing-m));
  color: var(--content-color-primary);

  .observability-gateway-title-wrapper {

    .observability-gateway-dashboard-link {
      cursor: pointer;
      display: flex;
      float: right;
      margin-left: var(--spacing-xs);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
      &__refresh {
        padding-right: var(spacing-xxl);
      }
    }
    .observability-gateway-external-link {
      display: flex;
    }
    .observability-gateway-deploy-cta {
      right: 0;
      display: flex;
      align-items: center;
      float: right;
      margin-left: auto;
      min-width: max-content;
      box-sizing: border-box;
    }
    .observability-gateway-import-cta {
      margin-left:var(--spacing-m);
    }
    .observability-gateway-refresh-cta {
      display: flex;
      align-items: center;
      cursor: pointer;
      float: right;
      margin-left: var(--spacing-xs);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
    }
    .observability-gateway-more-options {
      display: flex;
      cursor: pointer;
      float: right;
      margin-right: var(--spacing-s);
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
    }
  }

  .observability-stages-empty-state {
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    color: var(--content-color-secondary);
    margin-left: var(--spacing-l);
    margin-top: var(--spacing-s);
  }

  .observability-stage-wrapper {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-xl);
    .observability-stage-dropdown {
      height: var(--size-m);
      width: 240px;
      margin-left: var(--spacing-l);
      .integrations-add-card__segment {
        height: var(--size-m);
        margin-top: var(--spacing-zero);
        .integrations-add-card__label-title {
          display: none;
        }
        .integrations-add-card__label-description {
          display: none;
        }
        .integrations-add-card__input {
          padding-top: var(--spacing-zero);
        }
      }
    }

    .observability-stage-active-deployment-id {
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-s);
      color: var(--content-color-secondary);
      float: right;
      text-align: right;
      margin-left: auto;
      right: 0;
      width: 135px;
    }

    .observability-stage-status {
      float: right;
      margin-left: var(--spacing-m);
      right: 0;
      margin-right: var(--spacing-l);
      width: 135px;
      height: 24px;
    }
  }

  .observability-stage-dropdown-wrapper {
    background-color: var(--background-color-secondary);
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
  }

  .observability-gateway-url {
    display: flex;
    width: 100%;
    margin-left: var(--spacing-l);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    padding-top: var(--spacing-m);
    color: var(--content-color-link);
    .observability-gateway-url-title {
      color: var(--content-color-primary);
      font-weight: var(--text-weight-regular);
    }

    &__link {
      cursor: pointer;
    }
  }
  .observability-gateway-description {
    width: 630px;
    height: fit-content;
    margin-left: var(--spacing-l);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-m);
    line-height: var(--line-height-l);
    padding-top: var(--spacing-m);
    color: var(--content-color-secondary);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .observability-gateway-deployments-separator {
    border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
    margin-top: var(--spacing-xl);
  }

  .observability-gateway-deployments-table-title-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: var(--spacing-xxl);
    font-weight: var(--text-weight-regular);
    font-size: var(--text-size-l);
    line-height: var(--line-height-s);
    .observability-gateway-deployments-table-title {
      font-weight: var(--text-weight-medium);
    }
  }

  .observability-gateway-deployments-table-view-all {
    display: flex;
    align-items: center;
    width: 100%;
    .observability-gateway-deployments-table-view-all-btn {
      cursor: pointer;
      width: 178px;
      text-align: center;
      height: var(--size-m);
      margin-left: auto;
      margin-right: auto;
      margin-top: var(--spacing-xl);
      margin-bottom: var(--spacing-xl);
      font-weight: var(--text-weight-regular);
      font-size: var(--text-size-m);
      line-height: var(--line-height-xxl);
      border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
      border-radius: var(--border-radius-default);
    }
  }

  .observability-gateway-logs-wrapper {
    display: flex;
    width: 100%;
    padding-top: var(--spacing-m);
    margin-left: var(--spacing-l);
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);
    color: var(--content-color-secondary);
    .observability-gateway-access-logs {
      color: var(--content-color-link);
      cursor: pointer;
      margin-bottom: var(--spacing-l);
    }
    .observability-gateway-execution-logs {
      margin-left: var(--spacing-xl);
    }
    .observability-gateway-updated-at {
      float: right;
      margin-left: auto;
    }
    .observability-gateway-created-at {
      margin-left: var(--spacing-xl);
      float: right;
      margin-right: 18px;
      padding-right: 18px;
    }
  }

  .observability-deployments-table {
    margin: var(--spacing-s) var(--spacing-zero) var(--spacing-l) var(--spacing-zero);

    overflow-x: auto;
    .observability-deployments-table-success {
      min-width: max-content;
     .observability-deployments-table-header {
        display: flex;
        position: static;
        height: 33px;
        width: 100%;
        border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        box-sizing: border-box;
        min-width: max-content;

        &:last-child {
          flex-basis: 228px;
        }

        .observability-deployments-table-header-cell-dropdown {
        min-width: var(--size-m);
        box-sizing: border-box;
        float: left;
        padding: var(--spacing-s);
        background: var(--background-color-primary);
        color: var(--content-color-primary);
        }
        .observability-deployments-table-header-cell-id {
          width: 20%;
          min-width: 130px;
          float: left;
          padding: var(--spacing-s);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .observability-deployments-table-header-cell-id_short-col {
          width: 15%;
        }
        .observability-deployments-table-header-cell-generic {
          display: flex;
          flex: 1;
          min-width: 130px;
          float: left;
          padding: var(--spacing-s);
          background: var(--background-color-primary);
          color: var(--content-color-primary);

          &__info {
            cursor: pointer;
          }

          &:last-child {
            flex: 0;
          }
        }

        .observability-deployments-table-header-cell-description {
          width: 55%;
          float: left;
          padding: var(--spacing-s);
          min-width: 240px;
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .observability-deployments-table-header-cell-description_short-col {
          width: 40%;
        }
        .observability-deployments-table-header-cell-date {
          float: left;
          width: 25%;
          min-width: 125px;
          padding: var(--spacing-s);
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .observability-deployments-table-header-cell-date_short-col {
          width: 20%;
        }

        .observability-deployments-table-header-cell-source {
          float: left;
          width: 30%;
          padding: var(--spacing-s);
          min-width: 215px;
          background: var(--background-color-primary);
          color: var(--content-color-primary);
        }
        .observability-deployments-table-header-cell-source_short-col {
          width: 25%;
        }

      }
      .observability-deployments-table-row-wrapper {
        min-width: max-content;
        box-sizing: border-box;
        .observability-deployments-table-row {
          display: flex;
          position: static;
          align-items: center;
          font-size: var(--text-size-m);
          height: fit-content;
          min-height: 36px;
          width: 100%;
          box-sizing: border-box;

          .observability-deployments-table-row-cell-stage-link {
            visibility: hidden;
          }

          &:hover .observability-deployments-table-row-cell-stage-link {
            visibility: visible;
          }

          .observability-deployments-table-row-cell-id {
            width: 20%;
            min-width: 130px;
            float: left;
            padding: var(--spacing-s);
            background: var(--background-color-primary);
            color: var(--content-color-primary);
         }
          .observability-deployments-table-row-cell-id_short-col {
            width: 15%;
          }
         .observability-deployments-table-row-cell-dropdown {
            min-width: var(--size-m);
            box-sizing: border-box;
            cursor: pointer;
            float: left;
            padding: var(--spacing-s);
            background: var(--background-color-primary);
            color: var(--content-color-primary);
          }
          .observability-deployments-table-row-cell-generic {
            display: flex;
            flex: 1;
            min-width:130px;
            float: left;
            padding: var(--spacing-s);
            background: var(--background-color-primary);
            color: var(--content-color-primary);
          }
          .observability-deployments-table-row-cell-description {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 55%;
            min-width: 240px;
            min-height: 36px;
            padding: var(--spacing-zero) var(--spacing-s);
            background: var(--background-color-primary);
            color: var(--content-color-primary);
            display: flex;
            align-items: center;
          }
          .observability-deployments-table-row-cell-description_short-col {
            width: 40%;
          }
          .observability-deployments-table-row-cell-date {
            float: left;
            width: 25%;
            min-width: 125px;
            padding: var(--spacing-s);
            background: var(--background-color-primary);
            color: var(--content-color-primary);
          }
          .observability-deployments-table-row-cell-date_short-col {
            width: 20%;
          }

          .observability-deployments-table-row-cell-source {
            float: left;
            width: 30%;
            min-width: 215px;
            padding: var(--spacing-s);
            background: var(--background-color-primary);
            color: var(--content-color-primary);
          }
          .observability-deployments-table-row-cell-source_short-col {
            width: 25%;
          }

        }
        .is-closed {
          border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
        }
        .observability-deployments-table-row:hover {
          background-color: var(--background-color-secondary);
          .observability-deployments-table-row-cell-id {
            background-color: var(--background-color-secondary);
          }
          .observability-deployments-table-row-cell-dropdown {
            background-color: var(--background-color-secondary);
          }
          .observability-deployments-table-row-cell-description {
            background-color: var(--background-color-secondary);
          }
          .observability-deployments-table-row-cell-date {
            background-color: var(--background-color-secondary);
          }
          .observability-deployments-table-row-cell-source {
            background-color: var(--background-color-secondary);
          }
          .observability-deployments-table-row-cell-generic {
            background-color: var(--background-color-secondary);
          }
        }
      }
    }
  }

  .observability-deployments-table-sourced {
    .observability-deployments-table-header, .observability-deployments-table-row {
      padding-left: var(--spacing-xxl);
    }
  }

  .delete-option {
    color:var(--content-color-error);
  }

  .observability-gateway-postman-exports-container {
    margin-top: var(--spacing-xxl);
    max-width: fit-content;
    &__content {
      &__empty {
        margin-top: var(--spacing-s);
      }
      &__table {
        tr td,th {
          padding: var(--spacing-m) var(--spacing-s);
        }
      }
    }
  }
  .observability-gateway-banner {
    margin-top: var(--spacing-l);
  }
}

.observability-edit-integration {
  cursor: pointer;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-primary);
}

.observability-edit-integration-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-secondary);
}

.observability-edit-integration:hover {
  background-color: var(--highlight-background-color-primary);
}

.observability-delete-integration {
  cursor: pointer;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-error);
}

.observability-delete-integration-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--red-40);
}

.observability-delete-integration:hover {
  background-color: var(--background-color-secondary);
}
.observability-delete-integration-disabled:hover {
  background-color: var(--background-color-secondary);
}

.observability-gateway-more-options-icon-wrapper {
  display: flex;
  align-items: center;
}

.observability-loading-indicator {
  position: absolute;
  top: 50%;
  left:50%;
}

.observability-error {
  margin: auto;
  color: var(--content-color-primary);
}

.observability-deployments-id {
  margin-right: 2px;
}

.observability-collapsed-content {
  margin-left: var(--spacing-xs);
  padding-left: 3%;
}

.aws-gateway-dropdown {
  .integrations-add-card__segment .Select--single.pm-select {
    .Select-menu-outer {
      max-height: 100px;
      .Select-menu {
        max-height: 98px;
      }
    }
  }
}

.observability-modal__cancel-button {
  margin-right: var(--spacing-m);
}

.observability-gateway-deploy-cta__menu {
  margin-top: var(--spacing-s);
  min-width: 200px;
}

.observability-onboarding-tooltip-container {
  display: flex;
  flex-direction: column;
  padding:var(--spacing-m);
  &__title {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-zero) var(--spacing-xs);
  }
  &__content {
    padding: var(--spacing-zero) var(--spacing-xs);
  }
  &__button {
    margin-top: var(--spacing-l);
  }
}

.observability-gateway-deploy-cta__menu-heading {
  margin-bottom: var(--spacing-s);
  padding-top: var(--spacing-xs);
  padding-right: var(--spacing-s);
}
.observability-gateway-deploy-cta__menu-info {
  padding: var(--spacing-xs);
}

.deploy-page-container{
  height: 100%;

  &__content {
    overflow-y: auto !important;

    .integrations-add {
      width: unset;
      max-width: 500px;
    }
  }
  &__header {
    padding: var(--spacing-s) var(--spacing-zero) var(--spacing-s) var(--spacing-l);
  }
}

$icon-apigee-logo: $empty-state-path + 'apigee.svg';
$icon-azure-logo: $empty-state-path + 'azure-apim.png';

.connect-api-gateway-tab {
  overflow: auto;
  padding: var(--spacing-xxl);
  display: flex;
  align-items: center;
  flex-direction: column;

  .api-gateway-connecting {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-xxxl);

    &__illustration_wrapper {
      background-color: var(--grey-00);
      width: 60px;
      height: 60px;
      border-radius: var(--border-radius-default);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: var(--spacing-m);
    }

    &__illustration__api_gateway_apigee {
      @include icon(
          $icon-apigee-logo,
          56px,
          56px
      );
    }

    &__illustration__api_management_azure {
      @include icon(
          $icon-azure-logo,
          56px,
          56px
      );
    }

    &__text-primary {
      margin-bottom: var(--spacing-l);
      font-size: var(--text-size-xxl);
      font-weight: var(--text-weight-bold);
      line-height: var(--line-height-xxl);
      color: var(--content-color-primary);
    }

    &__text-secondary {
      font-size: var(--text-size-l);
      color: var(--content-color-secondary);
      margin-bottom: var(--spacing-xl);
    }

    &__cancel-btn {
      background-color: var(--background-color-tertiary);
    }

    &__cancel-btn:hover {
      background-color: var(--highlight-background-color-tertiary);
    }
  }
}

.connect-apigee-gateway {
  &__container {
    margin-top: var(--spacing-xl);
    display: flex;
    justify-content: center;
  }
  &__form {
    margin-top: var(--spacing-s);
    width: 440px;
    &__edit {
      width: 440px;
    }
    &__browse {
      margin-top: var(--spacing-l);
    }
    &--title {
      font-weight: var(--text-weight-medium);
      font-size: var(--text-size-xxxl);
      line-height: var(--line-height-l);
      color: var(--content-color-primary);
    }
    &--description {
      font-size: var(--text-size-l);
      line-height: var(--line-height-m);
      margin-bottom: var(--spacing-xl);
      margin-top: var(--spacing-s);
      color: var(--content-color-secondary);
    }
    &--component {
      margin-bottom: var(--spacing-l);
    }
    &--buttons {
      margin-top: var(--spacing-xl);
      float: right;
      &--update {
        float: right;
        margin-bottom: var(--spacing-xxxl);
      }
    }
    &--entity-selector {
      opacity: .4;
    }
  }
  &__stepper {
    width: 440px;
    &__description {
      font-size:  var(--text-size-s);
      color: var(--content-color-secondary);
      margin-bottom: var(--spacing-xl);
      margin-top: var(--spacing-l);
    }
    &__authorize {
      &__description {
        margin-left: var(--spacing-xs);
        margin-right: var(--spacing-xs);
      }
      &__input {
        margin-top: var(--spacing-s);
      }
      &__service-account {
        margin-top: var(--spacing-xl);
      }
      &__private-key {
        margin-top: var(--spacing-l);;
      }
      &__text {
        font-size: var(--text-size-s);
      }
    }
  }
}

.apigee-dashboard-container {
  padding: var(--spacing-xxl) var(--spacing-xxxl) var(--spacing-xxxl) var(--spacing-xxxl);
  &__header {
    display: flex;
    flex-direction: row;
    &__row1 {
    }
    &__row2 {
      margin-top: 6px;
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
      flex-wrap: wrap;
    }
    &__integration-name {
      font-size: var(--text-size-xl);
      line-height: var(--line-height-m);
      color: var(--content-color-primary);
    }
    &__api-proxy-id {
      margin-left: var(--text-size-xl);
      font-size: var(--text-size-m);
      color: var(--content-color-primary);
    }
    &__api-metrics {
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
    }
    &__options {
      &__delete {
        &__modal {
          max-width: 480px;
          margin: auto;
          &__title > h2{
            font-size: var(--text-size-m);
            line-height: var(--line-height-m);
            font-weight: var(--text-weight-medium);
            color: var(--content-color-secondary);
          }

          &__content {
            font-size: var(--text-size-m);
            line-height: var(--line-height-s);
            color: var(--content-color-primary);
          }
        }
      }
    }
    &__source {
      margin-left: var(--spacing-l);
      &__text {
        font-weight: var(--text-weight-medium);
        padding-right: var(--spacing-xs);
      }
    }
    &__latest-revision {
      &__text{
        font-weight: var(--text-weight-medium);
        padding-right: var(--spacing-xs);
      }
      &__id{
        color: var(--content-color-secondary);
      }
    }
    &__created-at__text {
      font-weight: var(--text-weight-medium);
      padding-right: var(--spacing-xs);
    }
    &__last-updated__text {
      font-weight: var(--text-weight-medium);
      padding-right: var(--spacing-xs);
      padding-left: var(--spacing-m);
    }
  }

  &__content {
    &__deployments {
      margin-top: var(--spacing-xx);
      &__heading {
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
        &--overview {
          font-weight: var(--text-weight-medium);
          color: var(--content-color-primary);
          font-size: var(--text-size-m);
          line-height: var(--line-height-s);
          &__view-all {
            font-size: var(--text-size-m);
            line-height: var(--line-height-s);
            color: var(--content-color-secondary);
            cursor: pointer;
          }
        }
      }

      &__table {
        margin-top: var(--spacing-s);
        &--overview {
          margin-top: var(--spacing-l);
        }
      }
      &__no-data {
        margin-top: var(--spacing-l);
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
      }
    }

    &__proxy-endpoints {
      margin-top: var(--spacing-xxl);
      &__heading {
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
        font-size: var(--text-size-m);
        line-height: var(--line-height-s);
      }

      &__table {
        margin-top: var(--spacing-s);
      }
      &__loading {
        margin-top: var(--spacing-xl);
        margin-left: var(--spacing-l);
      }
      &__no-data {
        margin-top: var(--spacing-l);
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
      }
    }

    &__environments {
      margin-top: var(--spacing-xxl);
      &__heading {
        font-weight: var(--text-weight-medium);
        color: var(--content-color-primary);
        font-size: var(--text-size-xl);
        line-height: var(--line-height-m);
      }

      &__table {
        margin-top: var(--spacing-xl);

        &__status {
          position: relative;
          &__icon {
            position: absolute;
            padding-top: 2px;
            padding-left: var(--spacing-xs);
          }
          &__badge {
            margin-left: var(--spacing-zero);
          }
        }
      }
      &__loading {
        margin-top: var(--spacing-xl);
        margin-left: var(--spacing-l);
      }
      &__no-data {
        margin-top: var(--spacing-xl);
        color: var(--content-color-secondary);
        font-size: var(--text-size-m);
      }
    }
    &__revision {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: var(--content-color-link);
      }
    }
  }
}

.apigee-overview-tab {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-l);
  &__heading {
    font-weight: var(--text-weight-medium);
    color: var(--content-color-primary);
    font-size: var(--text-size-m);
    line-height: var(--line-height-s);
    &__view-all {
      font-size: var(--text-size-m);
      line-height: var(--line-height-s);
      color: var(--content-color-secondary);
      cursor: pointer;
    }
  }
  &__table {
    margin-top: var(--spacing-l);
  }
  &__no-data {
    margin-top: var(--spacing-l);
    color: var(--content-color-secondary);
    font-size: var(--text-size-m);
  }
  &__loading {
    margin-top: var(--spacing-l);
    margin-left: var(--spacing-l);
  }
}

.dropdown-icon-remove {
  .aether-dropdown__dropdown-indicator {
    display: none;
  }
}

.dropdown-retry {
  text-decoration: underline;
  cursor: pointer;
  margin-left: var(--spacing-xs);
}

.loading-indicator-aether {
  &__dropdown {
    margin-right: var(--spacing-s);
  }
}

.loading-indicator-import {
  &__dropdown {
    margin-right: var(--spacing-l);
  }
}

.import-api-gateway {
  &__form {
    margin-top: var(--spacing-l);
    &--component {
      margin-bottom: var(--spacing-l);
      &__aws-account-id {
        margin-top: var(--spacing-l);
        margin-bottom: var(--spacing-l);
      }
    }
    &--last-component {
      margin-bottom: var(--spacing-zero)
    }
  }
}

.observability-deployments-table-row-cell-stage-link {
  flex: 0 !important; // To override specificity
}
