$collection-sidebar-list-item-height: 28px;
$collection-sidebar-list-item-action-height: var(--size-s);
$collection-sidebar-list-item-collection-icon-wrapper-width: 50px;
$collection-sidebar-list-item-collection-actions-wrapper-width: var(--size-s);

$empty-state-path: '../assets/images/empty-state/' !default;
$icon-no-collections-url: $empty-state-path + 'collection_cloud.svg';

@keyframes shimmer{
  to {
    background-position-x: -25%
 }
}

.collection-sidebar-list-item {
  border-bottom: 1px solid $app-border-color;
  flex: 0 0 auto;

  &.is-hidden {
    display: none;
  }
}

.collection-sidebar-list-item__head {
  display: flex;
  flex-direction: column;

  .collection-sidebar-list-item__head__head {
    flex: 0 0 auto;
  }
}

.collection-sidebar-list-item__head__head {
  display: flex;
  flex-direction: row;
  height: $collection-sidebar-list-item-height;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--global-navigation-background-color);


  &.is-active {
    background-color: var(--highlight-background-color-secondary);
  }
  &.is-focused {
    &.is-sidebar-in-focus {
      box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
    }
  }

  &.is-selected {
    background-color: var(--highlight-background-color-primary);
    &.is-sidebar-in-focus {
      background-color: var(--highlight-background-color-tertiary);
      box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset;
      &.is-focused {
        box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset, 0px 0px 0px 2px var(--blue-30) inset;
      }
    }
  }

  &.is-selected {
    &.is-sidebar-in-focus {
      .collection-sidebar__indent {
        &.active-indent {
          border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
        }
      }
    }
  }

  // We are temporarily disabling this until we figure out all the edge cases associated with cut state.
  // &.is-cut {
  //   opacity: 0.5;
  // }

  @include hover {
    background-color: var(--highlight-background-color-secondary);
  }


  &.is-drop-hovered {
    box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
  }

  // GRW-2118: Adding a shimmer effect on a collection template in the sidebar
  &.is-animated {
    background: linear-gradient(-45deg, var(--global-navigation-background-color) 45%,
    var(--background-color-primary) 50%, var(--global-navigation-background-color) 55%);
    background-size: 200%;
    background-position-x: 110%;
    animation: shimmer 1.5s infinite linear;
  }

  .collection-sidebar-list-item__head__icon-wrapper {
    flex: 0 0 28px;
    display: flex;
    justify-content: flex-end;
    margin: var(--spacing-zero) var(--spacing-xs);

    .icon-collection {
      padding: 0 5px;
    }
  }

  .collection-sidebar-list-item__head__meta {
    flex: 1;
    margin-left: var(--spacing-s);

    &__transient {
      flex: 1;
      margin-left: var(--spacing-zero);
    }

    .collection-sidebar-list-item__head__name__wrapper {
      display: flex;
      align-items: center;
      height: var(--size-s);

      .inline-input__wrapper {
        height: var(--size-s);
      }

      .inline-input {
        font-size: var(--text-size-m);
      }
    }
  }

  .collection-sidebar-list-item__head__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;


    &.is-selected {
      visibility: visible;
    }

    &.is-pending {
      visibility: visible;
    }
  }

  .collection-sidebar-list-item__head__action {
    display: flex;
    flex: 0 0 $collection-sidebar-list-item-action-height;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;


    .collection-sidebar-list-item__collection-browser_action-open-icon {
      transform: rotate(-180deg);
    }

    .btn {
      width: $collection-sidebar-list-item-collection-actions-wrapper-width;
      height: $collection-sidebar-list-item-action-height;
      padding: 0;
    }
  }

  @include hover {
    &:not(.is-multi-selection-active) {
      .collection-sidebar-list-item__head__actions {
        visibility: visible;
      }
    }
  }

  @include unselectable;
  @include clickable;
}

.collection-sidebar-list-item__head__meta {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.collection-sidebar-list-item__head__actions {
  visibility: hidden;
}

.collection-sidebar-list-empty-item {
  font-family: $font-primary;
  font-size: 12px;
  padding: var(--spacing-xl) var(--spacing-xxl);
  color: var(--content-color-secondary);
  text-align: center;

  .entity-empty {
    border: none;

    .entity-empty__title {
      font-size: var(--text-size-l);
      margin-bottom: var(--spacing-l);
      font-weight: var(--text-weight-medium);
    }

    .entity-empty__subtitle {
      margin-bottom: var(--spacing-xl);
      font-size: var(--text-size-m);
      font-weight: var(--text-weight-regular);
      line-height: var(--line-height-s);
    }

    .entity-empty__title,
    .entity-empty__subtitle {
      @include unselectable;
    }

    .entity-empty__illustration-wrapper--collections {
      margin-bottom: 16px;

      .collections-empty__illustration {
        @include icon(
          $icon-no-collections-url,
          75px,
          75px
        );
      }
    }

    .entity-empty__action {
      .btn-text {
        /*
           This has been done when the collection sidebar is squeezed down
           then the create collection buttons' text gets wrapped and moves to next line
           so to avoid that wrapping, the padding is being set to 0
        */
        padding: 0;
      }

      .create-collection-icon {
        padding: 4px 8px 4px 4px;
      }
    }
  }
}


.collection-sidebar-list-item__head__head:not(.is-favorited) {
  .collection-sidebar-list-item__head__favorite-button {
    display: none;
  }

  @include hover {
    .collection-sidebar-list-item__head__favorite-button {
      display: flex;
    }
  }
}

.collection-sidebar-list-item__body--empty {
  font-family: $font-primary;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);

  .collection-sidebar-list__empty-item__content {
    padding: var(--spacing-s) var(--spacing-xl);

    &.collection-sidebar-list__empty-item__content__for-collection{
      padding: var(--spacing-s) var(--spacing-l);
    }

    .btn-text {
      padding: var(--spacing-zero);
      padding-right: var(--spacing-xs);
      line-height: var(--line-height-s);
      font-size: var(--text-size-m);
    }
  }
}

.collection-sidebar-list-item__partial-retry {
  font-family: $font-primary;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: flex;

  .collection-sidebar-list-item__partial-retry__content {
    padding-left: var(--spacing-xl);
    padding-top: var(--spacing-xs);

    .btn-text {
      padding: var(--spacing-zero);
      padding-right: var(--spacing-xs);
      line-height: var(--line-height-s);
      font-size: var(--text-size-m);
    }
  }
}

.collection-sidebar-list-item__head__name__wrapper {

  .collection-sidebar-list-item__head__name-icon__wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;

    .collection-sidebar-list-item__head__name {
      font-size: var(--text-size-m);
      font-family: $font-primary;
      color: var(--content-color-primary);
      line-height: var(--line-height-m);
      padding-right: var(--spacing-xs);
      @include text-truncate-ellipsis;
    }
  }

  .shared-icon {
    margin-left: 10px;
  }

  .collection-sidebar-list-item__head__favorite-button {
    background-color: transparent;
    width: var(--size-s);
    height: var(--size-s);
    @include icon-color(var(--content-color-secondary));

    @include hover {
      background-color: var(--highlight-background-color-tertiary);

      .collection-sidebar-list-item__head__favorite-button-icon {
        @include icon-color(var(--content-color-primary));
      }
    }

    @include active {
      .collection-sidebar-list-item__head__favorite-button-icon {
        // @todo set the fill
      }
    }
  }
}

.collection-sidebar-list-item__head__meta-info {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: 4px;
  font-size: 11px;
  font-family: $font-primary;
}

.collection-sidebar-list-item__head__requests {
  white-space: nowrap;
  color: var(--content-color-primary);
}

.collection-sidebar-list-item__head__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-sidebar-dropdown-actions-wrapper {
  height: var(--size-s);
  width: var(--size-s);

  .collection-sidebar-list-item__spinner {
    margin-top: var(--spacing-xs);
  }
}

.collection-sidebar-actions-dropdown,
.collection-sidebar-folder-actions-dropdown,
.collection-sidebar-request-actions-dropdown {
  .dropdown-button .btn {
    border-radius: 0;
    background-color: inherit;
    min-width: unset;

    .entity-actions-dropdown__icon {
      @include icon-color(var(--content-color-secondary));
    }

    &.is-active {
      background-color: var(--highlight-background-color-secondary);

      .entity-actions-dropdown__icon {
        @include icon-color(var(--content-color-primary));
      }
    }

    @include hover {
      background-color: var(--highlight-background-color-tertiary);

      .entity-actions-dropdown__icon {
        @include icon-color(var(--content-color-primary));
      }
    }

    .collection-sidebar-list-item__head__action &,
    .collection-sidebar-list-item__folder &,
    .collection-sidebar-list-item__request & {
      border-radius: var(--border-radius-default);

      &.is-active {
        .entity-actions-dropdown__icon {
          @include icon-color(var(--content-color-secondary));
        }
      }

      @include hover {
        background-color: var(--highlight-background-color-tertiary);

        &.is-active {
          .entity-actions-dropdown__icon {
            @include icon-color(var(--content-color-primary));
          }
        }
      }
    }
  }
  & .dropdown-menu-item {
    &.is-sub-item-available .expand-icon-wrapper {
      flex: 0 1 auto;
    }
  }
}
