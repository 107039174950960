$icon-position: 24px;
$empty-states-path: '../assets/images/empty-states/' !default;
$share-collection-icon: $empty-states-path + 'share_collection.svg';
$postman-wave-icon: $empty-states-path + 'postman-wave.png';

.sign-in-modal-container {
  margin-top: 0px;
  height: 40vh !important;
  width: 40vw !important;
  min-height: 400px;
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal-content {
    padding: 0;
  }
}

.sign-in-modal {
  height: calc(100% - 32px);
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .sign-in-modal__close-icon {
    position: absolute;
    top: $icon-position;
    right: $icon-position;
    cursor: pointer;
  }

  .sign-in-modal__illustration {
    @include icon(
      $share-collection-icon,
      75px,
      75px
    );
  }

  .learning-center-empty-state-icon {
    @include icon(
      $postman-wave-icon,
      75px,
      75px
    );
  }

  .sign-in-modal__title {
    margin-top: var(--spacing-l);
  }

  .sign-in-modal__subtitle {
    margin-top: 8px;
    text-align: center;
    max-width: 340px;
    @include unselectable;
  }

  .sign-in-modal__buttons{
    display: flex;
    flex-direction: column;
  }

  .sign-in-modal__create-btn {
    margin: var(--spacing-l) var(--spacing-zero);
  }

  .sign-in-modal__separator-block {
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .sign-in-modal__separator-block--separator {
      width: 54px;
      height: 1px;
      background-color: var(--border-color-default);
    }

    .sign-in-modal__separator-block--separator-text {
      margin: 0px 16px;
      color: var(--content-color-secondary);
    }
  }
}
