.interceptor-warning-banner {
  height: auto;
  align-items: center;
  color: var(--content-color-primary);
  box-sizing: border-box;
  margin-top: var(--spacing-l);
  padding: var(--spacing-l);
}

.interceptor-warning-banner {
  background: var(--background-color-warning);

  .interceptor-warning {
    margin-left: var(--spacing-s);
    align-items: center;
    font-size: var(--text-size-m);
    line-height: var(--line-height-m);
    font-weight: var(--text-weight-small);

    span {
      font-weight: bold;
    }

    &__title {
      display: block;
      margin-bottom: var(--spacing-s);
    }
  }
}

.proxy-startup-banner {
  &__container {
    background-color: var(--background-color-info);
    padding: var(--spacing-l);
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-l);
  }

  &__close {
    cursor: pointer;
  }
}

.proxy-banner {
  &-body {
    padding: 5px 0;
  }
}

.proxy-info-banner {
  &__container {
    background-color: var(--background-color-secondary);
    padding: var(--spacing-l);
    display: flex;
    flex-direction: row;
  }
}

.target-input-flex {
  flex: auto;
  overflow: hidden;
}

.target-option-fork-label-area {
  max-width: 100px;
}
